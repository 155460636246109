import { lazy } from "react"

const Error = lazy(() => import("../../views/pages/misc/Error"))
const ComingSoon = lazy(() => import("../../views/pages/misc/ComingSoon"))
const Maintenance = lazy(() => import("../../views/pages/misc/Maintenance"))
const NotAuthorized = lazy(() => import("../../views/pages/misc/NotAuthorized"))
const Chat = lazy(() => import("../../views/chat"))
const DynamicPage = lazy(() => import("@src/shared/dynamic_pages"))
const DynamicPageDetail = lazy(() => import("@src/shared/dynamic_pages/detail"))

const PagesRoutes = [
  {
    path: "/chat",
    element: <Chat />,
    meta: {
      appLayout: true,
      publicRoute: true,
      className: "chat-application"
    }
  },
  {
    path: "/misc/coming-soon",
    element: <ComingSoon />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/misc/not-authorized",
    element: <NotAuthorized />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/misc/maintenance",
    element: <Maintenance />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },
  {
    path: "/misc/error",
    element: <Error />,
    meta: {
      publicRoute: true,
      layout: "blank"
    }
  },

  ////DYNAMIC PAGE
  {
    path: "/dyn_pages/:pageId",
    element: <DynamicPage />,
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/dyn_pages/:moduleId/:pageId",
    element: <DynamicPage />,
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/dyn_pages/:moduleId/:pageId/:pageType",
    element: <DynamicPageDetail />,
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/dyn_pages/:moduleId/:pageId/:pageType/:id",
    element: <DynamicPageDetail />,
    meta: {
      publicRoute: true
    }
  },

  //////////RELATIONS CRUD
  {
    path: "/dyn_pages/:moduleId/:pageId/:pageType/:id/:relPageId",
    element: <DynamicPageDetail />,
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/dyn_pages/:moduleId/:pageId/:pageType/:id/:relPageId/:relPageType",
    element: <DynamicPageDetail />,
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/dyn_pages/:moduleId/:pageId/:pageType/:id/:relPageId/:relPageType/:relId",
    element: <DynamicPageDetail />,
    meta: {
      publicRoute: true
    }
  }
  // {
  //   path: "/dyn_pages/:moduleId/:pageId/:pageType/:id/:relPageId/:relPageType/:relId",
  //   element: <DynamicPageDetail />,
  //   meta: {
  //     publicRoute: true
  //   }
  // }
]

export default PagesRoutes
