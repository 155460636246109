/* eslint-disable no-mixed-operators */
import { Fragment, useState, useEffect, useCallback, useMemo } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Label,
  Form,
  Button
} from "reactstrap"
import Select, { components } from "react-select"
import { useTranslation } from "react-i18next"
import { selectThemeColors, getUserData, getDepthValue } from "@utils"
import {
  getUnits,
  setUnitSelected,
  setUnitSelectedDropdown
} from "../views/apps/unit/store"
import { useDispatch, useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import classnames from "classnames"
import { ChevronDown, ChevronRight } from "react-feather"

import { FixedSizeList as List } from "react-window"
import memoize from "memoize-one"

const GlobalUnitDropdownForm = ({
  parentChange,
  error,
  onlyInput = false,
  colSize = 6,
  optionPlaceHolder = true,
  placeholder,
  colClassName = "",
  field = {},
  item = null,
  value,
  defaultFormValue,
  filterMode,
  beforeHandleFiltersChangeToParent,
  isClearable = false,
  isDisabled = false,
  afterChangeEvent = null,
  noOptionsMessage,
  loadingMessage,
  afterChangeUnitCount = 0,
  setAfterChangeUnitCount,
  onChangeAll,
  onChangeAll2
}) => {
  const { t } = useTranslation()
  const userData = getUserData()
  const dispatch = useDispatch()
  const store = useSelector((state) => state.units)
  const [unitOptions, setUnitOptions] = useState([])
  const [currentUnit, setCurrentUnit] = useState(
    optionPlaceHolder
      ? {
          value: "",
          label: `${t("Select")} ${t("Department")}`
        }
      : {}
  )

  const [unitOptionsCustom, setUnitOptionsCustom] = useState({})
  const [minParentId, setMinParentId] = useState(1)

  const handleChange = (data, parentChange) => {
    setCurrentUnit(data)
    // localStorage.setItem("last_id_unit", data.value)
    if (typeof parentChange === "function") {
      parentChange(data)
    }
  }

  const [hiddenIds, setHiddenIds] = useState({})

  const toggleExpand = (e, props, state) => {
    console.log("toggle expand")
    // e.stopPropagation()
    // const { id } = props.data
    // const newHiddenIds = { ...hiddenIds }

    // // Recursively get all nested children IDs
    // const getNestedChildrenIds = (parentId) => {
    //   const nestedIds = []
    //   unitOptions.forEach((option) => {
    //     if (option.parent_id === parentId) {
    //       nestedIds.push(option.id)
    //       nestedIds.push(...getNestedChildrenIds(option.id))
    //     }
    //   })
    //   // console.log(parentId, nestedIds)
    //   return nestedIds
    // }

    // // Add/remove nested children IDs to/from hiddenIds
    // const toggleNestedChildren = (parentId) => {
    //   const nestedIds = getNestedChildrenIds(parentId)
    //   console.log(nestedIds)
    //   nestedIds.forEach((nestedId) => {
    //     if (!state) {
    //       newHiddenIds[nestedId] = true
    //     } else {
    //       delete newHiddenIds[nestedId]
    //     }
    //   })
    // }

    // console.log(state)
    // // newHiddenIds[id] = state
    // // if (!state) {
    // toggleNestedChildren(id)
    // // } else {
    // //   // delete newHiddenIds[id]
    // //   const parent_id = props.data.parent_id
    // //   toggleNestedChildren(parent_id)
    // // }

    // setHiddenIds(newHiddenIds)
    // props.data.expanded = state
    // console.log(newHiddenIds)
  }

  const isHidden = (id) => hiddenIds[id]

  const isInitiallyExpanded = (depth) => depth < 2

  const height = 35

  const createItemData = memoize((options, children) => ({
    options,
    children
  }))

  const MenuList = (props) => {
    const { options, children, maxHeight, getValue } = props
    const [value] = getValue()
    const initialOffset = options.indexOf(value) * height
    const itemData = createItemData(options, children)

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
        itemData={itemData}
        width="100%"
        className="select__menu-list"
      >
        {Row}
      </List>
    )
  }

  const Row = ({ data, index, style }) => {
    const { children } = data
    return (
      <div
        className="select__option text-truncate"
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          height: `${height}px`
        }}
      >
        {children[index]}
      </div>
    )
  }

  const customComponents = useMemo(
    () => ({
      Option: (props) => {
        const { id, parent_id, hasChild, depth, expanded } = props.data
        const hasLogo = props.data?.attributes?.logo
        const isInitiallyVisible = isInitiallyExpanded(depth)
        const isExpandable = hasChild || isInitiallyVisible
        // const isParentCollapsed = parent_id && !isHidden(parent_id);

        // console.log(parent_id)
        // if (!isExpandable || isHidden(parent_id)) {
        //   return null // Render null for hidden options
        // }

        return (
          id && (
            <Option {...props}>
              <div className="align-items-center d-flex">
                {hasLogo ? (
                  <img
                    src={hasLogo}
                    style={{
                      maxHeight: "24px",
                      maxWidth: "24px",
                      borderRadius: "10%",
                      marginRight: "10px"
                    }}
                  />
                ) : hasChild ? (
                  <Button.Ripple
                    onClick={(e) => toggleExpand(e, props, !expanded)}
                    className="btn-icon me-25 btn-sm"
                    color="flat-secondary"
                  >
                    {expanded ? (
                      <ChevronDown className="" size={12} />
                    ) : (
                      <ChevronRight className="" size={12} />
                    )}
                  </Button.Ripple>
                ) : (
                  <span className="btn-icon me-25 btn-sm"></span>
                )}

                {props.data?.code && !item?.hideOptionsCodeField ? (
                  <span>{`${props.data?.code} ${
                    getLabel(item, props.data) ?? props.data?.label
                  }`}</span>
                ) : (
                  <span>{`${
                    getLabel(item, props.data) ?? props.data?.label
                  }`}</span>
                )}
              </div>
            </Option>
          )
        )
      },
      MenuList
    }),
    [hiddenIds]
  )

  const customStyles = {
    option: (provided, state) => {
      const { id, hasChild, depth, children_count } = state.data
      const hasLogo = state.data?.attributes?.logo
      const lvl = (depth ?? 1) + 1 - (depth ?? 1)
      const padding = calculatePadding(depth, minDepth)
      return {
        ...provided,
        fontWeight: id && hasChild ? weight?.[padding - 10] : "normal",
        // paddingLeft: `${padding}px`
        paddingLeft:
          children_count > 0 || !state.data?.hasOwnProperty("children_count")
            ? padding - (hasLogo ? 42 : 10) + (!hasChild ? 2 : 0)
            : 10
      }
    }
  }

  useEffect(() => {
    // if (store.data.length === 0 && !store.isLoading) {
    //   dispatch(getUnits())
    // }

    // console.log(currentUnit)

    const unitOptionsx =
      optionPlaceHolder && store.data?.length > 1
        ? store.dataDropdown
        : store.dataDropdown.filter((item) => item.value !== "")
    let newUnitOptions = []
    if (unitOptionsx?.length > 1) {
      unitOptionsx.map((i, indexx) => {
        // console.log(i.label)
        if (i.label === "Select Unit") {
          // newUnitOptions.push({
          //   value: i.value,
          //   label: t(i.label)
          // })
        } else {
          // newUnitOptions.push(i)
          let newNewUnitOptions = {
            ...i,
            ...(i.hasChild ? { expanded: true } : {})
          }
          newUnitOptions.push(newNewUnitOptions)
        }
      })
    } else {
      newUnitOptions = unitOptionsx
    }

    setUnitOptions(newUnitOptions)

    // console.log(unitOptions.find((c) => c.value === defaultFormValue))
    // if (defaultFormValue) {
    // console.log(store.dataDropdown.find((item) => item.value !== ""))
    // console.log(store.data, defaultFormValue)
    if (store.data?.length) {
      // console.log(store.data?.length)
      if (store.data?.length === 1) {
        let currentUnitx = defaultFormValue
          ? store.dataDropdown.find((c) => c.value === defaultFormValue)
          : store.dataDropdown.find((item) => item.value !== "")
        // let currentUnitx2 = { ...currentUnitx }
        // if (currentUnitx?.label === "Select Unit") {
        //   currentUnitx2.label = t("Select Unit")
        // }
        setCurrentUnit(currentUnitx)
      } else {
        let currentUnitx = defaultFormValue
          ? store.dataDropdown.find((c) => c.value === defaultFormValue)
          : store.dataDropdown[0]

        // let currentUnitx2 = { ...currentUnitx }
        // if (currentUnitx?.label === "Select Unit") {
        //   currentUnitx2.label = t("Select Unit")
        // }

        ///TRANSFORM ARRAY
        let elObj = {}
        for (const el of store.data) {
          elObj[el.id] = { parent_id: el.parent_id }
        }
        setUnitOptionsCustom(elObj)
        setMinParentId(
          Math.min.apply(
            null,
            store.data?.map((value) => value?.parent_id)
          )
        )
        setCurrentUnit(currentUnitx)
      }

      // if (afterChangeUnitCount > 1) {
      // field.onChange(currentUnit?.value)
      // afterChangeEvent(currentUnit?.value, item, true)

      if (currentUnit?.value) {
        setTimeout(() => {
          field.onChange(currentUnit?.value)
          afterChangeEvent(currentUnit?.value, item, true)
          if (filterMode) {
            beforeHandleFiltersChangeToParent()
          }
          // onChangeAll()
          // onChangeAll2("id_unit")
        }, 20)
      }
    } else {
      setAfterChangeUnitCount(1)
    }
    // }

    // }
  }, [currentUnit, store.data])

  const { Option } = components

  const res = []
  const getAncestory = (id, res) => {
    if ((unitOptionsCustom[id]?.parent_id ?? 0) === minParentId) {
      res.push(minParentId)
      return res
    }
    res.push(unitOptionsCustom[id]?.parent_id ?? 0)
    return getAncestory(unitOptionsCustom[id]?.parent_id, res)
  }

  const weight = [800, 700, 600, 500, 400, 300, 200, 100]

  // const handleChangeInput = (field, val) => {
  //   return field.onChange(val?.value)
  // }

  const getExpanded = (parent_id) => {
    let result = true
    let parentObj = unitOptions.find((itemxx) => itemxx.id === parent_id)
    if (parentObj && parentObj.hasOwnProperty("expanded")) {
      result = parentObj.expanded === true
    }
    return result
  }

  const filterOptions = (candidate, input) => {
    // console.log(input, candidate)
    if (input && candidate) {
      // const arrayOfObjects = unitOptions.map((obj) => {
      //   if (obj.hasOwnProperty("expanded")) {
      //     return { ...obj, expanded: true }
      //   }
      //   return obj
      // })
      // setUnitOptions(arrayOfObjects)
      // console.log(candidate, input)

      let result = candidate?.label?.includes(input)
      if (result && candidate?.data?.hasOwnProperty("expanded")) {
        candidate.data.expanded = true
      }
      return result
    } else {
      if (candidate?.data?.hasOwnProperty("expanded")) {
        candidate.data.expanded = false
      }
    }
    return true
  }

  const getLabel = (item, r) => {
    let objSelector = ""
    if (item?.field_label) {
      if (Array.isArray(item?.field_label)) {
        item?.field_label.map((lbl, i, row) => {
          if (i + 1 === row.length) {
            if (lbl.includes(".")) {
              objSelector = objSelector + (getDepthValue(r, lbl) ?? "")
            } else {
              objSelector = objSelector + (r[lbl] ?? "")
            }
          } else {
            if (lbl.includes(".")) {
              objSelector =
                objSelector +
                (getDepthValue(r, lbl) ?? "") +
                (item?.field_label_separator ?? " ")
            } else {
              objSelector =
                objSelector +
                (r[lbl] ?? "") +
                (item?.field_label_separator ?? " ")
            }
          }
        })
      } else {
        objSelector = item?.field_label.includes(".")
          ? getDepthValue(r, item?.field_label)
          : r[item.field_label]
      }
    } else {
      objSelector = r?.label
    }
    return objSelector ?? ""
  }

  const filteredOptions = unitOptions.filter((option) => {
    const isHidden = hiddenIds[option.id] === true
    // console.log(hiddenIds)
    return !isHidden
  })

  const minDepth = Math.min(...unitOptions.map((item) => item.depth))

  function calculatePadding(
    depth,
    startingDepth = 1,
    basePadding = 10,
    paddingMultiplier = 10
  ) {
    const relativeDepth = depth - startingDepth + 1
    return basePadding + (relativeDepth - 1) * paddingMultiplier
  }

  return store.data?.length > 0 && Object.keys(unitOptions)?.length > 0 ? (
    onlyInput ? (
      <Col md={`${colSize}`} xs={12} className={colClassName}>
        <Label for="plan-select">{t("Department")}</Label>
        <Select
          {...field}
          // styles={{
          //   option: (provided, state) => {
          //     const lvl =
          //       store.data?.length > 1 && state.data?.id
          //         ? state.data?.children_count > 0 ||
          //           !state.data?.hasOwnProperty("children_count")
          //           ? getAncestory(state.data?.id, [state.data?.id ?? 0]).length
          //           : 1
          //         : 1
          //     return {
          //       ...provided,
          //       fontWeight:
          //         state.data?.id && state.data?.hasChild
          //           ? weight?.[lvl - 1]
          //           : "normal",
          //       paddingLeft:
          //         state.data?.children_count > 0 ||
          //         !state.data?.hasOwnProperty("children_count")
          //           ? (lvl ?? 1) * 10 -
          //             (state.data?.attributes?.logo ? 42 : 10) +
          //             (!state.data?.hasChild ? 2 : 0)
          //           : 10
          //     }
          //   }
          // }}
          // filterOption={filterOptions}
          styles={customStyles}
          components={customComponents}
          // components={{
          //   Option: (props) => {
          //     // console.log(props)
          //     return (
          //       props.data?.id && (
          //         // getExpanded(props.data?.parent_id) &&
          //         <Option {...props}>
          //           <div
          //             // style={{
          //             //   display: props.data?.expanded ? "block" : "none"
          //             // }}
          //             className="align-items-center d-flex"
          //           >
          //             {props.data?.attributes?.logo ? (
          //               <img
          //                 src={props.data.attributes.logo}
          //                 style={{
          //                   maxHeight: "24px",
          //                   maxWidth: "24px",
          //                   borderRadius: "10%",
          //                   marginRight: "10px"
          //                 }}
          //               />
          //             ) : props.data?.hasChild ? (
          //               copyUnitOpt.find(
          //                 (itemxx) => itemxx.id === props.data.id
          //               )?.expanded === true ? (
          //                 <Button.Ripple
          //                   onClick={(e) => toggleExpand(e, props, false)}
          //                   className="btn-icon me-25 btn-sm"
          //                   color="flat-secondary"
          //                 >
          //                   <ChevronDown className="" size={12} />
          //                 </Button.Ripple>
          //               ) : (
          //                 <Button.Ripple
          //                   onClick={(e) => toggleExpand(e, props, true)}
          //                   className="btn-icon me-25 btn-sm"
          //                   color="flat-secondary"
          //                 >
          //                   <ChevronRight className="" size={12} />
          //                 </Button.Ripple>
          //               )
          //             ) : (
          //               <span className="btn-icon me-25 btn-sm"></span>
          //             )}
          //             {props.data?.code && !item?.hideOptionsCodeField ? (
          //               <span>{`${props.data?.code} ${
          //                 getLabel(item, props.data) ?? props.data?.label
          //               }`}</span>
          //             ) : (
          //               <span>{`${
          //                 getLabel(item, props.data) ?? props.data?.label
          //               }`}</span>
          //             )}
          //           </div>
          //         </Option>
          //       )
          //     )
          //   }
          // }}
          invalid={error && true}
          className={classnames("react-select", {
            "is-invalid": error
          })}
          isDisabled={isDisabled || store.data?.length === 1}
          placeholder={t(placeholder)}
          // theme={selectThemeColors}
          isClearable={isClearable}
          // isOptionDisabled={(option) => !filterMode && option?.hasChild}
          classNamePrefix="select"
          options={unitOptions}
          defaultValue={currentUnit}
          value={unitOptions.find((c) => c.value === value)}
          noOptionsMessage={() => noOptionsMessage}
          loadingMessage={() => loadingMessage}
          ref={field.ref}
          onChange={(val) => {
            field.onChange(val?.value)
            afterChangeEvent(val?.value, item)
            if (filterMode) {
              beforeHandleFiltersChangeToParent()
            }
            onChangeAll()
            onChangeAll2("id_unit")
          }}
          onBlur={field.onBlur}
          // getOptionLabel={(option) =>
          //   option.code ? `${option.code} ${option.label}` : `${option.label}`
          // }
        />
      </Col>
    ) : (
      <Select
        {...field}
        styles={customStyles}
        components={customComponents}
        defaultValue={currentUnit}
        value={unitOptions.find((c) => c.value === value)}
        invalid={error && true}
        className={classnames("react-select", {
          "is-invalid": error
          // hidden: store.data?.length === 1
        })}
        isDisabled={isDisabled || store.data?.length === 1}
        placeholder={placeholder}
        // theme={selectThemeColors}
        isClearable={isClearable}
        // isOptionDisabled={(option) => !filterMode && option?.hasChild}
        classNamePrefix="select"
        options={unitOptions}
        ref={field.ref}
        onChange={(val) => {
          field.onChange(val?.value)
          // handleChangeInput(field, val)
          // afterChangeEvent(val?.value, item)
          afterChangeEvent(val, item)
          if (filterMode) {
            beforeHandleFiltersChangeToParent()
          }
          onChangeAll()
          onChangeAll2("id_unit")
        }}
        onBlur={field.onBlur}
        // getOptionLabel={(option) =>
        //   option.code ? `${option.code} ${option.label}` : `${option.label}`
        // }
      />
    )
  ) : (
    // (
    //   <Select
    //     {...field}
    //     styles={customStyles}
    //     components={customComponents}
    //     invalid={error && true}
    //     className="react-select"
    //     isDisabled={true}
    //     placeholder={placeholder}
    //     classNamePrefix="select"
    //     options={[]}
    //     isLoading={true}
    //   />
    // )
    // filterMode ? (
    //   <Select
    //     className={classnames("react-select", {
    //       "is-invalid": error
    //     })}
    //     isDisabled={true}
    //     placeholder={placeholder}
    //     classNamePrefix="select"
    //     options={[]}
    //     isLoading={true}
    //   />
    // ) : null
    <div style={{ minHeight: "39px" }}></div>
  )
}

export default GlobalUnitDropdownForm
