import axios from "axios"
import jwtDefaultConfig from "./jwtDefaultConfig"

const setLastLogout = () => {
  const currentTime = new Date().getTime()
  localStorage.setItem("lastLogout", currentTime)
  if (
    location.pathname !== "/login" ||
    location.pathname !== "/error" ||
    location.pathname !== "/forgot-password" ||
    location.pathname !== "/reset-password" ||
    location.pathname !== "/register" ||
    location.pathname !== "/logout" ||
    location.pathname !== "/dashboard"
  ) {
    localStorage.setItem("lastLogoutUrl", location.pathname)
    const last_current_page_perm = sessionStorage.getItem("current_page_perm")
    localStorage.setItem("lastLogoutSubject", last_current_page_perm)
  }
}

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  ssoAxiosInterceptor = axios.create({
    baseURL: `${process.env.REACT_APP_SSO_URL}`
  })

  globalAxiosInterceptor = axios.create({
    baseURL: `${process.env.REACT_APP_GLOBAL_URL}`
  })

  plainAxiosInterceptor = axios.create({
    baseURL: ``
  })

  plainNoHeadersAxiosInterceptor = axios.create({
    baseURL: ``
  })

  hrAxiosInterceptor = axios.create({
    baseURL: `${process.env.REACT_APP_API_HR_URL}`
  })

  marketingAxiosInterceptor = axios.create({
    baseURL: `${process.env.REACT_APP_API_MARKETING_URL}`
  })

  financeAxiosInterceptor = axios.create({
    baseURL: `${process.env.REACT_APP_API_FINANCE_URL}`
  })

  fsAxiosInterceptor = axios.create({
    baseURL: `${process.env.REACT_APP_API_FS_URL}`
  })

  sipkdDocAxiosInterceptor = axios.create({
    baseURL: `${process.env.REACT_APP_API_SIPKDDOC_URL}`
  })

  labAxiosInterceptor = axios.create({
    baseURL: `${process.env.EACT_APP_API_LAB_URL}`
  })

  warehouseAxiosInterceptor = axios.create({
    baseURL: `${process.env.EACT_APP_API_WAREHOUSE_URL}`
  })

  purchasingAxiosInterceptor = axios.create({
    baseURL: `${process.env.EACT_APP_API_PURCHASING_URL}`
  })

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    const checkLastRefreshToken = () => {
      var dateString = sessionStorage.getItem("lastRefreshToken")
      var storedTimestamp = Date.parse(dateString)
      var currentTimestamp = new Date().getTime()
      var timeDifference = currentTimestamp - storedTimestamp
      var secondsDifference = Math.floor(timeDifference / 1000)
      if (secondsDifference < 60) {
        // console.log('Perbedaan adalah 1 menit. Melakukan event lain.');
        return false
      } else {
        // console.log('Perbedaan bukan 1 menit.');
        return true
      }
    }

    // // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        let originalRequest = config
        if (response?.status === 401 && !config?.url?.includes("login")) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false
              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** SSO Request Interceptor
    this.ssoAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** SSO response interceptor
    this.ssoAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              // originalRequest.baseURL = process.env.REACT_APP_SSO_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** SSO Add request/response interceptor
    this.globalAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** GLOBAL Request Interceptor
    this.globalAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_GLOBAL_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** HR Request Interceptor
    this.hrAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_HR_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** HR response interceptor
    this.hrAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        let originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_HR_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** MARKETING Request Interceptor
    this.marketingAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_MARKETING_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** MARKETING response interceptor
    this.marketingAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_MARKETING_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** FINANCE Request Interceptor
    this.financeAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_FINANCE_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** FINANCE response interceptor
    this.financeAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_FINANCE_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** FS Request Interceptor
    this.fsAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_FS_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** FS response interceptor
    this.fsAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_FS_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** SIPKDDOC Request Interceptor
    this.sipkdDocAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_SIPKDDOC_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** SIPKDDOC response interceptor
    this.sipkdDocAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_SIPKDDOC_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** LAB Request Interceptor
    this.labAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_LAB_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** LAB response interceptor
    this.labAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_LAB_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** WAREHOUSE Request Interceptor
    this.warehouseAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_WAREHOUSE_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** WAREHOUSE response interceptor
    this.warehouseAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_WAREHOUSE_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** PURCHASING Request Interceptor
    this.purchasingAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_API_PURCHASING_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** PURCHASING response interceptor
    this.purchasingAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_API_PURCHASING_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** PLAIN Request Interceptor
    this.plainAxiosInterceptor.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const refreshToken = this.getRefreshToken()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && !config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${accessToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        if (refreshToken && config?.url?.includes("refresh_token")) {
          config.headers.Authorization = `${
            this.jwtConfig.tokenType
          } ${refreshToken.replaceAll('"', "")}`
          config.baseURL = process.env.REACT_APP_SSO_URL
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    // ** PLAIN response interceptor
    this.plainAxiosInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response?.status === 401) {
          if (!this.isAlreadyFetchingAccessToken && checkLastRefreshToken()) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              this.setRefreshToken(r.data.refresh_token)
              this.onAccessTokenFetched(r.data.access_token)
            })
          } else {
            localStorage.removeItem("userData")
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            setLastLogout()
            window.location = "/login"
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = `${
                this.jwtConfig.tokenType
              } ${accessToken.replaceAll('"', "")}`
              originalRequest.baseURL = process.env.REACT_APP_SSO_URL
              // console.log(originalRequest)
              resolve(axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )

    // ** PLAIN WITHOUT HEADERS Request Interceptor
    // this.plainNoHeadersAxiosInterceptor.interceptors.request.use(
    //   (config) => {
    //     // ** Get token from localStorage
    //     const accessToken = this.getToken()
    //     const refreshToken = this.getRefreshToken()
    //     // ** If token is present add it to request's Authorization Header
    //     if (accessToken && !config?.url?.includes("refresh_token")) {
    //       config.headers.Authorization = `${
    //         this.jwtConfig.tokenType
    //       } ${accessToken.replaceAll('"', "")}`
    //       config.baseURL = process.env.REACT_APP_SSO_URL
    //     }
    //     if (refreshToken && config?.url?.includes("refresh_token")) {
    //       config.headers.Authorization = `${
    //         this.jwtConfig.tokenType
    //       } ${refreshToken.replaceAll('"', "")}`
    //       config.baseURL = process.env.REACT_APP_SSO_URL
    //     }
    //     return config
    //   },
    //   (error) => Promise.reject(error)
    // )

    // ** PLAIN WITHOUT HEADERS response interceptor
    // this.plainNoHeadersAxiosInterceptor.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     const { config, response } = error
    //     const originalRequest = config

    //     if (response?.status === 401 && checkLastRefreshToken()) {
    //       if (!this.isAlreadyFetchingAccessToken) {
    //         this.isAlreadyFetchingAccessToken = true
    //         this.refreshToken().then((r) => {
    //           this.isAlreadyFetchingAccessToken = false

    //           // ** Update accessToken in localStorage
    //           this.setToken(r.data.access_token)
    //           this.setRefreshToken(r.data.refresh_token)
    //           this.onAccessTokenFetched(r.data.access_token)
    //         })
    //       } else {
    //         localStorage.removeItem("userData")
    //         localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    //         localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    //         window.location = "/login"
    //       }
    //       const retryOriginalRequest = new Promise((resolve) => {
    //         this.addSubscriber((accessToken) => {
    //           originalRequest.headers.Authorization = `${
    //             this.jwtConfig.tokenType
    //           } ${accessToken.replaceAll('"', "")}`
    //           originalRequest.baseURL = process.env.REACT_APP_SSO_URL
    //           // console.log(originalRequest)
    //           resolve(axios(originalRequest))
    //         })
    //       })
    //       return retryOriginalRequest
    //     }
    //     return Promise.reject(error)
    //   }
    // )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    )
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  loginG(...args) {
    return axios.post(this.jwtConfig.loginGEndpoint, ...args)
  }

  forgot(...args) {
    return axios.post(this.jwtConfig.forgotEndpoint, ...args)
  }

  resetPwd(...args) {
    const customHeaders = {
      "reset-key": `Bearer ${args?.[0]?.token}`
    }
    return axios.post(this.jwtConfig.resetEndpoint, ...args, {
      headers: customHeaders
    })
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  registerOtp(...args) {
    return axios.post(this.jwtConfig.registerOtpEndpoint, ...args)
  }

  refreshToken() {
    var currentTimestamp = new Date().getTime()
    var dateString = new Date(currentTimestamp).toLocaleString()
    sessionStorage.setItem("lastRefreshToken", dateString)
    return axios.post(this.jwtConfig.refreshEndpoint)
  }
}
