import React, { useState, useEffect } from "react"
import quotes from "./quotes.json"
import { CardText } from "reactstrap"
import { useTranslation } from "react-i18next"

const RandomQuoteLoader = ({ styles, isLogin }) => {
  const [quote, setQuote] = useState("")
  const { i18n } = useTranslation()

  useEffect(() => {
    // console.log(i18n.language, i18n)
    const fetchRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * quotes.length)
      if (i18n.language === "id") {
        setQuote(quotes[randomIndex].text_indonesia)
      } else {
        setQuote(quotes[randomIndex].text)
      }
    }

    let timeoutId

    if (!isLogin) {
      timeoutId = setTimeout(fetchRandomQuote, 500)
    } else {
      fetchRandomQuote()
    }

    const intervalId = setInterval(fetchRandomQuote, 5000)
    return () => {
      if (!isLogin) {
        clearTimeout(timeoutId)
      }
      clearInterval(intervalId)
    }
  }, [])

  return (
    <CardText
      style={{
        // fontFamily: '"roboto", sans-serif',
        fontWeight: 500,
        textAlign: "center",
        fontSize: "1rem",
        ...(styles ?? {})
      }}
      className="mb-0 mt-50 text-secondary"
    >
      {quote}
    </CardText>
  )
}

export default RandomQuoteLoader
