// import { Badge, HStack, Image } from "@chakra-ui/react"
import Avatar from "@components/avatar"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import {
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap"

const TaskListHeader = ({
  pageAttr,
  headerHeight,
  rowWidth,
  fontFamily,
  fontSize
}) => {
  const { t } = useTranslation()
  const defaultWidth = 400
  const expanderWidthSize = 35
  //   console.log(pageAttr)

  const getColumnTitle = (fieldName) => {
    try {
      const columnTitle = fieldName.replace(/([A-Z])/g, " $1")
      let columnTitleEnd =
        columnTitle?.charAt(0)?.toUpperCase() + columnTitle?.slice(1)
      columnTitleEnd = columnTitleEnd?.replaceAll("_", " ")
      return columnTitleEnd
    } catch (error) {
      return fieldName ?? ""
    }
  }

  const getColumnWidthPercentage = (sizePercentStr) => {
    let sizeFix = 0
    if (sizePercentStr?.includes("%")) {
      // const sizePercent = parseFloat(sizePercentStr) / 100.0
      // sizeFix = `${defaultWidth * sizePercent}px`
      // sizeFix = sizePercentStr
      const sizePercent = sizePercentStr.replace("%")
      const sizePercentInt = parseInt(sizePercent)
      sizeFix = `${sizePercentInt - 8}%`
    } else {
      sizeFix = sizePercentStr
    }
    return sizeFix
  }

  const renderDtColumnProp = (column, index) => {
    const title = t(`${column.columnName ?? getColumnTitle(column.fieldName)}`)
    return title
    return (
      <div
        key={`gantt_header_${column?.fieldName ?? index}`}
        style={{
          fontSize: fontSize ?? 10,
          fontFamily: fontFamily ?? 10,
          fontWeight: "bold",
          width: column.width
            ? getColumnWidthPercentage(column.width)
            : "unset",
          maxWidth: column?.maxWidth ?? "100%",
          minWidth: column?.minWidth ?? "20px"
        }}
        // breakPoint={{ xs: 4 }}
      >
        {title}
      </div>
    )
  }

  return (
    <div
      className={"ganttTable"}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        width: defaultWidth
      }}
    >
      <div
        className={"ganttTable_Header "}
        style={{
          height: headerHeight - 1
        }}
      >
        <div
          style={{
            width: "1.25rem",
            borderRight: "none !important"
          }}
        ></div>
        {pageAttr?.crudOpt?.data?.dynamicColumns?.map((column, index) => (
          <Fragment>
            <div
              className={"ganttTable_HeaderItem ps-50"}
              style={{
                // minWidth: rowWidth,
                fontSize: fontSize ?? 10,
                fontFamily: fontFamily ?? 10,
                fontWeight: "bold",
                paddingLeft: "1rem",
                width: column.width
                  ? getColumnWidthPercentage(column.width)
                  : "auto"
                // maxWidth: column?.maxWidth ?? "100%",
                // minWidth: column?.minWidth ?? "20px"
              }}
            >
              {renderDtColumnProp(column, index)}
            </div>

            {/* <div
              className={"ganttTable_HeaderSeparator"}
              style={{
                height: headerHeight * 0.5,
                marginTop: headerHeight * 0.2
              }}
            ></div> */}
          </Fragment>
        ))}
      </div>
    </div>
  )

  {
    /* return (
    <div
      style={{
        width: defaultWidth,
        marginTop: 18,
        padding: "0 14px",
        display: "flex"
      }}
    >
      {pageAttr?.crudOpt?.data?.dynamicColumns?.map((column, index) =>
        renderDtColumnProp(column, index)
      )}
    </div>
  ) */
  }
}

export default TaskListHeader
