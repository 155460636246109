export const BUILD_DATE = "20241007-1115"
export const CHANGES_LOG = `- 2024-10-07 11:15:11 | fixed: show map modal on click marker
- 2024-10-02 14:09:17 | aset sewa
- 2024-10-02 14:09:10 | perubahan hr
- 2024-10-01 15:06:09 | Merge branch 'mixed_all_branch' of https://github.com/amry666/insaba_web_react_starter into mixed_all_branch
- 2024-10-01 15:06:04 | update checkin sample untuk dari client
- 2024-10-01 11:23:12 | commmit
- 2024-10-01 11:07:19 | commit
- 2024-09-23 14:53:00 | fixed dashboard page issue
- 2024-09-23 14:49:50 | Treeview auto expand on fetch, fixed dashboard page issues
- 2024-09-21 20:59:30 | chat refresh when incoming new and when app active
- 2024-09-20 22:25:00 | fixed some widgets issues
- 2024-09-20 14:02:15 | read attribute from domain in login page. appHeaderLinks (array) = embed links to append in app html header like google font,  layoutType = for layout position logo,  appLogoTextStyle (obj) = for style in app logo Text
- 2024-09-20 11:17:25 | ctrl+s save on JSON editor,  restrict add menus below homepage & playground, hide Save btn in FW site homepage,  fixed size widget activity, fixed hang issues when clicked map marker,  fixed rename menu if menu is separator,
- 2024-09-19 22:17:21 | widget user login growth show to user with user management permission.
- 2024-09-19 15:39:06 | fixed menu style overflow issues & add new widget user login growth only for developer
- 2024-09-18 12:13:54 | fixed issues on expandable as tab with getAlwaysGrid
- 2024-09-17 15:20:48 | remove error on map
- 2024-09-17 12:21:43 | add nested type page "endpointByLevel" & fixed map init bound issues. sample on  http://localhost:7010/dyn_pages/playground/dataOther9
- 2024-09-15 13:10:24 | +New Features : input type "domainData" & input type "current_location". see implementation on https://saba-framework.gitbook.io/saba-framework-documentation/examples/dynamic-form/input-type/domain-claims-data
- 2024-09-14 11:46:58 | fixed style in main menu text-overflow`
