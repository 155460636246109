// ** Third Party Components
import { useTranslation } from "react-i18next"
import { Fragment, useEffect, useState } from "react"
import {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden
} from "@store/layout"
import { useDispatch, useSelector } from "react-redux"
import { useNavbarType } from "@hooks/useNavbarType"
import { useFooterType } from "@hooks/useFooterType"

// ** Reactstrap Imports
import {
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap"
import { Tv } from "@mui/icons-material"

import { renderIcon } from "@src/views/apps/menu/store/my_menu"
const LayoutButton = () => {
  // ** Hooks
  const { i18n } = useTranslation()

  const [layoutMode, setLayoutMode] = useState("1")
  const dispatch = useDispatch()
  const setMenuCollapsed = (val) => dispatch(handleMenuCollapsed(val))
  const setIsHidden = (val) => dispatch(handleMenuHidden(val))

  const [isFullscreen, setIsFullscreen] = useState(false)

  const requestFullscreen = () => {
    const element = document.documentElement

    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }

    setIsFullscreen(true)
  }

  const exitFullscreen = () => {
    setIsHidden(false)
    localStorage.setItem("menuHidden", false)

    setMenuCollapsed(false)
    localStorage.setItem("menuCollapsed", false)

    setLayoutMode("1")
    localStorage.setItem("layoutMode", "1")

    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }

      setIsFullscreen(false)
    }
  }

  useEffect(() => {
    setLayoutMode(localStorage.getItem("layoutMode") ?? "1")

    const handleKeydown = (event) => {
      if (document.fullscreenElement === null) {
        // console.log("hit")
        exitFullscreen()
      }
    }

    document.addEventListener("fullscreenchange", handleKeydown)
    return () => {
      document.removeEventListener("fullscreenchange", handleKeydown)
    }
  }, [layoutMode, isFullscreen])

  // ** Function to switch Language
  const layoutEventChange = (e) => {
    e.preventDefault()
    let layoutValue = "1"
    let menuCollapsed = false
    let menuHidden = false
    let fullsrcreenState = false

    if (layoutMode === "1") {
      layoutValue = "2"
      menuCollapsed = true
      menuHidden = true
      fullsrcreenState = true
    }

    localStorage.setItem("layoutMode", layoutValue)
    setLayoutMode(layoutValue)

    localStorage.setItem("menuCollapsed", menuCollapsed)
    setMenuCollapsed(menuCollapsed)

    localStorage.setItem("menuHidden", menuHidden)
    setIsHidden(menuHidden)

    if (fullsrcreenState) {
      requestFullscreen()
    } else {
      exitFullscreen()
    }

    setLayoutMode(layoutValue)

    // console.log("event change")
  }

  return (
    <Fragment>
      <NavItem className="d-md-block d-none">
        <NavLink className="" onClick={(e) => layoutEventChange(e)}>
          {layoutMode === "1"
            ? renderIcon("material:tv", "ficon bookmark-icon23", 20)
            : renderIcon(
                "material:laptop_chromebook",
                "ficon bookmark-icon23",
                20
              )}
        </NavLink>
      </NavItem>
    </Fragment>
  )
}

export default LayoutButton
