// ** React Imports
import { NavLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
// ** Third Party Components
import classnames from "classnames"
import { useTranslation } from "react-i18next"
import { useLastMenuTabs } from "@hooks/useLastMenuTabs"
import { isAdminApp, restrictedMenuLink } from "@utils"
// ** Reactstrap Imports
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap"
import { Fragment, useState, useContext, useEffect } from "react"
import { MoreVertical, Plus } from "react-feather"
// import { createNewMenu } from "@store/developer"
import {
  createNewMenu,
  createNewSubMenu,
  deleteMenu,
  renderIcon
} from "@src/views/apps/menu/store/my_menu"
import { setStorePageAttributes } from "@src/shared/dynamic_pages/store"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import toast from "react-hot-toast"
import ModalAddEdit from "./modal"
const MySwal = withReactContent(Swal)
import { ThemeColors } from "@src/utility/context/ThemeColors"

const VerticalNavMenuLink = ({
  item,
  activeItem,
  menuHover,
  menuCollapsed,
  devMode,
  devModeSub
}) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? "a" : NavLink
  const dispatch = useDispatch()
  const dynamicStore = useSelector((state) => state.dynamicStore)
  // ** Hooks
  const { t } = useTranslation()
  const { lastMenuTabs, setLastMenuTabs } = useLastMenuTabs()
  const storeMyMenu = useSelector((state) => state.my_menu)

  const [currentMenuData, setCurrentMenuData] = useState(null)
  const [currentOriginalMenuData, setCurrentOriginalMenuData] = useState(null)
  const [showModalRename, setShowModalRename] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const { colors } = useContext(ThemeColors)
  const navigate = useNavigate()

  const createNewMenuHandle = (e, item) => {
    e.preventDefault()
    // console.log(`createNewMenu`, item, devModeSub)
    dispatch(createNewMenu({ item, isSubMenu: devModeSub })).then((res) => {
      // console.log(res)
      if (dynamicStore && res.meta.requestStatus === "fulfilled") {
        const newStoreName = res?.payload?.storeName
        const pageTitle = res?.payload?.pageTitle
        const defaultPageAttr = {
          pageType: "crud",
          pageTitle: pageTitle,
          crudOpt: {}
        }
        dispatch(
          setStorePageAttributes({
            storeName: newStoreName,
            data: defaultPageAttr
          })
        ).then((data) => {
          // navigate(res?.payload?.routePath)
        })
      }
    })
  }

  useEffect(() => {
    document.body.style.paddingRight = "0"
    return () => {
      document.body.style.paddingRight = ""
    }
  }, [])

  const doRename_before = (e, item) => {
    e.preventDefault()
    // console.log(e, item)
    setCurrentMenuData(item)

    const itemInStore = storeMyMenu?.dataMyMenu?.find(
      (i) => i?.id === parseInt(item.id?.replace("menu_", ""))
    )
    setCurrentOriginalMenuData(itemInStore)
    setShowModalRename(!showModalRename)
  }

  const addChild = (e, item) => {
    e.preventDefault()
    dispatch(createNewSubMenu(item)).then((res) => {
      // console.log(res)
      if (dynamicStore && res.meta.requestStatus === "fulfilled") {
        const newStoreName = res?.payload?.storeName
        const pageTitle = res?.payload?.pageTitle
        const defaultPageAttr = {
          pageType: "crud",
          pageTitle: pageTitle,
          crudOpt: {}
        }
        dispatch(
          setStorePageAttributes({
            storeName: newStoreName,
            data: defaultPageAttr
          })
        ).then((data) => {
          // navigate(res?.payload?.routePath)
        })
      }
      // const parent_id = parseInt(item.id?.replace("menu_", ""))
      // const itemInStore = storeMyMenu?.dataMyMenu?.find(
      //   (i) => i?.id === parent_id
      // )
    })
  }

  const editJsonAttr = (e, item) => {
    e.preventDefault()
    // console.log(e, item)
    // console.log(item, itemInStore)
    navigate(`${item?.navLink}?dev_mode_json=true`)
    // setCurrentOriginalMenuData(itemInStore)
  }

  const doDelete_before = async (e, item) => {
    e.preventDefault()
    // console.log(e, item)
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} Menu!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} Menu!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      // setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      dispatch(deleteMenu(item)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.success(`${t("Successfully deleted")} Menu`, {
            style: {
              padding: "16px",
              color: colors.success.main,
              border: `1px solid ${colors.success.main}`
            },
            iconTheme: {
              primary: colors.success.main
            }
          })

          setShowModalDelete(!showModalDelete)
          navigate("/dashboard")
        } else {
          toast.error(res?.payload?.message ?? t("Error"), {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }
  // console.log(activeItem)
  return (
    <li
      data-id={item?.id}
      data-parent-id={item?.parent_id}
      className={classnames({
        "nav-item": !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem,
        "d-none":
          !devMode &&
          !devModeSub &&
          (item?.id === "0000" || item?.id?.includes("000")),
        "dev-mode": devMode,
        "dev-mode-sub": devModeSub,
        "disabled-drag":
          item?.id === "0000" ||
          item?.id?.includes("000") ||
          restrictedMenuLink?.includes(item?.navLink) ||
          ((item?.navLink?.includes("/dyn_pages/_admin_") ||
            item?.navLink?.includes("/apps/menu")) &&
            !isAdminApp())
      })}
    >
      <Fragment>
        {(devMode || devModeSub) &&
          !(
            (item?.navLink?.includes("/dyn_pages/_admin_") ||
              item?.navLink?.includes("/apps/menu") ||
              restrictedMenuLink?.includes(item?.navLink)) &&
            !isAdminApp()
          ) && (
            <Fragment>
              <div className="dev-mode-plus">
                <Button.Ripple
                  onClick={(e) => createNewMenuHandle(e, item)}
                  className="btn-icon rounded-circle"
                  color="primary"
                >
                  <Plus className="align-middle" size={12} />
                </Button.Ripple>
              </div>

              {item?.id !== "0000" && !item?.id?.includes("000") && (
                <UncontrolledButtonDropdown className="dropdown-icon-wrapper btn-icon btn-sm dev-mode-other">
                  <DropdownToggle
                    color="flat-secondary"
                    style={
                      item.navLink === activeItem
                        ? {
                            color: "#FFF"
                          }
                        : {}
                    }
                    caret
                    className="btn-icon btn-sm dropdown-toggle-split"
                  >
                    <MoreVertical className="align-middle" size={12} />
                  </DropdownToggle>
                  <DropdownMenu tag="div" end>
                    <DropdownItem
                      onClick={(e) => doRename_before(e, item)}
                      href="/"
                      tag="a"
                    >
                      {renderIcon(
                        "material:drive_file_rename_outline",
                        "align-middle me-25",
                        18
                      )}
                      {t("Rename")}
                    </DropdownItem>
                    {!devModeSub && (
                      <DropdownItem
                        onClick={(e) => addChild(e, item)}
                        href="/"
                        tag="a"
                      >
                        {renderIcon(
                          "material:subdirectory_arrow_right",
                          "align-middle me-25",
                          18
                        )}
                        {t("Add Child")}
                      </DropdownItem>
                    )}
                    <DropdownItem
                      onClick={(e) => editJsonAttr(e, item)}
                      href="/"
                      tag="a"
                    >
                      {renderIcon("material:code", "align-middle me-25", 18)}
                      JSON
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => doDelete_before(e, item)}
                      href="/"
                      tag="a"
                    >
                      {renderIcon(
                        "material:delete_outline",
                        "align-middle me-25",
                        18
                      )}
                      {t("Delete")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}
            </Fragment>
          )}
        <LinkTag
          className="d-flex align-items-center"
          target={item.newTab ? "_blank" : undefined}
          /*eslint-disable */
          {...(item.externalLink === true
            ? {
                href: item.navLink || "/"
              }
            : {
                to: item.navLink || "/",
                className: ({ isActive }) => {
                  if (isActive && !item.disabled) {
                    return "d-flex align-items-center active"
                  } else {
                    return "d-flex align-items-center"
                  }
                }
              })}
          onClick={(e) => {
            if (item.id !== "0000" && !item?.id?.includes("000")) {
              setLastMenuTabs(item)

              // set permissionname Can for component
              sessionStorage.setItem("current_page_perm", item.resource)
            }

            if (
              item.navLink.length === 0 ||
              item.navLink === "#" ||
              item.disabled === true
            ) {
              e.preventDefault()
            }
          }}
        >
          {item.icon}
          <span
            style={
              ({
                color:
                  item?.id === "0000" || item?.id?.includes("000")
                    ? "transparent"
                    : "inherit"
              },
              !menuCollapsed || menuHover
                ? {
                    whiteSpace: "normal",
                    overflowWrap: "anywhere"
                  }
                : {})
            }
            className={`menu-item align-middle ${
              menuCollapsed && !menuHover ? "text-truncate" : ""
            }`}
          >
            {t(item.title)}
          </span>

          {item.badge && item.badgeText ? (
            <Badge className="ms-auto me-1" color={item.badge} pill>
              {item.badgeText}
            </Badge>
          ) : null}
        </LinkTag>

        {showModalRename ? (
          <ModalAddEdit
            show={showModalRename}
            setShow={setShowModalRename}
            data={currentOriginalMenuData}
          />
        ) : null}
      </Fragment>
    </li>
  )
}

export default VerticalNavMenuLink
