export const mainProperties = {
  default: {
    size: 12
  },
  pageTitle: {
    size: 8,
    fieldLabel: "Page Title"
  },
  pageModuleTitle: {
    size: 4,
    fieldLabel: "Module Name"
  },
  pageDesc: {
    size: 12,
    type: "textarea",
    fieldLabel: "Page Description"
  },
  crudOpt: {
    gridTheme: {
      size: 4,
      type: "dropdown",
      fieldLabel: "Element Type",
      required: true,
      // initialValueFromOther: "crudOpt.gridTheme",
      initialValue: "table",
      dataFrom: {
        value: [
          {
            value: "table",
            label: "Table",
            icon: "fontAwesome:table-list"
          },
          {
            value: "grid_card",
            label: "Grid",
            icon: "fontAwesome:grip"
          }
        ]
      }
    },
    sourceDataFrom: {
      fieldLabel: "Data Source From",
      size: 8,
      required: true,
      type: "dropdown",
      // initialValue: 1,
      dataFrom: {
        value: [
          {
            value: 1,
            label: "Api Endpoint (Saba Framework Python)",
            icon: "material:api"
          },
          {
            value: 2,
            label: "Api Endpoint",
            icon: "material:api"
          },
          {
            value: 3,
            label: "Static",
            icon: "material:data_array"
          },
          {
            value: 4,
            label: "File Upload",
            icon: "material:upload_file"
          }
        ],
        labelTranslations: true
      }
    },
    endpointName: {
      size: 12,
      fieldLabel: "Endpoint Url",
      required: true,
      showIf: [
        {
          fieldName: "sourceDataFrom",
          operator: "in",
          value: [1, 2]
        }
      ],
      rules: {
        pattern: {
          value:
            "^(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]+.[^ ]+|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]+.[^ ]+|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^ ]+|www.[a-zA-Z0-9]+.[^ ]+)/$",
          message:
            "enter valid url 'http://test.co.id/', diawali 'http://' atau 'https://' + 'example.com' or 'localhost:8080' or 'example.co.id'"
        }
      }
    },
    removeDefaultHttpHeader: {
      type: "radio",
      fieldLabel: "Remove Default Http Header",
      size: 4,
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary",
            icon: "material:check_circle_outline",
            selected: true
          },
          {
            value: false,
            label: "No",
            color: "secondary",
            icon: "material:highlight_off"
          }
        ],
        labelTranslations: true
      },
      showIf: [
        {
          fieldName: "sourceDataFrom",
          operator: "===",
          value: 2
        }
      ],
      tooltip:
        "Jika diatur sebagai true, maka semua metode permintaan data ke aplikasi backend akan menghilangkan header bawaan dari Saba Framework."
    },
    resp_key: {
      fieldLabel: "Response Key Selector",
      size: 4,
      showIf: [
        {
          fieldName: "sourceDataFrom",
          operator: "===",
          value: 2
        }
      ],
      tooltip:
        "tentukan key dari respon yang berisi baris data berupa array (multiple) untuk ditampilkan pada aplikasi. Jika key adalah nested kamu dapat menyertakan separator '.' seperti response.data.array"
    },
    data_idField: {
      fieldLabel: "Custom ID field",
      size: 4,
      showIf: [
        {
          fieldName: "sourceDataFrom",
          operator: "===",
          value: 2
        }
      ],
      tooltip: "tentukan key untuk ID (unik) dari data respon."
    },
    gridSizeLg: {
      fieldLabel: "Grid Size (Large Screen)",
      size: 3,
      type: "number-btn",
      initialValue: 3,
      rules: {
        maxLength: 12,
        minLength: 1
      },
      tooltip:
        "Total Ukuran Layar Penuh: 12. Contoh, jika nilai diatur menjadi 3, maka setiap bagian layar akan memiliki lebar 4, menampilkan 4 card dalam satu baris."
    },
    gridSizeMd: {
      fieldLabel: "Grid Size (Med Screen)",
      size: 3,
      type: "number-btn",
      initialValue: 6,
      rules: {
        maxLength: 12,
        minLength: 1
      },
      tooltip:
        "Total Ukuran Layar Penuh: 12. Contoh, jika nilai diatur menjadi 3, maka setiap bagian layar akan memiliki lebar 4, menampilkan 4 card dalam satu baris."
    },
    gridSizeSm: {
      fieldLabel: "Grid Size (Small Screen)",
      size: 3,
      rules: {
        maxLength: 12,
        minLength: 1
      },
      type: "number-btn",
      initialValue: 12,
      tooltip:
        "Total Ukuran Layar Penuh: 12. Contoh, jika nilai diatur menjadi 3, maka setiap bagian layar akan memiliki lebar 4, menampilkan 4 card dalam satu baris."
    },
    gridImgField: {
      fieldLabel: "Image Field Name",
      size: 3,
      tooltip:
        "Nama Key dari response yang berisi path/url gambar, akan ditampilkan sebagai gambar utama dari setiap card."
    },
    gridTitleField: {
      fieldLabel: "Title Field Name",
      size: 3,
      tooltip:
        "Nama Key dari response yang akan ditampilkan sebagai judul utama dari setiap card."
    },
    gridSubTitleField: {
      fieldLabel: "Subtitle Field Name",
      size: 3,
      tooltip:
        "Nama Key dari response yang akan ditampilkan sebagai sub judul utama dari setiap card."
    },
    detailFromGrid: {
      fieldLabel: "Detail data from row",
      size: 3,
      // inLineLabel: true,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary",
            icon: "material:check_circle_outline"
          },
          {
            value: false,
            label: "No",
            color: "secondary",
            icon: "material:highlight_off"
          }
        ],
        labelTranslations: true
      },
      tooltip:
        "Secara default data yang akan ditampilkan pada semua form di halaman detail (Add/Edit/View) akan diambil dari data baris yang di klik. Set ke false jika kamu ingin aplikasi melakukan request lagi ke backend, request dari aplikasi seperti ini method: GET => {endPointName}/{id}"
    },
    detailPage: {
      fieldLabel: "Add/Edit Type",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Page",
            color: "primary"
          },
          {
            value: false,
            label: "Modal",
            color: "success"
          }
        ],
        labelTranslations: true
      },
      tooltip:
        "Secara default Halaman detail ketika klik tombol Add/Edit/View akan menampilkan sebuah modal. Set ke Ya jika kamu ingin mengganti menjadi sebuah halaman."
    },

    disabledAdd: {
      fieldLabel: "Disable Add",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      tooltip: "Jika di set ke true maka tombol Tambah akan hilang."
    },
    disabledEdit: {
      fieldLabel: "Disable Edit",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      tooltip: "Jika di set ke true maka tombol Edit akan hilang."
    },
    disabledDelete: {
      fieldLabel: "Disable Delete",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      tooltip:
        "Jika di set ke true maka tombol Delete akan hilang. Jika property deleteSelection (multiple-delete) true, maka checklist di setiap baris akan hilang/tidak aktif."
    },
    hideTablePageLengthInfo: {
      fieldLabel: "Hide Data Length",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      tooltip:
        "Jika di set ke true maka dropdown untuk menyaring jumlah data yang ditampilkan akan hilang."
    },
    hideTablePagination: {
      fieldLabel: "Hide Pagination",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      tooltip: "Jika di set ke true maka pagination akan hilang."
    },
    hideTableSearchInput: {
      fieldLabel: "Hide Search",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      tooltip: "Jika di set ke true maka input pencarian akan hilang."
    },
    getAlwaysGrid: {
      fieldLabel: "Always Get Data",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: true,
            label: "Yes",
            color: "primary"
          },
          {
            value: false,
            label: "No"
          }
        ]
      },
      tooltip:
        "Jika di set ke true maka akan data akan selalu diminta ke api backend ketika menu di klik."
    },
    rowClickEvent: {
      fieldLabel: "Show Button View",
      size: 3,
      type: "radio",
      dataFrom: {
        value: [
          {
            value: "view",
            label: "Yes",
            color: "primary"
          },
          {
            value: null,
            label: "No"
          }
        ]
      },
      tooltip: "Memunculkan Tombol View pada Row / Grid"
    }
  }
}
