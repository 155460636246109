// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig"

const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

const initialMenuHidden = () => {
  const item = window.localStorage.getItem("menuHidden")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isHidden
}

const initialDirection = () => {
  const item = window.localStorage.getItem("direction")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.isRTL
}

const initialSkin = () => {
  const item = window.localStorage.getItem("skin")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.skin
}

const initialContentWidth = () => {
  const item = window.localStorage.getItem("contentWidth")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.contentWidth
}

const initialNavbarType = () => {
  // const item = window.localStorage.getItem("navbar")
  // //** Parse stored json or if none return initialValue
  // return item ? JSON.parse(item)?.type : themeConfig.layout.navbar.type

  const item = window.localStorage.getItem("navbarType")
  return item ? JSON.parse(item) : themeConfig.layout.navbar.type
}

const initialNavbarColor = () => {
  // const item = window.localStorage.getItem("navbar")
  // //** Parse stored json or if none return initialValue
  // return item
  //   ? JSON.parse(item)?.backgroundColor ?? item
  //   : themeConfig.layout.navbar.backgroundColor

  const item = window.localStorage.getItem("navbarColor")
  return item ? JSON.parse(item) : themeConfig.layout.navbar.backgroundColor
}

const initialFooterType = () => {
  const item = window.localStorage.getItem("footerType")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.footer.type
}

const initialLastMenuRemember = () => {
  const item = window.localStorage.getItem("lastMenuChachedState")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : false
}

const initWidgetVisibility = () => {
  const item = window.localStorage.getItem("widgetVisibility")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : { greeting: true, announcements: true }
}

const initialLastMenuTabs = () => {
  const item = window.sessionStorage.getItem("lastMenuChached")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : []
}

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    skin: initialSkin(),
    isRTL: initialDirection(),
    layout: themeConfig.layout.type,
    lastLayout: themeConfig.layout.type,
    menuCollapsed: initialMenuCollapsed(),
    footerType: initialFooterType(),
    navbarType: initialNavbarType(),
    // menuHidden: themeConfig.layout.menu.isHidden,
    menuHidden: initialMenuHidden(),
    contentWidth: initialContentWidth(),
    navbarColor: initialNavbarColor(),
    lastMenuRemember: initialLastMenuRemember(),
    widgetVisibility: initWidgetVisibility(),
    lastMenuTabs: initialLastMenuTabs(),
    lastMenuTabsLoding: false
  },
  reducers: {
    handleRTL: (state, action) => {
      state.isRTL = action.payload
      window.localStorage.setItem("direction", JSON.stringify(action.payload))
    },
    handleSkin: (state, action) => {
      state.skin = action.payload
      window.localStorage.setItem("skin", JSON.stringify(action.payload))
    },
    handleSkinLogin: (state, action) => {
      state.skin = action.payload
    },
    handleLayout: (state, action) => {
      state.layout = action.payload
    },
    handleFooterType: (state, action) => {
      state.footerType = action.payload
      window.localStorage.setItem("footerType", JSON.stringify(action.payload))
    },
    handleNavbarType: (state, action) => {
      state.navbarType = action.payload
      window.localStorage.setItem("navbarType", JSON.stringify(action.payload))
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload
      window.localStorage.setItem("menuHidden", JSON.stringify(action.payload))
    },
    handleLastLayout: (state, action) => {
      state.lastLayout = action.payload
    },
    handleNavbarColor: (state, action) => {
      state.navbarColor = action.payload
      window.localStorage.setItem("navbarColor", JSON.stringify(action.payload))
    },
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload
    },
    handleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload
      window.localStorage.setItem(
        "menuCollapsed",
        JSON.stringify(action.payload)
      )
    },
    handleLastMenuRemember: (state, action) => {
      state.lastMenuRemember = action.payload
      window.localStorage.setItem(
        "lastMenuChachedState",
        JSON.stringify(action.payload)
      )
    },
    handleLastMenuTabs: (state, action) => {
      state.lastMenuTabs = action.payload
      window.sessionStorage.setItem(
        "lastMenuChached",
        JSON.stringify(action.payload)
      )
    },
    handleWidgetVisiblity: (state, action) => {
      state.widgetVisibility = action.payload
      window.localStorage.setItem(
        "widgetVisibility",
        JSON.stringify(action.payload)
      )
    }
  }
})

export const {
  handleRTL,
  handleSkin,
  handleSkinLogin,
  handleLayout,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handleFooterType,
  handleNavbarColor,
  handleContentWidth,
  handleMenuCollapsed,
  handleLastMenuRemember,
  handleLastMenuTabs,
  handleWidgetVisiblity
} = layoutSlice.actions

export default layoutSlice.reducer
