import { useEffect, useState, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
// ** Reactstrap Imports
import {
  Badge,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from "reactstrap"
import { setEditMode } from "@store/developer"
// ** Utils
import { isUserLoggedIn } from "@utils"
import { useTranslation } from "react-i18next"
import { Bolt, ExitToApp } from "@mui/icons-material"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
const SuperOrDevBadge = () => {
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)
  const store = useSelector((state) => state.developer)
  const { t } = useTranslation()

  const setEditModeState = (e) => {
    e.preventDefault()
    dispatch(setEditMode()).then(() => {})
  }

  const [sourceUrl, setSourceUrl] = useState("")
  const [destUrl, setDestUrl] = useState("")
  const [isInput1Filled, setIsInput1Filled] = useState(false)

  useEffect(() => {
    // Memeriksa localStorage saat komponen dimuat
    const endpointUrls =
      JSON.parse(localStorage.getItem("endpointUrlReplacer")) || []

    // Mengisi nilai input jika localStorage memiliki nilai
    if (endpointUrls.length >= 2) {
      setSourceUrl(endpointUrls[0])
      setDestUrl(endpointUrls[1])
      setIsInput1Filled(!!endpointUrls[0]) // Mengatur status input 1 terisi jika ada nilai
    }
  }, [])

  const resetUrlReplacement = () => {
    setSourceUrl("")
    setDestUrl("")
    localStorage.removeItem("endpointUrlReplacer")
  }

  const handleSourceUrlChange = (event) => {
    const value = event.target.value
    setSourceUrl(value)
    setIsInput1Filled(!!value) // Mengatur status input 1 terisi

    // Simpan perubahan ke localStorage jika kedua input sudah diisi
    if (value && (destUrl || destUrl === "")) {
      localStorage.setItem(
        "endpointUrlReplacer",
        JSON.stringify([value, destUrl])
      )
    } else if (
      !value ||
      value === "" ||
      ((!value || value === "") && (!destUrl || destUrl === ""))
    ) {
      // Hapus localStorage jika sourceUrl kosong atau kedua input kosong
      localStorage.removeItem("endpointUrlReplacer")
    }
  }

  const handleDestUrlChange = (event) => {
    const value = event.target.value
    setDestUrl(value)

    // Simpan perubahan ke localStorage jika kedua input sudah diisi
    if (sourceUrl && (value || value === "")) {
      localStorage.setItem(
        "endpointUrlReplacer",
        JSON.stringify([sourceUrl, value])
      )
    } else if (
      !sourceUrl ||
      sourceUrl === "" ||
      ((!sourceUrl || sourceUrl === "") && (!value || value === ""))
    ) {
      // Hapus localStorage jika sourceUrl kosong atau kedua input kosong
      localStorage.removeItem("endpointUrlReplacer")
    }
  }

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")))
    }
  }, [])

  if (userData?.isDeveloper || userData?.isSuperUser) {
    return userData.isSuperUser ? (
      <NavItem className="d-lg-flex d-none">
        <NavLink className="nav-link-style">
          <Badge color="danger">
            {renderIcon("material:bolt", "align-middle me-25", 18)}
            {/* <Bolt className="align-middle me-25" /> */}
            <span className="align-middle">SuperUser</span>
          </Badge>
        </NavLink>
      </NavItem>
    ) : (
      <Fragment>
        {/* {store.editMode && !store.editModeJson ? (
          <Button
            onClick={(e) => setEditModeJsonState(e)}
            color="dark"
            className="btn-sm"
          >
            {renderIcon("material:data_object", "align-middle", 12)}
            <span className="align-middle ms-25">JSON</span>
          </Button>
        ) : (
          store.editMode && (
            <Button
              onClick={(e) => setEditModeJsonState(e)}
              color="success"
              className="btn-sm"
            >
              {renderIcon("material:data_object", "align-middle", 12)}
              <span className="align-middle ms-25">JSON</span>
            </Button>
          )
        )} */}

        <UncontrolledDropdown
          href="/"
          tag="li"
          className="dropdown-notification nav-item d-lg-flex d-none"
        >
          <DropdownToggle
            href="/"
            tag="a"
            className="nav-link"
            onClick={(e) => e.preventDefault()}
          >
            {store.editMode ? (
              <Fragment>
                <Badge
                  style={{
                    boxShadow: "0 0 10px 1px rgba(255 32 34 / 70%)"
                  }}
                  xs={12}
                  color="danger"
                >
                  {renderIcon("material:bolt", "align-middle me-25", 18)}
                  <span className="selected-language fw-bolder align-middle me-50">
                    {t("Developer Mode")}
                  </span>
                </Badge>
              </Fragment>
            ) : (
              <Fragment>
                <Badge xs={12} color="light-secondary">
                  {renderIcon(
                    "material:admin_panel_settings",
                    "align-middle me-25",
                    13
                  )}
                  <span className="selected-language align-middle">
                    {t("Developer")}
                  </span>
                </Badge>
              </Fragment>
            )}
          </DropdownToggle>

          <DropdownMenu tag="ul" className="dropdown-menu-media mt-0" end>
            <li className="dropdown-menu-header">
              <DropdownItem className="d-flex" tag="div" header>
                <h4 className="notification-title mb-0 me-auto">
                  {t("Developer Menu")}
                </h4>
              </DropdownItem>
            </li>

            <li className="media-list">
              {!store.editMode ? (
                <a
                  href="/"
                  className="d-flex"
                  onClick={(e) => setEditModeState(e)}
                  style={{ color: "inherit" }}
                >
                  <div className="list-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <Bolt size={18} className="country-flag mb-0 mt-0" />
                      <div className="ms-1 me-1">
                        {t("Developer Mode")}
                        <div className="">
                          <small>{t("Activate Developer Mode")}</small>
                        </div>
                      </div>
                    </div>
                    <div className="form-check form-switch form-check-secondary">
                      <Input
                        type="switch"
                        name="dev-mode-switch-name-off"
                        id="dev-mode-switch-off"
                        disabled
                      />
                    </div>
                  </div>
                </a>
              ) : (
                <a
                  href="/"
                  className="d-flex"
                  onClick={(e) => setEditModeState(e)}
                  style={{ color: "inherit" }}
                >
                  <div className="list-item d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <ExitToApp size={18} className="country-flag mb-0 mt-0" />
                      <div className="ms-1 me-1">
                        {t("Exit Developer Mode")}
                        <div className="">
                          <small>{t("Exit From Developer Mode")}</small>
                        </div>
                      </div>
                    </div>
                    <div className="form-check form-switch form-check-danger">
                      <Input
                        type="switch"
                        name="dev-mode-switch-name-on"
                        id="dev-mode-switch-on"
                        defaultChecked={true}
                        disabled
                      />
                    </div>
                  </div>
                </a>
              )}
            </li>

            <li className="media-list">
              <div className="list-item d-flex align-items-center">
                <div className="list-item-body flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="media-heading">
                      <div className="fw-bolder">{t("Endpoint Replacer")}</div>
                      <small className="notification-text">
                        {t(
                          "Replace endpoint url in all page attribute properties. This feature is temporary and does not change the original properties and is only active in the browser you are using"
                        )}
                        .
                      </small>
                      {sourceUrl && (
                        <Button.Ripple
                          onClick={resetUrlReplacement}
                          size="sm"
                          color="flat-danger"
                        >
                          {t("Reset")}
                        </Button.Ripple>
                      )}
                    </p>
                    {/* <div className="form-check form-switch form-check-danger">
                      <Input
                        type="switch"
                        name="url-replacer-switch-name-on"
                        id="url-replacer-switch-on"
                        disabled={!isInput1Filled}
                      />
                    </div> */}
                  </div>
                  <div className="form-floating" style={{ marginTop: "1rem" }}>
                    <Input
                      type="text"
                      placeholder={t("URL to be replace")}
                      id="source_url"
                      name="source_url"
                      value={sourceUrl}
                      onChange={handleSourceUrlChange}
                    />
                    <label htmlFor="source_char">
                      {t("URL to be replace")}
                    </label>
                  </div>

                  <div className="form-floating" style={{ marginTop: "2rem" }}>
                    <Input
                      placeholder={t("URL replacement")}
                      type="text"
                      id="dest_url"
                      name="dest_url"
                      value={destUrl}
                      onChange={handleDestUrlChange}
                    />
                    <label htmlFor="dest_char">{t("URL replacement")}</label>
                  </div>
                </div>
              </div>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    )
  } else {
    return null
  }
}

export default SuperOrDevBadge
