// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import useJwt from "@src/auth/jwt/useJwt"
import { isUserLoggedIn } from "@utils"
import { resetData } from "../../../my-info/store/my_activities"
const axios = useJwt.ssoAxiosInterceptor

export const pageTitle = "Unit Instance" ////
export const storeName = "units" ////
export const endpointName = "api/unit" ////

export const serverSideGrid = true ////
export const detailFromGrid = true //// GET DETAIL DATA FROM EXISTING DATA GRID (FASTER PEFORMANCE)
export const getAlwaysGrid = false //// GET ALWAYS GRID DATA EVERY CLICK MENU OR OPEN PAGE
export const getApiAfterPut = true ////
export const getApiAfterPost = true ////
export const getApiAfterDelete = true ////
export const getApiSummaryData = false //// GET SUMMARY DATA FROM BACKEND API
export const detailPage = true //// IF TRUE { WILL BE ACTIVATED DETAIL PAGE AND MENU ACTION BUTTON } ELSE { DETAIL PAGE SHOW ON MODAL }

///////DYNAMIC COLUMNS FOR TABLE
///////if there are several fields that will be customised, don't set them here but add them to the Table.js component in the column section
export const dynamicColumns = [
  {
    columnName: "Name", //// Title Of Column
    fieldName: "name" //// name of field from api
  },
  {
    columnName: "Code",
    fieldName: "code"
  }
  // {
  //   columnName: "Created Date",
  //   fieldName: "created_date",
  //   dataFormat: "date:DD MMM YYYY" //// Type Of Data and format, leave null or delete if type is string. Choices => ['date', 'date:DD MMM YYYY', '', '', '', '', ''] *see moment.js pattern for date/datetime.
  // }
]

///////DYNAMIC FORM FOR MODAL
///////if the fields display on the modal form matches the number of fields in the columns, leave this section filled with an empty array " dynamicForm = [] ". Or if you want to customize, do it on the modal/index.js component
export const dynamicForm = []

export const getSummaryData = createAsyncThunk(
  `${endpointName}/getSummaryData`,
  async (params) => {
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key]
      }
    })
    const response = await axios.get(`/${endpointName}/summary`, {
      params
    })
    return {
      params,
      data: response.data.data
    }
  }
)

export const getDataList = createAsyncThunk(
  `${endpointName}/getDataList`,
  async (params) => {
    params["flat_mode"] = false
    // console.log("getDataList")
    // Object.keys(params).forEach((key) => {
    //   if (params[key] === null || params[key] === "") {
    //     delete params[key]
    //   }
    // })

    const response = await axios.get(`/${endpointName}`, {
      params
    })

    return {
      params,
      data: response.data.data,
      total: response.data.total
    }
  }
)

export const getDataById = createAsyncThunk(
  `${endpointName}/getDataById`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/${endpointName}/${id}`)
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetSelectedData = createAsyncThunk(
  `${endpointName}/resetSelectedData`,
  async ({ rejectWithValue }) => {
    return rejectWithValue({})
  }
)

export const postData = createAsyncThunk(
  `${endpointName}/postData`,
  async (user, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`/${endpointName}`, user)
      dispatch(resetSelectedData({}))
      if (getApiAfterPost) {
        dispatch(getDataList({}))
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const putData = createAsyncThunk(
  `${endpointName}/putData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    const id = data.id ?? getState()[storeName].selectedData.id
    const notReload = data["notReload"]
    if (notReload) delete data["notReload"]
    try {
      const response = await axios.put(`/${endpointName}/${id}`, data)
      dispatch(resetSelectedData({}))
      if (getApiAfterPut && !notReload) {
        // console.log(getState()[storeName].params)
        await dispatch(getDataList({}))
        if (getApiSummaryData) {
          await dispatch(getSummaryData({}))
        }
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteData = createAsyncThunk(
  `${endpointName}/deleteData`,
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/${endpointName}/${id}`)
      if (getApiAfterDelete) {
        await dispatch(getDataList({}))
        if (getApiSummaryData) {
          dispatch(getSummaryData({}))
        }
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteSelectedRowData = createAsyncThunk(
  `${endpointName}/deleteSelectedRowData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/${endpointName}`, { data })
      if (getApiAfterDelete) {
        await dispatch(getDataList({}))
        if (getApiSummaryData) {
          dispatch(getSummaryData({}))
        }
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDataById = createAsyncThunk(
  `${endpointName}/setDataById`,
  async (rowData, { rejectWithValue }) => {
    return rejectWithValue(rowData)
  }
)

const initSelectData = {
  value: "",
  label: "Select Unit"
}

export const setUnitSelectedDropdown = createAsyncThunk(
  "Units/setUnitSelectedDropdown",
  (data) => {
    // console.log(data)
    return data
  }
)

export const getUnits = createAsyncThunk(
  "Units/getUnits",
  async (params, { rejectWithValue }) => {
    // console.log("GET INIT MEMBER-OF START...")
    params["flat_mode"] = true
    if (isUserLoggedIn() !== null) {
      const userMember =
        (await JSON.parse(localStorage.getItem("userData")).member_of) ?? []
      if (userMember.length > 0) {
        // userMember.unshift(initSelectData)
        let fetchState = false
        if (userMember.some((e) => e.children_count > 0)) {
          fetchState = true
        }

        if (fetchState) {
          const response = await useJwt.ssoAxiosInterceptor.get(
            "/api/unit",
            params
          )

          // response.data.data.unshift(initSelectData)
          // console.log("GET INIT MEMBER-OF SUCCESS")
          return {
            params,
            data: response.data.data,
            total: response.data.total
          }
        } else {
          console.log("GET INIT MEMBER-OF SUCCESS")
          return { data: userMember }
        }
      } else {
        try {
          const response = await useJwt.ssoAxiosInterceptor.get(
            "/api/unit",
            params
          )
          // response.data.data.unshift(initSelectData)
          console.log("GET INIT MEMBER-OF SUCCESS")
          return {
            params,
            data: response.data.data,
            total: response.data.total
          }
        } catch (error) {
          console.error("GET INIT MEMBER-OF FAILED!!!")
          return rejectWithValue(error.response.data)
        }
      }
    }
  }
)

export const getUnit = createAsyncThunk("Units/getUnit", async (id) => {
  const response = await axios.get(`/api/unit/${id}`)
  // console.log(response)
  return response.data.data
})

export const setDataList = createAsyncThunk(
  `${endpointName}/setDataList`,
  async (rowData, { rejectWithValue }) => {
    return rejectWithValue(rowData)
  }
)

export const setDataListNested = createAsyncThunk(
  `${endpointName}/setDataListNested`,
  async (rowData, { rejectWithValue }) => {
    return rejectWithValue(rowData)
  }
)

// export const addUnit = createAsyncThunk(
//   "Units/addUnit",
//   async (unit, { dispatch, getState }) => {
//     await axios.post("/api/unit", unit)
//     await dispatch(getUnits(getState().units.params))
//     await dispatch(getAllUnit())
//     return unit
//   }
// )

// export const deleteUnit = createAsyncThunk(
//   "Units/deleteUnit",
//   async (id, { dispatch, getState }) => {
//     await axios.delete("/apps/unit", { id })
//     await dispatch(getUnits(getState().units.params))
//     await dispatch(getAllUnit())
//     return id
//   }
// )

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const UnitsSlice = createSlice({
  name: storeName,
  initialState: {
    crudTitle: "Unit",
    //SHARED
    dataDropdown: [],
    selectedUnit: {},
    selectedUnitMulti: [],

    // SUMMARY
    statusSummary: fetchStatus,
    isLoadingSummary: false,
    errorSummary: "" | null,
    dataSummary: [],

    // ALL
    status: fetchStatus,
    isLoading: false,
    isLoadingNested: false,
    error: "" | null,
    data: [],
    dataNested: [],
    total: 1,

    params: {},
    selectedData: null,

    // DETAIL
    statusDetail: fetchStatus,
    isLoadingDetail: false,
    errorDetail: "" | null,

    // ADD&EDIT
    statusAddEdit: fetchStatus,
    isLoadingAddEdit: false,
    errorAddEdit: "" | null,

    // ADD&EDIT
    statusDelete: fetchStatus,
    isLoadingDelete: false,
    errorDelete: "" | null
  },
  reducers: {
    setUnitSelected: (state, action) => {
      // console.log(action)
      console.log("setUnitSelected")
      return { ...state, selectedUnit: action.payload }
    },
    resetAll: (state) => {
      // state.errorSummary = null
      // state.dataSummary = []
      // state.error = null
      state.data = []
      state.dataDropdown = []
      state.total = 1
      state.selectedUnit = null
      state.selectedUnitMulti = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUnitSelectedDropdown.fulfilled, (state, action) => {
        // state.selectedUnit = action.payload
        console.log("setUnitSelectedDropdown")
        return { ...state, selectedUnit: action.payload }
        // console.log(state.selectedUnit)
        // state.selectedUnit = { ...state.selectedUnit, ...action.payload }
      })
      .addCase(getUnits.pending, (state) => {
        // console.log("pending")
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getUnits.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.isLoading = false

        if (action.payload?.data) {
          state.data = action.payload.data
          state.dataDropdown = action.payload.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.name
          }))
          // console.log(state.dataDropdown)
          state.dataDropdown.unshift(initSelectData)
          if (action.payload?.total) {
            state.total = action.payload.total
          }
          state.selectedUnit = state.dataDropdown[0]
        }
      })
      .addCase(getUnits.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.message
      })

      .addCase(getUnit.fulfilled, (state, action) => {
        state.selectedUnit = action.payload
      })

      ///SUMMARY
      .addCase(getSummaryData.pending, (state) => {
        state.statusSummary = "loading"
        state.isLoadingSummary = true
      })
      .addCase(getSummaryData.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.statusSummary = "succeeded"
        state.isLoadingSummary = false
        state.dataSummary = action.payload.data
      })
      .addCase(getSummaryData.rejected, (state, action) => {
        state.statusSummary = "failed"
        state.isLoadingSummary = false
        state.errorSummary = action.error.message
      })

      ///ALL
      .addCase(getDataList.pending, (state) => {
        state.isLoadingNested = true
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.isLoadingNested = false
        if (action.payload.data.length > 0) {
          state.dataNested = action.payload.data
        }
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getDataList.rejected, (state, action) => {
        state.isLoadingNested = false
        state.error = action.error.payload?.message
      })

      ///EDIT
      .addCase(putData.pending, (state) => {
        state.statusAddEdit = "loading"
        state.isLoadingAddEdit = true
      })
      .addCase(putData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusAddEdit = "succeeded"
        state.isLoadingAddEdit = false
      })
      .addCase(putData.rejected, (state, action) => {
        state.statusAddEdit = "failed"
        state.isLoadingAddEdit = false
        state.errorAddEdit = action.payload?.message
      })

      ///ADD
      .addCase(postData.pending, (state) => {
        state.statusAddEdit = "loading"
        state.isLoadingAddEdit = true
      })
      .addCase(postData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusAddEdit = "succeeded"
        state.isLoadingAddEdit = false
      })
      .addCase(postData.rejected, (state, action) => {
        state.statusAddEdit = "failed"
        state.isLoadingAddEdit = false
        state.errorAddEdit = action.payload?.message
      })

      ///DELETE
      .addCase(deleteData.pending, (state) => {
        state.statusDelete = "loading"
        state.isLoadingDelete = true
      })
      .addCase(deleteData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusDelete = "succeeded"
        state.isLoadingDelete = false
      })
      .addCase(deleteData.rejected, (state, action) => {
        state.statusDelete = "failed"
        state.isLoadingDelete = false
        state.errorDelete = action.payload?.message
      })

      ///DELETE-SELECTED-ROW
      .addCase(deleteSelectedRowData.pending, (state) => {
        state.statusDelete = "loading"
        state.isLoadingDelete = true
      })
      .addCase(deleteSelectedRowData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusDelete = "succeeded"
        state.isLoadingDelete = false
      })
      .addCase(deleteSelectedRowData.rejected, (state, action) => {
        state.statusDelete = "failed"
        state.isLoadingDelete = false
        state.errorDelete = action.payload?.message
      })

      ///DETAIL
      .addCase(getDataById.pending, (state) => {
        state.statusDetail = "loading"
        state.isLoadingDetail = true
      })
      .addCase(getDataById.fulfilled, (state, action) => {
        state.statusDetail = "succeeded"
        state.isLoadingDetail = false
        state.selectedData = action.payload
      })
      .addCase(getDataById.rejected, (state, action) => {
        state.statusDetail = "failed"
        state.isLoadingDetail = false
        state.selectedData = null
        state.errorDetail = action.payload?.message
      })

      ///OTHERS
      .addCase(resetSelectedData.rejected, (state) => {
        state.selectedData = null
      })
      .addCase(setDataList.rejected, (state, action) => {
        state.data = action.payload
      })
      .addCase(setDataListNested.rejected, (state, action) => {
        state.dataNested = action.payload
      })
      .addCase(setDataById.rejected, (state, action) => {
        state.selectedData = action.payload
      })
  }
})

// export const { setUnitSelected, resetAll } = UnitsSlice.actions

export default UnitsSlice.reducer
