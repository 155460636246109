// ** Navigation imports
import dashboards from "./dashboards"
// import main from "./main"
// import master from "./master"
// import appMenu from "./app-menu"
// import apps from "./apps"
// import pages from "./pages"
// import forms from "./forms"
// import tables from "./tables"
// import others from "./others"
// import charts from "./charts"
// import uiElements from "./ui-elements"
// import { useDispatch, useSelector } from "react-redux"
// const storemenu = useSelector((state) => state.domain_claims)

// ** Merge & Export
export default [
  ...dashboards
  // ...main,
  // ...master
  // ...appMenu

  //   ...apps,
  //   ...pages,
  //   ...uiElements,
  //   ...forms,
  //   ...tables,
  //   ...charts,
  //   ...others
]
