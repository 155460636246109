// ** Third Party Components
import Avatar from "@components/avatar"
import { Calendar, MapPin, Video } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

// ** Reactstrap Imports
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner
} from "reactstrap"

import illustration from "@src/assets/images/illustration/email.svg"
import { getDataList, putDataWidget } from "@src/shared/dynamic_pages/store"
import "moment-timezone"
import "moment/locale/id"
import { useContext, useState } from "react"
import Moment from "react-moment"

import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { getDataList as getDataListMyActivity } from "@src/views/my-info/store/my_activities"
import toast from "react-hot-toast"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"

const params = {
  // navigation: true
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 5500,
    disableOnInteraction: false
  },
  slideToClickedSlide: true
}

const InvitationSlider = ({ item }) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { colors } = useContext(ThemeColors)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const storeName = item?.cardId
  const endpointName = item?.dataFrom?.api
  const baseUrl = item?.dataFrom?.baseUrl
  const [openAcc, setOpenAcc] = useState(null)
  const toggleAcc = (id) => {
    openAcc === id ? setOpenAcc() : setOpenAcc(id)
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const putDataToApi = (data) => {
    setLoadingPostPut(true)
    setLoadingText("Accepting Invitation")
    data["storeName"] = storeName
    data["endpointName"] = endpointName
    data["baseUrl"] = baseUrl
    data["callback_page"] = window.location.href
    dispatch(putDataWidget(data))
      .then((res) => {
        // console.log(res)

        if (res.meta.requestStatus === "fulfilled") {
          dispatch(
            getDataList({
              storeName: storeName,
              endpointName: endpointName,
              baseUrl: baseUrl,
              type: "to_me",
              Id_AttendingState: 0
            })
          )
            .then(() => {
              toast.success(
                `${t("Successfully Accept Training Invitation")}}`,
                {
                  style: {
                    padding: "16px",
                    color: colors.success.main,
                    border: `1px solid ${colors.success.main}`
                  },
                  iconTheme: {
                    primary: colors.success.main
                  }
                }
              )
            })
            .then(() => {
              setLoadingPostPut(false)
            })
            .then(() => {
              dispatch(
                getDataList({
                  storeName: "TrainingEmployee_task",
                  endpointName: "TrainingEmployee/assignment",
                  baseUrl: "hr",
                  type: "to_me",
                  Id_AttendingState: 1
                })
              )
              setTimeout(() => {
                dispatch(getDataListMyActivity({}))
              }, 8000)
            })
        } else if (res.meta.requestStatus === "rejected") {
          errorToast(res)
        }
      })
      .catch((error) => {
        // console.error(error)
        setLoadingPostPut(false)
        errorToast(error)
      })
  }

  const calendarStrings = {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "[last] dddd",
    nextWeek: "dddd",
    sameElse: "L"
  }

  const toUpperCaseFilter = (d) => {
    return d.toUpperCase()
  }

  return (
    <Card>
      <CardHeader>
        <div className="d-flex align-items-center">
          <InsertInvitationOutlinedIcon className="user-timeline-title-icon me-1" />
          <CardTitle tag="h4">{t(item?.title ?? "")}</CardTitle>
          {store.data[storeName]?.length > 1 && (
            <Badge className="ms-1" tag="div" color="info" pill>
              {store.data[storeName]?.length}
            </Badge>
          )}
        </div>
      </CardHeader>

      <CardBody className="px-0 pb-0">
        <Swiper {...params} {...(item.sliderOpt ?? {})}>
          {store.data[storeName]?.map((row) => {
            return (
              <SwiperSlide key={`swiper_${row.id}`}>
                <Card className="card-developer-meetup mb-0 cursor-pointer">
                  <div className="meetup-img-wrapper rounded-top text-center">
                    <img
                      src={
                        row?.TrainingData?.Image
                          ? row.TrainingData.Image
                          : illustration
                      }
                      height="170"
                    />
                  </div>
                  <CardBody>
                    <div className="meetup-header mb-1 d-flex align-items-center">
                      <Accordion className="" open={openAcc} toggle={toggleAcc}>
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <div className="meetup-day">
                              <h6 className="mb-0">
                                <Moment filter={toUpperCaseFilter} format="ddd">
                                  {row?.TrainingData?.StartDate}
                                </Moment>
                              </h6>
                              <h3 className="mb-0">
                                <Moment format="DD">
                                  {row?.TrainingData?.StartDate}
                                </Moment>
                              </h3>
                            </div>
                            <div className="my-auto">
                              <CardTitle
                                tag="h4"
                                style={{
                                  fontSize:
                                    row?.TrainingData?.Name?.length > 40
                                      ? "1rem"
                                      : "1.53rem"
                                }}
                                className="mb-25"
                              >
                                {row?.TrainingData?.Name}
                              </CardTitle>
                            </div>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <p
                              style={{
                                fontSize:
                                  row?.TrainingData?.Description?.length > 40
                                    ? "0.8rem"
                                    : "1.53rem"
                              }}
                            >
                              {row?.TrainingData?.Description}
                            </p>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    <div className="d-flex">
                      <Avatar
                        color="light-primary"
                        className="rounded me-1"
                        icon={<Calendar size={18} />}
                      />
                      <div>
                        {/* <h6 className="mb-0">Sat, May 25, 2020</h6>
                    <small>10:AM to 6:PM</small> */}
                        <h6 className="mb-0">
                          {
                            <Moment calendar={calendarStrings}>
                              {row?.TrainingData?.StartDate}
                            </Moment>
                          }
                        </h6>
                        <small>
                          {
                            <Moment format="[Start at] LT">
                              {row?.TrainingData?.StartDate}
                            </Moment>
                          }
                        </small>
                      </div>
                    </div>
                    {!row?.TrainingData?.IsOnline ? (
                      <div className="d-flex mt-2">
                        <Avatar
                          color="light-primary"
                          className="rounded me-1"
                          icon={<MapPin size={18} />}
                        />
                        <div>
                          {/* <h6 className="mb-0">Central Park</h6> */}
                          <small>{row?.TrainingData?.Address}</small>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex mt-2">
                        <Avatar
                          color="light-primary"
                          className="rounded me-1"
                          icon={<Video size={18} />}
                        />
                        <div>
                          <h6 className="mb-0">{t("Online Training")}</h6>
                          <small>
                            {row?.TrainingData?.LinkRef
                              ? row?.TrainingData?.LinkRef
                              : "The link will be sent before the event starts"}
                          </small>
                        </div>
                      </div>
                    )}
                    {/* <AvatarGroup data={data} /> */}
                    {row?.Id_AttendingState !== 3 && (
                      <div className="d-flex mt-2 align-items-center justify-content-end">
                        {!loadingPostPut &&
                          (!loadingDelete ? (
                            <Button.Ripple color="flat-secondary me-1">
                              {t("Decline")}
                            </Button.Ripple>
                          ) : (
                            <Button.Ripple color="flat-secondary me-1">
                              <Spinner color="white" size="sm" type="grow" />
                              <span className="ms-50">{loadingText}</span>
                            </Button.Ripple>
                          ))}

                        {!loadingPostPut ? (
                          <Button.Ripple
                            onClick={(e) => {
                              e.preventDefault()
                              putDataToApi({
                                id: row?.Id_Training
                              })
                            }}
                            color="gradient-primary"
                          >
                            {t("Accept")}
                          </Button.Ripple>
                        ) : (
                          <Button.Ripple color="gradient-primary">
                            <Spinner color="white" size="sm" type="grow" />
                            <span className="ms-50">{loadingText}</span>
                          </Button.Ripple>
                        )}
                      </div>
                    )}

                    {row?.Id_AttendingState === 3 && (
                      <div className="d-flex mt-2 align-items-center justify-content-center">
                        <Button.Ripple color="flat-secondary me-1">
                          {t("Awaiting Manager Approval")}
                        </Button.Ripple>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </CardBody>
    </Card>
  )
}
export default InvitationSlider
