import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
// import axios from "axios"
import useJwt from "@src/auth/jwt/useJwt"

export const pageTitle = "Aktifitas Saya" ////
export const storeName = "my_info_activities" ////
export const endpointName = "api/my_info/activity" ////

export const serverSideGrid = true ////
export const detailFromGrid = true //// GET DETAIL DATA FROM EXISTING DATA GRID (FASTER PEFORMANCE)
export const getAlwaysGrid = false //// GET ALWAYS GRID DATA EVERY CLICK MENU OR OPEN PAGE
export const getApiAfterPut = true ////
export const getApiAfterPost = true ////
export const getApiAfterDelete = true ////
export const getApiSummaryData = true //// GET SUMMARY DATA FROM BACKEND API
export const detailPage = true //// IF TRUE { WILL BE ACTIVATED DETAIL PAGE AND MENU ACTION BUTTON } ELSE { DETAIL PAGE SHOW ON MODAL }

export const getDataList = createAsyncThunk(
  `${endpointName}/getDataList`,
  async (params) => {
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key]
      }
    })
    const response = await useJwt.ssoAxiosInterceptor.get(`${endpointName}`, {
      params
    })
    return {
      params,
      data: response.data.data
    }
  }
)

export const resetData = createAsyncThunk(
  `${endpointName}/resetData`,
  async ({ rejectWithValue }) => {
    console.log("resetData activity")
    return rejectWithValue({})
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreSlice = createSlice({
  name: storeName,
  initialState: {
    pageTitle,

    // ALL
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data: [],

    params: {},
    selectedData: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///ALL
      .addCase(getDataList.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.status = "succeeded"
        state.isLoading = false
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
      })
      .addCase(getDataList.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.payload?.message
      })

      ///RESET
      .addCase(resetData.rejected, (state) => {
        state.data = []
      })
  }
})

export default StoreSlice.reducer
