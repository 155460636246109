import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

export const pageTitle = "Cetak Laporan" ////
export const storeName = "incoices" ////
export const endpointName = "invoices/export" ////

export const serverSideGrid = true ////
export const detailFromGrid = true //// GET DETAIL DATA FROM EXISTING DATA GRID (FASTER PEFORMANCE)
export const getAlwaysGrid = false //// GET ALWAYS GRID DATA EVERY CLICK MENU OR OPEN PAGE
export const getApiAfterPut = true ////
export const getApiAfterPost = true ////
export const getApiAfterDelete = true ////
export const getApiSummaryData = true //// GET SUMMARY DATA FROM BACKEND API
export const detailPage = true //// IF TRUE { WILL BE ACTIVATED DETAIL PAGE AND MENU ACTION BUTTON } ELSE { DETAIL PAGE SHOW ON MODAL }

export const getInvoice = createAsyncThunk(
  `${endpointName}/getInvoice`,
  async (params) => {
    console.log("getInvoice")
    const rpt_name = params.rpt_name
    const output_type = params.output_type
    const print = params?.print ?? false
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key]
      }
    })
    delete params.rpt_name
    delete params.output_type
    delete params.print
    const response = await axios
      .get(`/${endpointName}/${rpt_name}/${output_type}`, {
        params,
        responseType: "blob"
      })
      .then((response) => {
        if (print) {
          window.open(URL.createObjectURL(response.data)).print()
        } else {
          window.open(URL.createObjectURL(response.data))
        }
      })
      .catch((error) => console.log(error))
    return {
      params,
      data: response.data.data
    }
  }
)

export const resetData = createAsyncThunk(
  `${endpointName}/resetData`,
  async ({ rejectWithValue }) => {
    console.log("resetData activity")
    return rejectWithValue({})
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreSlice = createSlice({
  name: storeName,
  initialState: {
    pageTitle,

    // ALL
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data: [],

    params: {},
    selectedData: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///ALL
      .addCase(getInvoice.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        console.log(action.payload)
        state.status = "succeeded"
        state.isLoading = false
        state.data = action.payload.data
        state.params = action.payload.params
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.payload?.message
      })

      ///RESET
      .addCase(resetData.rejected, (state) => {
        state.data = []
      })
  }
})

export default StoreSlice.reducer
