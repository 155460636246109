import { neutralizeBack, revivalBack } from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import {
  Fragment,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalAddEdit from "./modal"
import { deleteData, getDataList } from "./store"

import { NoDataDisplay } from "@src/shared/CommonComponents"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import toast from "react-hot-toast"
import { Button, Input } from "reactstrap"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import Avatar from "@components/avatar"
import OrganizationChart from "@dabeng/react-orgchart"

const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const DefaultChart = ({ pageAttr, storeName, filtersCustomAdd }) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(store.params?.page ?? 1)
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(store.params?.length ?? 10)
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
  const parentIdField = pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"
  const titleField = pageAttr?.crudOpt?.treeviewTitleField ?? "title" ?? "name"
  const descField = pageAttr?.crudOpt?.treeviewDescField ?? "description"
  const levelField = pageAttr?.crudOpt?.treeviewLevelField ?? "depth"
  const detailField = pageAttr?.crudOpt?.orgchartDetailField ?? "detail"
  const codeField = pageAttr?.crudOpt?.treeviewAddSubIncrementField
  const iconField = pageAttr?.crudOpt?.treeviewIconField ?? "icon"

  const orgchart = useRef()

  const [zoomChart, setZoomChart] = useState(true)
  const [panChart, setPanChart] = useState(true)
  const [draggableChart, setDraggableChart] = useState(true)

  const exportTo = () => {
    // console.log(orgchart.current)
    orgchart.current.exportTo("organization_chart", "pdf")
  }

  const handleAddEdit = async (rowData, editMode = true, addChild = false) => {
    // console.log(rowData)
    // if (rowData && editMode && !addChild) {
    //   // setPageType("edit")
    //   setLoadingText(
    //     `${t("Loading")} ${t("Detail")} ${t("Data")} ${t(pageAttr.pageTitle)}`
    //   )
    //   setLoadingModal(true)
    //   if (!pageAttr?.crudOpt?.detailFromGrid) {
    //     // setLoading(false)
    //     setShowModal(true)
    //     dispatch(getDataById(rowData.id, storeName)).then((res) => {
    //       setLoadingModal(false)
    //     })
    //   } else {
    //     console.log("setSelectedData")
    //     dispatch(setSelectedData({ rowData, storeName: storeName })).then(
    //       () => {
    //         setLoadingModal(false)
    //         setShowModal(true)
    //       }
    //     )
    //   }
    // } else {
    //   if (addChild) {
    //     // console.log(rowData)
    //     ////FIELDS VALUE AUTOS ADD SUB
    //     let newData = {}
    //     if (rowData[pageAttr?.crudOpt?.treeviewAddParentIdField ?? idField]) {
    //       newData[parentIdField] =
    //         rowData[pageAttr?.crudOpt?.treeviewAddParentIdField ?? idField]
    //     }
    //     if (pageAttr?.crudOpt?.treeviewAddSubParentFields) {
    //       pageAttr.crudOpt.treeviewAddSubParentFields.map((item) => {
    //         if (pageAttr.crudOpt?.treeviewAddSubIncrementField !== item) {
    //           newData[item] = rowData[item]
    //         }
    //       })
    //     }
    //     ////CODE INCREMENT
    //     if (codeField) {
    //       const oldValuesArr =
    //         rowData?.children?.map((obj) => obj[codeField]) ?? []
    //       // console.log(oldValuesArr)
    //       let maxValue = 1
    //       if (oldValuesArr?.length > 0) {
    //         oldValuesArr.map((item) => {
    //           if (item?.includes(".")) {
    //             const newDataFieldArr = item.split(".")
    //             if (newDataFieldArr.length > 0) {
    //               const oldNumber =
    //                 newDataFieldArr.pop() !== ""
    //                   ? newDataFieldArr.pop()
    //                   : newDataFieldArr[newDataFieldArr.length - 1]
    //               // console.log(oldNumber)
    //               const inc = parseInt(oldNumber ?? "0", 10)
    //               if (inc > maxValue) {
    //                 maxValue = inc
    //               }
    //               const newInc = String(inc + 1).padStart(
    //                 pageAttr.crudOpt?.treeviewAddSubIncrementLength ??
    //                   (oldNumber?.length > 0 ? oldNumber?.length : 2) ??
    //                   2,
    //                 "0"
    //               )
    //               newData[codeField] =
    //                 `${rowData[codeField]}.${newInc}.`.replace("..", ".")
    //             }
    //           }
    //         })
    //       } else {
    //         const newInc = String(maxValue).padStart(
    //           pageAttr.crudOpt?.treeviewAddSubIncrementLength ?? 2,
    //           "0"
    //         )
    //         newData[codeField] = `${rowData[codeField]}.${newInc}.`.replace(
    //           "..",
    //           "."
    //         )
    //       }
    //     }
    //     setPageType("add-child")
    //     dispatch(
    //       setSelectedData({ rowData: newData, storeName: storeName })
    //     ).then((res) => {
    //       if (!pageAttr?.crudOpt?.detailPage || relPageId) {
    //         setLoadingModal(false)
    //         setLoading(false)
    //         setShowModal(true)
    //       } else {
    //         if (newData) {
    //           let str = []
    //           for (let p in newData) {
    //             if (newData.hasOwnProperty(p)) {
    //               str.push(
    //                 `${encodeURIComponent(p)}=${encodeURIComponent(newData[p])}`
    //               )
    //             }
    //           }
    //           const query = str.join("&")
    //           navigate(`${location.pathname}/add?${query}`)
    //         } else {
    //           navigate(`${location.pathname}/add`)
    //         }
    //       }
    //     })
    //   } else {
    //     setPageType("add")
    //     if (!pageAttr?.crudOpt?.detailPage || relPageId) {
    //       dispatch(resetSelectedData(storeName)).then((r) => {
    //         setLoadingModal(false)
    //         setLoading(false)
    //         setShowModal(true)
    //       })
    //     } else {
    //       dispatch(resetSelectedData(storeName)).then((r) => {
    //         navigate(`${location.pathname}/add`)
    //       })
    //     }
    //   }
    // }
  }

  const handleCloseModal = () => {
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    revivalBack()
  }

  const toggleSidebar = () => {
    // console.log("here")
    setPageType("add")
    setSidebarOpen(!sidebarOpen)
    setShowModal(!showModal)
    neutralizeBack(handleCloseModal)
  }

  const handleDelete = async (id) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} ${t(pageAttr.pageTitle)}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} ${t(pageAttr.pageTitle)}!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingText(`${t("Deleting")} Data ${t(pageAttr.pageTitle)}`)
      setLoading(true)
      dispatch(
        deleteData({
          id: id,
          storeName: storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((res) => {
        // console.log(res)
        if (res.meta.requestStatus === "fulfilled") {
          setLoading(false)
          toast.success(
            `${t("Successfully deleted")} ${t(pageAttr.pageTitle)}`,
            {
              style: {
                padding: "16px",
                color: colors.success.main,
                border: `1px solid ${colors.success.main}`
              },
              iconTheme: {
                primary: colors.success.main
              }
            }
          )
        } else if (res.meta.requestStatus === "rejected") {
          setLoading(false)
          toast.error(res.payload.message, {
            style: {
              padding: "16px",
              color: colors.danger.main,
              border: `1px solid ${colors.danger.main}`
            },
            iconTheme: {
              primary: colors.danger.main
            }
          })
        }
      })
    }
  }

  const ds = {
    id: "n1",
    name: "Lao Lao",
    title: "general manager",
    children: [
      { id: "n2", name: "Bo Miao", title: "department manager" },
      {
        id: "n3",
        name: "Su Miao",
        title: "department manager",
        children: [
          { id: "n4", name: "Tie Hua", title: "senior engineer" },
          {
            id: "n5",
            name: "Hei Hei",
            title: "senior engineer",
            children: [
              { id: "n6", name: "Dan Dan", title: "engineer" },
              { id: "n7", name: "Xiang Xiang", title: "engineer" }
            ]
          },
          { id: "n8", name: "Pang Pang", title: "senior engineer" }
        ]
      },
      { id: "n9", name: "Hong Miao", title: "department manager" },
      {
        id: "n10",
        name: "Chun Miao",
        title: "department manager",
        children: [{ id: "n11", name: "Yue Yue", title: "senior engineer" }]
      }
    ]
  }

  useEffect(() => {
    // console.log(storeName)
    // console.log(store.pageData)

    if (
      (!Object.keys(store.pageData[storeName]?.data ?? {})?.length ||
        pageAttr?.crudOpt?.getAlwaysGrid) &&
      !store.pageData[storeName]?.isLoading &&
      store.pageData[storeName]?.status !== "failed"
    ) {
      setLoading(true)
      setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)

      dispatch(
        pageAttr?.crudOpt?.serverSideGrid
          ? getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              sort_dir,
              sort,
              search: searchTerm,
              page: currentPage,
              length: rowsPerPage,
              ...filtersCustom
            })
          : getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              ...filtersCustom
            })
      ).then((x) => {
        // console.log(store.pageData)
        // console.log(x)
        // setTimeout(() => {
        //   const chartElem = document.querySelector(".myChart")
        //   if (chartElem) {
        //     chartElem.style.cursor = "move"
        //     chartElem.style.left = "-329px"
        //     chartElem.style.top = "-9px"
        //     chartElem.style.transform =
        //       "matrix(0.520403, 0, 0, 0.520403, -573, -191)"
        //   }
        // }, 100)
        setLoading(false)
        setZoomChart(true)
        setPanChart(true)
        // setDraggableChart(true)
        if (pageAttr?.crudOpt?.serverSideGrid) {
          setServerSideProps({
            sortServer: true,
            paginationServer: true,
            paginationTotalRows: store.pageData[storeName]?.total,
            paginationComponent: CustomPagination
            // paginationComponentOptions: paginationComponentOptions
          })
        }
      })
    } else {
      setLoading(true)
      // console.log(
      //   store.pageData[storeName]?.data,
      //   Object.keys(store.pageData[storeName]?.data ?? {})?.length
      // )

      //////CACHE ON RELATIONS CRUD
      if (relPageId || pageAttr?.isViewOnForm) {
        // console.log(store.pageData[storeName]?.params)
        // console.log(pageAttr?.crudOpt?.initialFiltersCondition)
        const lastParentData = storeParent?.selectedData
        let curPageLastParam = store.pageData[storeName]?.params
        let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
        let reloadState = false
        if (curPageAttrPageFilArray) {
          if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
            curPageAttrPageFilArray?.map((childPar) => {
              // console.log(childPar?.fieldNameCurrent)
              // console.log(lastParentData[childPar?.value_from_parentFieldName])
              reloadState =
                reloadState ||
                `${curPageLastParam[curPageAttrPageFilField]}` !==
                  `${lastParentData[childPar?.value_from_parentFieldName]}`
            })
          } else {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent
            // console.log(
            //   curPageAttrPageFilField,
            //   `${curPageLastParam[curPageAttrPageFilField]}`,
            //   `${id}`
            // )
            reloadState =
              `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
          }
        }

        if (reloadState) {
          if (!store.pageData[storeName]?.isLoading) {
            dispatch(
              pageAttr?.crudOpt?.serverSideGrid
                ? getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    sort_dir,
                    sort,
                    search: searchTerm,
                    page: currentPage,
                    length: rowsPerPage,
                    ...filtersCustom
                  })
                : getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    ...filtersCustom
                  })
            )
          }
        }
      }
      // const chartElem = document.querySelector(".myChart")
      // if (chartElem) {
      //   chartElem.style.cursor = "move"
      //   chartElem.style.left = "-329px"
      //   chartElem.style.top = "-9px"
      //   chartElem.style.transform =
      //     "matrix(0.520403, 0, 0, 0.520403, -573, -191)"
      // }
      setLoading(false)
      setZoomChart(true)
      setPanChart(true)
      setDraggableChart(true)
    }
  }, [])

  const renderDetView = (detailData) => {
    return detailData.map((det, index) => {
      return (
        <div
          key={`render_detail_${index}`}
          className="d-flex justify-content-between align-items-center m-1 text-start"
        >
          <div className="d-flex align-items-center">
            <Avatar
              className="me-75"
              img={
                det?.avatar ??
                require("@src/assets/images/avatars/avatar-blank.png").default
              }
              imgHeight="30"
              imgWidth="30"
            />
            <div className="my-auto">
              <h6
                style={{
                  color: "var(--primary-color)"
                }}
                className="mb-0"
              >{`${det?.name}`}</h6>
            </div>
          </div>
        </div>
      )
    })
  }

  function addTitleToChildren(data, titleField) {
    if (data.children && data.children.length > 0) {
      data.children = data.children.map((child) => {
        let nuData = { ...child, title: child[titleField] }
        if (child.children && child.children.length > 0) {
          nuData = addTitleToChildren(nuData, titleField)
        }
        return nuData
      })
    }
    let nuData = { ...data, title: data[titleField] }
    return nuData
  }

  function getWidth(nodeData) {
    return nodeData?.[levelField] === 1
      ? "250px"
      : nodeData?.[levelField] === 2
      ? "240px"
      : nodeData?.[levelField] === 3
      ? "200px"
      : nodeData?.[levelField] === 4
      ? "200px"
      : "200px"
  }

  function findNodeById(data, id) {
    for (let item of data) {
      if (item[idField] === id) {
        return item
      }
      if (item.children) {
        let found = findNodeById(item.children, id)
        if (found) {
          return found
        }
      }
    }
    return null
  }

  function setDropLine(nodeData) {
    let result = {}
    let parentNode = null
    let parentNodeDepth = null
    if (nodeData?.[parentIdField]) {
      parentNode = findNodeById(
        store.pageData[storeName]?.data,
        nodeData[parentIdField]
      )
      // console.log(parentNode)
      parentNodeDepth = parentNode?.[levelField]
    }

    if (nodeData?.[levelField] && parentNodeDepth) {
      // console.log(
      //   nodeData?.[levelField],
      //   parentNodeDepth,
      //   nodeData?.[levelField] - parentNodeDepth
      // )
      if (nodeData?.[levelField] - parentNodeDepth > 1) {
        result = {
          // marginTop: "130px"
          position: "absolute",
          top: "130px"
        }
      }
    }
    return result
  }

  function hasDropLineChildOneOnly(nodeData) {
    let result = null
    let childrenNode = nodeData?.children
    let childrenNodeDepth = null
    if (childrenNode?.length > 0) {
      childrenNodeDepth = childrenNode[0][levelField]
      if (childrenNodeDepth - nodeData?.[levelField] > 1) {
        result = childrenNodeDepth
      }
    }
    return result
  }

  function hasDropLineChilds(nodeData) {
    let result = {}
    let childrenNode = nodeData?.children
    if (childrenNode?.length > 0) {
      childrenNode.map((child, index) => {
        const childrenNodeDepth = child[levelField]
        if (childrenNodeDepth - nodeData?.[levelField] > 1) {
          result[child[idField]] = { dropSize: childrenNodeDepth }
        }
      })
    }
    // console.log(result)
    return result
  }

  function isDropLine(nodeData) {
    let result = false
    let parentNode = null
    let parentNodeDepth = null
    if (nodeData?.[parentIdField]) {
      parentNode = findNodeById(
        store.pageData[storeName]?.data,
        nodeData[parentIdField]
      )
      parentNodeDepth = parentNode?.[levelField]
    }

    if (nodeData?.[levelField] && parentNodeDepth) {
      if (
        nodeData?.[levelField] - parentNodeDepth > 1 &&
        parentNode?.children?.length > 1
      ) {
        result = true
      }
    }
    return result
  }

  const MyNode = ({ nodeData }) => {
    const selectNode = () => {
      // alert(`Hi All. I'm ${nodeData.name}. I'm a ${nodeData.title}.`)
    }

    // DETAIL
    // let detailElement = []
    // nodeData?.[detailField]?.map((det) => {
    //   console.log(det)
    // })

    let hasDropLineChildOneOnlyResult = nodeData?.children
      ? hasDropLineChildOneOnly(nodeData)
      : null
    let hasDropLineChildsResult =
      nodeData?.children && nodeData?.children?.length > 0
        ? hasDropLineChilds(nodeData)
        : {}

    const hasDropLineChildsResultExist = Object.keys(
      hasDropLineChildsResult
    ).length

    // console.log(
    //   // hasDropLineChildOneOnlyResult,
    //   hasDropLineChildsResult,
    //   nodeData[idField],
    //   hasDropLineChildsResultExist
    // )
    return hasDropLineChildsResultExist === 1 &&
      nodeData?.children?.length === 1 ? (
      <div
        key={`render_${nodeData[idField]}`}
        drop_size={hasDropLineChildsResult?.[nodeData[idField]]?.dropSize}
        id_field={nodeData[idField]}
        style={
          {
            // marginTop: "130px"
            // position: "inherit",
            // top: "130px"
          }
        }
        className="myChart-node drop-line"
      >
        <div
          style={{
            backgroundColor: "var(--primary-color)"
            // width: getWidth(nodeData)
          }}
          className="position"
        >
          <span>
            {nodeData?.[titleField]}
            <br />
            <span
              className="position-description"
              style={{
                fontWeight: "300"
              }}
            >
              {nodeData?.[descField] ?? ""}
            </span>
          </span>
        </div>
        <div
          style={
            {
              // width: getWidth(nodeData)
            }
          }
          className="fullname"
        >
          {nodeData?.[detailField] && renderDetView(nodeData?.[detailField])}
        </div>
      </div>
    ) : isDropLine(nodeData) ? (
      <div
        key={`render_${nodeData[idField]}`}
        // drop_size={hasDropLineChildsResult?.[nodeData[idField]]?.dropSize}
        id_field={nodeData[idField]}
        style={
          {
            // marginTop: "130px"
            // position: "inherit",
            // top: "130px"
          }
        }
        className="myChart-node drop-line-child"
      >
        <div
          style={{
            backgroundColor: "var(--primary-color)"
            // width: getWidth(nodeData)
          }}
          className="position"
        >
          <span>
            {nodeData?.[titleField]}
            <br />
            <span
              className="position-description"
              style={{
                fontWeight: "300"
              }}
            >
              {nodeData?.[descField] ?? ""}
            </span>
          </span>
        </div>
        <div
          style={
            {
              // width: getWidth(nodeData)
            }
          }
          className="fullname"
        >
          {nodeData?.[detailField] && renderDetView(nodeData?.[detailField])}
        </div>
      </div>
    ) : (
      <div key={`render_${nodeData[idField]}`} className="myChart-node">
        <div
          style={{
            backgroundColor: "var(--primary-color)"
            // width: getWidth(nodeData)
          }}
          className="position"
        >
          <span>
            {nodeData?.[titleField]}
            <br />
            <span
              className="position-description"
              style={{
                fontWeight: "300"
              }}
            >
              {nodeData?.[descField] ?? ""}
            </span>
          </span>
          {/* <div
            style={{
              backgroundColor: "var(--primary-color)"
              // width: getWidth(nodeData)
            }}
            className="position-description"
          >
          </div> */}
        </div>
        <div
          style={{
            display:
              nodeData?.[levelField] === 5 &&
              nodeData?.[detailField]?.length > 1
                ? "block"
                : "flex",
            height:
              nodeData?.[levelField] === 5 &&
              nodeData?.[detailField]?.length > 1
                ? "auto"
                : "50px",
            minHeight: "50px"
          }}
          className="fullname"
        >
          {nodeData?.[detailField] && renderDetView(nodeData?.[detailField])}
          {/* {nodeData.name} */}
        </div>
      </div>
    )
  }

  // return <NoDataDisplay />
  return store.pageData[storeName]?.data &&
    !store.pageData[storeName]?.isLoading ? (
    <>
      <div style={{ position: "absolute", zIndex: 1 }} className="">
        <Button.Ripple onClick={exportTo} color="relief-primary">
          {t("Export")}
        </Button.Ripple>
      </div>
      <OrganizationChart
        containerClass="orgchart-container-custom"
        chartClass="myChart"
        NodeTemplate={MyNode}
        datasource={
          store.pageData[storeName]?.data.map((item) => {
            let newData = { ...item }
            newData = addTitleToChildren(newData, titleField)
            // let nuData = { ...item, title: item[titleField] }
            return newData
          })[0] ?? {}
        }
        ref={orgchart}
        pan={true}
        zoom={true}
        // draggable={true}
        collapsible={true}
        // zoominLimit={4}
        zoomoutLimit={0}
        onClickNode={handleAddEdit}
      />
      {showModal && (
        <ModalAddEdit
          pageAttr={pageAttr}
          storeName={storeName}
          pageType={pageType}
          show={showModal}
          setShow={toggleSidebar}
          detailPage={pageAttr?.crudOpt?.detailPage}
          setPageType={setPageType}
          loading={loadingModal}
          detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
          filtersCustomAdd={filtersCustomAdd}
        />
      )}
    </>
  ) : (
    <NoDataDisplay />
  )
}

export default DefaultChart
