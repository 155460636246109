/* eslint-disable multiline-ternary */
import UILoader from "@components/ui-loader"
import { Loader } from "@src/shared/CommonComponents"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import {
  getDepthValue,
  parseStrAndField,
  restrictedMenuLink,
  getUserData
} from "@utils"
import "moment/locale/id"
import { useContext, useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap"

import DynamicForm from "@src/utility/generateForm/dynamicForm"
import DynamicFormLoading from "@src/utility/generateForm/dynamicFormLoading"

const ModalAddEditLanding = ({
  defaultFormValues,
  dynamicForm,
  show,
  setShow,
  headers,
  root,
  fieldArray,
  setFieldArray,
  storeName,
  pageAttr,
  submitHandle
}) => {
  const { t } = useTranslation()
  const userData = getUserData()

  function handleReset() {
    setShow(false)
  }

  function handleSubmit(data) {
    setShow(false)
    submitHandle(data)
  }

  return (
    <Modal
      isOpen={show}
      toggle={() => {
        handleReset()
      }}
      className={`modal-dialog-centered modal-lg`}
    >
      {/* <UILoader
        blocking={loadingPostPut}
        overlayColor="rgba(115, 103, 240, .1)"
        loader={<Loader loadingText={loadingText} />}
      > */}
      <ModalHeader
        className="bg-transparent"
        toggle={() => {
          handleReset()
        }}
      ></ModalHeader>
      <ModalBody className="px-sm-2 pt-50 pb-5">
        <div className="text-center mb-2">
          <h2 className="saba-detail-header mb-0">
            {t(headers?.title ?? "Edit Detail Content")}
          </h2>
        </div>

        <DynamicForm
          dynamicForm={dynamicForm}
          handleReset={handleReset}
          handleFormSubmit={handleSubmit}
          defaultFormValues={defaultFormValues}
          // hideAllFotterButton={
          //   restrictedMenuLink.includes(window.location.pathname) &&
          //   ![4, 6, 21].includes(userData.id)
          // }
          editForm={true}
        />
      </ModalBody>

      {/* </UILoader> */}
    </Modal>
  )
}

export default ModalAddEditLanding
