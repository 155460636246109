// ** Third Party Components
import Chart from "react-apexcharts"
import Flatpickr from "react-flatpickr"
import { Calendar, Download, RefreshCw } from "react-feather"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardSubtitle
} from "reactstrap"
import DynamicForm from "@src/utility/generateForm/dynamicForm"
import { RefreshOutlined } from "@mui/icons-material"

const BarStackedChart = ({
  pageAttr,
  currentAttr,
  item,
  handleChangeFilterFromChild
}) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const { t } = useTranslation()

  const beforeHandleChangeFilterFromChild = (params) => {
    // console.log(params)
    handleChangeFilterFromChild({
      item: currentAttr,
      ...params
    })
  }

  const areaColors = item?.chartOpt?.colors ?? {
    series1: "#a4f8cd",
    series2: "#60f2ca",
    series3: "#2bdac7",
    series4: "#2bdac6"
  }

  // ** Chart Options
  const options = {
    chart: {
      parentHeightOffset: 0,
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          download:
            '<span class="material-icons-outlined ms-1 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">file_download</span>'
          //     customIcons: [
          //       {
          //         icon: '<span class="material-icons-outlined ms-1 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">refresh</span>',
          //         // index: 4,
          //         title: t("Refresh Data"),
          //         class: "custom-icon",
          //         click: function (chart, options, e) {
          //           console.log("clicked custom-icon")
          //           return handleChangeFilterFromChild({
          //             item: currentAttr
          //           })
          //         }
          //       }
          //     ]
        }
      }
    },
    plotOptions: item?.plotOptions ?? {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        colors: {
          backgroundBarRadius: 10
        }
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
      curve: "straight"
    },
    legend: {
      position: "top",
      horizontalAlign: "start"
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    colors: [
      areaColors.series1,
      areaColors.series2,
      areaColors.series3,
      areaColors.series4
    ],
    xaxis: {
      categories: store.data[item.chart_id]?.[0]?.categories?.split(","),
      title: {
        text: item?.chartOpt?.xaxis_title ? t(item?.chartOpt?.xaxis_title) : ""
      }
    },
    // fill: {
    //   // opacity: 1,
    //   // type: "solid"
    //   type: "gradient",
    //   gradient: {
    //     shade: "light",
    //     type: "horizontal",
    //     shadeIntensity: 0.5,
    //     gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
    //     inverseColors: true,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 50, 100],
    //     colorStops: []
    //   }
    // },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return item?.chartOpt?.tooltip_suffix
            ? `${val} ${t(item?.chartOpt?.tooltip_suffix)}`
            : ""
        }
      }
    },
    yaxis: {
      // labels: {
      //   formatter: function (val) {
      //     return val.toFixed(0)
      //   }
      // },
      title: {
        text: item?.chartOpt?.yaxis_title ? t(item?.chartOpt?.yaxis_title) : ""
      }
    }
  }

  // ** Chart Series
  const series = store.data[item.chart_id].map((row) => {
    return {
      name: `${row?.["name"] ?? ""}`,
      data: row?.["data"]?.split(",")?.map((e) => parseInt(e))
    }
  })

  return (
    series && (
      <Chart
        key={item.chart_id}
        options={options}
        series={series}
        type="bar"
        height={item?.chartOpt?.height ?? "100%"}
      />
    )
  )
}
export default BarStackedChart
