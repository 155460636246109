import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getRespData, getSeries } from "@utils"

const LineChart = ({
  pageAttr,
  currentAttr,
  item,
  handleChangeFilterFromChild
}) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const { t } = useTranslation()

  const areaColors = item?.chartOpt?.colors ?? {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7"
  }

  // ** Chart Options
  const options = item?.chartOptAll
    ? {
        ...item?.chartOptAll,
        ...{
          yaxis: {
            ...item?.chartOptAll?.yaxis,
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            },
            title: {
              text: item?.chartOpt?.yaxis_title
                ? t(item?.chartOpt?.yaxis_title)
                : ""
            }
          },
          xaxis: {
            ...item?.chartOptAll?.xaxis,
            categories: item?.chartOpt?.reverse_categories
              ? getRespData(item, "categories", store)?.slice()?.reverse()
              : getRespData(item, "categories", store),
            title: {
              text: item?.chartOpt?.xaxis_title
                ? t(item?.chartOpt?.xaxis_title)
                : ""
            }
          }
        }
      }
    : {
        ...{
          chart: {
            zoom: {
              enabled: false
            },
            parentHeightOffset: 0,
            toolbar: {
              show: true,
              tools: {
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
                download:
                  '<span class="material-icons-outlined ms-25 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">file_download</span>'
                // customIcons: [
                //   {
                //     icon: '<span class="material-icons-outlined ms-1 align-middle notranslate MuiIcon-root MuiIcon-fontSizeMedium css-kp9ftd-MuiIcon-root">refresh</span>',
                //     // index: 4,
                //     title: t("Refresh Data"),
                //     class: "custom-icon",
                //     click: function (chart, options, e) {
                //       // console.log("clicked custom-icon")
                //       return handleChangeFilterFromChild({
                //         item: currentAttr
                //       })
                //     }
                //   }
                // ]
              }
            }
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: ["#fff"],
            colors: getRespData(item, "color", store) ?? [
              areaColors.series3,
              areaColors.series2,
              areaColors.series1
            ]
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: "straight"
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            }
          },
          tooltip: {
            shared: false,
            y: {
              formatter: function (val) {
                return item?.chartOpt?.tooltip_suffix
                  ? `${val} ${t(item?.chartOpt?.tooltip_suffix)}`
                  : ""
              }
            }
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              }
            },
            title: {
              text: item?.chartOpt?.yaxis_title
                ? t(item?.chartOpt?.yaxis_title)
                : ""
            }
          },
          xaxis: {
            categories: getRespData(item, "categories", store),
            title: {
              text: item?.chartOpt?.xaxis_title
                ? t(item?.chartOpt?.xaxis_title)
                : ""
            }
          },
          colors: getRespData(item, "color", store) ?? [
            areaColors.series3,
            areaColors.series2,
            areaColors.series1
          ]
          // yaxis: {
          //   opposite: direction === "rtl"
          // }
        }
      }

  // ** Chart Series
  const series = getSeries(item, store)

  return (
    series && (
      <Chart
        options={options}
        series={series}
        type="line"
        height={item?.chartOpt?.height ?? "100%"}
      />
    )
  )
}

export default LineChart
