import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getBaseUrl } from "@src/shared/dynamic_pages/store"

export const getDataCache = createAsyncThunk(
  "getDataCache",
  async (params, { getState, rejectWithValue }) => {
    try {
      const baseUrlOpt =
        params?.baseUrl ??
        getState()["dynamicStore"]?.pageAttributes[params?.storeName]?.crudOpt
          ?.baseUrl
      const response = await getBaseUrl(baseUrlOpt, params.url).get(
        `${params.url}`
      )
      return {
        data: response.data,
        params: params
      }
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDataCache = createAsyncThunk(
  "setDataCache",
  (data, { rejectWithValue }) => {
    // console.log("setDataCache")
    return rejectWithValue(data)
  }
)

export const setParentDataRelation = createAsyncThunk(
  "setParentDataRelation",
  (data, { rejectWithValue }) => {
    // console.log("setDataCache")
    return rejectWithValue(data)
  }
)

export const setLastApiPar = createAsyncThunk(
  "setLastApiPar",
  (data, { rejectWithValue }) => {
    // console.log("setDataCache")
    return rejectWithValue(data)
  }
)

export const setLastFilters = createAsyncThunk(
  "setLastFilters",
  (data, { rejectWithValue }) => {
    // console.log("setDataCache") dispatch(
    return rejectWithValue(data)
  }
)

export const setWidgetAutoRefresh = createAsyncThunk(
  "setWidgetAutoRefresh",
  (data, { rejectWithValue }) => {
    // console.log("setDataCache") dispatch(
    return rejectWithValue(data)
  }
)

function getDepthValue(obj, path, defaultValue) {
  let props
  if (typeof obj === "undefined") return defaultValue
  if (typeof path === "string") {
    props = path.split(".").reverse()
  } else {
    props = path
  }
  if (path.length === 0) return obj || defaultValue
  let current = props.pop()
  return getDepthValue(obj[current], props, defaultValue)
}

export const DynamicTempState = createSlice({
  name: "dynamic_temp_state",
  initialState: {
    data: {},
    parent_data_relation: {},
    params: {},
    apiPar: {},
    widget_auto_refresh: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDataCache.fulfilled, (state, action) => {
      // const item = action.payload.params.item
      // // console.log(item)
      // let stateDataCopy = { ...state.data }
      // if (item?.dataFrom?.field_label || item?.dataFrom?.field_value) {
      //   stateDataCopy[item?.fieldName] = action.payload.data.map((r) => {
      //     const container = {}
      //     container["label"] = item?.dataFrom?.field_label.includes(".")
      //       ? getDepthValue(r, item?.dataFrom?.field_label)
      //       : r[item.dataFrom.field_label]
      //     if (item?.dataFrom?.field_img) {
      //       container["img"] = item?.dataFrom?.field_img.includes(".")
      //         ? getDepthValue(r, item?.dataFrom?.field_img)
      //         : r[item.dataFrom.field_img]
      //     }
      //     container["value"] = item?.dataFrom?.field_value.includes(".")
      //       ? getDepthValue(r, item?.dataFrom?.field_value)
      //       : r[item.dataFrom.field_value]
      //     return container
      //   })
      //   state.data = stateDataCopy
      // } else {
      //   stateDataCopy[item?.fieldName] = action.payload.data
      //   state.data = stateDataCopy
      // }
    })

    builder.addCase(setDataCache.rejected, (state, action) => {
      let stateDataCopy = { ...state.data, ...action.payload }
      // console.log(stateDataCopy)
      state.data = stateDataCopy
      console.log("setDataCache success")
    })

    builder.addCase(setParentDataRelation.rejected, (state, action) => {
      let stateDataCopy = { ...state.parent_data_relation, ...action.payload }
      // console.log(stateDataCopy)
      state.parent_data_relation = stateDataCopy
      // console.log("setParentDataRelation success")
    })

    builder.addCase(setLastApiPar.rejected, (state, action) => {
      let stateDataCopy = { ...state.apiPar, ...action.payload }
      // console.log(stateDataCopy)
      state.apiPar = stateDataCopy
      // console.log("setLastApiPar success")
    })

    builder.addCase(setLastFilters.rejected, (state, action) => {
      let stateDataCopy = { ...state.params, ...action.payload }
      // console.log(stateDataCopy)
      state.params = stateDataCopy
      // console.log("setLastFilters success")
    })

    builder.addCase(setWidgetAutoRefresh.rejected, (state, action) => {
      let stateDataCopy = [...state.widget_auto_refresh, ...action.payload]
      // console.log(stateDataCopy)
      state.widget_auto_refresh = stateDataCopy
    })
  }
})

export default DynamicTempState.reducer
