// ** React Imports
import { useState, useEffect, useRef, Fragment } from "react"

// ** Custom Components
import Avatar from "@components/avatar"
import { useSearchParams } from "react-router-dom"

import { getMyNotifications } from "@store/notifications"
// ** Store & Actions
import {
  selectChat,
  selectChatFromCache,
  setIncomingMessageState
} from "./store"
import { useDispatch } from "react-redux"

// ** Utils
import { formatDateToMonthShort, isObjEmpty, convertHtmlToEmoji } from "@utils"

// ** Third Party Components
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import { X, Search, CheckSquare, Bell, User, Trash } from "react-feather"

// ** Reactstrap Imports
import {
  CardText,
  InputGroup,
  InputGroupText,
  Badge,
  Input,
  Button,
  Label
} from "reactstrap"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import "moment-timezone"
import "moment/locale/id"
import defaultAvatar from "@src/assets/images/avatars/avatar-blank.png"

import { renderIcon } from "@src/views/apps/menu/store/my_menu"

import { badgeChatDecrement } from "@store/notifications"
import { Skeleton } from "@mui/material"
const SidebarLeft = (props) => {
  // ** Props & Store
  const {
    store,
    sidebar,
    handleSidebar,
    userSidebarLeft,
    handleUserSidebarLeft,
    userData,
    clickSideBarId,
    setActiveUserChat
  } = props
  const {
    chats,
    contacts,
    userProfile,
    contacts_chats_loading,
    contacts_chats_success_last_fetch,
    selectedUsers,
    incoming_message_load,
    selectedUsers_loading
  } = store
  const { t, i18n } = useTranslation()

  // ** Dispatch
  const dispatch = useDispatch()
  const chatsListRef = useRef([])

  // ** State
  const [query, setQuery] = useState("")
  const [about, setAbout] = useState("")
  const [active, setActive] = useState(0)
  const [status, setStatus] = useState("online")
  const [filteredChat, setFilteredChat] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])

  const [searchParams, setSearchParams] = useSearchParams()

  // const [firstLoad, setFirstLoad] = useState(false)
  const handleUserClick = async (
    id,
    setParam = false,
    data = null,
    e = null
  ) => {
    // console.log(id, setParam, data, sidebar)
    if (e) {
      e.preventDefault()
    }

    // console.log(id, setParam, data)

    try {
      setActive(id)
      setActiveUserChat(id)
      if (sidebar === true) {
        handleSidebar()
      }

      if (setParam) {
        setQueryParam(id)
      }

      const index = selectedUsers_loading?.indexOf(id)
      if (index === -1) {
        await dispatch(selectChat(id))
      }

      if (data?.unseenMsgs > 0) {
        await dispatch(badgeChatDecrement({ count: data?.unseenMsgs }))
      }

      if (sessionStorage.getItem("counterUpdate") === "true") {
        dispatch(getMyNotifications({ unread_count: 1 })).then(() =>
          sessionStorage.removeItem("counterUpdate")
        )
      }
    } catch (error) {
      console.error("Error handling user click:", error)
    }
  }

  const setQueryParam = (id) => {
    // searchParams.set("current", `${id}`)
    // if (searchParams.has("fromSideBar")) {
    //   searchParams.delete("fromSideBar")
    // }
    // if (searchParams.has("refresh")) {
    //   searchParams.delete("refresh")
    // }
    // setSearchParams(searchParams)

    sessionStorage.setItem("current", id)
    if (sessionStorage.getItem("fromSideBar")) {
      sessionStorage.removeItem("fromSideBar")
    }
    if (sessionStorage.getItem("refresh")) {
      sessionStorage.removeItem("refresh")
    }
  }

  const initialRender = useRef(true)
  useEffect(
    () => {
      if (initialRender.current) {
        initialRender.current = false
        // console.log("here...")
        if (!isObjEmpty(store.selectedUser)) {
          if (store.selectedUser.chat) {
            setActive(store.selectedUser.chat.id)
          } else {
            setActive(store.selectedUser.contact.id)
          }
        }

        const paramId = sessionStorage.getItem("current")
        const fromSideBar = sessionStorage.getItem("fromSideBar") === "true"
        const refresh = sessionStorage.getItem("refresh")

        if (
          (paramId && fromSideBar) ||
          (paramId && refresh) ||
          (paramId && !fromSideBar && isObjEmpty(store.selectedUser))
        ) {
          handleUserClick(paramId)
          if (refresh) {
            sessionStorage.removeItem("refresh")
          }
        }

        // .dispatch(getMyNotifications({ unread_count: 1 }))
        // const paramId = searchParams.get("current")
        // const fromSideBar = searchParams.get("fromSideBar")
        // const refresh = searchParams.get("refresh")

        // if (
        //   (paramId && fromSideBar) ||
        //   (paramId && refresh) ||
        //   (paramId && !fromSideBar && isObjEmpty(store.selectedUser))
        // ) {
        //   handleUserClick(paramId)
        //   if (refresh) {
        //     searchParams.delete("refresh")
        //     // setSearchParams(searchParams)
        //   }
        // }
      }
    },
    [
      // store.selectedUser
    ]
  )

  // ** Renders Chat
  const renderChats = () => {
    if (chats && chats.length) {
      if (query.length && !filteredChat.length) {
        return (
          <li className="no-results show">
            <h6 className="mb-0">{t("No Chats Found")}</h6>
          </li>
        )
      } else {
        const arrToMap =
          query.length && filteredChat.length ? filteredChat : chats

        return arrToMap.map((item) => {
          // const time = formatDateToMonthShort(
          //   item?.message ? item?.timestamp : new Date()
          // )

          const time = (
            <Moment fromNow locale={i18n.language ?? "id"}>
              {item?.message ? item?.timestamp : new Date()}
            </Moment>
          )

          return (
            <li
              id={`chat_list_${item.current}`}
              ref={(ele) => (chatsListRef.current[item.current] = ele)}
              key={item.current}
              onClick={(e) => handleUserClick(item.current, true, item, e)}
              className={classnames("align-items-center", {
                active:
                  active === item.current ||
                  `${item.current}` === sessionStorage.getItem("current")
              })}
            >
              <Avatar
                img={item?.current_data_avatar ?? defaultAvatar}
                imgHeight="42"
                imgWidth="42"
                status={
                  item?.current_data_isOnline === true ? "online" : "offline"
                }
              />
              <div className="chat-info flex-grow-1">
                <h5 className="mb-0">{item?.current_data_name}</h5>
                {item?.from_me === false ? (
                  <CardText className="text-truncate">
                    {/* {item?.lastMessage
                    ? item?.lastMessage.message
                    : chats[chats.length - 1].message} */}
                    {convertHtmlToEmoji(item?.message, false)}
                  </CardText>
                ) : (
                  <CardText className="text-truncate">
                    {/* {item?.lastMessage
                    ? item?.lastMessage.message
                    : chats[chats.length - 1].message} */}
                    <span>
                      {item?.readAt && item?.deliveredAt
                        ? renderIcon(
                            "material:done_all",
                            "me-25 text-warning",
                            14
                          )
                        : !item?.readAt && item?.deliveredAt
                        ? renderIcon("material:done_all", "me-25", 14)
                        : renderIcon("material:done", "me-25", 14)}
                    </span>
                    {convertHtmlToEmoji(item?.message, false)}
                  </CardText>
                )}
              </div>
              <div className="chat-meta text-wrap">
                <small
                  style={{
                    fontSize: "9px"
                  }}
                  className="float-end text-end mb-25 chat-time ms-25"
                >
                  {time}
                </small>
                {item?.unseenMsgs >= 1 ? (
                  <Badge className="float-end" color="danger" pill>
                    {item?.unseenMsgs}
                  </Badge>
                ) : null}
              </div>
            </li>
          )
        })
      }
    } else {
      if (contacts_chats_loading && !contacts_chats_success_last_fetch) {
        return [1, 2, 3, 4, 5].map((item) => {
          return (
            <li key={item} className="align-items-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={42}
                height={42}
              />
              <div className="chat-info mt-0 flex-grow-1">
                <Skeleton
                  animation="wave"
                  height={30}
                  width={140}
                  className="mb-0"
                />
                <Skeleton
                  animation="wave"
                  height={16}
                  width={200}
                  className="card-text text-truncate"
                />
              </div>
              <div className="chat-meta text-nowrap">
                <small className="float-end mb-25 chat-time ms-25">
                  <Skeleton animation="wave" height={16} width={35} />
                </small>
              </div>
            </li>
          )
        })
      } else {
        return null
      }
    }
  }

  // ** Renders Contact
  const renderContacts = () => {
    if (contacts && contacts.length) {
      if (query.length && !filteredContacts.length) {
        return (
          <li className="no-results show align-items-center">
            <h6 className="mb-0">{t("No Contacts Found")}</h6>
          </li>
        )
      } else {
        const arrToMap =
          query.length && filteredContacts.length ? filteredContacts : contacts
        return arrToMap.map((item) => {
          return (
            <li
              className="align-items-center"
              key={item.id}
              onClick={(e) => handleUserClick(item.id, true, null, e)}
            >
              <Avatar
                img={item.avatar ?? defaultAvatar}
                imgHeight="42"
                imgWidth="42"
                status={item?.isOnline === true ? "online" : "offline"}
              />
              <div className="chat-info flex-grow-1">
                <h5 className="mb-0">{item.name}</h5>
                {item?.last_request_time &&
                  (item?.isOnline === false ? (
                    <CardText className="text-truncate">
                      {t("Last Online at")}{" "}
                      <Moment locale={i18n.language ?? "id"} fromNow>
                        {item?.last_request_time}
                      </Moment>
                    </CardText>
                  ) : item?.isOnlineOtherApp ? (
                    <Fragment>
                      <span>{t("Online di App")} </span>
                      <b>{item?.app_name ?? ""}</b>
                    </Fragment>
                  ) : (
                    t("Online")
                  ))}
              </div>
            </li>
          )
        })
      }
    } else {
      if (contacts_chats_loading && !contacts_chats_success_last_fetch) {
        return [1, 2, 3, 4, 5].map((item) => {
          return (
            <li key={item} className="align-items-center">
              <Skeleton
                animation="wave"
                variant="circular"
                width={42}
                height={42}
              />
              <div className="chat-info mt-0 flex-grow-1">
                <Skeleton
                  animation="wave"
                  height={30}
                  width={140}
                  className="mb-0"
                />
                <Skeleton
                  animation="wave"
                  height={16}
                  width={200}
                  className="card-text text-truncate"
                />
              </div>
              <div className="chat-meta text-nowrap">
                <small className="float-end mb-25 chat-time ms-25">
                  <Skeleton animation="wave" height={16} width={35} />
                </small>
              </div>
            </li>
          )
        })
      } else {
        return null
      }
    }
  }

  // ** Handles Filter
  const handleFilter = (e) => {
    setQuery(e.target.value)
    const searchFilterFunction = (contact) =>
      contact.name?.toLowerCase().includes(e.target.value.toLowerCase())
    const filteredChatsArr = chats.filter(searchFilterFunction)
    const filteredContactssArr = contacts.filter(searchFilterFunction)
    setFilteredChat([...filteredChatsArr])
    setFilteredContacts([...filteredContactssArr])
  }

  const renderAboutCount = () => {
    if (
      userProfile &&
      userProfile.about &&
      userProfile.about.length &&
      about.length === 0
    ) {
      return userProfile.about.length
    } else {
      return about.length
    }
  }

  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return store ? (
    <div className="sidebar-left">
      <div className="sidebar">
        <div
          className={classnames("sidebar-content", {
            show: sidebar === true
          })}
        >
          <div className="sidebar-close-icon" onClick={handleSidebar}>
            <X size={14} />
          </div>
          <div className="chat-fixed-search">
            <div className="d-flex align-items-center w-100">
              <div
                className="sidebar-profile-toggle"
                onClick={handleUserSidebarLeft}
              >
                <Avatar
                  className="avatar-border"
                  img={userAvatar}
                  status={status}
                  imgHeight="42"
                  imgWidth="42"
                />
              </div>
              <InputGroup className="input-group-merge ms-1 w-100">
                <InputGroupText className="round">
                  <Search className="text-muted" size={14} />
                </InputGroupText>
                <Input
                  value={query}
                  className="round"
                  placeholder={t("Search or start a new chat")}
                  onChange={handleFilter}
                />
              </InputGroup>
            </div>
          </div>
          <PerfectScrollbar
            className="chat-user-list-wrapper list-group"
            options={{ wheelPropagation: false }}
          >
            <h4 className="chat-list-title">{t("Chats")}</h4>
            <ul className="chat-users-list chat-list media-list">
              {renderChats()}
            </ul>
            <h4 className="chat-list-title">{t("Contacts")}</h4>
            <ul className="chat-users-list contact-list media-list">
              {renderContacts()}
            </ul>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default SidebarLeft
