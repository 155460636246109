import { useEffect, useState, useRef, Fragment } from "react"
import Stepper from "bs-stepper"
import classnames from "classnames"
import { PropTypes } from "prop-types"
import { ArrowLeft, ArrowRight, ChevronRight } from "react-feather"
import { useTranslation } from "react-i18next"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"

// ** Styles
import "bs-stepper/dist/css/bs-stepper.min.css"
import "../../../@core/scss/base/plugins/forms/form-wizard.scss"
import { Button, Row } from "reactstrap"

const InputWizard = ({
  dynamicFormWizard = [],
  className = "",
  type = 1,
  headerClassName = "",
  contentClassName = "",
  separator = <ChevronRight size={17} />,
  contentWrapperClassName = "",
  options = {
    linear: false,
    animation: true
  },
  form,
  formId,
  cancelBtn,
  submitBtn,
  editBtn,
  dynamicForm,
  formValues,
  getFormValues,
  setErrorForm
}) => {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)

  function checkAllRequiredFilled(inputs) {
    // console.log(getFormValues)
    let result = true
    let isLoadStateArr = []
    let isLoadStateArrInputs = []
    dynamicForm
      .filter(
        (x) =>
          ((x?.rules?.required === true || x?.required === true) &&
            inputs?.includes(x.fieldName)) ||
          (x?.type === "column" && inputs?.includes(x.fieldName))
      )
      .map((y) => {
        // console.log(y)
        if (y?.type === "column") {
          // console.log(y?.children)
          const childrenColumn = y?.children
          if (childrenColumn) {
            const childrenColumnRequired = childrenColumn.filter(
              (childs) =>
                childs?.rules?.required === true || childs?.required === true
            )
            if (childrenColumnRequired) {
              childrenColumnRequired.map((child) => {
                const stateInput = getFormValues?.[child.fieldName] && true
                if (!stateInput) {
                  isLoadStateArrInputs.push(child.fieldName)
                }
                isLoadStateArr.push(stateInput)
              })
            }
          }
        } else {
          const stateInput = getFormValues?.[y.fieldName] && true
          if (!stateInput) {
            isLoadStateArrInputs.push(y.fieldName)
          }
          isLoadStateArr.push(stateInput)
        }
      })

    // console.log(isLoadStateArr)

    if (isLoadStateArr.length) {
      result = isLoadStateArr?.every((value) => value === true)
    }

    if (!result && isLoadStateArrInputs?.length > 0) {
      for (const indexx in isLoadStateArrInputs) {
        setErrorForm(isLoadStateArrInputs[indexx], {
          type: "manual",
          message: `This Input field is required!`
        })
      }
    }

    return result
  }

  useEffect(() => {
    setActiveIndex(0)
    showStep(0)
    const stepZeroElement = document.getElementById("step-0")
    if (stepZeroElement) {
      stepZeroElement?.classList.remove("dstepper-none", "fade")
    }
  }, [])

  const handleStep = (e, indexClicked) => {
    const canNextStep = checkAllRequiredFilled(
      dynamicFormWizard[activeIndex]?.content ?? []
    )
    if (canNextStep) {
      const nextIndex = indexClicked
      setActiveIndex(nextIndex)
    } else {
      console.log("required input is not filled")
    }
  }

  const handleNext = () => {
    if (activeIndex < dynamicFormWizard.length - 1) {
      const canNextStep = checkAllRequiredFilled(
        dynamicFormWizard[activeIndex]?.content ?? []
      )
      if (canNextStep) {
        const nextIndex = activeIndex + 1
        setActiveIndex(nextIndex)
      } else {
        console.log("required input is not filled")
      }
    }
  }

  const handlePrevious = () => {
    if (activeIndex > 0) {
      const prevIndex = activeIndex - 1
      setActiveIndex(prevIndex)
    }
  }

  const hideElementsWithPrefixInForm = (formIdx, prefix, isToShow) => {
    const form = document.getElementById(formIdx)
    if (form) {
      const elements = form.querySelectorAll(`[id^="${prefix}"]`)
      if (elements) {
        elements.forEach((element) => {
          // element.style.display = "none"
          if (element && !element?.classList?.contains("hidden")) {
            if (!isToShow) {
              element.classList.add("d-none-wizard")
            } else {
              element.classList.add("d-block-wizard")
            }
          }
        })
      }
    } else {
      console.error(`Form with ID "${formIdx}" not found.`)
    }
  }

  const showStep = (index) => {
    // hideElementsWithPrefixInForm(formId, "col_")
    dynamicFormWizard.forEach((step, stepIndex) => {
      const contents = step?.content ?? []
      // console.log(contents)
      contents.forEach((fieldName) => {
        const idEl = `col_${fieldName}`
        const element = document.getElementById(idEl)
        // console.log(element)
        if (element) {
          let isGroupInput = element.classList.contains("input_column_type")
          // console.log(isGroupInput)
          if (!element.classList.contains("hidden")) {
            if (stepIndex === index) {
              // element.style.display = "block"
              element.classList.remove("d-none-wizard")
              element.classList.add("d-block-wizard")
              if (isGroupInput) {
                hideElementsWithPrefixInForm(idEl, "col_", true)
              }
            } else {
              // element.style.display = "none"
              element.classList.remove("d-block-wizard")
              element.classList.add("d-none-wizard")
              if (isGroupInput) {
                hideElementsWithPrefixInForm(idEl, "col_")
              }
            }
          }
        }
      })
    })
  }

  useEffect(() => {
    hideElementsWithPrefixInForm(formId, "col_")
    showStep(activeIndex)
  }, [activeIndex])

  // ** Renders Wizard Header
  const renderHeader = () => {
    return dynamicFormWizard.map((step, index) => (
      <Fragment key={index}>
        {index !== 0 && index !== dynamicFormWizard.length ? (
          <div className="line">{separator}</div>
        ) : null}
        <div
          className={classnames("step", {
            active: index === activeIndex
          })}
          onClick={(e) => handleStep(e, index)}
          key={`step_header_${index}`}
        >
          <button type="button" className="step-trigger">
            <span className="bs-stepper-box">
              {step.icon ? renderIcon(step.icon, "", 20, {}) : index + 1}
            </span>
            <span className="bs-stepper-label">
              <span className="bs-stepper-title">{step.title}</span>
              {step.subtitle && (
                <span className="bs-stepper-subtitle">{step.subtitle}</span>
              )}
            </span>
          </button>
        </div>
      </Fragment>
    ))
  }

  const renderContent = () => {
    return dynamicFormWizard.map((step, index) => (
      <div
        className={classnames("content", {
          "active dstepper-block": activeIndex === index,
          "dstepper-none": activeIndex !== index,
          "mh-auto": type === 2 || type === 3
        })}
        id={`step-${index}`}
        key={`step_content_${index}`}
      >
        {(step.showContentHeader === undefined ||
          step.showContentHeader === true) && (
          <div className="content-header mb-2">
            <h5 className="mb-0">{step.title}</h5>
            {step.description && <small>{step.description}</small>}
          </div>
        )}
      </div>
    ))
  }

  const getContainerClass = (type) => {
    if (type === 1) {
      return "horizontal-wizard"
    } else if (type === 2) {
      return "vertical-wizard"
    } else if (type === 3) {
      return "modern-vertical-wizard"
    } else {
      return "modern-horizontal-wizard"
    }
  }

  return (
    <div className={`${getContainerClass(type)} mb-0`}>
      <div
        ref={ref}
        style={{ boxShadow: "none" }}
        className={classnames("bs-stepper", {
          // vertical: type === "vertical",
          // "vertical wizard-modern": type === "modern-vertical",
          // "wizard-modern": type === "modern-horizontal"
          vertical: type === 2,
          "vertical wizard-modern": type === 3,
          "wizard-modern": type === 4
        })}
      >
        <div
          className={classnames("bs-stepper-header", {
            "px-0": type === 1
          })}
        >
          {renderHeader()}
        </div>

        <div
          className={classnames("bs-stepper-content", {
            "px-0": type === 1
            // "pb-0": type === 1 || type === 2
          })}
        >
          {renderContent()}

          {form}

          <div className={`d-flex justify-content-between mt-2`}>
            <Button.Ripple
              type="button"
              disabled={activeIndex === 0}
              onClick={handlePrevious}
              color="secondary"
              outline
            >
              <ArrowLeft
                size={14}
                className="align-middle me-sm-25 me-0"
              ></ArrowLeft>
              <span className="align-middle d-sm-inline-block d-none">
                {t("Previous")}
              </span>
            </Button.Ripple>

            <div>
              {/* <Button.Ripple
                type="button"
                className="me-1"
                color="secondary"
                outline
              >
                {t("Cancel")}
              </Button.Ripple> */}

              {editBtn}

              {cancelBtn}

              {activeIndex < dynamicFormWizard.length - 1 && (
                <Button.Ripple
                  type="button"
                  className="ms-1"
                  onClick={handleNext}
                  color="primary"
                >
                  <span className="align-middle d-sm-inline-block d-none">
                    {t("Next")}
                  </span>
                  <ArrowRight
                    size={14}
                    className="align-middle ms-sm-25 ms-0"
                  ></ArrowRight>
                </Button.Ripple>
              )}

              {activeIndex === dynamicFormWizard.length - 1 && //   {t("Submit")} // <Button.Ripple type="submit" color="success">
                // </Button.Ripple>
                submitBtn}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

InputWizard.propTypes = {
  pageAttr: PropTypes.object,
  dynamicFormWizard: PropTypes.array,
  className: PropTypes.string,
  type: PropTypes.Number,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  separator: PropTypes.node,
  contentWrapperClassName: PropTypes.string,
  options: PropTypes.object
}

export default InputWizard
