// ** React Imports
import { Fragment, useState, useEffect } from "react"

// ** Third Party Components
import classnames from "classnames"
// import * as Icons from "react-feather"
import * as Icons from "@mui/icons-material"

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Input,
  CardBody,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip
} from "reactstrap"

// ** Styles
import "@styles/base/pages/ui-feather.scss"
import { useDispatch, useSelector } from "react-redux"
import { setIconsComponents } from "../../../../redux/icons"
import { useTranslation } from "react-i18next"

const FeatherIcons = ({ setIcon, setCanvasOpen }) => {
  const IconsArr = []
  const IconsComponentsArr = []
  // ** States
  const [query, setQuery] = useState([]),
    [filteredArr, setFilteredArr] = useState([]),
    [filteredComponentsArr, setFilteredComponentsArr] = useState([]),
    [active, setActive] = useState(null)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const { t } = useTranslation()

  const handleFilter = (val) => {
    const arr = []
    const arrComponents = []
    if (val.length) {
      IconsArr.filter((icon) => {
        if (icon.toLowerCase().includes(val.toLowerCase())) {
          arr.push(icon)

          const IconTag = Icons[icon]
          arrComponents.push(
            <Fragment key={icon}>
              <Card
                id={icon}
                className={classnames(
                  "icon-card cursor-pointer text-center mb-2 mx-50",
                  {
                    active: active === icon
                  }
                )}
                onClick={() => handleIconCardClick(icon)}
              >
                <CardBody>
                  <div className="icon-wrapper">
                    <IconTag />
                  </div>
                  <p className="icon-name text-truncate mb-0 mt-1">{icon}</p>
                </CardBody>
              </Card>
              <UncontrolledTooltip placement="top" target={icon}>
                {icon.replace(/([A-Z])/g, " $1").trim()}
              </UncontrolledTooltip>
            </Fragment>
          )
        }
      })
    }
    setFilteredArr([...arr])
    setFilteredComponentsArr([...arrComponents])
  }

  function camelToUnderscore(key) {
    let result = key.replace(/([A-Z])/g, " $1")
    return result.split(" ").join("_").toLowerCase()
  }

  const handleIconCardClick = (icon) => {
    // console.log(icon)
    const customName = `material:${camelToUnderscore(icon).substring(1)}`
    setIcon(customName)
    setActive(icon)
    setCanvasOpen(false)
  }

  const renderIcons = () => {
    // console.log(IconsArr)
    const dataToRender = query.length ? filteredArr : IconsArr
    const componentsToRender = query.length
      ? filteredComponentsArr
      : IconsComponentsArr
    if (dataToRender.length) {
      // return dataToRender.map((icon) => {
      //   const IconTag = Icons[icon]
      //   return (
      //     <Fragment key={icon}>
      //       <Card
      //         id={icon}
      //         className={classnames(
      //           "icon-card cursor-pointer text-center mb-2 mx-50",
      //           {
      //             active: active === icon
      //           }
      //         )}
      //         onClick={() => handleIconCardClick(icon)}
      //       >
      //         <CardBody>
      //           <div className="icon-wrapper">
      //             <IconTag />
      //           </div>
      //           <p className="icon-name text-truncate mb-0 mt-1">{icon}</p>
      //         </CardBody>
      //       </Card>
      //       <UncontrolledTooltip placement="top" target={icon}>
      //         {icon.replace(/([A-Z])/g, " $1").trim()}
      //       </UncontrolledTooltip>
      //     </Fragment>
      //   )
      // })
      return componentsToRender
    } else {
      return (
        <div className="d-flex align-items-center justify-content-center w-100">
          <h4 className="mb-0">{t("No Icons Found!")}</h4>
        </div>
      )
    }
  }

  const store = useSelector((state) => state["icons_store"])
  const dispatch = useDispatch()

  useEffect(() => {
    console.log(store.data.material.length)
    if (store.data.material.length === 0) {
      // console.log(store.data.material)
      setLoadingText(`${t("Loading Data")} ${t("Icons")}`)

      for (const key in Icons) {
        if (key.includes("Outlined")) {
          IconsArr.push(key)

          const IconTag = Icons[key]
          IconsComponentsArr.push(
            <Fragment key={key}>
              <Card
                id={key}
                className={classnames(
                  "icon-card cursor-pointer text-center mb-2 mx-50",
                  {
                    active: active === key
                  }
                )}
                onClick={() => handleIconCardClick(key)}
              >
                <CardBody>
                  <div className="icon-wrapper">
                    <IconTag />
                  </div>
                  <p className="icon-name text-truncate mb-0 mt-1">{key}</p>
                </CardBody>
              </Card>
              <UncontrolledTooltip placement="top" target={key}>
                {key.replace(/([A-Z])/g, " $1").trim()}
              </UncontrolledTooltip>
            </Fragment>
          )
        }
      }
      dispatch(setIconsComponents({ material: IconsComponentsArr }))
    }
  }, [])

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <div className="icon-search-wrapper mt-0 mb-2 mx-auto">
            <InputGroup className="input-group-merge mb-1">
              <InputGroupText>
                {/* <Icons.Search size={14} /> */}
              </InputGroupText>
              <Input
                placeholder={t("Search icons...")}
                onChange={(e) => {
                  handleFilter(e.target.value)
                  setQuery(e.target.value)
                }}
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <div
        className="d-flex flex-wrap"
        style={{
          justifyContent: "center"
        }}
        id="icons-container"
      >
        {query.length ? filteredComponentsArr : store.data.material}
      </div>
    </Fragment>
  )
}
export default FeatherIcons
