// ** React Imports
import { Fragment, useEffect, memo, useState } from "react"

// ** Third Party Components
import classnames from "classnames"

// ** Store & Actions
import { useSelector, useDispatch } from "react-redux"
import {
  handleContentWidth,
  handleMenuCollapsed,
  handleMenuHidden
} from "@store/layout"

// ** ThemeConfig
import themeConfig from "@configs/themeConfig"
import { setAppNeedUpdate } from "@store/developer"
import MenuTabs from "../MenuTabs"
// ** Styles
import "animate.css/animate.css"
import { Alert, Button, Col, Row } from "reactstrap"
import { Download, GitHub, Info, PhoneCall } from "react-feather"
import { useTranslation } from "react-i18next"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import imgUpdate from "@src/assets/images/pages/graphic-1.png"
import { getUserData, isUserLoggedIn } from "@utils"

const LayoutWrapper = (props) => {
  // ** Props
  const { children, routeMeta } = props

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector((state) => state)
  const navbarStore = store.navbar
  const layoutStored = store.layout.layout
  const contentWidth = store.layout.contentWidth
  const { t } = useTranslation()
  //** Vars
  const appLayoutCondition =
    (layoutStored.layout === "horizontal" && !routeMeta) ||
    (layoutStored.layout === "horizontal" && routeMeta && !routeMeta.appLayout)
  const Tag = appLayoutCondition ? "div" : Fragment

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (
        routeMeta.contentWidth &&
        routeMeta.contentWidth === store.layout.contentWidth
      ) {
        dispatch(handleContentWidth(themeConfig.layout.contentWidth))
      }
      if (
        routeMeta.menuCollapsed &&
        routeMeta.menuCollapsed === store.layout.menuCollapsed
      ) {
        dispatch(handleMenuCollapsed(!store.layout.menuCollapsed))
      }
      if (
        routeMeta.menuHidden &&
        routeMeta.menuHidden === store.layout.menuHidden
      ) {
        dispatch(handleMenuHidden(!store.layout.menuHidden))
      }
    }
  }

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden))
      }
    }
    return () => cleanUp()
  }, [routeMeta])

  const getDownload = () => {
    // const url = "https://github.com/zaidan2312/saba_framework/tree/v1.0"
    const url = "https://github.com/amry666/saba_framework_frontend"
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  const contactAdmin = () => {
    const userData = getUserData()
    const phoneNumber = "+6282295133577" // zaidan
    const message = `Halo saya ${userData.name} (${userData.email}), mau minta file update terbaru Saba Framework.`

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`
    window.open(whatsappLink, "_blank")
  }

  return (
    <div
      className={classnames("app-content content overflow-hidden", {
        [routeMeta ? routeMeta.className : ""]:
          routeMeta && routeMeta.className,
        "show-overlay": navbarStore.query.length
      })}
    >
      <div className="content-overlay"></div>

      <div
        className={`header-navbar-shadow ${
          location.pathname === "/chat" ? "header-navbar-shadow-mobile" : ""
        }`}
      />
      <div
        className={classnames({
          "content-wrapper": routeMeta && !routeMeta.appLayout,
          "content-area-wrapper": routeMeta && routeMeta.appLayout,
          "container-xxl p-0": contentWidth === "boxed",
          "content-area-wrapper-chat-mobile": location.pathname === "/chat"
        })}
      >
        {store?.developer?.appNeedUpdate && routeMeta && !routeMeta.appLayout && (
          <Alert
            isOpen={store?.developer?.appNeedUpdate?.state}
            toggle={() => dispatch(setAppNeedUpdate({ state: false }))}
            color="warning"
            className="mb-2"
          >
            <div className="alert-body">
              <Row className="align-items-center">
                <Col
                  className="text-center fw-bolder my-1 d-none d-md-block"
                  md={1}
                >
                  {/* <Info size={35} /> */}
                  <img style={{ width: "100%" }} src={imgUpdate} />
                </Col>
                <Col style={{ fontSize: 17 }} md={11}>
                  <Row>
                    <Col md={12}>
                      {t(
                        `It looks like your Saba Framework hasn't been updated yet. Update now to get the latest features & fixes! Try searching latest version on download link or contact administrator of Saba Framework on WhatsApp.`
                      )}{" "}
                      {t(`Your version used is`)}{" "}
                      <span className="fw-bolder bg-light-warning">
                        {store?.developer?.appNeedUpdate?.currentVer ?? "....."}
                      </span>
                      , {t("and the latest version available is")}{" "}
                      <span className="fw-bolder bg-light-success">
                        {store?.developer?.appNeedUpdate?.lastVer ?? "....."}
                      </span>
                    </Col>
                    <Col className="mt-75 mb-1 d-flex" md={12}>
                      <Button.Ripple
                        onClick={() => getDownload()}
                        className=""
                        color="gradient-primary"
                      >
                        <GitHub size={16} />
                        <span className="align-middle ms-50">
                          {t("Download Link")}
                        </span>
                      </Button.Ripple>
                      <Button.Ripple
                        onClick={() => contactAdmin()}
                        className="ms-75"
                        color="gradient-success"
                      >
                        <PhoneCall size={16} />
                        <span className="align-middle ms-50">
                          {t("Contact Administrator")}
                        </span>
                      </Button.Ripple>
                      {/* <a
                className="fw-bold"
                target="_blank"
                href="https://github.com/zaidan2312/saba_framework/tree/v1.0"
              >
                Saba Framework Download Link
              </a>
              <a
                className="fw-bold ms-1"
                target="_blank"
                href="https://github.com/zaidan2312/saba_framework/tree/v1.0"
              >
                Contact Administrator
              </a> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Alert>
        )}

        <MenuTabs></MenuTabs>

        <Tag {...(appLayoutCondition ? { className: "content-body" } : {})}>
          {children}
        </Tag>
      </div>
    </div>
  )
}

export default memo(LayoutWrapper)
