// ** Store Imports
import { handleLastMenuTabs } from "@store/layout"
import { useDispatch, useSelector } from "react-redux"

export const useLastMenuTabs = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const store = useSelector((state) => state.layout)
  const storeMenu = useSelector((state) => state.my_menu)

  const setLastMenuTabs = (data, location, allData) => {
    let lastMenuChachedState =
      localStorage.getItem("lastMenuChachedState") ?? false
    if (lastMenuChachedState) {
      let lastMenuChachedStorage =
        sessionStorage.getItem("lastMenuChached") ?? "[]"
      let lastMenuChachedArr = JSON.parse(lastMenuChachedStorage)
      if (location) {
        // const location = window.location.pathname
        // const location = locations.pathname
        // console.log(lastMenuChachedArr, location)

        let locationRoute = location?.includes("/edit/")
          ? location?.split("/edit/")?.[0]
          : location?.includes("/add")
          ? location?.split("/add")?.[0]
          : location
        // console.log(locationRoute)
        const index = lastMenuChachedArr.findIndex((itemChild) => {
          let navLink = itemChild.navLink?.includes("/edit/")
            ? itemChild.navLink?.split("/edit/")?.[0]
            : itemChild.navLink?.includes("/add")
            ? itemChild.navLink?.split("/add")?.[0]
            : itemChild.navLink
          // console.log(navLink)
          return navLink === locationRoute
        })
        if (index !== -1) {
          // console.log(index)
          lastMenuChachedArr[index].navLink = location
        } else {
          if (storeMenu.dataMenu.length > 0) {
            const findMenuData = storeMenu.dataMenu.find(
              (item) => item?.navLink === locationRoute
            )
            // console.log(findMenuData)
            if (findMenuData) {
              lastMenuChachedArr.unshift(findMenuData)
            }
          }
        }

        if (lastMenuChachedArr.length > 8) {
          lastMenuChachedArr.pop()
        }

        // console.log(lastMenuChachedArr)
        dispatch(handleLastMenuTabs(lastMenuChachedArr))
      } else if (data) {
        // console.log(data)
        const location = data?.navLink
        // console.log(lastMenuChachedArr, location)

        let locationRoute = location?.includes("/edit/")
          ? location?.split("/edit/")?.[0]
          : location?.includes("/add")
          ? location?.split("/add")?.[0]
          : location
        // console.log(locationRoute)
        const index = lastMenuChachedArr.findIndex((itemChild) => {
          let navLink = itemChild.navLink?.includes("/edit/")
            ? itemChild.navLink?.split("/edit/")?.[0]
            : itemChild.navLink?.includes("/add")
            ? itemChild.navLink?.split("/add")?.[0]
            : itemChild.navLink
          // console.log(navLink)
          return navLink === locationRoute || itemChild?.id === data?.id
        })
        if (index !== -1) {
          lastMenuChachedArr[index].navLink = location
        } else {
          // lastMenuChachedArr.push(data)
          lastMenuChachedArr.unshift(data)
        }
        if (lastMenuChachedArr.length > 8) {
          lastMenuChachedArr.pop()
        }
        dispatch(handleLastMenuTabs(lastMenuChachedArr))
      } else {
        dispatch(handleLastMenuTabs(allData))
      }
    }
  }

  return {
    setLastMenuTabs,
    lastMenuTabs: store.lastMenuTabs
  }
}
