import UILoader from "@components/ui-loader"
import { Loader } from "@src/shared/CommonComponents"
import { AbilityContext } from "@src/utility/context/Can"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { renderIcon } from "../../views/apps/menu/store/my_menu"
import "cleave.js/dist/addons/cleave-phone.us"
import { Fragment, useContext, useRef, useState } from "react"
import { MoreVertical } from "react-feather"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  ListGroupItem,
  Badge,
  Row,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { getBaseUrl } from "./store"
import { updatetDomainInfoLandingPage } from "@store/domainInfo"

import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import DynamicForm from "../../utility/generateForm/dynamicForm"
import { ReactSortable } from "react-sortablejs"
import ModalAddEditLanding from "./landing/modal"
const MySwal = withReactContent(Swal)

const ListSortable = (props, ref) => {
  const { storeName } = props

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)
  const storeDomain = useSelector((state) => state.domain_claims)
  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const { colors } = useContext(ThemeColors)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")

  // useEffect(() => {
  //   // console.log(store.pageData[storeName]?.data)
  //   // console.log(selectedDataTable)
  //   // console.log(store.pageData[storeName]?.data)
  //   let isLoadState = pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.some(
  //     (item) => item?.rules?.required === true || item?.required === true
  //   )
  //   // console.log(storeName)

  //   if (!isLoadState) {
  //     if (
  //       (!store.pageData[storeName]?.data ||
  //         pageAttr?.crudOpt?.getAlwaysGrid ||
  //         searchParams.get("refresh") === "1" ||
  //         !store.pageData[storeName]?.status) &&
  //       !store.pageData[storeName]?.isLoading &&
  //       // store.pageData[storeName]?.status !== "failed" &&
  //       // !store.pageData[storeName]?.status &&
  //       pageAttr?.crudOpt?.endpointName &&
  //       !pageAttr?.isFromAddPage
  //     ) {
  //       getData()
  //     } else {
  //       if (relPageId || pageAttr?.isViewOnForm) {
  //         // const lastParentData = storeParent?.selectedData

  //         // ini untuk reload state pada relationscrud yang ada di form, melihat selectedData form terakhir dibandingkan dengan initialFiltersCondition
  //         const lastParentData =
  //           store.pageData[moduleId ? `${moduleId}_${pageId}` : `${pageId}`]
  //             ?.selectedData
  //         let curPageLastParam = store.pageData[storeName]?.params
  //         // console.log(lastParentData, curPageLastParam)
  //         let curPageAttrPageFilArray =
  //           pageAttr?.crudOpt?.initialFiltersCondition
  //         let reloadState = false

  //         if (curPageAttrPageFilArray) {
  //           const curPageAttrPageFilField =
  //             curPageAttrPageFilArray[0]?.fieldNameCurrent

  //           if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
  //             curPageAttrPageFilArray?.map((childPar) => {
  //               // console.log(
  //               //   `${curPageLastParam[curPageAttrPageFilField]}`,
  //               //   `${lastParentData[childPar?.value_from_parentFieldName]}`
  //               // )
  //               if (!childPar?.disablePageQueryParam) {
  //                 reloadState =
  //                   reloadState ||
  //                   `${curPageLastParam[curPageAttrPageFilField]}` !==
  //                     `${lastParentData[childPar?.value_from_parentFieldName]}`
  //               }
  //             })
  //           } else {
  //             reloadState =
  //               `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
  //           }
  //         }

  //         if (reloadState) {
  //           if (
  //             !store.pageData[storeName]?.isLoading &&
  //             // store.pageData[storeName]?.status !== "failed" &&
  //             // !store.pageData[storeName]?.status &&
  //             !pageAttr?.isFromAddPage
  //           ) {
  //             getData()
  //           }
  //         }
  //       }

  //       getTableProperties(store.pageData[storeName]?.data)
  //       setLoading(false)
  //     }
  //   } else {
  //     getTableProperties(store.pageData[storeName]?.data)
  //   }
  // }, [dispatch, getData, pageAttr, storeName, sortDir, sort, currentPage])

  const currentUrl = window.location.href
  const currentDomain = new URL(currentUrl).origin

  const defaultTheme = "naxos"

  const getDefaultAttributes = (type) => {
    let domainClaims = storeDomain?.data

    const app_setting = {
      ...{
        layout: domainClaims?.unit?.unit_app_attributes?.layout ?? {}
      },
      ...(domainClaims?.app?.setting ?? {})
    }

    const appLogoFix =
      domainClaims?.unit?.unit_app_attributes?.appLogoLoginBig ??
      domainClaims?.unit?.unit_app_attributes?.appLogoLogin ??
      domainClaims?.app?.logo?.appLogoLoginBig ??
      domainClaims?.app?.logo?.appLogoLogin ??
      domainClaims?.app?.logo?.appLogo

    const mainImageFix =
      domainClaims?.unit?.unit_app_attributes?.login_img_dark ??
      domainClaims?.app?.logo?.login_img_dark ??
      domainClaims?.unit?.unit_app_attributes?.login_img ??
      domainClaims?.app?.logo?.login_img ??
      `${currentDomain}/static/media/login-v2.2198399d.svg`

    //   console.log(mainImageFix)
    const bgImgFix =
      domainClaims?.unit?.unit_app_attributes?.background_img ??
      domainClaims?.app?.logo?.background_img ??
      `${currentDomain}/website/${defaultTheme}/images/banner/single-image.jpg`

    const mainBgVideoFix =
      domainClaims?.unit?.unit_app_attributes?.background_video ??
      domainClaims?.app?.logo?.background_video ??
      domainClaims?.app?.setting?.background_video

    const hasColorAttr =
      app_setting?.layout?.primaryColor ||
      domainClaims?.unit?.unit_app_attributes?.background_video_overlay ||
      domainClaims?.app?.setting?.background_video_overlay

    // console.log(domainClaims, defaultAttr)
    const defaultAttr = {
      gridTheme: defaultTheme,
      data: {
        mainMenu: [
          { title: "Fitur", link: "#features" },
          { title: "Testimonial", link: "#testimonials" }
        ],
        dynamicSection: [
          {
            fieldName: "home",
            fieldLabel: "",
            fieldDesc: "",
            type: "banner",
            data: [
              {
                title: `${
                  domainClaims?.app?.name
                }</br><div style="font-size: 45px;" class="wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0s">${
                  domainClaims?.app?.info?.description ??
                  "Pengelolaan Data Terintegrasi dan Online"
                }</div>`,
                subtitle:
                  "Selamat Datang di Aplikasi Kami! Temukan Kemudahan Pengelolaan Data yang Terintegrasi dan Selalu Terhubung Online, serta berbagai fitur lainnya.",
                image: mainImageFix,
                background_image: bgImgFix,
                background_video: mainBgVideoFix,
                overlay_color1: hasColorAttr
                  ? app_setting?.layout?.primaryColor ?? "#1878e4"
                  : null,
                overlay_color2: hasColorAttr
                  ? domainClaims?.unit?.unit_app_attributes
                      ?.background_video_overlay ??
                    app_setting?.background_video_overlay ??
                    "#004899"
                  : null
              }
            ]
            //   html: `
            //   <div class="col-12 col-lg res-margin">
            //     <!-- Banner text -->
            //     <div class="banner-text ">
            //     <h1 class="wow fadeInUp mb-0 mt-lg-5" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0s" >${
            //       domainClaims?.app?.name
            //     }</h1> <h1 style="font-size: 55px;" class="wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0s" >${
            //     domainClaims?.app?.info?.description ??
            //     "Pengelolaan Data Terintegrasi dan Online"
            //   }</h1> <p style="font-size:24px;" class="wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s" >${"Selamat Datang di Aplikasi Kami! Temukan Kemudahan Pengelolaan Data yang Terintegrasi dan Selalu Terhubung Online, serta berbagai fitur lainnya."}</p>
            //     </div>
            //   </div>

            //   ${
            //     !mainImageFix
            //       ? ""
            //       : `<div class="col-12 col-lg-6">
            //   <div
            //     class="banner-image wow fadeInUp"
            //     data-wow-offset="10"
            //     data-wow-duration="1s"
            //     data-wow-delay="0.3s"
            //   >
            //     <img
            //       class="bounce-effect"
            //       src="${mainImageFix}"
            //       alt="${domainClaims?.app.name}"
            //     />
            //   </div>
            // </div>`
            //   }`
          },
          {
            fieldName: "clients",
            fieldLabel: "",
            fieldDesc: "",
            type: "clients",
            data: [
              {
                title: domainClaims?.unit?.name,
                image: domainClaims?.unit?.attributes?.logo,
                link: "#"
              },
              appLogoFix
                ? {
                    title: domainClaims?.app?.name,
                    image: appLogoFix,
                    link: "#"
                  }
                : {},
              {
                title: "Saba Framework",
                image:
                  "https://res.cloudinary.com/insaba/image/upload/v1700625287/saba_framework/logo_saba_framework_gqw72y.png",
                link: "https://sabaframework.web.app/public"
              }
            ]
          },
          {
            fieldName: "features",
            fieldLabel: "Fitur Luar Biasa",
            fieldDesc: `Eksplorasi Keunggulan yang Membuat ${domainClaims?.app?.name} Berbeda. Temukan Fitur-fitur Inovatif yang Menjadikan ${domainClaims?.app?.name} Kami Pilihan Utama untuk Aplikasi Pengelolaan data anda.`,
            type: "features",
            isHidden: false,
            data: [
              {
                title: "Mudah Digunakan",
                subtitle:
                  "Desain UI & UX disesuaikan berdasarkan survei pengguna dan referensi, memastikan kemudahan penggunaan.",
                icon: "far fa-smile"
              },
              {
                title: "Responsif",
                subtitle:
                  "Tetap optimal di berbagai perangkat, beradaptasi dengan ukuran layar yang berbeda.",
                icon: "fas fa-mobile-alt"
              },
              {
                title: "Penyimpanan Cloud",
                subtitle:
                  "Kolaborasi dan kontrol data yang mudah, tanpa perlu biaya tambahan untuk server fisik.",
                icon: "fas fa-cloud-upload-alt"
              },
              {
                title: "Perlindungan Privasi",
                subtitle:
                  "Melindungi data dari akses tidak sah, perubahan, dan pengungkapan tanpa izin.",
                icon: "fas fa-shield-alt"
              },
              {
                title: "Keamanan Terjamin",
                subtitle:
                  "Penggunaan teknologi TSL dan ORM untuk menjaga kerahasiaan data dan mencegah SQL Injection.",
                icon: "fas fa-lock"
              },
              {
                title: "Kinerja Tinggi",
                subtitle:
                  "Aplikasi Halaman Tunggal (SPA) dan Manajemen State untuk mempercepat kinerja aplikasi.",
                icon: "fas fa-tachometer-alt"
              },
              {
                title: "Pengembangan Mudah",
                subtitle:
                  "Integrasi dan komunikasi data antar aplikasi menggunakan teknologi REST API.",
                icon: "fas fa-code"
              },

              {
                title: "Artikel & Berita",
                subtitle:
                  "Buat berita, artikel, dan pengumuman dinamis untuk pengguna terdaftar atau publik.",
                icon: "far fa-newspaper"
              },
              {
                title: "Fitur Chat",
                subtitle:
                  "Temukan dan mulai obrolan dengan petugas yang sedang bekerja.",
                icon: "fas fa-comments"
              },
              // {
              //   title: "Migrasi Data",
              //   subtitle: `Pindahkan data lama Anda ke ${domainClaims?.app?.name} tanpa perlu entri ulang.`,
              //   icon: "fas fa-exchange-alt"
              // },
              {
                title: "Pusat Bantuan",
                subtitle: `Dapatkan dukungan dari tim support dan helpdesk ${domainClaims?.app?.name}.`,
                icon: "fas fa-life-ring"
              }
            ]
          },
          {
            fieldName: "parallax-video",
            fieldLabel: "",
            fieldDesc: "",
            type: "parallax-video",
            isHidden: true
          },
          {
            fieldName: "overview",
            fieldLabel: "",
            fieldDesc: "",
            type: "overview",
            isHidden: true
          },
          {
            fieldName: "services",
            fieldLabel: "",
            fieldDesc: "",
            type: "services",
            isHidden: true
          },
          {
            fieldName: "testimonials",
            fieldLabel: "Pendapat Klien",
            fieldDesc:
              "Pengalaman Luar Biasa dari Pelanggan Kami: Ulasan dan Testimoni Otentik yang Menceritakan Kisah Sukses Bersama Layanan Kami.",
            type: "testimonials",
            data: [
              {
                title: "Maria Dewi",
                subtitle: "Manajer Operasional",
                content: `Aplikasi ${domainClaims?.app?.name} telah membawa revolusi dalam pengelolaan kegiatan kami. Sangat mudah digunakan dan membantu meningkatkan efisiensi. Terima kasih ${domainClaims?.app?.name}!`,
                image: `https://ui-avatars.com/api?size=128&background=0D8ABC&color=fff&name=Maria%20Dewi`
              },
              {
                title: "Ahmad Rizal",
                subtitle: "Pengembang Web",
                content: `Saya sangat terkesan dengan kecepatan dan ketepatan fitur-fitur ${domainClaims?.app?.name}. Membuat pekerjaan saya sebagai pengembang web menjadi lebih lancar dan terorganisir.`,
                image: `https://ui-avatars.com/api/?size=128&background=962370&color=fff&name=Ahmad%20Rizal`
              },
              {
                title: "Rina Fitriani",
                subtitle: "Manajer Proyek",
                content: `${domainClaims?.app?.name} membantu saya mengelola proyek-proyek dengan lebih baik. Sistem notifikasi dan integrasi online-nya sangat memudahkan pemantauan dari jauh.`,
                image: `https://ui-avatars.com/api/?size=128&background=e8a935&color=fff&name=Rina%20Fitriani`
                //   image: `${currentDomain}/website/${defaultTheme}/images/testimonials/client-3.jpg`
              },
              {
                title: "Siti Rahayu",
                subtitle: "Pebisnis",
                content: `Sebagai pemilik bisnis kecil, ${domainClaims?.app?.name} membantu saya menjalankan operasional dengan lebih efisien. Saya dapat fokus pada pertumbuhan bisnis tanpa khawatir tentang pengelolaan data.`,
                image: `https://ui-avatars.com/api/?size=128&background=bc3c3c&color=fff&name=Siti%20Rahayu`
                //   image: `${currentDomain}/website/${defaultTheme}/images/testimonials/client-4.jpg`
              },
              {
                title: "Nita Wulandari",
                subtitle: "Marketing Executive",
                content: `Pengalaman menggunakan ${domainClaims?.app?.name} sungguh luar biasa. Fitur-fiturnya yang intuitif membuat penggunaan sehari-hari menjadi lebih menyenangkan.`,
                image: `https://ui-avatars.com/api/?size=128&background=00cfe8&color=fff&name=Nita%20Wulandari`
              },
              {
                title: "Kevin Sudrajatun",
                subtitle: "Marketing Executive",
                content: `Pengalaman menggunakan ${domainClaims?.app?.name} sungguh luar biasa. Fitur-fiturnya yang intuitif membuat penggunaan sehari-hari menjadi lebih menyenangkan.`,
                image: `https://ui-avatars.com/api/?size=128&background=7367f0&color=fff&name=Kevin%20Sudrajatun`
              }
            ]
          },
          {
            fieldName: "screenshots",
            type: "slider_img",
            fieldLabel: "",
            fieldDesc: "",
            isHidden: true
          },
          {
            fieldName: "Teams",
            type: "team",
            fieldLabel: "",
            fieldDesc: "",
            isHidden: true
          },
          {
            fieldName: "support",
            type: "faq",
            fieldLabel: "",
            fieldDesc: "",
            isHidden: true
          },
          {
            fieldName: "footer",
            fieldLabel: "",
            fieldDesc: "",
            type: "footer"
          }
        ]
      }
    }

    if (type) {
      return defaultAttr.data.dynamicSection.filter(
        (attr) => attr.type === type
      )
    } else {
      return defaultAttr
    }
  }

  const sectionsUI = [
    {
      id: "header",
      title: "Header",
      isDisabledDrag: true,
      cardItemStyle: {
        backgroundColor: "rgb(221 244 255)",
        color: "#5e5873 !important"
      },
      cardItemClassNames: "bg-light-primary",
      type: "header",
      data: getDefaultAttributes()?.data?.mainMenu
    },
    {
      id: "banner",
      title: "Banner",
      isDisabledDrag: true,
      cardItemStyle: {
        backgroundColor: "rgb(221 244 255)",
        color: "#5e5873 !important"
      },
      cardItemClassNames: "bg-light-primary"
    },
    {
      id: "clients",
      title: "Clients",
      type: "clients"
    },
    {
      id: "features",
      title: "Features"
    },
    {
      id: "testimonials",
      title: "Testimonial",
      type: "testimonials"
    },
    {
      id: "footer",
      title: "Footer",
      isDisabledDrag: true,
      cardItemStyle: {
        backgroundColor: "rgb(221 244 255)",
        color: "#5e5873 !important"
      },
      cardItemClassNames: "bg-light-primary"
    }
  ]

  function generateUniqueId() {
    const timestamp = Date.now()
    const randomPart = Math.floor(Math.random() * 10000)
    return `${timestamp}-${randomPart}`
  }

  const [isOpenCollapse, setIsOpenCollapse] = useState({})

  const toggleCollapse = (item) => {
    let openCollapse = { ...isOpenCollapse }
    // if (openCollapse) {
    //   Object.keys(openCollapse).forEach((key) => {
    //     openCollapse[key] = key === item?.id
    //   })
    // } else {
    openCollapse = {
      [item?.id ?? item?.type]: !isOpenCollapse[item?.id ?? item?.type]
    }
    // }}
    setIsOpenCollapse(openCollapse)
  }

  const [isOpenCollapseDetail, setIsOpenCollapseDetail] = useState({})
  const toggleCollapseDetail = (e, type) => {
    e.preventDefault()
    // console.log(type, isOpenCollapseDetail[type])
    let openCollapse = { ...isOpenCollapseDetail }
    openCollapse = {
      [type]: !isOpenCollapseDetail[type]
    }
    setIsOpenCollapseDetail(openCollapse)
  }

  function deepMerge(target, source) {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (
          source[key] !== null &&
          typeof source[key] === "object" &&
          !Array.isArray(source[key])
        ) {
          // Recursive deep merge for objects
          target[key] = deepMerge(target[key] || {}, source[key])
        } else {
          // Assign values for non-objects or arrays
          target[key] = source[key]
        }
      }
    }
    return target
  }

  const getDataMixedAll = () => {
    // let sectionsAll = {
    //   ...getDefaultAttributes(),
    //   ...storeDomain?.data?.landing_page_attr
    // }
    const defaultLandingPageAttr = getDefaultAttributes()?.data?.dynamicSection

    let sectionsMix = []

    const landing_page_attr = storeDomain?.data?.landing_page_attr
    const userdynamicSection = landing_page_attr?.data?.dynamicSection
    const userMainMenu = landing_page_attr?.data?.mainMenu

    if (landing_page_attr && userdynamicSection) {
      userdynamicSection?.map((section) => {
        const defaultLandingPageAttrObj =
          defaultLandingPageAttr.find(
            (item) => item?.id === section?.type || item?.type === section?.type
          ) ?? {}

        const sectionOverride = {
          ...section,
          isHidden:
            section?.fieldLabel === "" &&
            (!section?.data || section?.data?.length === 0) &&
            !["banner", "header", "footer"].includes(section?.type)
          // id: generateUniqueId()
        }
        // // console.log(sectionOverride)

        let newSection = Object.assign(
          defaultLandingPageAttrObj,
          sectionOverride
        )

        // let newSection = deepMerge(
        //   {},
        //   defaultLandingPageAttrObj,
        //   sectionOverride
        // )
        sectionsMix.push(newSection)
      })
      defaultLandingPageAttr.map((section) => {
        let index = sectionsMix?.findIndex(
          (secMix) => secMix.type === section.type
        )
        if (index === -1) {
          sectionsMix.push({ ...section, title: section["fieldLabel"] })
        }
        // return section
        // return { ...section }
      })
    } else {
      sectionsMix = defaultLandingPageAttr.map((section) => {
        return { ...section, title: section["fieldLabel"] }
      })
    }

    // console.log(sectionsMix)
    let sectionsFinal = []
    let originalOrder = []
    sectionsMix?.map((section) => {
      const sectionsUIObj =
        sectionsUI.find((item) => item?.id === section.type) ?? {}
      let newSection = { ...sectionsUIObj, ...section }
      // let newSection = deepMerge({}, sectionsUIObj, section)
      sectionsFinal.push(newSection)
      originalOrder.push(section.type)
    })

    // console.log(sectionsFinal)
    const addIdsRecursive = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => addIdsRecursive(item))
      } else if (typeof obj === "object" && obj !== null) {
        if (Object.isExtensible(obj)) {
          // obj.id = generateUniqueId()
          if (obj.data) {
            obj.data = addIdsRecursive(obj.data)
          } else {
            obj.data = []
          }

          if (obj.buttons) {
            obj.buttons = addIdsRecursive(obj.buttons)
          } else {
            obj.buttons = []
          }
        } else {
          const newObj = { ...obj, id: generateUniqueId() }
          if (newObj.data) {
            newObj.data = addIdsRecursive(newObj.data)
          } else {
            newObj.data = []
          }

          if (newObj.buttons) {
            newObj.buttons = addIdsRecursive(newObj.buttons)
          } else {
            newObj.data = []
          }
          return newObj
        }
      }
      return obj
    }

    // console.log(sectionsFinal)

    sectionsFinal = addIdsRecursive(sectionsFinal)

    // console.log(originalOrder, sectionsFinal)
    // sectionsFinal.sort((a, b) => {
    //   return originalOrder.indexOf(a.type) - originalOrder.indexOf(b.type)
    // })

    // console.log(sectionsFinal)

    // if (userdynamicSection) {
    //   sectionsFinal.sort((a, b) =>
    //     a.isHidden === b.isHidden ? 0 : a.isHidden ? 1 : -1
    //   )

    //   // console.log(sectionsFinal)
    //   sectionsFinal.sort((a, b) =>
    //     a.type === "banner" ? -1 : b.type === "banner" ? 1 : 0
    //   )

    //   sectionsFinal.sort((a, b) =>
    //     a.type === "footer" ? 1 : b.type === "footer" ? -1 : 0
    //   )
    // } else {
    //   const idToObject = sectionsFinal.reduce((acc, item) => {
    //     acc[item?.type] = item
    //     return acc
    //   }, {})

    //   // Urutkan arrayToOrder berdasarkan originalOrder
    //   const sortedArrayToOrder = originalOrder.map((id) => idToObject[id])

    //   // Tampilkan hasil
    //   sortedArrayToOrder.forEach((item) => {
    //     console.log(item)
    //   })

    //   sectionsFinal = sortedArrayToOrder
    // }

    // console.log(sectionsFinal)
    let sectionsUIHeaderObj = sectionsUI.find((item) => item?.id === "header")

    sectionsUIHeaderObj.data = [
      ...sectionsUIHeaderObj.data,
      ...(userMainMenu ?? [])
    ]

    // console.log(sectionsUIHeaderObj)
    let sectionsFinalHeaderWithData = sectionsFinal.find(
      (item) => item?.type === "header" && item?.data?.length > 0
    )

    sectionsFinal = sectionsFinal.filter((obj) => obj.type !== "header")
    if (!sectionsFinalHeaderWithData) {
      sectionsFinal.unshift(sectionsUIHeaderObj)
    } else {
      sectionsFinal.unshift(sectionsFinalHeaderWithData)
    }

    const sectionsOrderTop = sectionsFinal.filter(
      (obj) => obj.type === "header"
    )
    const sectionsOrder1 = sectionsFinal.filter((obj) => obj.type === "banner")
    const sectionsOrder2 = sectionsFinal.filter(
      (obj) =>
        (!obj.isHidden || obj.isHidden === false) &&
        obj.type !== "banner" &&
        obj.type !== "footer" &&
        obj.type !== "header"
    )
    const sectionsOrder3 = sectionsFinal.filter((obj) => obj.isHidden)
    const sectionsOrder4 = sectionsFinal.filter((obj) => obj.type === "footer")
    let sectionsFinalOrdered = [
      ...sectionsOrderTop,
      ...sectionsOrder1,
      ...sectionsOrder2,
      ...sectionsOrder3,
      ...sectionsOrder4
    ]
    // console.log(sectionsFinal)
    return sectionsFinalOrdered
  }

  // const sectionsAll = getDataMixedAll()
  // const [landingPageAttr, setLandingPageAttr] = useState(null)
  const [sectionsAll, setSectionsAll] = useState(getDataMixedAll())
  // const [sectionsCards, setSectionsCards] = useState(sectionsAll)

  const updateSectionAllArr = (newList, sectionType, parent_id) => {
    setLoadingDelete(true)
    setLoadingText(`${t("Updating")} ${t("Content")} ${sectionType}`)
    let dataToPayload
    let dataToUpdateStore
    // console.log(newList)
    if (!sectionType) {
      setSectionsAll(newList)
      dataToPayload = newList
      dataToUpdateStore = newList
    } else {
      let sectionsAllCopy = JSON.parse(JSON.stringify(sectionsAll))
      let sectionsUpdatedIndex = sectionsAllCopy.findIndex(
        (section) =>
          section?.id === sectionType || section?.type === sectionType
      )
      if (sectionsUpdatedIndex !== -1) {
        if (!parent_id) {
          sectionsAllCopy[sectionsUpdatedIndex]["data"] = newList
          setSectionsAll(sectionsAllCopy)
          dataToUpdateStore = sectionsAllCopy
          dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
        } else {
          if (sectionsAllCopy[sectionsUpdatedIndex]?.data) {
            // console.log(sectionsAllCopy[sectionsUpdatedIndex]?.data)
            let sectionsUpdatedIndexDetail = sectionsAllCopy[
              sectionsUpdatedIndex
            ].data.findIndex((section) => section?.id === parent_id)
            if (sectionsUpdatedIndexDetail !== -1) {
              let keyName = sectionType === "banner" ? "buttons" : "data"
              sectionsAllCopy[sectionsUpdatedIndex].data[
                sectionsUpdatedIndexDetail
              ][keyName] = newList
              setSectionsAll(sectionsAllCopy)
              dataToUpdateStore = sectionsAllCopy
              dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
            }
          }
        }
      }
    }

    // console.log(dataToPayload)

    if (dataToPayload) {
      let newDataToPayload = JSON.parse(JSON.stringify(dataToPayload))
      if (Array.isArray(newDataToPayload)) {
        for (let obj of newDataToPayload) {
          // obj["type"] = "root"
          delete obj.data
          delete obj?.isDisabledDrag
          delete obj?.id
        }
      } else {
        // delete newDataToPayload.data
        delete newDataToPayload?.isDisabledDrag
        delete newDataToPayload?.id
      }

      // console.log(newDataToPayload)
      updateToBackend(newDataToPayload, "edit", dataToUpdateStore)
    }
  }

  const [editHeaderLanding, seteEditHeaderLanding] = useState()
  const [editDynamicFormLanding, setEditDynamicFormLanding] = useState({})
  const [editDefaultFormValuesLanding, setEditDefaultFormValuesLanding] =
    useState({})

  const updateFilePathOnly = (sectionType, path) => {}

  const updateToBackend = async (
    params,
    pageType = "edit",
    dataToUpdateStore,
    deletedItem
  ) => {
    let payload
    if (!Array.isArray(params)) {
      payload = { ...params }
      if (params?.storeName === "website_main_form") {
        payload["type"] = "main"
      }

      delete payload["storeName"]
      delete payload["endpointName"]
      delete payload["id"]
    } else {
      payload = [...params]
    }

    const apiUrl = "api/unit_app/landing_page"
    let config = {
      method: "put",
      url: apiUrl,
      data: payload
    }

    //////CHECK IF INCLUDES FILE ON PAYLOAD
    // console.log(payload)
    let file
    const uploadingImg =
      "https://res.cloudinary.com/insaba/image/upload/v1663564214/app/uploading.gif"
    if (pageType !== "delete") {
      let hasFile = payload.image && payload.image.path
      if (hasFile) {
        file = payload.image
        payload.image = uploadingImg
      } else {
        if (payload?.data && !hasFile) {
          payload?.data?.map((item) => {
            hasFile = item.image && item.image.path
            if (hasFile) {
              file = item.image
              item.image = uploadingImg
            } else {
              if (item?.data && !hasFile) {
                item?.data.map((item2) => {
                  hasFile = item2.image && item2.image.path
                  if (hasFile) {
                    file = item2.image
                    item2.image = uploadingImg
                  }
                })
              }
            }
          })
        }
      }
    } else {
      if (deletedItem && deletedItem?.image) {
        config.data["deleted_image"] = deletedItem?.image
      }
    }

    if (file) {
      let formDataPost = new FormData()
      formDataPost.append("payload", JSON.stringify(payload))
      formDataPost.append("file_upload", file)
      config["data"] = formDataPost
      config["headers"] = {
        "Content-Type": "multipart/form-data"
      }
    }

    await getBaseUrl(
      "sso",
      apiUrl
    )(config)
      .then((response) => {
        const respTitle =
          pageType === "edit"
            ? "Updated"
            : pageType === "add"
            ? "Created"
            : pageType === "delete"
            ? "Deleted"
            : ""
        // console.log("Response:", response.data)
        //
        toast.success(
          `${t(`Successfully ${respTitle}`)} ${payload?.type ?? ""}`,
          {
            style: {
              padding: "16px",
              color: colors.success.main,
              border: `1px solid ${colors.success.main}`
            },
            iconTheme: {
              primary: colors.success.main
            }
          }
        )

        // console.log(params, dataToUpdateStore, payload)
        if (!Array.isArray(params) && !["main"].includes(payload.type)) {
          let dataToUpdateStoreNew = dataToUpdateStore
          if (file) {
            let responseData = response?.data?.data
            // console.log(responseData)
            if (responseData) {
              dataToUpdateStoreNew = JSON.parse(
                JSON.stringify(dataToUpdateStoreNew)?.replaceAll(
                  uploadingImg,
                  responseData?.uploaded_path
                )
              )
            }
          }

          // console.log(dataToUpdateStoreNew)
          setSectionsAll(dataToUpdateStoreNew)
          dispatch(updatetDomainInfoLandingPage(dataToUpdateStoreNew))
          setShowModalDeleteDetail(!showModalDeleteDetail)
        } else {
          let newData = JSON.parse(JSON.stringify(sectionsAll))

          // console.log(payload)
          let newDataFinal = []
          if (Array.isArray(params)) {
            params?.map((itemss) => {
              newDataFinal.push({
                ...itemss,
                ...newData.find((i) => i.type === itemss.type)
              })
            })
          } else {
            newDataFinal = { ...payload }
            // console.log(newDataFinal)
            newDataFinal["data"] = {
              dynamicSection: newData
            }
            // console.log(newDataFinal)
          }

          dispatch(updatetDomainInfoLandingPage(newDataFinal))
        }
      })
      .catch((error) => {
        const respTitle =
          pageType === "edit"
            ? "Updating"
            : pageType === "add"
            ? "Creating"
            : pageType === "delete"
            ? "Deleting"
            : ""
        if (error.response) {
          console.error("Error Response:", error.response.data)
        } else if (error.request) {
          console.error("No response received")
        } else {
          console.error("Error:", error.message)
        }
        toast.error(`${t(`Failed ${respTitle}`)} ${payload?.type ?? ""}`, {
          style: {
            padding: "16px",
            color: colors.danger.main,
            border: `1px solid ${colors.danger.main}`
          },
          iconTheme: {
            primary: colors.danger.main
          }
        })
      })

    setLoadingDelete(false)
  }

  /////CHANGE EACH TYPE MAIN INPUT
  const changeMainInput = async (params) => {
    setLoadingDelete(true)
    setLoadingText(`${t("Updating")} ${t("Content")} ${params?.type}`)

    let sectionsAllCopy = JSON.parse(JSON.stringify(sectionsAll))
    let dataToUpdateStore
    if (params?.type) {
      let sectionsUpdatedIndex = sectionsAllCopy.findIndex(
        (section) =>
          section?.id === params?.type || section?.type === params?.type
      )
      params["fieldLabel"] = params?.title
      if (sectionsUpdatedIndex !== -1) {
        sectionsAllCopy[sectionsUpdatedIndex] = {
          ...sectionsAllCopy[sectionsUpdatedIndex],
          ...params
        }
        // console.log(params)
        setSectionsAll(sectionsAllCopy)
        dataToUpdateStore = sectionsAllCopy
      }
    }

    await updateToBackend(params, "edit", dataToUpdateStore)
  }

  const [showModalAddEdit, setShowModalAddEdit] = useState(false)
  const [showModalPageType, setShowModalPageType] = useState("add")
  const addDetail = (e, item, type, parent_id) => {
    e.preventDefault()
    seteEditHeaderLanding({ title: `${t("Add New Content")} ${type}` })
    const dynamicFormGen = getDynamicFormDetail(item, type)
    // console.log(dynamicFormGen)
    setEditDynamicFormLanding(dynamicFormGen)
    setEditDefaultFormValuesLanding({ type: type, parent_id: parent_id })
    setShowModalPageType("add")
    setShowModalAddEdit(!showModalAddEdit)
  }

  const editDetail = (e, item, type, parent_id) => {
    e.preventDefault()
    seteEditHeaderLanding({ title: `${t("Edit Content")} ${type}` })
    const dynamicFormGen = getDynamicFormDetail(item, type)
    // console.log(dynamicFormGen)
    setEditDynamicFormLanding(dynamicFormGen)
    setEditDefaultFormValuesLanding({
      ...item,
      parent_id: parent_id,
      type: type
    })
    setShowModalPageType("edit")
    setShowModalAddEdit(!showModalAddEdit)
  }

  const submitDetailInput = async (params) => {
    let keyName =
      params?.type?.replace("-detail", "") === "banner" ? "buttons" : "data"

    let dataToPayload
    let dataToUpdateStore
    if (showModalPageType === "edit") {
      setLoadingDelete(true)
      setLoadingText(`${t("Updating")} ${t("Content")} ${params?.type}`)

      let sectionsAllCopy = JSON.parse(JSON.stringify(sectionsAll))
      let sectionsUpdatedIndex = sectionsAllCopy.findIndex(
        (section) =>
          section?.id === params?.type ||
          section?.type === params?.type?.replace("-detail", "")
      )
      if (sectionsUpdatedIndex !== -1) {
        // if detail level 1
        if (!params?.parent_id) {
          if (
            params?.type === "banner" &&
            params?.id === "" &&
            sectionsAllCopy[sectionsUpdatedIndex].data?.length === 1
          ) {
            let currentDetail = {
              ...sectionsAllCopy[sectionsUpdatedIndex]?.data[0]
            }
            currentDetail = {
              ...currentDetail,
              ...params
            }

            sectionsAllCopy[sectionsUpdatedIndex].data = [currentDetail]
            setSectionsAll(sectionsAllCopy)
            dataToUpdateStore = sectionsAllCopy
            dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
          } else {
            let sectionsUpdatedIndexDetail = sectionsAllCopy[
              sectionsUpdatedIndex
            ]?.data?.findIndex((section) => section?.id === params?.id)
            if (sectionsUpdatedIndexDetail !== -1) {
              let currentDetail = {
                ...sectionsAllCopy[sectionsUpdatedIndex]?.data[
                  sectionsUpdatedIndexDetail
                ]
              }
              currentDetail = {
                ...currentDetail,
                ...params
              }

              sectionsAllCopy[sectionsUpdatedIndex].data[
                sectionsUpdatedIndexDetail
              ] = currentDetail
              setSectionsAll(sectionsAllCopy)
              dataToUpdateStore = sectionsAllCopy
              dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
            }
          }

          // eslint-disable-next-line brace-style
        }
        // if detail level 2
        else {
          let sectionsUpdatedIndexDetail_parent = sectionsAllCopy[
            sectionsUpdatedIndex
          ]?.data?.findIndex((section) => section?.id === params?.parent_id)

          if (sectionsUpdatedIndexDetail_parent !== -1) {
            let currentParentDetailData =
              sectionsAllCopy[sectionsUpdatedIndex]?.data[
                sectionsUpdatedIndexDetail_parent
              ][keyName]
            if (currentParentDetailData) {
              let sectionsUpdatedIndexDetail =
                currentParentDetailData?.findIndex(
                  (section) => section?.id === params?.id
                )
              // console.log(
              //   currentParentDetailData,
              //   sectionsUpdatedIndexDetail,
              //   params?.id
              // )

              if (sectionsUpdatedIndexDetail !== -1) {
                let currentDetail = {
                  ...sectionsAllCopy[sectionsUpdatedIndex]?.data[
                    sectionsUpdatedIndexDetail_parent
                  ][keyName][sectionsUpdatedIndexDetail]
                }
                currentDetail = {
                  ...currentDetail,
                  ...params
                }

                sectionsAllCopy[sectionsUpdatedIndex].data[
                  sectionsUpdatedIndexDetail_parent
                ][keyName][sectionsUpdatedIndexDetail] = currentDetail
                setSectionsAll(sectionsAllCopy)
                dataToUpdateStore = sectionsAllCopy
                dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
              }
            }
          }
        }
      }
    } else {
      // console.log(params)
      setLoadingDelete(true)
      setLoadingText(`${t("Create New")} ${t("Content")} ${params?.type}`)

      let sectionsAllCopy = JSON.parse(JSON.stringify(sectionsAll))
      let sectionsUpdatedIndex = sectionsAllCopy.findIndex(
        (section) =>
          section?.id === params?.type ||
          section?.type === params?.type?.replace("-detail", "")
      )
      if (sectionsUpdatedIndex !== -1) {
        let newParams = params
        if (params?.type === "clients" && !params?.image) {
          newParams[
            "image"
          ] = `https://placehold.co/450x200/00FFFFFF/75797E/png?font=playfair-display&text=${params?.title?.replaceAll(
            " ",
            "%20"
          )}`
        } else if (params?.type === "team" && !params?.image) {
          newParams[
            "image"
          ] = `https://placehold.co/300x308/EEE/31343C?font=playfair-display&text=${params?.title?.replaceAll(
            " ",
            "%20"
          )}`
        } else if (params?.type === "testimonials" && !params?.image) {
          newParams[
            "image"
          ] = `https://ui-avatars.com/api/?size=128&background=10519d&color=fff&name=${params?.title?.replaceAll(
            " ",
            "%20"
          )}`
        }

        // console.log(newParams)

        if (!params?.parent_id) {
          if (
            params?.type === "banner-detail" &&
            sectionsAllCopy[sectionsUpdatedIndex].data?.length === 1 &&
            sectionsAllCopy[sectionsUpdatedIndex].data[0]?.buttons?.length === 0
          ) {
            sectionsAllCopy[sectionsUpdatedIndex].data[0]?.buttons.push(
              newParams
            )
          } else {
            sectionsAllCopy[sectionsUpdatedIndex].data.push(newParams)
          }
          setSectionsAll(sectionsAllCopy)
          dataToUpdateStore = sectionsAllCopy
          dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
        } else {
          let sectionsUpdatedIndexDetail_parent = sectionsAllCopy[
            sectionsUpdatedIndex
          ]?.data?.findIndex((section) => section?.id === params?.parent_id)

          if (sectionsUpdatedIndexDetail_parent !== -1) {
            sectionsAllCopy[sectionsUpdatedIndex]?.data[
              sectionsUpdatedIndexDetail_parent
            ]?.[keyName].push(newParams)

            // console.log(sectionsUpdatedIndexDetail_parent)
            setSectionsAll(sectionsAllCopy)
            dataToUpdateStore = sectionsAllCopy
            dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
          }
        }
      }
    }

    // console.log(dataToPayload)
    if (dataToPayload) {
      await updateToBackend(dataToPayload, showModalPageType, dataToUpdateStore)
    }
  }

  const [showModalDeleteDetail, setShowModalDeleteDetail] = useState(false)
  const deleteDetail = async (e, item, type, parent_id) => {
    e.preventDefault()
    // console.log(e, item)
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text: `${t("You won't be able to revert")} this content!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("Yes, Delete")} this content!`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })
    if (result.value) {
      setLoadingDelete(true)
      setLoadingText(`${t("Deleting")} ${t("Content")} ${type}`)

      let keyName =
        type?.replace("-detail", "") === "banner" ? "buttons" : "data"
      let dataToPayload
      let dataToUpdateStore
      let sectionsAllCopy = JSON.parse(JSON.stringify(sectionsAll))
      let sectionsUpdatedIndex = sectionsAllCopy.findIndex(
        (section) =>
          section?.id === type || section?.type === type?.replace("-detail", "")
      )
      if (sectionsUpdatedIndex !== -1) {
        if (!parent_id) {
          let sectionsUpdatedNew = sectionsAllCopy[
            sectionsUpdatedIndex
          ]?.data?.filter((section) => section?.id !== item?.id)

          sectionsAllCopy[sectionsUpdatedIndex].data = sectionsUpdatedNew
          setSectionsAll(sectionsAllCopy)
          dataToUpdateStore = sectionsAllCopy
          dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
        } else {
          let sectionsUpdatedIndexDetail_parent = sectionsAllCopy[
            sectionsUpdatedIndex
          ]?.data?.findIndex((section) => section?.id === parent_id)

          if (sectionsUpdatedIndexDetail_parent !== -1) {
            let currentParentDetailData =
              sectionsAllCopy[sectionsUpdatedIndex]?.data[
                sectionsUpdatedIndexDetail_parent
              ]?.[keyName]
            if (currentParentDetailData) {
              let currentDetail = sectionsAllCopy[sectionsUpdatedIndex]?.data[
                sectionsUpdatedIndexDetail_parent
              ]?.[keyName].filter((section) => section?.id !== item?.id)

              sectionsAllCopy[sectionsUpdatedIndex].data[
                sectionsUpdatedIndexDetail_parent
              ][keyName] = currentDetail
              setSectionsAll(sectionsAllCopy)
              dataToUpdateStore = sectionsAllCopy
              dataToPayload = sectionsAllCopy[sectionsUpdatedIndex]
            }
          }
        }
      }

      if (dataToPayload) {
        if (item?.image && item?.image !== "undefined") {
        }
        await updateToBackend(dataToPayload, "delete", dataToUpdateStore, item)
      }
    }
  }

  const getDynamicForm = (item, type) => {
    let dynamicForm = [
      {
        fieldName: "fieldLabel",
        fieldLabel: "Title",
        size: 2,
        isHidden: true
      },
      {
        fieldName: "type",
        size: 2,
        isHidden: true
      },
      {
        fieldName: "title",
        fieldLabel: "Title",
        size: 9
      },
      {
        fieldName: "isHidden",
        fieldLabel: "Hidden",
        size: 3,
        type: "button-group",
        className: "justify-content-around",
        dataFrom: {
          value: [
            {
              value: true,
              label: "Yes",
              color: "danger"
            },
            {
              value: false,
              label: "No",
              color: "secondary"
            }
          ],
          labelTranslations: true
        }
      },
      {
        fieldName: "fieldDesc",
        fieldLabel: "Description",
        size: type === "features" ? 6 : 12,
        type: "textarea",
        minRows: type === "features" ? 7 : 3
      }
    ]
    if (type === "parallax-video") {
      dynamicForm = dynamicForm.filter((a) => a.fieldName !== "fieldDesc")
      dynamicForm.push(
        {
          fieldName: "videoLink",
          fieldLabel: "Youtube Video Link",
          type: "youtube",
          style: {
            height: "200px",
            backgroundSize: "200px"
          },
          size: 6,
          initialValueFromOther: item?.videoLink
        },
        {
          fieldName: "fieldDesc",
          fieldLabel: "Description",
          size: 6,
          type: "textarea",
          minRows: 10
        }
      )
    } else if (type === "features") {
      dynamicForm.push({
        fieldName: "mainImage",
        size: 6,
        type: "file_image",
        previewStyle: {
          backgroundSize: "contain"
        }
      })
    } else if (["header", "banner", "footer"].includes(type)) {
      // dynamicForm = dynamicForm.filter(
      //   (a) => a.fieldName !== "fieldDesc" && a.fieldName !== "fieldLabel"
      // )
      dynamicForm = []
    }
    return dynamicForm
  }

  const getDynamicFormDetail = (item, type) => {
    let dynamicForm = [
      {
        fieldName: "id",
        isHidden: true
      },
      {
        fieldName: "type",
        isHidden: true
      }
    ]
    if (["clients", "slider_img", "team", "testimonials"].includes(type)) {
      dynamicForm.push(
        {
          type: "column",
          size: 9,
          children: [
            {
              fieldName: "title",
              size: 12,
              required: false
            },
            type === "clients"
              ? {
                  fieldName: "link",
                  size: 12
                }
              : null,
            ["slider_img", "team", "testimonials"].includes(type)
              ? {
                  fieldName: "subtitle",
                  fieldLabel: "Description",
                  size: 12
                }
              : null,
            type === "testimonials"
              ? {
                  fieldName: "content",
                  size: 12,
                  type: "textarea",
                  minRows: 2
                }
              : null
          ].filter(Boolean)
        },
        {
          type: "column",
          children: [
            {
              fieldName: "image",
              size: 12,
              type: "file_image",
              previewStyle: {
                backgroundSize: "contain"
              },
              // fileImageOpt: {
              //   height: "200px"
              // },
              rules: {
                pattern: {
                  value: ".png,.jpeg,.jpg,.webm"
                }
              }
            }
          ]
        }
      )
    } else if (
      ["faq", "services", "features", "overview", "overview-detail"].includes(
        type
      )
    ) {
      dynamicForm.push(
        ["overview-detail"].includes(type)
          ? {
              fieldName: "parent_id",
              size: 3,
              isHidden: true
            }
          : null,

        {
          fieldName: "title",
          size: 12,
          required: false
        },
        type !== "faq" && type !== "overview"
          ? {
              fieldName: "icon",
              size: 12,
              type: "icon",
              manualInputMode: true
            }
          : null,
        {
          fieldName: "subtitle",
          fieldLabel: "Description",
          type: "textarea",
          minRows: 2,
          size: 12
        },
        type === "overview"
          ? {
              fieldName: "style_type",
              fieldLabel: "section theme",
              size: 12,
              type: "button-group",
              className: "justify-content-around",
              dataFrom: {
                value: [
                  {
                    value: 1,
                    label: "Style 1"
                  },
                  {
                    value: 2,
                    label: "Style 2"
                  }
                ],
                labelTranslations: true
              }
            }
          : null
      )
    } else if (["home", "banner"].includes(type)) {
      dynamicForm.push(
        {
          type: "column",
          size: 8,
          children: [
            {
              fieldName: "title",
              size: 12,
              required: false,
              type: "textarea"
            },
            {
              fieldName: "subtitle",
              fieldLabel: "Description",
              type: "textarea",
              minRows: 3,
              size: 12
            },
            {
              fieldName: "overlay_color1",
              fieldLabel: "Overlay Color 1",
              size: 6,
              type: "color_picker"
            },
            {
              fieldName: "overlay_color2",
              fieldLabel: "Overlay Color 2",
              size: 6,
              type: "color_picker"
            },
            {
              fieldName: "background_video",
              size: 12,
              type: "youtube",
              previewStyle: {
                backgroundSize: "contain"
              }
            }
          ]
        },
        {
          type: "column",
          children: [
            {
              fieldName: "image",
              size: 12,
              type: "file_image",
              previewStyle: {
                backgroundSize: "contain"
              },
              // fileImageOpt: {
              //   height: "200px"
              // },
              rules: {
                pattern: {
                  value: ".png,.jpeg,.jpg,.webm"
                }
              }
            },
            {
              fieldName: "background_image",
              size: 12,
              type: "file_image",
              previewStyle: {
                backgroundSize: "contain"
              },
              // fileImageOpt: {
              //   height: "200px"
              // },
              rules: {
                pattern: {
                  value: ".png,.jpeg,.jpg,.webm"
                }
              }
            }
          ]
        }
      )
    } else if (type === "banner-detail") {
      dynamicForm.push(
        {
          fieldName: "parent_id",
          size: 3,
          isHidden: true
        },
        {
          fieldName: "title",
          size: 6,
          required: false
        },
        {
          fieldName: "subtitle",
          fieldLabel: "Description",
          size: 6
        },
        {
          fieldName: "icon",
          size: 6,
          type: "icon",
          manualInputMode: true
        },
        {
          fieldName: "link",
          size: 6
        }
      )
    } else if (["header"].includes(type)) {
      dynamicForm.push(
        {
          fieldName: "title",
          size: 6,
          required: true
        },
        {
          fieldName: "link",
          size: 6
        }
      )
    }
    return dynamicForm.filter(Boolean)
  }

  const getDefaultFormValues = (item, type) => {
    let defaultValues = {
      fieldLabel: item?.fieldLabel ?? item?.title,
      title: item?.title ?? item?.fieldLabel,
      fieldDesc: item?.fieldDesc,
      isHidden: item?.isHidden,
      type: type
    }
    // console.log(item)
    return defaultValues
  }

  const isSortingRef = useRef(false)
  let sortDetailEventAll = (
    newState,
    sortable,
    store,
    item,
    type,
    parent_id
  ) => {
    if (!isSortingRef.current) return
    isSortingRef.current = false
    // console.log(newState)
    // setSectionsClients(newState)
    updateSectionAllArr(newState, type, parent_id)
  }

  const renderDivider = (item, type) => {
    let detailTitle = t("Contents")
    if (type === "header") {
      detailTitle = t("Main Menu")
    }
    const detailDataDivider = (
      <Row className="align-items-center justify-content-between mt-1 mb-1">
        <Col xs={12} md={10}>
          <div className="divider divider-dashed divider-start divider-primary">
            <div className="divider-text mb-50">
              {renderIcon(
                "material:view_list",
                "me-1 align-middle text-primary",
                20,
                {}
              )}
              <span
                style={{
                  fontSize: "16px"
                }}
                className="fw-bolder align-middle me-1 text-primary"
              >
                {detailTitle}
                <small className="ms-1 fw-normal text-primary">
                  {item?.data?.length > 0
                    ? t("Drag and Drop Cards To sort")
                    : t("There isn't any data yet, start adding new content")}
                </small>
              </span>
            </div>
          </div>
        </Col>
        <Col xs={12} md={2}>
          <Button
            onClick={(e) => addDetail(e, item, type)}
            block
            className="round"
            color="gradient-primary"
          >
            {renderIcon("material:add_circle", `me-50`, 16)}
            <span className="align-middle">{t("Add New")}</span>
          </Button>
        </Col>
      </Row>
    )

    return detailDataDivider
  }

  const renderBtnEditDeleteLvl1 = (item2, type) => {
    let detailTitle = t("Contents")
    if (type === "header") {
      detailTitle = t("Main Menu")
    }
    let className = ""
    let btnColor = "flat-secondary"
    if (["clients", "slider_img", "team"].includes(type)) {
      className = "btn-sm dropdown_menu_img"
      btnColor = "secondary"
    }
    const dropdownButtons = (
      <UncontrolledButtonDropdown
        className={`dropdown-icon-wrapper btn-icon p-0 ${className}`}
      >
        <DropdownToggle
          color={btnColor}
          caret
          className="btn-icon dropdown-toggle-split p-25"
        >
          <MoreVertical className="align-middle" size={12} />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={(e) => editDetail(e, item2, type)}
            href="/"
            tag="a"
          >
            {renderIcon("material:edit_note", "align-middle me-25", 18)}
            {t("Edit")}
          </DropdownItem>
          <DropdownItem
            onClick={(e) => deleteDetail(e, item2, type)}
            href="/"
            tag="a"
          >
            {renderIcon("material:delete_outline", "align-middle me-25", 18)}
            {t("Delete")}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )

    return dropdownButtons
  }

  const renderExpandedContent = (item) => {
    let result
    const type = item.type ?? item?.id
    const sharedForm = (
      <DynamicForm
        dynamicForm={getDynamicForm(item, type)}
        defaultFormValues={{ ...item, ...getDefaultFormValues(item, type) }}
        storeName={`website_${type}_form`}
        // filterMode={true}
        handleFormChangeToParent={changeMainInput}
        editForm={true}
        pageType={"edit"}
        hideAllFotterButton={true}
        cardClassNames={"bg-transparent mb-0"}
        cardBodyClassNames={"p-0"}
      />
    )

    const detailDataDivider = renderDivider(item, type)

    if (["clients"].includes(type)) {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            onUpdate={() => (isSortingRef.current = true)}
            className="row sortable-row justify-content-center mt-3"
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data?.map((item2, index) => (
              <Col
                className="draggable saba_canDrag"
                xl="2"
                md="4"
                sm="12"
                key={item2.id}
              >
                <Card
                  className={`draggable-cards draggable-cards-img ${
                    index < item?.data?.length ? "me-1" : null
                  }`}
                  style={{
                    height: "100px"
                  }}
                >
                  <CardBody
                    onClick={(e) => {
                      e.stopPropagation()
                      MySwal.fire({
                        imageUrl: item2?.image,
                        imageWidth: "auto",
                        imageHeight: "90vh",
                        width: "100%",
                        imageAlt: t("Picture"),
                        backdrop: `rgba(22, 29, 49,0.7)`,
                        confirmButtonText: t("Close"),
                        showCloseButton: true,
                        showConfirmButton: false,
                        grow: "fullscreen"
                      }).then(() => {})
                    }}
                    style={{
                      background: `url("${item2?.image}") center center / contain no-repeat`,
                      borderRadius: "inherit"
                    }}
                    className="text-center p-0 shadow"
                  ></CardBody>
                  {renderBtnEditDeleteLvl1(item2, type)}
                </Card>
              </Col>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else if (["slider_img"].includes(type)) {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            onUpdate={() => (isSortingRef.current = true)}
            className="row sortable-row justify-content-center"
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data?.map((item2, index) => (
              <Col
                className="draggable saba_canDrag"
                xl="2"
                md="4"
                sm="12"
                key={`${type ?? "card_detail"}_${item2.id ?? index}`}
              >
                <Card
                  className={`draggable-cards draggable-cards-img ${
                    index < item?.data?.length ? "me-1" : null
                  }`}
                  style={{
                    height: "118px"
                  }}
                >
                  <CardBody
                    onClick={(e) => {
                      e.stopPropagation()
                      MySwal.fire({
                        imageUrl: item2?.image,
                        imageWidth: "auto",
                        imageHeight: "90vh",
                        width: "100%",
                        imageAlt: t("Picture"),
                        backdrop: `rgba(22, 29, 49,0.7)`,
                        confirmButtonText: t("Close"),
                        showCloseButton: true,
                        showConfirmButton: false,
                        grow: "fullscreen"
                      }).then(() => {})
                    }}
                    style={{
                      background: `url("${item2?.image}") center center / contain no-repeat`,
                      borderRadius: "inherit"
                    }}
                    className="text-center p-0 website_drag_background_img shadow"
                  ></CardBody>
                  {renderBtnEditDeleteLvl1(item2, type)}
                </Card>
              </Col>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else if (["team"].includes(type)) {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            onUpdate={() => (isSortingRef.current = true)}
            className="row sortable-row justify-content-center"
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data.map((item2, index) => (
              <Col
                className="draggable saba_canDrag"
                xl="2"
                md="6"
                sm="12"
                key={`${type ?? "card_detail"}_${item2.id ?? index}`}
              >
                <Card
                  className={`draggable-cards draggable-cards-img ${
                    index < item?.data?.length ? "me-1" : null
                  }`}
                  style={{
                    minHeight: "250px"
                  }}
                >
                  <CardBody
                    onClick={(e) => {
                      e.stopPropagation()
                      MySwal.fire({
                        imageUrl: item2?.image,
                        imageWidth: "auto",
                        imageHeight: "90vh",
                        width: "100%",
                        imageAlt: t("Picture"),
                        backdrop: `rgba(22, 29, 49,0.7)`,
                        confirmButtonText: t("Close"),
                        showCloseButton: true,
                        showConfirmButton: false,
                        grow: "fullscreen"
                      }).then(() => {})
                    }}
                    style={{
                      background: `url("${item2?.image}") center center / cover no-repeat`,
                      borderRadius: "inherit"
                    }}
                    className="text-center p-0 website_drag_background_img shadow"
                  ></CardBody>
                  {renderBtnEditDeleteLvl1(item2, type)}
                  <p className="website_back_avatar_title mt-50">
                    {item2?.title ?? item2?.fieldLabel ?? ""}
                  </p>
                  <small className="website_back_avatar_subtitle mb-50">
                    {item2?.subtitle ?? ""}
                  </small>
                </Card>
              </Col>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else if (["testimonials"].includes(type)) {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            tag="ul"
            className="list-group row sortable-row justify-content-center mx-25"
            onUpdate={() => (isSortingRef.current = true)}
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data?.map((item2, index) => (
              <ListGroupItem
                className="draggable"
                key={`${type ?? "card_detail"}_${item2.id ?? index}`}
              >
                <div className="d-flex align-items-center">
                  {/* <div
                    style={{ width: "10%", lineHeight: 1 }}
                    className="justify-content-center text-center me-1"
                  > */}
                  {item2.image && (
                    <img
                      src={item2.image}
                      className="rounded-circle mb-50 me-1"
                      alt={`${type ?? "card_detail"}_${
                        item2.id ?? index
                      }_"img"`}
                      height="50"
                      width="50"
                    />
                  )}
                  {/* <p className="website_back_avatar_title">
                      {item2?.title ?? item2?.fieldLabel ?? ""}
                    </p>
                    <small className="website_back_avatar_subtitle">
                      {item2?.subtitle ?? ""}
                    </small> */}
                  {/* </div> */}
                  <div style={{ width: "97%" }}>
                    <h5 className="my-0">
                      {item2?.title ?? item2?.fieldLabel ?? ""}
                    </h5>
                    <p className="mb-50 fs-6">{item2?.subtitle ?? ""}</p>
                    <p className="fs-4 mb-0">"{item2.content}"</p>
                  </div>
                  <div className="ms-50" style={{ textAlign: "right" }}>
                    {renderBtnEditDeleteLvl1(item2, type)}
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else if (["faq", "services", "features"].includes(type)) {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            tag="ul"
            className="list-group row sortable-row justify-content-center mx-25"
            onUpdate={() => (isSortingRef.current = true)}
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data?.map((item2, index) => (
              <ListGroupItem
                className="draggable saba_canDrag"
                key={`${type ?? "card_detail"}_${item2.id ?? index}`}
              >
                <div
                  style={{
                    textAlign: "justify"
                  }}
                  className="d-flex align-items-center"
                >
                  {type !== "faq" && (
                    <div
                      style={{
                        width: "40px"
                      }}
                      className="justify-content-center text-center me-1"
                    >
                      {item2.icon &&
                        !item2.image &&
                        renderIcon(
                          item2.icon
                            ?.replace("fas fa-", "fontAwesome:")
                            ?.replace("far fa-", "fontAwesome:")
                            ?.replace("fab fa-", "fontAwesome:"),
                          "text-primary",
                          25
                        )}
                      {item2.image && (
                        <img
                          src={item2.image}
                          className="rounded-circle mb-50"
                          alt={`${type ?? "card_detail"}_${
                            item2.id ?? index
                          }_"img"`}
                          height="50"
                          width="50"
                        />
                      )}
                    </div>
                  )}
                  <div style={{ width: "-webkit-fill-available" }}>
                    <h5 className="mt-0">
                      {item2?.title ?? item2?.fieldLabel ?? ""}
                    </h5>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item2?.subtitle ?? ""
                      }}
                    ></span>
                  </div>
                  <div className="ms-50" style={{ textAlign: "right" }}>
                    {renderBtnEditDeleteLvl1(item2, type)}
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else if (type === "overview") {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            tag="ul"
            className="list-group row sortable-row justify-content-center mx-25"
            onUpdate={() => (isSortingRef.current = true)}
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data?.map((item2, index) => (
              <ListGroupItem
                className="draggable saba_canDrag"
                key={item2.id ?? `listgroup_website_${index}`}
              >
                <div
                  style={{
                    textAlign: "justify"
                  }}
                  className="d-flex align-items-start mt-1"
                >
                  <div
                    // style={{
                    //   width: "40px"
                    // }}
                    className="justify-content-center text-center me-1"
                  >
                    {item2.icon &&
                      !item2.image &&
                      renderIcon(
                        item2.icon
                          ?.replace("fas fa-", "fontAwesome:")
                          ?.replace("far fa-", "fontAwesome:")
                          ?.replace("fab fa-", "fontAwesome:"),
                        "text-primary",
                        25
                      )}
                    {item2.image && (
                      <img
                        onClick={(e) => {
                          e.stopPropagation()
                          MySwal.fire({
                            imageUrl: item2?.image,
                            imageWidth: "auto",
                            imageHeight: "90vh",
                            width: "100%",
                            imageAlt: t("Picture"),
                            backdrop: `rgba(22, 29, 49,0.7)`,
                            confirmButtonText: t("Close"),
                            showCloseButton: true,
                            showConfirmButton: false,
                            grow: "fullscreen"
                          }).then(() => {})
                        }}
                        src={item2.image}
                        className=""
                        alt={`${type ?? "card_detail"}_${
                          item2.id ?? index
                        }_"img"`}
                        style={{
                          // width: "150px"
                          height: "185px"
                        }}
                      />
                    )}
                  </div>
                  <div style={{ width: "-webkit-fill-available" }}>
                    <h5 className="mt-0">
                      {item2?.title ?? item2?.fieldLabel ?? ""}
                      <Badge
                        className="align-middle ms-1"
                        style={{ fontSize: "12px" }}
                        color={"light-primary"}
                      >
                        {`Style ${item2?.style_type ?? "1"}`}
                      </Badge>
                    </h5>
                    <span
                      className="mb-1"
                      dangerouslySetInnerHTML={{
                        __html: item2?.subtitle ?? ""
                      }}
                    ></span>

                    {/* {item2?.data && */}
                    {renderExpandedContentDetail(item2, type, index)}
                    {/* } */}
                  </div>
                  <div className="ms-50" style={{ textAlign: "right" }}>
                    {renderBtnEditDeleteLvl1(item2, type)}
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else if (type === "parallax-video") {
      result = (
        <Fragment>
          {sharedForm}
          {/* <DynamicForm
            dynamicForm={getDynamicForm(item, type)}
            defaultFormValues={{
              ...getDefaultFormValues(item, type),
              videoLink: item?.videoLink
            }}
            storeName={`website_${type}_form`}
            // filterMode={true}
            handleFormChangeToParent={changeMainInput}
            editForm={true}
            pageType={"edit"}
            hideAllFotterButton={true}
            cardClassNames={"bg-transparent"}
            cardBodyClassNames={"p-0"}
          /> */}
        </Fragment>
      )
    } else if (type === "banner") {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            tag="ul"
            className="list-group row sortable-row justify-content-center mx-25"
            onUpdate={() => (isSortingRef.current = true)}
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data?.map((item2, index) => (
              <ListGroupItem
                className="draggable saba_canDrag"
                key={item2.id ?? `listgroup_website_${index}`}
              >
                <div
                  style={{
                    textAlign: "justify"
                  }}
                  className="d-flex align-items-start mt-1"
                >
                  <div
                    // style={{
                    //   width: "40px"
                    // }}
                    className="justify-content-center text-center me-1"
                  >
                    {item2.image && (
                      <img
                        onClick={(e) => {
                          e.stopPropagation()
                          MySwal.fire({
                            imageUrl: item2?.image,
                            imageWidth: "auto",
                            imageHeight: "90vh",
                            width: "100%",
                            imageAlt: t("Picture"),
                            backdrop: `rgba(22, 29, 49,0.7)`,
                            confirmButtonText: t("Close"),
                            showCloseButton: true,
                            showConfirmButton: false,
                            grow: "fullscreen"
                          }).then(() => {})
                        }}
                        src={item2.image}
                        className=""
                        alt={`${type ?? "card_detail"}_${
                          item2.id ?? index
                        }_"img"`}
                        style={{
                          // width: "150px"
                          height: "85px"
                        }}
                      />
                    )}
                  </div>
                  <div style={{ width: "-webkit-fill-available" }}>
                    <h5
                      dangerouslySetInnerHTML={{
                        __html: item2?.title ?? ""
                      }}
                      className="mt-0"
                    ></h5>
                    <span
                      className="mb-2"
                      dangerouslySetInnerHTML={{
                        __html: item2?.subtitle ?? ""
                      }}
                    ></span>
                    <p></p>
                    {item2?.background_image && (
                      <p style={{ fontSize: "12px", margin: 0 }}>
                        <span className="fw-bold">Background Image: </span>
                        <a
                          href={item2?.background_image ?? "#"}
                          target="_blank"
                        >
                          {item2?.background_image ?? ""}
                        </a>
                      </p>
                    )}
                    {item2?.background_video && (
                      <p style={{ fontSize: "12px", margin: 0 }}>
                        <span className="fw-bold">Background Video: </span>
                        <a
                          href={item2?.background_video ?? "#"}
                          target="_blank"
                        >
                          {item2?.background_video ?? ""}
                        </a>
                      </p>
                    )}
                    {item2?.overlay_color1 && (
                      <p
                        className="pt-25"
                        style={{ fontSize: "12px", margin: 0 }}
                      >
                        <span className="fw-bold">Overlay Color 1: </span>
                        <span
                          style={{
                            backgroundColor: item2?.overlay_color1 ?? "inherit",
                            color: "#fff",
                            padding: "3px",
                            borderRadius: "5px"
                          }}
                        >
                          {item2?.overlay_color1 ?? ""}
                        </span>
                      </p>
                    )}
                    {item2?.overlay_color2 && (
                      <p
                        className="pt-25"
                        style={{ fontSize: "12px", margin: 0 }}
                      >
                        <span className="fw-bold">Overlay Color 2: </span>
                        <span
                          style={{
                            backgroundColor: item2?.overlay_color2 ?? "inherit",
                            color: "#fff",
                            padding: "3px",
                            borderRadius: "5px"
                          }}
                        >
                          {item2?.overlay_color2 ?? ""}
                        </span>
                      </p>
                    )}
                    {(item2?.buttons || item?.button) &&
                      renderButtons(item2, type)}
                  </div>
                  <div className="ms-50" style={{ textAlign: "right" }}>
                    {renderBtnEditDeleteLvl1(item2, type)}
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else if (type === "header") {
      result = (
        <Fragment>
          {sharedForm}
          {detailDataDivider}
          <ReactSortable
            tag="ul"
            className="list-group row sortable-row justify-content-center mx-25"
            onUpdate={() => (isSortingRef.current = true)}
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(newState, sortable, store, item, type)
            }
          >
            {item?.data?.map((item2, index) => (
              <ListGroupItem
                className="draggable saba_canDrag"
                key={item2.id ?? `listgroup_website_${index}`}
              >
                <div
                  style={{
                    textAlign: "justify"
                  }}
                  className="d-flex align-items-center"
                >
                  <div style={{ width: "-webkit-fill-available" }}>
                    <h5 className="m-0">{item2?.title ?? ""}</h5>
                  </div>
                  <div className="ms-50" style={{ textAlign: "right" }}>
                    {renderBtnEditDeleteLvl1(item2, type)}
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ReactSortable>
        </Fragment>
      )
    } else {
      result = <Fragment>{sharedForm}</Fragment>
    }
    return result
  }

  const renderExpandedContentDetail = (item, type, index) => {
    let result
    const detailDataDivider = (
      <Row className="align-items-center justify-content-between mt-1 mb-1">
        <Col xs={12} md={isOpenCollapseDetail[index] ? 10 : 11}>
          <div className="divider divider-dashed divider-start divider-info">
            <div className="divider-text mb-50">
              {renderIcon(
                "material:view_headline",
                "me-1 align-middle text-info",
                12,
                {}
              )}
              <span
                style={{
                  fontSize: "12px"
                }}
                className="fw-bolder align-middle me-1 text-info"
              >
                {t("Details Content List")}
                <small className="ms-1 fw-normal text-info">
                  {item?.data?.length > 0
                    ? t("Drag and Drop Cards To sort")
                    : t("There isn't any data yet, start adding new content")}
                </small>
              </span>
            </div>
          </div>
        </Col>

        <Col
          className="text-end"
          xs={12}
          md={isOpenCollapseDetail[index] ? 2 : 1}
        >
          {isOpenCollapseDetail[index] && (
            <Button
              style={{
                width: "70%"
              }}
              onClick={(e) => addDetail(e, item, `${type}-detail`, item.id)}
              // block
              className="btn-sm me-50"
              color="gradient-info"
            >
              {renderIcon("material:post_add", `me-50`, 16)}
              <span className="align-middle">{t("New")}</span>
            </Button>
          )}
          <Button
            className="btn-icon"
            outline
            color="flat-primary"
            onClick={(e) => toggleCollapseDetail(e, index)}
          >
            {isOpenCollapseDetail[index]
              ? renderIcon("material:expand_less", "", 16)
              : renderIcon("material:expand_more", "", 16)}
          </Button>
        </Col>
      </Row>
    )

    result = (
      <Fragment>
        {detailDataDivider}
        <Collapse
          className="collapse-detail"
          isOpen={isOpenCollapseDetail[index]}
        >
          <ReactSortable
            tag="ul"
            className="list-group row sortable-row justify-content-center mx-25 mb-1"
            onUpdate={() => (isSortingRef.current = true)}
            list={item?.data}
            setList={(newState, sortable, store) =>
              sortDetailEventAll(
                newState,
                sortable,
                store,
                item,
                type,
                item?.id
              )
            }
          >
            {item?.data?.map((item2, index) => (
              <ListGroupItem
                className="draggable draggable-detail2 saba_canDrag"
                key={item2.id}
              >
                <div
                  style={{
                    textAlign: "justify"
                  }}
                  className="d-flex align-items-center"
                >
                  <div
                    style={{
                      width: "35px"
                    }}
                    className="justify-content-center text-center me-1"
                  >
                    {item2.icon &&
                      !item2.image &&
                      renderIcon(
                        item2.icon
                          ?.replace("fas fa-", "fontAwesome:")
                          ?.replace("far fa-", "fontAwesome:")
                          ?.replace("fab fa-", "fontAwesome:"),
                        "text-primary",
                        16
                      )}
                    {item2.image && (
                      <img
                        src={item2.image}
                        className="rounded-circle mb-50"
                        alt={`${type ?? "card_detail"}_${
                          item2.id ?? index
                        }_"img"`}
                        height="30"
                        width="30"
                      />
                    )}
                  </div>
                  <div style={{ width: "-webkit-fill-available" }}>
                    <h6 className="mt-0 mb-0">
                      {item2?.title ?? item2?.fieldLabel ?? ""}
                    </h6>
                    <span
                      style={{ fontSize: "12px" }}
                      dangerouslySetInnerHTML={{
                        __html: item2?.subtitle ?? ""
                      }}
                    ></span>
                  </div>
                  <div className="ms-50" style={{ textAlign: "right" }}>
                    <UncontrolledButtonDropdown className="dropdown-icon-wrapper btn-icon p-0">
                      <DropdownToggle
                        color="flat-secondary"
                        caret
                        className="btn-icon dropdown-toggle-split"
                      >
                        <MoreVertical className="align-middle" size={12} />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) =>
                            editDetail(e, item2, `${type}-detail`, item?.id)
                          }
                          href="/"
                          tag="a"
                        >
                          {renderIcon(
                            "material:edit_note",
                            "align-middle me-25",
                            18
                          )}
                          {t("Edit")}
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) =>
                            deleteDetail(e, item2, `${type}-detail`, item?.id)
                          }
                          href="/"
                          tag="a"
                        >
                          {renderIcon(
                            "material:delete_outline",
                            "align-middle me-25",
                            18
                          )}
                          {t("Delete")}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ReactSortable>
        </Collapse>
      </Fragment>
    )

    return result
  }

  const renderButtons = (item, type) => {
    let result
    // let newData = (item?.buttons ?? item?.button ?? []).map((x) => {
    //   return { ...x, id: generateUniqueId() }
    // })
    // console.log(newData)
    // const [sectionsCurrentDetail, setSectionsCurrentDetail] = useState(newData)
    // const isSortingRefDetail = useRef(false)
    // let sortDetailEventDetail = (newState, sortable, store, item) => {
    //   if (!isSortingRefDetail.current) return
    //   isSortingRefDetail.current = false
    //   setSectionsCurrentDetail(newState)
    //   // updateSectionAllArrDetail(newState, type)
    // }

    const detailDataDivider = (
      <Row className="align-items-center justify-content-between mt-1 mb-1">
        <Col xs={12} md={10}>
          <div className="divider divider-dashed divider-start divider-info">
            <div className="divider-text mb-50">
              {renderIcon(
                "material:view_headline",
                "me-1 align-middle divider-info",
                12,
                {}
              )}
              <span
                style={{
                  fontSize: "12px"
                }}
                className="fw-bolder align-middle me-1 text-info"
              >
                {t("Buttons")}
                <small className="ms-1 fw-normal text-info">
                  {item?.buttons?.length > 0
                    ? t("Drag and Drop Cards To sort")
                    : t("There isn't any data yet, start adding new button")}
                </small>
              </span>
            </div>
          </div>
        </Col>

        <Col xs={12} md={2}>
          <Button
            onClick={(e) => addDetail(e, item, `${type}-detail`, item.id)}
            block
            className="btn-sm"
            color="gradient-info"
          >
            {renderIcon("material:post_add", `me-50`, 16)}
            <span className="align-middle">{t("New")}</span>
          </Button>
        </Col>
      </Row>
    )

    result = (
      <Fragment>
        {detailDataDivider}
        <ReactSortable
          tag="ul"
          className="list-group row sortable-row justify-content-center mx-25 mb-1"
          onUpdate={() => (isSortingRef.current = true)}
          list={item?.buttons}
          setList={(newState, sortable, store) =>
            sortDetailEventAll(newState, sortable, store, item, type, item?.id)
          }
        >
          {item?.buttons?.map((item2, index) => (
            <ListGroupItem
              className="draggable draggable-detail2 saba_canDrag"
              key={item2.id}
            >
              <div
                style={{
                  textAlign: "justify"
                }}
                className="d-flex align-items-center"
              >
                <div
                  style={{
                    width: "35px"
                  }}
                  className="justify-content-center text-center me-1"
                >
                  {item2.icon &&
                    renderIcon(
                      item2.icon
                        ?.replace("fas fa-", "fontAwesome:")
                        ?.replace("far fa-", "fontAwesome:")
                        ?.replace("fab fa-", "fontAwesome:"),
                      "text-primary",
                      22
                    )}
                </div>
                <div style={{ width: "-webkit-fill-available" }}>
                  <h6 className="mt-0 mb-0">{item2?.title ?? ""}</h6>
                  <small style={{ fontSize: "12px" }}>
                    {item2?.subtitle ?? ""}
                  </small>
                  <p style={{ fontSize: "12px", margin: 0 }}>
                    Link: {item2?.link ?? "#"}
                  </p>
                </div>
                <div className="ms-50" style={{ textAlign: "right" }}>
                  <UncontrolledButtonDropdown className="dropdown-icon-wrapper btn-icon p-0">
                    <DropdownToggle
                      color="flat-secondary"
                      caret
                      className="btn-icon dropdown-toggle-split"
                    >
                      <MoreVertical className="align-middle" size={12} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(e) =>
                          editDetail(e, item2, `${type}-detail`, item?.id)
                        }
                        href="/"
                        tag="a"
                      >
                        {renderIcon(
                          "material:edit_note",
                          "align-middle me-25",
                          18
                        )}
                        {t("Edit")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={(e) =>
                          deleteDetail(e, item2, `${type}-detail`, item?.id)
                        }
                        href="/"
                        tag="a"
                      >
                        {renderIcon(
                          "material:delete_outline",
                          "align-middle me-25",
                          18
                        )}
                        {t("Delete")}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ReactSortable>
      </Fragment>
    )

    return result
  }

  const getTitle = (item) => {
    let title =
      item.title && item.title !== ""
        ? item.title
        : item.fieldLabel && item.fieldLabel !== ""
        ? item.fieldLabel
        : item.fieldName && item.fieldName !== ""
        ? item.fieldName
        : item.type && item.type !== ""
        ? item.type
        : ""
    return title
  }

  return (
    <Fragment>
      <UILoader
        blocking={
          loadingDelete || store?.pageData?.[storeName]?.isLoadingDetail
        }
        loader={<Loader loadingText={loadingText} />}
      >
        {sectionsAll && (
          <Fragment>
            <div className="mb-3">
              <DynamicForm
                dynamicForm={[
                  {
                    fieldName: "status",
                    fieldLabel: "Status",
                    size: 3,
                    type: "button-group",
                    className: "justify-content-around",
                    dataFrom: {
                      value: [
                        {
                          value: 1,
                          label: "Published",
                          color: "flat-primary",
                          icon: "material:public"
                        },
                        {
                          value: 0,
                          label: "Disabled",
                          color: "flat-danger",
                          icon: "material:cloud_off"
                        }
                      ],
                      labelTranslations: true
                    }
                  },
                  {
                    fieldName: "gridTheme",
                    fieldLabel: "Theme",
                    size: 3,
                    type: "dropdown",
                    dataFrom: {
                      value: [
                        {
                          value: "naxos",
                          label: "Naxos"
                        }
                      ]
                    }
                  },
                  {
                    fieldName: "defaultRoute",
                    fieldLabel: "Base Url Route",
                    size: 3,
                    type: "button-group",
                    className: "justify-content-around",
                    dataFrom: {
                      value: [
                        {
                          value: "login",
                          label: "/login",
                          color: "flat-primary"
                        },
                        {
                          value: "public",
                          label: "/public",
                          color: "flat-primary"
                        }
                      ],
                      labelTranslations: true
                    }
                  },
                  {
                    fieldName: "siteLink",
                    fieldLabel: "View Site",
                    icon: "fontAwesome:square-arrow-up-right",
                    iconClass: "ms-25",
                    iconSize: "16",
                    color: "relief-primary",
                    round: false,
                    outline: false,
                    iconPos: "right",
                    size: 3,
                    type: "button",
                    clickLinkUrl: {
                      static: "/public",
                      externalLink: true
                    },
                    className: "justify-content-around"
                  }
                ]}
                defaultFormValues={{
                  status: storeDomain?.data?.landing_page_attr?.status ?? "1",
                  gridTheme:
                    storeDomain?.data?.landing_page_attr?.gridTheme ?? "naxos",
                  defaultRoute:
                    storeDomain?.data?.landing_page_attr?.defaultRoute ??
                    "login"
                }}
                storeName="website_main_form"
                filterMode={true}
                handleFiltersChangeToParent={changeMainInput}
                editForm={true}
                pageType={"edit"}
                hideAllFotterButton={true}
              />
            </div>
            <div className="mt-0 mb-1 divider divider-dashed divider-start">
              <div className="divider-text mb-50">
                {renderIcon("material:web", "me-1 align-middle", 20, {})}
                <span
                  style={{
                    fontSize: "16px"
                  }}
                  className="fw-bolder align-middle me-1"
                >
                  {t("List of")} {t("Sections")}
                  <small className="ms-1 fw-normal">
                    {t("Drag and Drop Cards To sort")}
                  </small>
                </span>
              </div>
            </div>

            <ReactSortable
              className="row sortable-row"
              list={sectionsAll}
              // setList={setSectionsAll}
              setList={(newState, sortable, store) =>
                sortDetailEventAll(newState, sortable, store)
              }
              onUpdate={() => (isSortingRef.current = true)}
              draggable=".saba_canDrag"
            >
              {sectionsAll.map((item) => (
                <Col
                  // style={{
                  //   cursor: !item?.isDisabledDrag ? "pointer" : "initial"
                  // }}
                  className={`draggable ${
                    !item?.isDisabledDrag &&
                    !isOpenCollapse[item?.id ?? item?.type]
                      ? "saba_canDrag"
                      : "saba_disabledDrag"
                  } ${
                    !item?.hidden && isOpenCollapse[item?.id ?? item?.type]
                      ? "not_disabled"
                      : ""
                  }`}
                  xl="12"
                  md="12"
                  sm="12"
                  key={item.id ?? item?.type}
                >
                  <Card
                    style={item.cardItemStyle ?? {}}
                    className={`draggable-cards mb-1 ${
                      item?.isHidden ? "draggable-cards-hidden" : ""
                    } ${item?.cardItemClassNames ?? ""} ${
                      !item?.hidden && isOpenCollapse[item?.id ?? item?.type]
                        ? "not_disabled"
                        : ""
                    }`}
                  >
                    <CardHeader className={`py-50`}>
                      <CardTitle
                        style={{
                          // ...(item.cardItemStyle?.color
                          //   ? {
                          //       color: item.cardItemStyle.color
                          //     }
                          //   : {}),
                          width: "80%",
                          textTransform: "capitalize"
                        }}
                        tag="h4"
                      >
                        {getTitle(item)}

                        <div className="d-block d-lg-none mt-50">
                          <Badge
                            className="align-middle"
                            style={{ fontSize: "10px" }}
                            color={
                              item?.isHidden
                                ? "light-secondary"
                                : "light-warning"
                            }
                          >
                            {item?.type}
                          </Badge>

                          {item?.isHidden && (
                            <Badge
                              className="ms-50 align-middle"
                              style={{ fontSize: "10px" }}
                              color="light-secondary"
                            >
                              {t("Hidden")}
                            </Badge>
                          )}
                        </div>

                        {/* {(item?.subtitle || item?.fieldDesc) && (
                      <div
                        style={{
                          fontSize: "11px"
                        }}
                        className="text-muted mt-25"
                      >
                        {item?.subtitle ?? item?.fieldDesc}
                      </div>
                    )} */}
                      </CardTitle>

                      <div>
                        <Badge
                          className="align-middle d-none d-lg-inline-flex"
                          style={{ fontSize: "10px" }}
                          color={
                            item?.isHidden ? "light-secondary" : "light-warning"
                          }
                        >
                          {item?.type}
                        </Badge>

                        {item?.isHidden && (
                          <Badge
                            className="ms-50 align-middle d-none d-lg-inline-flex"
                            style={{ fontSize: "10px" }}
                            color="light-secondary"
                          >
                            {t("Hidden")}
                          </Badge>
                        )}
                        <Button.Ripple
                          className="btn-icon"
                          outline
                          color="flat-primary"
                          onClick={(e) => toggleCollapse(item)}
                        >
                          {isOpenCollapse[item?.id ?? item?.type]
                            ? renderIcon("material:expand_less", "", 16)
                            : renderIcon("material:expand_more", "", 16)}
                        </Button.Ripple>
                      </div>
                    </CardHeader>

                    {/* {isOpenCollapse[item?.id ?? item?.type] && ( */}
                    <Collapse isOpen={isOpenCollapse[item?.id ?? item?.type]}>
                      <CardBody>{renderExpandedContent(item)}</CardBody>
                    </Collapse>
                    {/* )} */}
                  </Card>
                </Col>
              ))}
            </ReactSortable>
          </Fragment>
        )}
      </UILoader>

      {showModalAddEdit && (
        <ModalAddEditLanding
          // root={modalDevEditRoot}
          headers={editHeaderLanding}
          // fieldArray={modalDevEditData}
          // setFieldArray={setModalDevEditData}
          show={showModalAddEdit}
          setShow={setShowModalAddEdit}
          dynamicForm={editDynamicFormLanding}
          defaultFormValues={editDefaultFormValuesLanding}
          pageType={showModalPageType}
          // storeName={storeName}
          // pageAttr={}
          submitHandle={submitDetailInput}
        />
      )}
    </Fragment>
  )
}

export default ListSortable
