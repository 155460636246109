import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { resetData } from "../../../my-info/store/my_activities"
import useJwt from "@src/auth/jwt/useJwt"

const axios = useJwt.ssoAxiosInterceptor
export const pageTitle = "User Activities" ////
export const storeName = "apps_userActivity" ////
export const endpointName = "api/userActivity" ////

export const serverSideGrid = true ////
export const detailFromGrid = true //// GET DETAIL DATA FROM EXISTING DATA GRID (FASTER PEFORMANCE)
export const getAlwaysGrid = false //// GET ALWAYS GRID DATA EVERY CLICK MENU OR OPEN PAGE
export const getApiAfterPut = true ////
export const getApiAfterPost = true ////
export const getApiAfterDelete = true ////
export const getApiSummaryData = false //// GET SUMMARY DATA FROM BACKEND API
export const detailPage = true //// IF TRUE { WILL BE ACTIVATED DETAIL PAGE AND MENU ACTION BUTTON } ELSE { DETAIL PAGE SHOW ON MODAL }
export const deleteSelection = false
export const disabledAction = []
export const isManyToManyGrid = true
export const storeToRefreshAfterAll = []
export const isTreeLayout = false

///////DYNAMIC COLUMNS FOR TABLE
///////if there are several fields that will be customised, don't set them here but add them to the Table.js component in the column section
export const dynamicColumns = [
  {
    columnName: "Url",
    fieldName: "url_path",
    minWidth: "350px"
  }
  // {
  //   columnName: "App",
  //   fieldName: "app_name",
  //   minWidth: "120px"
  // }
]

///////DYNAMIC FILTERS FOR TABLE
export const dynamicColumnsFilter = [
  {
    filterLabel: "App",
    fieldName: "id_app"
  }
]

///////DYNAMIC FORM FOR MODAL
///////if the fields display on the modal form matches the number of fields in the columns, leave this section filled with an empty array " dynamicForm = [] ". Or if you want to customize, do it on the modal/index.js component
export const dynamicForm = [
  {
    fieldName: "url_path",
    fieldLabel: "Url",
    type: "text",
    required: true
  },
  {
    fieldName: "id_app",
    fieldLabel: "App",
    type: "dropdown",
    required: true,
    datafromStore: "app"
  }
]

export const extraCrud = []

export const getSummaryData = createAsyncThunk(
  `${endpointName}/getSummaryData`,
  async (params) => {
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key]
      }
    })
    const response = await axios.get(`/${endpointName}/summary`, {
      params
    })
    return {
      params,
      data: response.data.data
    }
  }
)

export const getDataList = createAsyncThunk(
  `${endpointName}/getDataList`,
  async (params) => {
    // console.log(params)
    Object.keys(params).forEach((key) => {
      if (params[key] === null || params[key] === "") {
        delete params[key]
      }
    })
    // params["filter_hide_me"] = "1"
    const response = await axios.get(`/${endpointName}`, {
      params
    })
    return {
      params,
      data: response.data.data,
      total: response.data.total
    }
  }
)

export const setDataList = createAsyncThunk(
  `${endpointName}/setDataList`,
  async (rowData, { rejectWithValue }) => {
    return rejectWithValue(rowData)
  }
)

export const getDataById = createAsyncThunk(
  `${endpointName}/getDataById`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/${endpointName}/${id}`)
      return response.data.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetSelectedData = createAsyncThunk(
  `${endpointName}/resetSelectedData`,
  async ({ rejectWithValue }) => {
    return rejectWithValue({})
  }
)

export const postData = createAsyncThunk(
  `${endpointName}/postData`,
  async (user, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`/${endpointName}`, user)
      dispatch(resetSelectedData({}))
      if (getApiAfterPost) {
        dispatch(getDataList(getState()[storeName].params))
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const putData = createAsyncThunk(
  `${endpointName}/putData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    // Object.keys(data).forEach((key) => {
    //   if (data[key] === null || data[key] === "") {
    //     delete data[key]
    //   }
    // })
    const id = data.id ?? getState()[storeName].selectedData.id
    const notReload = data["notReload"]
    if (notReload) delete data["notReload"]
    try {
      const response = await axios.put(`/${endpointName}/${id}`, data)
      dispatch(resetSelectedData({}))
      if (getApiAfterPut && !notReload) {
        await dispatch(getDataList(getState()[storeName].params))
        if (getApiSummaryData) {
          dispatch(getSummaryData({}))
        }
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteData = createAsyncThunk(
  `${endpointName}/deleteData`,
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/${endpointName}/${id}`)
      if (getApiAfterDelete) {
        await dispatch(getDataList(getState()[storeName].params))
        if (getApiSummaryData) {
          dispatch(getSummaryData({}))
        }
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const deleteSelectedRowData = createAsyncThunk(
  `${endpointName}/deleteSelectedRowData`,
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/${endpointName}`, { data })
      if (getApiAfterDelete) {
        await dispatch(getDataList(getState()[storeName].params))
        if (getApiSummaryData) {
          dispatch(getSummaryData({}))
        }
      }
      dispatch(resetData({}))
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const setDataById = createAsyncThunk(
  `${endpointName}/setDataById`,
  async (rowData, { rejectWithValue }) => {
    return rejectWithValue(rowData)
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const StoreSlice = createSlice({
  name: storeName,
  initialState: {
    pageTitle,
    dynamicColumns,
    // SUMMARY
    statusSummary: fetchStatus,
    isLoadingSummary: false,
    errorSummary: "" | null,
    dataSummary: [],

    // ALL
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data: [],
    total: 1,

    params: {},
    selectedData: null,
    dataDropdown: [],

    // DETAIL
    statusDetail: fetchStatus,
    isLoadingDetail: false,
    errorDetail: "" | null,

    // ADD&EDIT
    statusAddEdit: fetchStatus,
    isLoadingAddEdit: false,
    errorAddEdit: "" | null,

    // ADD&EDIT
    statusDelete: fetchStatus,
    isLoadingDelete: false,
    errorDelete: "" | null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      ///SUMMARY
      .addCase(getSummaryData.pending, (state) => {
        state.statusSummary = "loading"
        state.isLoadingSummary = true
      })
      .addCase(getSummaryData.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.statusSummary = "succeeded"
        state.isLoadingSummary = false
        state.dataSummary = action.payload.data
      })
      .addCase(getSummaryData.rejected, (state, action) => {
        state.statusSummary = "failed"
        state.isLoadingSummary = false
        state.errorSummary = action.error.message
      })

      ///ALL
      .addCase(getDataList.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
        state.error = null
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.dataDropdown = action.payload.data.map((item) => ({
          value: item.id,
          label: item.title
        }))
        state.status = "succeeded"
        state.isLoading = false
        state.error = null
      })
      .addCase(getDataList.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        // console.log(action)
        state.error = action.error.payload?.message ?? action.error.message
      })

      ///EDIT
      .addCase(putData.pending, (state) => {
        state.statusAddEdit = "loading"
        state.isLoadingAddEdit = true
      })
      .addCase(putData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusAddEdit = "succeeded"
        state.isLoadingAddEdit = false
      })
      .addCase(putData.rejected, (state, action) => {
        state.statusAddEdit = "failed"
        state.isLoadingAddEdit = false
        state.errorAddEdit = action.payload?.message
      })

      ///ADD
      .addCase(postData.pending, (state) => {
        state.statusAddEdit = "loading"
        state.isLoadingAddEdit = true
      })
      .addCase(postData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusAddEdit = "succeeded"
        state.isLoadingAddEdit = false
      })
      .addCase(postData.rejected, (state, action) => {
        state.statusAddEdit = "failed"
        state.isLoadingAddEdit = false
        state.errorAddEdit = action.payload?.message
      })

      ///DELETE
      .addCase(deleteData.pending, (state) => {
        state.statusDelete = "loading"
        state.isLoadingDelete = true
      })
      .addCase(deleteData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusDelete = "succeeded"
        state.isLoadingDelete = false
      })
      .addCase(deleteData.rejected, (state, action) => {
        state.statusDelete = "failed"
        state.isLoadingDelete = false
        state.errorDelete = action.payload?.message
      })

      ///DELETE-SELECTED-ROW
      .addCase(deleteSelectedRowData.pending, (state) => {
        state.statusDelete = "loading"
        state.isLoadingDelete = true
      })
      .addCase(deleteSelectedRowData.fulfilled, (state) => {
        // console.log(action.payload)
        state.statusDelete = "succeeded"
        state.isLoadingDelete = false
      })
      .addCase(deleteSelectedRowData.rejected, (state, action) => {
        state.statusDelete = "failed"
        state.isLoadingDelete = false
        state.errorDelete = action.payload?.message
      })

      ///DETAIL
      .addCase(getDataById.pending, (state) => {
        state.statusDetail = "loading"
        state.isLoadingDetail = true
      })
      .addCase(getDataById.fulfilled, (state, action) => {
        state.statusDetail = "succeeded"
        state.isLoadingDetail = false
        state.selectedData = action.payload
      })
      .addCase(getDataById.rejected, (state, action) => {
        state.statusDetail = "failed"
        state.isLoadingDetail = false
        state.selectedData = null
        state.errorDetail = action.payload?.message
      })

      ///OTHERS
      .addCase(resetSelectedData.rejected, (state) => {
        state.selectedData = null
      })
      .addCase(setDataList.rejected, (state, action) => {
        state.data = action.payload
      })
      .addCase(setDataById.rejected, (state, action) => {
        state.selectedData = action.payload
      })
  }
})

export default StoreSlice.reducer
