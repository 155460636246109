// ** Third Party Components
import Avatar from "@components/avatar"
import { Calendar, MapPin, Video } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

// ** Reactstrap Imports
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner
} from "reactstrap"

import illustration from "@src/assets/images/illustration/email.svg"
import {
  getDataList,
  putDataWidget,
  resetData
} from "@src/shared/dynamic_pages/store"
import "moment-timezone"
import "moment/locale/id"
import { Fragment, useContext, useState } from "react"
import Moment from "react-moment"
import { renderIcon } from "../../views/apps/menu/store/my_menu"

import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { getDataList as getDataListMyActivity } from "@src/views/my-info/store/my_activities"
import toast from "react-hot-toast"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const params = {
  // navigation: true
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 5500,
    disableOnInteraction: false
  },
  slideToClickedSlide: true
}

const MySwal = withReactContent(Swal)

const ListGroupBy = ({ item, trackBgColor, handleChangeFilterFromChild }) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const storeAll = useSelector((state) => state.dynamicStore)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { colors } = useContext(ThemeColors)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const storeName = item?.cardId
  const endpointName = item?.dataFrom?.api
  const baseUrl = item?.dataFrom?.baseUrl
  const [openAcc, setOpenAcc] = useState(null)
  const toggleAcc = (id) => {
    openAcc === id ? setOpenAcc() : setOpenAcc(id)
  }

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const putDataToApi = (data, item = null, row = null, status = "approve") => {
    let dataTitle = row?.[item?.data_titleField] ?? row?.title
    setLoadingPostPut(true)
    setLoadingText(
      status === "approve"
        ? `${t("Approving Submission")} ${dataTitle}...`
        : `${t("Rejecting Submission")} ${dataTitle}...`
    )
    dispatch(putDataWidget(data))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          // if (storeAll?.pageData[storeName]?.data) {
          //   dispatch(resetData(storeName))
          // }
          // dispatch(
          //   getDataList({
          //     storeName: storeName,
          //     endpointName: endpointName,
          //     baseUrl: baseUrl
          //   })
          // )
          handleChangeFilterFromChild({ item: item })
            .then(() => {
              toast.success(
                status === "approve"
                  ? `${t("Successfully Approved")} ${dataTitle}`
                  : `${t("Successfully Rejected")} ${dataTitle}`,
                {
                  style: {
                    padding: "16px",
                    color: colors.success.main,
                    border: `1px solid ${colors.success.main}`
                  },
                  iconTheme: {
                    primary: colors.success.main
                  }
                }
              )
            })
            .then(() => {
              setLoadingPostPut(false)
            })
            .then(() => {
              if (item?.afterSubmitReloadOtherPage) {
                setTimeout(() => {
                  item?.afterSubmitReloadOtherPage?.map((url) => {
                    const store_to_reset = url.replaceAll("/", "_")
                    if (storeAll?.pageData?.[store_to_reset]?.data) {
                      dispatch(resetData(store_to_reset))
                      // dispatch(
                      //   getDataList({
                      //     ...(storeAll?.pageData?.[store_to_reset]?.params ?? {}),
                      //     storeName: store_to_reset,
                      //     endpointName:
                      //       storeAll.pageAttributes[store_to_reset]?.crudOpt
                      //         ?.endpointName,
                      //     baseUrl:
                      //       storeAll.pageAttributes[store_to_reset]?.crudOpt
                      //         ?.baseUrl
                      //   })
                      // )
                    }
                  })
                }, 1000)
              } else {
                console.log("Successfully Approved")
              }
            })
        } else if (res.meta.requestStatus === "rejected") {
          setLoadingPostPut(false)
          errorToast(res)
        }
      })
      .catch((error) => {
        // console.error(error)
        setLoadingPostPut(false)
        errorToast(error)
      })
  }

  const btnEventSubmit = async (row, item) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text:
        item?.submitPromptMsg ??
        `${t("You will approve the submission.")} ${
          row?.[item?.data_titleField] ?? row?.title
        }. ${t("Approved data cannot be recovered")}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: item?.submitPromptBtnText ?? `${t("Yes, Approve")}`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })

    if (result.value) {
      setLoadingPostPut(true)
      setLoadingText(`${t("Approve the Submission")}...`)

      let endpointNameTo = item?.submitDataTo?.api
      let appendToEndpointPath = item?.submitDataTo?.appendToEndpointPath
      if (appendToEndpointPath) {
        let endpointNameTo_extra_arr = []

        appendToEndpointPath.map((qs, index) => {
          if (row?.[qs]) {
            endpointNameTo_extra_arr.push(row?.[qs])
          }
        })
        let endpointNameTo_extra = endpointNameTo_extra_arr.join("/")
        endpointNameTo = `${endpointNameTo}/${endpointNameTo_extra}`
      }

      let payLoad = {
        // id: row?.[item?.data_idField] ?? row?.id,
        storeName: storeName,
        endpointName: endpointNameTo,
        baseUrl: item?.submitDataTo?.baseUrl
        // callback_page: window.location.href
      }

      const payloadFromFields = item?.submitDataTo?.payloadFromFields
      if (payloadFromFields && Array.isArray(payloadFromFields)) {
        payloadFromFields?.map((i, x) => {
          payLoad[i?.paramName] = row?.[i?.fieldName]
        })
      }

      const payloadFromStatic = item?.submitDataTo?.payloadFromStatic
      if (payloadFromStatic && Array.isArray(payloadFromStatic)) {
        payloadFromStatic?.map((i, x) => {
          payLoad[i?.paramName] = i?.value
        })
      }

      return putDataToApi(payLoad, item, row)
    }
  }

  const btnEventReject = async (row, item) => {
    const result = await MySwal.fire({
      title: `${t("Are you sure?")}`,
      text:
        item?.rejectPromptMsg ??
        `${t("You will decline the submission.")} ${
          row?.[item?.data_titleField] ?? row?.title
        }. ${t("Declined data cannot be recovered")}!`,
      icon: "danger",
      showCancelButton: true,
      confirmButtonText: item?.rejectPromptBtnText ?? `${t("Yes, Decline")}`,
      cancelButtonText: `${t("Cancel")}`,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1"
      },
      buttonsStyling: false
    })

    if (result.value) {
      setLoadingPostPut(true)
      setLoadingText(`${t("Decline the Submission")}...`)

      let endpointNameTo = item?.rejectDataTo?.api
      let appendToEndpointPath = item?.rejectDataTo?.appendToEndpointPath
      if (appendToEndpointPath) {
        let endpointNameTo_extra_arr = []

        appendToEndpointPath.map((qs, index) => {
          if (row?.[qs]) {
            endpointNameTo_extra_arr.push(row?.[qs])
          }
        })
        let endpointNameTo_extra = endpointNameTo_extra_arr.join("/")
        endpointNameTo = `${endpointNameTo}/${endpointNameTo_extra}`
      }

      let payLoad = {
        storeName: storeName,
        endpointName: endpointNameTo,
        baseUrl: item?.rejectDataTo?.baseUrl
      }

      const payloadFromFields = item?.rejectDataTo?.payloadFromFields
      if (payloadFromFields && Array.isArray(payloadFromFields)) {
        payloadFromFields?.map((i, x) => {
          payLoad[i?.paramName] = row?.[i?.fieldName]
        })
      }

      const payloadFromStatic = item?.rejectDataTo?.payloadFromStatic
      if (payloadFromStatic && Array.isArray(payloadFromStatic)) {
        payloadFromStatic?.map((i, x) => {
          payLoad[i?.paramName] = i?.value
        })
      }

      return putDataToApi(payLoad, item, row, "reject")
    }
  }

  const btnEventExtra = async (row, item) => {
    let endpointNameTo = item?.extraBtnTo?.static
    if (item?.extraBtnTo?.field) {
      endpointNameTo = row[item?.extraBtnTo?.field]
    }

    const paramsFromFields = item?.extraBtnTo?.paramsFromFields
    if (paramsFromFields && Array.isArray(paramsFromFields)) {
      let paramsFromFields_arr = []
      paramsFromFields?.map((i, x) => {
        const value = row?.[i?.fieldName]
        if (value) {
          paramsFromFields_arr.push(`${i?.paramName}=${value}`)
        }
      })
      let endpointNameTo_extra = paramsFromFields_arr.join("&")
      if (endpointNameTo?.includes("?")) {
        endpointNameTo = `${endpointNameTo}&${endpointNameTo_extra}`
      } else {
        endpointNameTo = `${endpointNameTo}?${endpointNameTo_extra}`
      }
    }

    if (
      endpointNameTo.startsWith("http://") ||
      endpointNameTo.startsWith("https://")
    ) {
      return window.open(endpointNameTo, "_blank", "noreferrer")
    } else {
      return navigate(endpointNameTo ?? "/")
    }
  }

  const calendarStrings = {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "[last] dddd",
    nextWeek: "dddd",
    sameElse: "L"
  }

  const toUpperCaseFilter = (d) => {
    return d.toUpperCase()
  }

  const employeesTasks = [
    {
      avatar: require("@src/assets/images/portrait/small/avatar-s-9.jpg")
        .default,
      title: "Ryan Harrington",
      subtitle: "iOS Developer",
      time: "9hr 20m",
      chart: {
        type: "radialBar",
        series: [45],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [colors.primary.main],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%"
              },
              track: {
                background: trackBgColor
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: "round"
          }
        }
      }
    },
    {
      avatar: require("@src/assets/images/portrait/small/avatar-s-20.jpg")
        .default,
      title: "Louisa Norton",
      subtitle: "UI Designer",
      time: "4hr 17m",
      chart: {
        type: "radialBar",
        series: [65],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [colors.danger.main],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%"
              },
              track: {
                background: trackBgColor
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: "round"
          }
        }
      }
    },
    {
      avatar: require("@src/assets/images/portrait/small/avatar-s-1.jpg")
        .default,
      title: "Jayden Duncan",
      subtitle: "Java Developer",
      time: "12hr 8m",
      chart: {
        type: "radialBar",
        series: [60],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [colors.success.main],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%"
              },
              track: {
                background: trackBgColor
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: "round"
          }
        }
      }
    },
    {
      avatar: require("@src/assets/images/portrait/small/avatar-s-20.jpg")
        .default,
      title: "Cynthia Howell",
      subtitle: "Angular Developer",
      time: "3hr 19m",
      chart: {
        type: "radialBar",
        series: [35],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [colors.secondary.main],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%"
              },
              track: {
                background: trackBgColor
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: "round"
          }
        }
      }
    },
    {
      avatar: require("@src/assets/images/portrait/small/avatar-s-16.jpg")
        .default,
      title: "Helena Payne",
      subtitle: "Marketing",
      time: "9hr 50m",
      chart: {
        type: "radialBar",
        series: [65],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [colors.warning.main],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%"
              },
              track: {
                background: trackBgColor
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: "round"
          }
        }
      }
    },
    {
      avatar: require("@src/assets/images/portrait/small/avatar-s-13.jpg")
        .default,
      title: "Troy Jensen",
      subtitle: "iOS Developer",
      time: "4hr 48m",
      chart: {
        type: "radialBar",
        series: [80],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [colors.primary.main],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%"
              },
              track: {
                background: trackBgColor
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: "round"
          }
        }
      }
    }
  ]

  return (
    store.data[storeName]?.length > 0 && (
      <Card className="card-employee-task">
        <CardHeader>
          <div className="employee-task d-flex justify-content-between align-items-center">
            {item?.icon
              ? renderIcon(item?.icon, "user-timeline-title-icon me-1", 18)
              : null}
            <CardTitle tag="h4">
              {t(item?.title ?? item?.cardTitle ?? "")}
            </CardTitle>
            {store.data[storeName]?.length > 1 && (
              <Badge className="ms-1" tag="div" color="danger" pill>
                {store.data[storeName]?.length}
              </Badge>
            )}
          </div>
        </CardHeader>

        <CardBody>
          {employeesTasks.map((task) => {
            return (
              <div
                key={task.title}
                className="employee-task d-flex justify-content-between align-items-center"
              >
                <div className="d-flex">
                  <Avatar
                    imgClassName="rounded"
                    className="me-75"
                    img={task.avatar}
                    imgHeight="42"
                    imgWidth="42"
                  />
                  <div className="my-auto">
                    <h6 className="mb-0">{task.title}</h6>
                    <small>{task.subtitle}</small>
                  </div>
                </div>
              </div>
            )
          })}
        </CardBody>
      </Card>
    )
  )
}

export default ListGroupBy
