import { useEffect, Fragment, useState, forwardRef, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
// ** Third Party Components
import Spinner from "@src/@core/components/spinner/Fallback-spinner"
import { useTranslation } from "react-i18next"
// ** Reactstrap Imports
import { Card, Input } from "reactstrap"
import {
  setStorePageAttributes,
  getDataList
} from "@src/shared/dynamic_pages/store"
import Table from "@src/shared/dynamic_pages/Table"
// ** Bootstrap Checkbox Component
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const InputsSelectTable = ({
  item,
  data,
  onChange,
  endPointName,
  resetSelectTable
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [pageattr, setpageattr] = useState(null)
  const store = useSelector((state) => state.dynamicStore)
  const [storename, setstorename] = useState(`${item?.fieldName}_selectable`)
  const [loadingFirst, setLoadingFirst] = useState(false)

  // const dynamic_temp_state = useSelector((state) => state.dynamic_temp_state)
  // console.log(dynamic_temp_state.parent_data_relation)

  // console.log(tableOpt?.crudOpt?.maxPageLength)
  const getpageattr = async (sourceType) => {
    const tableOpt = item?.dataFrom?.tableOpt
    const initialpageattr = {
      pageId: storename,
      pageTitle: item?.fieldLabel ?? tableOpt?.pageTitle,
      isViewOnForm: true,
      isSeletTable: true,
      crudOpt: {
        ...(tableOpt?.crudOpt ?? {}),
        endpointName:
          endPointName ??
          item?.dataFrom?.api ??
          tableOpt?.crudOpt?.endpointName,
        baseUrl: item?.dataFrom?.baseUrl ?? tableOpt?.crudOpt?.baseUrl,
        multiselectToCurrentForm:
          item?.dataFrom?.multiselectToCurrentForm ?? true,
        multiselectToCurrentFormType:
          item?.dataFrom?.multiselectToCurrentFormType,
        // initialFiltersCondition: tableOpt?.crudOpt?.initialFiltersCondition,
        // dynamicColumnsGroupByKey: tableOpt?.crudOpt?.dynamicColumnsGroupByKey,
        // dynamicColumnsGroupByLabel:
        //   tableOpt?.crudOpt?.dynamicColumnsGroupByLabel,
        serverSideGrid:
          item?.dataFrom?.isAutoComplete ?? tableOpt?.crudOpt?.serverSideGrid,
        detailFromGrid: true,
        getApiSummaryData: false,
        getAlwaysGrid:
          tableOpt?.crudOpt?.getAlwaysGrid ??
          item?.dataFrom?.api_getAlways ??
          true,
        deleteSelection: false,
        addSelection: true,
        maxPageLength: tableOpt?.crudOpt?.maxPageLength ?? 10,
        hideBtnSwitchLayout: tableOpt?.crudOpt?.hideBtnSwitchLayout ?? true,
        hideTableSearchInput: tableOpt?.crudOpt?.hideTableSearchInput ?? false,
        hideTablePageLengthInfo:
          tableOpt?.crudOpt?.hideTablePageLengthInfo ?? true,
        hideTablePagination: tableOpt?.crudOpt?.hideTablePagination ?? false,
        disabledEdit: true,
        disabledDelete: true,
        disabledAdd: true,
        data: {
          ...(tableOpt?.crudOpt?.data ?? {}),
          dynamicColumns: tableOpt?.crudOpt?.data?.dynamicColumns ?? []
        }
      }
    }
    setpageattr(initialpageattr)
    dispatch(
      setStorePageAttributes({
        storeName: storename,
        data: initialpageattr
      })
    )
    setLoadingFirst(false)
  }

  const handleChangeFilterFromChild = (selectedData, reportMode = false) => {
    // console.log(selectedData)
    if (selectedData?.selectedRows) {
      onChange(selectedData?.selectedRows)
    } else {
      const pageAttr = pageAttr
      const storeName = storename
      let mixParams = { ...selectedData }

      let isLoadStateArr = []
      let isLoadState = true

      /////prevent load data if filter include required attr and undefined
      let isRequiredInputChanged = false
      let isRequiredInputs = []
      if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
        pageAttr?.crudOpt?.data?.dynamicColumnsFilter
          .filter((x) => x?.rules?.required === true || x?.required === true)
          .map((y) => {
            // eslint-disable-next-line no-unneeded-ternary
            isLoadState = mixParams?.[y.fieldName] ? true : false
            isLoadStateArr.push(mixParams?.[y.fieldName] && true)
            isRequiredInputs.push(y.fieldName)
            isRequiredInputChanged =
              mixParams?.[y.fieldName] !==
              store.pageData[storeName]?.params?.[y.fieldName]
          })
      }

      if (isLoadStateArr?.every((value) => value === true)) {
        let checkMixParams = JSON.parse(JSON.stringify(mixParams))
        // IF FILTER EXIST ON EXPANDABLE
        const curPageAttrPageFilArray =
          pageAttr?.crudOpt?.initialFiltersCondition
        if (
          (pageAttr?.isViewOnRow || pageAttr?.isViewOnForm) &&
          curPageAttrPageFilArray
        ) {
          mixParams = { ...getinitialFiltersCondition(), ...mixParams }
        }
        if (
          (JSON.stringify(checkMixParams) !==
            JSON.stringify(store.pageData[storeName]?.params) ||
            (pageAttr?.crudOpt?.getAlwaysGrid &&
              !store.pageData[storeName]?.isLoading)) &&
          (!pageAttr?.crudOpt?.dynamicColumnsFilterToCurrentData ||
            isRequiredInputChanged)
        ) {
          if (
            !pageAttr?.crudOpt?.getAlwaysGrid &&
            pageAttr?.crudOpt?.gridTheme === "treeview"
          ) {
          } else {
            dispatch(getDataList(mixParams))
          }
        }
      } else {
        console.log("resetData")
      }
    }
  }

  const loadingFirstOnce = useRef(false)
  useEffect(() => {
    if (loadingFirstOnce.current === false) {
      // if (!pageattr || !store.pageAttributes[storename]) {
      //   setLoadingFirst(true)
      //   if (!store.pageAttributes[storename]) {
      //     getpageattr()
      //   } else {
      //     setpageattr(store.pageAttributes[storename])
      //     setLoadingFirst(false)
      //   }
      // }

      setLoadingFirst(true)
      getpageattr()
      setLoadingFirst(false)
      loadingFirstOnce.current = true
    }
  }, [pageattr, store.pageAttributes[storename], store.pageData[storename]])

  return !resetSelectTable && pageattr && store.pageAttributes[storename] ? (
    <Fragment>
      <Card
        className="mb-0"
        style={{
          boxShadow: "none"
        }}
      >
        <div className="selectable-input">
          <Table
            handleFiltersChangeToParent={handleChangeFilterFromChild}
            pageAttr={pageattr}
            // filtersCustomAdd={store.pageData[storename]?.params ?? {}}
            storeName={storename}
          />
        </div>
      </Card>
    </Fragment>
  ) : (
    <Spinner />
  )
}

export default InputsSelectTable
