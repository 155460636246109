/* eslint-disable brace-style */
/* eslint-disable no-duplicate-imports */
import Breadcrumbs from "@components/breadcrumbs"
import UILoader from "@components/ui-loader"
import StatsHorizontal from "@components/widgets/stats/StatsHorizontal"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined"
import { Skeleton } from "@mui/material"
import Icon from "@mui/material/Icon"
import { Loader } from "@src/shared/CommonComponents"
import { AbilityContext } from "@src/utility/context/Can"
import { setDataCache } from "@store/dynamicTempState"
import {
  getRequiredFilterState,
  getUserData,
  isAdminApp,
  restrictedMenuLink,
  urlReplacer,
  isFWApp
} from "@utils"
import useJwt from "@src/auth/jwt/useJwt"
import {
  Fragment,
  createRef,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import toast from "react-hot-toast"
import {
  Camera,
  Inbox,
  Info,
  Printer,
  RefreshCcw,
  Search,
  X
} from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import CardAction from "@components/card-actions"
import DynamicCard from "../../utility/generateForm/dynamicCard"
import DynamicForm from "../../utility/generateForm/dynamicForm"
import {
  renderIcon,
  updateMenuToNeedFetch
} from "../../views/apps/menu/store/my_menu"
import BlankPage from "./Blank"
import GridCard from "./GridCard"
import List from "./List"
import ListSortable from "./ListSortable"
import OrgChart from "./OrgChart"
import Table from "./Table"
import TimeLine from "./TimeLine"
import TreeView from "./TreeView"
import OrgChart2 from "./OrgChart2"
import OrgChart3 from "./OrgChart3"
import CalendarPage from "./calendar"
import GanttChartCrud from "./gantt"
import MapPage from "./map"
import {
  getDataList,
  getSummaryData,
  resetData,
  resetDataListFiltered,
  setBtnAddCondition,
  setDataListFiltered,
  setStorePageAttributes,
  setDataFrame,
  setDataFrameHtml,
  setDataFrameHtmlModal,
  setModalImport,
  setOrgChartData
} from "./store"
import { getInvoice, getInvoicePlain } from "./store/invoices"
import "./style.scss"
// ** Spinner (Splash Screen)
import Spinner from "../../@core/components/spinner/Fallback-spinner"
import { getBaseUrl } from "@src/shared/dynamic_pages/store"
import Editor from "@monaco-editor/react"
// ** Third Party Components
import Prism from "prismjs"
import {
  setEditModeJson,
  setEditModeJsonAttr,
  setOriginalJsonAttr,
  setChangedJsonAttr,
  setLastState
} from "@store/developer"

import { Fullscreen, FullscreenExit, Refresh } from "@mui/icons-material"
import ModalAddEditDev from "./dev_mode/modal"
import ModalImport from "./modal/import_dropzone"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { object } from "prop-types"

const DynamicPage = ({
  lookupMode = false,
  lookupMode_fieldName = "",
  lookupMode_dropdown = false,
  lookupMode_defaultValue,
  lookupMode_onChange,
  lookupMode_isMulti = false,
  errorState = false,
  initialPageId = null,
  initialRelPageId = null,
  initialPageAttr = null,
  initialData = null,
  handleSelectedDropdownTableChange,
  selectedDataTable
}) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()
  const [pageAttr, setPageAttr] = useState(initialPageAttr)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)

  const storeUnit = useSelector((state) => state.units)
  const navigate = useNavigate()
  const [storeNameParent, setStoreNameParent] = useState(
    moduleId ? `${moduleId}_${pageId}` : `${pageId}`
  )
  const storeGlobalFilter = useSelector((state) => state.global_filter)
  const ability = useContext(AbilityContext)
  const [storeName, setStoreName] = useState(
    moduleId &&
      !pageAttr?.onDashboard &&
      !pageAttr?.isDropdownTable &&
      !pageAttr?.isInputTable
      ? `${moduleId}_${initialPageId ?? pageId}`
      : `${initialPageId ?? pageId}`
  )
  const [currentPageType, setCurrentPageType] = useState(null)
  const [currentGridTheme, setCurrentGridTheme] = useState(null)

  const [dataParamsChild, setDataParamsChild] = useState({})

  const [dynamicFiltersComponents, setDynamicFiltersComponents] = useState(null)
  /////THIS PAGE
  // console.log(initialPageAttr, store.pageAttributes[storeName])
  // const [tooltipBtnSwitchOpen, setTooltipBtnSwitchOpen] = useState(false)
  const [tooltipBtnSwitchDefaultOpen, setTooltipBtnSwitchDefaultOpen] =
    useState(false)
  const [tooltipBtnSwitchTreeviewOpen, setTooltipBtnSwitchTreeviewOpen] =
    useState(false)
  const [tooltipBtnSwitchOrgChartOpen, setTooltipBtnSwitchOrgChartOpen] =
    useState(false)
  const [tooltipBtnSwitchTreeTableOpen, setTooltipBtnSwitchTreeTableOpen] =
    useState(false)

  const [loadingFirst, setLoadingFirst] = useState(false)
  const [loadingSummary, setLoadingSummary] = useState(false)
  const [loadingFilldataFromGrid, setLoadingFilldataFromGrid] = useState([])
  const colors = [
    "primary",
    "success",
    "warning",
    "danger",
    "info",
    "secondary"
  ]

  const storeDeveloper = useSelector((state) => state.developer)
  const devModeEnabled =
    storeDeveloper.editMode &&
    ((!window.location.pathname.includes("/dyn_pages/_admin_") &&
      !window.location.pathname.includes("/apps/menu")) ||
      isAdminApp()) &&
    !pageAttr?.onDashboard
  const [editorValid, setEditorValid] = useState(true)
  const [foldAll, setFoldAll] = useState(false)

  const resetDevState = () => {
    dispatch(setOriginalJsonAttr({ storeName, pageAttr: null }))
    // setTempJsonAttr(null)
    dispatch(setChangedJsonAttr({ storeName, pageAttr: null }))
    setEditorValid(true)
  }

  const initNewModeJson = (pageAttrInit) => {
    const pageAttrInitFirst = pageAttrInit ?? pageAttr
    const pageAttrInitEdited = urlReplacer(pageAttrInitFirst, true)
    // console.log(pageAttrInitFirst)
    let newPageAttr = JSON.stringify(pageAttrInitEdited, null, 2)
    if (
      !storeDeveloper?.pageAttributesBackup ||
      !storeDeveloper?.pageAttributesBackup?.[storeName]
    ) {
      // console.log("setOriginalJsonAttr")
      // setOriginalJsonAttr(newPageAttr)
      dispatch(setOriginalJsonAttr({ storeName, pageAttr: newPageAttr }))
    }
    dispatch(
      setEditModeJson({
        storeName,
        pageAttr: newPageAttr
      })
    ).then((res) => {
      // console.log(res)
    })
  }

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const storeMenu = useSelector((state) => state.my_menu)
  const [resetGridAndFilters, setResetGridAndFilters] = useState(false)
  const userData = getUserData()

  function handleResetImportModal() {
    return dispatch(
      setModalImport({
        storeName
      })
    ).then((res) => {})
  }

  const JsonDiagramDev = ({ data, name }) => {
    // console.log(typeof dataFirst, name)
    const [collapsed, setCollapsed] = useState(true)
    // let data = typeof dataFirst === "string" ? JSON.parse(dataFirst) : dataFirst

    if (
      typeof data !== "object" ||
      data === null ||
      Object.keys(data).length === 0
    ) {
      return null
    }

    const handleToggle = () => setCollapsed(!collapsed)
    const hasChildren = Array.isArray(data)
      ? data.length > 0
      : Object.keys(data).length > 0

    return (
      <li>
        {name && (
          <span
            onClick={handleToggle}
            className={`node-json-diagram-dev-name ${
              hasChildren ? "has-children" : ""
            }`}
          >
            {name} {hasChildren ? (collapsed ? "[+]" : "[-]") : ""}
          </span>
        )}
        {!collapsed && (
          <ul className="children-json-diagram-dev">
            {Array.isArray(data)
              ? data.map((item, index) => (
                  <JsonDiagramDev
                    key={index}
                    name={item.fieldName || index.toString()}
                    data={item}
                  />
                ))
              : Object.keys(data).map((key) => (
                  <JsonDiagramDev key={key} name={key} data={data[key]} />
                ))}
          </ul>
        )}
      </li>
    )
  }

  function printDiv(elementName) {
    console.log("Function called")
    const printWindow = window.open("", "_blank")
    const contentToPrint = document.getElementById(elementName).outerHTML
    printWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>
      <body>
        ${contentToPrint}
        <script>
        window.onload = function() {
          window.print();
          window.onafterprint = function() {
            window.close();
          };
        };
      </script>
        </body>
        </html>
    `)
    // Ensure the document is closed to trigger the load event
    printWindow.document.close()
  }

  const isHaveGlobalFilter = () =>
    pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.map((item) => {
      if (item?.type === "global_filter_year") {
        return {
          [item.fieldName ?? "data_year"]: storeGlobalFilter.data_year
        }
      } else {
        return false
      }
    }) ?? false

  const resetAllData = () => {
    const isRequiredFilter =
      pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.some((x) => {
        if (x?.fieldName === "id_unit" && storeUnit?.data?.length === 1) {
          return (
            (x?.rules?.required === true || x?.required === true) &&
            storeUnit?.data[0]?.children_count > 0
          )
        }
        return x?.rules?.required === true || x?.required === true
      })
    // bisa reload jika tidak ada filter yang required

    // check apakah mempunyai globalFilter??

    console.log("resetAllData")
    initGrid(pageAttr)

    if (
      currentGridTheme &&
      (currentGridTheme === "timeline" || currentGridTheme === "orgchart")
    ) {
      dispatch(
        getDataList({
          storeName,
          endpointName: pageAttr?.crudOpt?.endpointName
        })
      ).then((data) => {
        if (currentGridTheme === "orgchart") {
          setTimeout(() => {
            const chartElem = document.querySelector(".myChart")
            if (chartElem) {
              chartElem.style.cursor = "move"
              chartElem.style.left = "-329px"
              chartElem.style.top = "-9px"
              chartElem.style.transform =
                "matrix(0.520403, 0, 0, 0.520403, -573, -191)"
            }
          }, 100)
        }
      })
      dispatch(
        resetDataListFiltered({
          storeName: storeName
        })
      )
      setResetGridAndFilters(!resetGridAndFilters)
    } else {
      dispatch(resetData(storeName)).then((res) => {
        // console.log(currentGridTheme)
        if (currentGridTheme !== "orgchart2") {
          // let isRequiredInputChanged = false
          // let isRequiredInputs = []
          // if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
          //   pageAttr?.crudOpt?.data?.dynamicColumnsFilter
          //     .filter(
          //       (x) => x?.rules?.required === true || x?.required === true
          //     )
          //     .map((y) => {
          //       // eslint-disable-next-line no-unneeded-ternary
          //       isLoadState = mixParams?.[y.fieldName] ? true : false
          //       isLoadStateArr.push(mixParams?.[y.fieldName] && true)
          //       isRequiredInputs.push(y.fieldName)
          //       isRequiredInputChanged =
          //         mixParams?.[y.fieldName] !==
          //         store.pageData[storeName]?.params?.[y.fieldName]
          //       // console.log(isRequiredInputChanged)
          //     })
          // }
          // console.log(isRequiredInputChanged)
          if (!isDynamicColumnsFilter() || !isRequiredFilter) {
            const isHaveGlobalFilters = isHaveGlobalFilter()
            if (!store.pageData[storeName]?.isLoading) {
              dispatch(
                getDataList({
                  storeName,
                  endpointName: pageAttr?.crudOpt?.endpointName,
                  ...(isHaveGlobalFilters ? isHaveGlobalFilters?.[0] ?? {} : {})
                })
              )
            }
          } else {
            console.log("FILTER IS REQUIRED...")
          }
        }

        // dispatch(
        //   resetDataListFiltered({
        //     storeName: storeName
        //   })
        // )
        setResetGridAndFilters(!resetGridAndFilters)
      })
    }
  }

  function isDynamicColumnsFilter(pageAttrLoaded = null) {
    let pageAttrFirst = pageAttrLoaded ?? pageAttr
    return (
      pageAttrFirst?.crudOpt?.data?.dynamicColumnsFilter?.length > 0 &&
      !pageAttrFirst?.crudOpt?.data?.dynamicColumnsFilter?.every(
        (item) => item.isHidden === true
      ) &&
      (pageAttrFirst?.crudOpt?.gridTheme !== "calendar" ||
        currentGridTheme === "table" ||
        currentGridTheme === "gantt_chart" ||
        pageAttrFirst?.onDashboard)
    )
  }

  const filtersComponents = (
    tableHeaderMode = false,
    pageAttrLoaded = null
  ) => {
    // console.log(store.pageData[storeName]?.params)
    let pageAttrFirst = pageAttrLoaded ?? pageAttr
    return (
      <DynamicForm
        key={resetGridAndFilters}
        dynamicForm={pageAttrFirst?.crudOpt?.data?.dynamicColumnsFilter.map(
          (obj) => ({
            ...obj,
            isFromFilter: true
          })
        )}
        storeName={storeName}
        pageAttr={pageAttrFirst}
        handleFiltersChangeToParent={handleChangeFilterFromChild}
        filterMode={true}
        tableHeaderMode={tableHeaderMode}
        defaultFormValues={{
          ...store.pageData[storeName]?.params,
          ...defValuesFix,
          ...getinitialFiltersCondition(),
          ...(initialData ?? {}),
          ...{}
        }}
      />
    )
  }

  const getPageAttr = async (sourceType) => {
    // console.log(window.location.pathname)
    let dynamicPartUrl = window.location.pathname.replace(
      /\/(edit|view)(\/.*)?$/,
      ""
    )
    // console.log(dynamicPartUrl)
    let currentMenuData = await storeMenu?.dataMyMenu?.find(
      (item) => item?.routePath === dynamicPartUrl
    )
    const needFetch = currentMenuData?.fetch
    if (needFetch === 1) {
      const axiosSso = useJwt.ssoAxiosInterceptor
      await axiosSso
        .get(`/api/my_info/menu/${currentMenuData.id}`)
        .then((res) => {
          // console.log(res)
          const data = res?.data?.data

          const newData = urlReplacer(data)
          return newData
        })
        .then((data) => {
          // console.log(data)
          const storeNameFix = moduleId
            ? `${moduleId}_${initialPageId ?? pageId}`
            : `${initialPageId ?? pageId}`
          setStoreName(storeNameFix)

          dispatch(setStorePageAttributes({ storeName: storeNameFix, data }))
          setPageAttr(data)
          return data
        })
        .then((ress) => {
          // console.log(ress)
          if (!pageAttr?.isFromAddPage) {
            initGrid(ress)
          }
        })
        .finally((_) => {
          setLoadingFirst(false)
        })
        .catch((error) => {
          console.error(error)
          setLoadingFirst(false)
        })
    } else {
      // console.log(`get getPageAttr from ${sourceType}`)

      // if (sourceType === "file") {
      //   if (moduleId) {
      //     await import(
      //       `@src/pageAttributes/${moduleId}/${initialPageId ?? pageId}.json`
      //     )
      //       .then((res) => {
      //         const data = res.default
      //         setPageAttr(data)
      //         // console.log(data)
      //         const storeNameFix = moduleId
      //           ? `${moduleId}_${initialPageId ?? pageId}`
      //           : `${initialPageId ?? pageId}`
      //         setStoreName(storeNameFix)
      //         dispatch(setStorePageAttributes({ storeName: storeNameFix, data }))
      //         return data
      //       })
      //       .then((ress) => {
      //         initGrid(ress)
      //       })
      //       .finally((_) => {
      //         setLoadingFirst(false)
      //       })
      //       .catch((error) => {
      //         console.error(error)
      //         setLoadingFirst(false)
      //       })
      //   } else {
      //     await import(`@src/pageAttributes/${initialPageId ?? pageId}.json`)
      //       .then((res) => {
      //         const data = res.default
      //         setPageAttr(data)
      //         const storeNameFix = moduleId
      //           ? `${moduleId}_${initialPageId ?? pageId}`
      //           : `${initialPageId ?? pageId}`
      //         setStoreName(storeNameFix)
      //         dispatch(setStorePageAttributes({ storeNameFix, data }))
      //         return data
      //       })
      //       .then((ress) => {
      //         initGrid(ress)
      //       })
      //       .finally((_) => {
      //         setLoadingFirst(false)
      //       })
      //       .catch((error) => {
      //         console.error(error)
      //         setLoadingFirst(false)
      //       })
      //   }
      // } else {
      const timestamp = new Date().getTime()
      let url = `${window.location.origin}/pageAttributes/${
        initialPageId ?? pageId
      }.json?timestamp=${timestamp}`
      if (moduleId) {
        // console.log(moduleId)
        if (moduleId === "_admin_") {
          // url = `${window.location.origin}/pageAttributes/${moduleId}/${
          //   initialPageId ?? pageId
          // }.json`
          await import(`@src/views/admin_pages/${initialPageId ?? pageId}.json`)
            .then((res) => {
              const data = res.default

              const newData = urlReplacer(data)

              setPageAttr(newData)
              // console.log(newData)
              const storeNameFix = moduleId
                ? `${moduleId}_${initialPageId ?? pageId}`
                : `${initialPageId ?? pageId}`
              setStoreName(storeNameFix)
              dispatch(
                setStorePageAttributes({
                  storeName: storeNameFix,
                  data: newData
                })
              )
              return newData
            })
            .then((ress) => {
              initGrid(ress)
            })
            .finally((_) => {
              setLoadingFirst(false)
            })
            .catch((error) => {
              console.error(error)
              setLoadingFirst(false)
            })
        } else {
          const timestamp = new Date().getTime()
          url = `${window.location.origin}/pageAttributes/${moduleId}/${
            initialPageId ?? pageId
          }.json?timestamp=${timestamp}`

          await fetch(url)
            .then(function (res) {
              try {
                return res.json()
              } catch (error) {
                console.error(error)
                throw new Error("Bad response from server")
              }
            })
            .then((data) => {
              const newData = urlReplacer(data)

              const storeNameFix = moduleId
                ? `${moduleId}_${initialPageId ?? pageId}`
                : `${initialPageId ?? pageId}`
              setStoreName(storeNameFix)
              dispatch(
                setStorePageAttributes({
                  storeName: storeNameFix,
                  data: newData
                })
              )
              setPageAttr(newData)
              return newData
            })
            .then((ress) => {
              if (!pageAttr?.isFromAddPage) {
                initGrid(ress)
              }
            })
            .finally((_) => {
              setLoadingFirst(false)
            })
            .catch((error) => {
              console.error(error)
              setLoadingFirst(false)
            })
        }
      }

      // }
    }
  }

  const initGrid = (data) => {
    // console.log(store.pageData)
    // console.log(!store.pageData[storeNameParent]?.dataSummary)
    // console.log(store.pageData[storeNameParent])
    // console.log(data?.crudOpt?.getApiSummaryData)
    // console.log(!relPageId)
    // console.log(!store.pageData[storeNameParent]?.isLoadingSummary)
    // console.log(store.pageData[storeNameParent]?.statusSummary)

    // if (isDynamicColumnsFilter(data)) {
    //   // customHeaderSet = filtersComponents(true, data)
    //   if (pageAttr?.crudOpt?.dynamicColumnsFilterPos === "table-header") {
    //     setDynamicFiltersComponents(filtersComponents(true, data))
    //   } else {
    //     setDynamicFiltersComponents(filtersComponents(false, data))
    //   }
    // }

    const queryParams = new URLSearchParams(window.location.search)
    const dev_mode_json_qs = queryParams.get("dev_mode_json")
    if (dev_mode_json_qs === "true" && devModeEnabled) {
      console.log("active modal")
      if (!storeDeveloper.pageAttributes[storeName]) {
        initNewModeJson(data)
      }
    } else {
      if (
        !relPageId &&
        data?.crudOpt?.getApiSummaryData &&
        !store.pageData[storeNameParent]?.dataSummary &&
        // store.pageData[storeNameParent] &&
        !store.pageData[storeNameParent]?.isLoadingSummary &&
        store.pageData[storeNameParent]?.statusSummary !== "failed" &&
        data?.crudOpt?.endpointName
      ) {
        setLoadingSummary(true)
        dispatch(
          getSummaryData({
            storeName: storeNameParent,
            endpointName: data?.crudOpt?.endpointName,
            baseUrl: data?.crudOpt?.baseUrl,
            pageAttrParam: data
          })
        ).then((res) => {
          // console.log({
          //   storeName: storeNameParent,
          //   endpointName: data?.crudOpt?.endpointName
          // })
          // console.log(res)
          setLoadingSummary(false)
        })
      }
      filldataFromGrid(data)

      // const editParts = document.querySelectorAll(".editmode_hover_edit")
      // editParts.forEach((editPart) => {
      //   editPart.addEventListener("click", function handleClick(event) {
      //     // console.log("box clicked", event)
      //     const fieldNamePart = editPart.getAttribute("dev-mode-data")
      //     if (fieldNamePart) {
      //       const fieldNamePartArr = fieldNamePart.split(",")
      //       // console.log(fieldNamePartArr)
      //       setModalDevEditData(fieldNamePartArr)
      //       setShowModalDevState(!showModalDevState)
      //     }

      //     // box.setAttribute("style", "background-color: yellow;")
      //   })
      // })
    }
  }

  const dynamicTempState = useSelector((state) => state.dynamic_temp_state)
  const [tempdataFromGrid, setdataFromGrid] = useState({})
  const [tempdataFromGridLoading, setTempdataFromGridLoading] = useState({})

  const renderDropdownItem = (item, res) => {
    const keySelector = item.dataFrom?.resp_key
      ? res.data[item.dataFrom?.resp_key]
      : res.data
    if (keySelector) {
      if (item.dataFrom?.field_label || item.dataFrom?.field_value) {
        const newDataMap = keySelector.map((r) => {
          // const container = {}
          let container = { ...r }
          if (Array.isArray(item?.dataFrom?.field_label)) {
            let objSelector = ""

            item?.dataFrom?.field_label.map((lbl, i, row) => {
              if (i + 1 === row.length) {
                if (lbl.includes(".")) {
                  objSelector = objSelector + getDepthValue(r, lbl)
                } else {
                  objSelector = objSelector + r[lbl]
                }
              } else {
                if (lbl.includes(".")) {
                  objSelector =
                    objSelector +
                      getDepthValue(r, lbl) +
                      item?.dataFrom?.field_label_separator ?? " "
                } else {
                  objSelector =
                    objSelector +
                      r[lbl] +
                      item?.dataFrom?.field_label_separator ?? " "
                }
              }
            })
            container["label"] = objSelector
          } else {
            container["label"] = item.dataFrom?.field_label.includes(".")
              ? getDepthValue(r, item.dataFrom?.field_label)
              : r[item.dataFrom.field_label]
          }

          if (item.dataFrom?.field_img) {
            container["img"] = item?.dataFrom?.field_img.includes(".")
              ? getDepthValue(r, item?.dataFrom?.field_img)
              : r[item.dataFrom.field_img]
          }

          container["value"] = item.dataFrom?.field_value.includes(".")
            ? getDepthValue(r, item.dataFrom?.field_value)
            : r[item.dataFrom.field_value]
          return container
        })
        return newDataMap
      } else {
        return keySelector
      }
    } else {
      return []
    }
  }

  // LOOKUP VALUE FROM API ON GRID
  const filldataFromGrid = async (data) => {
    const dynColumn = data?.crudOpt?.data?.dynamicColumns
    if (dynColumn) {
      const dataFromExist = dynColumn.some(
        (obj) =>
          Object.keys(obj).includes("dataFrom") && obj?.type !== "img_slider"
      )
      if (dataFromExist) {
        await dynColumn?.map(async (item) => {
          if (item.dataFrom?.api) {
            if (
              (!dynamicTempState.data[item?.fieldName] &&
                !loadingFilldataFromGrid[item?.fieldName] &&
                !tempdataFromGridLoading[item?.fieldName]) ||
              item.dataFrom?.api_getAlways
            ) {
              let tempLoadingFilldataFromGrid = { ...loadingFilldataFromGrid }
              tempLoadingFilldataFromGrid[item.fieldName] = true
              setLoadingFilldataFromGrid(tempLoadingFilldataFromGrid)
              try {
                const data = await getBaseUrl(
                  item.dataFrom?.baseUrl,
                  item.dataFrom?.api
                )
                  .get(`${item.dataFrom?.api}`)
                  .then((res) => {
                    let tempLoadingFilldataFromGrid = {
                      ...loadingFilldataFromGrid
                    }
                    tempLoadingFilldataFromGrid[item.fieldName] = false
                    setLoadingFilldataFromGrid(tempLoadingFilldataFromGrid)
                    return renderDropdownItem(item, res)
                  })
                if (data) {
                  tempdataFromGrid[item.fieldName] = data
                  setdataFromGrid(tempdataFromGrid)
                  dispatch(setDataCache({ [item.fieldName]: data }))
                }
              } catch (err) {
                console.error(err)
                let tempLoadingFilldataFromGrid = {
                  ...loadingFilldataFromGrid
                }
                tempLoadingFilldataFromGrid[item.fieldName] = false
                setLoadingFilldataFromGrid(tempLoadingFilldataFromGrid)
                throw new Error("Unable to get a filldataFromGrid.")
              }
            }
          } else {
            tempdataFromGrid[item.fieldName] =
              dynamicTempState.data[item?.fieldName]
            setdataFromGrid(tempdataFromGrid)
          }
        })
      }
    }
  }

  let defValues = location.search
    ? location.search
        .slice(1)
        .split("&")
        .map((p) => p.split("="))
        .reduce(
          (obj, [key, value]) =>
            !key.includes("refresh") &&
            !key.includes("isOrigin") && {
              ...obj,
              [key]:
                ////CONVERT VALUE FROM QUERY STRING TO INTEGER IF POSSIBLE
                !isNaN(value) && (key.includes("id") || key.includes("Id"))
                  ? +value
                  : value
            },
          {}
        )
    : {}

  let defValuesFix = Object.fromEntries(
    Object.entries(defValues).filter(([_, v]) => _ !== "")
  )

  const getinitialFiltersCondition = () => {
    const initialFiltersCondition =
      store.pageAttributes[storeName]?.crudOpt?.initialFiltersCondition
    let filters = {}

    if (initialFiltersCondition) {
      for (let x in initialFiltersCondition) {
        if (!initialFiltersCondition[x]?.disablePageQueryParam) {
          const value_from_parentFieldName =
            initialFiltersCondition[x]?.value_from_parentFieldName
          // console.log(
          //   dynamicTempState.parent_data_relation[value_from_parentFieldName]
          // )
          if (!initialData) {
            const value =
              value_from_parentFieldName === "id"
                ? parseInt(id)
                : dynamicTempState.parent_data_relation[
                    value_from_parentFieldName
                  ] ??
                  store.pageData[storeNameParent]?.selectedData[
                    value_from_parentFieldName
                  ] ??
                  initialFiltersCondition[x]?.value
            filters[initialFiltersCondition[x]?.fieldNameCurrent] = value
          } else {
            ////JIKA INI DARI EXPANDED TABLE
            const value =
              initialData?.[value_from_parentFieldName] ??
              initialFiltersCondition[x]?.value
            filters[initialFiltersCondition[x]?.fieldNameCurrent] = value
          }
        }
      }
      // filters = { ...(initialData ?? {}), ...(filters ?? {}) }
      // console.log(filters)
    }
    filters = { ...(defValuesFix ?? {}), ...(filters ?? {}) }
    // console.log(filters)
    return filters
  }

  function filterNested(array, key, value) {
    return array.reduce((r, o) => {
      let array = filterNested(o?.children || [], key, value)
      if (!Array.isArray(o[key])) {
        if (o[key] === value || array.length) {
          r.push(Object.assign({}, o, { result: array }))
        }
      } else {
        o[key].map((item) => {
          if (item?.id === value) {
            r.push(Object.assign({}, o, { result: array }))
          }
        })
      }
      return r
    }, [])
  }

  function resolvePath(obj, path) {
    return path.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    )
  }

  function filterByPath(data, keyToFilter, valueToFilter) {
    // console.log(data, keyToFilter, valueToFilter)
    const path = keyToFilter.split("___")
    const lastKey = path.pop()

    return data.filter((item) => {
      const arrayToSearch = resolvePath(item, path)

      if (!Array.isArray(arrayToSearch)) {
        return false
      }

      return arrayToSearch.some(
        (element) => resolvePath(element, [lastKey]) === valueToFilter
      )
    })
  }

  function recursiveRemoveByKeyValue(arrOri, key, value) {
    // Buat array baru untuk menyimpan item yang tidak dihapus
    let arr = JSON.parse(JSON.stringify(arrOri))
    let result = []

    if (arr) {
      for (let i = 0; i < arr?.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          arr[i].children = recursiveRemoveByKeyValue(
            arr[i].children,
            key,
            value
          )
          // if (arr[i].children.length === 0) {
          //   delete arr[i].children
          // }
        }
        // console.log(key, arr[i][key], value, arr[i])
        if (
          (arr[i][key] !== value && arr[i]?.children?.length > 0) ||
          arr[i][key] === value
        ) {
          result.push(arr[i])
        }
      }
    }

    return result
  }

  const handleChangeFilterFromChild = (params, reportMode = false) => {
    if (!reportMode && !store.pageData[storeName]?.isLoading) {
      console.log("handleChangeFilterFromChild")
      let mixParams = { ...dataParamsChild, ...params }
      // if (pageAttr?.crudOpt?.serverSideGrid) {
      let isLoadStateArr = []
      let isLoadState = true

      /////prevent load data if filter include required attr and undefined
      let isRequiredInputChanged = false
      let isRequiredInputs = []
      if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
        pageAttr?.crudOpt?.data?.dynamicColumnsFilter
          .filter((x) => x?.rules?.required === true || x?.required === true)
          .map((y) => {
            // eslint-disable-next-line no-unneeded-ternary
            isLoadState = mixParams?.[y.fieldName] ? true : false
            isLoadStateArr.push(mixParams?.[y.fieldName] && true)
            isRequiredInputs.push(y.fieldName)
            isRequiredInputChanged =
              mixParams?.[y.fieldName] !==
              store.pageData[storeName]?.params?.[y.fieldName]
            // console.log(isRequiredInputChanged)
          })
      }

      // console.log(
      //   isLoadStateArr,
      //   isLoadStateArr?.every((value) => value === true)
      // )
      // if (pageAttr?.crudOpt?.isSeletTable) {
      // console.log(pageAttr?.crudOpt?.endpointName)
      // console.log(dynamicTempState.parent_data_relation)
      // }
      // console.log("handleChangeFilterFromChild")
      // console.log(`isLoadState = ${isLoadState}`)
      // console.log(isLoadStateArr)
      // console.log(
      //   store.pageAttributes?.[storeName]?.crudOpt?.endpointName,
      //   params.endpointName
      // )
      if (
        isLoadStateArr?.every((value) => value === true) ||
        store.pageAttributes?.[storeName]?.crudOpt?.endpointName !==
          params.endpointName
      ) {
        let checkMixParams = JSON.parse(JSON.stringify(mixParams))
        // Object.keys(mixParams).forEach((key) => {
        //   if (checkMixParams[key] === null || checkMixParams[key] === "") {
        //     delete checkMixParams[key]
        //   }
        // })

        // IF FILTER EXIST ON EXPANDABLE
        const curPageAttrPageFilArray =
          pageAttr?.crudOpt?.initialFiltersCondition
        if (
          (pageAttr?.isViewOnRow || pageAttr?.isViewOnForm) &&
          curPageAttrPageFilArray
        ) {
          mixParams = { ...getinitialFiltersCondition(), ...mixParams }
        }

        if (pageAttr?.crudOpt?.serverSideGrid) {
          if (!params?.page) {
            mixParams["page"] = 1
          }
        }

        // console.log(checkMixParams)
        // console.log(store.pageData[storeName]?.params)

        // console.log(
        //   JSON.stringify(checkMixParams) !==
        //     JSON.stringify(store.pageData[storeName]?.params) ||
        //     pageAttr?.crudOpt?.getAlwaysGrid
        // )
        if (
          (JSON.stringify(checkMixParams) !==
            JSON.stringify(store.pageData[storeName]?.params) ||
            (pageAttr?.crudOpt?.getAlwaysGrid &&
              !store.pageData[storeName]?.isLoading)) &&
          (!pageAttr?.crudOpt?.dynamicColumnsFilterToCurrentData ||
            isRequiredInputChanged)
        ) {
          // console.log("handleChangeFilterFromChild")
          if (
            !pageAttr?.crudOpt?.getAlwaysGrid &&
            pageAttr?.crudOpt?.gridTheme === "treeview"
          ) {
            dispatch(getDataList(mixParams))
          } else {
            // console.log("hereee....")
            dispatch(getDataList(mixParams))
          }
        }
      } else {
        console.log("resetData")
        // if (store.pageData[storeName]?.data) {
        //   dispatch(resetData(storeName))
        // }
      }

      // } else {
      if (currentGridTheme === "orgchart2" || currentGridTheme === "treeview") {
        const copyParams = { ...params }
        delete copyParams.endpointName
        delete copyParams.storeName
        const paramsKeys = Object.keys(copyParams)
        let filteredDatas = null
        if (paramsKeys.length > 0) {
          paramsKeys.map((key) => {
            if (copyParams[key]) {
              // console.log(key, copyParams[key])
              filteredDatas = recursiveRemoveByKeyValue(
                filteredDatas ?? store.pageData[storeName]?.data,
                key,
                copyParams[key]
              )
              // filteredDatas = filterNested(
              //   filteredDatas ?? store.pageData[storeName]?.data,
              //   key,
              //   copyParams[key]
              // )
            }
          })
          // console.log(store.pageData[storeName]?.data, filteredDatas)
        }
        // console.log(store.pageData[storeName]?.data)
        if (filteredDatas?.length > 0) {
          let newList = filteredDatas ?? []
          dispatch(
            setDataListFiltered({
              data: newList,
              storeName: storeName
            })
          ).then((res) => {
            // console.log(res)
            // console.log(pageAttr)
            // console.log(newList)
            // if (
            //   pageAttr?.crudOpt?.gridTheme === "orgchart2" ||
            //   pageAttr?.crudOpt?.gridTheme === "treeview"
            // ) {
            dispatch(setOrgChartData({ data: newList, storeName: storeName }))
            // }
          })
        } else {
          console.log("reset")
          dispatch(
            resetDataListFiltered({
              storeName: storeName
            })
          ).then((res) => {
            // console.log(res)
            dispatch(
              setOrgChartData({
                data: store.pageData[storeName]?.data,
                storeName: storeName
              })
            )
          })
        }
      }
      // }
      if (pageAttr?.crudOpt?.dynamicColumnsFilterToCurrentData) {
        let copyParams = { ...params }
        delete copyParams.endpointName
        delete copyParams.storeName
        // eslint-disable-next-line no-unused-expressions
        isRequiredInputs?.map((field) => {
          delete copyParams?.[field]
        })
        let parOnlyFilter = pageAttr?.crudOpt?.data?.dynamicColumnsFilter?.map(
          (par) => {
            return par.fieldName
          }
        )
        let filteredDatas = null
        for (let key in copyParams) {
          if (copyParams[key] === null || copyParams[key] === undefined) {
            delete copyParams[key]
          }

          if (!parOnlyFilter?.includes(key)) {
            delete copyParams[key]
          }
        }
        const paramsKeys = Object.keys(copyParams)
        // console.log(storeName, store)
        // console.log(store.pageData[storeName ?? params?.storeName]?.data)
        if (paramsKeys.length > 0) {
          let dateRangeFilters = {}
          let storeData = JSON.parse(
            JSON.stringify(
              store.pageData[storeName ?? params?.storeName]?.data ?? []
            )
          )
          paramsKeys?.map((key) => {
            // console.log(copyParams[key])
            if (copyParams[key] || copyParams[key] === 0) {
              if (key.includes("___")) {
                // console.log(key)
                filteredDatas = filterByPath(
                  filteredDatas ?? storeData,
                  key,
                  copyParams[key]
                )
                // console.log(filteredDatas)
                // let keyObj = key.split(".")
                // for (let keyDeep in keyObj) {
                //   if (copyParams[keyDeep]) {
                //     filteredDatas = (filteredDatas ?? storeData).filter(
                //       (item) => item?.[keyDeep] === copyParams[key]
                //     )
                //   }
                // }
              } else {
                if (key.includes("__startDate__")) {
                  let __date__ = new Date(copyParams[key])
                  let keyOri = key.replace("__startDate__", "")
                  dateRangeFilters["__startDate__"] = __date__
                  dateRangeFilters["__startDate__key"] = keyOri
                } else if (key.includes("__endDate__")) {
                  let __date__ = new Date(copyParams[key])
                  let keyOri = key.replace("__endDate__", "")
                  dateRangeFilters["__endDate__"] = __date__
                  dateRangeFilters["__endDate__key"] = keyOri
                } else {
                  // console.log(filteredDatas, storeData, key)
                  filteredDatas = (filteredDatas ?? storeData)?.filter(
                    (item) => item?.[key] === copyParams[key]
                  )
                }
              }
            }
          })

          // console.log(dateRangeFilters, filteredDatas)
          if (dateRangeFilters && Object.keys(dateRangeFilters).length) {
            filteredDatas = (filteredDatas ?? storeData).filter(
              (item) =>
                new Date(item?.[dateRangeFilters?.__startDate__key]) >=
                  dateRangeFilters?.__startDate__ &&
                new Date(item?.[dateRangeFilters?.__endDate__key]) <=
                  dateRangeFilters?.__endDate__
            )
          }

          // console.log(filteredDatas)
          let newList = filteredDatas?.length > 0 ? filteredDatas : []
          dispatch(
            setDataListFiltered({
              data: newList,
              storeName: storeName,
              params: mixParams
            })
          ).then((res) => {
            // console.log(res)
          })
        } else {
          // console.log("here")
          dispatch(
            resetDataListFiltered({
              params: mixParams,
              storeName: storeName
            })
          )
        }
      }

      // if (pageAttr?.crudOpt?.serverSideGrid) {
      //   // mixParams.page = 1
      //   // mixParams.sort = "id"
      //   // mixParams.sort_dir = "desc"
      //   // mixParams.search = ""
      //   // mixParams.length = pageAttr?.crudOpt?.maxPageLength ?? 10
      // }

      setDataParamsChild(mixParams)
    } else {
      // console.log(params)
    }
  }

  const handleBeforeSelectedDropdownTableChange = (params) => {
    // console.log(params)
    return handleSelectedDropdownTableChange(params)
  }

  function objectToQueryString(obj) {
    return Object.entries(obj)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&")
  }

  const scrollToDiv = (divId) => {
    const element = document.getElementById(divId)
    // console.log(element)
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
  }

  function customizeEndpoint(url, outputType, params, rptName = null) {
    // Mengganti [output_type] dengan outputType, jika outputType diberikan
    if (outputType) {
      url = url.replace("[output_type]", outputType)
    } else {
      // Menghapus placeholder [output_type] jika outputType tidak diberikan
      url = url.replace("/[output_type]", "")
    }

    if (rptName) {
      url = url.replace("[rpt_name]", rptName)
    } else {
      url = url.replace("/[rpt_name]", "")
    }

    // Mengganti [params] dengan params, jika params diberikan
    if (params) {
      url = url.replace("[params]", params)
    } else {
      // Menghapus placeholder [params] jika params tidak diberikan
      url = url
        ?.replace("?values={[params]}", "")
        ?.replace("?[params]", "")
        ?.replace("[params]", "")
    }

    return url
  }

  const errorToastPrint = (res) => {
    let errorMsg = t("An error occurred while printing the report")
    return toast.error(errorMsg)
  }

  const printReport = (
    params,
    moduleName,
    rptName,
    viewContentAs,
    customEndpointName
  ) => {
    let newParams = {}
    Object.keys(params).forEach((key) => {
      if (
        (params[key] !== null || params[key] !== "") &&
        key !== "output_type" &&
        key !== "print"
      ) {
        newParams[`par_${key}`] = params[key]
      } else {
        newParams[key] = params[key]
      }
    })

    if (viewContentAs) {
      let urlRpt = `https://api-danadesa.insaba.co.id/invoices/export/${moduleName}/${rptName}/${newParams["output_type"]}`
      let newQueryString = objectToQueryString(newParams)
      urlRpt = `${urlRpt}?${newQueryString}`
      let dataFrameParams = {
        storeName: storeName,
        data: urlRpt
      }

      dispatch(setDataFrame(dataFrameParams)).then((ress) => {
        const el_id = `data_frame_${storeName}`
        setTimeout(() => {
          // console.log(`scrolling to ${el_id}`)
          scrollToDiv(el_id)
        }, 200)
      })
    } else {
      // console.log(
      //   params,
      //   moduleName,
      //   rptName,
      //   viewContentAs,
      //   customEndpointName
      // )
      if (customEndpointName) {
        let newParams2 = {}
        Object.keys(newParams).forEach((key) => {
          if (
            newParams?.[key] &&
            key !== "output_type" &&
            key !== "print" &&
            key !== "rpt_name" &&
            key !== "par_rpt_name"
          ) {
            newParams2[key] = newParams[key]
          }
        })
        // console.log(params)
        const newQueryString = newParams2
          ? objectToQueryString(newParams2)
          : null
        const urlRpt = customizeEndpoint(
          customEndpointName,
          newParams["output_type"],
          newQueryString,
          params["rpt_name"]
        )
        // console.log(newParams2, newQueryString, urlRpt)
        return dispatch(
          getInvoicePlain({
            url: urlRpt,
            as_attachment: true
            // ...newParams2
          })
        ).then((res) => {
          // console.log(res)
          if (res.meta.requestStatus === "rejected") {
            errorToastPrint(res)
          }
          return res
        })
      } else {
        return dispatch(
          getInvoice({
            module_name: moduleName ?? "",
            rpt_name: rptName ?? "",
            as_attachment: true,
            ...newParams
          })
        ).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            errorToastPrint(res)
          }
        })
      }
    }
  }

  const renderPage = () => {
    // console.log("renderPage")
    let customHeaderSet =
      isDynamicColumnsFilter() &&
      pageAttr?.crudOpt?.dynamicColumnsFilterPos === "table-header"
        ? filtersComponents(true)
        : null

    // const currentPageType = store.pageAttributes[storeName]?.pageType
    // const currentGridTheme = store.pageAttributes[storeName]?.crudOpt?.gridTheme
    if (currentPageType === "crud") {
      if (pageAttr?.crudOpt && Object.keys(pageAttr?.crudOpt)?.length > 0) {
        if (currentGridTheme && currentGridTheme === "timeline") {
          return (
            <TimeLine
              pageAttr={pageAttr}
              storeName={storeName}
              lookupMode={lookupMode}
              lookupMode_fieldName={lookupMode_fieldName}
              lookupMode_defaultValue={lookupMode_defaultValue}
              lookupMode_onChange={lookupMode_onChange}
              lookupMode_isMulti={lookupMode_isMulti}
              filtersCustomAdd={getinitialFiltersCondition()}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "treeview") {
          return (
            <TreeView
              pageAttr={pageAttr}
              storeName={storeName}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "orgchart2") {
          return (
            // <OrgChart2
            //   pageAttr={pageAttr}
            //   storeName={storeName}
            //   filtersCustomAdd={getinitialFiltersCondition()}
            //   handleFiltersChangeToParent={handleChangeFilterFromChild}
            // />
            <OrgChart3
              pageAttr={pageAttr}
              storeName={storeName}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "orgchart") {
          return (
            <OrgChart
              pageAttr={pageAttr}
              storeName={storeName}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "calendar") {
          return (
            <CalendarPage
              pageAttr={pageAttr}
              storeName={storeName}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "gantt_chart") {
          return (
            <GanttChartCrud
              store={store}
              pageAttr={pageAttr}
              storeName={storeName}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "grid_card") {
          return (
            <GridCard
              key={resetGridAndFilters}
              pageAttr={pageAttr}
              storeName={storeName}
              lookupMode={lookupMode}
              lookupMode_fieldName={lookupMode_fieldName}
              lookupMode_defaultValue={lookupMode_defaultValue}
              lookupMode_onChange={lookupMode_onChange}
              lookupMode_isMulti={lookupMode_isMulti}
              filtersCustomAdd={getinitialFiltersCondition()}
              customHeaderSet={customHeaderSet}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
              initialPageId={initialPageId}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "list") {
          return (
            <List
              key={resetGridAndFilters}
              pageAttr={pageAttr}
              storeName={storeName}
              lookupMode={lookupMode}
              lookupMode_fieldName={lookupMode_fieldName}
              lookupMode_defaultValue={lookupMode_defaultValue}
              lookupMode_onChange={lookupMode_onChange}
              lookupMode_isMulti={lookupMode_isMulti}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
              initialPageId={initialPageId}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "list-sortable") {
          return (
            <ListSortable
              key={resetGridAndFilters}
              pageAttr={pageAttr}
              storeName={storeName}
              lookupMode={lookupMode}
              lookupMode_fieldName={lookupMode_fieldName}
              lookupMode_defaultValue={lookupMode_defaultValue}
              lookupMode_onChange={lookupMode_onChange}
              lookupMode_isMulti={lookupMode_isMulti}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
              initialPageId={initialPageId}
            />
          )
        } else if (currentGridTheme && currentGridTheme === "map") {
          return (
            <MapPage
              childRef={childRef}
              resetGridAndFilters={resetGridAndFilters}
              pageAttr={pageAttr}
              storeName={storeName?.replace("undefined_", "")}
              initialData={initialData}
              filtersCustomAdd={getinitialFiltersCondition()}
              customHeaderSet={customHeaderSet}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
              initialPageId={initialPageId}
              btnAddCondition={
                store.pageData[storeName?.replace("undefined_", "")]
                  ?.btnAddCondition ?? {}
              }
            />
          )
        } else {
          return (
            <Table
              ref={childRef}
              key={resetGridAndFilters}
              pageAttr={pageAttr}
              storeName={storeName}
              initialData={initialData}
              lookupMode={lookupMode}
              lookupMode_fieldName={lookupMode_fieldName}
              lookupMode_defaultValue={lookupMode_defaultValue}
              lookupMode_onChange={lookupMode_onChange}
              lookupMode_isMulti={lookupMode_isMulti}
              filtersCustomAdd={getinitialFiltersCondition()}
              handleFiltersChangeToParent={handleChangeFilterFromChild}
              initialPageId={initialPageId}
              customHeaderSet={customHeaderSet}
              btnAddCondition={store.pageData[storeName]?.btnAddCondition ?? {}}
              handleSelectedDropdownTable={
                handleBeforeSelectedDropdownTableChange
              }
              selectedDataTable={selectedDataTable}
            />
            // <div></div>
          )
        }
      } else {
        return devModeEnabled ? (
          <Card
            style={{
              // height: "40vh",
              // minHeight: "210px"
              height: "auto",
              minHeight: "40vh"
            }}
            className={`new-dynamic-page d-flex align-items-center justify-content-center`}
          >
            <h4 className="text-muted mb-2">{t("Add New Data Element")}</h4>
            <Row className="mx-3">
              <Col className="text-center">
                <Button.Ripple
                  dev-mode-header={'{"title": "Set Table Properties"}'}
                  dev-mode-data={[
                    "crudOpt.gridTheme",
                    "crudOpt.sourceDataFrom",
                    "crudOpt.endpointName",
                    "crudOpt.resp_key",
                    "crudOpt.removeDefaultHttpHeader",
                    "crudOpt.data_idField"
                  ]}
                  dev-mode-data-root="crudOpt"
                  className="btn-icon btn-add-page editmode_hover_edit"
                  outline
                  color="primary"
                >
                  {renderIcon("fontAwesome:table-list", "align-middle", 60)}
                </Button.Ripple>
                <h6 className="mt-50 text-muted">{t("Table")}</h6>
              </Col>
              <Col className="text-center">
                <Button.Ripple
                  dev-mode-header={'{"title": "Set Grid Properties"}'}
                  dev-mode-data={[
                    "crudOpt.gridTheme=grid_card",
                    "crudOpt.sourceDataFrom",
                    "crudOpt.endpointName",
                    "crudOpt.resp_key",
                    "crudOpt.removeDefaultHttpHeader",
                    "crudOpt.data_idField",
                    "crudOpt.gridSizeLg",
                    "crudOpt.gridSizeMd",
                    "crudOpt.gridSizeSm",
                    "crudOpt.detailFromGrid"
                  ]}
                  dev-mode-data-root="crudOpt"
                  className="btn-icon btn-add-page editmode_hover_edit"
                  outline
                  color="primary"
                >
                  {renderIcon("fontAwesome:grip", "align-middle", 60)}
                </Button.Ripple>
                <h6 className="mt-50 text-muted">{t("Grid")}</h6>
              </Col>
              <Col className="text-center">
                <Button.Ripple
                  dev-mode-header={'{"title": "Import Spreadsheet"}'}
                  dev-mode-data={[
                    "crudOpt.gridTheme",
                    "crudOpt.sourceDataFrom=4",
                    "crudOpt.data_idField",
                    "crudOpt.detailFromGrid=true"
                  ]}
                  dev-mode-data-root="crudOpt"
                  className="btn-icon btn-add-page editmode_hover_edit"
                  outline
                  color="primary"
                >
                  {renderIcon("fontAwesome:file-excel", "align-middle", 50)}
                </Button.Ripple>
                <h6 className="mt-50 text-muted">{t("Import")}</h6>
              </Col>
            </Row>
          </Card>
        ) : (
          <div className="new-dynamic-page"></div>
        )
      }
    } else if (currentPageType === "report") {
      // if (pageAttr?.reportOpt?.data?.dynamicPar?.length > 0) {
      return (
        <div className="mb-3">
          <DynamicForm
            dynamicForm={pageAttr?.reportOpt?.data?.dynamicPar ?? []}
            storeName={storeName}
            pageAttr={pageAttr}
            handleFiltersChangeToParent={handleChangeFilterFromChild}
            reportMode={true}
            handleFormSubmitPrint={printReport}
          />
        </div>
      )
      // } else {
      // }
    } else if (currentPageType === "custom") {
      if (pageAttr?.options?.data?.dynamicCard?.length > 0) {
        return (
          <DynamicCard
            dynamicCard={pageAttr?.options?.data?.dynamicCard}
            storeName={storeName}
            pageAttr={pageAttr}
          />
        )
      } else {
        return <BlankPage />
      }
    } else if (currentPageType === "detail-page") {
      if (pageAttr?.options?.detailPageIdFrom) {
        const detailIdValue = pageAttr?.options?.detailPageIdFrom?.value
        const detailPageIdUrl = pageAttr?.options?.detailPageIdUrl
        if (detailIdValue) {
          if (detailIdValue.includes("__")) {
            let userDataValue = detailIdValue
            if (detailIdValue === "__userData.name__") {
              userDataValue = userData?.name
            } else if (detailIdValue === "__userData.email__") {
              userDataValue = userData?.email
            } else if (detailIdValue === "__userData.id__") {
              userDataValue = userData?.id
            } else if (detailIdValue === "__userData.username__") {
              userDataValue = userData?.username
            } else if (detailIdValue === "__userData.id_employee__") {
              userDataValue = userData?.id_employee
            }
            // history.push(`${location.pathname}/${userDataValue}`)

            return navigate(
              `${location.pathname}/view/${userDataValue}?refresh=1`
            )
          }
        }

        // return (
        //   <Deta
        //     dynamicCard={pageAttr?.options?.data?.dynamicCard}
        //     storeName={storeName}
        //     pageAttr={pageAttr}
        //   />
        // )
      } else {
        return navigate(`${location.pathname}/view/99999XX?refresh=1`)
      }
    } else {
      return <BlankPage />
    }
  }

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [oldStyleBackgroundColor, setOldStyleBackgroundColor] = useState("")

  const requestFullscreen = () => {
    const element = document.getElementById("card_json_dev")
    // setOldStyleBackgroundColor(element.style.backgroundColor)
    // if (element) {
    //   element.style.backgroundColor = "#1e1e1e"
    // }
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }

    setIsFullscreen(true)
  }

  const exitFullscreen = () => {
    // if (oldStyleBackgroundColor) {
    //   const element = document.getElementById("card_json_dev")
    //   if (element) {
    //     element.style.backgroundColor = oldStyleBackgroundColor
    //   }
    // }

    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }

    setIsFullscreen(false)
  }

  const loadingFirstOnce = useRef(false)
  // const [btnAddCondition, setBtnAddCondition] = useState({})

  const [showModalDevState, setShowModalDevState] = useState(false)
  const [modalDevEditData, setModalDevEditData] = useState([])
  const [modalDevEditHeader, setModalDevEditHeader] = useState([])
  const [modalDevEditRoot, setModalDevEditDataRoot] = useState([])

  useEffect(() => {
    // console.log(editorRef)
    // console.log(window.location.pathname)
    // console.log(
    //   window.location.pathname.includes("/dyn_pages/_admin_") && !isAdminApp()
    // )
    // console.log("useEffect DynamicPages")
    // console.log(pageAttr)
    // if (loadingFirstOnce.current === false) {
    if (initialPageId ?? pageId) {
      setLoadingFirst(true)
      if (!store.pageAttributes[storeName]) {
        // console.log("pageAttr from relation")
        if (!pageAttr) {
          // getPageAttr("file")
          getPageAttr()
        } else {
          dispatch(setStorePageAttributes({ storeName, data: pageAttr }))
          if (!pageAttr?.isFromAddPage) {
            initGrid(pageAttr)
          }
          setCurrentPageType(pageAttr?.pageType)
          setCurrentGridTheme(
            localStorage.getItem(`${storeName}_layout`) ??
              pageAttr?.crudOpt?.gridTheme
          )

          // setBtnAddCondition(
          //   getBtnConditionAttr(
          //     "add",
          //     pageAttr?.crudOpt?.btnAddTextCondition,
          //     pageAttr?.crudOpt?.btnAddTextConditionDataFrom
          //   )
          // )

          // if (!store.pageData[storeName]?.isLoadingBtnAddCondition) {
          //   dispatch(setBtnAddCondition({ type: "add", storeName }))
          // }
          setLoadingFirst(false)
        }
      } else {
        if (!initialPageAttr) {
          setPageAttr(store.pageAttributes[storeName])
          // console.log(`get getPageAttr from cache`)
        } else {
          dispatch(
            setStorePageAttributes({
              storeName: storeName,
              data: initialPageAttr
            })
          )
        }

        if (!pageAttr?.isFromAddPage) {
          initGrid(store.pageAttributes[storeName])
        }
        setCurrentPageType(store.pageAttributes[storeName]?.pageType)
        setCurrentGridTheme(
          localStorage.getItem(`${storeName}_layout`) ??
            pageAttr?.crudOpt?.calendarInitialLayout ??
            pageAttr?.crudOpt?.gridTheme
        )
        // console.log("here")

        // setLastMenuTabs(null, window.location)

        setLoadingFirst(false)
      }

      // setLastMenuCached()
    }

    // console.log(pageAttr)
    if (!relPageId) {
      ////PERMISSION
      const menuObj = storeMenu.dataMyMenu.find((el) => {
        return el.routePath === location.pathname
      })
      // console.log(relPageId)
      if (menuObj) {
        sessionStorage.setItem("current_page_perm", menuObj?.permission_subject)
      } else if (location.pathname === "/dyn_pages/_admin_/profile") {
        sessionStorage.setItem("current_page_perm", "user_default_page")
      } else {
        if (
          !pageAttr?.isViewOnForm &&
          !pageAttr?.isViewOnRow &&
          location.pathname.includes("/dyn_pages/") &&
          location.pathname !== "/dyn_pages/_admin_/notifications" &&
          location.pathname !== "/dyn_pages/_admin_/profile" &&
          location.pathname !== "/dyn_pages/_admin_/reg_user_await"
        ) {
          sessionStorage.removeItem("current_page_perm")
          // console.log("no current page perm")
          navigate("/error/403")
        }

        // else if (location.pathname === "/dyn_pages/_admin_/profile") {
        //   console.log("here....")
        //   sessionStorage.setItem("current_page_perm", "all")
        // }
      }
    }

    if (
      pageAttr &&
      (pageAttr?.crudOpt?.btnAddTextCondition ||
        pageAttr?.crudOpt?.btnAddTextConditionDataFrom) &&
      !store.pageData[storeName]?.isLoadingBtnAddCondition &&
      store.pageData[storeName]?.btnAddCondition !== {} &&
      loadingFirstOnce.current === false
    ) {
      dispatch(
        setBtnAddCondition({ type: "add", storeName, pageAttrOri: pageAttr })
      ).then(() => {
        loadingFirstOnce.current = true
      })
    }

    // if (!loadingFirstOnce.current) {
    //   // setLastMenuTabs(null, window.location)
    // }

    // loadingFirstOnce.current = true
    // }

    Prism.highlightAll()

    // return () => {
    //   if (editorRef.current) {
    //     editorRef.current.dispose()
    //   }
    // }
  }, [pageAttr])

  useEffect(() => {
    const enableKey =
      devModeEnabled &&
      ((restrictedMenuLink.includes(window.location.pathname) &&
        [4, 6, 21].includes(userData.id)) ||
        !restrictedMenuLink.includes(window.location.pathname)) &&
      editorValid &&
      storeDeveloper?.pageAttributes?.[storeName]
    const handleKeyDown = (event) => {
      if (enableKey && event.ctrlKey && event.key === "s") {
        event.preventDefault()
        saveJson(event)
        // console.log("Ctrl + S was pressed! Invalidate data...")
      }
    }

    if (enableKey) {
      window.addEventListener("keydown", handleKeyDown)
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [devModeEnabled, editorValid, storeDeveloper?.pageAttributes?.[storeName]])

  const switchBtnConditionTree = (currentGridTheme) => {
    const pageAttrGridTheme =
      store.pageAttributes[storeName]?.crudOpt?.gridTheme

    if (!store.pageAttributes[storeName]?.crudOpt?.hideBtnSwitchLayout) {
      const btnSwitchToDefault = pageAttrGridTheme !== currentGridTheme && (
        <Fragment>
          <Button.Ripple
            outline
            id="btn-grid-switch-to-default"
            className="btn-icon me-50"
            color="secondary"
            onClick={(e) => {
              e.preventDefault()
              setTooltipBtnSwitchDefaultOpen(false)
              setTooltipBtnSwitchTreeviewOpen(false)
              setTooltipBtnSwitchOrgChartOpen(false)
              setTooltipBtnSwitchTreeTableOpen(false)
              setCurrentGridTheme(pageAttrGridTheme)
              localStorage.removeItem(`${storeName}_layout`)
              renderPage()
            }}
          >
            {renderIcon("material:restart_alt", "", 14)}
          </Button.Ripple>
          <Tooltip
            isOpen={tooltipBtnSwitchDefaultOpen}
            toggle={() =>
              setTooltipBtnSwitchDefaultOpen(!tooltipBtnSwitchDefaultOpen)
            }
            target="btn-grid-switch-to-default"
          >
            {t("Switch Layout to Default")}
          </Tooltip>
        </Fragment>
      )

      const btnSwitchToTreeview = (
        <Fragment>
          <Button.Ripple
            id="btn-grid-switch-to-treeview"
            className="btn-icon me-50"
            color="gradient-primary"
            onClick={(e) => {
              e.preventDefault()
              setTooltipBtnSwitchDefaultOpen(false)
              setTooltipBtnSwitchTreeviewOpen(false)
              setTooltipBtnSwitchOrgChartOpen(false)
              setTooltipBtnSwitchTreeTableOpen(false)
              setCurrentGridTheme("treeview")
              localStorage.setItem(`${storeName}_layout`, "treeview")
              renderPage()
            }}
          >
            {renderIcon("material:account_tree", "", 14)}
          </Button.Ripple>
          <Tooltip
            isOpen={tooltipBtnSwitchTreeviewOpen}
            toggle={() =>
              setTooltipBtnSwitchTreeviewOpen(!tooltipBtnSwitchTreeviewOpen)
            }
            target="btn-grid-switch-to-treeview"
          >
            {t("Switch Layout to Tree View")}
          </Tooltip>
        </Fragment>
      )

      const btnSwitchToOrgChart = (
        <Fragment>
          <Button.Ripple
            id="btn-grid-switch-to-orgchart2"
            className="btn-icon me-50"
            color="gradient-primary"
            onClick={(e) => {
              e.preventDefault()
              setTooltipBtnSwitchDefaultOpen(false)
              setTooltipBtnSwitchTreeviewOpen(false)
              setTooltipBtnSwitchOrgChartOpen(false)
              setTooltipBtnSwitchTreeTableOpen(false)
              setCurrentGridTheme("orgchart2")
              localStorage.setItem(`${storeName}_layout`, "orgchart2")
              renderPage()
            }}
          >
            {renderIcon("fontAwesome:sitemap", "", 14)}
          </Button.Ripple>
          <Tooltip
            isOpen={tooltipBtnSwitchOrgChartOpen}
            toggle={() =>
              setTooltipBtnSwitchOrgChartOpen(!tooltipBtnSwitchOrgChartOpen)
            }
            target="btn-grid-switch-to-orgchart2"
          >
            {t("Switch Layout to Organization Chart")}
          </Tooltip>
        </Fragment>
      )

      const btnSwitchToTreeTable = (
        <Fragment>
          <Button.Ripple
            id="btn-grid-switch-to-table_tree"
            className="btn-icon me-50"
            color="gradient-primary"
            onClick={(e) => {
              e.preventDefault()
              setTooltipBtnSwitchDefaultOpen(false)
              setTooltipBtnSwitchTreeviewOpen(false)
              setTooltipBtnSwitchOrgChartOpen(false)
              setTooltipBtnSwitchTreeTableOpen(false)
              setCurrentGridTheme("table_tree")
              localStorage.setItem(`${storeName}_layout`, "table_tree")
              renderPage()
            }}
          >
            {renderIcon("fontAwesome:table", "", 14)}
          </Button.Ripple>
          <Tooltip
            isOpen={tooltipBtnSwitchTreeTableOpen}
            toggle={() =>
              setTooltipBtnSwitchTreeTableOpen(!tooltipBtnSwitchTreeTableOpen)
            }
            target="btn-grid-switch-to-table_tree"
          >
            {t("Switch Layout to Tree Table")}
          </Tooltip>
        </Fragment>
      )

      if (["treeview", "table_tree", "orgchart2"].includes(pageAttrGridTheme)) {
        if (currentGridTheme === "treeview") {
          return (
            <Fragment>
              {btnSwitchToDefault}
              {btnSwitchToOrgChart}
              {btnSwitchToTreeTable}
            </Fragment>
          )
        } else if (currentGridTheme === "orgchart2") {
          return (
            <Fragment>
              {btnSwitchToDefault}
              {btnSwitchToTreeview}
              {btnSwitchToTreeTable}
            </Fragment>
          )
        } else if (currentGridTheme === "table_tree") {
          return (
            <Fragment>
              {btnSwitchToDefault}
              {btnSwitchToTreeview}
              {btnSwitchToOrgChart}
            </Fragment>
          )
        }
      }
    }
  }

  useEffect(() => {
    let map
    let marker
    const mapElement = document.getElementById("map-report")
    if (mapElement) {
      setTimeout(() => {
        let textContent = mapElement.textContent
        if (textContent) {
          let locStr = textContent?.split(",")
          map = L.map("map-report", {
            center: locStr,
            zoom: store.mapOptions[storeName]?.zoom ?? 13,
            zoomControl: false,
            attributionControl: false,
            dragging: false,
            doubleClickZoom: false,
            scrollWheelZoom: false,
            boxZoom: false,
            keyboard: false,
            tap: false,
            touchZoom: false
          })
          L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19
          }).addTo(map)
          marker = L.marker(locStr).addTo(map)
        }
      }, 200)
    }
  }, [])

  // document.body.addEventListener("click", function (event) {
  //   const editPart = event.target.closest(".editmode_hover_edit")
  //   // console.log(editPart)
  //   if (editPart) {
  //     const fieldNamePart = editPart.getAttribute("dev-mode-data")

  //     if (fieldNamePart) {
  //       const fieldNamePartArr = fieldNamePart.split(",")
  //       setModalDevEditData(fieldNamePartArr)
  //       const headersPart = editPart.getAttribute("dev-mode-header")
  //       if (headersPart) {
  //         // console.log(headersPart, JSON.parse(headersPart))
  //         const headersPartsObj = JSON.parse(headersPart)
  //         setModalDevEditHeader(headersPartsObj)
  //       } else {
  //         setModalDevEditHeader(null)
  //       }

  //       const rootObj = editPart.getAttribute("dev-mode-data-root")
  //       if (rootObj) {
  //         // console.log(headersPart, JSON.parse(headersPart))
  //         setModalDevEditDataRoot(rootObj)
  //       } else {
  //         setModalDevEditDataRoot(null)
  //       }

  //       setShowModalDevState(!showModalDevState)
  //     }

  //     // box.setAttribute("style", "background-color: yellow;");
  //   }
  // })

  useEffect(() => {
    const handleClickHover = (event) => {
      const editPart = event.target.closest(".editmode_hover_edit")
      const editPart2 = event.target.closest(".editmode_hover_edit2")
      // console.log(editPart)
      if (editPart) {
        const fieldNamePart = editPart.getAttribute("dev-mode-data")

        if (fieldNamePart) {
          const fieldNamePartArr = fieldNamePart.split(",")
          setModalDevEditData(fieldNamePartArr)
          const headersPart = editPart.getAttribute("dev-mode-header")
          if (headersPart) {
            // console.log(headersPart, JSON.parse(headersPart))
            const headersPartsObj = JSON.parse(headersPart)
            setModalDevEditHeader(headersPartsObj)
          } else {
            setModalDevEditHeader(null)
          }

          const rootObj = editPart.getAttribute("dev-mode-data-root")
          if (rootObj) {
            // console.log(headersPart, JSON.parse(headersPart))
            setModalDevEditDataRoot(rootObj)
          } else {
            setModalDevEditDataRoot(null)
          }

          setShowModalDevState(!showModalDevState)
        }

        // box.setAttribute("style", "background-color: yellow;");
      } else if (editPart2) {
      }
    }

    document.body.addEventListener("click", handleClickHover)

    return () => {
      document.body.removeEventListener("click", handleClickHover)
    }
  }, [])

  // const childRef = useRef(null)
  const childRef = createRef()
  const editorRef = useRef(null)
  const [codeNavigationOpt, setCodeNavigationOpt] = useState([])

  function handleEditorWillMount(editor, monaco) {
    // console.log("handleEditorWillMount")
    editorRef.current = editor
    editor.setScrollPosition({ scrollTop: 0 })
  }

  function handleEditorDidMount(editor, monaco) {
    // console.log("handleEditorDidMount")
    const model = editor.getModel()
    const findCriteriaArr = [
      "crudOpt",
      "dynamicColumns",
      "dynamicColumnsFilter",
      "dynamicForm",
      "dynamicColumnsSearchFields",
      "tabContent",
      "relationsCrud"
    ]
    let codeNavigationArr = []
    let codeNavigationDropdown = []
    findCriteriaArr.map((criteria) => {
      const findRes = model.findMatches(
        `"${criteria}":`,
        true,
        false,
        false,
        null,
        true
      )
      if (findRes) {
        findRes.map((find) => {
          const lineData = find?.range
          codeNavigationDropdown.push({
            label: `${criteria} (line ${lineData?.startLineNumber})`,
            value: lineData,
            line: lineData?.startLineNumber
          })
          codeNavigationArr.push(lineData)
        })
      }
    })

    if (codeNavigationDropdown) {
      codeNavigationDropdown.sort((a, b) => a.line - b.line)
      setCodeNavigationOpt(codeNavigationDropdown)
    }

    editorRef.current = editor

    const lastLineNumber =
      storeDeveloper?.pageAttributesLastState[storeName]?.lineNumber
    // console.log(lastLineNumber)
    if (lastLineNumber) {
      // editor.getAction("editor.unfoldAll").run()
      // editor.revealLine(lastLineNumber)
      editor.revealLineInCenter(lastLineNumber)
      editor.setPosition({ column: 1, lineNumber: lastLineNumber })
    } else {
      editor.getAction("editor.foldLevel4").run()
    }

    /////GET LAST CHANGES IN STORAGE
    const lastChangePageAttr = JSON.parse(
      localStorage.getItem(`pageAttr_${storeName}`)
    )
    if (lastChangePageAttr) {
      setPageAttrLastChanges(lastChangePageAttr)
    }

    /////GET LAST AFTER CHANGES IN STORAGE
    const afterChangePageAttr = JSON.parse(
      localStorage.getItem(`pageAttr_after_${storeName}`)
    )
    if (afterChangePageAttr) {
      setPageAttrAfterChanges(afterChangePageAttr)
    }
  }

  const setEditModeJsonState = (e, isDiscard = false) => {
    e.preventDefault()
    if (isDiscard) {
      // console.log("discard")
      // console.log(originalJsonAttr.length)
      const pageAttrInitFirst =
        storeDeveloper.pageAttributesBackup[storeName] || []
      const pageAttrInitEdited = urlReplacer(JSON.parse(pageAttrInitFirst))
      let originalJsonAttrObj = pageAttrInitEdited

      dispatch(
        setStorePageAttributes({ storeName, data: originalJsonAttrObj })
      ).then((data) => {
        setPageAttr(originalJsonAttrObj)
        setTimeout(() => {
          resetDevState()
          renderPage()
        }, 50)
        dispatch(setEditModeJson({ storeName })).then((res) => {})

        const queryParams = new URLSearchParams(window.location.search)
        const dev_mode_json_qs = queryParams.get("dev_mode_json")
        if (dev_mode_json_qs || dev_mode_json_qs === "true") {
          queryParams.delete("dev_mode_json")
          navigate({ search: queryParams.toString() })
        }
      })
    } else {
      initNewModeJson()
    }

    if (isFullscreen) {
      exitFullscreen()
    }
  }

  ////// SAVE CHANGES FOR UNDO/REDO BEGIN
  const [pageAttrLastChanges, setPageAttrLastChanges] = useState(null)
  const [pageAttrAfterChanges, setPageAttrAfterChanges] = useState(null)

  const undo = () => {
    const pageAttrLastChangesObj = JSON.parse(pageAttrLastChanges)
    const model = editorRef.current.getModel()
    const currentPageAttrBackup = model.getValue()
    model.setValue(JSON.stringify(pageAttrLastChangesObj, null, 2))
    setPageAttr(pageAttrLastChangesObj)

    // remove undo button
    setPageAttrLastChanges(null)
    localStorage.removeItem(`pageAttr_${storeName}`)

    // save for redo later from changed json
    setPageAttrAfterChanges(currentPageAttrBackup)
    localStorage.setItem(
      `pageAttr_after_${storeName}`,
      JSON.stringify(currentPageAttrBackup)
    )
  }

  const redo = () => {
    const pageAttrAfterChangesObj = JSON.parse(pageAttrAfterChanges)
    const model = editorRef.current.getModel()
    const currentPageAttrBackup = model.getValue()
    model.setValue(JSON.stringify(pageAttrAfterChangesObj, null, 2))
    setPageAttr(pageAttrAfterChangesObj)
    // remove undo button
    setPageAttrLastChanges(currentPageAttrBackup)
    localStorage.setItem(
      `pageAttr_${storeName}`,
      JSON.stringify(currentPageAttrBackup)
    )

    // save for undo later from changed json
    setPageAttrAfterChanges(null)
    localStorage.removeItem(`pageAttr_after_${storeName}`)
  }

  function handleEditorChange(value, event, fromUndoRedo = false) {
    // console.log("handleEditorChange")
    // console.log(editorRef.current)
    let editor = editorRef.current
    let lastState = {
      lineNumber: editor.getPosition().lineNumber
    }
    dispatch(setLastState({ storeName, lastState })).then((state) => {
      // console.log(state)
    })

    setEditorValid(true)
    // setTempJsonAttr(value)
    dispatch(setChangedJsonAttr({ storeName, pageAttr: value }))
    dispatch(
      setEditModeJsonAttr({
        storeName,
        pageAttr: value
      })
    ).then((res) => {
      // console.log(res)
    })
  }

  function handleEditorValidation(markers) {
    setEditorValid(markers?.length === 0)
    // markers.forEach((marker) => {
    //   console.log("onValidate:", marker.message)
    // })
  }

  const saveJson = async (e) => {
    const changedJson = JSON.parse(
      storeDeveloper.pageAttributesChanged[storeName] ??
        storeDeveloper.pageAttributes[storeName]
    )

    const lastBeforeChangedJson = JSON.stringify(pageAttr)
    const changedJson2 = urlReplacer(changedJson)

    // dispatch(setStorePageAttributes({ storeName, data: {} })).then((x) => {
    dispatch(setStorePageAttributes({ storeName, data: changedJson2 })).then(
      (result) => {
        // console.log(result)
        setPageAttr(changedJson2)

        setTimeout(() => {
          renderPage()
        }, 50)

        // setTimeout(() => {
        //   resetAllData()
        // }, 250)

        setTimeout(() => {
          setEditModeJsonState(e)
          if (isFullscreen) {
            exitFullscreen()
          }
        }, 100)

        resetDevState()

        localStorage.setItem(
          `pageAttr_${storeName}`,
          JSON.stringify(lastBeforeChangedJson)
        )
        setPageAttrAfterChanges(null)
        localStorage.removeItem(`pageAttr_after_${storeName}`)
      }
    )
    // })

    let currentMenuData = storeMenu?.dataMyMenu?.find(
      (item) => item?.routePath === window.location.pathname
    )

    const menuId = currentMenuData.id

    const axiosSso = useJwt.ssoAxiosInterceptor
    await axiosSso({
      method: "put",
      url: `/api/menu/${menuId}`,
      data: {
        pageAttr: changedJson
      }
    }).then((res) => {
      // console.log(res)
      if (currentMenuData?.fetch !== 1) {
        dispatch(updateMenuToNeedFetch(menuId))
      }
    })
  }

  const editorGoTo = (e, item) => {
    e.preventDefault()
    const editor = editorRef.current
    const lineData = item.value
    editor.setScrollPosition({ scrollTop: 0 })
    editor.setPosition({
      column: lineData?.startColumn,
      lineNumber: lineData?.startLineNumber
    })
    // editor.revealLine(lineData?.startLineNumber)
    editor.revealLineInCenter(lineData?.startLineNumber)
    // editor.trigger("unfold", "editor.unfoldAll")
  }

  const foldEvent = (e, value) => {
    e.preventDefault()
    const editor = editorRef.current
    editor.setScrollPosition({ scrollTop: 0 })
    editor.setPosition({
      column: 0,
      lineNumber: 0
    })
    editor.revealLine(0)
    if (value) {
      editor.trigger("unfold", "editor.unfoldAll")
      setTimeout(() => {
        editor.getAction("editor.foldLevel2").run()
      }, 30)
    } else {
      editor.trigger("unfold", "editor.unfoldAll")
    }
    setFoldAll(value)
  }

  const previewJson = async (e) => {
    const changedJson = JSON.parse(
      storeDeveloper.pageAttributesChanged[storeName]
    )

    const lastBeforeChangedJson = JSON.stringify(pageAttr)
    const changedJson2 = urlReplacer(changedJson)

    dispatch(setStorePageAttributes({ storeName, data: changedJson2 })).then(
      (result) => {
        // console.log(result)
        setPageAttr(changedJson)

        setTimeout(() => {
          renderPage()
        }, 50)

        // setTimeout(() => {
        //   resetAllData()
        // }, 250)

        setTimeout(() => {
          setEditModeJsonState(e)
          if (isFullscreen) {
            exitFullscreen()
          }
        }, 100)

        localStorage.setItem(
          `pageAttr_${storeName}`,
          JSON.stringify(lastBeforeChangedJson)
        )
        setPageAttrAfterChanges(null)
        localStorage.removeItem(`pageAttr_after_${storeName}`)
      }
    )

    // let currentMenuData = storeMenu?.dataMyMenu?.find(
    //   (item) => item?.routePath === window.location.pathname
    // )
  }

  const saveJson2 = async (changedJson) => {
    // console.log(changedJson)
    dispatch(setStorePageAttributes({ storeName, data: changedJson })).then(
      (result) => {
        // console.log(result)
        setPageAttr(changedJson)
        setShowModalDevState(!showModalDevState)
        setTimeout(() => {
          renderPage()
        }, 50)

        // setTimeout(() => {
        //   resetAllData()
        // }, 250)
      }
    )

    let currentMenuData = storeMenu?.dataMyMenu?.find(
      (item) => item?.routePath === window.location.pathname
    )

    const axiosSso = useJwt.ssoAxiosInterceptor
    await axiosSso({
      method: "put",
      url: `/api/menu/${currentMenuData.id}`,
      data: {
        pageAttr: changedJson
      }
    }).then((res) => {
      // console.log(res)
    })
  }

  if (pageAttr && store.pageAttributes[storeName]) {
    if (!storeDeveloper.pageAttributes[storeName]) {
      return (
        <Fragment>
          {devModeEnabled && !pageAttr?.isViewOnForm && !pageAttr?.onDashboard && (
            <div className="d-none d-md-flex mb-1 justify-content-end">
              {storeDeveloper.pageAttributesBackup[storeName] && (
                <Button color="flat-warning" className="btn-sm" disabled>
                  {renderIcon("material:preview", "align-middle", 12)}
                  <span className="align-middle ms-25">{t("Preview")}</span>
                </Button>
              )}
              <Button
                onClick={(e) => setEditModeJsonState(e)}
                color="relief-dark"
                className="btn-sm btn-dev-mode-json"
              >
                {renderIcon("material:data_object", "align-middle", 12)}
                <span className="align-middle ms-25">JSON</span>
              </Button>
            </div>
          )}

          {!initialPageId ? (
            <Fragment>
              {!pageAttr?.hidePageHeader && (
                <div className="content-header row align-items-center saba-page-header">
                  {pageAttr?.pageTitle ||
                  pageAttr?.pageModuleTitle ||
                  pageAttr?.pageDesc ? (
                    <Fragment>
                      {!pageAttr?.isViewOnForm ? (
                        <Breadcrumbs
                          title={t(pageAttr?.pageTitle)}
                          data={
                            pageAttr?.pageModuleTitle
                              ? [
                                  { title: t(pageAttr?.pageModuleTitle) },
                                  { title: t(pageAttr?.pageTitle ?? "") }
                                ]
                              : [{ title: t(pageAttr?.pageTitle ?? "") }]
                          }
                          mb={2}
                          subtitle={t(
                            searchParams?.get("_page_desc_") ??
                              pageAttr?.pageDesc
                          )}
                        />
                      ) : (
                        <div
                          className={`content-header-left col-md-9 col-8 mb-2 ${
                            devModeEnabled ? "editmode_hover_edit" : ""
                          }`}
                        >
                          <h5 className="text-bold">
                            {t(pageAttr?.pageTitle)}
                          </h5>
                          <small>
                            {t(
                              searchParams?.get("_page_desc_") ??
                                pageAttr?.pageDesc
                            )}
                          </small>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    !pageAttr?.crudOpt?.hideReloadDataBtn && (
                      <div
                        className={`content-header-left col-md-9 col-8 mb-2 ${
                          devModeEnabled ? "editmode_hover_edit" : ""
                        }`}
                      ></div>
                    )
                  )}

                  {/* BUTTON REFRESH & SWITCH LAYOUT  */}
                  {currentPageType !== "report" &&
                    currentPageType !== "custom" && (
                      <Fragment>
                        <div className="content-header-right text-end col-md-3 col-4 mb-2">
                          <div className="breadcrumb-right">
                            {/* BUTTON ADD POSITION DESKTOP  */}
                            {!pageAttr?.crudOpt?.disabledAdd &&
                              !pageAttr?.onDashboard &&
                              ability.can(
                                "post",
                                sessionStorage.getItem("current_page_perm")
                              ) &&
                              getRequiredFilterState(
                                store.pageData[storeName]?.params
                              ) &&
                              pageAttr?.crudOpt?.btnAddTextPosition &&
                              !store.pageData[storeName]
                                ?.isLoadingBtnAddCondition &&
                              Object.keys(
                                store.pageData[storeName]?.btnAddCondition ?? {}
                              )?.length > 0 && (
                                <Button
                                  className={`add-permission mt-sm-0 mt-1 me-1 d-none d-md-inline-block ${
                                    pageAttr?.isViewOnRow ? "round" : ""
                                  }`}
                                  size={
                                    store.pageData[storeName]?.btnAddCondition
                                      ?.size ?? "md"
                                  }
                                  color={
                                    pageAttr?.isViewOnRow ||
                                    pageAttr?.isViewOnForm
                                      ? store.pageData[storeName]
                                          ?.btnAddCondition?.color
                                        ? store.pageData[storeName]
                                            ?.btnAddCondition?.color
                                        : "gradient-primary"
                                      : store.pageData[storeName]
                                          ?.btnAddCondition?.color
                                      ? store.pageData[storeName]
                                          ?.btnAddCondition?.color
                                      : "primary"
                                  }
                                  onClick={(e) =>
                                    childRef.current.handleAddEdit(null)
                                  }
                                >
                                  {!store.pageData[storeName]?.btnAddCondition
                                    ?.icon ? (
                                    <Fragment>
                                      {store.pageData[storeName]
                                        ?.btnAddCondition?.text ??
                                        pageAttr?.crudOpt?.btnAddText ??
                                        `${t("Add New")} ${t(
                                          pageAttr.pageTitle
                                        )}`}
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {(store.pageData[storeName]
                                        ?.btnAddCondition.iconPos === "left" ||
                                        !store.pageData[storeName]
                                          ?.btnAddCondition.iconPos) &&
                                        renderIcon(
                                          store.pageData[storeName]
                                            ?.btnAddCondition?.icon,
                                          store.pageData[storeName]
                                            ?.btnAddCondition?.iconClass ?? "",
                                          store.pageData[storeName]
                                            ?.btnAddCondition?.iconSize ?? 14
                                        )}
                                      <span className="align-middle">
                                        {store.pageData[storeName]
                                          ?.btnAddCondition?.text ??
                                          pageAttr?.crudOpt?.btnAddText ??
                                          `${t("Add New")} ${t(
                                            pageAttr.pageTitle
                                          )}`}
                                      </span>
                                      {store.pageData[storeName]
                                        ?.btnAddCondition.iconPos === "right" &&
                                        renderIcon(
                                          store.pageData[storeName]
                                            ?.btnAddCondition?.icon,
                                          store.pageData[storeName]
                                            ?.btnAddCondition?.iconClass ?? "",
                                          store.pageData[storeName]
                                            ?.btnAddCondition?.iconSize ?? 14
                                        )}
                                    </Fragment>
                                  )}
                                </Button>
                              )}

                            {/* {((store.pageAttributes[storeName]?.crudOpt
                          ?.gridTheme === "treeview" &&
                          currentGridTheme === "treeview") ||
                          (store.pageAttributes[storeName]?.crudOpt
                            ?.gridTheme === "table_tree" &&
                            currentGridTheme === "table_tree")) &&
                          !store.pageAttributes[storeName]?.crudOpt
                            ?.hideBtnSwitchLayout && (
                            <Fragment>
                              <Button.Ripple
                                id="btn-grid-switch-to-orgchart2"
                                className="btn-icon me-1"
                                color="primary"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setCurrentGridTheme("orgchart2")
                                  localStorage.setItem(
                                    `${storeName}_layout`,
                                    "orgchart2"
                                  )
                                  renderPage()
                                }}
                              >
                                {renderIcon("fontAwesome:sitemap", "", 14)}
                              </Button.Ripple>
                              <UncontrolledTooltip target="btn-grid-switch-to-orgchart2">
                                {t("Switch Layout to Organization Chart")}
                              </UncontrolledTooltip>

                              <Button.Ripple
                                id="btn-grid-switch-to-table_tree"
                                className="btn-icon me-1"
                                color="primary"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setCurrentGridTheme("table_tree")
                                  localStorage.setItem(
                                    `${storeName}_layout`,
                                    "table_tree"
                                  )
                                  renderPage()
                                }}
                              >
                                {renderIcon("fontAwesome:table", "", 14)}
                              </Button.Ripple>
                              <UncontrolledTooltip target="btn-grid-switch-to-table_tree">
                                {t("Switch Layout to Tree Table")}
                              </UncontrolledTooltip>
                            </Fragment>
                          )}

                        {store.pageAttributes[storeName]?.crudOpt?.gridTheme ===
                          "treeview" &&
                          currentGridTheme === "orgchart2" &&
                          !store.pageAttributes[storeName]?.crudOpt
                            ?.hideBtnSwitchLayout && (
                            <Fragment>
                              <Button.Ripple
                                id="btn-grid-switch-to-treeview"
                                className="btn-icon me-1"
                                color="primary"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setCurrentGridTheme("treeview")
                                  localStorage.setItem(
                                    `${storeName}_layout`,
                                    "treeview"
                                  )
                                  renderPage()
                                }}
                              >
                                {renderIcon("material:account_tree", "", 14)}
                              </Button.Ripple>
                              <UncontrolledTooltip target="btn-grid-switch-to-treeview">
                                {t("Switch Layout to Tree View")}
                              </UncontrolledTooltip>

                              <Button.Ripple
                                id="btn-grid-switch-to-table_tree"
                                className="btn-icon me-1"
                                color="primary"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setCurrentGridTheme("table_tree")
                                  localStorage.setItem(
                                    `${storeName}_layout`,
                                    "table_tree"
                                  )
                                  renderPage()
                                }}
                              >
                                {renderIcon("fontAwesome:table", "", 14)}
                              </Button.Ripple>
                              <UncontrolledTooltip target="btn-grid-switch-to-table_tree">
                                {t("Switch Layout to Tree Table")}
                              </UncontrolledTooltip>
                            </Fragment>
                          )} */}

                            {switchBtnConditionTree(currentGridTheme)}

                            {store.pageAttributes[storeName]?.crudOpt
                              ?.gridTheme === "calendar" &&
                              currentGridTheme === "calendar" &&
                              !store.pageAttributes[storeName]?.crudOpt
                                ?.hideBtnSwitchLayout && (
                                <Fragment>
                                  <Button.Ripple
                                    id="btn-grid-switch-to-table"
                                    className="btn-icon me-1"
                                    color="primary"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setCurrentGridTheme("table")
                                      localStorage.setItem(
                                        `${storeName}_layout`,
                                        "table"
                                      )
                                      renderPage()
                                    }}
                                  >
                                    <TableViewOutlinedIcon fontSize="large" />
                                  </Button.Ripple>
                                  <UncontrolledTooltip target="btn-grid-switch-to-table">
                                    {t("Switch Layout to Table")}
                                  </UncontrolledTooltip>
                                </Fragment>
                              )}
                            {store.pageAttributes[storeName]?.crudOpt
                              ?.gridTheme === "calendar" &&
                              currentGridTheme === "table" &&
                              !store.pageAttributes[storeName]?.crudOpt
                                ?.hideBtnSwitchLayout && (
                                <Fragment>
                                  <Button.Ripple
                                    id="btn-grid-switch-to-calendar"
                                    className="btn-icon me-1"
                                    color="primary"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setCurrentGridTheme("calendar")
                                      localStorage.setItem(
                                        `${storeName}_layout`,
                                        "calendar"
                                      )
                                      renderPage()
                                    }}
                                  >
                                    <CalendarMonthOutlinedIcon fontSize="large" />
                                  </Button.Ripple>
                                  <UncontrolledTooltip target="btn-grid-switch-to-calendar">
                                    {t("Switch Layout to Calendar")}
                                  </UncontrolledTooltip>
                                </Fragment>
                              )}

                            {(store.pageAttributes[storeName]?.crudOpt
                              ?.showBackButton ||
                              searchParams.get("isOrigin") === "true") && (
                              <Fragment>
                                <Button.Ripple
                                  id="btn-back-dynamic-page"
                                  className="btn-icon me-1"
                                  color="secondary"
                                  outline
                                  onClick={(e) => {
                                    e.preventDefault()
                                    return navigate(-1)
                                  }}
                                >
                                  {renderIcon(
                                    "material:arrow_back",
                                    "align-middle",
                                    16
                                  )}
                                </Button.Ripple>
                                <UncontrolledTooltip target="btn-back-dynamic-page">
                                  {t("Back")}
                                </UncontrolledTooltip>
                              </Fragment>
                            )}

                            {!pageAttr?.crudOpt?.hideReloadDataBtn &&
                              pageAttr?.crudOpt?.endpointName && (
                                <Fragment>
                                  <Button.Ripple
                                    outline
                                    id="btn-grid-reset"
                                    className="btn-icon"
                                    color="primary"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      resetAllData()
                                    }}
                                  >
                                    <RefreshCcw size={16} />
                                  </Button.Ripple>
                                  <UncontrolledTooltip target="btn-grid-reset">
                                    {t("Refresh data and reset filters")}
                                  </UncontrolledTooltip>
                                </Fragment>
                              )}
                          </div>
                        </div>
                        {/* BUTTON ADD POSITION MOBILE  */}
                        {!pageAttr?.crudOpt?.disabledAdd &&
                          !pageAttr?.onDashboard &&
                          ability.can(
                            "post",
                            sessionStorage.getItem("current_page_perm")
                          ) &&
                          getRequiredFilterState(
                            store.pageData[storeName]?.params
                          ) &&
                          pageAttr?.crudOpt?.btnAddTextPosition &&
                          !store.pageData[storeName]
                            ?.isLoadingBtnAddCondition &&
                          Object.keys(
                            store.pageData[storeName]?.btnAddCondition ?? {}
                          )?.length > 0 && (
                            <Col xs={12} className="d-block d-md-none mb-1">
                              <Button
                                className={`add-permission w-100 ${
                                  pageAttr?.isViewOnRow ? "round" : ""
                                }`}
                                size={
                                  store.pageData[storeName]?.btnAddCondition
                                    ?.size ?? "md"
                                }
                                color={
                                  pageAttr?.isViewOnRow ||
                                  pageAttr?.isViewOnForm
                                    ? store.pageData[storeName]?.btnAddCondition
                                        ?.color
                                      ? store.pageData[storeName]
                                          ?.btnAddCondition?.color
                                      : "gradient-primary"
                                    : store.pageData[storeName]?.btnAddCondition
                                        ?.color
                                    ? store.pageData[storeName]?.btnAddCondition
                                        ?.color
                                    : "primary"
                                }
                                onClick={(e) =>
                                  childRef.current.handleAddEdit(null)
                                }
                              >
                                {!store.pageData[storeName]?.btnAddCondition
                                  ?.icon ? (
                                  <Fragment>
                                    {store.pageData[storeName]?.btnAddCondition
                                      ?.text ??
                                      pageAttr?.crudOpt?.btnAddText ??
                                      `${t("Add New")} ${t(
                                        pageAttr.pageTitle
                                      )}`}
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    {(store.pageData[storeName]?.btnAddCondition
                                      .iconPos === "left" ||
                                      !store.pageData[storeName]
                                        ?.btnAddCondition.iconPos) &&
                                      renderIcon(
                                        store.pageData[storeName]
                                          ?.btnAddCondition?.icon,
                                        store.pageData[storeName]
                                          ?.btnAddCondition?.iconClass ?? "",
                                        store.pageData[storeName]
                                          ?.btnAddCondition?.iconSize ?? 14
                                      )}
                                    <span className="align-middle">
                                      {store.pageData[storeName]
                                        ?.btnAddCondition?.text ??
                                        pageAttr?.crudOpt?.btnAddText ??
                                        `${t("Add New")} ${t(
                                          pageAttr.pageTitle
                                        )}`}
                                    </span>
                                    {store.pageData[storeName]?.btnAddCondition
                                      .iconPos === "right" &&
                                      renderIcon(
                                        store.pageData[storeName]
                                          ?.btnAddCondition?.icon,
                                        store.pageData[storeName]
                                          ?.btnAddCondition?.iconClass ?? "",
                                        store.pageData[storeName]
                                          ?.btnAddCondition?.iconSize ?? 14
                                      )}
                                  </Fragment>
                                )}
                              </Button>
                            </Col>
                          )}
                      </Fragment>
                    )}
                </div>
              )}
            </Fragment>
          ) : !pageAttr?.isDropdownTable && !pageAttr?.isInputTable ? (
            !pageAttr?.isViewOnRow && !pageAttr?.onDashboard ? (
              // <Alert color="primary">
              //   <div className="alert-body">
              //     {pageAttr?.menuIcon ? (
              //       renderIcon(pageAttr?.menuIcon, "me-1 align-middle", 16, {})
              //     ) : (
              //       <Info size={15} />
              //     )}
              //     <span className="align-middle">
              //       {t("List of")} {t(pageAttr?.pageTitle)}
              //     </span>
              //   </div>
              // </Alert>
              <Fragment>
                <div className="mt-0 mb-0 divider divider-dashed divider-start">
                  <div className="divider-text mb-50">
                    {pageAttr?.menuIcon ? (
                      renderIcon(
                        pageAttr?.menuIcon,
                        "me-1 align-middle",
                        16,
                        {}
                      )
                    ) : (
                      <Info className="me-1" size={15} />
                    )}
                    <span className="fw-bolder align-middle me-1">
                      {t("List of")} {t(pageAttr?.pageTitle)}
                      {pageAttr?.pageDesc && (
                        <small className="ms-1 fw-normal">
                          {t(pageAttr?.pageDesc)}
                        </small>
                      )}
                    </span>
                  </div>
                </div>
              </Fragment>
            ) : !pageAttr?.onDashboard && !pageAttr?.isInputTable ? (
              <Fragment>
                {pageAttr?.menuIcon ? (
                  renderIcon(pageAttr?.menuIcon, "me-1 align-middle", 16, {})
                ) : (
                  <Info className="me-1" size={15} />
                )}
                <span className="fw-bold align-middle me-1">
                  {t("List of")} {t(pageAttr?.pageTitle)}
                  {pageAttr?.pageDesc && (
                    <small className="ms-1 fw-normal">
                      {t(pageAttr?.pageDesc)}
                    </small>
                  )}
                </span>
              </Fragment>
            ) : (
              (pageAttr?.pageTitle || pageAttr?.pageDesc) &&
              pageAttr?.pageTitlePos !== "table-header" && (
                <Fragment>
                  {pageAttr?.menuIcon
                    ? renderIcon(
                        pageAttr?.menuIcon,
                        "me-1 align-middle",
                        20,
                        {}
                      )
                    : null}
                  <span className="fw-bold align-middle mb-1">
                    <h4
                      style={{
                        ...(pageAttr?.cardTitleStyle ??
                          pageAttr?.pageTitleStyle ??
                          {})
                      }}
                    >
                      {t(pageAttr?.pageTitle)}
                    </h4>
                    {pageAttr?.pageDesc && (
                      <small
                        style={{
                          ...(pageAttr?.cardDescStyle ??
                            pageAttr?.pageDescStyle ??
                            {})
                        }}
                        className="fw-normal mb-1"
                      >
                        {t(pageAttr?.pageDesc)}
                      </small>
                    )}
                  </span>
                </Fragment>
              )
            )
          ) : null}

          <div className="app-user-list">
            {/* SUMMARY CARDS  */}
            {!lookupMode &&
              pageAttr?.crudOpt?.getApiSummaryData &&
              !pageAttr?.onDashboard &&
              !relPageId &&
              (!store.pageData[storeNameParent ?? storeName]
                ?.isLoadingSummary ? (
                <Row>
                  {store.pageData[storeNameParent]?.dataSummary?.map(
                    (item, index) => {
                      return (
                        <Col
                          className="card-summary-crud"
                          key={`card_${storeNameParent}_${index}`}
                        >
                          <StatsHorizontal
                            color={item.color ?? colors[index]}
                            cardColor={item.cardColor}
                            statTitle={`${t(item.title)}`}
                            icon={
                              item.icon ? (
                                renderIcon(
                                  item.icon,
                                  `${item.class} saba-fade-in-out`,
                                  20
                                )
                              ) : (
                                <Icon
                                  baseClassName="material-icons-outlined saba-fade-in-out"
                                  fontSize="20"
                                >
                                  lightbulb
                                </Icon>
                              )
                            }
                            // stats={item.count_format ?? item.count}
                            stats={
                              // <h3
                              //   // {...(item?.cardColor
                              //   //   ? { style: { color: "#FFF" } }
                              //   //   : {})}
                              //   className="fw-bolder mb-75"
                              // >
                              `${item.count_format ?? item.count}`
                              // </h3>
                            }
                          />
                        </Col>
                      )
                    }
                  )}
                </Row>
              ) : (
                <Row>
                  {[1, 2, 3, 4]?.map((item, index) => {
                    return (
                      <Col key={`card_${storeNameParent}_${index}`}>
                        <StatsHorizontal
                          renderStats={
                            <Fragment>
                              <div>
                                <Skeleton
                                  animation="wave"
                                  height={25}
                                  width={25}
                                  className="fw-bolder mb-75"
                                />
                                <Skeleton
                                  animation="wave"
                                  height={25}
                                  width={100}
                                  className="card-text"
                                />
                              </div>
                              <div
                                // style={{
                                //   backgroundColor: "#ebebeb"
                                // }}
                                className="avatar avatar-stats avatar-stats-shimmer p-50 m-0"
                              >
                                <Skeleton
                                  animation="wave"
                                  variant="circular"
                                  width={30}
                                  height={30}
                                />
                              </div>
                            </Fragment>
                          }
                        />
                      </Col>
                    )
                  })}
                </Row>
              ))}

            {/* FILTERS  */}
            {isDynamicColumnsFilter() &&
              pageAttr?.crudOpt?.dynamicColumnsFilterPos !== "table-header" &&
              filtersComponents()}
            {/* {dynamicFiltersComponents} */}

            <UILoader
              blocking={
                (loadingFirst || store?.pageData?.[storeName]?.isLoading) &&
                store.pageAttributes[storeName]?.crudOpt?.gridTheme &&
                store.pageAttributes[storeName]?.crudOpt?.gridTheme !==
                  "table" &&
                store.pageAttributes[storeName]?.crudOpt?.gridTheme !==
                  "timeline"
              }
              // blocking={true}
              loader={<Loader dataName={pageAttr?.pageTitle} />}
            >
              {storeDeveloper.editMode &&
                !pageAttr?.isViewOnForm &&
                !pageAttr?.onDashboard &&
                (!window.location.pathname.includes("/dyn_pages/_admin_") ||
                  !window.location.pathname.includes("/apps/menu") ||
                  isAdminApp()) && (
                  <div className="d-none d-md-flex mb-1 justify-content-end container-dev-mode-menu-head">
                    {store.pageAttributes[storeName]?.crudOpt?.data
                      ?.dynamicColumns && (
                      <Fragment>
                        {store.pageAttributes[storeName]?.crudOpt?.gridTheme ===
                          "grid_card" && (
                          <Button
                            dev-mode-header={'{"title": "Grid Card Setting"}'}
                            dev-mode-data={[
                              "crudOpt.gridSizeLg",
                              "crudOpt.gridSizeMd",
                              "crudOpt.gridSizeSm",
                              "crudOpt.gridImgField",
                              "crudOpt.gridTitleField",
                              "crudOpt.gridSubTitleField",
                              "crudOpt.detailFromGrid",
                              "crudOpt.detailPage",
                              "crudOpt.disabledAdd",
                              "crudOpt.disabledEdit",
                              "crudOpt.disabledDelete",
                              "crudOpt.rowClickEvent",
                              "crudOpt.getAlwaysGrid",
                              "crudOpt.hideTablePageLengthInfo",
                              "crudOpt.hideTablePagination",
                              "crudOpt.hideTableSearchInput"
                            ]}
                            dev-mode-data-root="crudOpt"
                            color="relief-dark"
                            className="ms-50 btn-sm editmode_hover_edit"
                          >
                            {t("Grid Setting")}
                          </Button>
                        )}

                        {(!store.pageAttributes[storeName]?.crudOpt
                          ?.gridTheme ||
                          store.pageAttributes[storeName]?.crudOpt
                            ?.gridTheme === "table") && (
                          <Button
                            dev-mode-header={'{"title": "Table Setting"}'}
                            dev-mode-data={[
                              "crudOpt.detailFromGrid",
                              "crudOpt.detailPage",
                              "crudOpt.disabledAdd",
                              "crudOpt.disabledEdit",
                              "crudOpt.disabledDelete",
                              "crudOpt.rowClickEvent",
                              "crudOpt.getAlwaysGrid",
                              "crudOpt.hideTablePageLengthInfo",
                              "crudOpt.hideTablePagination",
                              "crudOpt.hideTableSearchInput"
                            ]}
                            dev-mode-data-root="crudOpt"
                            color="relief-dark"
                            className="ms-50 btn-sm editmode_hover_edit"
                          >
                            {t("Table Setting")}
                          </Button>
                        )}

                        <Button
                          dev-mode-header={
                            '{"title": "Dynamic Columns Properties"}'
                          }
                          dev-mode-data={["crudOpt.dynamicColumns"]}
                          dev-mode-data-code-line=""
                          dev-mode-data-root="dynamicColumns"
                          color="relief-dark"
                          className="ms-50 btn-sm editmode_hover_edit"
                        >
                          {t("Columns")}
                        </Button>
                      </Fragment>
                    )}

                    {store.pageAttributes[storeName]?.crudOpt &&
                      Object.keys(store.pageAttributes[storeName]?.crudOpt)
                        ?.length > 0 && (
                        <Button
                          dev-mode-header={
                            '{"title": "Set Element Properties"}'
                          }
                          dev-mode-data={[
                            "crudOpt.gridTheme",
                            "crudOpt.sourceDataFrom",
                            "crudOpt.endpointName",
                            "crudOpt.resp_key",
                            "crudOpt.removeDefaultHttpHeader",
                            "crudOpt.data_idField"
                          ]}
                          dev-mode-data-root="crudOpt"
                          color="relief-dark"
                          className="ms-50 btn-sm editmode_hover_edit"
                        >
                          {t("Edit Element")}
                        </Button>
                      )}
                  </div>
                )}

              {renderPage()}

              <div id={`data_frame_${storeName}`}>
                {store.dataFrame[storeName] && (
                  <Card
                    style={{
                      borderRadius: "8px"
                    }}
                    title={t("Detail Data Viewer")}
                    // actions={["collapse", "remove"]}
                  >
                    <Button.Ripple
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "-10px",
                        zIndex: 1
                      }}
                      onClick={() =>
                        dispatch(
                          setDataFrame({
                            storeName: storeName,
                            data: null
                          })
                        )
                      }
                      className="btn-icon btn-sm p-0"
                      color="dark"
                    >
                      <X size={20} />
                    </Button.Ripple>
                    <CardBody
                      className="p-0 m-0"
                      style={{
                        lineHeight: "normal"
                      }}
                    >
                      <iframe
                        title={t("Detail Data Viewer Content")}
                        style={{ width: "100%", minHeight: "600px" }}
                        src={store.dataFrame[storeName]}
                        frameBorder="0"
                      >
                        {t(
                          "Your browser does not support embedded PDF files. You can download the file"
                        )}
                        <a href="http://www.pdf995.com/samples/pdf.pdf">
                          {t("here")}
                        </a>
                        .
                      </iframe>
                    </CardBody>
                  </Card>
                )}

                {store.dataFrameHtml[storeName] && (
                  <Card
                    style={{
                      borderRadius: "8px"
                    }}
                    title={t("Detail Data Viewer")}
                    // actions={["collapse", "remove"]}
                  >
                    <Button.Ripple
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "-10px",
                        zIndex: 1
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        printDiv("print_view_area")
                      }}
                      className="btn-sm p-25"
                      color="primary"
                    >
                      <Printer size={16} />
                      <span className="align-middle ms-25">{t("Print")}</span>
                    </Button.Ripple>

                    <Button.Ripple
                      style={{
                        position: "absolute",
                        right: "-12px",
                        top: "-10px",
                        zIndex: 1
                      }}
                      onClick={() =>
                        dispatch(
                          setDataFrameHtml({
                            storeName: storeName,
                            data: null
                          })
                        )
                      }
                      className="btn-icon btn-sm p-25"
                      color="dark"
                    >
                      <X size={16} />
                    </Button.Ripple>
                    <CardBody
                      className="p-0 m-0"
                      style={{
                        lineHeight: "normal"
                      }}
                    >
                      <div
                        id="print_view_area"
                        style={{ width: "100%" }}
                        dangerouslySetInnerHTML={{
                          __html: store.dataFrameHtml[storeName]
                        }}
                      ></div>
                    </CardBody>
                  </Card>
                )}

                <Modal
                  isOpen={store.dataFrameHtmlModal[storeName]}
                  toggle={() =>
                    dispatch(
                      setDataFrameHtmlModal({
                        storeName: storeName,
                        data: null
                      })
                    )
                  }
                  scrollable
                  className={`modal-dialog-centered modal-xl`}
                >
                  <ModalHeader
                    className="bg-transparent"
                    toggle={() =>
                      dispatch(
                        setDataFrameHtmlModal({
                          storeName: storeName,
                          data: null
                        })
                      )
                    }
                  ></ModalHeader>
                  <ModalBody
                    className="p-0 m-0"
                    style={{
                      lineHeight: "normal"
                    }}
                  >
                    <div
                      id="print_view_area"
                      style={{ width: "100%" }}
                      dangerouslySetInnerHTML={{
                        __html: store.dataFrameHtmlModal[storeName]
                      }}
                    ></div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="flat-secondary"
                      onClick={() =>
                        dispatch(
                          setDataFrameHtmlModal({
                            storeName: storeName,
                            data: null
                          })
                        )
                      }
                    >
                      {t("Cancel")}
                    </Button>
                    <Button.Ripple
                      onClick={(e) => {
                        e.preventDefault()
                        printDiv("print_view_area")
                      }}
                      color="primary"
                    >
                      <Printer size={16} />
                      <span className="align-middle ms-25">{t("Print")}</span>
                    </Button.Ripple>
                  </ModalFooter>
                </Modal>
              </div>
            </UILoader>
          </div>

          {showModalDevState && devModeEnabled && (
            <ModalAddEditDev
              root={modalDevEditRoot}
              headers={modalDevEditHeader}
              fieldArray={modalDevEditData}
              setFieldArray={setModalDevEditData}
              show={showModalDevState}
              setShow={setShowModalDevState}
              storeName={storeName}
              pageAttr={store.pageAttributes[storeName]}
              submitHandle={saveJson2}
            />
          )}

          <Modal
            isOpen={store.showModalImport[storeName]}
            toggle={handleResetImportModal}
            className={`modal-dialog-centered modal-xl`}
          >
            <ModalHeader
              className="bg-transparent"
              toggle={handleResetImportModal}
            ></ModalHeader>
            <ModalBody className="pb-3">
              <ModalImport
                storeName={storeName}
                pageAttr={pageAttr}
                setPageAttr={setPageAttr}
              />
            </ModalBody>
          </Modal>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Card
            style={{
              backgroundColor: "#1e1e1e",
              color: "#FEFBF8"
            }}
            title="JSON Editor"
            id="card_json_dev"
          >
            <CardHeader className="pt-1 pb-1 justify-content-beetween">
              <CardTitle>{t("JSON Editor")}</CardTitle>

              <div className="justify-content-end">
                {codeNavigationOpt && (
                  <UncontrolledButtonDropdown>
                    <DropdownToggle size="sm" color="flat-dark" caret>
                      {t("Navigate to")}
                    </DropdownToggle>
                    <DropdownMenu>
                      {codeNavigationOpt.map((item) => (
                        <DropdownItem
                          onClick={(e) => editorGoTo(e, item)}
                          tag="a"
                        >
                          {item.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
                {foldAll ? (
                  <Button.Ripple
                    onClick={(e) => foldEvent(e, false)}
                    color="relief-dark"
                    className="btn-sm ms-50"
                  >
                    {renderIcon("material:unfold_less", "align-middle", 12)}
                    <span className="align-middle ms-25">{t("Unfold")}</span>
                  </Button.Ripple>
                ) : (
                  <Button.Ripple
                    onClick={(e) => foldEvent(e, true)}
                    color="relief-dark"
                    className="btn-sm ms-50"
                  >
                    {renderIcon("material:unfold_more", "align-middle", 12)}
                    <span className="align-middle ms-25">{t("Fold All")}</span>
                  </Button.Ripple>
                )}

                <Button.Ripple
                  style={{
                    padding: "0.2rem"
                  }}
                  onClick={(e) =>
                    !isFullscreen ? requestFullscreen() : exitFullscreen()
                  }
                  className="btn-icon btn-sm ms-50"
                  color="relief-dark"
                >
                  {!isFullscreen ? (
                    <Fullscreen size={13} />
                  ) : (
                    <FullscreenExit size={13} />
                  )}
                </Button.Ripple>
                {!storeDeveloper.pageAttributes[storeName] ? (
                  <Button.Ripple
                    onClick={(e) => setEditModeJsonState(e)}
                    color="relief-dark"
                    className="btn-sm btn-icon btn-dev-mode-json"
                  >
                    {renderIcon("material:data_object", "align-middle", 12)}
                    <span className="align-middle ms-25">JSON</span>
                  </Button.Ripple>
                ) : (
                  <Fragment>
                    <Button.Ripple
                      onClick={(e) => setEditModeJsonState(e, true)}
                      color="relief-dark"
                      className="btn-sm btn-icon ms-50"
                    >
                      {renderIcon("material:close", "align-middle", 12)}
                      <span className="align-middle ms-25">{t("Cancel")}</span>
                    </Button.Ripple>
                    {storeDeveloper.pageAttributesChanged[storeName] !==
                      storeDeveloper.pageAttributesBackup[storeName] &&
                      storeDeveloper.pageAttributesChanged[storeName] && (
                        <Button.Ripple
                          disabled={!editorValid}
                          onClick={(e) => previewJson(e)}
                          color="relief-warning"
                          className="btn-sm ms-50"
                        >
                          {renderIcon("material:preview", "align-middle", 12)}
                          <span className="align-middle ms-25">
                            {t("Preview")}
                          </span>
                        </Button.Ripple>
                      )}

                    {/* ////UNDO */}
                    {pageAttrLastChanges && (
                      <Button.Ripple
                        onClick={undo}
                        color="relief-dark btn-icon"
                        className="btn-sm ms-50"
                      >
                        {renderIcon("material:undo", "align-middle", 14)}
                      </Button.Ripple>
                    )}

                    {/* ////REDO */}
                    {pageAttrAfterChanges && (
                      <Button.Ripple
                        onClick={redo}
                        color="relief-dark btn-icon"
                        className="btn-sm ms-50"
                      >
                        {renderIcon("material:redo", "align-middle", 14)}
                      </Button.Ripple>
                    )}

                    {((restrictedMenuLink.includes(window.location.pathname) &&
                      [4, 6, 21].includes(userData.id)) ||
                      !restrictedMenuLink.includes(
                        window.location.pathname
                      )) && (
                      <Button.Ripple
                        disabled={!editorValid}
                        onClick={(e) => saveJson(e)}
                        color="relief-primary"
                        className="btn-sm ms-50"
                      >
                        {renderIcon("material:save", "align-middle", 12)}
                        <span className="align-middle ms-25">{t("Save")}</span>
                      </Button.Ripple>
                    )}
                  </Fragment>
                )}
              </div>
            </CardHeader>
            <CardBody>
              {/* <div
                id="json-diagram-dev"
                className="justify-content-center json-diagram-dev"
              >
                <ul>
                  <JsonDiagramDev
                    data={JSON.parse(
                      storeDeveloper.pageAttributesChanged[storeName] ??
                        storeDeveloper.pageAttributes[storeName]
                    )}
                    name="page"
                  />
                </ul>
              </div> */}
              <Editor
                height={!isFullscreen ? "75vh" : "100%"}
                language="json"
                theme="vs-dark"
                defaultValue={storeDeveloper.pageAttributesChanged[storeName]}
                value={
                  storeDeveloper.pageAttributesChanged[storeName] ??
                  storeDeveloper.pageAttributes[storeName]
                }
                onChange={handleEditorChange}
                onValidate={handleEditorValidation}
                options={{
                  inlineSuggest: true,
                  fontSize: "13px",
                  formatOnType: true,
                  formatOnPaste: true,
                  autoClosingBrackets: true,
                  minimap: { scale: 25, maxColumn: 50 },
                  showFoldingControls: "always",
                  wordWrap: true
                }}
                // beforeMount={handleEditorWillMount}
                onMount={handleEditorDidMount}
              />
            </CardBody>
          </Card>
        </Fragment>
      )
    }
  } else {
    return loadingFirst || store?.pageData?.[storeName]?.isLoading ? (
      <Spinner isFromDynPage={true} />
    ) : !storeDeveloper.pageAttributes[storeName] ? (
      <Fragment>
        {devModeEnabled && (
          <div className="d-none d-md-flex mb-1 justify-content-end">
            {storeDeveloper.pageAttributesBackup[storeName] && (
              <Button color="flat-warning" className="btn-sm" disabled>
                {renderIcon("material:preview", "align-middle", 12)}
                <span className="align-middle ms-25">{t("Preview")}</span>
              </Button>
            )}
            <Button
              onClick={(e) => setEditModeJsonState(e)}
              color="relief-dark"
              className="btn-sm btn-dev-mode-json"
            >
              {renderIcon("material:data_object", "align-middle", 12)}
              <span className="align-middle ms-25">JSON</span>
            </Button>
          </div>
        )}
        <BlankPage />
      </Fragment>
    ) : (
      <Fragment>
        <Card
          style={{
            backgroundColor: "#1e1e1e",
            color: "#FEFBF8"
          }}
          title="JSON Editor"
          id="card_json_dev"
        >
          <CardHeader className="pt-1 pb-1 justify-content-beetween">
            <CardTitle>JSON Editor</CardTitle>
            <div className="justify-content-end">
              {/* <Button.Ripple
                onClick={(e) => editorGoTo(e, "dynamicColumnsFilter")}
                color="relief-dark"
                className="btn-sm ms-50"
              >
                dynamicColumnsFilter
              </Button.Ripple> */}

              {codeNavigationOpt && (
                <UncontrolledButtonDropdown>
                  <DropdownToggle size="sm" color="flat-dark" caret>
                    {t("Navigate to")}
                  </DropdownToggle>
                  <DropdownMenu>
                    {codeNavigationOpt.map((item) => (
                      <DropdownItem
                        onClick={(e) => editorGoTo(e, item)}
                        tag="a"
                      >
                        {item.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}

              {foldAll ? (
                <Button.Ripple
                  onClick={(e) => foldEvent(e, false)}
                  color="relief-dark"
                  className="btn-sm ms-50"
                >
                  {renderIcon("material:unfold_less", "align-middle", 12)}
                  <span className="align-middle ms-25">{t("Unfold")}</span>
                </Button.Ripple>
              ) : (
                <Button.Ripple
                  onClick={(e) => foldEvent(e, true)}
                  color="relief-dark"
                  className="btn-sm ms-50"
                >
                  {renderIcon("material:unfold_more", "align-middle", 12)}
                  <span className="align-middle ms-25">{t("Fold All")}</span>
                </Button.Ripple>
              )}

              <Button.Ripple
                style={{
                  padding: "0.2rem"
                }}
                onClick={(e) =>
                  !isFullscreen ? requestFullscreen() : exitFullscreen()
                }
                className="btn-icon btn-sm ms-50"
                color="relief-dark"
              >
                {!isFullscreen ? (
                  <Fullscreen size={13} />
                ) : (
                  <FullscreenExit size={13} />
                )}
              </Button.Ripple>

              {!storeDeveloper.pageAttributes[storeName] ? (
                <Button.Ripple
                  onClick={(e) => setEditModeJsonState(e)}
                  color="relief-dark"
                  className="btn-sm btn-icon btn-dev-mode-json"
                >
                  {renderIcon("material:data_object", "align-middle", 12)}
                  <span className="align-middle ms-25">JSON</span>
                </Button.Ripple>
              ) : (
                <Fragment>
                  <Button.Ripple
                    onClick={(e) => setEditModeJsonState(e, true)}
                    color="relief-dark"
                    className="btn-sm btn-icon ms-50"
                  >
                    {renderIcon("material:close", "align-middle", 12)}
                    <span className="align-middle ms-25">{t("Cancel")}</span>
                  </Button.Ripple>
                  {storeDeveloper.pageAttributesChanged[storeName] !==
                    storeDeveloper.pageAttributesBackup[storeName] &&
                    storeDeveloper.pageAttributesChanged[storeName] && (
                      <Button.Ripple
                        disabled={!editorValid}
                        onClick={(e) => previewJson(e)}
                        color="relief-warning"
                        className="btn-sm ms-50"
                      >
                        {renderIcon("material:preview", "align-middle", 12)}
                        <span className="align-middle ms-25">
                          {t("Preview")}
                        </span>
                      </Button.Ripple>
                    )}
                  {((restrictedMenuLink.includes(window.location.pathname) &&
                    [4, 6, 21].includes(userData.id)) ||
                    !restrictedMenuLink.includes(window.location.pathname)) && (
                    <Button.Ripple
                      disabled={!editorValid}
                      onClick={(e) => saveJson(e)}
                      color="relief-primary"
                      className="btn-sm ms-50"
                    >
                      {renderIcon("material:save", "align-middle", 12)}
                      <span className="align-middle ms-25">{t("Save")}</span>
                    </Button.Ripple>
                  )}
                </Fragment>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <Editor
              height={!isFullscreen ? "75vh" : "100%"}
              language="json"
              theme="vs-dark"
              defaultValue={storeDeveloper.pageAttributesChanged[storeName]}
              value={
                storeDeveloper.pageAttributesChanged[storeName] ??
                storeDeveloper.pageAttributes[storeName]
              }
              onChange={handleEditorChange}
              onValidate={handleEditorValidation}
              options={{
                inlineSuggest: true,
                fontSize: "13px",
                formatOnType: true,
                formatOnPaste: true,
                autoClosingBrackets: true,
                minimap: { scale: 25, maxColumn: 50 },
                showFoldingControls: "always",
                wordWrap: true
              }}
              // beforeMount={handleEditorWillMount}
              onMount={handleEditorDidMount}
            />
          </CardBody>
        </Card>
      </Fragment>
    )
  }
}

export default DynamicPage
