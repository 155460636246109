// ** Redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import axios from "axios"
import useJwt from "@src/auth/jwt/useJwt"

export const getDomainInfo = createAsyncThunk(
  "getDomainInfo",
  async ({ rejectWithValue }) => {
    // console.log("GET INIT DOMAIN-INFO START...")
    if (JSON.parse(localStorage.getItem("domainClaim")) !== null) {
      const domainClaim = await JSON.parse(localStorage.getItem("domainClaim"))
      // console.log("GET INIT DOMAIN-INFO SUCCESS")
      return domainClaim
    } else {
      try {
        const response = await useJwt.ssoAxiosInterceptor.post(`domain_claims`)
        localStorage.setItem("domainClaim", JSON.stringify(response.data.data))
        // console.log("GET INIT DOMAIN-INFO SUCCESS")
        return response.data.data
      } catch (error) {
        // console.error("GET INIT DOMAIN-INFO FAILED!!!")
        return rejectWithValue(error.response.data)
      }
    }
  }
)

export const updatetDomainInfoLandingPage = createAsyncThunk(
  "updatetDomainInfoLandingPage",
  async (data, { rejectWithValue }) => {
    try {
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const DomainInfoSlice = createSlice({
  name: "domain_claims",
  initialState: {
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    dataAll: [],
    data: {
      app: {
        name: process.env.REACT_APP_BASENAME,
        info: null,
        logo: {},
        setting: {}
      },
      unit: {
        name: "Demo",
        code: null,
        attributes: {}
      }
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDomainInfo.pending, (state) => {
        state.status = "loading"
        state.isLoading = true
      })
      .addCase(getDomainInfo.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.isLoading = false
        state.data = action.payload
        state.dataAll = action.payload
        document.title = state.data.app.name
        // console.log(action.payload)
      })
      .addCase(getDomainInfo.rejected, (state, action) => {
        state.status = "failed"
        state.isLoading = false
        state.error = action.error.message
      })
      .addCase(updatetDomainInfoLandingPage.fulfilled, (state, action) => {
        // console.log(current(state.data), action.payload)
        let newPayload = JSON.parse(JSON.stringify(action.payload))

        if (!newPayload.hasOwnProperty("data")) {
          newPayload = {
            data: {
              dynamicSection: action.payload
            }
          }
        } else {
          if (!newPayload.data.hasOwnProperty("dynamicSection")) {
            newPayload.data = {
              dynamicSection: action.payload
            }
          }
        }
        state.data["landing_page_attr"] = newPayload
        // console.log(current(state.data))
        // state.data.landing_page_attr.data.dynamicSection =
        localStorage.setItem("domainClaim", JSON.stringify(state.data))
      })
  }
})

export default DomainInfoSlice.reducer
