// ** React Imports
import { Fragment, useEffect, useState, useContext } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
// ** Third Party Components
import classnames from "classnames"
import { useTranslation } from "react-i18next"

// ** Reactstrap Imports
import {
  Collapse,
  Badge,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"

// ** Vertical Menu Items Component
import VerticalNavMenuItems from "./VerticalNavMenuItems"
// ** Utils
import { hasActiveChild, removeChildren } from "@layouts/utils"
import { isAdminApp } from "@utils"
import { MoreVertical, Plus } from "react-feather"
import {
  createNewMenu,
  createNewSubMenu,
  deleteMenu,
  renderIcon,
  updateMenu,
  updateMenuSub,
  updateMenuSortToApi
} from "@src/views/apps/menu/store/my_menu"
import { ReactSortable } from "react-sortablejs"
import { setStorePageAttributes } from "@src/shared/dynamic_pages/store"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import toast from "react-hot-toast"
import ModalAddEdit from "./modal"
const MySwal = withReactContent(Swal)
import { ThemeColors } from "@src/utility/context/ThemeColors"

const VerticalNavMenuGroup = ({
  item,
  groupOpen,
  menuHover,
  activeItem,
  parentItem,
  groupActive,
  setGroupOpen,
  menuCollapsed,
  setGroupActive,
  currentActiveGroup,
  setCurrentActiveGroup,
  devMode,
  ...rest
}) => {
  // ** Hooks
  const { t } = useTranslation()
  const location = useLocation()

  // ** Current Val
  const currentURL = useLocation().pathname
  const storeDev = useSelector((state) => state.developer)
  const storeMyMenu = useSelector((state) => state.my_menu)
  const dynamicStore = useSelector((state) => state.dynamicStore)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [currentMenuData, setCurrentMenuData] = useState(null)
  const [currentOriginalMenuData, setCurrentOriginalMenuData] = useState(null)
  const [showModalRename, setShowModalRename] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [relatedParentId, setRelatedParentId] = useState(null)
  const [currentItemMoved, setCurrentItemMoved] = useState(null)
  const { colors } = useContext(ThemeColors)
  // ** Toggle Open Group
  const toggleOpenGroup = (item, parent) => {
    let openGroup = groupOpen
    const activeGroup = groupActive

    // ** If Group is already open and clicked, close the group
    if (openGroup.includes(item.id)) {
      openGroup.splice(openGroup.indexOf(item.id), 1)

      // ** If clicked Group has open group children, Also remove those children to close those groups
      if (item.children) {
        removeChildren(item.children, openGroup, groupActive)
      }
    } else if (
      activeGroup.includes(item.id) ||
      currentActiveGroup.includes(item.id)
    ) {
      // ** If Group clicked is Active Group

      // ** If Active group is closed and clicked again, we should open active group else close active group
      if (
        !activeGroup.includes(item.id) &&
        currentActiveGroup.includes(item.id)
      ) {
        activeGroup.push(item.id)
      } else {
        activeGroup.splice(activeGroup.indexOf(item.id), 1)
      }

      // ** Update Active Group
      setGroupActive([...activeGroup])
    } else if (parent) {
      // ** If Group clicked is the child of a open group, first remove all the open groups under that parent
      if (parent.children) {
        removeChildren(parent.children, openGroup, groupActive)
      }

      // ** After removing all the open groups under that parent, add the clicked group to open group array
      if (!openGroup.includes(item.id)) {
        openGroup.push(item.id)
      }
    } else {
      // ** If clicked on another group that is not active or open, create openGroup array from scratch

      // ** Empty Open Group array
      openGroup = []

      // ** Push current clicked group item to Open Group array
      if (!openGroup.includes(item.id)) {
        openGroup.push(item.id)
      }
    }
    setGroupOpen([...openGroup])
  }

  // ** On Group Item Click
  const onCollapseClick = (e, item) => {
    toggleOpenGroup(item, parentItem)

    e.preventDefault()
  }

  // ** Checks url & updates active item
  useEffect(() => {
    if (hasActiveChild(item, currentURL)) {
      if (!groupActive.includes(item.id)) groupActive.push(item.id)
    } else {
      const index = groupActive.indexOf(item.id)
      if (index > -1) groupActive.splice(index, 1)
    }
    setGroupActive([...groupActive])
    setCurrentActiveGroup([...groupActive])
    setGroupOpen([])
  }, [location])

  // ** Returns condition to add open class
  const openClassCondition = (id) => {
    if ((menuCollapsed && menuHover) || menuCollapsed === false) {
      if (groupActive.includes(id) || groupOpen.includes(id)) {
        return true
      }
    } else if (
      groupActive.includes(id) &&
      menuCollapsed &&
      menuHover === false
    ) {
      return false
    } else {
      return null
    }
  }

  useEffect(() => {
    document.body.style.paddingRight = "0"
    return () => {
      document.body.style.paddingRight = ""
    }
  }, [])

  const doOrder = (newState, sortable, store) => {
    if (
      store.dragging &&
      store.dragging.props &&
      JSON.stringify(item.children) !== JSON.stringify(newState) &&
      !storeMyMenu.isLoadingPostPut
      // JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState)
    ) {
      console.log("doOrder2", newState)
      // console.log(sortable)
      // console.log(newState)
      // const sameCount = item.children.length === newState.length
      // let itemMoved = {}
      // if (!sameCount) {
      //   const differenceArray = item.children.filter(
      //     (item1) => !newState.find((item2) => item1.id === item2.id)
      //   )
      //   if (differenceArray) {
      //     itemMoved = differenceArray[0]
      //   }

      // }
      // console.log(storeMyMenu.dataMenu)
      // const newItem = JSON.parse(JSON.stringify(item))
      // newItem.children = newState
      // const copyStore = JSON.parse(JSON.stringify(storeMyMenu.dataMenu))
      const newItem = { ...item, children: newState }
      // const newStateWChild = [...copyStore, newItem]
      const updatedArray = storeMyMenu.dataMenu.map((item) => {
        if (item.id === newItem.id) {
          return { ...item, ...newItem }
        }
        return item
      })
      // console.log(updatedArray)
      // e.preventDefault()
      dispatch(updateMenuSub(newItem)).then((res) => {
        console.log(res)
      })
      // }
    }
  }

  const createNewMenuHandle = (e, item) => {
    e.stopPropagation()
    e.preventDefault()
    // console.log(`createNewMenu`, before_id)
    dispatch(createNewMenu(item)).then((res) => {
      // console.log(res)
      if (dynamicStore && res.meta.requestStatus === "fulfilled") {
        const newStoreName = res?.payload?.storeName
        const pageTitle = res?.payload?.pageTitle
        const defaultPageAttr = {
          pageType: "crud",
          pageTitle: pageTitle,
          crudOpt: {}
        }
        dispatch(
          setStorePageAttributes({
            storeName: newStoreName,
            data: defaultPageAttr
          })
        ).then((data) => {
          navigate(res?.payload?.routePath)
        })
      }
    })
  }

  const doRename_before = (e, item) => {
    // console.log("here")
    // e.stopPropagation()
    e.preventDefault()
    // console.log(e, item)
    setCurrentMenuData(item)

    const itemInStore = storeMyMenu?.dataMyMenu?.find(
      (i) => i?.id === parseInt(item.id?.replace("menu_", ""))
    )
    setCurrentOriginalMenuData(itemInStore)
    setShowModalRename(!showModalRename)
  }

  const addChild = (e, item) => {
    e.stopPropagation()
    e.preventDefault()
    dispatch(createNewSubMenu(item)).then((res) => {
      if (dynamicStore && res.meta.requestStatus === "fulfilled") {
        const newStoreName = res?.payload?.storeName
        const pageTitle = res?.payload?.pageTitle
        const defaultPageAttr = {
          pageType: "crud",
          pageTitle: pageTitle,
          crudOpt: {}
        }
        dispatch(
          setStorePageAttributes({
            storeName: newStoreName,
            data: defaultPageAttr
          })
        ).then((data) => {
          // setTimeout(() => {
          //   setGroupOpen([...groupOpen])
          // }, 50)
          navigate(res?.payload?.routePath)
        })
      }
    })
  }

  return (
    <Fragment>
      <li
        data-id={item?.id}
        className={classnames("nav-item has-sub", {
          open: openClassCondition(item.id),
          "menu-collapsed-open": groupActive.includes(item.id),
          "sidebar-group-active":
            groupActive.includes(item.id) ||
            groupOpen.includes(item.id) ||
            currentActiveGroup.includes(item.id),
          "dev-mode-header": storeDev.editMode,
          "disabled-drag":
            (item?.navLink?.includes("/dyn_pages/_admin_") ||
              item?.navLink?.includes("/apps/menu")) &&
            !isAdminApp()
        })}
      >
        <Link
          className="d-flex align-items-center"
          to="/"
          onClick={(e) => onCollapseClick(e, item)}
        >
          {storeDev.editMode &&
            !(
              (item?.navLink?.includes("/dyn_pages/_admin_") ||
                item?.navLink?.includes("/apps/menu")) &&
              !isAdminApp()
            ) && (
              <Fragment>
                <div className="dev-mode-plus-header">
                  <Button.Ripple
                    onClick={(e) => createNewMenuHandle(e, item)}
                    className="btn-icon rounded-circle"
                    color="primary"
                  >
                    <Plus className="align-middle" size={12} />
                  </Button.Ripple>
                </div>

                <UncontrolledButtonDropdown
                  direction="up"
                  className="dropdown-icon-wrapper btn-icon btn-sm dev-mode-other-header"
                  onClick={(e) => {
                    // console.log("UncontrolledButtonDropdown")
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  <DropdownToggle
                    color="flat-secondary"
                    caret
                    className="btn-icon btn-sm dropdown-toggle-split"
                    onClick={(e) => {
                      // console.log("toggle")
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <MoreVertical className="align-middle" size={12} />
                  </DropdownToggle>
                  <DropdownMenu tag="div" end>
                    <DropdownItem
                      onClick={(e) => doRename_before(e, item)}
                      href="/"
                      tag="a"
                    >
                      {renderIcon(
                        "material:drive_file_rename_outline",
                        "align-middle me-25",
                        18
                      )}
                      {t("Rename")}
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => addChild(e, item)}
                      href="/"
                      tag="a"
                    >
                      {renderIcon(
                        "material:subdirectory_arrow_right",
                        "align-middle me-25",
                        18
                      )}
                      {t("Add Child")}
                    </DropdownItem>
                    {/* <DropdownItem
                      onClick={(e) => doDelete_before(e, item)}
                      href="/"
                      tag="a"
                    >
                      {renderIcon(
                        "material:delete_outline",
                        "align-middle me-25",
                        18
                      )}
                      {t("Delete")}
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Fragment>
            )}

          {item.icon}
          <span
            style={
              menuCollapsed && !menuHover
                ? {}
                : { whiteSpace: "normal", overflowWrap: "anywhere" }
            }
            // className="menu-title"
            className={`menu-title ${
              !menuCollapsed && !menuHover ? "" : "text-truncate"
            }`}
          >
            {t(item.title)}
          </span>

          {item.badge && item.badgeText ? (
            <Badge className="ms-auto me-1" color={item.badge} pill>
              {item.badgeText}
            </Badge>
          ) : null}
        </Link>

        {/* Render Child Recursively Through VerticalNavMenuItems Component */}
        {storeDev.editMode ? (
          <ul className="menu-content">
            <Collapse
              isOpen={
                (groupActive && groupActive.includes(item.id)) ||
                (groupOpen && groupOpen.includes(item.id))
              }
            >
              <ReactSortable
                dataIdAttr="data-id"
                group={{ name: `group_${item.id}`, pull: true, put: "array" }}
                animation={150}
                fallbackOnBody={true}
                swapThreshold="0.65"
                delay={10}
                // delayOnTouchStart={true}
                tag="div"
                className={`group_${item.id}`}
                list={item.children}
                filter=".disabled-drag"
                setList={doOrder}
                // nested={true}
                // draggable=".nav-item"
                onEnd={(evt) => {
                  // console.log(evt)
                  let itemEl = evt.item
                  const idEl = itemEl.getAttribute("data-id")
                  const id = parseInt(idEl?.replace("menu_", ""))

                  let parToApi = {
                    id: id,
                    index: evt.newIndex
                  }
                  let from = evt?.from?.getAttribute("class")
                  let to = evt?.to?.getAttribute("class")
                  // console.log(from)
                  // console.log(to)
                  if (from !== to) {
                    let from_parent_id = parseInt(
                      from.replace("group_menu_", "")
                    )
                    // let to_parent_id = parseInt(to.replace("group_menu_", ""))

                    if (relatedParentId) {
                      let to_parent_id = parseInt(
                        relatedParentId.replace("menu_", "")
                      )

                      ////UPDATE MENU STORE
                      let itemMoved = {
                        ...item.children?.find((itemEl) => itemEl.id === idEl)
                      }
                      itemMoved["parent_id"] = relatedParentId
                      // console.log(itemMoved)
                      setCurrentItemMoved(itemMoved)
                      // console.log("different", from_parent_id, to_parent_id)
                      parToApi["parent_id"] = to_parent_id
                    }
                  }
                  // console.log(parToApi)

                  dispatch(updateMenuSortToApi(parToApi)).then((res) => {
                    console.log(res)
                  })
                }}
                onMove={(evt, originalEvent) => {
                  let relatedParentIdx =
                    evt?.related?.getAttribute("data-parent-id")
                  setRelatedParentId(relatedParentIdx)
                  return true
                }}
              >
                <VerticalNavMenuItems
                  {...rest}
                  items={item.children}
                  groupActive={groupActive}
                  setGroupActive={setGroupActive}
                  currentActiveGroup={currentActiveGroup}
                  setCurrentActiveGroup={setCurrentActiveGroup}
                  groupOpen={groupOpen}
                  setGroupOpen={setGroupOpen}
                  parentItem={item}
                  menuCollapsed={menuCollapsed}
                  menuHover={menuHover}
                  activeItem={activeItem}
                  // devMode={storeDev.editMode}
                  devModeSub={storeDev.editMode}
                />
              </ReactSortable>
            </Collapse>
          </ul>
        ) : (
          <ul className="menu-content">
            <Collapse
              isOpen={
                (groupActive && groupActive.includes(item.id)) ||
                (groupOpen && groupOpen.includes(item.id))
              }
            >
              <VerticalNavMenuItems
                {...rest}
                items={item.children}
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                currentActiveGroup={currentActiveGroup}
                setCurrentActiveGroup={setCurrentActiveGroup}
                groupOpen={groupOpen}
                setGroupOpen={setGroupOpen}
                parentItem={item}
                menuCollapsed={menuCollapsed}
                menuHover={menuHover}
                activeItem={activeItem}
              />
            </Collapse>
          </ul>
        )}
      </li>

      {showModalRename ? (
        <ModalAddEdit
          show={showModalRename}
          setShow={setShowModalRename}
          data={currentOriginalMenuData}
        />
      ) : null}
    </Fragment>
  )
}

export default VerticalNavMenuGroup
