// ** React Imports
import { Fragment, useEffect, useState } from "react"

// ** Third Party Components
import { ReactSortable } from "react-sortablejs"
import { useTranslation } from "react-i18next"
// ** Reactstrap Imports
import {
  Row,
  Col,
  ListGroupItem,
  Input,
  Label,
  Badge,
  Button,
  Collapse
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux"
import { Check, Edit, MoreVertical, Save, X } from "react-feather"
import DynamicForm from "@src/utility/generateForm/dynamicForm"
import { columnsProperties as fieldTypeValue } from "./columnsProps"

const DynamicColumnsDev = ({
  storeName,
  onChangedDynamicColumns,
  pageAttr,
  setIsEditedPropCurrentParent
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)

  const badgeColors = {
    type: "light-danger",
    default: "light-primary"
  }
  const getBadgeColor = (key) => {
    if (!key) return badgeColors["default"]
    return badgeColors[key] ?? badgeColors["default"]
  }

  const [isAutoColumns, setIsAutoColumns] = useState(false)
  const [listArr1, setListArr1] = useState([])
  const [listArr2, setListArr2] = useState([])

  const getDynamicOrAutoColumns = () => {
    let newArr1 = []
    const dynamicColumnsStore =
      store.pageAttributes[storeName]?.crudOpt?.data?.dynamicColumns ?? []
    if (dynamicColumnsStore.length > 0) {
      newArr1 = dynamicColumnsStore?.map((item, index) => {
        return {
          id_gen_: index,
          ...item
        }
      })
    } else {
      /////SET IS AUTO COLUMNS
      setIsAutoColumns(true)
      let columnsResponse = Object.keys(store.pageData[storeName]?.data[0])
      newArr1 = columnsResponse?.map((item, index) => {
        return {
          id_gen_: index,
          fieldName: item,
          columnName: item
        }
      })
    }

    return newArr1
  }

  const beforeOnChangeDynamicColumns = (newState) => {
    // console.log(newState)
    setListArr1(newState)
    // if (newState.length > 0) {
    const newDynamicColumns = newState.map((item) => {
      const newObj = { ...item }
      delete newObj["id_gen_"]
      delete newObj["selected"]
      delete newObj["chosen"]
      return newObj
    })
    onChangedDynamicColumns(newDynamicColumns)
    // }
  }

  const [dynamicForm, setDynamicForm] = useState(null)
  useEffect(() => {
    const dynamicOrAutoColumnsData = getDynamicOrAutoColumns()
    setListArr1(dynamicOrAutoColumnsData)

    if (!isAutoColumns) {
      let columnsResponse = Object.keys(store.pageData[storeName]?.data[0])
      let newArr2 = columnsResponse?.map((item, index) => {
        return {
          id_gen_: index,
          fieldName: item,
          columnName: item
        }
      })
      // console.log(newArr2)
      const filteredNewArr2 = newArr2.filter((item1) =>
        dynamicOrAutoColumnsData.every(
          (item2) => item2.fieldName !== item1.fieldName
        )
      )
      setListArr2(filteredNewArr2 ?? [])
    } else {
      setListArr2([])
    }

    genDynamicForm()
  }, [isAutoColumns])

  const [isEditedProp, setIsEditedProp] = useState(null)
  const [isEditedPropCurrent, setIsEditedPropCurrent] = useState(null)
  const toggleEdit = (item, state) => {
    // console.log(item)

    if (state === false) {
      setIsEditedProp(null)
      setIsEditedPropCurrent(null)
      setIsEditedPropCurrentParent(null)
    } else {
      let isEditedPropCopy = { ...isEditedProp }
      isEditedPropCopy[item?.fieldName] = !isEditedPropCopy[item?.fieldName]
      setIsEditedProp(isEditedPropCopy)
      setIsEditedPropCurrent(item?.fieldName)
      setIsEditedPropCurrentParent(item?.fieldName)
    }
  }

  const genDynamicForm = () => {
    let fieldObj = Object.keys(fieldTypeValue)?.map((key) => {
      // console.log(fieldTypeValue[key])
      return {
        fieldName: key,
        ...fieldTypeValue[key]
      }
    })

    const filteredArray = fieldObj.filter((obj) => {
      if (obj === undefined) {
        return false
      }

      let gridTheme =
        store.pageAttributes[storeName]?.crudOpt?.gridTheme ?? "table"
      return (
        Object.values(obj).every((value) => value !== undefined) &&
        (!obj?.dev_fieldGroup || obj?.dev_fieldGroup === gridTheme)
      )
    })
    // console.log(filteredArray)
    setDynamicForm(filteredArray)
    // return filteredArray
  }

  const genDefaultFormValues = (item) => {
    let newObjFinal = {}
    Object.keys(fieldTypeValue)?.map((key) => {
      newObjFinal["fieldName"] = `${key}`
      newObjFinal[key] = item[key]
    })
    // console.log(newObjFinal)
    return newObjFinal
  }

  const saveColumnAttr = (data) => {
    // console.log(data, isEditedPropCurrent)
    // Remove properties with undefined or "" values
    const cleanData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== undefined && value !== ""
      )
    )
    const listArr1Copy = [...listArr1]
    let currentIndexUpdate = listArr1Copy.findIndex(
      (item) => item?.fieldName === isEditedPropCurrent
    )
    // console.log(currentIndexUpdate)
    if (currentIndexUpdate !== -1) {
      // console.log(listArr1Copy[currentIndexUpdate])
      listArr1Copy[currentIndexUpdate] = {
        ...listArr1Copy[currentIndexUpdate],
        ...cleanData
      }
      // setListArr1(listArr1Copy)
      beforeOnChangeDynamicColumns(listArr1Copy)
    }
    toggleEdit(cleanData, false)
  }

  return (
    <Fragment>
      <p className="mt-1">
        {t(
          "Set what columns will be displayed on the UI and also set the properties if necessary. Drag n drop the column name from Available column to Shown column or vice versa."
        )}
      </p>

      <Row>
        <Col md="8" sm="12">
          <div className="d-flex align-items-end justify-content-between">
            <div className="mt-1">
              <h4 className="mb-0">{t("Shown Column")}</h4>
              <small>
                {t("Columns that are already displayed in the UI (in order)")}
              </small>
            </div>
            <div className="form-check form-check-inline">
              <Input
                disabled
                type="checkbox"
                checked={isAutoColumns && listArr2?.length === 0}
                id="basic-cb-checked"
              />
              <Label for="basic-cb-checked" className="form-check-label">
                {t("Auto Columns")}
              </Label>
            </div>
          </div>

          <ReactSortable
            tag="ul"
            className="list-group list-group-flush sortable dev-columns mt-1"
            group="shared-group"
            list={listArr1}
            setList={beforeOnChangeDynamicColumns}

            // onAdd={onChangeDynamicColumns}
            // onUpdate={onChangeDynamicColumns}
            // onRemove={onChangeDynamicColumns}
            // onEnd={onChangeDynamicColumns}
            // onStart={onChangeDynamicColumns}
            // onMove={onChangeDynamicColumns}
          >
            {listArr1.map((item) => {
              // let columnTitleEnd = item.columnName ?? item.fieldName
              // if (!item.columnName) {
              const columnTitle = (item?.columnName ?? item?.fieldName).replace(
                /([A-Z])/g,
                " $1"
              )
              let columnTitleEnd =
                columnTitle?.charAt(0)?.toUpperCase() + columnTitle?.slice(1)

              if (columnTitleEnd?.includes(".")) {
                columnTitleEnd = columnTitleEnd?.split(".")?.[-1]
              }

              columnTitleEnd = t(
                `${columnTitleEnd?.replaceAll("_", " ")}`
              )?.toUpperCase()
              // }

              return (
                <ListGroupItem
                  className="draggable"
                  key={`columns_pick_${item.id_gen_}`}
                  style={{
                    background:
                      item?.fieldName === isEditedPropCurrent
                        ? "aliceblue"
                        : "inherit"
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="me-1">
                      <h5 className="my-0">{columnTitleEnd}</h5>
                      {/* <small>Key: {item.fieldName}</small> */}
                    </div>
                    <div className="ms-1 d-flex">
                      {/* <small>
                        Column Title: {item.columnName ?? item.fieldName}
                      </small> */}
                      {Object.keys(item).map((key2) => {
                        if (!["id_gen_", "selected", "chosen"].includes(key2)) {
                          return (
                            <span className="ms-50 d-flex align-items-center">
                              <Badge color={getBadgeColor(key2)}>
                                <small
                                  style={{ fontSize: "0.7rem" }}
                                  className="fw-bold"
                                >
                                  {key2}
                                </small>
                                <small style={{ fontSize: "0.7rem" }}>: </small>
                                <small style={{ fontSize: "0.7rem" }}>
                                  {item[key2] === true ? (
                                    <Check className="align-middle" size={8} />
                                  ) : item[key2] === false ? (
                                    <X
                                      className="text-danger align-middle"
                                      size={8}
                                    />
                                  ) : (
                                    item[key2]
                                  )}
                                </small>
                              </Badge>
                            </span>
                          )
                        }
                      })}
                      <Button.Ripple
                        onClick={(e) => toggleEdit(item, !isEditedProp)}
                        disabled={
                          isEditedProp !== null &&
                          isEditedPropCurrent !== item?.fieldName
                        }
                        className="ms-50 btn-icon btn-sm"
                        color="flat-secondary"
                      >
                        <Edit className="align-middle" size={12} />
                      </Button.Ripple>
                    </div>
                  </div>
                  <Collapse
                    isOpen={isEditedProp && isEditedProp?.[item?.fieldName]}
                  >
                    <div className="ps-1 my-1">
                      {dynamicForm && (
                        <DynamicForm
                          dynamicForm={dynamicForm}
                          pageAttr={{
                            pageTitle: "Dev Edit Columns Properties",
                            crudOpt: {
                              data: {
                                dynamicForm: dynamicForm
                              },
                              btnSubmitText: "Save",
                              btnSubmitIcon: "feather:Save",
                              btnCancelIcon: "feather:X",
                              footerProp: { className: "text-end mt-1" },
                              btnSubmitProp: {
                                color: "info",
                                outline: true,
                                className: "ms-50 btn-sm btn-icon"
                              },
                              btnCancelProp: {
                                color: "flat-secondary",
                                className: "ms-50 btn-sm btn-icon"
                              }
                            }
                          }}
                          storeName={`dev_columnProp_${item?.fieldName}`}
                          handleReset={() => toggleEdit(item, false)}
                          handleFormSubmit={saveColumnAttr}
                          defaultFormValues={genDefaultFormValues(item)}
                          editForm={true}
                        />
                      )}
                    </div>
                    {/* <div end className="justify-content-end d-flex mt-1 mb-50">
                      <Button
                        color="info"
                        outline
                        className="ms-50 btn-sm btn-icon"
                        onClick={() => saveColumnAttr(item)}
                        // disabled={
                        //   !changedDynamicColumns || changedDynamicColumns?.length === 0
                        // }
                      >
                        <Save className="align-middle mx-25" size={12} />
                        <span className="align-middle">{t("Save")}</span>
                      </Button>
                      <Button
                        color="flat-secondary"
                        className="ms-50 btn-sm btn-icon"
                        onClick={() => toggleEdit(item, false)}
                      >
                        <X className="align-middle mx-25" size={12} />
                        <span className="align-middle">{t("Cancel")}</span>
                      </Button>
                    </div> */}
                  </Collapse>
                </ListGroupItem>
              )
            })}
          </ReactSortable>
        </Col>
        <Col md="4" sm="12">
          <h4 className="mt-1 mb-0">{t("Available Columns")}</h4>
          <small>{t("Available Column From Api Response")}</small>
          <ReactSortable
            // sort={false}
            tag="ul"
            className="list-group list-group-flush sortable dev-columns mt-1"
            group="shared-group"
            list={listArr2}
            setList={setListArr2}
          >
            {listArr2.map((item) => {
              return (
                <ListGroupItem
                  className="draggable"
                  key={`columns_pick_${item.id_gen_}`}
                >
                  <div>
                    <h5 className="my-25">{item.fieldName}</h5>
                  </div>
                </ListGroupItem>
              )
            })}
          </ReactSortable>
        </Col>
      </Row>
    </Fragment>
  )
}

export default DynamicColumnsDev
