// ** Redux Imports
import rootReducer from "./rootReducer"
import { configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"

const preloadedState = {
  todos: [],
  visibilityFilter: "SHOW_COMPLETED"
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
      // immutableCheck: false,
      immutableCheck: { warnAfter: 128 }
      // serializableCheck: { warnAfter: 128 },
    })
  }
  // devTools: process.env.NODE_ENV !== "production",
  // preloadedState
})

export { store }
