// ** React Imports
import { lazy } from "react"

import { GoogleOAuthProvider } from "@react-oauth/google"

const Login = lazy(() => import("../../views/pages/authentication/Login"))
const LoginBasic = lazy(() =>
  import("../../views/pages/authentication/LoginBasic")
)
const LoginCover = lazy(() =>
  import("../../views/pages/authentication/LoginCover")
)

const Register = lazy(() => import("../../views/pages/authentication/Register"))
const RegisterBasic = lazy(() =>
  import("../../views/pages/authentication/RegisterBasic")
)
const RegisterCover = lazy(() =>
  import("../../views/pages/authentication/RegisterCover")
)
const RegisterMultiSteps = lazy(() =>
  import("../../views/pages/authentication/register-multi-steps")
)

const ForgotPassword = lazy(() =>
  import("../../views/pages/authentication/ForgotPassword")
)
const ResetPassword = lazy(() =>
  import("../../views/pages/authentication/ResetPassword")
)

const ForgotPasswordBasic = lazy(() =>
  import("../../views/pages/authentication/ForgotPasswordBasic")
)
const ForgotPasswordCover = lazy(() =>
  import("../../views/pages/authentication/ForgotPasswordCover")
)

const ResetPasswordBasic = lazy(() =>
  import("../../views/pages/authentication/ResetPasswordBasic")
)
const ResetPasswordCover = lazy(() =>
  import("../../views/pages/authentication/ResetPasswordCover")
)

const VerifyEmailBasic = lazy(() =>
  import("../../views/pages/authentication/VerifyEmailBasic")
)
const VerifyEmailCover = lazy(() =>
  import("../../views/pages/authentication/VerifyEmailCover")
)

const TwoStepsBasic = lazy(() =>
  import("../../views/pages/authentication/TwoStepsBasic")
)
const TwoStepsCover = lazy(() =>
  import("../../views/pages/authentication/TwoStepsCover")
)

const AuthenticationRoutes = [
  {
    path: "/login",
    element: (
      <GoogleOAuthProvider clientId="1096092734184-n0tcl5abejf0s0r2t4fmoi3qf4fhen76.apps.googleusercontent.com">
        <Login />{" "}
      </GoogleOAuthProvider>
    ),
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: "/register",
    element: <RegisterMultiSteps />,
    meta: {
      layout: "blank",
      publicRoute: true,
      restricted: true
    }
  },
  // {
  //   path: "/pages/register-multi-steps",
  //   element: <RegisterMultiSteps />,
  //   meta: {
  //     layout: "blank"
  //   }
  // },

  {
    path: "/forgot-password",
    element: <Login pageType="forgot" />,
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true
      // restricted: true
    }
  },

  {
    path: "/reset-password",
    element: <Login pageType="reset" />,
    layout: "BlankLayout",
    meta: {
      layout: "blank",
      publicRoute: true
      // restricted: true
    }
  }

  // {
  //   path: "/pages/verify-email-basic",
  //   element: <VerifyEmailBasic />,
  //   meta: {
  //     layout: "blank"
  //   }
  // },
  // {
  //   path: "/pages/verify-email-cover",
  //   element: <VerifyEmailCover />,
  //   meta: {
  //     layout: "blank"
  //   }
  // },
  // {
  //   path: "/pages/two-steps-basic",
  //   element: <TwoStepsBasic />,
  //   meta: {
  //     layout: "blank"
  //   }
  // },
  // {
  //   path: "/pages/two-steps-cover",
  //   element: <TwoStepsCover />,
  //   meta: {
  //     layout: "blank"
  //   }
  // }
]

export default AuthenticationRoutes
