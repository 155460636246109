// ** React Import
import { useEffect, useRef, memo, Fragment, useContext } from "react"

// ** Full Calendar & it's Plugins
import FullCalendar from "@fullcalendar/react"
import listPlugin from "@fullcalendar/list"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { createPortal } from "react-dom"

// ** Third Party Components
import toast from "react-hot-toast"
import { Menu } from "react-feather"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"

import idLocale from "@fullcalendar/core/locales/id"
import esLocale from "@fullcalendar/core/locales/es"
import { AbilityContext } from "@src/utility/context/Can"
import { Tooltip } from "bootstrap"

const Calendar = (props) => {
  const ability = useContext(AbilityContext)
  // ** Refs
  const calendarRef = useRef(null)
  const calendarsColors = {}
  const calendarsColorsNew = {}
  const colorCollections = [
    "primary",
    "secondary",
    "success",
    "danger",
    "info",
    "warning",
    "dark",
    "light-primary",
    "light-success",
    "light-danger",
    "light-info",
    "light-warning"
  ]

  // ** Props
  const {
    store,
    storeCalendar,
    isRtl,
    dispatch,
    calendarsColor,
    calendarApi,
    setCalendarApi,
    handleAddEventSidebar,
    blankEvent,
    toggleSidebar,
    selectEvent,
    updateEvent,
    storeName,
    pageAttr,
    handleAddEdit,
    updateDataToApi
  } = props

  function getRandomHexColor() {
    // Generate random values for the red, green, and blue channels
    const red = Math.floor(Math.random() * 256)
    const green = Math.floor(Math.random() * 256)
    const blue = Math.floor(Math.random() * 256)

    // Convert the values to hexadecimal and format the color string
    const hexColor = `#${red.toString(16)}${green.toString(16)}${blue.toString(
      16
    )}`

    return hexColor
  }

  function generateRandomColors() {
    // Generate a background color with alpha 0.12
    const backgroundR = Math.floor(Math.random() * 256) // Values between 0 and 255
    const backgroundG = Math.floor(Math.random() * 256)
    const backgroundB = Math.floor(Math.random() * 256)
    const backgroundA = 0.12
    const backgroundColor = `rgba(${backgroundR}, ${backgroundG}, ${backgroundB}, ${backgroundA})`

    // Generate a text color (RGB)
    const textR = backgroundR
    const textG = backgroundG
    const textB = backgroundB
    const textColor = `rgb(${textR}, ${textG}, ${textB})`

    const borderA = 0.8
    const borderColor = `rgba(${textR}, ${textG}, ${textB}, ${borderA})`
    return {
      color: textColor,
      backgroundColor: backgroundColor,
      textColor: textColor,
      borderColor: borderColor
    }
  }

  function genTooltip(item) {
    let calendarSimpleTooltip = pageAttr?.crudOpt?.calendarSimpleTooltip
    let dynamicTooltipColumns = pageAttr?.crudOpt?.dynamicTooltipColumns
    if (calendarSimpleTooltip && !dynamicTooltipColumns) {
      let result = ""
      let calendarSimpleToolSeparator =
        pageAttr?.crudOpt?.calendarSimpleToolSeparator ?? " "
      if (Array.isArray(calendarSimpleTooltip)) {
        if (calendarSimpleTooltip?.length > 0) {
          calendarSimpleTooltip.map((fieldName, index) => {
            let fieldData = item?.[fieldName] ?? ""
            result =
              result +
              fieldData +
              (fieldData
                ? index === calendarSimpleTooltip?.length - 1
                  ? ""
                  : calendarSimpleToolSeparator
                : "")
          })
        }
      } else {
        let fieldData = item?.[calendarSimpleTooltip] ?? ""
        result = result + fieldData
      }
      return result
    }
  }

  // store.pageData[storeName]?.data?.map((item, i) => {
  //   // const { backgroundColor, textColor, borderColor } = generateRandomColors()
  //   if (item?.calendarColorField) {
  //     calendarsColors[item?.id] = colorCollections[item?.calendarColorField]
  //   } else {
  //     calendarsColors[item?.id] = getRandomHexColor()
  //   }
  // })

  const calendarTooltips = {}

  // INIT FIRST
  store.pageData[storeName]?.data?.map((item, i) => {
    // INIT COLOR
    let defaultColor = {}
    let fieldName = pageAttr?.crudOpt?.calendarColorField ?? "color"
    let fieldData = item?.[fieldName]

    if (fieldData) {
      if (["#", "rgb", "rgba", "hsl"].find((v) => fieldData.includes(v))) {
        defaultColor = {
          color: "#111111",
          backgroundColor: fieldData,
          textColor: "#111111",
          borderColor: fieldData
        }
      } else {
        defaultColor = {
          color: "#111111",
          backgroundColor: colorCollections[fieldData],
          textColor: "#111111",
          borderColor: colorCollections[fieldData]
        }
      }
    } else {
      defaultColor = generateRandomColors()
    }

    let fieldNameTextCol =
      pageAttr?.crudOpt?.calendarTextColorField ?? "textColor"
    let fieldDataTextCol = item?.[fieldNameTextCol]

    if (fieldDataTextCol) {
      if (
        ["#", "rgb", "rgba", "hsl"].find((v) => fieldDataTextCol.includes(v))
      ) {
        defaultColor = {
          ...defaultColor,
          color: fieldDataTextCol,
          textColor: fieldDataTextCol
        }
      } else {
        defaultColor = {
          ...defaultColor,
          color: colorCollections[fieldDataTextCol],
          textColor: colorCollections[fieldDataTextCol]
        }
      }
    }

    calendarsColorsNew[item?.id] = defaultColor

    // INIT TOLLTIP
    calendarTooltips[item?.id] = genTooltip(item)
  })

  // ** UseEffect checks for CalendarAPI Update
  useEffect(() => {
    // console.log(calendarRef.current)
    // console.log(store.pageData[storeName]?.data)
    if (calendarApi === null) {
      setCalendarApi(calendarRef.current.getApi())
      // console.log(calendarApi)
      // console.log(calendarRef)
      // calendarRef.current.setOption("locale", "id")
    }
  }, [calendarApi])

  // ** calendarOptions(Props)
  const calendarOptions = {
    // events: storeCalendar.events.length ? storeCalendar.events : [],
    events: store.pageData[storeName]?.data?.length
      ? store.pageData[storeName]?.data.map((item) => ({
          id: item.id,
          url: "",
          title: item?.[pageAttr?.crudOpt?.calendarTitleField ?? "title"],
          start: item?.[pageAttr?.crudOpt?.calendarStartField ?? "start"],
          end: item?.[pageAttr?.crudOpt?.calendarEndField ?? "end"],
          allDay:
            // eslint-disable-next-line no-unneeded-ternary
            pageAttr?.crudOpt?.calendarEnableTime === true ? false : true,
          extendedProps: { data: item },
          // ...generateRandomColors()
          ...calendarsColorsNew[item?.id]
        }))
      : [],
    locales: [esLocale, idLocale],
    locale: localStorage.getItem("i18nextLng") ?? "id",
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth,today"
    },
    // customButtons: {
    //   dayGridMonthCustom: {
    //     text: "Hoy",
    //     click: () => {
    //       calendarApi.changeView("dayGridMonth")
    //     }
    //     // click: function () {
    //     //   alert("clicked the custom button!")
    //     // }
    //   },
    //   timeGridWeekCustom: {
    //     text: "Semana",
    //     click: () => {
    //       calendarRef.current.getApi().changeView("timeGridWeek")
    //     }
    //   },
    //   timeGridDayCustom: {
    //     text: "Mes",
    //     click: () => {
    //       calendarRef.current.getApi().changeView("timeGridDay")
    //     }
    //   },
    //   listMonthCustom: {
    //     text: "Nueva cita",
    //     click: () => {
    //       calendarRef.current.getApi().changeView("listMonth")
    //     }
    //   }
    // },

    customButtons: {
      sidebarToggle: {
        text: <Menu className="d-xl-none d-block" />,
        click() {
          toggleSidebar(true)
        }
      },
      prev: {
        click() {
          calendarRef.current.getApi().prev()
          const lastDate = calendarRef.current
            .getApi()
            ?.view?.activeEnd?.toISOString()
            .substring(0, 10)
          // console.log(lastDate)
          localStorage.setItem(`${storeName}_layout_startDate`, lastDate)
        }
      },
      next: {
        click() {
          calendarRef.current.getApi().next()
          const lastDate = calendarRef.current
            .getApi()
            ?.view?.activeEnd?.toISOString()
            .substring(0, 10)
          // console.log(lastDate)
          localStorage.setItem(`${storeName}_layout_startDate`, lastDate)
        }
      }
    },
    editable: true,
    eventResizableFromStart: true,
    eventDurationEditable:
      ability.can("update", sessionStorage.getItem("current_page_perm")) &&
      !store.pageAttributes[storeName]?.crudOpt?.calendarDisabledResize,
    eventStartEditable:
      ability.can("update", sessionStorage.getItem("current_page_perm")) &&
      !store.pageAttributes[storeName]?.crudOpt?.calendarDisabledDrop,
    // droppable: true,
    dragScroll: true,
    dayMaxEvents: 4,
    dayMaxEventRows: 4, // for all non-TimeGrid views
    views: {
      timeGrid: {
        dayMaxEventRows: 4 // adjust to 6 only for timeGridWeek/timeGridDay
      }
    },
    eventMaxStack: 4,
    navLinks: true,
    // eventClassNames({ event: calendarEvent }) {
    //   // eslint-disable-next-line no-underscore-dangle
    //   // const colorName =
    //   //   calendarsColor[calendarEvent._def.extendedProps.calendar]
    //   // const backColor =
    //   //   calendarsColors[Math.floor(Math.random() * calendarsColors.length)]
    //   // console.log(calendarEvent)
    //   // console.log(calendarEvent._def.extendedProps?.data?.id)
    //   // const backColor =
    //   //   calendarsColors[calendarEvent._def.extendedProps?.data?.id]
    //   return ["text-dark text-calendar"]
    //   // if (backColor) {
    //   //   return [
    //   //     // Background Color
    //   //     // `bg-light-${colorName}`
    //   //     `bg-light-${backColor}`
    //   //   ]
    //   // } else {
    //   //   // return [`bg-light-primary`]
    //   // }
    // },
    eventDidMount: function (info) {
      let tooltip = new Tooltip(info.el, {
        // title:
        //   info.event._def?.extendedProps.Description ?? info.event?.title ?? "",
        title:
          calendarTooltips[info.event._def.extendedProps?.data?.id] ??
          info.event?.title,
        placement: "auto",
        trigger: "hover",
        container: "body"
      })
    },

    eventClick({ event: clickedEvent }) {
      // dispatch(selectEvent(clickedEvent))
      // handleAddEventSidebar()
      // console.log(clickedEvent._def?.extendedProps?.data)
      if (
        !pageAttr?.crudOpt?.disabledEdit &&
        ability.can("update", sessionStorage.getItem("current_page_perm"))
      ) {
        handleAddEdit(clickedEvent._def?.extendedProps?.data)
      }

      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      // event.value = grabEventDataFromEventApi(clickedEvent)

      // eslint-disable-next-line no-use-before-define
      // isAddNewEventSidebarActive.value = true
    },

    datesSet(info) {
      // console.log(info)
      if (info.view.type) {
        localStorage.setItem(`${storeName}_layout_viewType`, info.view.type)
      }
      // localStorage.setItem(
      //   `${storeName}_layout_startDate`,
      //   info.startStr.substring(0, 10)
      // )
    },
    dateClick(info) {
      // const ev = blankEvent
      // ev.start = info.date
      // ev.end = info.date
      // dispatch(selectEvent(ev))
      // handleAddEventSidebar()

      // console.log(info)
      // console.log(info.date.getHours())
      if (
        ability.can("delete", sessionStorage.getItem("current_page_perm")) &&
        !store.pageAttributes[storeName]?.crudOpt?.disabledDelete
      ) {
        handleAddEdit(null, {
          [pageAttr?.crudOpt?.calendarStartField ?? "start"]: info.dateStr,
          [pageAttr?.crudOpt?.calendarEndField ?? "end"]: info.dateStr
        })
      }
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */

    eventDrop({ event: droppedEvent }) {
      if (
        ability.can("update", sessionStorage.getItem("current_page_perm")) &&
        !store.pageAttributes[storeName]?.crudOpt?.calendarDisabledDrop
      ) {
        const allData = { ...droppedEvent._def?.extendedProps?.data }
        let oldData = {
          [pageAttr?.crudOpt?.calendarStartField ?? "start"]:
            allData[pageAttr?.crudOpt?.calendarStartField ?? "start"],
          [pageAttr?.crudOpt?.calendarEndField ?? "end"]:
            allData[pageAttr?.crudOpt?.calendarEndField ?? "start"]
        }
        oldData.id = allData?.id

        let data = { ...oldData }
        const start =
          pageAttr?.crudOpt?.calendarEnableTime === true
            ? `${droppedEvent.startStr.substring(
                0,
                10
              )} ${droppedEvent.startStr.substring(11, 16)}`
            : droppedEvent.startStr
        const end =
          pageAttr?.crudOpt?.calendarEnableTime === true
            ? `${droppedEvent.endStr.substring(
                0,
                10
              )} ${droppedEvent.endStr.substring(11, 16)}`
            : droppedEvent.endStr
        data[pageAttr?.crudOpt?.calendarStartField ?? "start"] = start
        data[pageAttr?.crudOpt?.calendarEndField ?? "end"] = end
        updateDataToApi(oldData, data)
      }
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      if (
        ability.can("update", sessionStorage.getItem("current_page_perm")) &&
        !store.pageAttributes[storeName]?.crudOpt?.calendarDisabledResize
      ) {
        const allData = { ...resizedEvent._def?.extendedProps?.data }
        let oldData = {
          [pageAttr?.crudOpt?.calendarStartField ?? "start"]:
            allData[pageAttr?.crudOpt?.calendarStartField ?? "start"],
          [pageAttr?.crudOpt?.calendarEndField ?? "end"]:
            allData[pageAttr?.crudOpt?.calendarEndField ?? "start"]
        }
        oldData.id = allData?.id

        let data = { ...oldData }
        const start =
          pageAttr?.crudOpt?.calendarEnableTime === true
            ? `${resizedEvent.startStr.substring(
                0,
                10
              )} ${resizedEvent.startStr.substring(11, 16)}`
            : resizedEvent.startStr
        const end =
          pageAttr?.crudOpt?.calendarEnableTime === true
            ? `${resizedEvent.endStr.substring(
                0,
                10
              )} ${resizedEvent.endStr.substring(11, 16)}`
            : resizedEvent.endStr
        data[pageAttr?.crudOpt?.calendarStartField ?? "start"] = start
        data[pageAttr?.crudOpt?.calendarEndField ?? "end"] = end
        updateDataToApi(oldData, data)
      }
    },
    ref: calendarRef,

    // Get direction from app state (storeCalendar)
    direction: isRtl ? "rtl" : "ltr",
    ...pageAttr?.crudOpt?.calendarOptions,
    initialView:
      localStorage.getItem(`${storeName}_layout_viewType`) ??
      pageAttr?.crudOpt?.calendarOptions?.initialView ??
      "dayGridMonth",
    initialDate:
      localStorage.getItem(`${storeName}_layout_startDate`) ??
      pageAttr?.crudOpt?.calendarOptions?.initialDate
  }

  return (
    <Card className="shadow-none border-0 mb-0 rounded-0">
      <CardBody className="pb-0">
        <FullCalendar {...calendarOptions} />
      </CardBody>
    </Card>
  )
}

export default memo(Calendar)
