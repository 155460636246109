import { AbilityContext } from "@src/utility/context/Can"
import {
  getRequiredFilterState,
  parseStrAndField,
  getUserData,
  getDomainInfo,
  getDepthValue
} from "@utils"
import classnames from "classnames"
import "cleave.js/dist/addons/cleave-phone.us"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { Fragment, forwardRef, useContext, useState } from "react"
import {
  Download,
  Facebook,
  FileText,
  Info,
  Share,
  Twitter
} from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import jsPDF from "jspdf"
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Input,
  Row,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap"
import toast from "react-hot-toast"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { renderIcon } from "../../views/apps/menu/store/my_menu"
import { renderToString } from "react-dom/server"

// ** Third Party Components
import { utils, write } from "xlsx"
import * as FileSaver from "file-saver"
import {
  setDataFrame,
  setDataFrameHtml,
  getBaseUrl,
  setModalImport
} from "@src/shared/dynamic_pages/store"
import { QRCodeSVG } from "qrcode.react"

const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))

const CustomHeader = forwardRef((props, ref) => {
  const {
    pageAttr,
    handleAddEdit,
    handlePerPage,
    rowsPerPage,
    handleFilter,
    searchTerm,
    selectedRow,
    handleDeleteSelected,
    handleUpdateSelected,
    storeName,
    btnAddCondition = {},
    customHeaderSet,
    expandAllRows,
    headerMode
  } = props

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)
  const { colors } = useContext(ThemeColors)
  const [dynamicColumnsFilter, setDynamicColumnsFilter] = useState(
    pageAttr?.crudOpt?.data?.dynamicColumnsFilter
  )
  const dispatch = useDispatch()
  const [dynamicFilterComponents, setDynamicFilterComponents] = useState({})
  const [dynamicFilterDatas, setDynamicFilterDatas] = useState({})

  const store = useSelector((state) => state.dynamicStore)
  // const [storeNameParent, setStoreNameParent] = useState(
  //   moduleId ? `${moduleId}_${pageId}` : `${pageId}`
  // )
  const paramss = useParams()

  const userData = getUserData()
  const userDataField = [
    { key: "__userData.name__", value: userData?.name },
    { key: "__userData.avatar__", value: userData?.avatar },
    { key: "__userData.email__", value: userData?.email },
    { key: "__userData.username__", value: userData?.username },
    { key: "__userData.isManager__", value: userData?.isManager },
    { key: "__userData.id__", value: userData?.id }
  ]

  const domainData = getDomainInfo()
  // console.log(domainData)
  const appDataField = [
    { key: "__appData.logo__", value: domainData?.app?.logo?.appLogo },
    { key: "__appData.name__", value: domainData?.app?.name }
  ]
  const unitDataField = [
    { key: "__unitData.logo__", value: domainData?.unit?.attributes?.logo },
    { key: "__unitData.name__", value: domainData?.unit?.name },
    { key: "__unitData.code__", value: domainData?.unit?.code }
  ]

  function formatDate(type) {
    const datetime = new Date()
    if (type === 1) {
      // 23/01/2024
      return datetime.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      })
    } else if (type === 2) {
      // 23/01/2024 21:25
      const option2 = `${formatDate(1)} ${datetime.toLocaleTimeString("id-ID", {
        hour: "2-digit",
        minute: "2-digit"
      })}`
      return option2
    } else if (type === 3) {
      // 23 Januari 2024
      return datetime.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "long",
        year: "numeric"
      })
    } else if (type === 4) {
      // 23 Januari 2024 Jam 21:25
      const option4 = `${formatDate(3)} Jam ${datetime.toLocaleTimeString(
        "id-ID",
        { hour: "2-digit", minute: "2-digit" }
      )}`
      return option4
    } else if (type === 5) {
      // 23 Jan 2024
      return datetime.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "short",
        year: "numeric"
      })
    } else if (type === 6) {
      // 23-01-2024
      return datetime
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })
        .replace(/\//g, "-")
    } else if (type === 7) {
      // 23-01-2024 21:25
      const option7 = `${formatDate(6)} ${datetime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit"
      })}`
      return option7
    } else if (type === 8) {
      // 2024-01-23
      return datetime.toISOString().split("T")[0]
    } else if (type === 9) {
      // 2024/01/23
      return datetime
        .toLocaleDateString("en-CA", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        })
        .replace(/\-/g, "/")
    } else {
      // Default format (type not recognized)
      return "Invalid type"
    }
  }

  const formattedDate = [
    { key: "__currentDate__", value: formatDate(1) },
    { key: "__currentDate1__", value: formatDate(1) },
    { key: "__currentDate2__", value: formatDate(2) },
    { key: "__currentDate3__", value: formatDate(3) },
    { key: "__currentDate4__", value: formatDate(4) },
    { key: "__currentDate5__", value: formatDate(5) },
    { key: "__currentDate6__", value: formatDate(6) },
    { key: "__currentDate7__", value: formatDate(7) },
    { key: "__currentDate8__", value: formatDate(8) },
    { key: "__currentDate9__", value: formatDate(9) }
  ]

  const successToast = () => {
    const msg = t(
      `Successfully Copied Table Data ${t(pageAttr?.pageTitle)} To Clipboard`
    )
    return toast.success(msg, {
      style: {
        padding: "16px",
        color: colors.success.main,
        border: `1px solid ${colors.success.main}`
      },
      iconTheme: {
        primary: colors.success.main
      }
    })
  }

  const fileNameAttr_prefix = `${Math.floor(Date.now() / 1000)}`
  const fileNameAttr = `${
    pageAttr?.pageTitle ?? `export`
  }_${fileNameAttr_prefix}`

  function convertDomToJson() {
    let result = []

    let headerKeys = []
    const rdt_Table = document.querySelector(".rdt_Table")
    ////CARI TEXT HEADER
    let rdt_TableHeadRow = rdt_Table.querySelector(".rdt_TableHeadRow")

    if (rdt_TableHeadRow) {
      const rdt_TableCol = rdt_TableHeadRow.querySelectorAll(".rdt_TableCol")
      rdt_TableCol.forEach(function (row) {
        let rowText = row.textContent?.trim()
        // console.log(rowText)
        if (
          rowText?.toLowerCase() !== "tindakan" &&
          rowText?.toLowerCase() !== "actions"
        ) {
          headerKeys.push(rowText)
        }
      })
    }

    ////CARI TEXT BODY
    let rdt_TableBody = rdt_Table.querySelector(".rdt_TableBody")
    if (rdt_TableBody) {
      const rdt_TableRow = rdt_TableBody.querySelectorAll(".rdt_TableRow")
      if (rdt_TableRow?.length > 0) {
        rdt_TableRow.forEach(function (row) {
          const rdt_TableCell = row.querySelectorAll(".rdt_TableCell")
          if (rdt_TableCell?.length > 0) {
            let ctr = 0
            let newRow = {}
            rdt_TableCell.forEach(function (cell) {
              if (!cell.querySelector(".column-action")) {
                const cellText = cell.textContent?.trim()
                newRow[headerKeys[ctr]] = cellText
                ctr++
              }
            })
            result.push(newRow)
          }
        })
      }
    }
    // console.log(result)
    return { header: headerKeys, data: result }
  }

  function convertArrayOfObjectsToCSV() {
    let result

    const columnDelimiter = ","
    const lineDelimiter = "\n"
    // let keys = Object.keys(array[0])
    // let keys = []
    // let keysLabel = []
    // console.log(pageAttr?.crudOpt?.data?.dynamicColumns)

    const rdt_Table = document.querySelector(".rdt_Table")
    ////CARI TEXT HEADER
    let rdt_TableHeadRow = rdt_Table.querySelector(".rdt_TableHeadRow")
    let headerKeys = []
    if (rdt_TableHeadRow) {
      const rdt_TableCol = rdt_TableHeadRow.querySelectorAll(".rdt_TableCol")
      rdt_TableCol.forEach(function (row) {
        let rowText = row.textContent?.trim()
        // console.log(rowText)
        if (
          rowText?.toLowerCase() !== "tindakan" &&
          rowText?.toLowerCase() !== "actions"
        ) {
          headerKeys.push(rowText)
        }
      })
    }

    // console.log(headerKeys)
    result = ""
    result += headerKeys.join(columnDelimiter)
    result += lineDelimiter

    ////CARI TEXT BODY
    let bodyText = []
    let rdt_TableBody = rdt_Table.querySelector(".rdt_TableBody")
    if (rdt_TableBody) {
      const rdt_TableRow = rdt_TableBody.querySelectorAll(".rdt_TableRow")
      if (rdt_TableRow?.length > 0) {
        rdt_TableRow.forEach(function (row) {
          const rdt_TableCell = row.querySelectorAll(".rdt_TableCell")
          if (rdt_TableCell?.length > 0) {
            let ctr = 0
            rdt_TableCell.forEach(function (cell) {
              if (!cell.querySelector(".column-action")) {
                const cellText = cell.textContent?.trim()
                // console.log(cellText)
                // bodyText.push(cellText)
                if (ctr > 0) result += columnDelimiter

                result += cellText

                ctr++
              }
            })
            result += lineDelimiter
          }
        })
      }
    }
    // console.log(bodyText)

    // pageAttr?.crudOpt?.data?.dynamicColumns?.map((item, index) => {
    //   if (
    //     item?.fieldName &&
    //     !item?.isHidden &&
    //     (!item?.includeOnExport || !item?.includeOnExport !== false)
    //   ) {
    //     keys.push(item?.fieldName)
    //     keysLabel.push(item?.columnName ?? item?.fieldName)
    //   }
    // })

    // result = ""

    // bodyText.forEach((item) => {
    //   let ctr = 0
    //   keys.forEach((key) => {
    //     if (ctr > 0) result += columnDelimiter

    //     result += item[key]

    //     ctr++
    //   })
    //   result += lineDelimiter
    // })

    // console.log(result)
    return result
  }

  function convertArrayOfObjectsToHtml() {
    let result = "<html>"
    result += `<head>
      <style>
        .table {
          width: 100%;
          font-size: 12px;
          border-collapse: collapse;
        }
        .table th, td {
          padding: 15px;
          text-align: left;
          border: 1px solid #ddd;
        }
      </style>
    </head><body>`
    const rdt_Table = document.querySelector(".rdt_Table")
    ////CARI TEXT HEADER
    result += "<table class='table'>"
    let rdt_TableHeadRow = rdt_Table.querySelector(".rdt_TableHeadRow")
    let headerKeys = []
    if (rdt_TableHeadRow) {
      result += "<thead>"
      result += "<tr>"
      const rdt_TableCol = rdt_TableHeadRow.querySelectorAll(".rdt_TableCol")
      rdt_TableCol.forEach(function (row) {
        let rowText = row.textContent?.trim()
        if (
          rowText?.toLowerCase() !== "tindakan" &&
          rowText?.toLowerCase() !== "actions"
        ) {
          headerKeys.push(rowText)
          result += `<th>${rowText}</th>`
        }
      })
      result += "</tr>"
      result += "</thead>"
    }

    ////CARI TEXT BODY
    let bodyText = []
    let rdt_TableBody = rdt_Table.querySelector(".rdt_TableBody")
    if (rdt_TableBody) {
      result += "<tbody>"
      const rdt_TableRow = rdt_TableBody.querySelectorAll(".rdt_TableRow")
      if (rdt_TableRow?.length > 0) {
        rdt_TableRow.forEach(function (row) {
          result += "<tr>"
          const rdt_TableCell = row.querySelectorAll(".rdt_TableCell")
          if (rdt_TableCell?.length > 0) {
            let ctr = 0
            rdt_TableCell.forEach(function (cell) {
              if (!cell.querySelector(".column-action")) {
                const cellText = cell.textContent?.trim()
                result += `<td>${cellText}</td>`

                ctr++
              }
            })
          }

          result += "</tr>"
        })
      }
      result += "</tbody>"
    }
    result += "</table>"
    result += "</body></html>"
    return result
  }

  function convertArrayOfObjectsToText() {
    let result

    const columnDelimiter = " | "
    const lineDelimiter = "\n"

    const rdt_Table = document.querySelector(".rdt_Table")
    ////CARI TEXT HEADER
    let rdt_TableHeadRow = rdt_Table.querySelector(".rdt_TableHeadRow")
    let headerKeys = []
    if (rdt_TableHeadRow) {
      const rdt_TableCol = rdt_TableHeadRow.querySelectorAll(".rdt_TableCol")
      rdt_TableCol.forEach(function (row) {
        let rowText = row.textContent?.trim()
        // console.log(rowText)
        if (
          rowText?.toLowerCase() !== "tindakan" &&
          rowText?.toLowerCase() !== "actions"
        ) {
          headerKeys.push(rowText)
        }
      })
    }

    // console.log(headerKeys)
    result = ""
    result += headerKeys.join(columnDelimiter)
    result += lineDelimiter

    ////CARI TEXT BODY
    let bodyText = []
    let rdt_TableBody = rdt_Table.querySelector(".rdt_TableBody")
    if (rdt_TableBody) {
      const rdt_TableRow = rdt_TableBody.querySelectorAll(".rdt_TableRow")
      if (rdt_TableRow?.length > 0) {
        rdt_TableRow.forEach(function (row) {
          const rdt_TableCell = row.querySelectorAll(".rdt_TableCell")
          if (rdt_TableCell?.length > 0) {
            let ctr = 0
            rdt_TableCell.forEach(function (cell) {
              if (!cell.querySelector(".column-action")) {
                const cellText = cell.textContent?.trim()
                if (ctr > 0) result += columnDelimiter

                result += cellText

                ctr++
              }
            })
            result += lineDelimiter
          }
        })
      }
    }
    return result
  }

  function convertArrayOfObjectsToText2() {
    let result

    const columnDelimiter = " | "
    const lineDelimiter = "\n"

    const rdt_Table = document.querySelector(".rdt_Table")
    ////CARI TEXT HEADER
    let rdt_TableHeadRow = rdt_Table.querySelector(".rdt_TableHeadRow")
    let headerKeys = []
    if (rdt_TableHeadRow) {
      const rdt_TableCol = rdt_TableHeadRow.querySelectorAll(".rdt_TableCol")
      rdt_TableCol.forEach(function (row) {
        let rowText = row.textContent?.trim()
        if (
          rowText?.toLowerCase() !== "tindakan" &&
          rowText?.toLowerCase() !== "actions"
        ) {
          headerKeys.push(rowText)
        }
      })
    }

    // console.log(headerKeys)
    result = ""
    // result += headerKeys.join(columnDelimiter)
    // result += lineDelimiter

    ////CARI TEXT BODY
    let bodyText = []
    let rdt_TableBody = rdt_Table.querySelector(".rdt_TableBody")
    if (rdt_TableBody) {
      const rdt_TableRow = rdt_TableBody.querySelectorAll(".rdt_TableRow")
      if (rdt_TableRow?.length > 0) {
        rdt_TableRow.forEach(function (row) {
          const rdt_TableCell = row.querySelectorAll(".rdt_TableCell")
          if (rdt_TableCell?.length > 0) {
            let ctr = 0
            rdt_TableCell.forEach(function (cell) {
              if (!cell.querySelector(".column-action")) {
                const cellText = cell.textContent?.trim()
                if (ctr > 0) result += lineDelimiter

                result += `*${headerKeys[ctr]}* : ${cellText}`

                ctr++
              }
            })
            result += lineDelimiter + lineDelimiter
          }
        })
      }
    }
    return result
  }

  function downloadCSV() {
    const link = document.createElement("a")
    let csv = convertArrayOfObjectsToCSV()
    if (csv === null) return

    const filename = `${fileNameAttr}.csv`

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`
    }

    link.setAttribute("href", encodeURI(csv))
    link.setAttribute("download", filename)
    link.click()
  }

  const exportEvent = (fileFormat, attr) => {
    if (fileFormat === "xlsx" || fileFormat === "xls") {
      const renderedData = JSON.parse(JSON.stringify(convertDomToJson()))
      const bookType = fileFormat

      const ws = utils.book_new()
      utils.sheet_add_aoa(ws, [renderedData.header])
      utils.sheet_add_json(ws, renderedData.data, {
        origin: "A2",
        skipHeader: true
      })
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
      const excelBuffer = write(wb, {
        bookType: bookType,
        type: "array",
        cellStyles: true
      })
      const finalData = new Blob([excelBuffer], { type: fileFormat })
      FileSaver.saveAs(finalData, `${fileNameAttr}.xlsx`)
    } else if (fileFormat === "csv") {
      return downloadCSV()
    } else if (fileFormat === "json") {
      const renderedData = convertDomToJson()
      const data = renderedData?.data
      const dataResult = JSON.stringify(data)
      // return renderedData
      const finalData = new Blob([dataResult], { type: "application/json" })
      FileSaver.saveAs(finalData, `${fileNameAttr}.json`)
    } else if (fileFormat === "copy") {
      const renderedText = convertArrayOfObjectsToText()
      navigator.clipboard.writeText(renderedText)
      return successToast()
    } else if (fileFormat === "text") {
      const renderedText = convertArrayOfObjectsToText2()
      const encodedURL = encodeURIComponent(renderedText)
      return window.open(
        `https://api.whatsapp.com/send?text=${encodedURL}`,
        "_blank"
      )
      // console.log(renderedText)
    } else if (fileFormat === "pdf") {
      const htmlContent = convertArrayOfObjectsToHtml()
      const doc = new jsPDF({
        format: "a4",
        unit: "px",
        orientation: attr?.orientation ?? "landscape",
        fontsize: "10"
      })

      doc.html(htmlContent, {
        callback: function (doc) {
          doc.save(`${fileNameAttr}.pdf`)
        },
        x: 15,
        y: 15,
        width: attr?.width ?? doc.internal.pageSize.getWidth() - 300 ?? 400,
        height: attr?.height ?? doc.internal.pageSize.getHeight() - 300,
        windowWidth: attr?.windowWidth ?? 650
      })
      // console.log(htmlContent)
    }
  }

  const importEvent = (fileFormat, attr) => {
    // setImportModal(!importModal)
    dispatch(
      setModalImport({
        storeName
      })
    ).then((res) => {
      // console.log(res)
    })
  }

  const scrollToDiv = (divId) => {
    const element = document.getElementById(divId)
    // console.log(element)
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
  }

  const renderExportBtn = () => {
    return (
      <div className="d-flex align-items-center mb-sm-0 mb-1 ms-md-1">
        <UncontrolledButtonDropdown className="w-100">
          <DropdownToggle color="secondary" caret outline>
            <Share size={15} />
            <span className="align-middle ms-50 ">{t("Export")}</span>
          </DropdownToggle>
          <DropdownMenu>
            {pageAttr.crudOpt?.exportButton?.map((item, index) => {
              return (
                <DropdownItem
                  className="w-100"
                  onClick={() => exportEvent(item?.fileType, item)}
                >
                  <Fragment>
                    {(item?.iconPos === "left" || !item.iconPos) &&
                      renderIcon(
                        item.icon,
                        item?.iconClass ?? (item?.buttonText ? "me-25" : ""),
                        14
                      )}
                    <span className="align-middle ms-50">{`${t(
                      item?.buttonText
                    )}`}</span>
                    {item.iconPos === "right" &&
                      renderIcon(
                        item.icon,
                        item?.iconClass ?? (item?.buttonText ? "ms-25" : ""),
                        14
                      )}
                  </Fragment>
                  {/* <FileText size={15} />
                  <span className="align-middle ms-50">{item?.buttonText}</span> */}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
    )
  }

  const renderImportBtnItem = (item) => {
    return (
      <DropdownItem
        className="w-100"
        onClick={() => importEvent(item?.fileType, item)}
      >
        <Fragment>
          {(item?.iconPos === "left" || !item.iconPos) &&
            renderIcon(
              item.icon,
              item?.iconClass ?? (item?.buttonText ? "me-25" : ""),
              14
            )}
          <span className="align-middle ms-50">{`${t(item?.buttonText)}`}</span>
          {item.iconPos === "right" &&
            renderIcon(
              item.icon,
              item?.iconClass ?? (item?.buttonText ? "ms-25" : ""),
              14
            )}
        </Fragment>
        {/* <FileText size={15} />
        <span className="align-middle ms-50">{item?.buttonText}</span> */}
      </DropdownItem>
    )
  }

  const renderImportBtn = () => {
    return (
      <div className="d-flex align-items-center mb-sm-0 mt-1 mt-sm-0 mb-1 ms-md-1">
        {Array.isArray(pageAttr.crudOpt?.importButton) ? (
          <UncontrolledButtonDropdown>
            <DropdownToggle color="secondary" caret outline>
              <Download size={15} />
              <span className="align-middle ms-50 ">{t("Import")}</span>
            </DropdownToggle>
            <DropdownMenu>
              {pageAttr.crudOpt?.importButton?.map((item, index) => {
                return renderImportBtnItem(item)
              })}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ) : (
          <Button
            className="w-100"
            outline={pageAttr.crudOpt?.importButton?.outline ?? true}
            color={pageAttr.crudOpt?.importButton.color ?? "secondary"}
            onClick={() =>
              importEvent(
                pageAttr.crudOpt?.importButton?.fileType,
                pageAttr.crudOpt?.importButton
              )
            }
          >
            <Fragment>
              {(pageAttr.crudOpt?.importButton?.iconPos === "left" ||
                !pageAttr.crudOpt?.importButton.iconPos) &&
              pageAttr.crudOpt?.importButton.icon ? (
                renderIcon(
                  pageAttr.crudOpt?.importButton.icon,
                  pageAttr.crudOpt?.importButton?.iconClass ??
                    (pageAttr.crudOpt?.importButton?.buttonText ? "me-25" : ""),
                  15
                )
              ) : (
                <Download size={15} />
              )}
              <span className="align-middle ms-50">
                {t(pageAttr.crudOpt?.importButton?.buttonText ?? "Import")}
              </span>
              {pageAttr.crudOpt?.importButton.iconPos === "right" &&
                (pageAttr.crudOpt?.importButton.icon ? (
                  renderIcon(
                    pageAttr.crudOpt?.importButton.icon,
                    pageAttr.crudOpt?.importButton?.iconClass ??
                      (pageAttr.crudOpt?.importButton?.buttonText
                        ? "ms-25"
                        : ""),
                    15
                  )
                ) : (
                  <Download size={15} />
                ))}
            </Fragment>
          </Button>
        )}
      </div>
    )
  }

  const getParamsFromField1 = (
    staticUrl,
    paramsFromFields,
    appendToEndpointPath,
    row
  ) => {
    let params = ""
    let paramsArr = []
    if (paramsFromFields) {
      paramsFromFields?.map((it, idx) => {
        let paramKey = it?.paramName
        // let paramValue = row?.[it?.fieldName]
        let paramValue = parseStrAndField(row, it?.fieldName)
        if (paramValue) {
          params += `&${paramKey}=${paramValue}`
          paramsArr.push(`${paramKey}=${paramValue}`)
        }
      })
    }
    if (appendToEndpointPath) {
      // let appendToEndpointPathObj = appendToEndpointPath?.split(",")
      appendToEndpointPath?.map((it, idx) => {
        let paramKey = it?.paramName
        let paramValue = parseStrAndField(row, it)
        params += `/${paramValue}`
        paramsArr.push(`${paramValue}`)
      })
    }

    const isQueryStr = staticUrl?.includes("?")

    let newStaticUrl = !appendToEndpointPath
      ? `${staticUrl}${isQueryStr ? "" : "?"}${paramsArr.join("&")}`
      : `${staticUrl}${isQueryStr ? "/" : "/"}${paramsArr.join("/")}`

    let staticUrlFix = !appendToEndpointPath
      ? newStaticUrl
          .replace("&&", "&")
          .replace("/?&", "?")
          .replace("??", "?")
          .replace("/?", "?")
          .replace("/&", "&")
          .replace("?&", "?")
          .replace("&?", "&")
          .replace("?&", "")
      : newStaticUrl
    return staticUrlFix
  }

  const renderLoopElements = (arrayKey, htmlTextClean, data) => {
    const hasLoop = htmlTextClean.includes(arrayKey)
    // console.log(hasLoop)
    if (hasLoop) {
      // Define the regular expression pattern
      let pattern = new RegExp(`\\${arrayKey}(.*?)\\${arrayKey}`, "s")

      // Use the match method to find matches
      let loopPartEl = htmlTextClean.match(pattern)

      let loopPartElMixed = ""
      if (loopPartEl) {
        data?.map((value) => {
          let newLoopPartEl = loopPartEl[1]
          // console.log(newLoopPartEl)
          Object.keys(value).forEach((keyChild, index) => {
            // console.log(keyChild)

            ////QR TYPE VARIABLE
            let qrField = `{${keyChild}:qrcode}`
            if (newLoopPartEl.includes(qrField)) {
              console.log("qrcode exist")
              // let qrFieldAttr = `{${keyChild}:qrcode|`
              // if (newLoopPartEl.includes(qrField)) {
              //   console.log("qrcode attr exist")
              //   let qrFieldAttrParse =
              // }
              let qrField_svg = new QRCodeSVG({
                size: "100%",
                value: value?.[keyChild] ?? "",
                content: value?.[keyChild] ?? "",
                join: "true",
                container: "svg-viewbox"
              })
              const svgString = renderToString(qrField_svg)
              newLoopPartEl = newLoopPartEl?.replaceAll(qrField, svgString)
            } else {
              newLoopPartEl = newLoopPartEl?.replaceAll(
                `{${keyChild}}`,
                value[keyChild]
              )
            }
          })

          /////ADD UNIQUE KEYS TO ELEMENTS
          // Temukan posisi setelah <tr> atau <div> atau <ul>
          const openingTagEndIndex = newLoopPartEl.indexOf(">") - 1
          // Tentukan nilai untuk atribut key
          const timestamp = new Date().getTime()
          let idEl = timestamp
          const keyAttributeValue = `cell-name-${idEl}`
          // Sisipkan atribut key ke dalam string
          const modifiedString = `${newLoopPartEl.slice(
            0,
            openingTagEndIndex + 1
          )} key="${keyAttributeValue}"${newLoopPartEl.slice(
            openingTagEndIndex + 1
          )}`
          newLoopPartEl = modifiedString
          loopPartElMixed = loopPartElMixed + newLoopPartEl
        })
      }

      loopPartElMixed = loopPartElMixed?.replaceAll(arrayKey, "")
      htmlTextClean = htmlTextClean.replace(loopPartEl[0], loopPartElMixed)
    }
    return htmlTextClean
  }

  const fetchHtmlFile = async (path, item, row) => {
    try {
      const timestamp = new Date().getTime()
      let url = `${window.location.origin}/pageAttributes/${path}?timestamp=${timestamp}`
      // console.log(url)
      const response = await fetch(url)
      const htmlText = await response.text()
      // setHtmlContent(htmlText);
      let htmlTextClean = htmlText?.replace(/(\n|\s{3,})/g, " ")

      // check if css exist
      let urlCss = `${window.location.origin}/pageAttributes/${path?.replace(
        ".html",
        ".css"
      )}?timestamp=${timestamp}`
      const responseCss = await fetch(urlCss)
      const CssText = await responseCss.text()
      // console.log(CssText)
      if (CssText) {
        // getCssLinkTag(CssText)

        // Check if <head> exists in the HTML string
        const hasHead = htmlTextClean.includes("<head>")

        // If <head> doesn't exist, create it
        if (!hasHead) {
          htmlTextClean = htmlTextClean.replace(
            '<meta charset="utf-8" />',
            '<head><meta charset="utf-8" /></head>'
          )
        }

        // Check if <style> exists in the HTML string
        const hasStyle = htmlTextClean.includes("<style>")

        // If <style> doesn't exist, create it
        if (!hasStyle) {
          const styleTagPosition = htmlTextClean.indexOf("</head>")
          const modifiedHtmlString = `${htmlTextClean.slice(
            0,
            styleTagPosition
          )}<style>${CssText}</style>${htmlTextClean.slice(styleTagPosition)}`

          htmlTextClean = modifiedHtmlString
        }
      }

      // const userData = getUserData()
      ////replace with data
      let dataToReport = item?.renderCustomHtml?.dataFrom?.value
      if (item?.renderCustomHtml?.dataFrom === "grid_selection") {
        console.log(selectedRow)
        dataToReport = selectedRow
      } else if (item?.renderCustomHtml?.dataFrom?.api) {
        let finUrl = item?.renderCustomHtml?.dataFrom?.api
        let paramsFromFields = item?.renderCustomHtml?.paramsFromFields
        let appendToEndpointPath = item?.renderCustomHtml?.appendToEndpointPath
        const staticUrlFix = getParamsFromField1(
          finUrl,
          paramsFromFields,
          appendToEndpointPath,
          row
        )

        dataToReport = await getBaseUrl(null, staticUrlFix)
          .get(`${staticUrlFix}`)
          .then((res) => {
            // console.log(res?.data)
            const keySelector = item?.renderCustomHtml?.dataFrom?.resp_key
            return keySelector
              ? getDepthValue(res?.data, keySelector)
              : res?.data?.data ?? null
          })
      }
      // console.log(dataToReport)
      const hasData =
        htmlTextClean.includes("{data.") || htmlTextClean.includes("[data")
      if (hasData) {
        let keys = []
        if (Array.isArray(dataToReport)) {
          const arrayKey = `[data]`
          htmlTextClean = renderLoopElements(
            arrayKey,
            htmlTextClean,
            dataToReport
          )
        } else {
          keys = Object.keys(dataToReport)
          keys.forEach((key) => {
            const currentValue = dataToReport[key]
            htmlTextClean = htmlTextClean.replaceAll(
              `{data.${key}}`,
              currentValue
            )
            if (Array.isArray(currentValue)) {
              const arrayKey = `[data.${key}]`
              htmlTextClean = renderLoopElements(
                arrayKey,
                htmlTextClean,
                currentValue
              )
            }
          })
        }
      }

      // __USERDATA
      if (htmlTextClean?.includes("__userData")) {
        userDataField?.map((itemUser) => {
          htmlTextClean = htmlTextClean?.replaceAll(
            `{${itemUser.key}}`,
            itemUser.value
          )
        })
      }

      // __UNITDATA
      if (htmlTextClean?.includes("__unitData")) {
        unitDataField?.map((itemUser) => {
          htmlTextClean = htmlTextClean?.replaceAll(
            `{${itemUser.key}}`,
            itemUser.value
          )
        })
      }

      // __APPDATA
      if (htmlTextClean?.includes("__appData")) {
        appDataField?.map((itemUser) => {
          htmlTextClean = htmlTextClean?.replaceAll(
            `{${itemUser.key}}`,
            itemUser.value
          )
        })
      }

      // __COMMON
      if (htmlTextClean?.includes("__currentDate")) {
        formattedDate.map((item) => {
          htmlTextClean = htmlTextClean?.replaceAll(`{${item.key}}`, item.value)
        })
      }

      return htmlTextClean
    } catch (error) {
      console.error("Error fetching HTML file:", error)
    }
  }

  return (
    // <div className="invoice-list-table-header w-100 me-1 mt-1 ms-50 mb-75">
    <div className="invoice-list-table-header w-100">
      <Row
        className={
          customHeaderSet
            ? pageAttr?.crudOpt?.gridTheme === "grid_card" ||
              pageAttr?.crudOpt?.gridTheme === "map"
              ? "align-items-center px-0 mb-1"
              : "align-items-end p-1"
            : "align-items-center p-1"
        }
        style={{
          paddingTop: "5px"
        }}
      >
        {!pageAttr?.crudOpt?.hideTableHeaderLeft && (
          <Fragment>
            {pageAttr?.crudOpt?.addSelection ||
            customHeaderSet ||
            pageAttr.crudOpt?.extraButton?.length > 0 ||
            (store.pageData[storeName]?.data?.length > 0 &&
              // pageAttr?.crudOpt?.serverSideGrid &&
              !pageAttr?.crudOpt?.importButton &&
              !pageAttr.crudOpt?.hideTablePageLengthInfo &&
              getRequiredFilterState(
                pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
                store.pageData[storeName]?.params
              )) ? (
              <Col
                className="d-flex align-items-center p-0 m-0"
                lg={pageAttr?.crudOpt?.hideTableHeaderRight ? 12 : 6}
                xs={12}
              >
                {pageAttr?.crudOpt?.addSelection &&
                  !pageAttr?.isDropdownTable &&
                  pageAttr?.pageTitle &&
                  pageAttr?.crudOpt?.hideTablePageLengthInfo &&
                  !customHeaderSet && (
                    <div className="align-items-center w-100">
                      <h6>{pageAttr?.pageTitle}</h6>
                    </div>
                  )}
                {customHeaderSet && (
                  <div className="align-items-center w-100 px-1">
                    {customHeaderSet}
                  </div>
                )}

                {store.pageData[storeName]?.data?.length > 0 &&
                  pageAttr?.crudOpt?.serverSideGrid &&
                  !pageAttr.crudOpt?.hideTablePageLengthInfo &&
                  getRequiredFilterState(
                    pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
                    store.pageData[storeName]?.params
                  ) &&
                  !["treeview", "orgchart", "orgchart2", "calendar"].includes(
                    pageAttr?.crudOpt?.gridTheme
                  ) && (
                    <div className="d-flex align-items-center w-100 ms-1 justify-content-sm-start justify-content-center me-1 me-sm-0">
                      <Fragment>
                        <label className="form-label" htmlFor="rows-per-page">
                          {t("Show")}
                        </label>
                        <Input
                          className="mx-50"
                          type="select"
                          id="rows-per-page"
                          value={rowsPerPage}
                          onChange={handlePerPage}
                          style={{ width: "5rem" }}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </Input>
                        <label className="form-label" htmlFor="rows-per-page">
                          {t("Entries")}
                        </label>
                      </Fragment>
                    </div>
                  )}
              </Col>
            ) : (
              !pageAttr.crudOpt?.importButton && (
                <Col
                  className="d-flex align-items-center p-0 m-0"
                  lg={6}
                  xs={12}
                ></Col>
              )
            )}
          </Fragment>
        )}

        {!pageAttr?.crudOpt?.hideTableHeaderRight && (
          <Col
            // style={{
            //   flexBasis: "unset"
            // }}
            lg={
              !pageAttr.crudOpt?.importButton &&
              !pageAttr?.crudOpt?.hideTableHeaderLeft
                ? 6
                : 12
            }
            xs={12}
            className={`d-flex align-items-center justify-content-sm-end justify-content-start flex-wrap flex-sm-nowrap flex-sm-row flex-column col-12 col-lg-6 ${
              customHeaderSet ? "p-1" : "px-1"
            } ${
              pageAttr?.crudOpt?.gridTheme === "table" ||
              !pageAttr?.crudOpt.gridTheme
                ? ""
                : ""
            }
            
            `}
          >
            {selectedRow?.length > 0 &&
            !pageAttr?.isDropdownTable &&
            ((pageAttr?.crudOpt?.deleteSelection &&
              ability.can(
                "delete",
                sessionStorage.getItem("current_page_perm")
              )) ||
              (pageAttr?.crudOpt?.updateSelection &&
                ability.can(
                  "update",
                  sessionStorage.getItem("current_page_perm")
                ))) &&
            !pageAttr?.crudOpt?.addSelection ? (
              <Fragment>
                <div className="d-flex align-items-center mb-sm-0 mb-0 mt-1 mt-sm-0 ms-sm-1 ms-0">
                  {pageAttr?.crudOpt?.deleteSelection &&
                    ability.can(
                      "delete",
                      sessionStorage.getItem("current_page_perm")
                    ) && (
                      <Button
                        className="delete-users w-100"
                        color="gradient-danger"
                        onClick={handleDeleteSelected}
                      >
                        {t("Delete")} {selectedRow.length}
                        {pageAttr?.isViewOnRow || pageAttr?.isViewOnForm
                          ? ""
                          : ` ${t(pageAttr.pageTitle)}`}
                      </Button>
                    )}

                  {pageAttr?.crudOpt?.updateSelection &&
                    ability.can(
                      "update",
                      sessionStorage.getItem("current_page_perm")
                    ) && (
                      <Button
                        className="update-users w-100 ms-1"
                        color="gradient-warning"
                        onClick={handleUpdateSelected}
                      >
                        {t("Update")} {selectedRow.length}
                        {pageAttr?.isViewOnRow || pageAttr?.isViewOnForm
                          ? ""
                          : ` ${t(pageAttr.pageTitle)}`}
                      </Button>
                    )}
                </div>
              </Fragment>
            ) : null}

            {selectedRow?.length > 0 &&
            !pageAttr?.isDropdownTable &&
            pageAttr?.crudOpt?.addSelection &&
            !pageAttr?.crudOpt?.deleteSelection ? (
              <div className="d-flex align-items-center mb-sm-0 mb-1 ms-1">
                <Alert className="mb-0 me-1" color="warning">
                  <div className="alert-body">
                    <span className="fw-bold">
                      {selectedRow.length} {t(pageAttr.pageTitle)}{" "}
                      {t("Selected")}
                    </span>
                  </div>
                </Alert>
                {/* <Button disabled className="delete-users" color="flat-warning">
                {selectedRow.length} {t(pageAttr.pageTitle)} {t("Selected")}
              </Button> */}
              </div>
            ) : null}

            {!pageAttr.crudOpt?.hideTableSearchInput &&
              getRequiredFilterState(
                pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
                store.pageData[storeName]?.params
              ) && (
                // && store.pageData[storeName]?.status !== "failed"
                <div
                  className={`d-flex align-items-center mb-sm-0 mb-0 mt-1 mt-sm-0 ${
                    pageAttr?.crudOpt?.gridTheme === "map"
                      ? " ms-sm-0 w-100"
                      : " ms-sm-1"
                  }`}
                >
                  {/* <label className="mb-0" htmlFor="search-invoice">
                    {t("Search")}:
                  </label> */}
                  <Input
                    id="search-invoice"
                    className="w-100"
                    type="text"
                    placeholder={t("Search")}
                    value={searchTerm}
                    onChange={(e) => handleFilter(e.target.value, e)}
                  />
                </div>
              )}

            {pageAttr?.crudOpt?.dynamicColumnsGroupByKey &&
              !pageAttr?.crudOpt?.dynamicColumnsGroupByKeyDisabledCollapse && (
                // <Button
                //   className={`add-permission ${
                //     pageAttr?.isViewOnRow
                //       ? "round mt-sm-50 mt-50 me-1 "
                //       : "mt-sm-0 mt-1 me-1 "
                //   }`}
                //   color={
                //     pageAttr?.isViewOnRow || pageAttr?.isViewOnForm
                //       ? btnAddCondition?.color
                //         ? btnAddCondition?.color
                //         : "gradient-primary"
                //       : btnAddCondition?.color
                //       ? btnAddCondition?.color
                //       : "primary"
                //   }
                //   // onClick={() => {
                //   //   handleAddEdit(null)
                //   // }}
                // >
                //   {t("Expand All")}
                // </Button>
                <ButtonGroup
                  className={`expand-collapse-all ${
                    pageAttr?.isViewOnRow
                      ? "mt-sm-50 mt-50 ms-lg-1 "
                      : "mt-sm-0 mt-1 ms-lg-1 "
                  }`}
                >
                  <Button
                    id="collapse-all-btn-crud"
                    outline
                    color="secondary"
                    onClick={() => {
                      expandAllRows("collapse")
                    }}
                  >
                    {renderIcon("material:unfold_less", "", 15)}
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="collapse-all-btn-crud"
                  >
                    {t("Collapse All")}
                  </UncontrolledTooltip>

                  <Button
                    id="expand-all-btn-crud"
                    outline
                    color="secondary"
                    onClick={() => {
                      expandAllRows()
                    }}
                  >
                    {renderIcon("material:unfold_more", "", 15)}
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target="expand-all-btn-crud"
                  >
                    {t("Expand All")}
                  </UncontrolledTooltip>
                </ButtonGroup>
              )}

            {pageAttr.crudOpt?.importButton ? renderImportBtn() : null}

            {pageAttr.crudOpt?.exportButton?.length > 0 &&
            getRequiredFilterState(
              pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
              store.pageData[storeName]?.params
            )
              ? renderExportBtn()
              : null}

            {pageAttr.crudOpt?.extraButton?.length > 0 &&
            getRequiredFilterState(
              pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
              store.pageData[storeName]?.params
            )
              ? pageAttr.crudOpt?.extraButton?.map((item, index) => {
                  return (
                    <div
                      key={`extraButton_${index}`}
                      // className="d-flex mt-1 mt-lg-0 align-items-center mb-0 ms-sm-1"
                      className={classnames(
                        `d-flex mt-1 mt-lg-0 align-items-center mb-0 ms-sm-1`,
                        {
                          "d-none":
                            item?.renderCustomHtml?.dataFrom ===
                              "grid_selection" && selectedRow?.length === 0
                        }
                      )}
                    >
                      <Button
                        className={`extra-btn-table-${index} w-100`}
                        color={item?.color ? item?.color : `gradient-primary`}
                        onClick={(e) => {
                          e.preventDefault()
                          let renderCustomHtml = item?.renderCustomHtml
                          let paramsFromFields =
                            item?.clickLinkUrl?.paramsFromFields
                          let appendToEndpointPath =
                            item?.clickLinkUrl?.appendToEndpointPath
                          // let paramsArr = []
                          // let params = ""
                          let row = store?.pageData[storeName]?.params
                          if (!renderCustomHtml) {
                            let staticUrl = item?.clickLinkUrl?.static
                            const staticUrlFix = getParamsFromField1(
                              staticUrl,
                              paramsFromFields,
                              appendToEndpointPath,
                              row
                            )

                            // if (paramsFromFields && row) {
                            //   paramsFromFields?.map((it, idx) => {
                            //     let paramKey = it?.paramName
                            //     // let paramValue = row?.[it?.fieldName]
                            //     let paramValue = parseStrAndField(
                            //       row,
                            //       it?.fieldName
                            //     )
                            //     params += `&${paramKey}=${paramValue}`
                            //     paramsArr.push(`${paramKey}=${paramValue}`)
                            //   })
                            // }

                            // if (appendToEndpointPath) {
                            //   // let appendToEndpointPathObj = appendToEndpointPath?.split(",")
                            //   appendToEndpointPath?.map((it, idx) => {
                            //     let paramKey = it?.paramName
                            //     let paramValue = parseStrAndField(row, it)
                            //     params += `/${paramValue}`
                            //     paramsArr.push(`${paramValue}`)
                            //   })
                            // }
                            // let staticUrl = item?.clickLinkUrl?.static

                            // if (staticUrl) {
                            //   const isQueryStr = staticUrl?.includes("?")

                            //   let newStaticUrl = !appendToEndpointPath
                            //     ? `${staticUrl}${isQueryStr ? "" : "?"}${
                            //         !staticUrl.endsWith("&") ? "&" : ""
                            //       }${paramsArr.join("&")}`
                            //     : `${staticUrl}${
                            //         isQueryStr ? "/" : "/"
                            //       }${paramsArr.join("/")}`

                            //   let staticUrlFix = !appendToEndpointPath
                            //     ? newStaticUrl
                            //         .replace("&&", "&")
                            //         .replace("/?&", "?")
                            //         .replace("??", "?")
                            //         .replace("/?", "?")
                            //         .replace("/&", "&")
                            //         .replace("?&", "?")
                            //         .replace("&?", "&")
                            //         .replace("?&", "")
                            //     : newStaticUrl

                            if (
                              staticUrl.startsWith("http://") ||
                              staticUrl.startsWith("https://")
                            ) {
                              if (
                                item?.clickLinkUrl?.viewContentAs === "iframe"
                              ) {
                                let dataFrameParams = {
                                  storeName: storeName,
                                  data: staticUrlFix
                                }
                                // console.log("setDataFrame", dataFrameParams)
                                dispatch(setDataFrame(dataFrameParams)).then(
                                  (ress) => {
                                    const el_id = `data_frame_${storeName}`
                                    setTimeout(() => {
                                      // console.log(`scrolling to ${el_id}`)
                                      scrollToDiv(el_id)
                                    }, 200)
                                  }
                                )
                              } else {
                                window.open(
                                  staticUrlFix,
                                  "_blank",
                                  "noreferrer"
                                )
                              }
                            } else {
                              navigate(staticUrlFix)
                            }
                          } else {
                            console.log("renderCustomHtml")
                            const htmlFile = item?.renderCustomHtml?.file
                            if (htmlFile) {
                              // console.log(htmlFile)
                              let dataHtml = fetchHtmlFile(
                                htmlFile,
                                item,
                                row
                              ).then((res) => {
                                let dataFrameParams = {
                                  storeName: storeName,
                                  data: res
                                }
                                // console.log("setDataFrame", dataFrameParams)
                                dispatch(
                                  setDataFrameHtml(dataFrameParams)
                                ).then((ress) => {
                                  const el_id = `data_frame_${storeName}`
                                  setTimeout(() => {
                                    // console.log(`scrolling to ${el_id}`)
                                    scrollToDiv(el_id)
                                  }, 200)
                                })
                              })

                              // console.log(dataHtml)
                            }
                          }
                        }}
                      >
                        {!item?.icon ? (
                          <Fragment>
                            {item?.renderCustomHtml?.dataFrom ===
                            "grid_selection"
                              ? `${t(item?.buttonText)} ${selectedRow.length}`
                              : t(item?.buttonText)}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {(item?.iconPos === "left" || !item.iconPos) &&
                              renderIcon(
                                item.icon,
                                item?.iconClass ??
                                  (item?.buttonText ? "me-25" : ""),
                                14
                              )}
                            <span className="align-middle">
                              {item?.renderCustomHtml?.dataFrom ===
                              "grid_selection"
                                ? `${t(item?.text ?? item?.buttonText)} ${
                                    selectedRow.length
                                  }`
                                : t(item?.text ?? item?.buttonText)}
                            </span>
                            {item.iconPos === "right" &&
                              renderIcon(
                                item.icon,
                                item?.iconClass ??
                                  (item?.buttonText ? "ms-25" : ""),
                                14
                              )}
                          </Fragment>
                        )}
                      </Button>
                    </div>
                  )
                })
              : null}

            {paramss?.pageType !== "view" &&
            (!pageAttr.disableBtnEditFromParent ||
              !pageAttr.disableBtnAddFromParent) &&
            !pageAttr?.crudOpt?.disabledAdd &&
            !pageAttr.disableBtnAddCurrent &&
            (!pageAttr?.onDashboard || pageAttr?.onDynamicCard) &&
            ability.can("post", sessionStorage.getItem("current_page_perm")) &&
            getRequiredFilterState(
              pageAttr?.crudOpt?.data?.dynamicColumnsFilter,
              store.pageData[storeName]?.params
            ) &&
            !pageAttr?.crudOpt?.btnAddTextPosition &&
            !["treeview", "orgchart", "orgchart2", "calendar"].includes(
              pageAttr?.crudOpt?.gridTheme
            ) ? (
              <Button
                ref={ref}
                className={`add-permission ${
                  pageAttr?.isViewOnRow
                    ? // ? "round mt-sm-50 mt-50 me-1 "
                      "me-25 ms-sm-1 "
                    : "mt-sm-0 mt-1 ms-sm-1 "
                }`}
                color={
                  pageAttr?.isViewOnRow || pageAttr?.isViewOnForm
                    ? btnAddCondition?.color
                      ? btnAddCondition?.color
                      : "gradient-primary"
                    : btnAddCondition?.color
                    ? btnAddCondition?.color
                    : "gradient-primary"
                }
                onClick={() => {
                  handleAddEdit(null)
                }}
              >
                {!btnAddCondition?.icon ? (
                  <Fragment>
                    {btnAddCondition?.text ??
                      pageAttr?.crudOpt?.btnAddText ??
                      `${t("Add New")} ${t(
                        pageAttr.crudTitle ??
                          (pageAttr.pageTitle?.length < 16
                            ? pageAttr.pageTitle
                            : "")
                      )}`}
                  </Fragment>
                ) : (
                  <Fragment>
                    {(btnAddCondition.iconPos === "left" ||
                      !btnAddCondition.iconPos) &&
                      renderIcon(
                        btnAddCondition.icon,
                        btnAddCondition?.iconClass ?? "",
                        14
                      )}
                    <span className="align-middle">
                      {btnAddCondition?.text ??
                        pageAttr?.crudOpt?.btnAddText ??
                        `${t("Add New")} ${t(
                          pageAttr.crudTitle ??
                            (pageAttr.pageTitle?.length < 16
                              ? pageAttr.pageTitle
                              : "")
                        )}`}
                    </span>
                    {btnAddCondition.iconPos === "right" &&
                      renderIcon(
                        btnAddCondition.icon,
                        btnAddCondition?.iconClass ?? "",
                        14
                      )}
                  </Fragment>
                )}
              </Button>
            ) : null}
          </Col>
        )}

        {!pageAttr.disableBtnEditFromParent &&
          pageAttr?.crudOpt?.data?.dynamicColumns
            ?.filter((column) => column?.editable)
            .some((column) => column?.editable) &&
          store.pageData[storeName]?.data?.length > 0 &&
          paramss?.pageType !== "view" && (
            <div className="mt-1 mt-md-50 mb-0 mb-1 mb-md-0 px-1">
              <div className="text-secondary text-italic">
                <Info size={13} className="ms-0" />
                <small
                  style={{ fontStyle: "italic" }}
                  className="ms-25 align-middle"
                >
                  {t("Please click on the grid or cell to edit the data")}
                </small>
              </div>
            </div>
          )}
      </Row>
    </div>
  )
})

export default CustomHeader
