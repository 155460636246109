/* eslint-disable brace-style */
/* eslint-disable no-var */
import { useContext, useState } from "react"
import { Row, Col, Form, CardBody, Card } from "reactstrap"
import { useTranslation } from "react-i18next"
import { AbilityContext } from "@src/utility/context/Can"
import { useSelector } from "react-redux"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import { useForm } from "react-hook-form"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "cleave.js/dist/addons/cleave-phone.us"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "@mui/material"

const DynamicFormLoading = ({ dynamicForm, storeName }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)
  const { colors } = useContext(ThemeColors)
  const [formDatas, setFormDatas] = useState({})
  const store = useSelector((state) => state.dynamicStore)

  const {
    handleSubmit,
    formState: { isDirty, isValid }
  } = useForm({})

  const renderInput = (item, index) => {
    const fieldName = item["fieldName"]
    // const fieldLabel = item["fieldLabel"]
    // const path_name = item?.fieldName?.replaceAll("id_", "")

    const colSizeFix = {
      md: item?.size ?? true,
      xs: item?.size < 4 ? 6 : 12
    }

    ////////////INPUT - IMAGE
    if (item?.type === "file_image") {
      return (
        <Col key={`col_${fieldName}`} className="mb-1" {...colSizeFix}>
          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            style={{
              border: "none",
              marginBottom: "5px",
              transform: "scale(1, 1)"
            }}
            width={100}
            height={15}
          />
          <div
            style={{
              // width: "200px",
              height: "200px",
              padding: 0,
              textAlign: "center",
              margin: "auto",
              border: "none"
            }}
            className="crop-container-dynamic form-control"
            id={`imgPreview_${fieldName}`}
          >
            <Skeleton
              sx={{ bgcolor: colors.dark.light }}
              style={{ transform: "scale(1, 1)" }}
              animation="wave"
              width="100%"
              height="100%"
            />
          </div>
        </Col>
      )
      // eslint-disable-next-line brace-style
    }
    // else if (item?.type === "textarea") {
    //   return (
    //     <Col key={`col_${fieldName}`} className="mb-1" md={colSize} xs={sizeXs}>
    //       <Skeleton
    //         sx={{ bgcolor: colors.dark.light }}
    //         animation="wave"
    //         style={{
    //           border: "none",
    //           marginBottom: "5px",
    //           transform: "scale(1, 1)"
    //         }}
    //         width={100}
    //         height={15}
    //       />
    //       <Skeleton
    //         sx={{ bgcolor: colors.dark.light }}
    //         animation="wave"
    //         style={{
    //           border: "none",
    //           borderRadius: "0.357rem",
    //           padding: "0.571rem 1rem",
    //           transform: "scale(1, 1)"
    //         }}
    //         width="100%"
    //         height={85}
    //       />
    //     </Col>
    //   )
    // }
    ////////////INPUT - TEXT & OTHERS
    else if (item?.type === "separator") {
      return (
        <Col key={`col_${index}`} className="mb-1" {...colSizeFix}>
          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            style={{
              border: "none",
              borderRadius: "0.357rem",
              padding: "0.3rem 1rem",
              transform: "scale(1, 1)"
            }}
            width="100%"
            height={8}
          />
        </Col>
      )
    }
    ////////////INPUT - TABLE
    else if (item?.type === "table") {
      return (
        <Col key={`col_${index}`} className="mb-1" {...colSizeFix}>
          {[1, 2, 3].map((item, index) => (
            <Skeleton
              sx={{ bgcolor: colors.dark.light }}
              animation="wave"
              style={{
                border: "none",
                borderRadius: "0.357rem",
                padding: "0.571rem 1rem",
                marginBottom: "0.571rem",
                transform: "scale(1, 1)"
              }}
              width="100%"
              height={38}
            />
          ))}
        </Col>
      )
    }
    ////////////INPUT - TEXT & OTHERS
    else {
      return (
        <Col key={`col_${fieldName}`} className="mb-1" {...colSizeFix}>
          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            style={{
              border: "none",
              marginBottom: "5px",
              transform: "scale(1, 1)"
            }}
            width={100}
            height={15}
          />

          <Skeleton
            sx={{ bgcolor: colors.dark.light }}
            animation="wave"
            style={{
              border: "none",
              borderRadius: "0.357rem",
              padding: "0.571rem 1rem",
              transform: "scale(1, 1)"
            }}
            width="100%"
            height={38}
          />
        </Col>
      )
    }
  }

  return (
    <Form>
      <Row className="gy-1 pt-75">
        {dynamicForm.map((item, index) => {
          const colSizeFix = {
            md: item?.size ?? true,
            xs: 12
          }
          if (item?.isHidden !== true) {
            if (item?.type === "column" && item?.children) {
              return (
                <Col key={`col_${index}_`} {...colSizeFix}>
                  <Row>
                    <Card className="saba-card-form-loading saba-card-form mt-75 mb-0">
                      <CardBody className="saba-card-form-loading-body">
                        <Row>
                          {item?.children.map((item_child, index_child) => {
                            if (item_child?.isHidden !== true) {
                              return renderInput(item_child, index_child)
                            }
                          })}
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              )
            } else {
              return renderInput(item, index)
            }
          }
        })}
      </Row>
    </Form>
  )
}

export default DynamicFormLoading
