// ** React Imports
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Utils
import { isUserLoggedIn, isAdminApp } from "@utils"

// ** Store & Actions
import { useDispatch } from "react-redux"
import { handleLogout } from "@store/authentication"

// ** Third Party Components
import {
  User,
  Mail,
  CheckSquare,
  MessageSquare,
  Settings,
  CreditCard,
  HelpCircle,
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/avatars/avatar-blank.png"

import { useTranslation } from "react-i18next"

const UserDropdown = () => {
  // ** Hooks
  const { t } = useTranslation()

  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem("userData")))
    }
  }, [])

  const handleLogoutBef = () => {
    return dispatch(handleLogout())
  }

  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {(userData && userData["name"]) || "John Doe"}
          </span>
          <span className="user-status">
            {(userData && userData.role_name) || "Admin"}
          </span>
        </div>
        {userData?.avatar ? (
          <Avatar
            img={userAvatar}
            imgHeight="30"
            imgWidth="30"
            status="online"
            color={"light-primary"}
          />
        ) : (
          <Avatar
            initials
            // size="md"
            color={"light-primary"}
            content={userData?.name || "John Doe"}
            status="online"
          />
        )}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/dyn_pages/_admin_/profile">
          <User size={14} className="me-75" />
          <span className="align-middle">{t("Profile")}</span>
        </DropdownItem>
        {/* <DropdownItem tag={Link} to="/apps/email">
          <Mail size={14} className="me-75" />
          <span className="align-middle">{t("Inbox")}</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to='/apps/todo'>
          <CheckSquare size={14} className='me-75' />
          <span className='align-middle'>Tasks</span>
        </DropdownItem> */}
        {!isAdminApp() && (
          <DropdownItem tag={Link} to="/chat">
            <MessageSquare size={14} className="me-75" />
            <span className="align-middle">{t("Chats")}</span>
          </DropdownItem>
        )}
        {/* <DropdownItem divider />
        <DropdownItem tag={Link} to="#">
          <Settings size={14} className="me-75" />
          <span className="align-middle">{t("Settings")}</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to='/pages/pricing'>
          <CreditCard size={14} className='me-75' />
          <span className='align-middle'>Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/pages/faq'>
          <HelpCircle size={14} className='me-75' />
          <span className='align-middle'>FAQ</span>
        </DropdownItem> */}
        <DropdownItem
          tag="a"
          // to="/login"
          onClick={() => handleLogoutBef()}
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">{t("Logout")}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
