/* eslint-disable no-mixed-operators */
/* eslint-disable brace-style */
import InputPasswordToggle from "@components/input-password-toggle"
import GlobalUnitDropdownForm from "@src/shared/GlobalUnitDropdownForm"
import { getCroppedImg } from "@src/utility/canvasUtils"
import { AbilityContext } from "@src/utility/context/Can"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import { useSkin } from "@hooks/useSkin"
import { useGeoLocation } from "@src/utility/hooks/useGeoLocation"

import {
  checkAndCorrectDateValue,
  disableBtnCondition,
  generateDateFormat,
  getDepthValue,
  getUserData,
  renderDropdownItem,
  selectThemeColors,
  renderAvatarImgIcon,
  isValidInteger,
  convertToEmbedUrl,
  processHtmlIndent,
  genDropdownDataYear,
  ensureArray,
  getCurrentHostName
} from "@utils"
import classnames from "classnames"
import "cleave.js/dist/addons/cleave-phone.us"
import Cleave from "cleave.js/react"
import EditorMonaco from "@monaco-editor/react"
import UILoader from "@components/ui-loader"
import { Paid } from "@mui/icons-material"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined"
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined"
import { Loader } from "@src/shared/CommonComponents"
import {
  getBaseUrl,
  resetData,
  resetSelectedData,
  getDataList,
  setDataList,
  setDynamicFiltersData
} from "@src/shared/dynamic_pages/store"
import debounce from "lodash.debounce"
import "moment-timezone"
import "moment/locale/id"
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"
import Dropzone from "react-dropzone"
import Cropper from "react-easy-crop"
import {
  ArrowLeft,
  Calendar,
  Check,
  ChevronRight,
  Clock,
  DownloadCloud,
  Edit,
  HelpCircle,
  Mail,
  Minus,
  Phone,
  Plus,
  PlusSquare,
  Printer,
  X,
  XCircle
} from "react-feather"
import Flatpickr from "react-flatpickr"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Moment from "react-moment"
import { useDispatch, useSelector } from "react-redux"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom"
import Select, { components } from "react-select"
import CreatableSelect from "react-select/creatable"
import TextareaAutosize from "react-textarea-autosize"
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledButtonDropdown,
  UncontrolledTooltip,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import CardAction2 from "@components/card-actions/ver2"
import {
  setDataCache,
  setLastApiPar,
  setParentDataRelation
} from "../../redux/dynamicTempState"
import { renderIcon } from "../../views/apps/menu/store/my_menu"
import { setGlobalFilterYear } from "@store/globalFilter"

import { ContentState, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { Editor } from "react-draft-wysiwyg"

// ** Styles
import "@styles/react/libs/editor/editor.scss"
import "@styles/react/libs/noui-slider/noui-slider.scss"

/////INPUTS RENDER
import Repeater from "@components/repeater"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SlideDown } from "react-slidedown"
import InputsRelationsCrud from "./inputs/InputRelationsCrud"
import InputsSelectTable from "./inputs/InputsSelectTable"
import InputsDropdownTable from "./inputs/InputsDropdownTable"
import InputsDropdownTreeTable from "./inputs/InputsDropdownTreeTable"
import InputWizard from "./inputs/InputsWizard"

import CodeEditor from "@uiw/react-textarea-code-editor"
// import "@uiw/react-textarea-code-editor/dist.css"
import L from "leaflet"
import icon from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import "leaflet/dist/leaflet.css"
// import Prism from "prismjs"
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
  Polygon,
  FeatureGroup,
  ImageOverlay
} from "react-leaflet"
import { EditControl } from "react-leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"
import "react-leaflet-fullscreen/styles.css"
import "leaflet-geosearch/dist/geosearch.css"

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { QRCodeSVG } from "qrcode.react"

const MySwal = withReactContent(Swal)

import IconsFeather from "@src/views/apps/menu/icons/feather"
import IconsMaterial from "@src/views/apps/menu/icons/material"

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
  // iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  // shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
})

L.Marker.prototype.options.icon = DefaultIcon

const calendarStrings = {
  lastDay: "[Yesterday]",
  // sameDay: "[Today]",
  nextDay: "[Tomorrow]",
  lastWeek: "DD/MM/yyyy [Jam] HH:mm",
  nextWeek: "dddd",
  sameElse: "L"
}

// ** Third Party Components
import InputNumber from "rc-input-number"

// ** Styles
import "@styles/react/libs/input-number/input-number.scss"
import "./style.scss"
import { SketchPicker } from "react-color"
import InputsTable from "./inputs/InputsTable"

////MONACO
const MAX_HEIGHT = 600
const MIN_COUNT_OF_LINES = 9
const LINE_HEIGHT = 20

const DynamicForm = ({
  dynamicForm = [],
  pageAttr,
  storeName,
  handleReset,
  handleFormSubmit,
  defaultFormValues = {},
  editForm = true,
  filterMode = false,
  tableHeaderMode = false,
  filterModeCalendar = false,
  chartFilter = false,
  handleFiltersChangeToParent,
  reportMode = false,
  handleFormSubmitPrint,
  pageType,
  showDeleteButton = false,
  handleDelete,
  hideAllFotterButton = false,
  cardClassNames = "",
  cardBodyClassNames = "",
  handleFormChangeToParent,
  onlyInputMode = false
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const ability = useContext(AbilityContext)
  const [searchParams] = useSearchParams()
  const { skin } = useSkin()
  const { colors } = useContext(ThemeColors)
  const dispatch = useDispatch()
  const userData = getUserData()
  const [useEffectFinish, setUseEffectFinish] = useState(false)
  const { moduleId, pageId, id } = useParams()
  const [storeNameParent, setStoreNameParent] = useState(
    moduleId ? `${moduleId}_${pageId}` : `${pageId}`
  )
  const storeGlobalFilter = useSelector((state) => state.global_filter)
  const storeDomain = useSelector((state) => state.domain_claims)
  function isDateString(input) {
    // Regular expression to match date in 'YYYY-MM-DD' format
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/

    return typeof input === "string" && dateRegex.test(input)
  }

  function isDate(input) {
    return !isNaN(Date.parse(input)) && !isNaN(new Date(input).getDate())
  }

  // const allStore = useSelector((state) => state)
  const store = useSelector((state) => state.dynamicStore)
  const storeInvoices = useSelector((state) => state.invoices)
  const storeUnit = useSelector((state) => state.units)

  const dynamicTempState = useSelector((state) => state.dynamic_temp_state)
  const [isLoadingReport, setIsLoadingReport] = useState(false)
  const [changedFieldName, setChangedFieldName] = useState([])
  const [selectedValuesCheckbox, setSelectedValuesCheckbox] = useState({})

  const {
    reset,
    // resetField,
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    setFocus,
    watch,
    formState: { errors, isDirty, isValid }
  } = useForm({
    // mode: "onSubmit",
    mode: "all",
    // reValidateMode: "all",
    defaultValues: defaultFormValues
    // resolver: undefined,
    // context: undefined,
    // criteriaMode: "firstError",
    // shouldFocusError: true,
    // shouldUnregister: false,
    // shouldUseNativeValidation: false,
    // delayError: undefined
  })

  const [resetSelectTable, setResetSelectTable] = useState(false)

  const [isFullScreenImage, setIsFullScreenImage] = useState(false)
  const [isFullScreenImageFile, setIsFullScreenImageFile] = useState(null)

  const onChangeAllEvent = () => {
    // if (!pageAttr?.relationsCrudKey) {
    // console.log(getValues())
    if (!onlyInputMode) {
      let newValues = JSON.parse(JSON.stringify(getValues() ?? {}))
      setResetSelectTable(true)
      dispatch(setParentDataRelation(newValues)).then(() =>
        setResetSelectTable(false)
      )
    }

    if (handleFormChangeToParent) {
      // console.log(getValues())
      handleFormChangeToParent(getValues())
    }
    // SET DRAFT ON ADD
    // console.log(pageType)
    // if (pageType === "add") {
    //   let location = window.location.pathname

    //   let newDraft = {
    //     id: pageId,
    //     location: location,
    //     values: getValues()
    //   }

    //   let lastDraftDataStore = sessionStorage.getItem("form_add_draft") ?? "[]"
    //   let lastDraftData = JSON.parse(lastDraftDataStore)

    //   const index = lastDraftData.findIndex(
    //     (item) => item.id === newDraft.id || item.location === newDraft.location
    //   )
    //   if (index !== -1) {
    //     lastDraftData[index] = { ...lastDraftData[index], ...newDraft }
    //   } else {
    //     lastDraftData.push(newDraft)
    //   }

    //   sessionStorage.setItem("form_add_draft", JSON.stringify(lastDraftData))
    // }

    // }
  }

  const clearDate = (field, item, ref) => {
    // console.log(ref.current)
    field.onChange(null)
    if (filterMode) {
      beforeHandleFiltersChangeToParent()
    }
    onChangeAll()
    onChangeAll2(item?.fieldName)
    // ref?.current?.flatpickr?.clear()
  }

  const onChangeAll2 = (fieldName) => {
    // console.log("🚀 ~ onChangeAll2 ~ fieldName:", fieldName)
    // console.log(pageType)
    if (fieldName && pageType === "edit") {
      // console.log(defaultFormValues?.fieldName, getValues(fieldName))
      // console.log("🚀 ~ onChangeAll2 ~ defaultFormValues:", defaultFormValues)
      let fieldNameFix = fieldName
      if (typeof fieldName === "object") {
        fieldNameFix = fieldName?.fieldName
      }
      // console.log("🚀 ~ onChangeAll2 ~ fieldNameFix:", fieldNameFix)
      // console.log(defaultFormValues?.[fieldNameFix])
      // console.log(getValues(fieldNameFix))
      // console.log(defaultFormValues?.[fieldNameFix] !== getValues(fieldNameFix))
      // console.log(defaultFormValues?.[fieldNameFix], getValues(fieldNameFix))
      if (
        // getValues(fieldName) &&
        // defaultFormValues?.[fieldName] &&
        defaultFormValues?.[fieldNameFix] !== getValues(fieldNameFix)
      ) {
        setChangedFieldName((prevNames) => [...prevNames, fieldNameFix])
      } else {
        setChangedFieldName((prevNames) =>
          prevNames.filter((name) => name !== fieldNameFix)
        )
      }
    }
  }

  /////LEAFLET - POLYGON
  const geoLocation = dynamicForm?.some((f) => f.type === "map-polygon")
    ? useGeoLocation()
    : null

  const def_polygon = [
    [51.515, -0.09],
    [51.52, -0.1],
    [51.52, -0.12]
  ]

  const [mapLayers, setMapLayers] = useState(def_polygon)
  const mapRefPolygon = useRef({})

  function getPolygonCenter(polygonCoords) {
    // console.log(polygonCoords)
    if (!Array.isArray(polygonCoords) || polygonCoords.length === 0) {
      return [NaN, NaN] // Handle empty or non-array input
    }

    let latSum = 0
    let lngSum = 0
    const numPoints = polygonCoords.length

    // Sum up the coordinates
    for (const point of polygonCoords) {
      if (!Array.isArray(point) || point.length !== 2) {
        return [NaN, NaN] // Handle invalid coordinate format
      }
      const [lat, lng] = point
      if (typeof lat !== "number" || typeof lng !== "number") {
        return [NaN, NaN] // Handle non-numeric coordinates
      }
      latSum += lat
      lngSum += lng
    }

    // Calculate the average
    const avgLat = latSum / numPoints
    const avgLng = lngSum / numPoints
    // console.log([avgLat, avgLng])
    return [avgLat, avgLng]
  }

  // function LeafletGeoSearch() {
  //   const map = useMap()
  //   useEffect(() => {
  //     const provider = new OpenStreetMapProvider()

  //     const searchControl = new GeoSearchControl({
  //       provider,
  //       style: "bar",
  //       searchLabel: t("Search Address or Coordinate"),
  //       //       autoComplete: true, // optional: true|false  - default true
  //       // autoCompleteDelay: 250, // optional: number      - default 250
  //       //       popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label,
  //       // resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
  //       // maxMarkers: 1, // optional: number      - default 1
  //       // retainZoomLevel: false, // optional: true|false  - default false
  //       // animateZoom: true, // optional: true|false  - default true
  //       // autoClose: false, // optional: true|false  - default false
  //       // searchLabel: 'Enter address', // optional: string      - default 'Enter address'
  //       // keepResult: false, // optional: true|false  - default false
  //       // updateMap: true, // optional: true|false  - default true
  //       // showMarker: true, // optional: true|false  - default true
  //       // showPopup: false, // optional: true|false  - default false
  //       marker: L.icon({
  //         // iconSize: [25, 41],
  //         // iconAnchor: [10, 41],
  //         // popupAnchor: [2, -40],
  //         iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  //         shadowUrl:
  //           "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
  //       })
  //     })

  //     map.addControl(searchControl)

  //     return () => map.removeControl(searchControl)
  //   }, [])

  //   return null
  // }

  const featureGroupRef = useRef(null)
  // const onFeatureGroupReady = (reactFGref) => {
  //   setEditableFG(reactFGref)
  // }

  const getCoordinateFromLayer = (layerType, layer, layer2) => {
    // console.log(layerType, layer, layer2)
    let coordinates
    if (layerType === "polygon") {
      // FOR MULTI POLYGON
      // const { _leaflet_id } = layer
      // setMapLayers((layers) => [
      //   ...layers,
      //   { id: _leaflet_id, latlngs: layer.getLatLngs()[0] }
      // ])
      // if (layer.getLatLngs()?.length > 0) {
      if (layer) {
        coordinates = layer
          .getLatLngs()[0]
          .map((itemz) => [itemz.lat, itemz.lng])
      } else {
        coordinates = layer2?.latlngs?.[0]?.[0]?.map((itemz) => [
          itemz.lat,
          itemz.lng
        ])
      }
    } else if (layerType === "polyline") {
      if (layer) {
        coordinates = layer?.getLatLngs()?.map((item) => [item.lat, item.lng])
      } else {
        coordinates = layer2?.latlngs?.[0]?.map((itemz) => [
          itemz.lat,
          itemz.lng
        ])
      }
    } else if (layerType === "marker") {
      if (layer) {
        coordinates = [layer?._latlng?.lat, layer?._latlng?.lng]
      } else {
        coordinates = [
          layer2?._marker?._latlng?.lat,
          layer2?._marker?._latlng?.lng
        ]
      }
    } else {
      // console.log(layerType)
      // console.log("🚀 ~ layer:", layer?.getLatLngs())
      // console.log("🚀 ~ layer2:", layer2)
    }
    return coordinates
  }

  const _onCreatePolygon = (e, item, field) => {
    if (e.layerType === "marker") {
      const marker = e.layer
      marker.setIcon(DefaultIcon)
    }
    // console.log(e)
    const featureGroup = featureGroupRef.current
    if (featureGroup) {
      featureGroup.clearLayers()
    }

    const { layerType, layer } = e
    featureGroup.addLayer(layer)

    let coordinates = getCoordinateFromLayer(layerType, layer)
    let mainMarker = item?.mapOtions?.mainMarker

    if (coordinates) {
      let mapPositionCopy = { ...mapPosition }
      mapPositionCopy[item?.fieldName] = coordinates
      setMapPosition(mapPositionCopy)
      // setMapPosition((prevMapPosition) => ({
      //   ...prevMapPosition,
      //   [item?.fieldName]: coordinates
      // }))
      let coordinateStr = JSON.stringify(coordinates)
      // console.log(layerType, layerType !== "marker", `${coordinates}`)
      if (layerType === "marker") {
        coordinateStr = coordinateStr?.replaceAll("[", "").replaceAll("]", "")
      }
      setValue(item?.fieldName, coordinateStr)
      field.onChange(coordinateStr)
      afterChangeEvent(coordinateStr, item)
      // onChangeAll()
      onChangeAll2(item?.fieldName)

      if (
        item?.mapOtherOpt?.calcDistanceFromMainMarker &&
        mainMarker &&
        layerType === "marker"
      ) {
        let latlng1 = L.latLng(coordinates[0], coordinates[1])
        let latlng2 = L.latLng(mainMarker[0], mainMarker[1])
        let distanceInMeters = latlng1.distanceTo(latlng2)
        let distanceInKilometers = distanceInMeters / 1000
        let roundedDistanceInKilometers = distanceInKilometers.toFixed(1)
        // console.log(`${roundedDistanceInKilometers} kilometers`)
        let mapDistanceFromMainCopy = { ...mapDistanceFromMain }
        mapDistanceFromMainCopy[item?.fieldName] = roundedDistanceInKilometers
        setMapDistanceFromMain(mapDistanceFromMainCopy)

        if (item?.mapOtherOpt?.calcDistanceFromMainMarker?.fieldName) {
          setValue(
            item?.mapOtherOpt?.calcDistanceFromMainMarker?.fieldName,
            roundedDistanceInKilometers
          )
        }
      }
    }
  }

  const _onEditedPolygon = (e, item, field) => {
    // console.log(e)
    const {
      layers: { _layers }
    } = e

    if (e.layerType === "marker") {
      const marker = e.layer
      marker.setIcon(DefaultIcon)
    }

    // Object.values(_layers).map(({ _leaflet_id, editing }) => {
    //   console.log("🚀 ~ Object.values ~ editing:", editing)
    // })

    Object.values(_layers).map((layer) => {
      // console.log(layer.editing)
      // console.log("🚀 ~ Object.values ~ editing:", layer.editing)
      let layerType

      if (layer instanceof L.Polygon) {
        layerType = "polygon"
      } else if (layer instanceof L.Polyline) {
        layerType = "polyline"
      } else if (layer instanceof L.Marker) {
        layerType = "marker"
      } else if (layer instanceof L.Rectangle) {
        layerType = "rectangle"
      }

      let coordinates = getCoordinateFromLayer(layerType, null, layer.editing)

      // let coordinates = layer.editing.latlngs?.[0]?.[0].map((itemz) => [
      //   itemz.lat,
      //   itemz.lng
      // ])
      // console.log(coordinates)
      let mapPositionCopy = { ...mapPosition }
      mapPositionCopy[item?.fieldName] = coordinates
      setMapPosition(mapPositionCopy)
      let coordinateStr
      // console.log(layerType, coordinates)
      if (layerType !== "marker") {
        coordinateStr = JSON.stringify(coordinates)
      } else {
        coordinateStr = `${coordinates}`
          ?.replaceAll("[", "")
          .replaceAll("]", "")
      }
      // console.log("🚀 ~ Object.values ~ coordinateStr:", coordinateStr)
      setValue(item?.fieldName, coordinateStr)
      field.onChange(coordinateStr)
      afterChangeEvent(coordinateStr, item)
      // onChangeAll()
      onChangeAll2(item?.fieldName)
    })

    // Object.values(_layers).map(({ _leaflet_id, editing }) => {
    //   console.log("🚀 ~ Object.values ~ editing:", editing)
    //   // FOR MULTI POLYGON
    //   // setMapLayers((layers) =>
    //   //   layers.map((l) =>
    //   //     l.id === _leaflet_id
    //   //       ? { ...l, latlngs: { ...editing.latlngs[0] } }
    //   //       : l
    //   //   )
    //   // )
    //   let coordinates = editing.latlngs?.[0]?.[0].map((itemz) => [
    //     itemz.lat,
    //     itemz.lng
    //   ])
    //   // console.log(coordinates)
    //   // setMapLayers(coordinates)
    //   let mapPositionCopy = { ...mapPosition }
    //   mapPositionCopy[item?.fieldName] = coordinates
    //   setMapPosition(mapPositionCopy)
    //   const coordinateStr = JSON.stringify(coordinates)
    //   // console.log("🚀 ~ Object.values ~ coordinateStr:", coordinateStr)
    //   setValue(item?.fieldName, coordinateStr)
    //   afterChangeEvent(coordinateStr, item)
    //   onChangeAll()
    //   onChangeAll2(item?.fieldName)
    // })
  }

  const _onDeletedPolygon = (e, item) => {
    // console.log(e)
    const {
      layers: { _layers }
    } = e

    Object.values(_layers).map(({ _leaflet_id }) => {
      // FOR MULTI POLYGON
      // setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id))
      // setMapLayers([])
      let mapPositionCopy = { ...mapPosition }
      mapPositionCopy[item?.fieldName] = []
      setMapPosition(mapPositionCopy)
      if (layerType !== "marker") {
        setValue(item?.fieldName, "[]")
        field.onChange("[]")
        afterChangeEvent("[]", item)
      } else {
        setValue(item?.fieldName, "")
        field.onChange("")
        afterChangeEvent("", item)
      }

      onChangeAll()
      onChangeAll2(item?.fieldName)
    })
  }

  /////MONACO
  const [height, setHeight] = useState(170)
  const valueGetter = useRef()

  const handleEditorChange = useCallback((_) => {
    const countOfLines = valueGetter.current().split("\n").length
    if (countOfLines >= MIN_COUNT_OF_LINES) {
      const currentHeight = countOfLines * LINE_HEIGHT
      if (MAX_HEIGHT > currentHeight) {
        setHeight(currentHeight)
      }
    } else {
      setHeight(100)
    }
  }, [])

  const handleEditorDidMount = useCallback(
    (_valueGetter, editor) => {
      valueGetter.current = _valueGetter
      editor.onDidChangeModelContent(handleEditorChange)
    },
    [handleEditorChange]
  )

  const onChangeAll = useCallback(debounce(onChangeAllEvent, 1000), [])

  const [hiddenInput, setHiddenInput] = useState({})
  const [fieldDesc, setFieldDesc] = useState({})
  const [disabledInputs, setDisabledInputs] = useState({})
  const [dynamicComponents, setDynamicComponents] = useState({})
  const [formDatas, setFormDatas] = useState({})
  const [formDatasError, setFormDatasError] = useState({})
  const [inputsFinal, setInputsFinal] = useState([])

  //// FILES & CROP
  const [filesDefaultTemp, setFilesDefaultTemp] = useState({})
  const [colorPickersTemp, setColorPickersTemp] = useState({})

  const [uploadImagesTemp, setUploadImagesTemp] = useState({})
  const [imgPreview, setImgPreview] = useState({})
  const [crop, setCrop] = useState({})
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState({})
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({})
  const [removePayload, setRemovePayload] = useState([])
  const [tempAsyncOptValues, setTempAsyncOptValues] = useState({})
  const [tempAsyncOptLoading, setTempAsyncOptLoading] = useState({})
  const [dropdownAsTableModal, setDropdownAsTableModal] = useState({})
  const [dropdownAsTableModalCurSelected, setDropdownAsTableModalCurSelected] =
    useState(null)
  const [tempLastQuery, setTempLastQuery] = useState([])
  const [tempLastAutoCompOpt, setTempLastAutoCompOpt] = useState([])
  const [tempAsyncOptCreateLoading, setTempAsyncOptCreateLoading] = useState({})
  const [tempSliderVal, setTempSliderVal] = useState([])
  const [inputLabelRef, setInputLabelRef] = useState({})
  // const [editorValue, setEditorValue] = useState(EditorState.createEmpty())
  const [editorValue, setEditorValue] = useState({})

  const [iconCategory, setIconCategory] = useState("feather")
  // const [icon, setIcon] = useState({})
  const [iconCurrentFieldName, setIconCurrentFieldName] = useState({})
  const [canvasPlacement, setCanvasPlacement] = useState("bottom")
  const [canvasOpen, setCanvasOpen] = useState(false)
  const toggleCanvasStart = () => {
    setIconCategory("feather")
    setCanvasPlacement("start")
    setCanvasOpen(!canvasOpen)
  }
  watch("icon")
  const toggleCanvasBottom = (fieldName) => {
    setIconCurrentFieldName(fieldName)
    setIconCategory("feather")
    setCanvasPlacement("bottom")
    setCanvasOpen(!canvasOpen)
  }
  const setIconSelected = (value) => {
    setValue(iconCurrentFieldName, value, {
      shouldValidate: true,
      shouldDirty: true
    })
    onChangeAll()
    onChangeAll2(iconCurrentFieldName)
  }

  const [activeTab, setActiveTab] = useState("")
  const isFormRepeater = dynamicForm.find((x) => x?.type === "form-repeater")
  const toggleTab = (tab, item_childs = null) => {
    if (activeTab !== tab) {
      // console.log(item_childs)
      item_childs?.tabContent?.map((item_child, index_child) => {
        // inputLabelRef[item?.fieldName] = useRef(null)
        // console.log(item_child)
        if (item_child?.type === "relationsCrud") {
          if (item_child?.relationsCrud?.crudOpt?.getAlwaysGrid === true) {
            // console.log("reload")
            const pageIdRel = item_child?.relationsCrud?.pageId
            const storeNameRelCrud = moduleId
              ? `${moduleId}_${pageIdRel}`
              : `${pageIdRel}`
            // console.log(storeNameRelCrud)

            const params = {
              storeName: storeNameRelCrud,
              endpointName: item_child?.relationsCrud?.crudOpt?.endpointName,
              baseUrl: item_child?.relationsCrud?.crudOpt?.baseUrl
            }

            let curPageLastParam = store.pageData[storeNameRelCrud]?.params
            // console.log(store.pageData[storeNameRelCrud]?.params)
            dispatch(getDataList({ ...params, ...curPageLastParam }))
          }
        }
        // renderInput(item_child, index_child)
      })
      setActiveTab(tab)
    }
  }

  ///////COLOR PICKER
  const handleClickColorPicker = (fieldName) => {
    // console.log(fieldName)
    // colorPickersTemp[fieldName] = {
    //   displayColorPicker: true,
    //   color: colorPickersTemp[fieldName]?.color
    // }
    // setColorPickersTemp()
    setColorPickersTemp({
      ...colorPickersTemp,
      [fieldName]: {
        displayColorPicker: !colorPickersTemp[fieldName]?.displayColorPicker,
        color: colorPickersTemp[fieldName]?.color
      }
    })
    // this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  const handleCloseColorPicker = (fieldName) => {
    // colorPickersTemp[fieldName] = {
    //   displayColorPicker: false,
    //   color: colorPickersTemp[fieldName]?.color
    // }
    setColorPickersTemp({
      ...colorPickersTemp,
      [fieldName]: {
        displayColorPicker: false,
        color: colorPickersTemp[fieldName]?.color
      }
    })
    // this.setState({ displayColorPicker: false })
  }

  const handleChangeColorPicker = (fieldName, color) => {
    // colorPickersTemp[fieldName] = {
    //   displayColorPicker: colorPickersTemp[fieldName]?.displayColorPicker,
    //   color: color.hex
    // }
    setColorPickersTemp({
      ...colorPickersTemp,
      [fieldName]: {
        displayColorPicker: colorPickersTemp[fieldName]?.displayColorPicker,
        color: color
      }
    })
    // this.setState({ color: color.rgb })
  }

  ///////REPEATING FORM
  const [count, setCount] = useState(1)
  const increaseCount = () => {
    setCount(count + 1)
  }
  const decreaseCount = () => {
    setCount(count - 1)
  }
  const deleteForm = (e) => {
    e.preventDefault()
    decreaseCount()
    // const slideDownWrapper = e.target.closest(".react-slidedown"),
    //   form = e.target.closest("form")
    // if (slideDownWrapper) {
    //   slideDownWrapper.remove()
    // } else {
    //   form.remove()
    // }
  }
  let loadingFirstOnce = useRef(false)
  // let afterChangeUnitCount = 0
  const [afterChangeUnitCount, setAfterChangeUnitCount] = useState(0)

  //////MAP
  const [mapPosition, setMapPosition] = useState({})
  const [mapDistanceFromMain, setMapDistanceFromMain] = useState({})
  const [mapPositionLatLng, setMapPositionLatLng] = useState({})
  const [defaultValueMapFixState, setDefaultValueMapFixState] = useState({})
  const mapCenterDefault = [-0.789275, 113.921327]
  let mapDefaultOpt = {
    zoom: 5,
    maxZoom: 20,
    scrollWheelZoom: true,
    center: mapCenterDefault,
    bounds: def_polygon,
    boundsOptions: { padding: [1, 1] }
  }
  let mapDefaultStyle = {
    height: "450px",
    borderRadius: "7px",
    zIndex: 0
  }

  // const whenMapPolygonCreated = (defaultValueMapFix, mapOpt) => {
  //   console.log("map polygon created")
  //   let isPolygon = true
  //   if (!defaultValueMapFix && pageType === "add") {
  //     if (geoLocation?.coordinates?.lat && geoLocation?.coordinates?.lng) {
  //       setTimeout(() => {
  //         mapRefPolygon.current?.flyTo(
  //           [geoLocation.coordinates.lat, geoLocation.coordinates.lng],
  //           mapDefaultOpt?.zoom,
  //           { animate: true, duration: 1.5 }
  //         )
  //       }, 200)
  //     } else {
  //       if (!mapOpt?.mainMarker) {
  //         mapDefaultOpt["center"] = mapOpt?.center ?? mapCenterDefault
  //         setTimeout(() => {
  //           mapRefPolygon.current.setZoom(mapOpt?.center ? 19 : 7)
  //         }, 200)
  //       } else {
  //         setTimeout(() => {
  //           mapRefPolygon.current?.flyTo(
  //             mapOpt.mainMarker,
  //             mapDefaultOpt?.zoom,
  //             { animate: true, duration: 1.5 }
  //           )
  //         }, 200)
  //       }
  //     }
  //   } else {
  //     if (defaultValueMapFix) {
  //       if (isPolygon) {
  //         const boundss = getBoundsFromMarkers(defaultValueMapFix)

  //         setTimeout(() => {
  //           // let centerMap = getPolygonCenter(defaultValueMapFix[0])
  //           // console.log(centerMap)
  //           // // mapRefPolygon.current.setView(defaultValueMapFix, 20)
  //           // mapRefPolygon.current.flyTo(centerMap, 20, {
  //           //   animate: true,
  //           //   duration: 1.5
  //           // })
  //           // mapRefPolygon.current.setZoom(18)
  //           mapRefPolygon.current.fitBounds(boundss)
  //           mapRefPolygon.current.setZoom(17)
  //         }, 200)
  //         // setTimeout(() => {
  //         //   mapRefPolygon.current.setZoom(20)
  //         // }, 250)
  //       } else {
  //         setTimeout(() => {
  //           mapRefPolygon.current?.flyTo(defaultValueMapFix, 17, {
  //             animate: true,
  //             duration: 0.5
  //           })
  //         }, 200)

  //         // console.log(defaultValueMapFix)
  //         // setTimeout(() => {
  //         //   let coordinates = defaultValueMapFix
  //         //   let mainMarker = item?.mapOtions?.mainMarker
  //         //   if (
  //         //     item?.mapOtherOpt?.calcDistanceFromMainMarker &&
  //         //     mainMarker
  //         //   ) {
  //         //     let latlng1 = L.latLng(coordinates[0], coordinates[1])
  //         //     let latlng2 = L.latLng(mainMarker[0], mainMarker[1])
  //         //     let distanceInMeters = latlng1.distanceTo(latlng2)
  //         //     let distanceInKilometers = distanceInMeters / 1000
  //         //     let roundedDistanceInKilometers =
  //         //       distanceInKilometers.toFixed(1)
  //         //     // console.log(`${roundedDistanceInKilometers} kilometers`)
  //         //     let mapDistanceFromMainCopy = { ...mapDistanceFromMain }
  //         //     mapDistanceFromMainCopy[item?.fieldName] =
  //         //       roundedDistanceInKilometers
  //         //     setMapDistanceFromMain(mapDistanceFromMainCopy)

  //         //     if (
  //         //       item?.mapOtherOpt?.calcDistanceFromMainMarker?.fieldName
  //         //     ) {
  //         //       setValue(
  //         //         item?.mapOtherOpt?.calcDistanceFromMainMarker?.fieldName,
  //         //         roundedDistanceInKilometers
  //         //       )
  //         //     }
  //         //   }
  //         // }, 2000)
  //       }
  //     } else {
  //       if (!mapOpt?.mainMarker) {
  //         mapDefaultOpt["center"] = mapOpt?.center ?? mapCenterDefault
  //         setTimeout(() => {
  //           mapRefPolygon.current.setZoom(mapOpt?.center ? 19 : 7)
  //         }, 200)
  //       } else {
  //         setTimeout(() => {
  //           mapRefPolygon.current?.flyTo(
  //             mapOpt.mainMarker,
  //             mapDefaultOpt?.zoom,
  //             { animate: true, duration: 1.5 }
  //           )
  //         }, 200)
  //       }
  //     }
  //   }
  // }

  const getBoundsFromMarkers = (markerPositions) => {
    let minLat = Infinity
    let maxLat = -Infinity
    let minLng = Infinity
    let maxLng = -Infinity

    // Iterate through all marker positions
    markerPositions.forEach((position) => {
      // If the position is an array of coordinates
      if (position) {
        if (Array.isArray(position[0])) {
          position.forEach((coord) => {
            const [lat, lng] = coord
            // Update minimum and maximum latitude and longitude
            minLat = Math.min(minLat, lat)
            maxLat = Math.max(maxLat, lat)
            minLng = Math.min(minLng, lng)
            maxLng = Math.max(maxLng, lng)
          })
        } else {
          // If the position is a single coordinate
          const [lat, lng] = position
          // Update minimum and maximum latitude and longitude
          minLat = Math.min(minLat, lat)
          maxLat = Math.max(maxLat, lat)
          minLng = Math.min(minLng, lng)
          maxLng = Math.max(maxLng, lng)
        }
      }
    })

    return [
      [minLat, minLng],
      [maxLat, maxLng]
    ]
  }

  function LocationMarker2({ item }) {
    const fieldName = item?.fieldName
    const [position, setPosition] = useState(null)
    const [bbox, setBbox] = useState([])

    const map = useMap()

    useEffect(() => {
      map.locate().on("locationfound", function (e) {
        setPosition(e?.latlng)
        map.flyTo(e?.latlng, map.getZoom())
        const radius = e.accuracy
        const circle = L.circle(e?.latlng, radius)
        circle.addTo(map)
        setBbox(e.bounds.toBBoxString().split(","))

        const locStr = `${e?.latlng?.lat},${e?.latlng?.lng}`
        // console.log(locStr)
        mapPositionLatLng[fieldName] = e.latlng
        mapPosition[fieldName] = locStr
        setValue(fieldName, locStr)
        afterChangeEvent(locStr, item)
      })
    }, [map])

    useMapEvents({
      // locationfound(e) {
      //   // setPosition(e.latlng)
      //   map.flyTo(e.latlng, map.getZoom())
      // },
      click(e) {
        const locStr = `${e.latlng.lat},${e.latlng.lng}`
        // console.log(locStr)

        // let mapPositionCopy = { ...mapPosition }
        // mapPositionCopy[fieldName] = locStr
        // setMapPosition(mapPositionCopy)

        // let mapPositionLatLngCopy = { ...mapPositionLatLng }
        // mapPositionLatLngCopy[fieldName] = e.latlng
        // setMapPositionLatLng(mapPositionLatLngCopy)

        mapPositionLatLng[fieldName] = e.latlng
        mapPosition[fieldName] = locStr
        setValue(fieldName, locStr)
        afterChangeEvent(locStr, item)

        // console.log(mapPosition, mapPositionLatLng)
        // map.locate()
        setPosition(e.latlng)

        // map.flyTo(e.latlng, map.getZoom())
      }
    })

    return position === null ? null : (
      <Marker position={position}>
        <Popup>
          You are here. <br />
        </Popup>
      </Marker>
    )
  }

  function LocationMarker({
    item,
    defValue,
    setChangedFieldName,
    changedFieldName
  }) {
    // console.log("LocationMarker")
    // console.log(item, defValue)
    let fixPositionInit = defValue
    if (typeof defValue === "string" && defValue !== "") {
      if (defValue.startsWith("[")) {
        fixPositionInit = JSON.parse(defValue)
      } else {
        fixPositionInit = defValue?.split(",")
      }
    }

    const [position, setPosition] = useState(fixPositionInit ?? null)

    const fieldName = item?.fieldName
    const map = useMapEvents({
      click(e) {
        const locStr = `${e.latlng.lat},${e.latlng.lng}`
        mapPositionLatLng[fieldName] = e.latlng
        mapPosition[fieldName] = locStr
        setValue(fieldName, locStr)

        afterChangeEvent(locStr, item)
        setPosition(e.latlng)
      }
    })

    // useEffect(() => {
    //   if (position !== fixPositionInit) {
    //     let changedFieldNameCopy = [...changedFieldName, fieldName]
    //     setChangedFieldName(changedFieldNameCopy)
    //   }
    // }, [position])

    return position === null ? null : (
      <Marker position={position}>
        <Popup>{t("You are here")}</Popup>
      </Marker>
    )
  }

  const LeafletGeoSearch = () => {
    const map = useMap()

    useEffect(() => {
      const provider = new OpenStreetMapProvider({
        params: {
          countrycodes: "ID" // Membatasi pencarian hanya di Indonesia
        }
      })

      const searchControl = new GeoSearchControl({
        provider,
        style: "bar",
        searchLabel: t("Search Address or Coordinate"),
        marker: {
          icon: L.icon({
            iconUrl:
              "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
            shadowUrl:
              "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
          })
        },
        updateMap: true, // optional: true|false  - default true
        showMarker: true, // optional: true|false  - default true
        retainZoomLevel: false,
        autoClose: true,
        keepResult: true,
        animateZoom: true
        // resultFormat: ({ result }) => result.label,
        // onResults: (data) => {
        //   const westJavaBoundingBox = {
        //     minLat: -7.795, // Southern boundary
        //     maxLat: -5.837, // Northern boundary
        //     minLon: 106.036, // Western boundary
        //     maxLon: 108.801 // Eastern boundary
        //   }

        //   const filteredResults = data.filter((result) => {
        //     return (
        //       result.y >= westJavaBoundingBox.minLat &&
        //       result.y <= westJavaBoundingBox.maxLat &&
        //       result.x >= westJavaBoundingBox.minLon &&
        //       result.x <= westJavaBoundingBox.maxLon
        //     )
        //   })

        //   // setResults(filteredResults)
        // }
      })

      map.addControl(searchControl)

      return () => map.removeControl(searchControl)
    }, [map])

    return null
  }

  ////FILTER COMPONENT
  const filterComponent = () => {
    const mainFilterComponent = (
      <Row>
        {(pageAttr?.pageTitlePos === "table-header" ||
          pageAttr?.crudOpt?.pageTitlePos === "table-header") &&
          (pageAttr?.pageTitle || pageAttr?.pageDesc) && (
            <Col className="d-flex align-items-center">
              {pageAttr?.menuIcon
                ? renderIcon(pageAttr?.menuIcon, "me-1 align-middle", 20, {})
                : null}
              <span className="fw-bold align-middle mb-1">
                <h4
                  style={{
                    ...(pageAttr?.cardTitleStyle ??
                      pageAttr?.pageTitleStyle ??
                      {})
                  }}
                >
                  {t(pageAttr?.pageTitle)}
                </h4>
                {pageAttr?.pageDesc && (
                  <small
                    style={{
                      ...(pageAttr?.cardDescStyle ??
                        pageAttr?.pageDescStyle ??
                        {})
                    }}
                    className="fw-normal mb-1"
                  >
                    {t(pageAttr?.pageDesc)}
                  </small>
                )}
              </span>
            </Col>
          )}
        {dynamicForm.map((item, index) => {
          const fieldName = item["fieldName"]
          formDatas[fieldName] = null
          formDatasError[fieldName] = false

          let colSize = {}
          if (item?.size) {
            colSize = {
              xxl: item?.size?.xxl ?? item?.size,
              xl: item?.size?.xl ?? item?.size,
              lg: item?.size?.lg ?? item?.size,
              md: item?.size?.md ?? item?.size,
              sm: item?.size?.sm ?? item?.size,
              xs: item?.size?.xs ?? (item?.size < 4 ? 6 : 12)
            }
          }
          const abiltyToInput = item?.ability?.name
          let canViewInput = true
          if (abiltyToInput) {
            canViewInput = ability.can(
              item?.ability?.method,
              item?.ability?.name
            )
          }
          if (!abiltyToInput || canViewInput) {
            if (item["type"] === "column" && item?.children) {
              return (
                <Col key={`col_${index}_`} {...colSize}>
                  <Row>
                    {item?.children.map((item_child) => {
                      return renderInput(item_child)
                    })}
                  </Row>
                </Col>
              )
            } else {
              return renderInput(item)
            }
          }
        })}
      </Row>
    )

    if (!tableHeaderMode) {
      return (
        <Card
          style={
            pageAttr?.onDashboard
              ? {
                  boxShadow: "none"
                }
              : {
                  zIndex: filterMode ? 3 : "inherit"
                }
          }
          className={`${reportMode ? "mb-0" : ""} ${cardClassNames}`}
        >
          {/* {filterMode && (
            <CardHeader className="d-none d-md-block">
              <CardTitle tag="h4">{t("Filters")}</CardTitle>
            </CardHeader>
          )} */}

          <CardBody className={`mt-1 mt-md-0 py-75 ${cardBodyClassNames}`}>
            <Form
              onSubmit={(event) => {
                event.preventDefault()
                handleSubmit(beforeHandleFormSubmit(getValues(), "pdf", true))
              }}
            >
              <UILoader
                blocking={storeInvoices.isLoading || isLoadingReport}
                loader={<Loader loadingText={`${t("Exporting Report")}...`} />}
              >
                {reportMode && (
                  <Row>
                    <Col md={12} className="mb-1">
                      <div className="mt-2 mb-1">
                        {!pageAttr?.reportOpt?.title ? (
                          <h5>
                            {t("Print Report")} "{t(pageAttr.pageTitle)}"
                          </h5>
                        ) : (
                          <h3>{pageAttr?.reportOpt?.title}</h3>
                        )}
                        {pageAttr?.reportOpt?.description && (
                          <p>{pageAttr?.reportOpt?.description}</p>
                        )}
                      </div>
                      {dynamicForm?.length > 0 && (
                        <Alert color="info">
                          <div className="alert-body">
                            {t(
                              "Please fill in the required fields to print or export the report."
                            )}
                          </div>
                        </Alert>
                      )}
                    </Col>
                  </Row>
                )}

                {mainFilterComponent}
              </UILoader>

              {reportMode && (
                <Row>
                  <Col xs={12} className="text-end pt-50">
                    <div
                      style={{ justifyContent: "flex-end" }}
                      className="text-end demo-inline-spacing"
                    >
                      {(storeInvoices.isLoading || isLoadingReport) && (
                        <Button color="primary">
                          <Spinner color="white" size="sm" />
                          <span className="align-middle ms-50">
                            {t("Exporting Report")}...
                          </span>
                        </Button>
                      )}
                      {!storeInvoices.isLoading && !isLoadingReport && (
                        <UncontrolledButtonDropdown className="mr-0">
                          <Button
                            disabled={
                              (!isDirty || !isValid) && dynamicForm?.length > 0
                            }
                            color="primary"
                            type="submit"
                          >
                            <Printer size={14} />
                            <span className="align-middle ms-50">
                              {t("Print")}
                            </span>
                          </Button>
                          <DropdownToggle
                            className="dropdown-toggle-split"
                            color="primary"
                            caret
                            disabled={
                              (!isDirty || !isValid) && dynamicForm?.length > 0
                            }
                          ></DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              tag="a"
                              onClick={() => {
                                handleSubmit(
                                  beforeHandleFormSubmit(
                                    getValues(),
                                    "pdf",
                                    false
                                  )
                                )
                              }}
                            >
                              <PictureAsPdfOutlinedIcon /> pdf
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                handleSubmit(
                                  beforeHandleFormSubmit(
                                    getValues(),
                                    "xls",
                                    false
                                  )
                                )
                              }}
                              tag="a"
                            >
                              <ArticleOutlinedIcon /> xls
                            </DropdownItem>
                            <DropdownItem
                              tag="a"
                              onClick={() => {
                                handleSubmit(
                                  beforeHandleFormSubmit(
                                    getValues(),
                                    "csv",
                                    false
                                  )
                                )
                              }}
                            >
                              <TextSnippetOutlinedIcon /> csv
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </CardBody>
        </Card>
      )
    } else {
      return mainFilterComponent
    }
  }

  ////HELPER FUNCTIONS
  function checkAllRequiredFilled() {
    let isLoadStateArr = []
    /////prevent load data if filter include required attr and undefined
    if (pageAttr?.crudOpt?.data?.dynamicColumnsFilter) {
      pageAttr?.crudOpt?.data?.dynamicColumnsFilter
        .filter((x) => x?.rules?.required === true)
        .map((y) => {
          isLoadStateArr.push(getValues()?.[y.fieldName] && true)
        })
    }

    return isLoadStateArr?.every((value) => value === true)
  }

  function isFullyNestedArray(arr) {
    // console.log(arr)
    let result = false
    if (arr && Array.isArray(arr)) {
      result = arr.every((element) => Array.isArray(element))
    }
    return result
  }

  ////FOR FILTER
  const beforeHandleFiltersChangeToParent = () => {
    if (!onlyInputMode) {
      const formValues = getValues()
      let params = {}
      let endpointSuffix = ""
      for (let key in formValues) {
        const currentData = dynamicForm.find((x) => x.fieldName === key)
        // console.log(key, currentData, currentData.includeOnPayload === false)
        if (currentData?.includeOnPayload !== false) {
          let apiParamsName = currentData?.apiParamsName ?? key
          let paramValue = formValues?.[key]
          try {
            paramValue = formValues?.[key]?.trim()
          } catch (error) {}
          if (!currentData?.appendToEndpointPath) {
            params[`${apiParamsName}`] = paramValue
          } else {
            endpointSuffix = `${endpointSuffix}/${paramValue}`
          }
        }
      }

      // console.log("here....", formValues)

      dispatch(
        setDynamicFiltersData({
          storeName: storeName,
          data: params
        })
      )

      let endPointFix = pageAttr?.crudOpt?.endpointName

      if (endpointSuffix) {
        if (pageAttr?.crudOpt?.endpointName?.includes("?")) {
          endPointFix = pageAttr?.crudOpt?.endpointName?.replace(
            "?",
            `${endpointSuffix}?`
          )
        } else {
          endPointFix = pageAttr?.crudOpt?.endpointName + endpointSuffix
        }
      }

      handleFiltersChangeToParent({
        endpointName: endPointFix,
        storeName: storeName,
        ...params
      })
      // console.log(params)
      // console.log("beforeHandleFiltersChangeToParent")
    }
  }

  // document.addEventListener("DOMContentLoaded", function () {
  // if (onlyInputMode) {
  //   // Menambahkan event listener ke elemen yang lebih tinggi, misalnya `document` atau `body`
  //   document.body.addEventListener("click", function (event) {
  //     // Mengecek apakah elemen yang diklik atau salah satu dari leluhurnya memiliki kelas 'react-select'
  //     const reactSelectElement = event.target.closest(".react-select")

  //     if (reactSelectElement) {
  //       console.log("React-select element clicked")

  //       // Logika khusus jika klik terjadi di dalam `.select__menu`
  //       if (event.target.closest(".select__menu")) {
  //         console.log(
  //           "Dropdown menu of react-select clicked, propagation not stopped"
  //         )
  //       } else {
  //         event.stopPropagation() // Menghentikan bubbling event
  //         console.log("Propagation stopped")
  //       }
  //     }
  //   })
  // }
  // })

  const beforeHandleFormSubmit = (
    data,
    printType = "pdf",
    directPrint = true,
    saveAsState = false
  ) => {
    let newPayloadNew = {}
    let isMultiselectData = []
    ///remove form data not exist in dynamicForm
    if (dynamicForm?.length > 0) {
      let newPayload = {}
      const isFormRepeater = dynamicForm.find((x) => x.type === "form-repeater")
      const ismultiselectRepeater = dynamicForm.find(
        (x) => x.dataFrom?.multiselectToCurrentFormType === "form-repeater"
      )
      if (
        (isFormRepeater || ismultiselectRepeater) &&
        (pageType === "add" || !editForm)
      ) {
        let newDataRepeaterArray = []
        dynamicForm.map((x) => {
          if (x.children) {
            if (x.type === "form-repeater") {
              Object.keys(data).map((key) => {
                if (key.lastIndexOf("__") !== -1) {
                  const key_fix = key.split("__")[0]
                  const key_index = key.split("__")[1]
                  if (!newDataRepeaterArray[key_index]) {
                    newDataRepeaterArray[key_index] = {
                      [key_fix]: data[key]
                    }
                  } else {
                    newDataRepeaterArray[key_index][key_fix] = data[key]
                  }
                }
              })
            } else {
              x.children.map((x2) => {
                newPayload[x2.fieldName] = data[x2.fieldName]
              })
            }
          } else {
            if (x.dataFrom?.multiselectToCurrentFormType === "form-repeater") {
              data[x.fieldName].map((c) => {
                let eliminateKeys = {
                  [x.fieldName]: c.id
                }
                // delete c.id

                const multiselectPayloadExtra =
                  x.dataFrom?.multiselectPayloadExtra ?? []
                // const keysFromattr =  Object.keys(c)
                // .filter((key) => dynamicForm.some((f) => f.fieldName === key))
                // const keysFromMultiselectPayloadExtra = Object.keys(c)
                // .filter((key) => multiselectPayloadExtra.some((f) => f.fieldName === key))
                Object.keys(c)
                  .filter(
                    (key) =>
                      multiselectPayloadExtra.some(
                        (f) => f.fieldName === key
                      ) &&
                      dynamicForm.some(
                        (ff) =>
                          (ff.fieldName === key &&
                            ff?.includeOnPayload === undefined) ||
                          ff?.includeOnPayload !== false
                      )
                  )
                  .forEach((key) => {
                    const matchingField = multiselectPayloadExtra.find(
                      (fx) => fx.fieldName === key
                    )
                    if (matchingField && matchingField.alias) {
                      eliminateKeys[matchingField.alias] = c[key]
                      // delete c[key]
                    }
                  })
                // console.log(eliminateKeys)
                // Object.keys({
                //   ...keysFromattr,
                //   ...keysFromMultiselectPayloadExtra
                // })
                //   .filter((key) => dynamicForm.some((f) => f.fieldName === key))
                //   .forEach((key) => {
                //     eliminateKeys[key] = c[key][x.fieldName] = c.id
                //   })

                // let newData = {
                //   ...eliminateKeys,
                //   [x.fieldName]: c.id
                // }
                // delete newData.id
                newDataRepeaterArray.push(eliminateKeys)
              })

              // newDataRepeaterArray = data[x.fieldName]
            } else {
              if (
                x?.type === "code_editor" &&
                x?.toObject &&
                x?.code_language === "json"
              ) {
                let parseObj = data[x.fieldName]
                try {
                  parseObj = JSON.parse(data[x.fieldName])
                } catch (error) {
                  console.error(`Error parsing ${x.fieldName}`)
                }
                newPayload[x.fieldName] = parseObj
              } else {
                newPayload[x.fieldName] = data[x.fieldName]
              }
            }
          }
        })

        let isFormRepeaterReq = []
        if (isFormRepeater) {
          isFormRepeaterReq = (isFormRepeater ?? [])?.children?.filter(
            (x) =>
              x?.includeOnPayload === undefined || x?.includeOnPayload !== false
          )
        }
        // eslint-disable-next-line func-call-spacing
        let newPayloadRepeater = []

        if (newDataRepeaterArray.length > 0) {
          newDataRepeaterArray.map((x) => {
            removePayload.map((del) => {
              delete x[del]
            })

            if (isFormRepeater) {
              if (isFormRepeaterReq?.length <= Object.keys(x).length) {
                newPayloadRepeater.push({ ...x, ...newPayload })
              }
            }

            if (ismultiselectRepeater) {
              newPayloadRepeater.push({ ...x, ...newPayload })
            }
          })
        }
        newPayload["repeater"] = newPayloadRepeater
      } else {
        dynamicForm.map((x) => {
          // console.log(x)
          if (x.children && x?.type !== "tabs") {
            x.children.map((x2) => {
              if (
                x2?.type === "code_editor" &&
                x2?.toObject &&
                x2?.code_language === "json"
              ) {
                let parseObj = data[x2.fieldName]
                try {
                  parseObj = JSON.parse(data[x2.fieldName])
                } catch (error) {
                  console.error(`Error parsing ${x2.fieldName}`)
                }
                newPayload[x2.fieldName] = parseObj
              } else if (x2?.type === "date" || x2?.type === "datetime") {
                // console.log(data[x2.fieldName])
                const validVal = checkAndCorrectDateValue(data[x2.fieldName])
                // console.log(validVal)
                newPayload[x2.fieldName] = validVal
              } else {
                newPayload[x2.fieldName] = data[x2.fieldName]
              }
            })
          } else {
            if (
              x?.type === "code_editor" &&
              x?.toObject &&
              x?.code_language === "json"
            ) {
              let parseObj = data[x.fieldName]
              try {
                parseObj = JSON.parse(data[x.fieldName])
              } catch (error) {
                console.error(`Error parsing ${x.fieldName}`)
              }
              newPayload[x.fieldName] = parseObj
            } else if (x?.type === "date" || x?.type === "datetime") {
              const validVal = checkAndCorrectDateValue(data[x.fieldName])
              newPayload[x.fieldName] = validVal
            } else if (x?.type === "time") {
              if (
                typeof data[x.fieldName] === "object" &&
                data[x.fieldName] instanceof Date
              ) {
                const options = {
                  timeZone: "Asia/Jakarta",
                  hour12: false,
                  hour: "numeric",
                  minute: "numeric"
                }
                const timeString = data[x.fieldName].toLocaleTimeString(
                  undefined,
                  options
                )
                newPayload[x.fieldName] = timeString
              } else {
                newPayload[x.fieldName] = data[x.fieldName]
              }
            } else {
              if (x?.type !== "tabs") {
                // console.log(x.fieldName, newPayload[x.fieldName], data[x.fieldName])
                // if (newPayload[x.fieldName]) {
                newPayload[x.fieldName] = data[x.fieldName]
                // }
              }
            }
          }
        })
      }

      // console.log(newPayload, removePayload)
      removePayload.map((del) => {
        delete newPayload[del]
      })
      // console.log(newPayload)
      ////JIKA ADA MULTIPLE SELECT DROPDOWN KE BEDA TABEL (SAMA DENGAN RelationsCrud)
      let isContainMapInput = false
      dynamicForm.map((x) => {
        const key = x.fieldName
        if (x.isMultiSelect && x.isMultiSelectPostOpt) {
          let isMultiSelectPostOpt = { ...x.isMultiSelectPostOpt }
          let extraPayloadsData = {}
          const dataKeysValues = newPayload[key].map((i) => {
            if (x?.isMultiSelectPostOpt?.extraPayloadKeys) {
              x?.isMultiSelectPostOpt?.extraPayloadKeys.map((y) => {
                extraPayloadsData[y] = newPayload[y]
              })
              delete isMultiSelectPostOpt.extraPayloadKeys
            }
            delete isMultiSelectPostOpt?.payloadKey
            delete isMultiSelectPostOpt?.fieldName
            return {
              ...extraPayloadsData,
              [x?.isMultiSelectPostOpt?.payloadKey]: parseInt(id),
              [x?.isMultiSelectPostOpt?.fieldName ?? key]: i
            }
          })

          isMultiselectData.push({
            ...isMultiSelectPostOpt,
            repeater: dataKeysValues
          })

          delete newPayload[key]
        } else if (x.type === "relationsCrud" && x?.relationsCrud) {
          // console.log(x?.relationsCrud)
        }

        if (x.type === "map-latlng" || x?.type === "map-polygon") {
          isContainMapInput = true
        }
      })

      // console.log(changedFieldName)
      if (pageType === "edit" && (changedFieldName || isContainMapInput)) {
        if (!isContainMapInput) {
          const uniqueArray = Array.from(new Set(changedFieldName))
          Object.keys(newPayload)?.map((key) => {
            if (uniqueArray.includes(key)) {
              newPayloadNew[key] = newPayload[key]
            }
          })
        } else {
          newPayloadNew = newPayload
        }
      } else {
        newPayloadNew = newPayload
      }

      // console.log(newPayloadNew, changedFieldName)
    }

    const postAfterPostData =
      isMultiselectData.length > 0 ? isMultiselectData : null
    // console.log(postAfterPostData)
    if (saveAsState) {
      newPayloadNew["id_ref"] = id
    }

    // console.log(newPayloadNew)

    if (!reportMode) {
      return handleFormSubmit(newPayloadNew, postAfterPostData, saveAsState)
    } else {
      newPayloadNew["output_type"] = printType
      newPayloadNew["print"] = directPrint
      setIsLoadingReport(true)
      return handleFormSubmitPrint(
        newPayloadNew,
        pageAttr.reportOpt.moduleName,
        pageAttr.reportOpt.rptName,
        pageAttr.reportOpt.viewContentAs,
        pageAttr.reportOpt.customEndpointName
      ).then((r) => {
        // console.log(r)
        setIsLoadingReport(false)
      })
    }
  }

  // const debouncedAfterChangeEvent = debounce(afterChangeEvent, 1000)

  const debouncedAfterChangeEvent = useCallback(
    debounce(afterChangeEvent, 1000),
    []
  )
  //////DROPDOWN: FUNCTIONS
  const setLoadingInputs = (fieldName, value, init) => {
    // console.log(fieldName, value, init, defaultFormValues[fieldName])
    if (defaultFormValues[fieldName] && init) {
      tempAsyncOptLoading[fieldName] = value ?? false
      setTempAsyncOptLoading(tempAsyncOptLoading)
    } else {
      tempAsyncOptLoading[fieldName] = value ?? false
      setTempAsyncOptLoading(tempAsyncOptLoading)
      let tempAsyncOptLoadingCopy = { ...tempAsyncOptLoading }
      tempAsyncOptLoadingCopy[fieldName] = value ?? false
      setTempAsyncOptLoading(tempAsyncOptLoadingCopy)
    }
  }

  const afterChangeGetValueSetLoading = (item, value, init) => {
    const afterRequestFillOther =
      item?.afterChangeGetValue?.dataFrom?.afterRequestFillOther
    if (afterRequestFillOther) {
      afterRequestFillOther?.map((item, ind) => {
        const fieldsParse = item?.split("=")
        const fieldName = fieldsParse[0]
        // console.log(fieldName, disabledInputs)

        // if (init) {
        disabledInputs[item?.fieldName] = value ?? false
        setDisabledInputs(disabledInputs)

        tempAsyncOptLoading[item?.fieldName] = value ?? false
        setTempAsyncOptLoading(tempAsyncOptLoading)

        disabledInputs[fieldName] = value ?? false
        setDisabledInputs(disabledInputs)

        tempAsyncOptLoading[fieldName] = value ?? false
        setTempAsyncOptLoading(tempAsyncOptLoading)
        // } else {
        // let disabledInputsCopy = { ...disabledInputs }
        // disabledInputsCopy[fieldName] = value ?? false
        // setDisabledInputs(disabledInputsCopy)

        // let tempAsyncOptLoadingCopy = { ...tempAsyncOptLoading }
        // tempAsyncOptLoadingCopy[fieldName] = value ?? false
        // setTempAsyncOptLoading(tempAsyncOptLoadingCopy)
        // }
      })
    }
  }

  // const getParamsApiUrlAttr = (url, item) => {
  //   let finUrl = url
  //   /// get_params
  //   let getParams = item.dataFrom?.get_params
  //   if (getParams) {
  //     let queryStrGetParams = ""
  //     getParams?.map((par) => {
  //       let querystrKey = `${par?.key}=`
  //       let querystrVal
  //       if (!par?.valueFrom || par?.valueFrom === "static") {
  //         querystrVal = `${par?.value}`
  //       } else if (par?.valueFrom === "other") {
  //         if (!isFormRepeater) {
  //           if (getValues(par?.value)) {
  //             querystrVal = `${getValues()[par?.value]}`
  //           }
  //         } else {
  //           const targetFieldNameSuffix = `__${item.fieldName
  //             .split("__")
  //             .pop()}`
  //           const targetFieldNameValue = `${
  //             getValues()[par?.value + targetFieldNameSuffix]
  //           }`
  //           if (targetFieldNameValue) {
  //             querystrVal = targetFieldNameValue
  //           }
  //         }
  //       } else if (par?.valueFrom === "parent") {
  //         const parentData = store.pageData[storeNameParent]?.selectedData
  //         if (!isFormRepeater) {
  //           const value = parentData?.[par?.value]
  //           if (value) {
  //             querystrVal = `${value}`
  //           }
  //         } else {
  //           const targetFieldNameValue = `${parentData[par?.value]}`
  //           if (targetFieldNameValue) {
  //             querystrVal = targetFieldNameValue
  //           }
  //         }
  //       }

  //       if (querystrKey && querystrVal) {
  //         queryStrGetParams = `${
  //           queryStrGetParams + querystrKey + querystrVal
  //         }&`
  //       }
  //     })
  //     let prefix = "?"
  //     if (finUrl.includes("?")) {
  //       prefix = "&"
  //     }
  //     finUrl = finUrl + prefix + queryStrGetParams.replace(/.$/, "")
  //   }
  //   return finUrl
  // }

  // const getParamsApiUrlAttr = (url, item) => {
  //   let finUrl = url

  //   const getParams = item.dataFrom?.get_params
  //   if (!getParams) {
  //     return finUrl
  //   }

  //   let queryStrGetParams = ""
  //   getParams.forEach((par) => {
  //     let querystrKey = `${par?.key}=`
  //     let querystrVal = null

  //     let parValue = par?.value
  //     const isValueDeepKey = parValue?.includes(".") ?? false

  //     switch (par?.valueFrom) {
  //       case "static":
  //         querystrVal = `${parValue}`
  //         break
  //       case "other":
  //         if (!isFormRepeater) {
  //           if (isValueDeepKey) {
  //             const parValueObj = parValue?.split(".")
  //             const valuePath = parValue.replaceAll(`${parValueObj[0]}.`, "")
  //             const valueSelector =
  //               getValues()[parValueObj[0]] ?? defaultFormValues[parValueObj[0]]
  //             const selectedOpt = tempAsyncOptValues[parValueObj[0]]?.find(
  //               (c) => c.value === valueSelector
  //             )
  //             querystrVal = valuePath?.includes(".")
  //               ? getDepthValue(selectedOpt, valuePath)
  //               : selectedOpt?.[valuePath]
  //           } else {
  //             querystrVal = `${getValues()[parValue]}`
  //           }
  //         } else {
  //           const targetFieldNameSuffix = `__${item.fieldName
  //             .split("__")
  //             .pop()}`
  //           const targetFieldNameValue = `${
  //             getValues()[parValue + targetFieldNameSuffix]
  //           }`
  //           querystrVal = targetFieldNameValue
  //         }
  //         break
  //       case "parent":
  //         const parentData = store.pageData[storeNameParent]?.selectedData
  //         if (!isFormRepeater) {
  //           querystrVal = `${parentData?.[parValue]}`
  //         } else {
  //           querystrVal = `${parentData[parValue]}`
  //         }
  //         break
  //       default:
  //         break
  //     }

  //     if (
  //       querystrKey &&
  //       querystrVal &&
  //       querystrVal !== "undefined" &&
  //       querystrVal !== "null"
  //     ) {
  //       queryStrGetParams += `${querystrKey + querystrVal}&`
  //     }
  //   })

  //   let prefix = finUrl.includes("?") ? "&" : "?"
  //   finUrl += (prefix + queryStrGetParams).slice(0, -1)

  //   return finUrl
  // }

  const getParamsApiUrlAttr = (
    url,
    item,
    afterChangeGetValue = null,
    inputValue
  ) => {
    const { get_params: getParams } = afterChangeGetValue
      ? item?.afterChangeGetValue?.dataFrom || {}
      : item?.dataFrom || {}
    if (!getParams) return url
    // console.log(getParams)
    let queryStrGetParams = ""

    for (const par of getParams) {
      const { key, value, valueFrom } = par
      let querystrVal = null

      if (!value) continue

      const isValueDeepKey = value.includes(".")
      switch (valueFrom) {
        case "static":
          querystrVal = value
          break
        case "other":
          if (!isFormRepeater) {
            if (isValueDeepKey) {
              const [parValueObj0, ...valuePathArr] = value.split(".")
              const valuePath = valuePathArr.join(".")

              const valueSelector =
                getValues()[parValueObj0] || defaultFormValues[parValueObj0]
              let selectedOpt = {}
              if (parValueObj0 === "id_unit") {
                selectedOpt = storeUnit?.dataDropdown?.find(
                  (c) => c.value === valueSelector
                )
              } else {
                selectedOpt = tempAsyncOptValues[parValueObj0]?.find(
                  (c) => c.value === valueSelector
                )
              }
              querystrVal = getDepthValue(selectedOpt, valuePath)
              // querystrVal = selectedOpt?.[valuePath]
            } else {
              // console.log(getValues(), value)
              querystrVal = getValues()[value]
            }
          } else {
            const targetFieldNameSuffix = `__${item.fieldName
              .split("__")
              .pop()}`
            querystrVal = getValues()[`${value}${targetFieldNameSuffix}`]
          }
          if (Object.prototype.toString.call(querystrVal) === "[object Date]") {
            querystrVal = querystrVal.toISOString().slice(0, 10)
            // console.log(querystrVal)
          }
          break
        case "parent":
          // console.log(
          //   pageAttr?.isViewOnRow,
          //   dynamicTempState.parent_data_relation,
          //   store.pageData[storeNameParent]?.selectedData,
          //   store.pageData[storeNameParent].currentExpandedData
          // )
          const parentData = !pageAttr?.isViewOnRow
            ? dynamicTempState.parent_data_relation ??
              store.pageData[storeNameParent]?.selectedData
            : store.pageData[storeNameParent].currentExpandedData
          // console.log(dynamicTempState.parent_data_relation, value)
          querystrVal = parentData?.[value]
          break
        case "grid":
          querystrVal = store.currentExpandedDataGlobal?.[value]
          break
        case "current":
          querystrVal = inputValue?.[value] ?? inputValue
          break
        default:
          // console.log(item)
          // console.log(tempAsyncOptValues?.[item?.fieldName])
          // console.log(inputValue)
          // let newSelected = tempAsyncOptValues?.[item?.fieldName]?.find((itesss) => itesss.id === )
          // console.log(querystrVal)
          break
      }
      if (
        // eslint-disable-next-line eqeqeq
        querystrVal != null &&
        querystrVal !== "undefined" &&
        querystrVal !== "null"
      ) {
        queryStrGetParams += `${key}=${querystrVal}&`
        // console.log(queryStrGetParams)
      }
    }

    let finUrl = url

    if (queryStrGetParams) {
      finUrl +=
        (finUrl.includes("?") ? "&" : "?") + queryStrGetParams.slice(0, -1)
    }
    return finUrl
  }

  const loadDropdownFromApi = async (
    inputValue,
    item,
    setValueInput = false,
    afterChange = false,
    init = false,
    url = null,
    afterChangeGetValue = null
    // nonDropdownMode = false
  ) => {
    // console.log(`loadDropdownFromApi: BEGIN ${item?.fieldName}`)
    let fieldName = item.fieldName
    let finUrl = url ?? item?.dataFrom?.api

    /// get_params
    finUrl = getParamsApiUrlAttr(finUrl, item, afterChangeGetValue, inputValue)

    // console.log(afterChange, finUrl)

    // check jika ada afterChangeFillOther dari field lain yang merujuk ke field ini
    if (!afterChange) {
      finUrl = afterChangeFillOther(item, inputValue, finUrl)
    }
    // console.log(url)
    // console.log(dynamicTempState.apiPar?.[item?.fieldName], finUrl)
    if (
      (!dynamicTempState.data[item?.fieldName] &&
        !tempAsyncOptLoading[item?.fieldName]) ||
      item.dataFrom?.api_getAlways ||
      url ||
      dynamicTempState.apiPar?.[item?.fieldName] !== finUrl // jika afterChangeFillOther value berbeda dengan input lain atau ada perubahan di input lain yang relevan
    ) {
      console.log(
        `loadDropdownFromApi: ${fieldName} DATA NOT EXIST ON STORE OR REFRESH PAGE`
      )
      setLoadingInputs(item?.fieldName, true, init)
      setLoadingInputsCreate(item?.fieldName, init)
      const baseUrlOpt = afterChangeGetValue
        ? item?.afterChangeGetValue?.dataFrom?.baseUrl ??
          pageAttr?.crudOpt?.baseUrl
        : item?.dataFrom?.baseUrl ?? pageAttr?.crudOpt?.baseUrl

      // check jika ada afterChangeFillOtherRequestParams dari field lain yang merujuk ke field ini
      // finUrl = afterChangeFillOtherRequestParams(item, inputValue, finUrl)
      let data = null
      const reqHeaders =
        item?.dataFrom?.reqHeaders ||
        item?.afterChangeGetValue?.dataFrom?.reqHeaders
      const reqOptions =
        item?.dataFrom?.reqOptions ||
        item?.afterChangeGetValue?.dataFrom?.reqOptions

      const afterRequestFillOther =
        item?.afterChangeGetValue?.dataFrom?.afterRequestFillOther

      if (!reqHeaders && !reqOptions) {
        data = await getBaseUrl(baseUrlOpt, finUrl)
          .get(finUrl)
          .then((res) => {
            let keySelector = item?.afterChangeGetValue?.dataFrom?.resp_key
              ? res?.data?.[item?.afterChangeGetValue?.dataFrom?.resp_key]
              : res?.data

            const afterRequestValidate =
              item?.afterChangeGetValue?.dataFrom?.afterRequestValidate

            let resp_validate = true
            if (afterRequestValidate) {
              resp_validate = false
              afterRequestValidate.map((valid) => {
                const isValueDeepKey2 = valid?.key?.includes(".") ?? false
                const keySelectorCur = isValueDeepKey2
                  ? getDepthValue(keySelector, valid?.key)
                  : keySelector[valid?.key]
                const valueCur = !valid?.valueFrom
                  ? valid?.value
                  : getValues(valid?.value)
                const evalString = keySelectorCur + valid?.operator + valueCur
                // console.log(evalString)
                resp_validate = eval(evalString)
              })
            }

            const afterRequestFillOtherDescription =
              item?.afterChangeGetValue?.dataFrom
                ?.afterRequestFillOtherDescription

            if (resp_validate) {
              if (afterRequestFillOther) {
                afterChangeFillOtherSetValue(
                  item,
                  afterRequestFillOther,
                  keySelector
                )
              }

              const afterRequestFillOtherLabel =
                item?.afterChangeGetValue?.dataFrom?.afterRequestFillOtherLabel
              if (afterRequestFillOtherLabel) {
                // console.log(fieldsParse0Repeater)
                // inputLabelRef[fieldsParse0Repeater] = "sfdsfdsfdsfdsf"
                afterChangeFillOtherSetValue(
                  item,
                  afterRequestFillOtherLabel,
                  keySelector,
                  null,
                  null,
                  null,
                  "label"
                )
              }

              if (afterRequestFillOtherDescription) {
                // console.log(fieldsParse0Repeater)
                // inputLabelRef[fieldsParse0Repeater] = "sfdsfdsfdsfdsf"
                afterChangeFillOtherSetValue(
                  item,
                  afterRequestFillOtherDescription,
                  keySelector,
                  null,
                  null,
                  null,
                  "desc"
                )
              }
            } else {
              if (afterRequestFillOtherDescription) {
                // console.log(fieldsParse0Repeater)
                // inputLabelRef[fieldsParse0Repeater] = "sfdsfdsfdsfdsf"

                afterChangeFillOtherSetValue(
                  item,
                  afterRequestFillOtherDescription,
                  keySelector,
                  null,
                  null,
                  null,
                  "desc",
                  true
                )
                if (afterRequestFillOther) {
                  afterChangeFillOtherSetValue(
                    item,
                    afterRequestFillOther,
                    null
                  )
                }
              } else {
                setError(fieldName, {
                  message: `${getValues(fieldName)} is invalid`
                })
                setValue(fieldName, "")
              }
            }

            afterChangeGetValueSetLoading(item, false)
            return renderDropdownItem(item, res)
          })
          .catch((error) => {
            afterChangeGetValueSetLoading(item, false)
            console.log("error", error)
          })
      } else {
        fieldName = `${fieldName}_`
        let myHeaders = new Headers()
        for (const key in reqHeaders) {
          if (reqHeaders.hasOwnProperty(key)) {
            myHeaders.append(key, reqHeaders[key])
          }
        }

        const requestOptions = { ...reqOptions, headers: myHeaders }
        data = await fetch(finUrl, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            const afterRequestFillOther =
              item?.afterChangeGetValue?.dataFrom?.afterRequestFillOther
            if (afterRequestFillOther) {
              afterChangeFillOtherSetValue(item, afterRequestFillOther, result)
            }
            const afterRequestFillOtherLabel =
              item?.afterChangeGetValue?.dataFrom?.afterRequestFillOtherLabel
            if (afterRequestFillOtherLabel) {
              // console.log(fieldsParse0Repeater)
              // inputLabelRef[fieldsParse0Repeater] = "sfdsfdsfdsfdsf"
              afterChangeFillOtherSetValue(
                item,
                afterRequestFillOtherLabel,
                result,
                null,
                null,
                null,
                "label"
              )
            }
            afterChangeGetValueSetLoading(item, false)
          })
          .catch((error) => {
            afterChangeGetValueSetLoading(item, false)
            console.log("error", error)
          })
      }

      if (data) {
        // console.log(data)
        // tempAsyncOptValues[fieldName] = [
        //   ...tempAsyncOptValues[fieldName],
        //   ...data
        // ]
        // setTempAsyncOptValues(tempAsyncOptValues)
        // console.log(data)
        // console.log(tempAsyncOptValues[fieldName])

        const isExist = tempAsyncOptValues[fieldName]?.some((obj) => {
          return data.find((o) => o.id === obj.value)
        })

        let mixedDatas = data
        if (isExist) {
          mixedDatas = [...(tempAsyncOptValues[fieldName] ?? []), ...data]
        }

        // console.log(mixedDatas)
        const uniqueArray = mixedDatas
          .reduce((acc, current) => {
            let itemFieldValue = item?.dataFrom?.field_value ?? "id"
            const isValueDeepKey = itemFieldValue?.includes(".") ?? false
            const existingIndex = acc.findIndex((item) =>
              isValueDeepKey
                ? getDepthValue(item, itemFieldValue) ===
                  getDepthValue(current, itemFieldValue)
                : item[itemFieldValue] === current[itemFieldValue]
            )
            if (existingIndex === -1) {
              // If the current object's id is not found in the accumulator array, add it
              return [...acc, current]
            } else {
              // If the current object's id is found in the accumulator array, compare the number of keys
              const existingKeys = Object.keys(acc[existingIndex])
              const currentKeys = Object.keys(current)
              if (existingKeys.length < currentKeys.length) {
                // If the current object has fewer keys, replace the existing object with the current object
                acc[existingIndex] = current
              }
              // Otherwise, keep the existing object
              return acc
            }
          }, [])
          .filter(function (element) {
            return element?.value !== undefined
          })

        if (!afterChangeGetValue) {
          // console.log(uniqueArray)
          tempAsyncOptValues[fieldName] = uniqueArray
          setTempAsyncOptValues(tempAsyncOptValues)
          dispatch(setDataCache({ [fieldName]: uniqueArray }))
          dispatch(
            setLastApiPar({
              [fieldName]: finUrl
            })
          )
        }

        // DEFAULT CHANGE TRIGGER ON ADD
        const checkValue = data.find((c) => c.value === inputValue)
        if (checkValue) {
          afterChangeEvent(checkValue, item)
        }
      }
      setLoadingInputs(fieldName, false, init)
      setLoadingInputsCreate(fieldName, false)
    } else {
      console.log(`loadDropdownFromApi: ${fieldName} DATA EXIST ON STORE`)
      const value =
        defaultFormValues[fieldName] ??
        defaultFormValues[
          item?.initialValueFromOther ?? item?.dataFrom?.initialValueFromOther
        ] ??
        getDepthValue(
          store.pageData[storeName]?.selectedData,
          item?.initialValueFromOther ??
            item?.dataFrom?.initialValueFromOther ??
            fieldName
        )

      let checkCurrentVal = dynamicTempState.data[item?.fieldName]?.find(
        (c) => c.value === value
      )

      if (item?.initialValueFromOtherByLabel) {
        checkCurrentVal = dynamicTempState.data[item?.fieldName]?.find((c) => {
          if (Array.isArray(item?.dataFrom?.field_label)) {
            let result = false
            item?.dataFrom?.field_label.map((lbl) => {
              result = result || c[lbl]?.includes(value)
            })
            return result
          } else {
            return c.label.includes(value)
          }
        })
      }

      if (checkCurrentVal || !inputValue) {
        console.log(
          `loadDropdownFromApi: ${fieldName} SELECTED DATA EXIST ON STORE`
        )
        tempAsyncOptValues[fieldName] = dynamicTempState.data[item?.fieldName]
        // console.log(dynamicTempState.data[item?.fieldName])
        // setTempAsyncOptValues(tempAsyncOptValues)

        // DEFAULT CHANGE TRIGGER ON ADD
        const checkValue = tempAsyncOptValues[fieldName].find(
          (c) => c.value === inputValue
        )
        if (checkValue) {
          afterChangeEvent(checkValue, item)
        }

        setLoadingInputs(item?.fieldName, false, init)
        setLoadingInputsCreate(fieldName, false)
      } else {
        console.log(
          `loadDropdownFromApi: ${fieldName} SELECTED DATA NOT EXIST ON STORE`
        )
        // console.log(dynamicTempState.data[item?.fieldName])
        tempAsyncOptValues[fieldName] = await dynamicTempState.data[
          item?.fieldName
        ]
        // setTempAsyncOptValues(tempAsyncOptValues)
        setLoadingInputs(item?.fieldName, false, init)
        setLoadingInputsCreate(fieldName, false)
      }
    }
  }

  /////DROPDOWN: AUTOCOMPLETE FUNCTIONS
  const debounceFn = useCallback(
    debounce(loadDropdownFromApiAutoComplete, 600),
    []
  )

  const handleAutoCompleteChange = (query, item, lastOpt = []) => {
    tempLastQuery[item?.fieldName] = query
    setTempLastQuery(tempLastQuery)
    tempLastAutoCompOpt[item?.fieldName] = lastOpt
    setTempLastAutoCompOpt(tempLastAutoCompOpt)
    if (query && query.length > 0) {
      debounceFn(query, item)
    }
  }

  async function loadDropdownFromApiAutoComplete(
    inputValue,
    item,
    setValueInput = false,
    afterChange = false,
    init = false
  ) {
    // console.log(inputValue)
    // console.log(`loadDropdownFromApiAutoComplete: BEGIN ${item?.fieldName}`)
    ///IF DATA NOT EXIST OR REFRESH PAGE
    if (
      (!dynamicTempState.data[item?.fieldName] &&
        !tempAsyncOptLoading[item?.fieldName]) ||
      item.dataFrom?.api_getAlways
    ) {
      // console.log(
      //   "loadDropdownFromApiAutoComplete: DATA NOT EXIST ON STORE OR REFRESH PAGE"
      // )

      ///IF DATA EXIST ON DEFAULT VALUE
      // console.log(item?.fieldName, defaultFormValues[item?.fieldName])

      if (item?.isMultiSelect) {
        if (init) {
          // console.log(defaultFormValues[item?.fieldName])
          tempAsyncOptValues[item.fieldName] = renderDropdownItem(item, {
            data: { data: defaultFormValues[item?.fieldName] }
          })
          setTempAsyncOptValues(tempAsyncOptValues)
        } else {
          getAutoCompleteAPi(item, inputValue, init, afterChange)
        }
      } else {
        getAutoCompleteAPi(item, inputValue, init, afterChange)
      }
      // eslint-disable-next-line brace-style
    }
    ///IF DATA EXIST ON STORE
    else {
      // console.log("loadDropdownFromApiAutoComplete: DATA EXIST ON STORE")
      ///CHECK SELECTED DATA EXIST IN DATA STORE

      let checkCurrentVal = dynamicTempState.data[item?.fieldName]?.find(
        (c) => c.value === inputValue
      )

      if (item?.initialValueFromOtherByLabel) {
        checkCurrentVal = dynamicTempState.data[item?.fieldName]?.find((c) => {
          if (Array.isArray(item?.dataFrom?.field_label)) {
            let result = false
            item?.dataFrom?.field_label.map((lbl) => {
              result = result || c[lbl]?.includes(inputValue)
            })
            return result
          } else {
            return c.label.includes(inputValue)
          }
        })
      }

      if (checkCurrentVal || !inputValue) {
        // console.log(
        //   "loadDropdownFromApiAutoComplete: SELECTED DATA EXIST ON STORE"
        // )
        tempAsyncOptValues[item.fieldName] =
          dynamicTempState.data[item?.fieldName]
      } else {
        // console.log(
        //   "loadDropdownFromApiAutoComplete: SELECTED DATA NOT EXIST ON STORE"
        // )
        if (item?.isMultiSelect && init) {
          tempAsyncOptValues[item.fieldName] = renderDropdownItem(item, {
            data: { data: inputValue }
          })
          setTempAsyncOptValues(tempAsyncOptValues)
        } else {
          getAutoCompleteAPi(item, inputValue, init, afterChange)
        }
      }
    }
  }

  //////DROPDOWN: CREATEABLE FUNCTIONS
  const handleCreateOptions = async (inputValue, item) => {
    let data
    setLoadingInputs(item.fieldName, true)
    setLoadingInputsCreate(item.fieldName, true)
    if (!item.isCreatableOnSubmit) {
      const url = item.dataFrom.api
      const params = {
        [item?.isCreatableFieldName]: inputValue
      }
      if (item?.isCreatableValueFromOtherFields) {
        item?.isCreatableValueFromOtherFields?.map((values) => {
          const valueObj = values?.split("=")
          const keyParam = valueObj[0]
          const keyCurrentForm = valueObj[1]
          params[keyParam] = getValues(keyCurrentForm)
        })
      }

      // console.log(params)
      const baseUrlOpt = item?.dataFrom?.baseUrl ?? pageAttr?.crudOpt?.baseUrl

      data = await getBaseUrl(baseUrlOpt, url)
        .post(url, params)
        .then((res) => {
          if (res.status === 200) {
            return renderDropdownItem(item, res)
          }
        })
    } else {
      data = [
        {
          value: null,
          id: null,
          label: inputValue,
          [item?.isCreatableFieldName]: inputValue
        }
      ]
    }

    // console.log(data)

    if (data) {
      let newDataMix = data[0]
      let newSelectedValueArr = data[0]
      let newSelectedValue = newSelectedValueArr?.value

      newDataMix = [data[0], ...(tempAsyncOptValues[item.fieldName] ?? [])]
      if (item?.isMultiSelect) {
        const currentSelectedValue = getValues(item.fieldName) ?? []
        // console.log(currentSelectedValue, newSelectedValue)
        const mixSelectedValue = [...currentSelectedValue, newSelectedValue]
        newSelectedValue = mixSelectedValue
        newSelectedValueArr = newDataMix.filter((row) =>
          mixSelectedValue.includes(row.value)
        )
      }

      let tempAsyncOptValuesCopy = { ...tempAsyncOptValues }
      tempAsyncOptValuesCopy[item.fieldName] = newDataMix
      setTempAsyncOptValues(tempAsyncOptValuesCopy)

      tempAsyncOptValues[item.fieldName] = newDataMix
      dispatch(
        setDataCache({
          [item.fieldName]: newDataMix
        })
      )
      setLoadingInputs(item.fieldName, false)
      setLoadingInputsCreate(item.fieldName, false)
      setTimeout(() => {
        setValue(item.fieldName, newSelectedValue)
        afterChangeEvent(newSelectedValueArr, item)
        if (item?.afterAddReloadOtherPage) {
          item.afterAddReloadOtherPage?.map((url) => {
            const store_to_reset = url.replaceAll("/", "_")
            if (store.pageData[store_to_reset]?.data) {
              dispatch(resetData(store_to_reset))
            }
          })
        }
      }, 1000)
    }

    setLoadingInputs(item.fieldName, false)
    setLoadingInputsCreate(item.fieldName, false)
  }

  const setLoadingInputsCreate = (fieldName, value, init) => {
    if (defaultFormValues[fieldName] && init) {
      tempAsyncOptCreateLoading[fieldName] = value ?? false
      setTempAsyncOptCreateLoading(tempAsyncOptCreateLoading)
    } else {
      tempAsyncOptCreateLoading[fieldName] = value ?? false
      setTempAsyncOptCreateLoading(tempAsyncOptCreateLoading)
      // let tempAsyncOptCreateLoadingCopy = { ...tempAsyncOptCreateLoading }
      // tempAsyncOptCreateLoadingCopy[fieldName] = value ?? false
      // setTempAsyncOptCreateLoading(tempAsyncOptCreateLoadingCopy)
    }
  }

  // const renderDropdownItem = async (item, res) => {
  //   let keySelector = item.dataFrom?.resp_key
  //     ? res?.data?.[item.dataFrom?.resp_key]
  //     : res?.data ?? null
  //   if (keySelector) {
  //     if (!Array.isArray(keySelector)) {
  //       keySelector = [keySelector]
  //     }
  //     if (item.dataFrom?.field_label || item.dataFrom?.field_value) {
  //       return await keySelector.map((r) => {
  //         let container = { ...r }
  //         if (Array.isArray(item?.dataFrom?.field_label)) {
  //           let objSelector = ""

  //           item?.dataFrom?.field_label.map((lbl, i, row) => {
  //             const isLabelFormated = lbl.includes(":") ?? false
  //             const labelFormatType = isLabelFormated
  //               ? lbl?.split(":")[1]
  //               : null
  //             const labelFormat = isLabelFormated ? lbl?.split(":")[0] : lbl
  //             const isLabelDeepKey = labelFormat.includes(".") ?? false

  //             if (i + 1 === row.length) {
  //               if (isLabelDeepKey) {
  //                 objSelector = objSelector + getDepthValue(r, lbl)
  //               } else {
  //                 objSelector = objSelector + (r[lbl] ?? "")
  //               }
  //             } else {
  //               if (isLabelDeepKey) {
  //                 objSelector =
  //                   objSelector +
  //                     getDepthValue(r, lbl) +
  //                     item?.dataFrom?.field_label_separator ?? " "
  //               } else {
  //                 objSelector =
  //                   objSelector +
  //                     (r?.[lbl] ?? "") +
  //                     item?.dataFrom?.field_label_separator ?? " "
  //               }
  //             }

  //             objSelector = formatLabel(
  //               objSelector?.replaceAll("null", ""),
  //               labelFormatType
  //             )
  //           })
  //           container["label"] = objSelector?.replaceAll("null", "")
  //         } else {
  //           const label = item.dataFrom?.field_label
  //           const isLabelFormated = label.includes(":") ?? false
  //           const labelFormatType = isLabelFormated
  //             ? label?.split(":")[1]
  //             : null
  //           const labelFormat = isLabelFormated ? label?.split(":")[0] : label
  //           const isLabelDeepKey = labelFormat.includes(".") ?? false
  //           let labelValue = isLabelDeepKey
  //             ? getDepthValue(r, labelFormat)?.replaceAll("null", "")
  //             : r[labelFormat]?.replaceAll("null", "")

  //           container["label"] = formatLabel(labelValue, labelFormatType)
  //         }

  //         if (item.dataFrom?.field_img) {
  //           container["img"] = item?.dataFrom?.field_img.includes(".")
  //             ? getDepthValue(r, item?.dataFrom?.field_img)
  //             : r[item.dataFrom.field_img]
  //         }

  //         container["value"] = item.dataFrom?.field_value.includes(".")
  //           ? getDepthValue(r, item.dataFrom?.field_value)
  //           : r[item.dataFrom.field_value]
  //         return container
  //       })
  //       // console.log(newDataMap)
  //       // newDataMap
  //     } else {
  //       return await keySelector
  //     }
  //   } else {
  //     return []
  //   }
  // }

  // jika input dropdown focus, maka check afterChange di input lain yang menunjuk ke input ini
  const afterChangeFillOther = (itemAttr, inputValue, url) => {
    // console.log("afterChangeFillOther")
    let finUrl = url
    let item = itemAttr
    // FIND RELATIONS IN OTHER INPUT
    let afterChangeFillOtherDatas = dynamicForm.filter(
      (i) => i?.afterChangeFillOther !== null
    )

    let afterChangeFillOtherColumns = dynamicForm.filter(
      (i) => i?.type === "column" && i?.children?.length > 0
    )

    const afterChangeFillOtherColumn = []
    afterChangeFillOtherColumns.map((obj) => {
      const el = { ...obj, ...{} }
      delete el.children
      afterChangeFillOtherColumn.push(el)
      Object.values(obj.children).map((v) => {
        afterChangeFillOtherColumn.push(v)
      })
    })

    let afterChangeFillOtherData = [
      ...afterChangeFillOtherDatas,
      ...afterChangeFillOtherColumn
    ]
    //IF FORM-REPEATER
    const isFieldRepeater = item?.fieldName.includes("__")
    if (isFieldRepeater) {
      afterChangeFillOtherData = dynamicForm
        .find((x) => x.type === "form-repeater")
        ?.children?.filter((i) => i?.afterChangeFillOther !== null)
    }

    afterChangeFillOtherData?.map((it) => {
      let match = false
      // let otherField = null
      let queryStrParName = null
      let fieldsParse0 = null
      let fieldsParse0Alias = null
      let fieldsParse1 = null
      let fieldsParse1Alias = null
      ///find

      //IF FORM-REPEATER
      let fieldsParse0Repeater
      let fieldsParse1Repeater

      it?.afterChangeFillOther?.map((it_det) => {
        if (it_det) {
          const fieldsParse = it_det?.split("=")
          if (fieldsParse.length) {
            fieldsParse0 = fieldsParse[0].includes(":")
              ? fieldsParse[0]?.split(":")[0]
              : fieldsParse[0]
            fieldsParse0Repeater = fieldsParse0

            //IF FORM-REPEATER
            if (isFieldRepeater) {
              fieldsParse0Repeater = `${fieldsParse0}__${
                item?.fieldName.split("__")[1]
              }`
              fieldsParse1Repeater = `${fieldsParse1}__${
                item?.fieldName.split("__")[1]
              }`
            }

            if (fieldsParse0Repeater === item.fieldName) {
              fieldsParse0Alias = fieldsParse[0].includes(":")
                ? fieldsParse[0]?.split(":")[1]
                : null
              fieldsParse1 = fieldsParse[1].includes(":")
                ? fieldsParse[1]?.split(":")[0]
                : fieldsParse[1]
              fieldsParse1Alias = fieldsParse[1].includes(":")
                ? fieldsParse[1]?.split(":")[1]
                : null

              match = true
              queryStrParName = fieldsParse0Alias ?? fieldsParse1
            }
          }
        }
      })

      if (match) {
        let currentOtherFieldSelected
        let otherFieldMatchValue
        let fieldNameRepeater = it.fieldName
        // console.log(fieldsParse1, fieldsParse1Repeater, it.fieldName)

        let id_unitField = "id_unit"
        if (isFieldRepeater) {
          id_unitField = `${id_unitField}__${item?.fieldName.split("__")[1]}`
          fieldNameRepeater = `${it.fieldName}__${
            item?.fieldName.split("__")[1]
          }`
        }

        if (it?.fieldName === id_unitField && it?.type !== "dropdown") {
          currentOtherFieldSelected = storeUnit?.dataDropdown?.find(
            (item) => item.value === getValues(fieldNameRepeater)
          )
          otherFieldMatchValue = currentOtherFieldSelected?.[fieldsParse1]
        } else {
          currentOtherFieldSelected = tempAsyncOptValues?.[
            fieldNameRepeater
          ]?.find((c) => c.value === getValues(fieldNameRepeater))
          // console.log(currentOtherFieldSelected)
          otherFieldMatchValue = currentOtherFieldSelected?.[fieldsParse1]
        }

        if (otherFieldMatchValue) {
          // console.log(`fieldName yang dirujuk: ${fieldNameRepeater}`)
          // console.log(
          //   `key yang dirujuk dari fieldName ${fieldNameRepeater} = ${fieldsParse1}`
          // )
          // console.log(
          //   `value dari fieldName yang dirujuk: ${fieldNameRepeater} = `,
          //   currentOtherFieldSelected
          // )
          // console.log(
          //   `value dari key yang dirujuk dari fieldName ${fieldNameRepeater} = ${otherFieldMatchValue}`
          // )

          // const newVal = otherFieldMatchValue

          // let queryStrParName = otherFieldMatch
          // if value not "id" then set query key name from pageAttr
          if (fieldsParse1 === "id") {
            queryStrParName = it.fieldName
          }

          let newQueryStr = `${queryStrParName}=${otherFieldMatchValue}`
          // prevent query str duplicates
          if (
            !finUrl?.includes(`${newQueryStr}`) &&
            inputValue !== newQueryStr
          ) {
            const isQueryStr = finUrl?.includes("?")
            // let prefix = isQueryStr ? "&" : "?"

            const isParExist = finUrl?.includes(`${queryStrParName}=`)

            const afterChangeFillOtherApiParams =
              it?.dataFrom?.afterChangeFillOtherApiParams ||
              it?.afterChangeFillOtherApiParams
            if (afterChangeFillOtherApiParams) {
              newQueryStr = `${afterChangeFillOtherApiParams.replaceAll(
                "?",
                ""
              )}${otherFieldMatchValue}`
            }

            if (isQueryStr) {
              if (isParExist) {
                finUrl = finUrl?.replaceAll(
                  `${queryStrParName}=`,
                  `${newQueryStr}`
                )
              } else {
                finUrl = `${finUrl}&${queryStrParName}=${otherFieldMatchValue}`
              }
            } else {
              finUrl = `${finUrl}?${queryStrParName}=${otherFieldMatchValue}`
            }
          }
        }
      }
    })
    return finUrl
  }

  const afterChangeFillOtherSetValue = async (
    item,
    afterChangeFillOtherData,
    val = null,
    init = null,
    afterChangeFillOtherApiParams = null,
    afterChangeFillOtherApiMultiParams = null,
    mode = "input",
    mode_error = false
  ) => {
    await afterChangeFillOtherData?.map(async (it) => {
      const fieldsParse = it?.split("=")
      if (fieldsParse.length) {
        const fieldsParse0 = fieldsParse[0].includes(":")
          ? fieldsParse[0]?.split(":")[0]
          : fieldsParse[0]
        const fieldsParse0Alias = fieldsParse[0].includes(":")
          ? fieldsParse[0]?.split(":")[1]
          : null
        const fieldsParse1 = fieldsParse[1].includes(":")
          ? fieldsParse[1]?.split(":")[0]
          : fieldsParse[1]
        let targetField
        dynamicForm.map((x) => {
          if (x?.type === "column" && x?.children?.length) {
            x.children.map((xx) => {
              if (xx.fieldName === fieldsParse0) {
                targetField = xx
              }
            })
          } else {
            if (x.fieldName === fieldsParse0) {
              targetField = x
            }
          }
        })
        let targetFieldRepeater = targetField
        //IF FORM-REPEATER
        const isFieldRepeater = item?.fieldName.includes("__")
        let fieldsParse0Repeater = fieldsParse0
        let fieldsParse1Repeater = fieldsParse1
        if (isFieldRepeater) {
          fieldsParse0Repeater = `${fieldsParse0}__${
            item?.fieldName.split("__")[1]
          }`
          fieldsParse1Repeater = `${fieldsParse1}__${
            item?.fieldName.split("__")[1]
          }`
          targetField = {
            ...dynamicForm
              .find((x) => x.type === "form-repeater")
              ?.children?.find((x) => x.fieldName === fieldsParse0)
          }
          targetFieldRepeater = { ...targetField }
          targetFieldRepeater.fieldName = fieldsParse0Repeater
        }
        // console.log(
        //   val,
        //   init,
        //   !getValues(targetFieldRepeater?.fieldName),
        //   item?.fieldName === "id_unit"
        // )
        if (
          ((val && init && !getValues(targetFieldRepeater?.fieldName)) ||
            (val && !init)) &&
          mode !== "label" &&
          mode !== "desc"
        ) {
          // console.log("asdsadsa")
          // console.log(
          //   fieldsParse0Repeater,
          //   (val && init && !getValues(fieldsParse0Repeater)) || !init
          // )
          //reset dulu value lama

          // setValue(fieldsParse0Repeater, null)

          const isDropdDownType =
            (targetFieldRepeater?.type === "dropdown" ||
              targetFieldRepeater?.type === "id_employee" ||
              targetFieldRepeater?.type === "global_filter_year") &&
            targetFieldRepeater?.dataFrom !== null
          const isAutoCompleteTarget =
            targetFieldRepeater?.dataFrom?.isAutoComplete
          // console.log(isDropdDownType, isAutoCompleteTarget)
          if (isDropdDownType && !afterChangeFillOtherApiMultiParams) {
            // console.log(`target = ${targetField} isDropdDownType`)

            let prefix = targetField?.dataFrom?.api?.includes("?") ? "&" : "?"
            // SET PARAM TO OTHER FIELD
            let finUrl = null
            const newVal =
              val[fieldsParse1] ?? (Object.keys(val).length ? "" : val)
            // jika target adalah autocomplete, maka tambahkan saja langsung query string baru
            if (isAutoCompleteTarget) {
              let newQueryStr = `&${item.fieldName}=${newVal}`
              // if value not "id" then set query key name from pageAttr
              if (fieldsParse1 !== "id") {
                newQueryStr = `${prefix}${
                  fieldsParse0Alias ?? fieldsParse1
                }=${newVal}`
              }
              // jika ada afterChangeFillOtherApiParams maka ovveride query-string key
              if (afterChangeFillOtherApiParams) {
                newQueryStr = `&${afterChangeFillOtherApiParams.replaceAll(
                  "?",
                  ""
                )}${newVal}`
              }
              if (item?.fieldName !== targetFieldRepeater?.fieldName && !init) {
                await loadDropdownFromApiAutoComplete(
                  newQueryStr,
                  targetFieldRepeater,
                  true,
                  true,
                  false
                )
              }
            } else {
              let queryStrParName = item.fieldName
              // if value not "id" then set query key name from pageAttr
              if (fieldsParse1 !== "id") {
                queryStrParName = fieldsParse0Alias ?? fieldsParse1
              }
              // jika ada afterChangeFillOtherApiParams maka ovveride query-string key
              if (afterChangeFillOtherApiParams) {
                queryStrParName = `${afterChangeFillOtherApiParams
                  .replaceAll("?", "")
                  .replaceAll("=", "")}`
              }
              const isQueryStr = targetField.dataFrom?.api?.includes("?")
              // console.log(val)

              const isParExist = targetField.dataFrom?.api?.includes(
                `${queryStrParName}=`
              )

              if (isQueryStr) {
                if (isParExist) {
                  finUrl = targetField?.dataFrom?.api?.replaceAll(
                    `${queryStrParName}=`,
                    `${queryStrParName}=${newVal}`
                  )
                } else {
                  finUrl = `${targetField?.dataFrom?.api}&${queryStrParName}=${newVal}`
                }
              } else {
                finUrl = `${targetField?.dataFrom?.api}?${queryStrParName}=${newVal}`
              }
              if (item?.fieldName !== targetFieldRepeater?.fieldName && !init) {
                await loadDropdownFromApi(
                  newVal,
                  targetFieldRepeater,
                  true,
                  true,
                  true,
                  finUrl
                )
              }
            }
          } else {
            // console.log(fieldsParse1, val, userData)
            // console.log(
            //   fieldsParse0Repeater,
            //   fieldsParse1,
            //   item?.fieldName,
            //   val
            // )

            if (item?.fieldName === "id_unit" && item?.type !== "dropdown") {
              function isNumber(n) {
                return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
              }
              if (typeof val === "string" || isNumber(val)) {
                const seletedUnit = storeUnit?.dataDropdown?.find(
                  (item) => item.value === val
                )
                setValue(
                  fieldsParse0Repeater,
                  seletedUnit?.[fieldsParse1] ??
                    getDepthValue(seletedUnit, fieldsParse1)
                )
                onChangeAll2(fieldsParse0Repeater)
              } else {
                setValue(
                  fieldsParse0Repeater,
                  getDepthValue(val, fieldsParse1) ?? val?.[fieldsParse1]
                )
                onChangeAll2(fieldsParse0Repeater)
              }
            } else if (fieldsParse1 === item?.fieldName) {
              setValue(fieldsParse0Repeater, val)
              onChangeAll2(fieldsParse0Repeater)
            } else {
              let userDataValue = val[fieldsParse1]
              if (val[fieldsParse1] === "__userData.name__") {
                userDataValue = userData?.name
              } else if (val[fieldsParse1] === "__userData.email__") {
                userDataValue = userData?.email
              } else if (val[fieldsParse1] === "__userData.id__") {
                userDataValue = userData?.id
              } else if (val[fieldsParse1] === "__userData.username__") {
                userDataValue = userData?.username
              }
              let valuex =
                calcVal(fieldsParse1Repeater, item) ??
                getValues(fieldsParse1Repeater) ??
                userDataValue
              if (fieldsParse1Repeater === item?.fieldName) {
                valuex = val
              }

              setValue(
                fieldsParse0Repeater,
                val[fieldsParse1Repeater] ??
                  getDepthValue(val, fieldsParse1Repeater) ??
                  val[fieldsParse1] ??
                  valuex ??
                  ""
                // { shouldValidate: true, shouldTouch: true }
              )
              onChangeAll2(fieldsParse0Repeater)
              afterChangeEvent(valuex, targetFieldRepeater)
            }
          }
        } else {
          // console.log("hhherrr")
          if (
            ((targetFieldRepeater?.fieldName && !init) ||
              (targetFieldRepeater?.fieldName &&
                init &&
                !getValues(targetFieldRepeater?.fieldName))) &&
            mode !== "label" &&
            mode !== "desc"
          ) {
            setValue(targetFieldRepeater?.fieldName, "")
            trigger(targetFieldRepeater?.fieldName)
            onChangeAll2(targetFieldRepeater)
            // tempAsyncOptValues[targetFieldRepeater?.fieldName] = []
            // dispatch(
            //   setDataCache({
            //     [targetFieldRepeater?.fieldName]: []
            //   })
            // )
          } else {
            if (mode === "label") {
              if (fieldsParse1?.startsWith("'")) {
                inputLabelRef[fieldsParse0Repeater] = fieldsParse1.substring(
                  1,
                  fieldsParse1.length - 1
                )
              }
            } else if (mode === "desc") {
              let descriptionx = ""
              if (fieldsParse1?.startsWith("'")) {
                descriptionx = fieldsParse1.substring(
                  1,
                  fieldsParse1.length - 1
                )
              } else {
                // console.log(
                //   val[fieldsParse1Repeater] ??
                //     getDepthValue(val, fieldsParse1Repeater)
                // )
                descriptionx =
                  val[fieldsParse1Repeater] ??
                  getDepthValue(val, fieldsParse1Repeater)
              }

              if (mode_error) {
                fieldDesc[fieldsParse0Repeater] = ""
                setError(fieldsParse0Repeater, {
                  message: `${getValues(item?.fieldName)} : ${descriptionx}`
                })
                setValue(item?.fieldName, "")
                onChangeAll2(item?.fieldName)
              } else {
                fieldDesc[fieldsParse0Repeater] = descriptionx
              }
            }
          }
        }
      }
    })
  }

  const getAutoCompleteAPi = async (
    item,
    inputValue,
    init,
    afterChange = null
  ) => {
    // console.log("getAutoCompleteAPi")
    !item?.isCreatable
      ? setLoadingInputs(item?.fieldName, true, init)
      : setLoadingInputsCreate(item.fieldName, true, init)

    let finUrl = item?.dataFrom?.api
    finUrl = getParamsApiUrlAttr(finUrl, item)
    if (item?.dataFrom?.autoCompleteParams) {
      if (item?.dataFrom?.api.includes(item?.dataFrom?.autoCompleteParams)) {
        finUrl = item?.dataFrom?.api.replaceAll(
          item?.dataFrom?.autoCompleteParams,
          item?.dataFrom?.autoCompleteParams + inputValue
        )
      } else {
        finUrl =
          finUrl +
          (finUrl.includes("?") ? "&" : "?") +
          item?.dataFrom?.autoCompleteParams +
          inputValue
      }
    }
    finUrl = finUrl.replace("&?", "&")
    // console.log(finUrl)
    // check jika ada afterChangeFillOther dari field lain yang merujuk ke field ini
    if (!afterChange) {
      finUrl = afterChangeFillOther(item, inputValue, finUrl)
    }
    // console.log(finUrl)
    const baseUrlOpt = item?.dataFrom?.baseUrl ?? pageAttr?.crudOpt?.baseUrl
    const data = await getBaseUrl(baseUrlOpt, finUrl)
      .get(`${finUrl}`)
      .then((res) => {
        return renderDropdownItem(item, res)
      })

    if (data) {
      tempAsyncOptValues[item.fieldName] = data
      setTempAsyncOptValues(tempAsyncOptValues)

      if (dynamicTempState.data[item?.fieldName]) {
        const newDataMIxed = [
          ...dynamicTempState.data[item?.fieldName],
          ...data
        ]
        const unique = Array.from(
          new Set(newDataMIxed.map((o) => JSON.stringify(o))),
          (s) => JSON.parse(s)
        )
        // console.log("setDataCache unique")
        dispatch(setDataCache({ [item.fieldName]: unique }))
      } else {
        // console.log("setDataCache")
        dispatch(setDataCache({ [item.fieldName]: data }))
      }
    }
    !item?.isCreatable
      ? setLoadingInputs(item?.fieldName, false, init)
      : setLoadingInputsCreate(item.fieldName, false, init)
  }

  ///////INPUT METHOD OR FUNCTION

  const getMinMaxTimeInputs = (item, type) => {
    let result
    if (item?.calendarRules) {
      const minTime = item?.calendarRules?.minTime
      const minDate = item?.calendarRules?.minDate
      const maxTime = item?.calendarRules?.maxTime
      const maxDate = item?.calendarRules?.maxDate
      const minTimeField = item?.calendarRules?.minTimeFromField
      const minDateField = item?.calendarRules?.minDateFromField
      const maxTimeField = item?.calendarRules?.maxTimeFromField
      const maxDateField = item?.calendarRules?.maxDateFromField

      const nowTime = new Date()

      if (type === "min" && (minTime || minTimeField)) {
        if (minTime && !minTimeField) {
          if (minTime !== "today" && minTime !== "now") {
            result = minTime
          } else {
            result = nowTime
          }
        } else {
          const otherValue = getValues(minTimeField)
          if (otherValue) {
            result = otherValue
          }
        }
      } else if (type === "min" && (minDate || minDateField)) {
        if (minDate && !minDateField) {
          if (minDate !== "today" && minDate !== "now") {
            result = minDate
          } else {
            result = nowTime
          }
        } else {
          const otherValue = getValues(minDateField)
          if (otherValue) {
            result = otherValue
          }
        }
      } else if (type === "max" && (maxTime || maxTimeField)) {
        if (maxTime && !maxTimeField) {
          if (maxTime !== "today" && maxTime !== "now") {
            result = maxTime
          } else {
            result = nowTime
          }
        } else {
          const otherValue = getValues(maxTimeField)
          if (otherValue) {
            result = otherValue
          }
        }
      } else if (type === "max" && (maxDate || maxDateField)) {
        if (maxDate && !maxDateField) {
          if (maxDate !== "today" && maxDate !== "now") {
            result = maxDate
          } else {
            result = nowTime
          }
        } else {
          const otherValue = getValues(maxDateField)
          if (otherValue) {
            result = otherValue
          }
        }
      }
    }
    return result
  }

  const getDefaultDateInputs = (item, type) => {
    if (!editForm) {
      let result = new Date()
      if (item?.calendarRules) {
        const defaultDate = item.calendarRules?.defaultDate
        const defaultHour = item.calendarRules?.defaultHour
        if (defaultDate && (type === "date" || type === "datetime")) {
          if (defaultDate !== "today" && defaultDate !== "now") {
            result = new Date(defaultDate)
          }
        } else if (defaultHour && type === "time") {
          if (defaultHour !== "today" && defaultHour !== "now") {
            let myDate = new Date()
            myDate.setHours(
              defaultHour,
              item.calendarRules?.defaultMinute ?? 0,
              0,
              0
            )
            result = myDate
          }
        } else if (
          (!defaultDate && !defaultHour) ||
          item?.defaultDateDisabled
        ) {
          result = ""
        }
      }

      if (item?.defaultDateDisabled) {
        result = ""
      }

      return {
        defaultValue: result
      }
    } else {
      return {
        defaultValue:
          defaultFormValues[item?.fieldName] ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.fieldName
          ) ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.initialValueFromOther
          )
      }
    }
  }

  const calcVal = (inputName, item = null) => {
    if (
      inputName.indexOf("*") ||
      inputName.indexOf("+") ||
      inputName.indexOf("-") ||
      inputName.indexOf("/")
    ) {
      // let otherValueFieldName = []

      // if (inputName.includes("*")) {
      //   otherValueFieldName = inputName.split("*")
      //   operator = "*"
      // } else if (inputName.includes("+")) {
      //   otherValueFieldName = inputName.split("+")
      //   operator = "+"
      // } else if (inputName.includes("-")) {
      //   otherValueFieldName = inputName.split("-")
      //   operator = "-"
      // } else if (inputName.includes("/")) {
      //   otherValueFieldName = inputName.split("/")
      //   operator = "/"
      // }

      const otherValueFieldName = inputName.split(/[-+*/]/)
      const operators = inputName.match(/[-+*/]/g)
      let evals
      // console.log(otherValueFieldName, operators)

      if (otherValueFieldName?.length > 1) {
        // console.log(otherValueFieldName, operators)
        if (otherValueFieldName?.length > 2) {
          let evalString = ""
          otherValueFieldName.map((item, index) => {
            let input = getValues(item) ?? item

            if (input) {
              if (item?.valueType === "text") {
                evalString = `${evalString}${input}${
                  item?.afterChangeFillOtherSeparator ?? ""
                }`
              } else {
                let regExp = /[a-zA-Z]/g
                if (!regExp.test(input)) {
                  evalString = `${evalString}${input}${operators[index] ?? ""}`
                } else {
                  evalString = `${evalString}0${operators[index] ?? ""}`
                }
              }
            }
          })

          // console.log(evalString)
          evals = eval(evalString)
          return evals
        } else {
          let operator = operators[0]
          // if (otherValueFieldName[0].includes("this.")) {
          //   otherValueFieldName[0] = item?.fieldName
          // }
          let input1 =
            getValues(otherValueFieldName[0]) ?? otherValueFieldName[0]
          let input2 =
            getValues(otherValueFieldName[1]) ?? otherValueFieldName[1]
          // console.log(input1, input2)
          if (input1 && input2) {
            if (
              item?.valueType === "datetime" ||
              item?.valueType === "date" ||
              item?.type === "datetime" ||
              item?.type === "date"
            ) {
              input1 = new Date(Date.parse(input1))
              input2 = !isValidInteger(input2)
                ? new Date(Date.parse(input2))
                : input2
              let same = input1 === input2

              if (!same && operator === "-") {
                let input2New = null
                if (isValidInteger(input2)) {
                  input2New = input1
                  input2New.setDate(input2New.getDate() - parseInt(input2))
                  evals = input2New
                } else {
                  evals = Math.round((input1 - input2) / (1000 * 60 * 60 * 24))
                }
              } else if (!same && operator === "+") {
                let input2New = null
                if (isValidInteger(input2)) {
                  input2New = input1
                  input2New.setDate(input2New.getDate() + parseInt(input2))
                  evals = input2New
                } else {
                  evals = Math.round((input1 + input2) / (1000 * 60 * 60 * 24))
                }
              } else {
                evals = 0
              }
              // console.log(evals, evals + 1)
              return isValidInteger(input2) ? evals : evals + 1
            } else if (item?.valueType === "text") {
              return `${input1}${
                item?.afterChangeFillOtherSeparator ?? ""
              }${input2}`
            } else {
              let regExp = /[a-zA-Z]/g
              if (!regExp.test(input1) && !regExp.test(input2)) {
                // console.log(`${input1}${operator}${input2}`)
                let evals = eval(`${input1}${operator}${input2}`)
                return evals
              }
            }
          }
        }
      } else {
        return getValues(inputName)
      }
    }
  }

  async function afterChangeEvent(val, item, init = null) {
    // console.log(item)
    showIfCondition(item)
    disabledIfCondition(item)
    const afterChangeFillOther =
      item?.dataFrom?.afterChangeFillOther || item?.afterChangeFillOther
    const afterChangeHideOther =
      item?.dataFrom?.afterChangeHideOther || item?.afterChangeHideOther
    const afterChangeFillOtherApiParams =
      item?.dataFrom?.afterChangeFillOtherApiParams ||
      item?.afterChangeFillOtherApiParams
    const afterChangeFillOtherApiMultiParams =
      item?.dataFrom?.afterChangeFillOtherApiMultiParams ||
      item?.afterChangeFillOtherApiMultiParams

    const afterChangeGetValue =
      item?.dataFrom?.afterChangeGetValue || item?.afterChangeGetValue

    if (afterChangeFillOther) {
      await afterChangeFillOtherSetValue(
        item,
        afterChangeFillOther,
        val,
        init,
        afterChangeFillOtherApiParams,
        afterChangeFillOtherApiMultiParams
      )
    }

    if (afterChangeHideOther) {
      // console.log("afterChangeHideOther", item?.fieldName)
      let hiddenInputCopy = { ...hiddenInput }
      afterChangeHideOther.map((it) => {
        const value1 =
          typeof getValues()[it?.condition?.fieldName] === "boolean"
            ? getValues()[it?.condition?.fieldName]
            : `"${getValues()[it?.condition?.fieldName]}"`
        const value2 =
          typeof it?.condition?.value === "boolean"
            ? it.condition.value
            : `"${it?.condition?.value}"`
        if (eval(`${value1} ${it?.condition?.operator} ${value2}`)) {
          hiddenInputCopy[it?.fieldName] = true
        } else {
          hiddenInputCopy[it?.fieldName] = false
        }
        setHiddenInput(hiddenInputCopy)
      })
    }

    if (afterChangeGetValue) {
      // console.log(afterChangeGetValue)
      // console.log(val)
      // console.log(afterChangeGetValue?.dataFrom?.api)
      let afterChangeGetValueUrl = afterChangeGetValue?.dataFrom?.api
      afterChangeGetValueSetLoading(item, true)
      await loadDropdownFromApi(
        val,
        item,
        false,
        true,
        false,
        afterChangeGetValueUrl,
        afterChangeGetValue
      )
    }

    showIfCondition(item)
    disabledIfCondition(item)
    // console.log(`afterChange ${item.fieldName}`)
  }

  //   function getPageType() {
  //     const params = useParams();
  //     return params?.pageType
  // }

  const checkCondition = (data, itemCurrent, isDisabledIf = false) => {
    let result = false
    let resultArr = []
    let resultCount = 0
    let operator = false
    // console.log(data)
    if (data) {
      data.map((it) => {
        let fieldName = it?.fieldName
        let fieldNamePath = null
        let fieldValue = null

        //IF FORM-REPEATER
        const isFieldRepeater = itemCurrent?.fieldName.includes("__")
        if (isFieldRepeater) {
          fieldName = `${it?.fieldName}__${
            itemCurrent?.fieldName?.split("__")[1]
          }`
        }

        if (fieldName?.includes("__userData")) {
          if (fieldName === "__userData.name__") {
            fieldValue = userData?.name
          } else if (fieldName === "__userData.isManager__") {
            fieldValue = userData?.isManager
          } else if (fieldName === "__userData.email__") {
            fieldValue = userData?.email
          } else if (fieldName === "__userData.id__") {
            fieldValue = userData?.id
          } else if (fieldName === "__userData.username__") {
            fieldValue = userData?.username
          }
        } else if (fieldName?.includes("__domainData")) {
          // console.log(fieldName)
          fieldValue = getDepthValue(
            storeDomain?.data,
            fieldName?.replace("__domainData__.", ""),
            null
          )
        } else if (fieldName?.includes("__domain")) {
          const currentHostName = getCurrentHostName()
          // console.log(currentHostName)
          fieldValue = currentHostName
        } else {
          if (fieldName.includes(".") && !fieldName?.includes("__userData")) {
            let fieldNameObj = fieldName.split(".")
            if (fieldNameObj) {
              fieldName = fieldNameObj[0]
              fieldNamePath = fieldNameObj.slice(1).join(".")
              // console.log(fieldNamePath)
            }
          }

          if (it?.fieldNameFromOriginalData) {
            fieldValue = getDepthValue(defaultFormValues, fieldName, null)
            if (Object.keys(fieldValue ?? {})?.length > 0) {
              fieldValue = getDepthValue(defaultFormValues, it?.fieldName, null)
            }
          } else if (it?.fieldNameFromDropdownData) {
            const obj =
              tempAsyncOptValues[fieldName]?.find(
                (item) => item?.value === getValues(fieldName)
              ) ?? {}

            fieldValue = getDepthValue(obj, fieldNamePath)
          } else if (it?.fieldNameFromDynamicColumsFilter) {
            let lastFilterData = dynamicTempState?.data?.[fieldName]
            if (lastFilterData) {
              let lastFilterValue =
                store.pageData[storeName]?.dynamicColumnsFilterData?.[fieldName]
              // console.log(lastFilterValue)
              if (lastFilterValue) {
                let lastFilterDataSelected = lastFilterData?.find(
                  (i) => i?.id === lastFilterValue
                )
                fieldValue = getDepthValue(
                  lastFilterDataSelected,
                  fieldNamePath,
                  null
                )
              }
            }
          } else {
            fieldValue = getValues(fieldName)
          }
        }

        // console.log(it?.operator, it?.value, fieldValue)
        if (it?.operator && (it?.value || it?.value === 0)) {
          operator = true

          if (it?.valueType === "datetime" || it?.valueType === "date") {
            const newfieldValue = new Date(fieldValue)

            let newValue = null
            if (it?.value === "today") {
              newValue = new Date()
            } else {
              newValue = new Date(it?.value)
            }

            if (it?.operator === ">") {
              result = newfieldValue > newValue
            } else if (it?.operator === ">=") {
              result = newfieldValue >= newValue
            } else if (it?.operator === "<") {
              result = newfieldValue < newValue
            } else if (it?.operator === "<=") {
              result = newfieldValue <= newValue
            } else {
              result = newfieldValue === newValue
            }
          } else if (it?.valueType === "boolean") {
            if (it?.operator === "===") {
              result = fieldValue === it?.value
            } else if (it?.operator === "!==") {
              result = fieldValue !== it?.value
            }
          } else if (
            it?.fieldName === "__userData.isManager__" &&
            it?.value === "null"
          ) {
            if (it?.operator === "===") {
              result = fieldValue === null
            } else if (it?.operator === "!==") {
              result = fieldValue !== null
            }
          } else {
            if (it?.operator === "in") {
              // if (typeof it.value === "object") {
              let strValue = JSON.stringify(it?.value)
              let fieldValueAs = fieldValue
              if (typeof fieldValue === "string") {
                fieldValueAs = `"${fieldValue}"`
              }
              // console.log(`${strValue}?.includes(${fieldValueAs})`)
              result = eval(`${strValue}?.includes(${fieldValueAs})`)
              // } else {
              //   result = eval(`[${it?.value}]?.includes(${fieldValue})`)
              // }
            } else if (it?.operator === "not_in") {
              // result = eval(`![${it?.value}]?.includes(${fieldValue})`)
              let strValue = JSON.stringify(it?.value)
              let fieldValueAs = fieldValue
              if (typeof fieldValue === "string") {
                fieldValueAs = `"${fieldValue}"`
              }
              // console.log(`!${strValue}?.includes(${fieldValueAs})`)
              result = eval(`!${strValue}?.includes(${fieldValueAs})`)
            } else {
              // console.log(`"${fieldValue}"${it?.operator}"${it?.value}"`)
              if (!fieldValue && !isDisabledIf && fieldValue !== 0) {
                result = false
              } else {
                result = eval(`"${fieldValue}"${it?.operator}"${it?.value}"`)
              }
              // resultArr.push(result)
            }
          }
          resultArr.push(result)
        } else {
          // console.log(it?.value)
          let evals =
            fieldValue !== null &&
            fieldValue !== undefined &&
            fieldValue !== "undefined" &&
            fieldValue !== ""
          // console.log(fieldValue, evals)
          resultCount++
          result = result + evals
          resultArr.push(evals)
        }
      })
    }
    // if (isDisabledIf) {
    //   result = resultArr?.some((value) => value === true)
    //   // console.log(result)
    // }
    // // EVALUATE ARRAY TO FIND RESULT
    // if (resultArr.length > 0) {
    //   result = resultArr?.some((value) => value === true)
    // } else {
    //   result = resultArr?.every((value) => value === true)
    // }

    return {
      result: result,
      resultCount: resultCount,
      operator: operator
    }
  }

  const showIfCondition = async (itemCurrent) => {
    // console.log("showIfCondition", itemCurrent?.fieldName)
    return dynamicForm.map((item, index) => {
      if (item?.showIf) {
        let result = false
        let resultCount = 0
        let operator = false

        const resultCondition = checkCondition(item.showIf, itemCurrent)
        result = resultCondition.result
        resultCount = resultCondition.resultCount
        operator = resultCondition.operator
        const fieldNameGenerate =
          item?.type === "separator" ||
          item?.type === "column" ||
          item?.type === "tabs"
            ? `${item.type}_${index}`
            : item.fieldName
        if (operator) {
          setHiddenInput((current) => {
            return {
              ...current,
              [fieldNameGenerate]: !result
            }
          })
        } else {
          setHiddenInput((current) => {
            return {
              ...current,
              [fieldNameGenerate]: resultCount !== result
            }
          })
        }
      } else if (item?.children) {
        item.children?.map((item_child, index_child) => {
          if (item_child?.showIf) {
            let result = false
            let resultCount = 0
            let operator = false

            const resultCondition = checkCondition(
              item_child.showIf,
              itemCurrent
            )
            result = resultCondition.result
            resultCount = resultCondition.resultCount
            operator = resultCondition.operator

            const fieldNameGenerateChild =
              item_child?.type === "separator" ||
              item_child?.type === "column" ||
              item_child?.type === "tabs"
                ? `${item_child.type}_${index_child}`
                : item_child.fieldName
            if (operator) {
              setHiddenInput((current) => {
                return {
                  ...current,
                  [fieldNameGenerateChild]: !result
                }
              })
            } else {
              setHiddenInput((current) => {
                return {
                  ...current,
                  [fieldNameGenerateChild]: resultCount !== result
                }
              })
            }
          }
        })
      }
    })
  }

  const disabledIfCondition = async (itemCurrent) => {
    return dynamicForm.map((item, index) => {
      if (item?.disabledIf) {
        let result = false
        let resultCount = 0
        let operator = false

        const resultCondition = checkCondition(
          item.disabledIf,
          itemCurrent,
          true
        )
        result = resultCondition.result
        resultCount = resultCondition.resultCount
        operator = resultCondition.operator
        const fieldNameGenerate =
          item?.type === "separator" ||
          item?.type === "column" ||
          item?.type === "tabs"
            ? `${item.type}_${index}`
            : item.fieldName
        if (operator) {
          setDisabledInputs((current) => {
            return {
              ...current,
              [fieldNameGenerate]: !result
            }
          })
        } else {
          setDisabledInputs((current) => {
            return {
              ...current,
              [fieldNameGenerate]: resultCount !== result
            }
          })
        }
      } else if (item?.children) {
        item.children?.map((item_child, index_child) => {
          if (item_child?.disabledIf) {
            let result = false
            let resultCount = 0
            let operator = false

            const resultCondition = checkCondition(
              item_child.disabledIf,
              itemCurrent,
              true
            )
            result = resultCondition.result
            resultCount = resultCondition.resultCount
            operator = resultCondition.operator

            const fieldNameGenerateChild =
              item_child?.type === "separator" ||
              item_child?.type === "column" ||
              item_child?.type === "tabs"
                ? `${item_child.type}_${index_child}`
                : item_child.fieldName
            if (operator) {
              setDisabledInputs((current) => {
                return {
                  ...current,
                  [fieldNameGenerateChild]: !result
                }
              })
            } else {
              setDisabledInputs((current) => {
                return {
                  ...current,
                  [fieldNameGenerateChild]: resultCount !== result
                }
              })
            }
          }
        })
      }
    })
  }

  const handleChangeInputs = (isMulti = false) => {
    // console.log(value, fieldName, isMulti)
    // setValue(fieldName, value)
    // if (isMulti) {
    //   if (formDatas[fieldName]) {
    //     const index = formDatas[fieldName].indexOf(value)
    //     if (index !== -1) {
    //       formDatas[fieldName].splice(index, 1)
    //     } else {
    //       formDatas[fieldName].push(value)
    //     }
    //   } else {
    //     formDatas[fieldName] = [value]
    //   }
    // } else {
    //   formDatas[fieldName] = value
    // }
    // formDatasError[fieldName] = false
    // setFormDatasError({ ...formDatasError, [fieldName]: false })
  }

  const handleChangeInputs2 = async (val, item, fieldState) => {
    const fieldName = item?.fieldName
    await trigger(fieldName)
    // console.log(fieldState, val)
    // setValue(field, val)
    if (
      fieldState?.invalid === false &&
      // fieldState.isDirty === true &&
      !errors[fieldName]
    ) {
      item?.afterChangeGetValue
        ? debouncedAfterChangeEvent(val, item)
        : afterChangeEvent(val, item)
      onChangeAll()
      onChangeAll2(item?.fieldName)
    }
  }

  ////FILE & IMAGE & CROPS BEGIN
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const setUploadImagesTemps = (data, fieldName) => {
    let uploadImagesTempCopy = { ...uploadImagesTemp }
    uploadImagesTempCopy[fieldName] = data
    setUploadImagesTemp(uploadImagesTempCopy, fieldName)
  }

  const setImgPreviews = async (data, fieldName) => {
    let imgPreviewCopy = { ...imgPreview }
    imgPreviewCopy[fieldName] = await readFile(data)
    setImgPreview(imgPreviewCopy)
  }

  const setCropImage = (data, fieldName) => {
    let cropTempCopy = { ...crop }
    cropTempCopy[fieldName] = data
    setCrop(cropTempCopy)
  }

  const setZoomImage = (data, fieldName) => {
    let zoomTempCopy = { ...zoom }
    zoomTempCopy[fieldName] = data
    setZoom(zoomTempCopy)
  }

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixelss, fieldName) => {
      // console.log(croppedArea, croppedAreaPixelss)
      let croppedAreaPixelsCopy = { ...croppedAreaPixels }
      croppedAreaPixelsCopy[fieldName] = croppedAreaPixelss
      setCroppedAreaPixels(croppedAreaPixelsCopy)
      try {
        // console.log(imgPreview[fieldName])
        const croppedImage = await getCroppedImg(
          imgPreview[fieldName],
          croppedAreaPixels[fieldName],
          0,
          { horizontal: false, vertical: false },
          false,
          true
        )
        setValue(
          fieldName,
          new File([croppedImage], `${fieldName}_${new Date().getTime()}.jpeg`)
        )
      } catch (e) {
        console.error(e)
      }
    },
    [imgPreview, croppedAreaPixels]
  )

  const removeImage = (fieldName) => {
    setUploadImagesTemp({ ...uploadImagesTemp, [fieldName]: null })
    setImgPreview({ ...imgPreview, [fieldName]: null })
    setCrop({ ...crop, [fieldName]: null })
    setZoom({ ...zoom, [fieldName]: null })
    setFilesDefaultTemp({ ...filesDefaultTemp, [fieldName]: null })
  }

  ///////DEFAULT VIEW INPUT TEXT
  const renderDefInputViewToText = (fieldName, value) => {
    return (
      <Fragment>
        <p
          style={{
            minHeight: "21px"
          }}
          className="mb-0 mt-1 text-truncate"
          key={`dynInput_${fieldName}`}
        >
          {value ? value : "-"}
        </p>
        <hr className="m-0" />
      </Fragment>
    )
  }

  const renderDefInputViewToTextArea = (fieldName, value) => {
    return (
      <Fragment>
        <p
          style={{
            minHeight: "21px"
          }}
          className="mb-0 mt-1"
          key={`dynInput_${fieldName}`}
        >
          {value ? value : "-"}
        </p>
        <hr className="m-0" />
      </Fragment>
    )
  }

  const renderDefInputViewToCurrency = (fieldName, value, item = null) => {
    const storeageLang =
      item?.currencyCode ?? localStorage.getItem("i18nextLng")
    const currentLang =
      storeageLang === "id" || storeageLang === "IDR" ? "id-ID" : "en-US"
    const currentCurrency =
      storeageLang === "id" || storeageLang === "IDR" ? "IDR" : "USD"

    return (
      <Fragment>
        <p
          style={{
            minHeight: "21px"
          }}
          className="mb-0 mt-1 text-truncate"
          key={`dynInput_${fieldName}`}
        >
          {new Intl.NumberFormat(currentLang, {
            style: "currency",
            currency: currentCurrency
          }).format(value ?? 0)}
        </p>
        <hr className="m-0" />
      </Fragment>
    )
  }

  const renderDefInputViewToDateRange = (fieldName, value1, value2) => {
    const calendarFormatx = {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[last] dddd",
      nextWeek: "dddd",
      sameElse: "L"
    }
    return (
      <Fragment>
        <p
          style={{
            minHeight: "21px"
          }}
          className="mb-0 mt-1 text-truncate"
          key={`dynInput_${fieldName}`}
        >
          {value1 && <Moment calendar={calendarFormatx}>{value1}</Moment>} -{" "}
          {value2 && <Moment calendar={calendarFormatx}>{value2}</Moment>}
        </p>
        <hr className="m-0" />
      </Fragment>
    )
  }

  //////LOADING FIRST
  const getLabelTitle = (fieldName) => {
    try {
      const columnTitle = fieldName.replace(/([A-Z])/g, "$1")
      let columnTitleEnd =
        columnTitle?.charAt(0)?.toUpperCase() + columnTitle?.slice(1)
      columnTitleEnd = columnTitleEnd?.replaceAll("_", " ")
      return columnTitleEnd
    } catch (error) {
      return fieldName ?? ""
    }
  }

  const OverlayCoverAll = ({ options = {} }) => {
    const imageBounds = options?.imageBound ?? [
      [40.7937721704678, -74.12398338317873],
      [40.68581858921337, -74.23092842102052]
    ]

    let imageUrl = options?.static
    if (options?.currentClickedRowData) {
      // console.log(options, store?.currentClickedRowData)
      if (store?.currentClickedRowData) {
        imageUrl = store.currentClickedRowData?.[options.currentClickedRowData]
      }
    }
    // console.log(imageUrl)
    if (imageUrl) {
      return (
        <ImageOverlay
          // className="custom-image-overlay"
          url={imageUrl}
          bounds={imageBounds}
        />
      )
    }

    return null
  }

  const renderInput = (
    item,
    index,
    prefixKey = pageAttr?.relationsCrudKey ?? ""
  ) => {
    const fieldName = item?.fieldName
    const fieldNameEl = item?.fieldName?.replace(/\./g, "_")
    const fieldLabel = getLabelTitle(
      item?.type === "separator"
        ? item?.fieldLabel
        : inputLabelRef[item?.fieldName] ?? item?.fieldLabel ?? fieldName
    )

    ////SET DEFAULT VALUE FROM ATTRIBUTE
    let defaultValueSet = {}
    if (item?.defaultValue) {
      defaultValueSet = {
        value: item?.defaultValue
      }
    }

    // SET VIEWONLY ATTRIBUTE
    const fieldNameGenerate =
      item?.type === "separator" ||
      item?.type === "column" ||
      item?.type === "tabs"
        ? `${item.type}_${index}`
        : ""
    const hideOnPageClassName = {
      hidden:
        hiddenInput[fieldName ?? ""] === true ||
        hiddenInput[fieldNameGenerate] === true ||
        item?.isHidden ||
        item?.hideOnPage?.[pageType ?? useParams()["pageType"]] === true
    }

    // SET DISABLED ATTRIBUTE
    const disabledInput =
      item?.isDisabled ||
      disabledInputs[fieldName ?? ""] === true ||
      disabledInputs[fieldNameGenerate] === true ||
      (filterMode && store.pageData[storeName]?.isLoading) ||
      hiddenInput[fieldName] === true ||
      pageType === "view" ||
      item?.isDisabledToText ||
      item?.disabledOnPage?.[pageType]

    const colSizeFix = {
      // md: item?.size ?? true,
      // xs: 12
      xxl: item?.size?.xxl ?? item?.size ?? true,
      xl: item?.size?.xl ?? item?.size ?? true,
      lg: item?.size?.lg ?? item?.size ?? true,
      md: item?.size?.md ?? item?.size ?? true,
      sm: item?.size?.sm ?? item?.size ?? true,
      xs: item?.size?.xs ?? 12
    }
    let rules = { ...item?.rules }

    if (rules) {
      let rulesCopy = JSON.parse(JSON.stringify(rules))

      if (rulesCopy.pattern) {
        if (rulesCopy.pattern.value) {
          if (
            typeof rulesCopy.pattern.value === "string" &&
            rulesCopy.pattern.value.includes("^", "[")
          ) {
            try {
              const regEx = new RegExp(rulesCopy.pattern.value.slice(1, -1))
              rulesCopy["pattern"]["value"] = regEx
              rules = rulesCopy
            } catch (error) {
              console.log(error)
            }
          }
        }
      }

      if ("validateValueToOther" in rules) {
        delete rules?.validateValueToOther
      }
      if ("maxValueFromOther" in rules) {
        delete rules?.maxValueFromOther
      }
      if ("requiredCondition" in rules) {
        delete rules?.requiredCondition
      }
    }

    let calendarRules = { ...item?.calendarRules }
    if (calendarRules) {
      delete calendarRules.defaultDate
      delete calendarRules.defaultHour
      delete calendarRules.defaultMinute
    }

    let labelInput = item?.fieldLabelHide !== true && (
      <Label className="" for={`input_${fieldName}`}>
        {!item?.inLineLabel ? t(fieldLabel ?? fieldName ?? "") : " "}
        {(item?.required || rules?.required) && (
          <span className="text-danger">*</span>
        )}
        {item?.tooltip && (
          <Fragment>
            <HelpCircle
              style={{
                marginBottom: "2px"
              }}
              id={`input-tip-${fieldNameEl ?? fieldName}`}
              className="ms-50 text-secondary"
              size={12}
            />
            <UncontrolledTooltip
              target={`input-tip-${fieldNameEl ?? fieldName}`}
            >
              {t(item.tooltip)}
            </UncontrolledTooltip>
          </Fragment>
        )}
      </Label>
    )

    const editOrViewState = pageType !== "view" && !item?.isDisabledToText

    let customStyles = {}

    let onlyInputAttrEvent = {
      ...(onlyInputMode
        ? {
            onClick: (e) => e.stopPropagation(),
            onMouseDown: (e) => e.stopPropagation(),
            onMouseUp: (e) => e.stopPropagation(),
            onDragStart: (e) => e.stopPropagation()
          }
        : {})
    }

    /////SORT BY ID AND PARENT_ID
    let minParentId = null
    function assemble(arr, parentId = null) {
      const table = {}
      arr.forEach((el) => {
        if (!table[el.parent_id]) {
          table[el.parent_id] = []
        }
        table[el.parent_id].push(el)
        if (el.parent_id < minParentId) {
          minParentId = el.parent_id
        }
      })
      const result = []
      const constructResult = (key) => {
        if (table[key]) {
          table[key].forEach((el) => {
            result.push(el)
            constructResult(el.id)
          })
        }
      }
      constructResult(minParentId === 0 ? null : minParentId)
      return result
    }

    let current_tempAsyncOptValues =
      tempAsyncOptValues[fieldName] ?? dynamicTempState.data[item?.fieldName]
    let current_tempAsyncOptValues2 =
      tempAsyncOptValues[fieldName] ?? dynamicTempState.data[item?.fieldName]
    if (current_tempAsyncOptValues?.length) {
      if (
        Object.keys(current_tempAsyncOptValues[0]).includes("parent_id") &&
        item?.dataFrom?.flat_mode !== true
      ) {
        minParentId = Math.min.apply(
          null,
          current_tempAsyncOptValues2?.map((value) => value?.parent_id)
        )
        current_tempAsyncOptValues = assemble(
          current_tempAsyncOptValues2,
          minParentId
        )

        // jika tree function gagal kembalikasn data ke semula
        if (
          current_tempAsyncOptValues?.length === 0 &&
          current_tempAsyncOptValues2?.length !== 0
        ) {
          current_tempAsyncOptValues = current_tempAsyncOptValues2
        } else {
          let elObj = {}

          for (const el of current_tempAsyncOptValues) {
            elObj[el.id] = { parent_id: el.parent_id }
          }

          minParentId = minParentId === 0 ? null : minParentId
          // console.log(elObj, minParentId)
          const getAncestory = (id, res) => {
            // console.log(minParentId)
            if (elObj[id]?.parent_id === minParentId) {
              res.push(minParentId)
              return res
            }
            res.push(elObj[id]?.parent_id)
            return getAncestory(elObj[id]?.parent_id, res)
          }

          const IconOption = (props) => (
            <Option {...props}>
              {props.data?.parent_id &&
                props.data?.parent_id !== minParentId && (
                  <ChevronRight className="" size={12} />
                )}
              {props.data.label}
            </Option>
          )

          const weight = [800, 600, 500, 400, 300, 200, 100]
          const { Option } = components
          customStyles = {
            styles: {
              option: (provided, state) => {
                const lvl =
                  store.data?.length > 1 && state.data?.id
                    ? getAncestory(state.data?.id, [state.data?.id ?? 0]).length
                    : 1
                return {
                  ...provided,
                  fontWeight:
                    state.data?.parent_id === minParentId
                      ? // || state.data?.hasChild
                        weight?.[lvl - 1]
                      : "normal",
                  paddingLeft:
                    state.data?.parent_id === minParentId
                      ? // || state.data?.hasChild
                        20
                      : getAncestory(state.data?.id, [state.data?.id ?? 0])
                          ?.length *
                          20 -
                        20
                }
              }
            },
            components: {
              Option: IconOption
            }
          }
        }
      }
    }

    ////////////INPUT - ID_UNIT
    if (item["fieldName"] === "id_unit" && item?.type !== "dropdown") {
      return (
        <Col
          key={`col_${fieldName}`}
          id={`col_${fieldName}`}
          className={classnames(hideOnPageClassName, {
            "mb-1": !item?.fieldDesc
          })}
          {...colSizeFix}
        >
          {labelInput}

          {editOrViewState ? (
            <Controller
              // defaultValue={defValue}
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field, value }) => {
                if (!item?.dropdownAsTreeTable) {
                  return storeUnit.data?.length > 0 ? (
                    <GlobalUnitDropdownForm
                      key={`col_unit_${fieldName}`}
                      parentChange={handleChangeInputs}
                      defaultFormValue={defaultFormValues[fieldName]}
                      optionPlaceHolder={
                        Object.keys(defaultFormValues)?.length === 0
                      }
                      isClearable={!item?.rules?.required || item?.required}
                      isDisabled={disabledInput}
                      placeholder={
                        item.placeholder
                          ? t(item.placeholder)
                          : onlyInputMode
                          ? t("Filter By ") + item?.columnName ??
                            item?.fieldLabel ??
                            item?.fieldName
                          : `${t("Select")} ${t(fieldLabel)}`
                      }
                      noOptionsMessage={t("No Options Found")}
                      loadingMessage={t("Loading...")}
                      // optionPlaceHolder={
                      //   item?.placeholder
                      //     ? t(item?.placeholder)
                      //     : `${t("Select")} ${t(fieldLabel)}`
                      // }
                      error={errors[fieldName]}
                      field={field}
                      value={value}
                      filterMode={filterMode}
                      item={item}
                      beforeHandleFiltersChangeToParent={
                        beforeHandleFiltersChangeToParent
                      }
                      afterChangeEvent={afterChangeEvent}
                      afterChangeUnitCount={afterChangeUnitCount}
                      setAfterChangeUnitCount={setAfterChangeUnitCount}
                      onChangeAll={onChangeAll}
                      onChangeAll2={onChangeAll2}
                    />
                  ) : (
                    <Select
                      className="react-select"
                      isDisabled={true}
                      classNamePrefix="select"
                      isLoading={true}
                    />
                  )
                }
              }}
            />
          ) : (
            <Fragment>
              <p className="mb-0 mt-1" key={`dynInput_${fieldName}`}>
                {storeUnit?.dataDropdown?.find(
                  (item) => item?.value === defaultFormValues[fieldName]
                )?.["name"] ?? "-"}
              </p>
              <hr className="m-0" />
            </Fragment>
          )}
          {errors[item.fieldName] ? (
            <FormText color="danger">
              {t(errors[item.fieldName]?.message)}
            </FormText>
          ) : (
            <FormText className="text-muted">
              {item?.fieldDesc ? t(item?.fieldDesc) : ""}
            </FormText>
          )}
        </Col>
      )
    } else {
      ////////////INPUT - EMPLOYEE
      if (item["type"] === "id_employee") {
        const lockState =
          (userData?.id_employee && !userData?.isManager && !userData?.isHRD) ||
          item?.lockedState
        const defaultEmployee = !lockState
          ? Object.keys(defaultFormValues).length > 0
            ? !item?.isMultiSelect
              ? current_tempAsyncOptValues?.find(
                  (c) => c.value === defaultFormValues[fieldName]
                )
              : current_tempAsyncOptValues
            : item?.initialValue
          : current_tempAsyncOptValues?.find(
              (c) => c.value === userData?.id_employee
            )
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field, value }) => {
                  let componentsInput = renderAvatarImgIcon(item)

                  ////RENDER SELECT DROPDOWN
                  let InputFinal = (
                    <Select
                      isDisabled={
                        lockState ||
                        disabledInput ||
                        tempAsyncOptCreateLoading[fieldName] ||
                        (item?.fieldName === "parent_id" &&
                          defaultFormValues["parent_id"] &&
                          !defaultFormValues["id"])
                      }
                      isMulti={item?.isMultiSelect}
                      id={`input_${fieldName}`}
                      invalid={errors[fieldName] && true}
                      isClearable={
                        !item?.rules?.required && !item?.isMultiSelect
                      }
                      {...customStyles}
                      className={classnames("react-select", {
                        "is-invalid": errors[fieldName],
                        "select-multi-full-width": item?.isMultiSelectFullWidth
                      })}
                      // autoFocus={!!defaultFormValues[fieldName]}
                      key={JSON.stringify(
                        current_tempAsyncOptValues?.find(
                          (c) => c.value === defaultFormValues[fieldName]
                        )
                      )}
                      {...componentsInput}
                      placeholder={
                        item.placeholder
                          ? t(item.placeholder)
                          : onlyInputMode
                          ? t("Filter By ") + item?.columnName ??
                            item?.fieldLabel ??
                            item?.fieldName
                          : `${t("Select")} ${t(fieldLabel)}`
                      }
                      noOptionsMessage={() => t("No Options Found")}
                      loadingMessage={() => t("Loading...")}
                      classNamePrefix="select"
                      ref={field.ref}
                      onChange={(val) => {
                        const finalVal = !item?.isMultiSelect
                          ? val?.value
                          : val.map(({ id }) => id)

                        field.onChange(finalVal)
                        if (
                          item.fieldName.includes("Id_") &&
                          Object.keys(getValues()).includes(
                            item.fieldName.replace("Id_", "")
                          )
                        ) {
                          setValue(item.fieldName.replace("Id_", ""), val)
                        }

                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                      onFocus={(e) => loadDropdownFromApi(e, item)}
                      isLoading={tempAsyncOptLoading[fieldName] ?? false}
                      options={current_tempAsyncOptValues}
                      defaultValue={defaultEmployee}
                      value={
                        !lockState
                          ? current_tempAsyncOptValues?.find(
                              (c) => c.value === value
                            )
                          : current_tempAsyncOptValues?.find(
                              (c) => c.value === userData?.id_employee
                            )
                      }
                    />
                  )

                  return InputFinal
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                current_tempAsyncOptValues?.find((c) =>
                  c.value === !lockState
                    ? defaultFormValues[fieldName]
                    : userData?.id_employee
                )?.label
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - GLOBAL FILTER YEAR
      if (item["type"] === "global_filter_year") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                name={fieldName}
                render={({ field, value }) => {
                  let InputFinal = (
                    <Select
                      // isMulti={item?.isMultiSelect}
                      id={`input_${fieldName}`}
                      invalid={errors[fieldName] && true}
                      {...customStyles}
                      className={classnames("react-select", {
                        "is-invalid": errors[fieldName],
                        "select-multi-full-width": item?.isMultiSelectFullWidth
                      })}
                      key={JSON.stringify(
                        current_tempAsyncOptValues?.find(
                          (c) => c.value === defaultFormValues[fieldName]
                        )
                      )}
                      placeholder={
                        item.placeholder
                          ? t(item.placeholder)
                          : onlyInputMode
                          ? t("Filter By ") + item?.columnName ??
                            item?.fieldLabel ??
                            item?.fieldName
                          : `${t("Select")} ${t(fieldLabel)}`
                      }
                      noOptionsMessage={() => t("No Options Found")}
                      loadingMessage={() => t("Loading...")}
                      classNamePrefix="select"
                      ref={field.ref}
                      onChange={(val) => {
                        const finalVal = !item?.isMultiSelect
                          ? val?.value
                          : val.map(({ id }) => id)
                        dispatch(setGlobalFilterYear(finalVal))
                        field.onChange(finalVal)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                      options={genDropdownDataYear()}
                      defaultValue={storeGlobalFilter.data_year}
                      value={genDropdownDataYear()?.find(
                        (c) => c.value === storeGlobalFilter.data_year
                      )}
                    />
                  )

                  return InputFinal
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                current_tempAsyncOptValues?.find((c) =>
                  c.value === !lockState
                    ? defaultFormValues[fieldName]
                    : userData?.id_employee
                )?.label
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - ARRAY
      else if (item["type"] === "array") {
        function arrayMove(array, from, to) {
          const slicedArray = array.slice()
          slicedArray.splice(
            to < 0 ? array.length + to : to,
            0,
            slicedArray.splice(from, 1)[0]
          )
          return slicedArray
        }

        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field, value }) => {
                  let componentsInput = renderAvatarImgIcon(item)

                  ////RENDER SELECT DROPDOWN

                  let InputFinal = <Fragment></Fragment>

                  const valuex =
                    defaultFormValues?.[item.fieldName] ??
                    defaultFormValues?.[item?.initialValueFromOther] ??
                    getDepthValue(
                      store.pageData[storeName]?.selectedData,
                      item?.initialValueFromOther
                    ) ??
                    []

                  let selected_valuex = []
                  if (valuex) {
                    selected_valuex = (
                      Array.isArray(valuex) ? valuex : [valuex]
                    )?.map((e) => {
                      return {
                        label: e,
                        value: e
                      }
                    })
                  }

                  InputFinal = (
                    <CreatableSelect
                      key={JSON.stringify(
                        current_tempAsyncOptValues?.find(
                          (c) => c.value === valuex
                        )
                      )}
                      isMulti
                      isDisabled={
                        disabledInput ||
                        tempAsyncOptCreateLoading[fieldName] ||
                        (item?.fieldName === "parent_id" &&
                          defaultFormValues["parent_id"] &&
                          !defaultFormValues["id"])
                      }
                      id={`input_${fieldName}`}
                      invalid={errors[fieldName] && true}
                      isClearable={!item?.rules?.required}
                      className={classnames("react-select", {
                        "is-invalid": errors[fieldName],
                        "select-multi-full-width": item?.isMultiSelectFullWidth
                      })}
                      theme={selectThemeColors}
                      placeholder={
                        item.placeholder
                          ? t(item.placeholder)
                          : onlyInputMode
                          ? t("Filter By ") + item?.columnName ??
                            item?.fieldLabel ??
                            item?.fieldName
                          : `${t("Select or Create")} ${t(fieldLabel)}`
                      }
                      noOptionsMessage={() => t("No Options Found")}
                      // loadingMessage={() => t("Loading...")}
                      formatCreateLabel={(inputValue) =>
                        `${t("Create")} ${inputValue}`
                      }
                      classNamePrefix="select"
                      ref={field.ref}
                      onChange={(val) => {
                        const finalVal = val?.map((vals, i) => {
                          return vals.value
                        })
                        // console.log(finalVal)
                        field.onChange(finalVal)
                        // if (
                        //   item.fieldName.includes("Id_") &&
                        //   Object.keys(getValues()).includes(
                        //     item.fieldName.replace("Id_", "")
                        //   )
                        // ) {
                        //   setValue(item.fieldName.replace("Id_", ""), val)
                        // }
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                      // onCreateOption={async (val) => {
                      //   await handleCreateOptions(val, item)
                      // }}
                      // allowCreateWhileLoading={false}
                      // createOptionPosition="first"
                      // getNewOptionData={(e, t) => {
                      //   console.log(e, t)
                      // }}
                      // isLoading={tempAsyncOptLoading[fieldName] ?? false}
                      // onInputChange={(v, e) => {
                      //   if (e.action === "input-change") {
                      //     handleAutoCompleteChange(
                      //       v,
                      //       item,
                      //       current_tempAsyncOptValues
                      //     )
                      //   }
                      // }}
                      {...customStyles}
                      {...componentsInput}
                      // onFocus={(e) => loadDropdownFromApi(e, item)}
                      options={selected_valuex}
                      defaultValue={selected_valuex}
                      // value={selected_valuex?.find((c) => c.value === value)}
                    />
                  )

                  return InputFinal
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                current_tempAsyncOptValues?.find(
                  (c) => c.value === defaultFormValues[fieldName]
                )?.label ?? "-"
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - DROPDOWN
      else if (item["type"] === "dropdown") {
        if (item?.dataFrom?.api) {
          return (
            <Col
              key={`col_${fieldName}`}
              id={`col_${fieldName}`}
              className={classnames(hideOnPageClassName, {
                "mb-1": !item?.fieldDesc
              })}
              {...colSizeFix}
            >
              {labelInput}
              {editOrViewState ? (
                <Controller
                  control={control}
                  rules={item.rules}
                  name={fieldName}
                  render={({ field, value }) => {
                    let componentsInput = renderAvatarImgIcon(item)

                    ////RENDER SELECT DROPDOWN

                    let InputFinal = <Fragment></Fragment>

                    if (
                      item?.isCreatable &&
                      !item?.isAutoComplete &&
                      !item.dataFrom?.isAutoComplete
                    ) {
                      const valuex =
                        defaultFormValues?.[item.fieldName] ??
                        defaultFormValues?.[
                          item?.initialValueFromOther ??
                            item?.dataFrom?.initialValueFromOther
                        ] ??
                        getDepthValue(
                          store.pageData[storeName]?.selectedData,
                          item?.initialValueFromOther ??
                            item?.dataFrom?.initialValueFromOther
                        ) ??
                        ""
                      const selected_valuex = valuex
                        ? renderDropdownItem(item, {
                            data: { data: valuex }
                          })
                        : []

                      InputFinal = (
                        <CreatableSelect
                          key={JSON.stringify(
                            current_tempAsyncOptValues?.find(
                              (c) => c.value === valuex
                            )
                          )}
                          isDisabled={
                            disabledInput ||
                            tempAsyncOptCreateLoading[fieldName] ||
                            (item?.fieldName === "parent_id" &&
                              defaultFormValues["parent_id"] &&
                              !defaultFormValues["id"])
                          }
                          id={`input_${fieldName}`}
                          invalid={errors[fieldName] && true}
                          isClearable={!item?.rules?.required}
                          className={classnames("react-select", {
                            "is-invalid": errors[fieldName],
                            "select-multi-full-width":
                              item?.isMultiSelectFullWidth
                          })}
                          theme={selectThemeColors}
                          placeholder={
                            item.placeholder
                              ? t(item.placeholder)
                              : onlyInputMode
                              ? t("Filter By ") + item?.columnName ??
                                item?.fieldLabel ??
                                item?.fieldName
                              : `${t("Select or Create")} ${t(fieldLabel)}`
                          }
                          noOptionsMessage={() => t("No Options Found")}
                          loadingMessage={() => t("Loading...")}
                          formatCreateLabel={(inputValue) =>
                            `${t("Create")} ${inputValue}`
                          }
                          classNamePrefix="select"
                          ref={field.ref}
                          onChange={(val) => {
                            const finalVal = val?.value
                            // console.log(val)
                            field.onChange(finalVal)
                            if (
                              item.fieldName.includes("Id_") &&
                              Object.keys(getValues()).includes(
                                item.fieldName.replace("Id_", "")
                              )
                            ) {
                              setValue(item.fieldName.replace("Id_", ""), val)
                            }
                            if (filterMode) {
                              beforeHandleFiltersChangeToParent()
                            }
                            afterChangeEvent(val, item)
                            onChangeAll()
                            onChangeAll2(item?.fieldName)
                          }}
                          onBlur={field.onBlur}
                          onCreateOption={async (val) => {
                            await handleCreateOptions(val, item)
                          }}
                          allowCreateWhileLoading={false}
                          createOptionPosition="first"
                          // getNewOptionData={(e, t) => {
                          //   console.log(e, t)
                          // }}
                          isLoading={tempAsyncOptLoading[fieldName] ?? false}
                          // onInputChange={(v, e) => {
                          //   if (e.action === "input-change") {
                          //     handleAutoCompleteChange(
                          //       v,
                          //       item,
                          //       current_tempAsyncOptValues
                          //     )
                          //   }
                          // }}
                          {...customStyles}
                          {...componentsInput}
                          onFocus={(e) => loadDropdownFromApi(e, item)}
                          options={current_tempAsyncOptValues}
                          defaultValue={
                            !item?.isMultiSelect
                              ? current_tempAsyncOptValues?.find(
                                  (c) => c.value === valuex
                                )
                              : selected_valuex
                          }
                          value={current_tempAsyncOptValues?.find(
                            (c) => c.value === value
                          )}
                        />
                      )
                    } else if (
                      item?.isAutoComplete ||
                      item.dataFrom?.isAutoComplete
                    ) {
                      const valuex =
                        defaultFormValues?.[item.fieldName] ??
                        defaultFormValues?.[
                          item?.initialValueFromOther ??
                            item?.dataFrom?.initialValueFromOther
                        ] ??
                        getDepthValue(
                          store.pageData[storeName]?.selectedData,
                          item?.initialValueFromOther ??
                            item?.dataFrom?.initialValueFromOther
                        ) ??
                        ""
                      const selected_valuex = valuex
                        ? renderDropdownItem(item, {
                            data: { data: valuex }
                          })
                        : []
                      if (item?.isCreatable) {
                        InputFinal = (
                          <CreatableSelect
                            key={JSON.stringify(
                              current_tempAsyncOptValues?.find(
                                (c) => c.value === valuex
                              )
                            )}
                            isDisabled={
                              disabledInput ||
                              // tempAsyncOptCreateLoading[fieldName] ||
                              (item?.fieldName === "parent_id" &&
                                defaultFormValues["parent_id"] &&
                                !defaultFormValues["id"])
                            }
                            id={`input_${fieldName}`}
                            invalid={errors[fieldName] && true}
                            isClearable={
                              !item?.rules?.required && !item?.isMultiSelect
                            }
                            className={classnames("react-select", {
                              "is-invalid": errors[fieldName],
                              "select-multi-full-width":
                                item?.isMultiSelectFullWidth
                            })}
                            theme={selectThemeColors}
                            {...componentsInput}
                            isMulti={item?.isMultiSelect}
                            placeholder={
                              item.placeholder
                                ? t(item.placeholder)
                                : onlyInputMode
                                ? t("Filter By ") + item?.columnName ??
                                  item?.fieldLabel ??
                                  item?.fieldName
                                : `${t("Find")} ${t(fieldLabel)}`
                            }
                            noOptionsMessage={() => t("No Options Found")}
                            loadingMessage={() => t("Loading...")}
                            classNamePrefix="select"
                            ref={field.ref}
                            onChange={(val) => {
                              const finalVal = !item?.isMultiSelect
                                ? val?.value
                                : val.map(({ id }) => id)
                              field.onChange(finalVal)
                              if (
                                item.fieldName.includes("Id_") &&
                                Object.keys(getValues()).includes(
                                  item.fieldName.replace("Id_", "")
                                )
                              ) {
                                setValue(item.fieldName.replace("Id_", ""), val)
                              }
                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              afterChangeEvent(val, item)
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                            onBlur={field.onBlur}
                            onFocus={() => {
                              return item.dataFrom?.autoCompleteLoadOnFocus &&
                                !current_tempAsyncOptValues
                                ? loadDropdownFromApiAutoComplete(
                                    "",
                                    item,
                                    false,
                                    false,
                                    false
                                  )
                                : null
                            }}
                            onCreateOption={async (val) => {
                              await handleCreateOptions(val, item)
                            }}
                            allowCreateWhileLoading={false}
                            createOptionPosition="first"
                            isLoading={tempAsyncOptLoading[fieldName] ?? false}
                            onInputChange={(v, e) => {
                              if (e.action === "input-change") {
                                handleAutoCompleteChange(
                                  v,
                                  item,
                                  current_tempAsyncOptValues
                                )
                              }
                            }}
                            options={current_tempAsyncOptValues}
                            defaultValue={
                              !item?.isMultiSelect
                                ? current_tempAsyncOptValues?.find(
                                    (c) => c.value === valuex
                                  )
                                : selected_valuex
                            }
                            value={current_tempAsyncOptValues?.find(
                              (c) => c.value === value
                            )}
                          />
                        )
                      } else {
                        InputFinal = (
                          <Select
                            key={JSON.stringify(
                              current_tempAsyncOptValues?.find(
                                (c) => c.value === valuex
                              )
                            )}
                            {...customStyles}
                            isDisabled={
                              disabledInput ||
                              // tempAsyncOptLoading[fieldName] ||
                              (item?.fieldName === "parent_id" &&
                                defaultFormValues["parent_id"] &&
                                !defaultFormValues["id"])
                            }
                            id={`input_${fieldName}`}
                            invalid={errors[fieldName] && true}
                            isClearable={
                              !item?.rules?.required && !item?.isMultiSelect
                            }
                            className={classnames("react-select", {
                              "is-invalid": errors[fieldName],
                              "select-multi-full-width":
                                item?.isMultiSelectFullWidth
                            })}
                            theme={selectThemeColors}
                            {...componentsInput}
                            isMulti={item?.isMultiSelect}
                            placeholder={
                              item.placeholder
                                ? t(item.placeholder)
                                : onlyInputMode
                                ? t("Filter By ") + item?.columnName ??
                                  item?.fieldLabel ??
                                  item?.fieldName
                                : `${t("Find")} ${t(fieldLabel)}`
                            }
                            noOptionsMessage={() => t("No Options Found")}
                            loadingMessage={() => t("Loading...")}
                            classNamePrefix="select"
                            ref={field.ref}
                            onChange={(val) => {
                              const finalVal = !item?.isMultiSelect
                                ? val?.value
                                : val.map(({ id }) => id)
                              field.onChange(finalVal)
                              if (
                                item.fieldName.includes("Id_") &&
                                Object.keys(getValues()).includes(
                                  item.fieldName.replace("Id_", "")
                                )
                              ) {
                                setValue(item.fieldName.replace("Id_", ""), val)
                              }

                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }

                              afterChangeEvent(val, item)
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                            onBlur={field.onBlur}
                            onFocus={() => {
                              // console.log("onFocus")
                              return item.dataFrom?.autoCompleteLoadOnFocus &&
                                !current_tempAsyncOptValues
                                ? loadDropdownFromApiAutoComplete(
                                    "",
                                    item,
                                    false,
                                    false,
                                    false
                                  )
                                : null
                            }}
                            isLoading={tempAsyncOptLoading[fieldName] ?? false}
                            // defaultOptions
                            // cacheOptions

                            onInputChange={(v, e) => {
                              if (e.action === "input-change") {
                                handleAutoCompleteChange(v, item)
                              }
                            }}
                            options={current_tempAsyncOptValues}
                            defaultValue={
                              !item?.isMultiSelect
                                ? current_tempAsyncOptValues?.find(
                                    (c) => c.value === valuex
                                  )
                                : current_tempAsyncOptValues
                            }
                            value={current_tempAsyncOptValues?.find(
                              (c) => c.value === value
                            )}
                          />
                        )
                      }
                    } else {
                      // InputFinal = (
                      //   <InputsDropdown
                      //     field
                      //     storeName
                      //     hiddenInput
                      //     item
                      //     errors
                      //     filterMode
                      //     store
                      //     afterChangeEvent
                      //     pageType
                      //   />
                      // )
                      // console.log(
                      //   store.pageData[storeName]?.selectedData,
                      //   item?.initialValueFromOther,
                      //   getDepthValue(
                      //     store.pageData[storeName]?.selectedData,
                      //     item?.initialValueFromOther
                      //   )
                      // )
                      if (
                        !item?.dropdownAsTable &&
                        !item?.dropdownAsTreeTable
                      ) {
                        InputFinal = (
                          <Select
                            isDisabled={
                              disabledInput ||
                              tempAsyncOptCreateLoading[fieldName] ||
                              (item?.fieldName === "parent_id" &&
                                defaultFormValues["parent_id"] &&
                                !defaultFormValues["id"] &&
                                !filterMode)
                            }
                            isMulti={item?.isMultiSelect}
                            id={`input_${fieldName}`}
                            invalid={errors[fieldName] && true}
                            isClearable={
                              !item?.rules?.required && !item?.isMultiSelect
                            }
                            {...customStyles}
                            className={classnames("react-select", {
                              "is-invalid": errors[fieldName],
                              "select-multi-full-width":
                                item?.isMultiSelectFullWidth
                            })}
                            // autoFocus={!!defaultFormValues[fieldName]}
                            key={JSON.stringify(
                              current_tempAsyncOptValues?.find(
                                (c) => c.value === defaultFormValues[fieldName]
                              )
                            )}
                            // closeMenuOnSelect={false}
                            {...componentsInput}
                            placeholder={
                              item.placeholder
                                ? t(item.placeholder)
                                : onlyInputMode
                                ? t("Filter By ") + item?.columnName ??
                                  item?.fieldLabel ??
                                  item?.fieldName
                                : `${t("Select")} ${t(fieldLabel)}`
                            }
                            noOptionsMessage={() => t("No Options Found")}
                            loadingMessage={() => t("Loading...")}
                            classNamePrefix="select"
                            ref={field.ref}
                            onChange={(val) => {
                              const finalVal = !item?.isMultiSelect
                                ? val?.value
                                : val.map(({ id }) => id)

                              field.onChange(finalVal)
                              if (
                                item.fieldName.includes("Id_") &&
                                Object.keys(getValues()).includes(
                                  item.fieldName.replace("Id_", "")
                                )
                              ) {
                                setValue(item.fieldName.replace("Id_", ""), val)
                              }

                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              afterChangeEvent(val, item)
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                            onBlur={field.onBlur}
                            onFocus={(e) => {
                              e.preventDefault()
                              return loadDropdownFromApi(e, item)
                            }}
                            // cacheOptions
                            // cache={false}
                            // openOnFocus={false}
                            // openOnClick={false}
                            isLoading={tempAsyncOptLoading[fieldName] ?? false}
                            options={current_tempAsyncOptValues}
                            defaultValue={
                              Object.keys(defaultFormValues).length > 0
                                ? !item?.isMultiSelect
                                  ? current_tempAsyncOptValues?.find(
                                      (c) =>
                                        c.value ===
                                          defaultFormValues?.[fieldName] ??
                                        getDepthValue(
                                          store.pageData[storeName]
                                            ?.selectedData,
                                          item?.initialValueFromOther ??
                                            item?.dataFrom
                                              ?.initialValueFromOther
                                        )
                                    )
                                  : current_tempAsyncOptValues
                                : item?.initialValue
                            }
                            value={current_tempAsyncOptValues?.find(
                              (c) => c.value === value
                            )}
                          />
                        )
                      } else {
                        InputFinal = (
                          <Fragment>
                            <InputGroup
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={async (e) => {
                                // console.log(e)
                                e.preventDefault()
                                if (
                                  item?.dataFrom?.tableOpt?.crudOpt
                                    ?.serverSideGrid
                                ) {
                                  if (dropdownAsTableModal[fieldName]) {
                                    setDropdownAsTableModal({
                                      ...dropdownAsTableModal,
                                      [fieldName]: {
                                        state:
                                          !dropdownAsTableModal[fieldName]
                                            .state,
                                        selected:
                                          dropdownAsTableModal[fieldName]
                                            .selected
                                      }
                                    })
                                  } else {
                                    dropdownAsTableModal[fieldName] = {
                                      state: true,
                                      selected: {}
                                    }
                                  }
                                } else {
                                  await loadDropdownFromApi(
                                    value ?? defaultFormValues[fieldName],
                                    item
                                  ).then(() => {
                                    dispatch(
                                      setDataList({
                                        storeName: `${item?.fieldName}_dropdowntable`,
                                        data: tempAsyncOptValues[fieldName]
                                      })
                                    ).then((resp) => {
                                      if (dropdownAsTableModal[fieldName]) {
                                        setDropdownAsTableModal({
                                          ...dropdownAsTableModal,
                                          [fieldName]: {
                                            state:
                                              !dropdownAsTableModal[fieldName]
                                                .state,
                                            selected:
                                              dropdownAsTableModal[fieldName]
                                                .selected
                                          }
                                        })
                                      } else {
                                        dropdownAsTableModal[fieldName] = {
                                          state: true,
                                          selected: {}
                                        }
                                      }

                                      // setDropdownAsTableModal({
                                      //   ...dropdownAsTableModal,
                                      //   [fieldName]:
                                      //     !dropdownAsTableModal[fieldName]
                                      // })
                                    })
                                  })
                                }
                              }}
                              className={classnames("", {
                                "is-invalid": errors[fieldName]
                              })}
                            >
                              <Select
                                isDisabled={true}
                                id={`input_${fieldName}`}
                                invalid={errors[fieldName] && true}
                                isClearable={
                                  !item?.rules?.required && !item?.isMultiSelect
                                }
                                {...customStyles}
                                className={classnames(
                                  "react-select react-select-as-table",
                                  {
                                    "is-invalid": errors[fieldName],
                                    "select-multi-full-width":
                                      item?.isMultiSelectFullWidth
                                  }
                                )}
                                key={JSON.stringify(
                                  current_tempAsyncOptValues?.find(
                                    (c) =>
                                      c.value === defaultFormValues[fieldName]
                                  )
                                )}
                                {...componentsInput}
                                placeholder={
                                  item.placeholder
                                    ? t(item.placeholder)
                                    : onlyInputMode
                                    ? t("Filter By ") + item?.columnName ??
                                      item?.fieldLabel ??
                                      item?.fieldName
                                    : `${t("Select")} ${t(fieldLabel)}`
                                }
                                noOptionsMessage={() => t("No Options Found")}
                                loadingMessage={() => t("Loading...")}
                                classNamePrefix="select"
                                ref={field.ref}
                                // onBlur={field.onBlur}
                                isLoading={
                                  tempAsyncOptLoading[fieldName] ?? false
                                }
                                options={current_tempAsyncOptValues}
                                defaultValue={
                                  Object.keys(defaultFormValues).length > 0
                                    ? !item?.isMultiSelect
                                      ? current_tempAsyncOptValues?.find(
                                          (c) =>
                                            c.value ===
                                              defaultFormValues?.[fieldName] ??
                                            getDepthValue(
                                              store.pageData[storeName]
                                                ?.selectedData,
                                              item?.initialValueFromOther
                                            )
                                        )
                                      : current_tempAsyncOptValues
                                    : item?.initialValue
                                }
                                value={
                                  Object.keys(
                                    dropdownAsTableModal[fieldName]?.selected ??
                                      {}
                                  )?.length > 0
                                    ? dropdownAsTableModal[fieldName]?.selected
                                    : current_tempAsyncOptValues?.find(
                                        (c) => c.value === value
                                      )
                                }
                              />
                              <InputGroupText>
                                {renderIcon("material:search", "", 16)}
                              </InputGroupText>

                              {dropdownAsTableModal[fieldName]?.state && (
                                <Modal
                                  scrollable
                                  className={`modal-${
                                    item?.dataFrom?.tableOpt
                                      ?.detailPageModalSize ?? "lg"
                                  }`}
                                  isOpen={
                                    dropdownAsTableModal[fieldName]?.state
                                  }
                                  toggle={() =>
                                    // setDropdownAsTableModal((prevState) => ({
                                    //   ...prevState,
                                    //   [fieldName]: !prevState[fieldName]
                                    // }))
                                    setDropdownAsTableModal({
                                      ...dropdownAsTableModal,
                                      [fieldName]: {
                                        state:
                                          !dropdownAsTableModal[fieldName]
                                            .state,
                                        selected:
                                          dropdownAsTableModal[fieldName]
                                            .selected
                                      }
                                    })
                                  }
                                >
                                  <ModalHeader
                                    toggle={
                                      () =>
                                        setDropdownAsTableModal({
                                          ...dropdownAsTableModal,
                                          [fieldName]: {
                                            state:
                                              !dropdownAsTableModal[fieldName]
                                                .state,
                                            selected:
                                              dropdownAsTableModal[fieldName]
                                                .selected
                                          }
                                        })
                                      // setDropdownAsTableModal((prevState) => ({
                                      //   ...prevState,
                                      //   [fieldName]: !prevState[fieldName]
                                      // }))
                                    }
                                  >
                                    {t("Select")}{" "}
                                    {t(
                                      item?.dataFrom?.tableOpt?.pageTitle ??
                                        item?.fieldLabel ??
                                        ""
                                    )}
                                  </ModalHeader>
                                  <ModalBody>
                                    {item?.dropdownAsTreeTable ? (
                                      <InputsDropdownTreeTable
                                        item={item}
                                        endPointName={
                                          item?.dataFrom?.tableOpt?.crudOpt
                                            ?.serverSideGrid
                                            ? getParamsApiUrlAttr(
                                                item?.dataFrom?.api,
                                                item
                                              )
                                            : null
                                        }
                                        data={current_tempAsyncOptValues}
                                        onChange={async (selectedValTable) => {
                                          let val = selectedValTable

                                          let newValues =
                                            await renderDropdownItem(item, {
                                              data: { data: [val] }
                                            })
                                          let newValue = newValues?.[0] ?? {}
                                          const finalVal =
                                            newValue?.[value ?? "id"] ??
                                            getDepthValue(
                                              newValue,
                                              value ?? "id"
                                            )

                                          if (item?.closeOnSelect) {
                                            field.onChange(finalVal)
                                            setValue(fieldName, finalVal)
                                            if (filterMode) {
                                              beforeHandleFiltersChangeToParent()
                                            }
                                            afterChangeEvent(newValue, item)
                                            onChangeAll()
                                            onChangeAll2(item?.fieldName)
                                            setDropdownAsTableModal({
                                              ...dropdownAsTableModal,
                                              [fieldName]: {
                                                state:
                                                  !dropdownAsTableModal[
                                                    fieldName
                                                  ].state,
                                                selected: newValue
                                              }
                                            })
                                          } else {
                                            setDropdownAsTableModalCurSelected({
                                              state:
                                                !dropdownAsTableModal[fieldName]
                                                  .state,
                                              selected: newValue
                                            })
                                          }
                                        }}
                                      ></InputsDropdownTreeTable>
                                    ) : (
                                      <InputsDropdownTable
                                        item={item}
                                        endPointName={
                                          item?.dataFrom?.tableOpt?.crudOpt
                                            ?.serverSideGrid
                                            ? getParamsApiUrlAttr(
                                                item?.dataFrom?.api,
                                                item
                                              )
                                            : null
                                        }
                                        data={current_tempAsyncOptValues}
                                        onChange={async (selectedValTable) => {
                                          let val = selectedValTable

                                          let newValues =
                                            await renderDropdownItem(item, {
                                              data: { data: [val] }
                                            })
                                          let newValue = newValues?.[0] ?? {}
                                          const finalVal =
                                            newValue?.[value ?? "id"] ??
                                            getDepthValue(
                                              newValue,
                                              value ?? "id"
                                            )

                                          if (item?.closeOnSelect) {
                                            field.onChange(finalVal)
                                            setValue(fieldName, finalVal)
                                            if (filterMode) {
                                              beforeHandleFiltersChangeToParent()
                                            }
                                            afterChangeEvent(newValue, item)
                                            onChangeAll()
                                            onChangeAll2(item?.fieldName)
                                            setDropdownAsTableModal({
                                              ...dropdownAsTableModal,
                                              [fieldName]: {
                                                state:
                                                  !dropdownAsTableModal[
                                                    fieldName
                                                  ].state,
                                                selected: newValue
                                              }
                                            })
                                          } else {
                                            setDropdownAsTableModalCurSelected({
                                              state:
                                                !dropdownAsTableModal[fieldName]
                                                  .state,
                                              selected: newValue
                                            })
                                          }
                                        }}
                                      ></InputsDropdownTable>
                                    )}
                                  </ModalBody>
                                  {!item?.closeOnSelect && (
                                    <ModalFooter>
                                      <Button
                                        color="secondary"
                                        outline
                                        onClick={() => {
                                          setDropdownAsTableModal({
                                            ...dropdownAsTableModal,
                                            [fieldName]: {
                                              state:
                                                !dropdownAsTableModal[fieldName]
                                                  .state,
                                              selected:
                                                dropdownAsTableModal[fieldName]
                                                  .selected
                                            }
                                          })
                                          // setDropdownAsTableModal(
                                          //   (prevState) => ({
                                          //     ...prevState,
                                          //     [fieldName]: !prevState[fieldName]
                                          //   })
                                          // )
                                        }}
                                      >
                                        {t("Cancel")}
                                      </Button>
                                      <Button
                                        disabled={
                                          !dropdownAsTableModalCurSelected
                                        }
                                        color="primary"
                                        className="me-1"
                                        onClick={async () => {
                                          let val =
                                            dropdownAsTableModalCurSelected?.selected

                                          let newValues =
                                            await renderDropdownItem(item, {
                                              data: { data: [val] }
                                            })
                                          let newValue = newValues?.[0] ?? {}
                                          const finalVal =
                                            newValue?.[value ?? "id"] ??
                                            getDepthValue(
                                              newValue,
                                              value ?? "id"
                                            )

                                          field.onChange(finalVal)
                                          setValue(fieldName, finalVal)

                                          if (filterMode) {
                                            beforeHandleFiltersChangeToParent()
                                          }
                                          afterChangeEvent(newValue, item)
                                          onChangeAll()
                                          onChangeAll2(item?.fieldName)
                                          setDropdownAsTableModal({
                                            ...dropdownAsTableModal,
                                            [fieldName]:
                                              dropdownAsTableModalCurSelected
                                          })

                                          // setDropdownAsTableModal(
                                          //   (prevState) => ({
                                          //     ...prevState,
                                          //     [fieldName]: !prevState[fieldName]
                                          //   })
                                          // )
                                        }}
                                      >
                                        {t("Accept")}
                                      </Button>
                                    </ModalFooter>
                                  )}
                                </Modal>
                              )}
                            </InputGroup>
                          </Fragment>
                        )
                      }
                    }

                    if (item?.icon) {
                      return (
                        <InputGroup
                          className={classnames("input-group-dropdown", {
                            "is-invalid": errors[fieldName]
                          })}
                        >
                          {item?.icon && (
                            <InputGroupText>
                              {renderIcon(item?.icon, "", 16)}
                            </InputGroupText>
                          )}
                          {InputFinal}
                        </InputGroup>
                      )
                    } else {
                      return InputFinal
                    }
                  }}
                />
              ) : (
                renderDefInputViewToText(
                  fieldName,
                  current_tempAsyncOptValues?.find(
                    (c) => c.value === defaultFormValues[fieldName]
                  )?.label ?? "-"
                )
              )}

              {errors[item.fieldName] ? (
                <FormText color="danger">
                  {t(errors[item.fieldName]?.message)}
                </FormText>
              ) : (
                <FormText className="text-muted">
                  {item?.fieldDesc ? t(item?.fieldDesc) : ""}
                </FormText>
              )}
            </Col>
          )
        } else if (
          item?.dataFrom?.value &&
          Array.isArray(item?.dataFrom?.value)
        ) {
          // console.log(
          //   defaultFormValues,
          //   getDepthValue(
          //     defaultFormValues,
          //     item?.initialValue?.includes(".") && item?.initialValue
          //       ? getDepthValue(defaultFormValues, item?.initialValue)
          //       : "NOT"
          //   )
          // )

          return (
            <Col
              key={`col_${fieldName}_${index}`}
              id={`col_${fieldName}`}
              className={classnames("mb-1", hideOnPageClassName)}
              {...colSizeFix}
            >
              {labelInput}
              {editOrViewState ? (
                <Controller
                  control={control}
                  rules={item.rules}
                  name={fieldName}
                  render={({ field, value }) => {
                    let componentsInput = renderAvatarImgIcon(item)
                    let InputFinal = (
                      <Select
                        {...field}
                        {...componentsInput}
                        defaultValue={item?.dataFrom?.value.find(
                          (c) =>
                            c.value ===
                            (defaultFormValues?.[fieldName] ??
                              getDepthValue(
                                defaultFormValues,
                                item?.initialValue ??
                                  item?.initialValueFromOther
                              ) ??
                              getDepthValue(
                                store.pageData?.[storeName]?.selectedData,
                                item?.initialValue ??
                                  item?.initialValueFromOther
                              ) ??
                              item?.initialValue)
                        )}
                        value={item?.dataFrom?.value.find(
                          (c) => c.value === value
                        )}
                        isDisabled={
                          disabledInput ||
                          tempAsyncOptCreateLoading[fieldName] ||
                          (item?.fieldName === "parent_id" &&
                            defaultFormValues["parent_id"] &&
                            !defaultFormValues["id"])
                        }
                        id={`input_${fieldName}`}
                        invalid={errors[fieldName] && true}
                        options={
                          !item.dataFrom?.labelTranslation
                            ? item.dataFrom.value
                            : item.dataFrom.value.map((item) => ({
                                ...item,
                                label: t(item?.label)
                              }))
                        }
                        className={classnames("react-select", {
                          "is-invalid": errors[fieldName],
                          "flex-auto": item?.icon
                          // "w-94": item?.icon
                        })}
                        placeholder={
                          item.placeholder
                            ? t(item.placeholder)
                            : onlyInputMode
                            ? t("Filter By ") + item?.columnName ??
                              item?.fieldLabel ??
                              item?.fieldName
                            : `${t("Select")} ${t(fieldLabel)}`
                        }
                        noOptionsMessage={() => t("No Options Found")}
                        loadingMessage={() => t("Loading...")}
                        // styles={{ flex: "auto" }}
                        classNamePrefix="select"
                        ref={field.ref}
                        onChange={(val) => {
                          // console.log(val?.value)
                          field.onChange(val?.value)
                          if (filterMode) {
                            beforeHandleFiltersChangeToParent()
                          }
                          afterChangeEvent(val, item)
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        }}
                        onBlur={field.onBlur}
                        isClearable={!item?.rules?.required}
                        // theme={selectThemeColors}
                        // onClick={(event) => event.stopPropagation()}
                        // onFocus={(event) => event.stopPropagation()}
                        // onMenuOpen={(e) => {
                        //   if (e && e.nativeEvent) {
                        //     e.nativeEvent.stopPropagation()
                        //   }
                        // }}
                      />
                    )

                    if (item?.icon) {
                      return (
                        <InputGroup
                          className={classnames("input-group-dropdown", {
                            "is-invalid": errors[fieldName]
                          })}
                        >
                          {item?.icon && (
                            <InputGroupText>
                              {renderIcon(item?.icon, "", 16)}
                            </InputGroupText>
                          )}
                          {InputFinal}
                        </InputGroup>
                      )
                    } else {
                      return InputFinal
                    }
                  }}
                />
              ) : (
                renderDefInputViewToText(
                  fieldName,
                  item?.dataFrom?.value.find(
                    (c) => c.value === defaultFormValues[fieldName]
                  )?.label
                )
              )}
              {errors[item.fieldName] ? (
                <FormText color="danger">
                  {t(errors[item.fieldName]?.message)}
                </FormText>
              ) : (
                <FormText className="text-muted">
                  {item?.fieldDesc ? t(item?.fieldDesc) : ""}
                </FormText>
              )}
            </Col>
          )
        }
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - TABLE
      else if (item["type"] === "table") {
        if (!item?.dataFrom) {
          return (
            <InputsTable
              item={item}
              data={
                defaultFormValues?.[fieldName] ??
                getDepthValue(
                  store.pageData[storeName]?.selectedData,
                  fieldName
                )
              }
            />
          )
        }
      }

      ////////////INPUT - SWITCH
      else if (item["type"] === "switch") {
        return (
          <Col
            key={`col_${fieldName}_${index}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) => (
                <div className={`demo-inline-spacing ${item?.className ?? ""}`}>
                  <div
                    key={`div_switch_${fieldName}`}
                    style={{ marginTop: ".5rem" }}
                    className={classnames(
                      `form-check form-switch ${
                        item?.color
                          ? `form-check-${item.color}`
                          : `form-check-primary`
                      }`
                    )}
                  >
                    <Input
                      {...field}
                      type="switch"
                      id={`switch_${fieldName}`}
                      name={fieldName}
                      defaultChecked={
                        ((defaultFormValues[fieldName] !== ""
                          ? defaultFormValues[fieldName]
                          : null) ??
                          getDepthValue(
                            store.pageData[storeName]?.selectedData,
                            item?.initialValueFromOther ?? fieldName
                          ) ??
                          item?.initialValue) === item?.checkedValue ||
                        item?.defaultValue
                      }
                      disabled={disabledInput}
                      ref={field.ref}
                      onClick={(e) => {
                        const inputValue = e.target.checked
                          ? item?.checkedValue
                          : item?.uncheckedValue
                        afterChangeEvent(inputValue ?? e.target.checked, item)
                        field.onChange(inputValue ?? e.target.checked)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onChange={(e) => {
                        // console.log(e.target.checked)
                        const inputValue = e.target.checked
                          ? item?.checkedValue
                          : item?.uncheckedValue
                        afterChangeEvent(inputValue ?? e.target.checked, item)
                        field.onChange(inputValue ?? e.target.checked)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor={`switch_${fieldName}`}
                    >
                      <span className="switch-icon-left">
                        {item?.checkedIcon ? (
                          renderIcon(item?.checkedIcon, "", 14)
                        ) : (
                          <Check size={14} />
                        )}
                      </span>
                      <span className="switch-icon-right">
                        {item?.uncheckedIcon ? (
                          renderIcon(item?.uncheckedIcon, "", 14)
                        ) : (
                          <X size={14} />
                        )}
                      </span>
                      {item?.inLineLabel ? t(item?.fieldLabel) : ``}
                    </Label>
                  </div>
                </div>
              )}
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - CHECKBOX
      else if (item["type"] === "checkbox") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) => (
                <div className={`demo-inline-spacing ${item?.className ?? ""}`}>
                  <div
                    key={`div_checkbox_${fieldName}`}
                    style={{ marginTop: ".7rem" }}
                    className={classnames(
                      `form-check ${
                        item?.inLineLabel ? `form-check-inline` : ``
                      } ${
                        item?.color
                          ? `form-check-${item.color}`
                          : `form-check-primary`
                      }`
                    )}
                  >
                    <Input
                      {...field}
                      type="checkbox"
                      id={`checkbox_${fieldName}`}
                      name={fieldName}
                      defaultChecked={
                        (defaultFormValues[fieldName] ??
                          item?.initialValue ??
                          getDepthValue(
                            store.pageData[storeName]?.selectedData,
                            item?.initialValueFromOther ?? fieldName
                          )) === item?.checkedValue || item?.defaultValue
                      }
                      disabled={disabledInput}
                      ref={field.ref}
                      onClick={(e) => {
                        const inputValue = e.target.checked
                          ? item?.checkedValue
                          : item?.uncheckedValue
                        afterChangeEvent(inputValue ?? e.target.checked, item)
                        field.onChange(inputValue ?? e.target.checked)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onChange={(e) => {
                        // console.log(e.target.checked)
                        const inputValue = e.target.checked
                          ? item?.checkedValue
                          : item?.uncheckedValue
                        afterChangeEvent(inputValue ?? e.target.checked, item)
                        field.onChange(inputValue ?? e.target.checked)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                    />
                    <Label
                      className="form-check-label"
                      htmlFor={`checkbox_${fieldName}`}
                    >
                      {item?.inLineLabel ? t(item?.fieldLabel) : ``}
                    </Label>
                  </div>
                </div>
              )}
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - ICON
      else if (item["type"] === "icon") {
        const btnInputIcon = (showInput = false) => (
          <Button.Ripple
            className="btn-icon"
            outline
            block={!showInput}
            color="secondary"
            onClick={(e) => toggleCanvasBottom(fieldName)}
          >
            {pageType !== "add"
              ? renderIcon(
                  getValues()[fieldName] ??
                    item?.defaultValue ??
                    defaultFormValues?.[fieldName] ??
                    item?.initialValue ??
                    defaultFormValues?.[item?.initialValueFromOther] ??
                    "fw-bold",
                  "",
                  16
                )
              : renderIcon(
                  getValues()[fieldName] ?? "material:highlight_alt",
                  "",
                  16
                )}
          </Button.Ripple>
        )
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) =>
                !item?.manualInputMode ? (
                  <div
                  // style={{
                  //   width: "max-content"
                  // }}
                  >
                    {btnInputIcon()}
                  </div>
                ) : (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <Input
                      {...field}
                      id={fieldName}
                      onChange={(event) => {
                        const val = event.target.value
                        field.onChange(val)
                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      placeholder={
                        item.placeholder
                          ? t(item.placeholder)
                          : onlyInputMode
                          ? t("Filter By ") + item?.columnName ??
                            item?.fieldLabel ??
                            item?.fieldName
                          : ""
                      }
                      ref={field.ref}
                      onBlur={field.onBlur}
                      disabled={disabledInput}
                      key={`dynInput_${fieldName}`}
                      invalid={errors[fieldName] && true}
                    />
                    {btnInputIcon(true)}
                  </InputGroup>
                )
              }
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}

            {
              <Offcanvas
                direction={canvasPlacement}
                isOpen={canvasOpen}
                toggle={toggleCanvasStart}
                style={{
                  height: "80vh"
                }}
              >
                <OffcanvasHeader
                  className="mt-1 me-1"
                  toggle={toggleCanvasStart}
                >
                  {t("Select")} {t("Icon")}
                  <Button.Ripple
                    className="ms-2 me-2"
                    color="primary"
                    outline={iconCategory !== "feather"}
                    onClick={(event) => {
                      setIconCategory("feather")
                    }}
                  >
                    Feather Icon
                  </Button.Ripple>
                  <Button.Ripple
                    color="primary"
                    outline={iconCategory !== "material"}
                    onClick={(event) => {
                      setIconCategory("material")
                    }}
                  >
                    Material Icon
                  </Button.Ripple>
                </OffcanvasHeader>
                <OffcanvasBody
                  style={{
                    padding: "0.8rem"
                  }}
                  className={classnames({
                    "my-auto mx-0 flex-grow-0":
                      canvasPlacement === "start" || canvasPlacement === "end"
                  })}
                >
                  {iconCategory === "feather" ? (
                    <IconsFeather
                      setIcon={setIconSelected}
                      setCanvasOpen={setCanvasOpen}
                    />
                  ) : (
                    <IconsMaterial
                      setIcon={setIconSelected}
                      setCanvasOpen={setCanvasOpen}
                    />
                  )}
                </OffcanvasBody>
              </Offcanvas>
            }
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - RADIO
      else if (item["type"] === "radio") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) => (
                <div
                  style={{
                    height: "38px"
                  }}
                  className={`demo-inline-spacing ${item?.className ?? ""}`}
                >
                  {Array.isArray(item?.dataFrom?.value)
                    ? item.dataFrom.value.map((value) => (
                        <div
                          key={`div_radio_${value.value}`}
                          // style={{ marginTop: ".5rem" }}
                          style={{ marginTop: 0 }}
                          className={classnames(
                            `form-check ${
                              value.color ? `form-check-${value.color}` : null
                            }`
                          )}
                        >
                          <Input
                            {...field}
                            type="radio"
                            id={`radio_${value.value}`}
                            name={fieldName}
                            defaultChecked={
                              (item?.defaultValue ??
                                defaultFormValues?.[fieldName] ??
                                getDepthValue(
                                  store.pageData[storeName]?.selectedData,
                                  item?.initialValueFromOther ?? fieldName
                                ) ??
                                item?.initialValue) === value.value ||
                              value.selected
                            }
                            disabled={
                              (filterMode &&
                                store.pageData[storeName]?.isLoading) ||
                              hiddenInput[fieldName] ||
                              pageType === "view" ||
                              item?.isDisabled
                            }
                            ref={field.ref}
                            onClick={() => {
                              // console.log(value.value)
                              afterChangeEvent(value.value, item)
                              field.onChange(value.value)
                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                            onChange={() => {
                              // console.log(value.value)
                              afterChangeEvent(value.value, item)
                              field.onChange(value.value)
                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                          />
                          <Label
                            className="form-check-label me-1"
                            for={`radio_${value.label}`}
                          >
                            {t(value.label)}
                          </Label>
                        </div>
                      ))
                    : null}
                </div>
              )}
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - CHECKBOX-MULTISELECT
      else if (item["type"] === "checkbox-multiselect") {
        const valueInputDef = ensureArray(
          item?.defaultValue ??
            defaultFormValues?.[fieldName] ??
            getDepthValue(
              store.pageData[storeName]?.selectedData,
              item?.initialValueFromOther ?? fieldName
            ) ??
            item?.initialValue ??
            []
        )
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) => (
                <div
                  style={{
                    height: "38px"
                  }}
                  className={`demo-inline-spacing ${item?.className ?? ""}`}
                >
                  {!tempAsyncOptLoading[fieldName] ? (
                    tempAsyncOptValues?.[fieldName]?.map((value) => (
                      <div
                        key={`div_radio_${value.value}`}
                        // style={{ marginTop: ".5rem" }}
                        style={{ marginTop: 0 }}
                        className={classnames(
                          `form-check ${
                            value.color ? `form-check-${value.color}` : null
                          }`
                        )}
                      >
                        <Input
                          {...field}
                          type="checkbox"
                          id={`checkbox_multi_${value.value}`}
                          name={fieldName}
                          defaultChecked={valueInputDef.includes(value.value)}
                          disabled={
                            (filterMode &&
                              store.pageData[storeName]?.isLoading) ||
                            hiddenInput[fieldName] ||
                            pageType === "view" ||
                            item?.isDisabled
                          }
                          ref={field.ref}
                          // onClick={() => {
                          //   // console.log(value.value)
                          //   afterChangeEvent(value.value, item)
                          //   field.onChange(value.value)
                          //   if (filterMode) {
                          //     beforeHandleFiltersChangeToParent()
                          //   }
                          //   onChangeAll()
                          //   onChangeAll2(item?.fieldName)
                          // }}
                          onChange={(event) => {
                            const valueInput = value?.value
                            let selectedValuesCheckboxCopy = {
                              ...selectedValuesCheckbox
                            }
                            if (selectedValuesCheckboxCopy[fieldName]) {
                              if (
                                selectedValuesCheckboxCopy[fieldName]?.includes(
                                  valueInput
                                )
                              ) {
                                let index =
                                  selectedValuesCheckboxCopy[fieldName].indexOf(
                                    valueInput
                                  )
                                if (index !== -1) {
                                  selectedValuesCheckboxCopy[fieldName].splice(
                                    index,
                                    1
                                  )
                                }
                              } else {
                                selectedValuesCheckboxCopy[fieldName].push(
                                  valueInput
                                )
                              }
                            } else {
                              selectedValuesCheckboxCopy[fieldName] = [
                                valueInput
                              ]
                            }
                            setSelectedValuesCheckbox(
                              selectedValuesCheckboxCopy
                            )
                            const valueInputAll =
                              selectedValuesCheckbox[fieldName]

                            afterChangeEvent(valueInputAll, item)
                            field.onChange(valueInputAll)
                            if (filterMode) {
                              beforeHandleFiltersChangeToParent()
                            }
                            onChangeAll()
                            onChangeAll2(item?.fieldName)
                          }}
                        />
                        <Label
                          className="form-check-label me-1"
                          for={`radio_${value.label}`}
                        >
                          {t(value.label)}
                        </Label>
                      </div>
                    ))
                  ) : (
                    <Spinner size="sm"></Spinner>
                  )}
                </div>
              )}
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////BUTTON
      else if (item["type"] === "button") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            <Label className="" for={`input_${fieldName}`}></Label>
            <Button.Ripple
              block
              className={`${item?.round ? "round" : ""}`}
              outline={item?.outline ?? false}
              color={item?.color ? item?.color : `primary`}
              onClick={(e) => {
                e.preventDefault()
                let clickLinkUrl = item?.clickLinkUrl
                if (clickLinkUrl) {
                  const staticUrl = clickLinkUrl?.static
                  if (staticUrl) {
                    if (
                      staticUrl.startsWith("http://") ||
                      staticUrl.startsWith("https://") ||
                      clickLinkUrl?.externalLink
                    ) {
                      if (clickLinkUrl?.externalLink) {
                        const currentUrl = window.location.href
                        const currentPath = window.location.pathname
                        const currentDomain = new URL(currentUrl).origin
                        window.open(
                          currentDomain + staticUrl,
                          "_blank",
                          "noreferrer"
                        )
                      } else {
                        window.open(staticUrl, "_blank", "noreferrer")
                      }
                    } else {
                      navigate(staticUrl)
                    }
                  }
                }
              }}
            >
              {item?.icon ? (
                <Fragment>
                  {/* {renderIcon(item.icon, "", 16)} */}
                  {(item?.iconPos === "left" || !item.iconPos) &&
                    renderIcon(
                      item?.icon,
                      `${item?.iconClass ?? ""} me-50`,
                      item?.iconSize ?? 16
                    )}
                  <span className="align-middle">
                    {t(fieldLabel ?? fieldName ?? "")}
                  </span>
                  {(item?.iconPos === "right" || !item.iconPos) &&
                    renderIcon(
                      item?.icon,
                      `${item?.iconClass ?? ""} ms-50`,
                      item?.iconSize ?? 16
                    )}
                </Fragment>
              ) : (
                t(fieldLabel ?? fieldName ?? "")
              )}
            </Button.Ripple>
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - BUTTON-GROUP
      else if (item["type"] === "button-group") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) => (
                <div className={`demo-inline-spacing ${item?.className}`}>
                  <ButtonGroup className="w-100 mt-0">
                    {Array.isArray(item?.dataFrom?.value)
                      ? item.dataFrom.value.map((value) => (
                          <Button
                            key={`buttongroup_${value.value}`}
                            {...field}
                            // className={`${value?.className} ${
                            //   value?.selected && !defaultFormValues[fieldName]
                            //     ? "active"
                            //     : ""
                            // }`}
                            className={`${
                              filterMode ? "saba_filter_button_group" : ""
                            } saba_button_group ${
                              ["light-"].includes(value.color)
                                ? `bg-${value.color}`
                                : ""
                            }`}
                            type="button"
                            id={`buttongroup_${value.value}`}
                            name={fieldName}
                            defaultChecked={
                              defaultFormValues[fieldName] === value.value
                            }
                            // disabled={
                            //   (filterMode &&
                            //     store.pageData[storeName]?.isLoading) ||
                            //   hiddenInput[fieldName] ||
                            //   pageType === "view"
                            // }
                            disabled={disabledInput}
                            ref={field.ref}
                            outline={
                              // defaultFormValues[fieldName] !== value.value ||
                              getValues(fieldName) !== value.value
                            }
                            onClick={(e) => {
                              e.preventDefault()
                              // console.log(value.value)
                              field.onChange(value.value)
                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              afterChangeEvent(value.value, item)
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                            color={
                              // defaultFormValues[fieldName] === value.value ||
                              getValues(fieldName) === value.value
                                ? value.color?.replace("light-", "") ??
                                  "primary"
                                : "secondary"
                            }
                            active={
                              // defaultFormValues[fieldName] === value.value ||
                              getValues(fieldName) === value.value ??
                              value?.selected
                            }
                          >
                            {value?.icon ? (
                              <Fragment>
                                {renderIcon(value?.icon)}
                                <span className="align-middle ms-25">
                                  {t(value.label)}
                                </span>
                              </Fragment>
                            ) : (
                              t(value.label)
                            )}
                          </Button>
                        ))
                      : null}
                  </ButtonGroup>
                </div>
              )}
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - MAP GET LATLNG
      else if (item["type"] === "map-latlng") {
        let mapDefaultOpt = {
          center:
            defaultValueMapFixState[fieldName]?.defaultValueMapFix &&
            defaultValueMapFixState[fieldName]?.defaultValueMapFix !== ""
              ? defaultValueMapFixState[fieldName]?.defaultValueMapFix
              : [-6.9034495, 107.6431575],
          zoom: 15,
          scrollWheelZoom: true
        }
        // console.log(mapDefaultOpt)
        let mapDefaultStyle = {
          height: "200px",
          borderRadius: "7px",
          zIndex: 0
        }
        let mapOpt = item?.mapOtions ?? {}
        let mapStyle = item?.mapStyle ?? {}
        let mapOtherOpt = item?.mapOtherOpt ?? {}

        // console.log(defaultValueMapFixState)

        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              defaultValue={
                item?.defaultValue
                  ? item?.defaultValue
                  : item?.isM2MDefValFromGrid
                  ? store.pageData[storeName]?.data?.map((x) => x[fieldName])
                  : item?.initialValue
                  ? item?.initialValue
                  : store.pageData[storeName]?.selectedData
                  ? store.pageData[storeName]?.selectedData[fieldName]
                  : ""
              }
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) => (
                <Fragment>
                  <MapContainer
                    className="mb-1"
                    key={`map_${fieldName}`}
                    id={`map_${fieldName}`}
                    ref={mapRefPolygon}
                    style={{ ...mapDefaultStyle, ...mapStyle }}
                    {...(mapOtherOpt?.imageOverlay
                      ? {
                          // zoom: 13,
                          center: mapOpt?.center ?? [
                            40.74266576860375, -74.17450905020814
                          ],
                          maxZoom: mapOpt?.maxZoom ?? 17,
                          minZoom: mapOpt?.minZoom ?? 13.3,
                          maxBounds: mapOpt?.maxBounds ?? [
                            [40.712216, -74.22655],
                            [40.773941, -74.12544]
                          ],
                          // dragging: false, // Disable dragging (panning)
                          touchZoom: false, // Disable zooming with touch gestures
                          doubleClickZoom: false, // Optional: Disable zooming with double-click
                          // scrollWheelZoom: false, // Optional: Disable zooming with mouse wheel
                          style: {
                            width: "100%",
                            height: mapStyle?.height ?? "900px",
                            zIndex: 0
                          }
                        }
                      : {
                          ...mapDefaultOpt,
                          mapOpt
                        })}
                  >
                    <TileLayer
                      className={
                        mapOtherOpt?.imageOverlay ? "saba-map-overlay" : ""
                      }
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {mapOtherOpt?.imageOverlay && (
                      <OverlayCoverAll options={mapOtherOpt?.imageOverlay} />
                    )}

                    {defaultValueMapFixState[fieldName]?.defaultValueMapFix &&
                    defaultValueMapFixState[fieldName]?.defaultValueMapFix !==
                      "" ? (
                      <LocationMarker
                        item={item}
                        defValue={
                          defaultValueMapFixState[fieldName]?.defaultValueMapFix
                        }
                        // setValuex={setValue}
                        // afterChangeEventx={afterChangeEvent}
                        setChangedFieldName={setChangedFieldName}
                        changedFieldName={changedFieldName}
                      />
                    ) : (
                      <LocationMarker2 item={item} />
                    )}

                    {mapOpt?.geoSearch !== false &&
                      !mapOtherOpt?.imageOverlay && <LeafletGeoSearch />}

                    {/* {!mapPosition?.[fieldName] ? null : (
                      <Marker position={mapPosition?.[fieldName]}>
                        <Popup>Deskripsi Marker</Popup>
                      </Marker>
                    )} */}
                  </MapContainer>

                  {mapOtherOpt?.viewLatLngInfo &&
                    (editOrViewState ? (
                      <Input
                        {...field}
                        id={fieldName}
                        value={mapPosition[fieldName] ?? ""}
                        {...defaultValueSet}
                        placeholder={
                          item.placeholder ? t(item.placeholder) : ""
                        }
                        ref={field.ref}
                        onBlur={field.onBlur}
                        disabled={true}
                        key={`dynInput_${fieldName}`}
                        invalid={errors[fieldName] && true}
                      />
                    ) : (
                      renderDefInputViewToText(
                        fieldName,
                        defaultFormValues[fieldName]
                      )
                    ))}
                </Fragment>
              )}
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - MAP GET POLYGON
      else if (item["type"] === "map-polygon") {
        let map_draw_opt = item?.map_draw_opt || pageAttr?.crudOpt?.map_draw_opt
        let isPolygon = true
        let defaultValueMap =
          defaultFormValues[fieldName] ??
          getDepthValue(
            store.pageData?.[storeName]?.selectedData,
            item?.initialValueFromOther
          ) ??
          getDepthValue(store.pageData?.[storeName]?.selectedData, fieldName) ??
          item?.defaultValue ??
          item?.initialValue ??
          ""

        let defaultValueMapFix =
          defaultValueMap !== "," ? defaultValueMap : null

        if (pageType !== "add") {
          if (
            defaultValueMap &&
            defaultValueMap !== "," &&
            typeof defaultValueMap === "string" &&
            defaultValueMap.startsWith("[[")
          ) {
            defaultValueMapFix = [JSON.parse(defaultValueMap)]
          } else if (
            typeof defaultValueMap === "string" &&
            !defaultValueMap.startsWith("[[")
          ) {
            isPolygon = false
            const [lat, lon] = defaultValueMap?.split(",")?.map(parseFloat)
            if (!isNaN(lat) && !isNaN(lon)) {
              defaultValueMapFix = [lat, lon]
            }
          }
        }

        // console.log("🚀 ~ defaultValueMapFix:", defaultValueMap)
        // console.log("🚀 ~ defaultValueMapFix:", defaultValueMapFix)
        let mapOpt = item?.mapOtions ?? {}
        let mapStyle = item?.mapStyle ?? {}
        let mapOtherOpt = item?.mapOtherOpt ?? {}
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            <Controller
              defaultValue={defaultValueMap}
              control={control}
              rules={item.rules}
              name={fieldName}
              render={({ field }) => (
                <Fragment>
                  <MapContainer
                    fullscreenControl={true}
                    ref={mapRefPolygon}
                    className="mb-1"
                    key={`map_${fieldName}`}
                    id={`map_${fieldName}`}
                    style={{ ...mapDefaultStyle, ...mapStyle }}
                    {...(mapOtherOpt?.imageOverlay
                      ? {
                          ...mapOpt,
                          // zoom: 13,
                          center: mapOpt?.center ?? [
                            40.74266576860375, -74.17450905020814
                          ],
                          maxZoom: mapOpt?.maxZoom ?? 17,
                          minZoom: mapOpt?.minZoom ?? 13.3,
                          maxBounds: mapOpt?.maxBounds ?? [
                            [40.712216, -74.22655],
                            [40.773941, -74.12544]
                          ],
                          // dragging: false, // Disable dragging (panning)
                          touchZoom: false, // Disable zooming with touch gestures
                          doubleClickZoom: false, // Optional: Disable zooming with double-click
                          // scrollWheelZoom: false, // Optional: Disable zooming with mouse wheel
                          style: {
                            width: "100%",
                            height: mapStyle?.height ?? "900px",
                            zIndex: 0
                          }
                        }
                      : {
                          ...mapDefaultOpt,
                          mapOpt
                        })}
                  >
                    <TileLayer
                      className={
                        mapOtherOpt?.imageOverlay ? "saba-map-overlay" : ""
                      }
                      maxZoom={mapOpt?.maxZoom ?? 20}
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {mapOtherOpt?.imageOverlay && (
                      <OverlayCoverAll options={mapOtherOpt?.imageOverlay} />
                    )}

                    <FeatureGroup ref={featureGroupRef}>
                      {isPolygon ? (
                        <Polygon
                          // pathOptions={{ color: "purple" }}
                          positions={mapPosition?.[fieldName]}
                        />
                      ) : defaultValueMapFix ? (
                        <Marker position={defaultValueMapFix} />
                      ) : null}
                      {editOrViewState ? (
                        <EditControl
                          position="topright"
                          onCreated={(e) => _onCreatePolygon(e, item, field)}
                          onEdited={(e) => _onEditedPolygon(e, item, field)}
                          onDeleted={(e) => _onDeletedPolygon(e, item, field)}
                          draw={{
                            polyline: false,
                            marker: false,
                            ...map_draw_opt,
                            ...(map_draw_opt?.marker
                              ? {
                                  marker: {
                                    icon: DefaultIcon
                                  }
                                }
                              : { marker: false }),
                            circle: false,
                            circlemarker: false,
                            rectangle: false
                          }}
                          edit={{
                            remove: false,
                            edit: mapPosition?.[fieldName]?.length > 0
                          }}
                        />
                      ) : null}
                      {mapOpt?.mainMarker && (
                        <Marker
                          position={mapOpt?.mainMarker}
                          zIndexOffset={200}
                          icon={
                            mapOpt?.mainMarkerIcon
                              ? new L.Icon({
                                  iconUrl: mapOpt?.mainMarkerIcon,
                                  shadowUrl:
                                    "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
                                  iconSize: [50, 50],
                                  shadowSize: [50, 50]
                                })
                              : DefaultIcon
                          }
                        />
                      )}
                    </FeatureGroup>

                    {mapOpt?.geoSearch !== false &&
                      !mapOtherOpt?.imageOverlay && <LeafletGeoSearch />}
                  </MapContainer>

                  {editOrViewState ? (
                    <TextareaAutosize
                      style={{
                        width: "100%",
                        display: mapOtherOpt?.viewLatLngInfo
                          ? "inherit"
                          : "none"
                      }}
                      className="map-coordinates-info"
                      {...field}
                      id={fieldName}
                      // value={JSON.stringify(mapPosition[fieldName] ?? [])}
                      value={
                        !isFullyNestedArray(mapPosition?.[fieldName] ?? [])
                          ? `${mapPosition[fieldName]}`
                              ?.replaceAll("[", "")
                              .replaceAll("]", "") ?? ""
                          : JSON.stringify(mapPosition[fieldName]) ?? []
                      }
                      {...defaultValueSet}
                      placeholder={item.placeholder ? t(item.placeholder) : ""}
                      ref={field.ref}
                      onBlur={field.onBlur}
                      disabled={true}
                      key={`dynInput_${fieldName}`}
                      invalid={errors[fieldName] && true}
                    />
                  ) : (
                    mapOtherOpt?.viewLatLngInfo &&
                    renderDefInputViewToTextArea(
                      fieldName,
                      defaultFormValues[fieldName] ??
                        getDepthValue(
                          store.pageData[storeName]?.selectedData,
                          fieldName
                        )
                    )
                  )}

                  {mapOtherOpt?.calcDistanceFromMainMarker &&
                    mapOpt?.mainMarker &&
                    (mapOtherOpt?.calcDistanceFromMainMarker?.show ===
                      undefined ||
                      mapOtherOpt?.calcDistanceFromMainMarker?.show !==
                        false) &&
                    (editOrViewState ? (
                      <Input
                        className="mt-50"
                        {...field}
                        value={mapDistanceFromMain?.[fieldName] ?? ""}
                        id={`${fieldName}_distance_main_marker`}
                        disabled={true}
                        key={`dynInput_${fieldName}_distance_main_marker`}
                        invalid={errors[fieldName] && true}
                      />
                    ) : (
                      renderDefInputViewToText(
                        `${fieldName}_distance_main_marker`,
                        mapDistanceFromMain?.[fieldName] ?? ""
                      )
                    ))}
                </Fragment>
              )}
            />
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - QRCODE
      else if (item["type"] === "qrcode") {
        let defaultRules = {}
        let defaultValueCurrentInput =
          getValues(fieldName) ??
          item?.defaultValue ??
          item?.initialValue ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.initialValueFromOther
          ) ??
          store.pageData[storeName]?.selectedData?.[fieldName] ??
          defaultFormValues?.[fieldName] ??
          ""
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={defaultValueCurrentInput}
                control={control}
                rules={{ ...rules, ...defaultRules }}
                name={fieldName}
                render={({ field, fieldState }) => {
                  let InputFinal = (
                    <Fragment>
                      {getValues(fieldName) ? (
                        <div
                          className="w-100 mb-50 text-center qrcode-input"
                          style={{
                            cursor: "pointer"
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsFullScreenImage(!isFullScreenImage)
                            setIsFullScreenImageFile(
                              filesDefaultTemp[fieldName]
                            )
                            MySwal.fire({
                              html: (
                                <QRCodeSVG
                                  size={450}
                                  value={getValues(fieldName)}
                                />
                              ),
                              title: "QR Code Viewer",
                              width: "530px",
                              backdrop: `rgba(22, 29, 49,0.7)`,
                              confirmButtonText: t("Close"),
                              showCloseButton: true,
                              showConfirmButton: false
                              // grow: "fullscreen"
                            }).then(() => {
                              setIsFullScreenImage(!isFullScreenImage)
                              setIsFullScreenImageFile(null)
                            })
                          }}
                        >
                          <QRCodeSVG
                            // size={column?.qrcode_size ?? 40}
                            value={defaultValueCurrentInput}
                          />
                        </div>
                      ) : (
                        <div
                          className="w-100 mb-50 text-center qrcode-input"
                          style={{
                            backgroundColor:
                              skin === "dark" ? "#171E31" : "#e1e1e1",
                            // backgroundImage: `url(${
                            //   require("@src/assets/images/icons/youtube.png")
                            //     .default
                            // })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "65%",
                            backgroundPosition: "center",
                            filter: "opacity(0.5)"
                          }}
                        ></div>
                      )}
                      <Input
                        {...field}
                        type={"text"}
                        key={`dynInput_${fieldName}`}
                        placeholder={
                          item.placeholder
                            ? t(item.placeholder)
                            : "Input for Set QRCode Value"
                        }
                        onChange={async (e) => {
                          const val = e.target.value
                          field.onChange(val)
                          setValue(fieldName, val)
                          await handleChangeInputs2(val, item, fieldState)
                        }}
                        onBlur={field.onBlur}
                        {...defaultValueSet}
                        disabled={disabledInput}
                        invalid={errors[fieldName] && true}
                      />
                    </Fragment>
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : getValues(fieldName) ? (
              <iframe
                width="100%"
                height="56%"
                style={{
                  borderRadius: 5,
                  width: "100%",
                  height: "56%"
                }}
                src={convertToEmbedUrl(getValues(fieldName))}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            ) : (
              <div
                style={{
                  borderRadius: 5,
                  width: "100%",
                  height: "64%",
                  backgroundColor: skin === "dark" ? "#171E31" : "#e1e1e1",
                  marginBottom: "5px",
                  backgroundImage: `url(${
                    require("@src/assets/images/icons/youtube.png").default
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "65%",
                  backgroundPosition: "center",
                  filter: "opacity(0.5)"
                }}
              ></div>
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {fieldDesc[item?.fieldName] ??
                  (item?.fieldDesc ? t(item?.fieldDesc) : "")}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - IMAGE
      else if (item["type"] === "file_image") {
        // console.log(filesDefaultTemp, fieldName)
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field }) =>
                  !uploadImagesTemp[fieldName] &&
                  !filesDefaultTemp[fieldName] ? (
                    <Dropzone
                      disabled={disabledInput}
                      accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                      multiple={item.multiple}
                      onDrop={(acceptedFiles) => {
                        // console.log(acceptedFiles[0])
                        setUploadImagesTemps(acceptedFiles[0], fieldName)
                        setImgPreviews(acceptedFiles[0], fieldName)
                        setCropImage({ x: 0, y: 0 }, fieldName)
                        setZoomImage(1, fieldName)
                        field.onChange(
                          item.multiple ? acceptedFiles : acceptedFiles[0]
                        )
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          style={{
                            // width: "200px",
                            maxWidth: "100%",
                            // height: "200px",
                            textAlign: "center",
                            margin: "auto"
                          }}
                        >
                          <div
                            {...getRootProps({
                              className:
                                "dropzone form-control formInputDynamic d-flex align-items-center justify-content-center",
                              style: {
                                padding: "8px",
                                overflow: "hidden",
                                minHeight:
                                  item?.previewStyle?.height ??
                                  item?.previewStyle?.minHeight ??
                                  "164px",
                                maxHeight:
                                  item?.previewStyle?.height ??
                                  item?.previewStyle?.maxHeight ??
                                  "164px"
                              }
                            })}
                          >
                            <input {...getInputProps()} />
                            <div className="d-flex align-items-center justify-content-center flex-column">
                              <DownloadCloud size={50} />
                              <h6 style={{ fontSize: "0.9rem" }}>
                                {t("Drop Files here or click to upload")}
                              </h6>
                              <p
                                style={{ fontSize: "9px" }}
                                className="text-secondary mb-0"
                              >
                                {t("Drop files here or click")}{" "}
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault()
                                  }}
                                >
                                  {t("browse")}
                                </a>{" "}
                                {t("through your machine")}
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  ) : (
                    <Fragment>
                      {!filesDefaultTemp[fieldName] ? (
                        <div
                          style={{
                            // width: "200px",
                            minHeight:
                              item?.previewStyle?.height ??
                              item?.previewStyle?.minHeight ??
                              "164px",
                            // height: "92%",
                            maxHeight:
                              item?.previewStyle?.height ??
                              item?.previewStyle?.maxHeight ??
                              "164px",
                            // height: "75%"
                            textAlign: "center",
                            margin: "auto",
                            cursor: "pointer"
                          }}
                          className="crop-container-dynamic form-control formInputDynamic"
                          id={`imgPreview_${fieldNameEl}`}
                        >
                          <Fragment>
                            <Cropper
                              image={imgPreview[fieldName]}
                              crop={crop[fieldName] ?? { x: 0, y: 0 }}
                              rotation={rotation}
                              zoom={zoom[fieldName] ?? 0}
                              aspect={1}
                              cropShape="round"
                              showGrid={false}
                              onCropChange={(e) => setCropImage(e, fieldName)}
                              onRotationChange={setRotation}
                              onCropComplete={(
                                croppedArea,
                                croppedAreaPixels
                              ) =>
                                onCropComplete(
                                  croppedArea,
                                  croppedAreaPixels,
                                  fieldName
                                )
                              }
                              onZoomChange={(e) => setZoomImage(e, fieldName)}
                              // restrictPosition={false}
                              // objectFit="auto-contain"
                              // objectFit="cover"
                              objectFit="auto-cover"
                              // objectFit="contain"
                              // objectFit="vertical-cover"
                              // objectFit="fill"
                              // objectFit="horizontal-cover"
                              // cropSize={{ width: "130px", height: "130px" }}
                            />

                            <UncontrolledTooltip
                              placement="right"
                              target={`imgPreview_${fieldNameEl}`}
                            >
                              {t("Scroll mouse or pinch to zoom")}
                            </UncontrolledTooltip>
                            <Button.Ripple
                              type="button"
                              color="danger"
                              style={{
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                padding: "4px"
                              }}
                              onClick={(e) => {
                                e.stopPropagation()
                                field.onChange(null)
                                removeImage(fieldName)
                                onChangeAll()
                                onChangeAll2(item?.fieldName)
                              }}
                            >
                              <X size={14} />
                            </Button.Ripple>
                          </Fragment>
                        </div>
                      ) : (
                        <div
                          style={{
                            // width: "200px",
                            // height: "200px",
                            padding: 0,
                            textAlign: "center",
                            margin: "auto",
                            background: `url(${filesDefaultTemp[fieldName]})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: "pointer",
                            ...(item?.previewStyle ?? {}),
                            ...(item?.previewStyle?.height ||
                            item?.previewStyle?.minHeight
                              ? {
                                  minHeight:
                                    item?.previewStyle?.height ??
                                    item?.previewStyle?.minHeight
                                }
                              : {}),
                            height: "92%"
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setIsFullScreenImage(!isFullScreenImage)
                            setIsFullScreenImageFile(
                              filesDefaultTemp[fieldName]
                            )
                            MySwal.fire({
                              imageUrl: filesDefaultTemp[fieldName],
                              imageWidth: "auto",
                              imageHeight: "90vh",
                              width: "100%",
                              imageAlt: t("Picture"),
                              backdrop: `rgba(22, 29, 49,0.7)`,
                              confirmButtonText: t("Close"),
                              showCloseButton: true,
                              showConfirmButton: false,
                              grow: "fullscreen"
                            }).then(() => {
                              setIsFullScreenImage(!isFullScreenImage)
                              setIsFullScreenImageFile(null)
                            })
                          }}
                          className="crop-container-dynamic form-control formInputDynamic"
                          id={`imgPreview_${fieldNameEl}`}
                        >
                          {/* <img
                            src={filesDefaultTemp[fieldName]}
                            style={{ width: "100%", height: "100%" }}
                          /> */}

                          <Button.Ripple
                            type="button"
                            color="danger"
                            style={{
                              position: "absolute",
                              top: "-13px",
                              right: "-13px",
                              padding: "4px"
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              field.onChange(null)
                              removeImage(fieldName)
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                          >
                            <X size={14} />
                          </Button.Ripple>
                        </div>
                      )}
                    </Fragment>
                  )
                }
              />
            ) : (
              <div
                style={{
                  padding: 0,
                  textAlign: "center",
                  margin: "auto",
                  background: `url(${filesDefaultTemp[fieldName]})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  cursor: "pointer"
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsFullScreenImage(!isFullScreenImage)
                  setIsFullScreenImageFile(filesDefaultTemp[fieldName])
                  MySwal.fire({
                    imageUrl: filesDefaultTemp[fieldName],
                    imageWidth: "auto",
                    imageHeight: "90vh",
                    width: "100%",
                    imageAlt: t("Picture"),
                    backdrop: `rgba(22, 29, 49,0.7)`,
                    confirmButtonText: t("Close"),
                    showCloseButton: true,
                    showConfirmButton: false,
                    grow: "fullscreen"
                  }).then(() => {
                    setIsFullScreenImage(!isFullScreenImage)
                    setIsFullScreenImageFile(null)
                  })
                }}
                className="crop-container-dynamic form-control formInputDynamic"
                id={`imgPreview_${fieldNameEl}`}
              >
                {/* <img
                  className="img-fluid"
                  src={filesDefaultTemp[fieldName]}
                  // style={{ width: "100%", height: "100%" }}
                  alt={`Image ${fieldLabel}`}
                /> */}
              </div>
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - ALL FILE
      else if (item["type"] === "file") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field }) =>
                  typeof field?.value === "object" || !field?.value ? (
                    <Input
                      {...field}
                      type="file"
                      disabled={disabledInput}
                      accept={item?.rules?.pattern?.value}
                      key={`dynInput_${fieldName}`}
                      id={`dynInput_file_${fieldName}`}
                      // ref={field.ref}
                      onChange={(val) => {
                        field.onChange()
                        setValue(fieldName, val.target.files[0])
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      // onFocus={field.onFocus}
                      value={field?.value?.filename}
                      invalid={errors[fieldName] && true}
                    />
                  ) : (
                    <div
                      style={{
                        padding: "inherit"
                      }}
                      className="d-flex justify-content-between formInputDynamic form-control"
                    >
                      <div
                        style={{
                          padding: "6px"
                        }}
                        className="d-flex align-items-center text-truncate"
                      >
                        {/* <FileText size="sm" /> */}
                        <span className="fw-bolder">
                          <a
                            target="_blank"
                            href={defaultFormValues[fieldName]}
                          >
                            {`${fieldLabel} File`}
                          </a>
                        </span>
                      </div>
                      {!disabledInput && (
                        <div className="d-flex flex-wrap align-items-center cursor-pointer">
                          <Button.Ripple
                            className="btn-icon"
                            color="flat-danger"
                            onClick={() => {
                              // console.log(field)
                              field.value = null
                              field.onChange()
                              setValue(fieldName, null)
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                              // console.log(field?.value)
                            }}
                          >
                            <XCircle size={12} />
                          </Button.Ripple>
                        </div>
                      )}
                    </div>
                  )
                }
              />
            ) : (
              <Fragment>
                <div className="mb-0 mt-1 text-truncate">
                  {defaultFormValues[fieldName] ? (
                    <a
                      key={`dynInput_${fieldName}`}
                      target="_blank"
                      href={defaultFormValues[fieldName]}
                    >
                      {`${fieldLabel} File`}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
                <hr className="m-0" />
              </Fragment>
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - PHONE
      else if (item["type"] === "phone") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Phone size={14} />
                    </InputGroupText>
                    <Cleave
                      {...field}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      disabled={disabledInput}
                      placeholder="0 813 567 8900"
                      options={{ phone: true, phoneRegionCode: "US" }}
                      id={`dynInput_${fieldName}`}
                      ref={field.ref}
                      // onChange={field.onChange}
                      onChange={(event) => {
                        field.onChange(event.target.rawValue)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                      invalid={errors[fieldName] && true}
                    />
                  </InputGroup>
                )}
              />
            ) : (
              renderDefInputViewToText(fieldName, defaultFormValues[fieldName])
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - CODE
      else if (item["type"] === "code") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={
                  item?.defaultValue ??
                  defaultFormValues?.[fieldName] ??
                  item?.initialValue ??
                  defaultFormValues?.[item?.initialValueFromOther] ??
                  ""
                }
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      {renderIcon(item?.icon ?? "material:numbers", "", 14)}
                    </InputGroupText>
                    <Cleave
                      {...field}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      placeholder={item.placeholder ? t(item.placeholder) : ``}
                      options={{
                        phone: false,
                        prefix:
                          pageType === "edit"
                            ? ``
                            : `${item?.formatOpt?.prefix ?? "PREFIX"}`,
                        delimiter: `${item?.formatOpt?.delimiter ?? "-"}`,
                        blocks: item?.formatOpt?.blocks ?? [6, 4, 4, 4],
                        uppercase: item?.formatOpt?.uppercase ?? false,
                        numericOnly: false
                      }}
                      disabled={disabledInput}
                      // onInit={(e) => {
                      //   console.log(defaultFormValues[fieldName])
                      //   setValue(fieldName, defaultFormValues[fieldName])
                      // }}
                      id={`dynInput_${fieldName}`}
                      ref={field.ref}
                      onChange={(event) => {
                        field.onChange(event.target.value)
                        afterChangeEvent(event.target.value, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                      invalid={errors[fieldName] && true}
                    />
                  </InputGroup>
                )}
              />
            ) : (
              <Fragment>
                <p className="mb-0 mt-1" key={`dynInput_${fieldName}`}>
                  {defaultFormValues[fieldName]}
                </p>
                <hr className="m-0" />
              </Fragment>
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - CURRENCY
      else if (item["type"] === "currency") {
        return (
          <Col
            id={`col_${fieldName}`}
            key={`col_${fieldName}_${prefixKey}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={
                  item?.defaultValue ??
                  defaultFormValues?.[fieldName] ??
                  item?.initialValue ??
                  defaultFormValues?.[item?.initialValueFromOther] ??
                  ""
                }
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      {item?.icon ? (
                        renderIcon(item?.icon, "", 14)
                      ) : item?.currencyCode === "IDR" ? (
                        <FontAwesomeIcon
                          className="align-middle"
                          icon="rupiah-sign"
                        />
                      ) : item?.currencyCode === "USD" ? (
                        <Paid size={14} />
                      ) : localStorage.getItem("i18nextLng") === "id" ? (
                        <FontAwesomeIcon
                          className="align-middle"
                          icon="rupiah-sign"
                        />
                      ) : (
                        <Paid size={14} />
                      )}
                    </InputGroupText>
                    <Cleave
                      {...field}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      placeholder={
                        item.placeholder
                          ? t(item.placeholder)
                          : `example: 10.000`
                      }
                      options={{
                        numeral: true
                        // numeralDecimalMark: ",",
                        // numeralDecimalScale: 2,
                        // delimiter: ".",
                        // numeralPositiveOnly: true
                      }}
                      disabled={disabledInput}
                      id={`dynInput_${fieldName}_${prefixKey}`}
                      ref={field.ref}
                      // onChange={field.onChange}
                      onChange={(event) => {
                        // console.log(event.target.rawValue)
                        // console.log(parseFloat(event.target.rawValue))
                        // console.log(parseFloat(event.target.rawValue))
                        // console.log(event.target.rawValue !== "")
                        if (event.target.rawValue !== "") {
                          const value = parseFloat(event.target.rawValue)
                          field.onChange(value)
                          debouncedAfterChangeEvent(value, item)
                          // afterChangeEvent(value, item)
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        } else {
                          field.onChange(0)
                          afterChangeEvent(0, item)
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        }
                      }}
                      onBlur={field.onBlur}
                      invalid={errors[fieldName] && true}
                    />
                    {tempAsyncOptLoading[fieldName] && (
                      <InputGroupText>
                        <Spinner size="sm"></Spinner>
                      </InputGroupText>
                    )}
                  </InputGroup>
                )}
              />
            ) : (
              renderDefInputViewToCurrency(
                fieldName,
                defaultFormValues[fieldName],
                item
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - DATE
      else if (item["type"] === "date") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                {...getDefaultDateInputs(item, "date")}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Calendar size={14} />
                    </InputGroupText>
                    <Flatpickr
                      {...field}
                      disabled={disabledInput}
                      placeholder={
                        item?.placeholder
                          ? t(item?.placeholder)
                          : `${t("Select")} ${t(fieldLabel)}`
                      }
                      key={`dynInput_${fieldName}`}
                      options={{
                        ...(item.calendarRules ? calendarRules : null),
                        minTime: getMinMaxTimeInputs(item, "min"),
                        maxTime: getMinMaxTimeInputs(item, "max"),
                        minDate: getMinMaxTimeInputs(item, "min"),
                        maxDate: getMinMaxTimeInputs(item, "max"),
                        onReady: (selectedDates, dateStr, instance) => {
                          if (item?.shortcutBtn) {
                            if (Array.isArray(item?.shortcutBtn)) {
                              item?.shortcutBtn.map((shortcutBtn) => {
                                let specificDates = shortcutBtn?.value
                                const specificDateTitle = shortcutBtn?.title
                                const className = shortcutBtn?.className
                                const customButton =
                                  document.createElement("button")
                                customButton.innerText = specificDateTitle
                                customButton.classList.add(
                                  "btn",
                                  "btn-gradient-primary",
                                  "mx-50",
                                  "my-25",
                                  "calendar-input-shortcut-btn",
                                  "btn-sm",
                                  ...(className ? className?.split(" ") : [])
                                )
                                customButton.addEventListener("click", () => {
                                  instance.setDate(specificDates)
                                  setValue(item?.fieldName, specificDates)
                                  field.onChange(specificDates)
                                  if (filterMode) {
                                    beforeHandleFiltersChangeToParent()
                                  }
                                  afterChangeEvent(specificDates, item)
                                  onChangeAll()
                                  onChangeAll2(item?.fieldName)
                                })

                                instance?.calendarContainer?.appendChild(
                                  customButton
                                )
                              })
                            }
                          }
                        }
                      }}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      id={`${fieldName}`}
                      ref={field.ref}
                      onChange={async (selectedDates, dateStr) => {
                        field.onChange(dateStr)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        await afterChangeEvent(dateStr, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                    />
                    {!item?.rules?.required &&
                      !disabledInput &&
                      field.value?.length > 0 &&
                      !store.pageData[storeName]?.isLoading && (
                        <InputGroupText>
                          <X
                            style={{ cursor: "pointer", fontWeight: "bolder" }}
                            size={16}
                            onClick={() => {
                              field.onChange(null)
                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                          />
                        </InputGroupText>
                      )}
                  </InputGroup>
                )}
              />
            ) : (
              <Fragment>
                <p
                  style={{
                    minHeight: "21px"
                  }}
                  className="mb-0 mt-1"
                  key={`dynInput_${fieldName}`}
                >
                  {defaultFormValues[fieldName] ||
                    (getDepthValue(
                      store.pageData[storeName]?.selectedData,
                      fieldName
                    ) && (
                      // <Moment calendar={calendarStrings}>
                      //   {defaultFormValues[fieldName]}
                      // </Moment>
                      <Moment
                        calendar={{
                          lastDay: "DD-MM-YYYY",
                          nextDay: "DD-MM-YYYY",
                          lastWeek: "DD-MM-YYYY",
                          lastWeek: "DD-MM-YYYY",
                          sameDay: "DD-MM-YYYY",
                          sameElse: "DD-MM-YYYY"
                        }}
                      >
                        {defaultFormValues[fieldName] ??
                          getDepthValue(
                            store.pageData[storeName]?.selectedData,
                            fieldName
                          )}
                      </Moment>
                    ))}
                </p>
                <hr className="m-0" />
              </Fragment>
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - DATE-RANGE
      else if (item["type"] === "date-range") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                defaultValue={
                  !filterMode
                    ? [
                        getValues(item?.startDateField) ??
                          defaultFormValues[item?.startDateField],
                        // ?? new Date().toISOString().slice(0, 10),
                        getValues(item?.endDateField) ??
                          defaultFormValues[item?.endDateField]
                        // ?? new Date().toISOString().slice(0, 10)
                      ]
                    : [
                        defaultFormValues[item?.startDateField],
                        defaultFormValues[item?.endDateField]
                      ]
                }
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Calendar size={14} />
                    </InputGroupText>
                    <Flatpickr
                      {...field}
                      key={`dynInput_${fieldName}`}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      placeholder={
                        item?.placeholder
                          ? t(item?.placeholder)
                          : `${t("Select")} ${t(fieldLabel)}`
                      }
                      options={{
                        allowInput: false,
                        autoFillDefaultTime: false,
                        closeOnSelect: true,
                        mode: "range",
                        defaultDate: !filterMode
                          ? [
                              getValues(item?.startDateField) ??
                                defaultFormValues[item?.startDateField],
                              // ?? new Date().toISOString().slice(0, 10),
                              getValues(item?.endDateField) ??
                                defaultFormValues[item?.endDateField]
                              // ?? new Date().toISOString().slice(0, 10)
                            ]
                          : null,
                        ...(item?.calendarRules ?? {}),
                        minTime: getMinMaxTimeInputs(item, "min"),
                        maxTime: getMinMaxTimeInputs(item, "max"),
                        minDate: getMinMaxTimeInputs(item, "min"),
                        maxDate: getMinMaxTimeInputs(item, "max"),
                        disable: [
                          function (date) {
                            // console.log(
                            //   getValues(item?.calendarRules?.maxRangeFromField)
                            // )
                            if (item?.calendarRules) {
                              let today =
                                getMinMaxTimeInputs(item, "min") ?? new Date()
                              // get the date 8 days after today
                              let next8days = new Date(today)
                              next8days.setDate(
                                next8days.getDate() +
                                  (getValues(
                                    item?.calendarRules?.maxRangeFromField
                                  ) ?? 0)
                              )
                              return date < today || date > next8days
                            } else {
                              return false
                            }
                          }
                        ],
                        onOpen: function (selectedDates, dateStr, instance) {
                          if (item?.calendarRules?.minDateFromField) {
                            let minResult = getMinMaxTimeInputs(item, "min")
                            instance.set("minDate", minResult)

                            let currentMonth = minResult

                            if (isDateString(minResult)) {
                              currentMonth = new Date(minResult).getMonth() + 1
                            } else if (isDate(minResult)) {
                              currentMonth = minResult.getMonth() + 1
                            }
                            if (currentMonth !== 12) {
                              instance.changeMonth(-12)
                              instance.changeMonth(12)
                            }
                          }
                        },
                        onReady: (selectedDates, dateStr, instance) => {
                          if (item?.shortcutBtn) {
                            if (Array.isArray(item?.shortcutBtn)) {
                              item?.shortcutBtn.map((shortcutBtn) => {
                                let specificDates = shortcutBtn?.value
                                const specificDateTitle = shortcutBtn?.title
                                const className = shortcutBtn?.className
                                const customButton =
                                  document.createElement("button")
                                customButton.innerText = specificDateTitle
                                // customButton.classList.add(
                                //   "calendar-input-shortcut-btn"
                                // )
                                customButton.classList.add(
                                  "btn",
                                  "btn-gradient-primary",
                                  "mx-50",
                                  "my-25",
                                  "calendar-input-shortcut-btn",
                                  "btn-sm",
                                  ...(className ? className?.split(" ") : [])
                                )
                                customButton.addEventListener("click", () => {
                                  if (Array.isArray(specificDates)) {
                                    setValue(
                                      item?.startDateField,
                                      specificDates[0]
                                    )
                                    setValue(
                                      item?.endDateField,
                                      specificDates[1]
                                    )
                                  }
                                  // instance.clear()
                                  // console.log(specificDates)
                                  instance.setDate(specificDates)
                                  setValue(item?.fieldName, specificDates)
                                  field.onChange(specificDates)
                                  // trigger(item?.fieldName)

                                  if (filterMode) {
                                    beforeHandleFiltersChangeToParent()
                                  }
                                  afterChangeEvent(specificDates, item)
                                  onChangeAll()
                                  onChangeAll2(item?.fieldName)
                                })

                                instance.calendarContainer.appendChild(
                                  customButton
                                )
                              })
                            }
                          }
                        }
                      }}
                      disabled={disabledInput}
                      id={`${fieldName}`}
                      ref={field.ref}
                      onChange={(data) => {
                        // console.log(data)
                        // setValue(
                        //   item?.startDateField,
                        //   generateDateFormat(data[0])
                        // )
                        if (data.length > 1) {
                          setValue(
                            item?.startDateField,
                            generateDateFormat(data[0])
                          )
                          setValue(
                            item?.endDateField,
                            generateDateFormat(data[1])
                          )

                          field.onChange(data)
                          if (filterMode) {
                            beforeHandleFiltersChangeToParent()
                          }
                          afterChangeEvent(data, item)
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        }
                      }}
                      onBlur={field.onBlur}
                    />
                    {!item?.rules?.required &&
                      !disabledInput &&
                      field.value?.length > 0 &&
                      !store.pageData[storeName]?.isLoading && (
                        <InputGroupText>
                          <X
                            style={{ cursor: "pointer", fontWeight: "bolder" }}
                            size={16}
                            onClick={() => {
                              setValue(item?.startDateField, null)
                              setValue(item?.endDateField, null)
                              field.onChange(null)
                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              afterChangeEvent(null, item)
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                          />
                        </InputGroupText>
                      )}
                  </InputGroup>
                )}
              />
            ) : (
              renderDefInputViewToDateRange(
                fieldName,
                defaultFormValues[item?.startDateField] ??
                  getDepthValue(
                    store.pageData[storeName]?.selectedData,
                    item?.startDateField
                  ),
                defaultFormValues[item?.endDateField] ??
                  getDepthValue(
                    store.pageData[storeName]?.selectedData,
                    item?.endDateField
                  )
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - DATETIME-RANGE
      else if (item["type"] === "datetime-range") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                defaultValue={
                  !filterMode
                    ? [
                        getValues(item?.startDateField) ??
                          defaultFormValues[item?.startDateField],
                        // ?? new Date().toISOString().slice(0, 10),
                        getValues(item?.endDateField) ??
                          defaultFormValues[item?.endDateField]
                        // ?? new Date().toISOString().slice(0, 10)
                      ]
                    : [
                        defaultFormValues[item?.startDateField],
                        defaultFormValues[item?.endDateField]
                      ]
                }
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Calendar size={14} />
                    </InputGroupText>
                    <Flatpickr
                      data-enable-time
                      {...field}
                      key={`dynInput_${fieldName}`}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      placeholder={
                        item?.placeholder
                          ? t(item?.placeholder)
                          : `${t("Select")} ${t(fieldLabel)}`
                      }
                      options={{
                        allowInput: false,
                        autoFillDefaultTime: false,
                        closeOnSelect: true,
                        mode: "range",
                        // enableTime: true,
                        // dateFormat: "Y-m-d h:iK",
                        defaultDate: !filterMode
                          ? [
                              getValues(item?.startDateField) ??
                                defaultFormValues[item?.startDateField],
                              // ?? new Date().toISOString().slice(0, 10),
                              getValues(item?.endDateField) ??
                                defaultFormValues[item?.endDateField]
                              // ?? new Date().toISOString().slice(0, 10)
                            ]
                          : null
                      }}
                      disabled={disabledInput}
                      id={`${fieldName}`}
                      ref={field.ref}
                      onChange={(data, dateStr) => {
                        // console.log(data, dateStr, instance)
                        // setValue(
                        //   item?.startDateField,
                        //   generateDateFormat(data[0])
                        // )
                        // console.log(dateStr.split(" to "))
                        const dateString = dateStr.split(" to ")
                        if (data.length > 1) {
                          setValue(item?.startDateField, dateString[0])
                          setValue(item?.endDateField, dateString[1])

                          field.onChange(data)
                          if (filterMode) {
                            beforeHandleFiltersChangeToParent()
                          }
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        }
                      }}
                      onBlur={field.onBlur}
                    />
                    {!item?.rules?.required &&
                      !disabledInput &&
                      field.value?.length > 0 &&
                      !store.pageData[storeName]?.isLoading && (
                        <InputGroupText>
                          <X
                            style={{ cursor: "pointer", fontWeight: "bolder" }}
                            size={16}
                            onClick={() => {
                              setValue(item?.startDateField, null)
                              setValue(item?.endDateField, null)
                              field.onChange(null)
                              if (filterMode) {
                                beforeHandleFiltersChangeToParent()
                              }
                              onChangeAll()
                              onChangeAll2(item?.fieldName)
                            }}
                          />
                        </InputGroupText>
                      )}
                  </InputGroup>
                )}
              />
            ) : (
              renderDefInputViewToDateRange(
                fieldName,
                defaultFormValues[item?.startDateField] ??
                  getDepthValue(
                    store.pageData[storeName]?.selectedData,
                    item?.startDateField
                  ),
                defaultFormValues[item?.endDateField] ??
                  getDepthValue(
                    store.pageData[storeName]?.selectedData,
                    item?.endDateField
                  )
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - TIME
      else if (item["type"] === "time") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={rules}
                name={fieldName}
                {...getDefaultDateInputs(item, "time")}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Clock size={14} />
                    </InputGroupText>
                    <Flatpickr
                      data-enable-time
                      {...field}
                      disabled={disabledInput}
                      key={`dynInput_${fieldName}`}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      options={{
                        ...(item.calendarRules ? calendarRules : null),
                        minTime: getMinMaxTimeInputs(item, "min"),
                        maxTime: getMinMaxTimeInputs(item, "max"),
                        noCalendar: true
                      }}
                      id={`${fieldName}`}
                      ref={field.ref}
                      onChange={(selectedDates, dateStr) => {
                        field.onChange(dateStr)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        afterChangeEvent(dateStr, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onReady={(selectedDates, dateStr) => {
                        afterChangeEvent(dateStr, item)
                      }}
                      onBlur={field.onBlur}
                    />
                    {!item?.rules?.required &&
                      !item?.required &&
                      !disabledInput &&
                      field.value?.length > 0 && (
                        <InputGroupText>
                          <X
                            style={{ cursor: "pointer" }}
                            size={14}
                            onClick={(e) => clearDate(field, item, field.ref)}
                          />
                        </InputGroupText>
                      )}
                  </InputGroup>
                )}
              />
            ) : (
              <Fragment>
                <p
                  style={{
                    minHeight: "21px"
                  }}
                  className="mb-0 mt-1"
                  key={`dynInput_${fieldName}`}
                >
                  {/* <Moment calendar={calendarStrings}> */}
                  {defaultFormValues[fieldName] ??
                    getDepthValue(
                      store.pageData[storeName]?.selectedData,
                      fieldName
                    )}
                  {/* </Moment> */}
                </p>
                <hr className="m-0" />
              </Fragment>
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - DATETIME
      else if (item["type"] === "datetime") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                {...getDefaultDateInputs(item, "datetime")}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Calendar size={14} />
                    </InputGroupText>
                    <Flatpickr
                      data-enable-time
                      {...field}
                      disabled={disabledInput}
                      key={`dynInput_${fieldName}`}
                      options={{
                        ...(item.calendarRules ? calendarRules : null),
                        minTime: getMinMaxTimeInputs(item, "min"),
                        maxTime: getMinMaxTimeInputs(item, "max"),
                        minDate: getMinMaxTimeInputs(item, "min"),
                        maxDate: getMinMaxTimeInputs(item, "max")
                      }}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      id={`${fieldName}`}
                      ref={field.ref}
                      onChange={(selectedDates, dateStr) => {
                        field.onChange(dateStr)
                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }
                        afterChangeEvent(dateStr, item)
                      }}
                      onReady={(selectedDates, dateStr) => {
                        afterChangeEvent(dateStr, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                    />
                  </InputGroup>
                )}
              />
            ) : (
              <Fragment>
                <p className="mb-0 mt-1" key={`dynInput_${fieldName}`}>
                  {defaultFormValues[fieldName] && (
                    <Moment calendar={calendarStrings}>
                      {defaultFormValues[fieldName]}
                    </Moment>
                  )}
                </p>
                <hr className="m-0" />
              </Fragment>
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - YEAR
      else if (item["type"] === "year") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={
                  ({ ...item.rules },
                  {
                    min: {
                      value: 1990,
                      message: "Invalid year, min value is 1990"
                    },
                    max: {
                      value: 2040,
                      message: "Invalid year, min length is 2040"
                    },
                    minLength: {
                      value: 4,
                      message: "Invalid year, min length is 4"
                    }
                  })
                }
                name={fieldName}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Calendar size={14} />
                    </InputGroupText>
                    <Cleave
                      {...field}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      disabled={disabledInput}
                      placeholder="1999"
                      options={{ date: true, datePattern: ["Y"] }}
                      id={`dynInput_${fieldName}`}
                      ref={field.ref}
                      // onChange={field.onChange}
                      onChange={(event) => {
                        // console.log(event.target.rawValue)
                        if (event.target.rawValue) {
                          if (item?.valueType === "int") {
                            field.onChange(parseInt(event.target.rawValue))
                          } else {
                            field.onChange(event.target.rawValue)
                          }
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        }
                      }}
                      onBlur={field.onBlur}
                      invalid={errors[fieldName] && true}
                    />
                  </InputGroup>
                )}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                getDepthValue(
                  store.pageData[storeName]?.selectedData,
                  item?.initialValue ?? item?.initialValueFromOther ?? fieldName
                ) ?? ""
              )
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - EMAIL
      else if (item["type"] === "email") {
        const defaultRules = {
          maxLength: {
            value: 50,
            message: "Max. length is 50"
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            // value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: "Invalid email address"
          }
        }

        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={
                  item.rules ? { ...item.rules, ...defaultRules } : defaultRules
                }
                name={fieldName}
                render={({ field }) => (
                  <InputGroup
                    className={classnames("", {
                      "is-invalid": errors[fieldName]
                    })}
                  >
                    <InputGroupText>
                      <Mail size={14} />
                    </InputGroupText>

                    <Input
                      {...field}
                      id={fieldName}
                      type="email"
                      onChange={(event) => {
                        const val = event.target.value
                        field.onChange(val)
                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      placeholder={item.placeholder ? t(item.placeholder) : ""}
                      ref={field.ref}
                      onBlur={field.onBlur}
                      disabled={disabledInput}
                      key={`dynInput_${fieldName}`}
                      invalid={errors[fieldName] && true}
                    />
                  </InputGroup>
                )}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                getDepthValue(
                  store.pageData[storeName]?.selectedData,
                  item?.initialValue ?? item?.initialValueFromOther ?? fieldName
                ) ?? ""
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - TEXTAREA
      else if (item["type"] === "textarea") {
        const defaultRules = {}

        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={rules ?? defaultRules}
                name={fieldName}
                render={({ field }) => (
                  <TextareaAutosize
                    {...field}
                    key={`dynInput_${fieldName}`}
                    invalid={errors[fieldName] && true}
                    className={classnames("form-control", {
                      "is-invalid": errors[fieldName]
                    })}
                    defaultValue={
                      defaultFormValues?.[fieldName] ??
                      getDepthValue(
                        store.pageData[storeName]?.selectedData,
                        item?.initialValue ??
                          item?.initialValueFromOther ??
                          fieldName
                      ) ??
                      ""
                    }
                    ref={field.ref}
                    placeholder={item.placeholder ? t(item.placeholder) : ""}
                    onChange={(event) => {
                      // console.log(event.target.value)
                      const val = event.target.value
                      field.onChange(val)
                      afterChangeEvent(val, item)
                      onChangeAll()
                      onChangeAll2(item?.fieldName)
                    }}
                    onBlur={field.onBlur}
                    {...defaultValueSet}
                    disabled={item?.isDisabled || disabledInput}
                    // cacheMeasurements
                    style={{
                      height: "38px !important",
                      padding: "0.571rem 1rem !important"
                    }}
                    {...(item?.minRows ? { minRows: item?.minRows } : {})}
                  />
                )}
              />
            ) : (
              renderDefInputViewToTextArea(
                fieldName,
                defaultFormValues?.[fieldName] ??
                  getDepthValue(
                    store.pageData[storeName]?.selectedData,
                    item?.initialValue ??
                      item?.initialValueFromOther ??
                      fieldName
                  )
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - EDITOR
      else if (item["type"] === "editor") {
        const defaultRules = {}

        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={rules ?? defaultRules}
                name={fieldName}
                render={({ field }) => (
                  <Editor
                    {...field}
                    key={`dynInput_${fieldName}`}
                    editorState={editorValue[fieldName]}
                    onEditorStateChange={(data) => {
                      let editorValueCopy = { ...editorValue }
                      editorValueCopy[fieldName] = data
                      setEditorValue(editorValueCopy)
                      // const contentState = draftToHtml(
                      //   convertToRaw(data.getCurrentContent())
                      // )

                      // field.onChange(contentState)
                      // setValue(fieldName, contentState)
                      // afterChangeEvent(contentState, item)
                    }}
                    onChange={(data) => {
                      // console.log(draftToHtml(data))
                      const contentState = draftToHtml(data)
                      field.onChange(contentState)
                      // setValue(fieldName, contentState)
                      afterChangeEvent(contentState, item)
                      onChangeAll()
                      onChangeAll2(item?.fieldName)
                    }}
                    // onBlur={field.onBlur}
                    invalid={errors[fieldName] && true}
                    className={classnames("form-control", {
                      "is-invalid": errors[fieldName]
                    })}
                    ref={field.ref}
                    placeholder={item.placeholder ? t(item.placeholder) : ""}
                    {...defaultValueSet}
                  />
                )}
              />
            ) : (
              <Fragment>
                <div
                  className="mb-0 mt-1"
                  dangerouslySetInnerHTML={{
                    __html: defaultFormValues[fieldName]
                  }}
                ></div>
                <hr className="m-0" />
              </Fragment>
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - CODE_EDITOR
      else if (item["type"] === "code_editor") {
        const defaultRules = {}

        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}

            {editOrViewState ? (
              <Controller
                control={control}
                rules={rules ?? defaultRules}
                name={fieldName}
                render={({ field }) => (
                  <div
                    style={{
                      overflow: "auto"
                      // minHeight: "100px"
                    }}
                  >
                    <CodeEditor
                      data-color-mode="dark"
                      language={item?.code_language ?? "js"}
                      // onChange={(evn) => setCode(evn.target.value)}
                      {...field}
                      key={`dynInput_${fieldName}`}
                      onChange={(data) => {
                        let contentState = data.target.value
                        // console.log(contentState)
                        let contentStateParse = null

                        if (item?.toObject && item.code_language === "json") {
                          if (contentState === "" && item?.fromObject) {
                            contentState = item?.multipleData ? "[]" : "{}"
                          }
                          try {
                            contentStateParse = JSON.parse(contentState)
                          } catch (error) {
                            setError(fieldName, {
                              message: "invalid format"
                            })
                            console.error(`Error parsing ${fieldName}`)
                          }
                        }
                        field.onChange(contentState)
                        // setValue(fieldName, contentState)
                        afterChangeEvent(contentState, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      // onBlur={field.onBlur}
                      invalid={errors[fieldName] && true}
                      className={classnames("form-control", {
                        "is-invalid": errors[fieldName]
                      })}
                      ref={field.ref}
                      placeholder={item.placeholder ? t(item.placeholder) : ""}
                      // {...defaultValueSet}
                      padding={15}
                      style={{
                        fontSize: 12,
                        // backgroundColor: "var(--color-canvas-subtle)",
                        // backgroundColor: "#efefef",
                        resize: true,
                        // overflowY: "auto"
                        fontFamily:
                          "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace"
                      }}
                    />

                    {/* // <EditorMonaco
                    //   {...field}
                    //   key={`dynInput_${fieldName}`}
                    //   invalid={errors[fieldName] && true}
                    //   className={classnames("form-control", {
                    //     "is-invalid": errors[fieldName]
                    //   })}
                    //   ref={field.ref}
                    //   placeholder={item.placeholder ? t(item.placeholder) : ""}
                    //   // height={!isFullscreen ? "75vh" : "100%"}
                    //   height="100px"Z
                    //   language={item?.code_language ?? "js"}
                    //   theme="vs-dark"
                    //   // defaultValue={
                    //   //   storeDeveloper.pageAttributesChanged[storeName]
                    //   // }
                    //   // value={
                    //   //   storeDeveloper.pageAttributesChanged[storeName] ??
                    //   //   storeDeveloper.pageAttributes[storeName]
                    //   // }
                    //   // onChange={handleEditorChange}
                    //   // onValidate={handleEditorValidation}
                    //   options={{
                    //     inlineSuggest: true,
                    //     fontSize: "13px",
                    //     formatOnType: true,
                    //     formatOnPaste: true,
                    //     // automaticLayout: true,
                    //     // autoClosingBrackets: true,
                    //     // minimap: { scale: 25, maxColumn: 50 },
                    //     showFoldingControls: "always",
                    //     wordWrap: true
                    //   }}
                    //   // beforeMount={handleEditorWillMount}
                    //   // editorDidMount={handleEditorDidMount}
                    // />
                     */}
                  </div>
                )}
              />
            ) : (
              renderDefInputViewToTextArea(
                fieldName,
                defaultFormValues[fieldName] ??
                  defaultFormValues[fieldName] ??
                  getDepthValue(
                    store.pageData[storeName]?.selectedData,
                    fieldName
                  )
              )
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - SEPARATOR
      else if (item["type"] === "separator") {
        return (
          <Col
            id={`col_${fieldName}`}
            key={`col_separator_${index}`}
            className={classnames("mb-0 mt-0", hideOnPageClassName)}
            {...colSizeFix}
          >
            {!fieldLabel ? (
              <hr />
            ) : item?.icon ? (
              <div className="d-flex align-items-center">
                <div className="me-75">
                  {item?.icon &&
                    renderIcon(
                      item?.icon,
                      `${item?.iconClass ?? ""}`,
                      item.fieldDesc ? 25 : 20,
                      {
                        marginTop: "-3px"
                      }
                    )}
                </div>
                {item?.fieldDesc ? (
                  <div className="w-100">
                    <div
                      style={{ margin: "1rem 0 0 0" }}
                      className={`w-100 divider ${
                        item?.className ? item?.className : ""
                      }`}
                    >
                      <div className="divider-text">
                        {t(fieldLabel ?? fieldName ?? "")}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        margin: "0 0 1rem 0"
                      }}
                    >
                      {item.fieldDesc}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`w-100 divider ${
                      item?.className ? item?.className : ""
                    }`}
                  >
                    <div className="divider-text">
                      {t(fieldLabel ?? fieldName ?? "")}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`divider ${item?.className ? item?.className : ""}`}
              >
                <div className="divider-text">
                  {t(fieldLabel ?? fieldName ?? "")}
                </div>
              </div>
            )}
          </Col>
        )
      }

      ////////////INPUT - YOUTUBE
      else if (item["type"] === "youtube") {
        let defaultRules = {}
        let defaultValueCurrentInput =
          item?.defaultValue ??
          item?.initialValue ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.initialValueFromOther
          ) ??
          store.pageData[storeName]?.selectedData?.[fieldName] ??
          defaultFormValues?.[fieldName] ??
          ""
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={defaultValueCurrentInput}
                control={control}
                rules={{ ...rules, ...defaultRules }}
                name={fieldName}
                render={({ field, fieldState }) => {
                  let InputFinal = (
                    <Fragment>
                      {getValues(fieldName) ? (
                        <iframe
                          width="100%"
                          height="56%"
                          style={{
                            borderRadius: 5,
                            width: "100%",
                            height: "56%"
                          }}
                          src={convertToEmbedUrl(getValues(fieldName))}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <div
                          style={{
                            borderRadius: 5,
                            width: "100%",
                            height: "64%",
                            backgroundColor:
                              skin === "dark" ? "#171E31" : "#e1e1e1",
                            marginBottom: "5px",
                            backgroundImage: `url(${
                              require("@src/assets/images/icons/youtube.png")
                                .default
                            })`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "65%",
                            backgroundPosition: "center",
                            filter: "opacity(0.5)",
                            ...(item?.style ?? {})
                          }}
                        ></div>
                      )}
                      <Input
                        {...field}
                        type={item?.type ?? "text"}
                        key={`dynInput_${fieldName}`}
                        placeholder={
                          item.placeholder
                            ? t(item.placeholder)
                            : "https://www.youtube.com/watch?v=gyXqLwWOFjk"
                        }
                        onChange={(event) => {
                          const val = event.target.value
                          // console.log(val)
                          // console.log(`${convertToEmbedUrl(val)}`)
                          field.onChange(val)
                          // setValue(fieldName, val)
                          handleChangeInputs2(val, item, fieldState)
                        }}
                        onBlur={field.onBlur}
                        {...defaultValueSet}
                        disabled={disabledInput}
                        invalid={errors[fieldName] && true}
                      />
                    </Fragment>
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : getValues(fieldName) ? (
              <iframe
                width="100%"
                height="56%"
                style={{
                  borderRadius: 5,
                  width: "100%",
                  height: "56%"
                }}
                src={convertToEmbedUrl(getValues(fieldName))}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            ) : (
              <div
                style={{
                  borderRadius: 5,
                  width: "100%",
                  height: "64%",
                  backgroundColor: skin === "dark" ? "#171E31" : "#e1e1e1",
                  marginBottom: "5px",
                  backgroundImage: `url(${
                    require("@src/assets/images/icons/youtube.png").default
                  })`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "65%",
                  backgroundPosition: "center",
                  filter: "opacity(0.5)"
                }}
              ></div>
            )}

            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {fieldDesc[item?.fieldName] ??
                  (item?.fieldDesc ? t(item?.fieldDesc) : "")}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - COLOR_PICKER
      else if (item["type"] === "color_picker") {
        let defaultRules = {}
        let defaultValueCurrentInput =
          item?.defaultValue ??
          item?.initialValue ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.initialValueFromOther
          ) ??
          store.pageData[storeName]?.selectedData?.[fieldName] ??
          defaultFormValues?.[fieldName] ??
          ""
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={defaultValueCurrentInput}
                control={control}
                rules={{ ...rules, ...defaultRules }}
                name={fieldName}
                render={({ field, fieldState }) => {
                  let InputFinal = (
                    <Fragment>
                      <InputGroup
                        className={classnames("", {
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <Input
                          {...field}
                          id={fieldName}
                          type="text"
                          onChange={async (event) => {
                            const val = event.target.value
                            // console.log(val)
                            let newVal = val
                            if (val === "") {
                              newVal = "#"
                            }
                            field.onChange(newVal)
                            await afterChangeEvent(newVal, item)
                            onChangeAll()
                            onChangeAll2(item?.fieldName)
                          }}
                          placeholder={
                            item.placeholder
                              ? t(item.placeholder)
                              : "pick some color..."
                          }
                          onFocus={(e) => {
                            return handleClickColorPicker(fieldName)
                          }}
                          ref={field.ref}
                          // onBlur={}
                          onBlur={(e) => {
                            return handleClickColorPicker(fieldName)
                          }}
                          disabled={true}
                          key={`dynInput_${fieldName}`}
                          invalid={errors[fieldName] && true}
                        />

                        <InputGroupText
                          style={{
                            padding: "5px"
                          }}
                        >
                          {getValues(fieldName) ||
                          colorPickersTemp[fieldName]?.color ? (
                            <div
                              style={{
                                width: "30px",
                                height: "100%",
                                background:
                                  getValues(fieldName) ??
                                  colorPickersTemp[fieldName]?.color ??
                                  "#FFF",
                                cursor: "pointer",
                                borderRadius: "4px"
                              }}
                              onClick={(e) => {
                                return handleClickColorPicker(fieldName)
                              }}
                            ></div>
                          ) : (
                            <PlusSquare
                              style={{
                                width: "30px",
                                cursor: "pointer"
                              }}
                              onClick={(e) => {
                                return handleClickColorPicker(fieldName)
                              }}
                            ></PlusSquare>
                          )}
                        </InputGroupText>
                        {colorPickersTemp[fieldName]?.displayColorPicker ? (
                          <div
                            style={{
                              position: "absolute",
                              left: "5px",
                              top: "46px",
                              zIndex: 2
                            }}
                          >
                            <div
                              style={{
                                position: "fixed",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px"
                              }}
                              onClick={(e) => {
                                return handleCloseColorPicker(fieldName)
                              }}
                            />
                            <SketchPicker
                              color={colorPickersTemp[fieldName]?.color}
                              onChange={async (color, e) => {
                                // console.log(e)
                                const val = color.hex
                                field.onChange(val)
                                setValue(fieldName, val)
                                await handleChangeInputs2(val, item, fieldState)
                                return handleChangeColorPicker(fieldName, val)
                              }}
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                      {/* <div
                        style={styles.swatch}
                        onClick={(e) => handleClickColorPicker(fieldName)}
                      >
                        <div style={styles.color} />
                      </div>
                      {colorPickersTemp[fieldName]?.displayColorPicker ? (
                        <div style={styles.popover}>
                          <div
                            style={styles.cover}
                            onClick={(e) => handleCloseColorPicker(fieldName)}
                          />
                          <SketchPicker
                            color={colorPickersTemp[fieldName]?.color}
                            onChange={(color, e) =>
                              handleChangeColorPicker(fieldName, color)
                            }
                          />
                        </div>
                      ) : null} */}
                    </Fragment>
                    // <SketchPicker
                    //   color={defaultValueCurrentInput ?? "#fff"}
                    //   // {...field}
                    //   key={`dynInput_${fieldName}`}
                    //   onChange={async (color, event) => {
                    //     const val = color.hex
                    //     console.log(val)
                    //     // field.onChange(val)
                    //     setValue(fieldName, val)
                    //     await handleChangeInputs2(val, item, fieldState)
                    //   }}
                    // />
                  )

                  return InputFinal
                }}
              />
            ) : (
              renderDefInputViewToText(fieldName, defaultFormValues[fieldName])
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {fieldDesc[item?.fieldName] ??
                  (item?.fieldDesc ? t(item?.fieldDesc) : "")}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - NUMBER
      else if (item["type"] === "number") {
        let defaultRules = {}
        if (rules?.maxLength && !rules?.maxLength?.message) {
          let max = rules?.maxLength?.value ?? rules?.maxLength
          defaultRules = {
            ...defaultRules,
            maxLength: {
              value: max,
              message: `Max. length is ${max}`
            }
          }
        }

        if (rules?.minLength && !rules?.minLength?.message) {
          let min = rules?.minLength?.value ?? rules?.minLength
          defaultRules = {
            ...defaultRules,
            minLength: {
              value: min,
              message: `Min. length is ${min}`
            }
          }
        }
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            md={item?.size ?? 12}
            xs={12}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={
                  item?.defaultValue
                    ? item?.defaultValue
                    : item?.isM2MDefValFromGrid
                    ? store.pageData[storeName].data.map((x) => x[fieldName])
                    : item?.initialValue
                    ? item?.initialValue
                    : store.pageData[storeName]?.selectedData
                    ? store.pageData[storeName]?.selectedData[fieldName]
                    : ""
                }
                control={control}
                rules={{ ...rules, ...defaultRules }}
                name={fieldName}
                render={({ field }) => {
                  let InputFinal = (
                    <Input
                      {...field}
                      type="number"
                      key={`dynInput_${fieldName}`}
                      onChange={(e) => {
                        // console.log(e.target.value)
                        const val = e.target.value
                        const valType = item?.valueType
                        if (val !== "") {
                          if (valType === "int") {
                            field.onChange(parseInt(val))
                          } else {
                            field.onChange(val)
                          }
                        } else {
                          if (valType === "int") {
                            field.onChange(0)
                          } else {
                            field.onChange("")
                          }
                        }
                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      disabled={disabledInput}
                      invalid={errors[fieldName] && true}
                      {...defaultValueSet}
                    />
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : (
              renderDefInputViewToText(fieldName, defaultFormValues[fieldName])
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - NUMBER
      else if (item["type"] === "number-btn") {
        let defaultRules = {}
        if (rules?.maxLength && !rules?.maxLength?.message) {
          let max = rules?.maxLength?.value ?? rules?.maxLength
          defaultRules = {
            ...defaultRules,
            maxLength: {
              value: max,
              message: `Max. length is ${max}`
            }
          }
        }

        if (rules?.minLength && !rules?.minLength?.message) {
          let min = rules?.minLength?.value ?? rules?.minLength ?? 0
          defaultRules = {
            ...defaultRules,
            minLength: {
              value: min,
              message: `Min. length is ${min}`
            }
          }
        }
        let mixRules = { ...rules, ...defaultRules }
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            md={item?.size ?? 12}
            xs={12}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={
                  item?.defaultValue
                    ? item?.defaultValue
                    : item?.isM2MDefValFromGrid
                    ? store.pageData[storeName].data.map((x) => x[fieldName])
                    : item?.initialValue
                    ? item?.initialValue
                    : store.pageData[storeName]?.selectedData
                    ? store.pageData[storeName]?.selectedData[fieldName]
                    : ""
                }
                control={control}
                rules={mixRules}
                name={fieldName}
                render={({ field }) => {
                  let InputFinal = (
                    <InputNumber
                      className="input-lg"
                      style={{
                        width: "100%"
                      }}
                      min={mixRules?.minLength?.value ?? 0}
                      max={mixRules?.maxLength?.value}
                      {...field}
                      key={`dynInput_${fieldName}`}
                      onChange={(e) => {
                        // console.log(e)
                        const val = e
                        // let execute = true
                        // if (
                        //   mixRules?.maxLength &&
                        //   val > mixRules?.maxLength?.value
                        // ) {
                        //   execute = false
                        // }

                        // if (
                        //   mixRules?.minLength &&
                        //   val < mixRules?.minLength?.value
                        // ) {
                        //   execute = false
                        // }

                        // if (execute) {
                        const valType = item?.valueType
                        if (val) {
                          if (valType !== "int" && valType === "str") {
                            field.onChange(`${val}`)
                          } else {
                            field.onChange(val)
                          }
                        } else {
                          if (valType !== "int" && valType === "str") {
                            field.onChange("")
                          } else {
                            field.onChange(0)
                          }
                          // }
                        }

                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      disabled={disabledInput}
                      invalid={errors[fieldName] && true}
                      {...defaultValueSet}
                      upHandler={<Plus />}
                      downHandler={<Minus />}
                    />
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : (
              renderDefInputViewToText(fieldName, defaultFormValues[fieldName])
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - PROGRESS
      else if (item["type"] === "progress") {
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            <Label className="" for={fieldName}>
              {t(fieldLabel ?? fieldName ?? "")}
              {(item.required || rules?.required) && (
                <span className="text-danger">*</span>
              )}{" "}
              <span
                className={
                  (tempSliderVal[fieldName] ?? defaultFormValues[fieldName]) <
                  10
                    ? "fw-bolder text-secondary"
                    : (tempSliderVal[fieldName] ??
                        defaultFormValues[fieldName]) < 30
                    ? "fw-bolder text-danger"
                    : (tempSliderVal[fieldName] ??
                        defaultFormValues[fieldName]) < 50
                    ? "fw-bolder text-warning"
                    : (tempSliderVal[fieldName] ??
                        defaultFormValues[fieldName]) < 70
                    ? "fw-bolder text-primary"
                    : (tempSliderVal[fieldName] ??
                        defaultFormValues[fieldName]) < 90
                    ? "fw-bolder text-info"
                    : (tempSliderVal[fieldName] ??
                        defaultFormValues[fieldName]) < 100
                    ? "fw-bolder text-success"
                    : "fw-bolder text-secondary"
                }
              >
                {tempSliderVal[fieldName] ?? defaultFormValues[fieldName]}{" "}
                {item?.displayValueSuffix}
              </span>
            </Label>
            {editOrViewState ? (
              <Controller
                defaultValue={
                  item?.isM2MDefValFromGrid
                    ? store.pageData[storeName].data.map((x) => x[fieldName])
                    : item?.initialValue
                    ? item?.initialValue
                    : store.pageData[storeName]?.selectedData
                    ? store.pageData[storeName]?.selectedData[fieldName]
                    : defaultFormValues[fieldName] ?? 0
                }
                control={control}
                rules={rules}
                name={fieldName}
                render={({ field }) => {
                  let InputFinal = (
                    <Fragment>
                      <Input
                        style={{
                          height: "8px"
                        }}
                        id="myinput"
                        type="range"
                        min="0"
                        max="100"
                        {...field}
                        key={`dynInput_${fieldName}`}
                        onChange={(e) => {
                          // console.log(e)
                          const val = e.target.value
                          const valType = item?.valueType
                          if (val !== "") {
                            if (valType === "int") {
                              field.onChange(parseInt(val))
                            } else {
                              field.onChange(val)
                            }
                          } else {
                            if (valType === "int") {
                              field.onChange(0)
                            } else {
                              field.onChange("")
                            }
                          }
                          let tempSliderValCopy = { ...tempSliderVal }
                          tempSliderValCopy[fieldName] = val ?? 0
                          setTempSliderVal(tempSliderValCopy)
                          afterChangeEvent(val, item)
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        }}
                        disabled={
                          item?.isDisabled ||
                          (filterMode &&
                            store.pageData[storeName]?.isLoading) ||
                          hiddenInput[fieldName] ||
                          pageType === "view"
                        }
                        list="steplist"
                        step="10"
                      />
                      <datalist id="steplist">
                        <option>0</option>
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                        <option>40</option>
                        <option>50</option>
                        <option>60</option>
                        <option>70</option>
                        <option>80</option>
                        <option>90</option>
                        <option>100</option>
                      </datalist>
                    </Fragment>
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : (
              <Progress
                key={`dynInput_${fieldName}`}
                value={defaultFormValues[fieldName]}
                style={{
                  // marginTop: "7px",
                  fontSize: "inherit",
                  height: "inherit"
                }}
                className={
                  defaultFormValues[fieldName] < 10
                    ? "progress-bar-secondary"
                    : defaultFormValues[fieldName] < 30
                    ? "progress-bar-danger"
                    : defaultFormValues[fieldName] < 50
                    ? "progress-bar-warning"
                    : defaultFormValues[fieldName] < 70
                    ? "progress-bar-primary"
                    : defaultFormValues[fieldName] < 90
                    ? "progress-bar-info"
                    : "progress-bar-success"
                }
              >
                {defaultFormValues[fieldName]}
                {item?.displayValueSuffix}
              </Progress>
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
        // eslint-disable-next-line brace-style
      }

      ////////////INPUT - RELATIONS-CRUD
      else if (item["type"] === "relationsCrud" && !filterMode) {
        if (item?.relationsCrud && pageType !== "add") {
          const relationsCrud_pageId =
            item?.relationsCrud?.pageId ?? item?.relationsCrud?.data[0]?.pageId
          const relationsCrud_pageAttr = item?.relationsCrud?.data
            ? item?.relationsCrud?.data[0]
            : item?.relationsCrud
          // console.log(relationsCrud_pageId)

          return (
            <Col
              key={`col_${fieldName}`}
              id={`col_${fieldName}`}
              className={classnames("mb-1", hideOnPageClassName)}
              {...colSizeFix}
            >
              <div
                key={`col_relationCrud_${fieldName ?? index}`}
                className={`${item?.className ?? "mt-2"}`}
              >
                {!resetSelectTable && (
                  <InputsRelationsCrud
                    // resetSelectTable={resetSelectTable}
                    initialPageId={relationsCrud_pageId}
                    initialPageAttr={relationsCrud_pageAttr}
                    pageType={
                      !item?.relationsCrud?.crudOpt?.disabledAdd
                        ? pageType
                        : "edit"
                    }
                    disableBtnAddFromParent={
                      // false
                      (item?.relationsCrud?.crudOpt?.disabledAdd ===
                        undefined ||
                        item?.relationsCrud?.crudOpt?.disabledAdd !== false) &&
                      disableBtnCondition(
                        store.pageAttributes[storeName]?.crudOpt,
                        defaultFormValues,
                        "add",
                        storeUnit?.data,
                        pageType,
                        store.pageAttributes[storeName]
                      )
                    }
                    disableBtnAddCurrent={disableBtnCondition(
                      item?.relationsCrud?.crudOpt,
                      defaultFormValues,
                      "add",
                      storeUnit?.data,
                      pageType,
                      store.pageAttributes[storeName]
                    )}
                    disableBtnEditFromParent={
                      (item?.relationsCrud?.crudOpt?.disabledEdit ===
                        undefined ||
                        item?.relationsCrud?.crudOpt?.disabledEdit !== false) &&
                      disableBtnCondition(
                        store.pageAttributes[storeName]?.crudOpt,
                        defaultFormValues,
                        "edit",
                        storeUnit?.data,
                        pageType,
                        store.pageAttributes[storeName]
                      )
                    }
                    disableBtnDeleteFromParent={
                      (item?.relationsCrud?.crudOpt?.disabledDelete ===
                        undefined ||
                        item?.relationsCrud?.crudOpt?.disabledDelete !==
                          false) &&
                      disableBtnCondition(
                        store.pageAttributes[storeName]?.crudOpt,
                        defaultFormValues,
                        "delete",
                        storeUnit?.data,
                        pageType,
                        store.pageAttributes[storeName]
                      )
                    }
                  />
                )}
              </div>
            </Col>
          )
        }
      }

      ////////////INPUT - SELECT-TABLE
      else if (item["type"] === "selectTable" && pageType === "add") {
        // if (item?.relationsCrud && pageType !== "add") {
        // return <InputsSelectTable />
        // }
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames("mb-1", hideOnPageClassName)}
            {...colSizeFix}
          >
            {/* {labelInput} */}
            {editOrViewState ? (
              <Controller
                control={control}
                rules={item.rules}
                name={fieldName}
                render={({ field, value }) => {
                  return (
                    <InputsSelectTable
                      // resetSelectTable={resetSelectTable}
                      item={item}
                      endPointName={getParamsApiUrlAttr(
                        item?.dataFrom?.api ??
                          item?.dataFrom?.tableOpt?.crudOpt?.endpointName,
                        item
                      )}
                      data={tempAsyncOptValues[fieldName]}
                      // onChange={handleChangeFilterFromChild}
                      onChange={(selectedValTable) => {
                        // console.log(selectedValTable)
                        field.onChange(selectedValTable)
                        setValue(fieldName, selectedValTable)
                        afterChangeEvent(selectedValTable, item)
                      }}
                    />
                  )
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                current_tempAsyncOptValues?.find((c) =>
                  c.value === !lockState
                    ? defaultFormValues[fieldName]
                    : userData?.id_employee
                )?.label
              )
            )}
          </Col>
        )
      }

      ////////////INPUT - ID_USER
      else if (item["type"] === "userData") {
        let username = userData?.username
        if (item?.keySelector) {
          if (
            [
              "username",
              "id",
              "email",
              "name",
              "id_employee",
              "name_employee"
            ].includes(item?.keySelector)
          ) {
            // if (userData?.[item?.keySelector]) {
            username = userData?.[item?.keySelector]
            // }
          }
        }
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames("mb-1", hideOnPageClassName)}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                defaultValue={defaultFormValues[fieldName] ?? username}
                rules={{ ...rules }}
                name={fieldName}
                render={({ field }) => {
                  let InputFinal = (
                    <Input
                      {...field}
                      defaultValue={defaultFormValues[fieldName] ?? username}
                      type={"text"}
                      key={`dynInput_${fieldName}`}
                      // value={username}
                      // value={value}
                      disabled={true}
                      invalid={errors[fieldName] && true}
                    />
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                defaultFormValues[fieldName] ?? username
              )
            )}
          </Col>
        )
      }

      ////////////INPUT - DOMAIN_CLAIM
      else if (item["type"] === "domainData") {
        const storeDomainData = storeDomain?.data
        let valueDomainData = storeDomainData?.app?.name
        const keySelector = item?.keySelector
        if (keySelector) {
          const isValueDeepKey2 = keySelector.includes(".") ?? false
          valueDomainData = isValueDeepKey2
            ? getDepthValue(storeDomainData, keySelector)
            : storeDomainData[keySelector]
        }
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames("mb-1", hideOnPageClassName)}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                defaultValue={defaultFormValues[fieldName] ?? valueDomainData}
                rules={{ ...rules }}
                name={fieldName}
                render={({ field }) => {
                  let InputFinal = (
                    <Input
                      {...field}
                      defaultValue={
                        defaultFormValues[fieldName] ?? valueDomainData
                      }
                      type={"text"}
                      key={`dynInput_${fieldName}`}
                      // value={username}
                      // value={value}
                      disabled={true}
                      invalid={errors[fieldName] && true}
                    />
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                defaultFormValues[fieldName] ?? valueDomainData
              )
            )}
          </Col>
        )
      }

      ////////////INPUT - CURRENT LOCATION
      else if (item["type"] === "current_location") {
        let valueDomainData = window.location.href
        if (item?.keySelector) {
          valueDomainData = window.location?.[item?.keySelector]
        }
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames("mb-1", hideOnPageClassName)}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                control={control}
                defaultValue={defaultFormValues[fieldName] ?? valueDomainData}
                rules={{ ...rules }}
                name={fieldName}
                render={({ field }) => {
                  let InputFinal = (
                    <Input
                      {...field}
                      defaultValue={
                        defaultFormValues[fieldName] ?? valueDomainData
                      }
                      type={"text"}
                      key={`dynInput_${fieldName}`}
                      // value={username}
                      // value={value}
                      disabled={true}
                      invalid={errors[fieldName] && true}
                    />
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                defaultFormValues[fieldName] ?? valueDomainData
              )
            )}
          </Col>
        )
      }

      // ////////////INPUT - TEXT & OTHERS
      else if (item["type"] === "password") {
        let defaultRules = {}

        // if (!rules?.pattern) {
        //   defaultRules = {
        //     ...defaultRules,
        //     pattern: {
        //       value: "/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/",
        //       message:
        //         "enter 6 - 16 character, have special characters and number"
        //     }
        //   }
        // }

        if (rules?.maxLength && !rules?.maxLength?.message) {
          let max = rules?.maxLength?.value ?? rules?.maxLength
          defaultRules = {
            ...defaultRules,
            maxLength: {
              value: max,
              message: `Max. length is ${max}`
            }
          }
        }

        if (rules?.minLength && !rules?.minLength?.message) {
          let min = rules?.minLength?.value ?? rules?.minLength
          defaultRules = {
            ...defaultRules,
            minLength: {
              value: min,
              message: `Min. length is ${min}`
            }
          }
        }

        if (pageType === "edit" && "required" in rules) {
          // rules.required = false
          delete rules?.required
        }

        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames("mb-1", hideOnPageClassName)}
            {...colSizeFix}
          >
            {/* {labelInput} */}
            {editOrViewState ? (
              <Controller
                defaultValue={
                  item?.defaultValue
                    ? item?.defaultValue
                    : item?.isM2MDefValFromGrid
                    ? store.pageData[storeName].data.map((x) => x[fieldName])
                    : item?.initialValue
                    ? item?.initialValue
                    : store.pageData[storeName]?.selectedData
                    ? store.pageData[storeName]?.selectedData[fieldName]
                      ? pageAttr?.isViewOnRow
                      : defaultFormValues[fieldName]
                    : ""
                }
                control={control}
                rules={{ ...rules, ...defaultRules }}
                name={fieldName}
                render={({ field }) => {
                  let InputFinal = (
                    // <InputPasswordToggle className='input-group-merge mb-2' label='Password' htmlFor='basic-default-password' />
                    <InputPasswordToggle
                      htmlFor={`dynInput_${fieldName}`}
                      {...field}
                      label={
                        !item?.inLineLabel
                          ? t(fieldLabel ?? fieldName ?? "")
                          : " "
                      }
                      id={`dynInput_${fieldName}`}
                      key={`dynInput_${fieldName}`}
                      autoComplete="new-password"
                      // autoFocus={item?.autoFocus}
                      onChange={(event) => {
                        // console.log(event.target.value)
                        const val = event.target.value
                        field.onChange(val)
                        afterChangeEvent(val, item)
                        onChangeAll()
                        onChangeAll2(item?.fieldName)
                      }}
                      onBlur={field.onBlur}
                      {...defaultValueSet}
                      disabled={disabledInput}
                      invalid={errors[fieldName] && true}
                    />
                  )

                  return InputFinal
                }}
              />
            ) : (
              renderDefInputViewToText(fieldName, defaultFormValues[fieldName])
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {item?.fieldDesc ? t(item?.fieldDesc) : ""}
              </FormText>
            )}
          </Col>
        )
      }

      ////////////INPUT - TEXT & OTHERS
      else {
        let defaultRules = {}
        if (item.type === "email") {
          defaultRules = {
            ...defaultRules,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address"
            }
          }
        }

        if (rules?.maxLength && !rules?.maxLength?.message) {
          let max = rules?.maxLength?.value ?? rules?.maxLength
          defaultRules = {
            ...defaultRules,
            maxLength: {
              value: max,
              message: `Max. length is ${max}`
            }
          }
        }

        if (rules?.minLength && !rules?.minLength?.message) {
          let min = rules?.minLength?.value ?? rules?.minLength
          defaultRules = {
            ...defaultRules,
            minLength: {
              value: min,
              message: `Min. length is ${min}`
            }
          }
        }

        let defaultValueText =
          defaultFormValues[fieldName] ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.initialValueFromOther ?? fieldName
          ) ??
          (item?.defaultValue
            ? item?.defaultValue
            : item?.isM2MDefValFromGrid
            ? store.pageData[storeName].data.map((x) => x[fieldName])
            : item?.initialValue
            ? item?.initialValue
            : store.pageData[storeName]?.selectedData
            ? store.pageData[storeName]?.selectedData[fieldName]
              ? pageAttr?.isViewOnRow
              : defaultFormValues[fieldName]
            : "")
        return (
          <Col
            key={`col_${fieldName}`}
            id={`col_${fieldName}`}
            className={classnames(hideOnPageClassName, {
              "mb-1": !item?.fieldDesc
            })}
            {...colSizeFix}
          >
            {labelInput}
            {editOrViewState ? (
              <Controller
                defaultValue={defaultValueText}
                control={control}
                rules={{ ...rules, ...defaultRules }}
                name={fieldName}
                render={({ field, fieldState }) => {
                  let InputFinal = (
                    <Input
                      {...field}
                      type={item?.type ?? "text"}
                      key={`dynInput_${fieldName}`}
                      autoComplete={
                        item.type === "password" ? "new-password" : "off"
                      }
                      // onChange={(e) =>
                      //   handleChangeInputs(
                      //     e.target.value,
                      //     fieldName,
                      //     item.isMultiSelection ?? false
                      //   )
                      // }
                      onChange={async (event) => {
                        const val = event.target.value
                        // console.log(val)
                        field.onChange(val)

                        if (filterMode) {
                          beforeHandleFiltersChangeToParent()
                        }

                        if (onlyInputMode) {
                          event.stopPropagation()
                          afterChangeEvent(val, item)
                          onChangeAll()
                          onChangeAll2(item?.fieldName)
                        } else {
                          await handleChangeInputs2(val, item, fieldState)
                        }
                      }}
                      /////19-05-2024
                      // onChange={async (event) => {
                      //   // event.preventDefault()
                      //   // console.log(event.target.value)
                      //   // console.log(fieldState)
                      //   // field.onChange(event)
                      //   // console.log("dsadsadsa")
                      //   // console.log(isDirty, isValid)

                      //   const val = event.target.value
                      //   field.onChange(val)
                      //   if (onlyInputMode) {
                      //     event.stopPropagation()
                      //     afterChangeEvent(val, item)
                      //     onChangeAll()
                      //     onChangeAll2(item?.fieldName)
                      //   } else {
                      //     await handleChangeInputs2(val, item, fieldState)
                      //     // await trigger(fieldName)
                      //     // console.log(fieldState, val)
                      //     // // setValue(field, val)
                      //     // if (
                      //     //   fieldState?.invalid === false &&
                      //     //   fieldState.isDirty === true &&
                      //     //   !errors[fieldName]
                      //     // ) {
                      //     //   item?.afterChangeGetValue
                      //     //     ? debouncedAfterChangeEvent(val, item)
                      //     //     : afterChangeEvent(val, item)

                      //     //   //
                      //     //   onChangeAll()
                      //     onChangeAll2(item?.fieldName)
                      //     // }
                      //   }
                      // }}
                      // onKeyUp={async (event) => {
                      //   const val = event.target.value
                      //   field.onChange(val)
                      //   await handleChangeInputs2(val, item, fieldState)
                      // }}
                      /////19-05-2024

                      placeholder={
                        item.placeholder
                          ? t(item.placeholder)
                          : onlyInputMode
                          ? t("Filter By ") + item?.columnName ??
                            item?.fieldLabel ??
                            item?.fieldName
                          : ""
                      }
                      {...onlyInputAttrEvent}
                      onBlur={field.onBlur}
                      {...defaultValueSet}
                      disabled={disabledInput}
                      invalid={errors[fieldName] && true}
                    />
                  )
                  if (item?.icon) {
                    return (
                      <InputGroup
                        className={classnames({
                          "is-invalid": errors[fieldName]
                        })}
                      >
                        <InputGroupText>
                          {renderIcon(item?.icon, "", 14)}
                        </InputGroupText>
                        {InputFinal}
                      </InputGroup>
                    )
                  } else {
                    return InputFinal
                  }
                }}
              />
            ) : (
              renderDefInputViewToText(
                fieldName,
                defaultFormValues[fieldName] ??
                  getDepthValue(
                    store.pageData[storeName]?.selectedData,
                    fieldName
                  )
              )
            )}
            {errors[item.fieldName] ? (
              <FormText color="danger">
                {t(errors[item.fieldName]?.message)}
              </FormText>
            ) : (
              <FormText className="text-muted">
                {fieldDesc[item?.fieldName] ??
                  (item?.fieldDesc ? t(item?.fieldDesc) : "")}
              </FormText>
            )}
          </Col>
        )
      }
    }
  }

  const initialValueFillFromOtherGenerate = async (item) => {
    if (
      (Array.isArray(defaultFormValues[item?.initialValueFillFromOther]) ||
        Object.keys(defaultFormValues[item?.initialValueFillFromOther])) &&
      item?.dataFrom?.resp_key === "data"
    ) {
      // console.log("initialValueFillFromOther")
      tempAsyncOptValues[item.fieldName] = await renderDropdownItem(item, {
        data: { data: defaultFormValues[item?.initialValueFillFromOther] }
      })
      setTempAsyncOptValues(tempAsyncOptValues)
      setLoadingInputs(item?.fieldName, false, true)
      setLoadingInputsCreate(item.fieldName, false)
      // console.log("here")
    } else if (
      !Array.isArray(defaultFormValues[item?.initialValueFillFromOther]) &&
      item?.dataFrom?.resp_key === "data"
    ) {
      tempAsyncOptValues[item.fieldName] = await renderDropdownItem(item, {
        data: {
          data: [
            {
              [item?.dataFrom?.field_label]:
                defaultFormValues[item?.initialValueFillFromOther],
              [item?.dataFrom?.field_value]: defaultFormValues[item?.fieldName]
            }
          ]
        }
      })
      // console.log("here2")
      setTempAsyncOptValues(tempAsyncOptValues)
      setLoadingInputs(item?.fieldName, false, true)
      setLoadingInputsCreate(item.fieldName, false)
    }
  }

  const initialValueFromOtherGenerate = async (item) => {
    if (defaultFormValues?.[item?.initialValueFromOther]) {
      // console.log("initialValueFromOther", item?.fieldName)
      const field_label = item?.dataFrom?.field_label
      const initialValueFromOther = Array.isArray(item?.initialValueFromOther)
        ? item.initialValueFromOther
        : [item.initialValueFromOther]
      let dropdownOptTemp = {}
      if (Array.isArray(field_label)) {
        if (initialValueFromOther?.length === 1) {
          const fieldLabelSelect =
            field_label[0] === item?.dataFrom?.field_value
              ? field_label[1]
              : field_label[0]
          dropdownOptTemp = {
            [fieldLabelSelect]: defaultFormValues[initialValueFromOther[0]],
            [item?.dataFrom?.field_value]: defaultFormValues[item.fieldName]
            // label: defaultFormValues[initialValueFromOther[0]],
            // value: defaultFormValues[item.fieldName],
          }
        } else {
          field_label.forEach((x, i) => {
            dropdownOptTemp[x] =
              defaultFormValues?.[initialValueFromOther[i]] ?? ""
          })
          dropdownOptTemp[item?.dataFrom?.field_value] =
            defaultFormValues?.[item.fieldName]
        }
      } else {
        dropdownOptTemp = {
          // label: defaultFormValues[initialValueFromOther[0]],
          // value: defaultFormValues[item.fieldName],
          [field_label]: defaultFormValues?.[initialValueFromOther[0]],
          [item?.dataFrom?.field_value]: defaultFormValues?.[item.fieldName]
        }
      }

      // console.log(dropdownOptTemp)
      if (item?.dataFrom?.resp_key === "data") {
        tempAsyncOptValues[item.fieldName] = await renderDropdownItem(item, {
          data: {
            data: [dropdownOptTemp]
          }
        })
      } else if (item?.dataFrom?.resp_key === "") {
        tempAsyncOptValues[item.fieldName] = await renderDropdownItem(item, {
          data: [dropdownOptTemp]
        })
      }

      setTempAsyncOptValues(tempAsyncOptValues)
      if (pageType !== "edit") {
        afterChangeEvent(defaultFormValues[item.fieldName], item, true)
      }
      setLoadingInputs(item?.fieldName, false, true)
      setLoadingInputsCreate(item.fieldName, false)
    }
  }

  // const [globalUnitDropdownForm, setGlobalUnitDropdownFormComp] = useState(null)

  const initInput = async (item) => {
    const fieldName = item?.fieldName
    // console.log(fieldName, defaultFormValues?.[fieldName])
    //////
    if (
      item?.type === "file_image" &&
      fieldName &&
      (defaultFormValues[fieldName] ||
        getDepthValue(
          store.pageData[storeName]?.selectedData,
          item?.initialValueFromOther
        ))
    ) {
      filesDefaultTemp[fieldName] =
        defaultFormValues[fieldName] ??
        getDepthValue(
          store.pageData[storeName]?.selectedData,
          item?.initialValueFromOther
        )
    } else if (
      item?.type === "color_picker" &&
      fieldName
      // &&
      // (defaultFormValues[fieldName] ||
      //   getDepthValue(
      //     store.pageData[storeName]?.selectedData,
      //     item?.initialValueFromOther
      //   )
      //   )
    ) {
      colorPickersTemp[fieldName] = {
        displayColorPicker: false,
        color:
          item?.defaultValue ??
          item?.initialValue ??
          defaultFormValues?.[fieldName] ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.initialValueFromOther
          ) ??
          ""
      }
    } else if (item["fieldName"] === "id_unit" && item?.type !== "dropdown") {
      // console.log(GlobalUnitDropdownForm)
    } else if (
      fieldName &&
      item["type"] === "dropdown" &&
      // (pageType === "edit" || pageType === "view") &&
      item?.dataFrom?.api &&
      (defaultFormValues?.[fieldName] ||
        defaultFormValues?.[item?.initialValueFromOther] ||
        getDepthValue(
          store.pageData[storeName]?.selectedData,
          item?.initialValueFromOther ?? item?.fieldName
        ))
    ) {
      // console.log(defaultFormValues?.[fieldName])
      console.log(`init dropdown ${fieldName} first`)
      tempAsyncOptValues[fieldName] = []

      const isAutoComplete =
        item?.dataFrom?.isAutoComplete ?? item?.isAutoComplete
      const hasInitialValueFill = item?.initialValueFillFromOther
      const initialValueFromOther = item?.initialValueFromOther
      const value =
        defaultFormValues[fieldName] ??
        getDepthValue(store.pageData[storeName]?.selectedData, fieldName)
      const initialValueFromOtherValue =
        defaultFormValues[initialValueFromOther] ??
        getDepthValue(
          store.pageData[storeName]?.selectedData,
          initialValueFromOther
        )

      if ((hasInitialValueFill || initialValueFromOther) && editForm) {
        // console.log("here,,,")
        if (hasInitialValueFill) {
          await initialValueFillFromOtherGenerate(item)
        } else {
          await initialValueFromOtherGenerate(item)
        }
      } else {
        if (isAutoComplete && initialValueFromOtherValue) {
          const autoValue = initialValueFromOtherValue.split(" ")[0]
          await loadDropdownFromApiAutoComplete(
            autoValue,
            item,
            true,
            true,
            true
          )
        } else {
          await loadDropdownFromApi(value, item, true, true, true)
        }
      }

      if (filterMode && checkAllRequiredFilled()) {
        console.log("dropdown type filter change")
        // setTimeout(() => {
        beforeHandleFiltersChangeToParent()
        // }, 30)
      }

      if (item?.dropdownAsTable || item?.dropdownAsTreeTable) {
        // dropdownAsTableModal[fieldName] = false
        const deffValue = tempAsyncOptValues[fieldName]?.find(
          (c) =>
            c.value === defaultFormValues?.[fieldName] ??
            getDepthValue(
              store.pageData[storeName]?.selectedData,
              item?.initialValueFromOther
            )
        )

        dropdownAsTableModal[fieldName] = {
          state: false,
          selected: deffValue
        }
      }
    } else if (item["type"] === "array") {
    } else if (
      item["type"] === "checkbox-multiselect" &&
      (item?.dataFrom?.value || item?.dataFrom?.api)
    ) {
      const valueCheckbox = ensureArray(
        item?.defaultValue ??
          defaultFormValues?.[fieldName] ??
          getDepthValue(
            store.pageData[storeName]?.selectedData,
            item?.initialValueFromOther ?? fieldName
          ) ??
          item?.initialValue ??
          []
      )
      // setSelectedValuesCheckbox(valueCheckbox)
      selectedValuesCheckbox[fieldName] = valueCheckbox
      if (item?.dataFrom?.value) {
        tempAsyncOptValues[fieldName] = item.dataFrom.value
        tempAsyncOptLoading[fieldName] = false
      } else if (item?.dataFrom?.api) {
        console.log(`init checkbox multi ${fieldName} first`)
        await loadDropdownFromApi(valueCheckbox, item, true, true, true)
      }

      if (filterMode && checkAllRequiredFilled()) {
        console.log("dropdown type filter change")
        // setTimeout(() => {
        beforeHandleFiltersChangeToParent()
        // }, 30)
      }
      // console.log(selectedValuesCheckbox)
      //  setTempAsyncOptValues(tempAsyncOptValues)
    } else if (fieldName && item["type"] === "id_employee") {
      const lockState =
        (userData?.id_employee && !userData?.isManager && !userData?.isHRD) ||
        item?.lockedState
      // console.log(userData?.isHRD && item?.lockedState)
      // console.log(`init id_employee ${fieldName} first`)
      if (lockState) {
        setLoadingInputs(item.fieldName, true, true)
        const employeeDefaultArr = [
          {
            label: userData?.name_employee ?? "Default",
            name: userData?.name_employee ?? "Default",
            value: userData?.id_employee,
            id: userData?.id_employee
          }
        ]
        tempAsyncOptValues[fieldName] = employeeDefaultArr
        setValue(fieldName, userData?.id_employee)
        dispatch(setDataCache({ [fieldName]: employeeDefaultArr }))

        if (filterMode && checkAllRequiredFilled()) {
          // console.log("id_employee type filter change")
          // setTimeout(() => {
          beforeHandleFiltersChangeToParent()
          // }, 30)
        }
        // afterChangeEvent(userData?.id_employee, item)
        // field.onChange(userData?.id_employee)
        // afterChangeEvent(currentUnit?.value)

        setLoadingInputs(item?.fieldName, false, true)
      } else {
        // console.log("here is")
        // initDefVal2(item)

        tempAsyncOptValues[fieldName] = []
        const isAutoComplete = item?.dataFrom?.isAutoComplete
        const hasInitialValueFill = item?.initialValueFillFromOther
        const initialValueFromOther = item?.initialValueFromOther
        const value = defaultFormValues[fieldName]

        // console.log(value)

        if ((hasInitialValueFill || initialValueFromOther) && editForm) {
          if (hasInitialValueFill) {
            await initialValueFillFromOtherGenerate(item)
          } else {
            await initialValueFromOtherGenerate(item)
          }
        } else {
          if (isAutoComplete && defaultFormValues?.[initialValueFromOther]) {
            const autoValue =
              defaultFormValues[initialValueFromOther].split(" ")[0]
            await loadDropdownFromApiAutoComplete(
              autoValue,
              item,
              true,
              true,
              true
            )
          } else {
            await loadDropdownFromApi("", item, true, true, true)
            // await loadDropdownFromApi(value, item, true, true, true)
          }
        }

        if (filterMode && checkAllRequiredFilled()) {
          // console.log("id_employee type filter change")
          // setTimeout(() => {
          beforeHandleFiltersChangeToParent()
          // }, 30)
        }
      }
    } else if (fieldName && item["type"] === "userData") {
      let currentValue = defaultFormValues[fieldName]
      let username = userData?.username
      if (item?.keySelector) {
        if (
          ["username", "id", "email", "name", "id_employee"].includes(
            item?.keySelector
          )
        ) {
          // if (userData?.[item?.keySelector]) {
          username = userData?.[item?.keySelector]

          // }
        }
      }
      if (!currentValue || currentValue === "") {
        setValue(fieldName, username)
        // setTimeout(() => {
        if (filterMode) {
          // console.log("userData type filter change")
          beforeHandleFiltersChangeToParent()
        }
        afterChangeEvent(username, item)
        // }, 30)
      }
    } else if (
      fieldName &&
      (item["type"] === "map-latlng" || item["type"] === "map-polygon")
    ) {
      let currentValue =
        defaultFormValues[fieldName] ??
        getDepthValue(
          store.pageData?.[storeName]?.selectedData,
          item?.initialValueFromOther
        ) ??
        getDepthValue(store.pageData?.[storeName]?.selectedData, fieldName) ??
        item?.defaultValue ??
        item?.initialValue ??
        (item["type"] === "map-polygon" ? [] : "")

      // if (!currentValue || currentValue === "") {
      //   console.log(
      //     typeof currentValue === "string" && currentValue.startsWith("[")
      //   )

      if (typeof currentValue === "string" && currentValue.includes(",")) {
        let mainMarker = item?.mapOtions?.mainMarker
        if (item?.mapOtherOpt?.calcDistanceFromMainMarker && mainMarker) {
          let coordinates = currentValue?.split(",")
          let latlng1 = L.latLng(coordinates[0], coordinates[1])
          let latlng2 = L.latLng(mainMarker[0], mainMarker[1])
          let distanceInMeters = latlng1.distanceTo(latlng2)
          let distanceInKilometers = distanceInMeters / 1000
          let roundedDistanceInKilometers = distanceInKilometers.toFixed(1)
          // console.log(`${roundedDistanceInKilometers} kilometers`)
          let mapDistanceFromMainCopy = { ...mapDistanceFromMain }
          mapDistanceFromMainCopy[item?.fieldName] = roundedDistanceInKilometers
          setMapDistanceFromMain(mapDistanceFromMainCopy)
          if (item?.mapOtherOpt?.calcDistanceFromMainMarker?.fieldName) {
            setValue(
              item?.mapOtherOpt?.calcDistanceFromMainMarker?.fieldName,
              roundedDistanceInKilometers
            )
          }
        }
      }

      if (
        item["type"] === "map-polygon" &&
        typeof currentValue === "string" &&
        currentValue.startsWith("[")
      ) {
        try {
          currentValue = JSON.parse(currentValue)
        } catch (error) {
          console.error("Gagal mengonversi 'coordinate' menjadi array:", error)
        }
      }

      let mapPositionCopy = { ...mapPosition }
      mapPositionCopy[fieldName] = currentValue
      // console.log(currentValue)
      setMapPosition(mapPositionCopy)

      // if (pageType !== "add") {
      let map_draw_opt = item?.map_draw_opt || pageAttr?.crudOpt?.map_draw_opt
      let isPolygon = true
      let defaultValueMap =
        defaultFormValues[fieldName] ??
        getDepthValue(
          store.pageData?.[storeName]?.selectedData,
          item?.initialValueFromOther
        ) ??
        getDepthValue(store.pageData?.[storeName]?.selectedData, fieldName) ??
        item?.defaultValue ??
        item?.initialValue ??
        ""

      let defaultValueMapFix = defaultValueMap !== "," ? defaultValueMap : null

      if (
        defaultValueMap &&
        defaultValueMap !== "," &&
        typeof defaultValueMap === "string" &&
        defaultValueMap.startsWith("[[")
      ) {
        defaultValueMapFix = [JSON.parse(defaultValueMap)]
      } else if (
        typeof defaultValueMap === "string" &&
        !defaultValueMap.startsWith("[[")
      ) {
        isPolygon = false
        const [lat, lon] = defaultValueMap?.split(",")?.map(parseFloat)
        if (!isNaN(lat) && !isNaN(lon)) {
          defaultValueMapFix = [lat, lon]
        }
      }

      // console.log(defaultValueMapFix, item?.fieldName?.replace(".", "_"))
      let defaultValueMapFixCopy = { ...defaultValueMapFixState }
      defaultValueMapFixCopy[item?.fieldName?.replace(".", "_")] = {
        defaultValueMapFix: defaultValueMapFix,
        isPolygon: isPolygon,
        ...item
      }
      setDefaultValueMapFixState(defaultValueMapFixCopy)
      // }

      setTimeout(() => {
        afterChangeEvent(currentValue, item)
      }, 30)
      // }
    } else if (fieldName && item["type"] === "code_editor") {
      let currentValue =
        defaultFormValues[fieldName] ??
        getDepthValue(
          store.pageData[storeName]?.selectedData,
          item?.initialValueFromOther
        )
      if (currentValue && currentValue !== "") {
        // console.log(currentValue)
        if (item?.code_language && item.code_language === "json") {
          let currentValueStr = currentValue
          if (item?.fromObject) {
            currentValueStr = JSON.stringify(currentValue, null, 2)
          }
          setValue(fieldName, currentValueStr)
          setTimeout(() => {
            afterChangeEvent(currentValueStr, item)
          }, 30)
        } else if (item?.code_language && item.code_language === "html") {
          let currentValueStr = currentValue
          if (item?.fromObject) {
            currentValueStr = processHtmlIndent(currentValue)
          }
          setValue(fieldName, currentValueStr)
          setTimeout(() => {
            afterChangeEvent(currentValueStr, item)
          }, 30)
        } else {
          setValue(fieldName, currentValue)
          setTimeout(() => {
            afterChangeEvent(currentValue, item)
          }, 30)
        }
      }
    } else if (fieldName && item["type"] === "editor") {
      let currentValue = defaultFormValues[fieldName]
      let editorState = EditorState.createEmpty()
      if (currentValue && currentValue !== "") {
        const contentBlock = htmlToDraft(currentValue)
        // console.log(contentBlock)
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          )
          editorState = EditorState.createWithContent(contentState)
        }
      }
      let editorValueCopy = { ...editorValue }
      editorValueCopy[fieldName] = editorState
      setEditorValue(editorValueCopy)
    } else if (item["type"] === "form-repeater" && item?.dataFrom?.api) {
      // console.log(fieldName)
      tempAsyncOptValues[fieldName] = []
      await loadDropdownFromApi("", item, true, true, true)
    }
    // else if (
    //   fieldName &&
    //   item["type"] === "selectTable" &&
    //   item?.dataFrom?.api
    // ) {
    //   tempAsyncOptValues[fieldName] = []
    //   await loadDropdownFromApi("", item, true, true, true)
    // }

    //////
    // console.log(item, item?.includeOnPayload === false)
    if (item?.includeOnPayload === false) {
      setRemovePayload([...removePayload, fieldName])
      removePayload.push(fieldName) //<---- diff children / not
    }

    showIfCondition(null)
    disabledIfCondition(null)
  }

  // const initDraft = (item, newDraft) => {
  //   let excludeType = [
  //     "id_unit",
  //     "dropdown",
  //     "userData",
  //     "password",
  //     "id_employee",
  //     "file_image"
  //   ]

  //   if (
  //     (!item?.type || !excludeType.includes(item?.type ?? "xx")) &&
  //     item?.fieldName !== "id_unit" &&
  //     !item?.defaultValue
  //   ) {
  //     let lastVal = newDraft?.[item?.fieldName]
  //     if (lastVal) {
  //       setValue(item?.fieldName, lastVal)
  //     }
  //   }
  // }

  const [isInitialized, setIsInitialized] = useState(false)
  useEffect(() => {
    // if (loadingFirstOnce.current === false) {
    // const updatedInputLabelRef = {}

    // GET DRAFT VALUES ON ADD
    // let newDraft = null
    // if (pageType === "add") {
    //   let lastDraftDataStore =
    //     sessionStorage.getItem("form_add_draft") ?? "[]"
    //   let lastDraftData = JSON.parse(lastDraftDataStore)

    //   let location = window.location.pathname

    //   let indexDraft = lastDraftData.findIndex(
    //     (item) => item.id === pageId || item.location === location
    //   )

    //   if (indexDraft !== -1) {
    //     newDraft = lastDraftData[indexDraft]?.values
    //   }
    // }

    if (!dynamicForm || dynamicForm?.length === 0) {
      setIsInitialized(true)
    }

    dynamicForm.forEach(async (item, index) => {
      if (
        (item?.type === "column" || item?.type === "form-repeater") &&
        item?.children
      ) {
        await item?.children.map(async (item_child) => {
          // inputLabelRef[item?.fieldName] = useRef(null)
          await initInput(item_child)
          // if (pageType === "add" && newDraft) {
          //   initDraft(item_child, newDraft)
          // }
        })
      } else if (item?.type === "tabs" && item?.children && !filterMode) {
        await item?.children.map(async (item_childs, index_childs) => {
          if (index_childs === 0) {
            setActiveTab(
              `${storeName}_tab${index}_menu_${
                item?.pageId ?? index_childs
              }_${index_childs}`
            )
          }
          await item_childs?.tabContent?.map(async (item_child) => {
            // inputLabelRef[item?.fieldName] = useRef(null)
            await initInput(item_child)
            // if (pageType === "add" && newDraft) {
            //   initDraft(item_child, newDraft)
            // }
          })
        })
      } else {
        await initInput(item)
        // if (pageType === "add" && newDraft) {
        //   initDraft(item, newDraft)
        // }
      }

      if (item?.autoFocus) {
        setTimeout(() => {
          const targetInput = document.querySelector(
            `input[name="${item?.fieldName}"]`
          )
          // console.log(targetInput)
          if (targetInput) {
            targetInput.focus()
          }
          // setFocus(item?.fieldName)
        }, 300)
      }

      // finishInit = index + 1
    })

    setIsInitialized(true)
    if (isInitialized) {
      const refs = dynamicForm.reduce((acc, item) => {
        acc[item.fieldName] = item.fieldLabel
        return acc
      }, {})
      setInputLabelRef(refs)
      // loadingFirstOnce.current = true
    }
    // }
    // Prism.highlightAll()
  }, [])

  useEffect(() => {
    if (isInitialized) {
      if (mapRefPolygon.current) {
        if (defaultValueMapFixState) {
          Object.keys(defaultValueMapFixState)?.map((key) => {
            const item = defaultValueMapFixState[key]
            let isPolygon = item?.isPolygon
            const fieldName = item?.fieldName?.replace(".", "_")
            let defaultValueMapFix =
              defaultValueMapFixState?.[fieldName]?.defaultValueMapFix
            let mapOpt = item?.mapOtions ?? {}
            let mapOtherOpt = item?.mapOtherOpt ?? {}
            if (!defaultValueMapFix && pageType === "add") {
              if (
                geoLocation?.coordinates?.lat &&
                geoLocation?.coordinates?.lng &&
                !mapOtherOpt?.imageOverlay
              ) {
                mapRefPolygon.current?.setView(
                  [geoLocation.coordinates.lat, geoLocation.coordinates.lng],
                  mapDefaultOpt?.zoom
                )
              } else {
                if (!mapOpt?.mainMarker) {
                  if (!mapOtherOpt?.imageOverlay) {
                    mapDefaultOpt["center"] = mapOpt?.center ?? mapCenterDefault
                    mapRefPolygon.current.setZoom(mapOpt?.center ? 19 : 7)
                  } else {
                    const imageBounds = mapOtherOpt?.imageOverlay?.imgBound ??
                      mapOpt?.bounds ?? [
                        [40.7937721704678, -74.12398338317873],
                        [40.68581858921337, -74.23092842102052]
                      ]
                    mapRefPolygon.current.fitBounds(imageBounds)
                  }
                } else {
                  mapRefPolygon.current?.setView(
                    mapOpt.mainMarker,
                    mapDefaultOpt?.zoom
                  )
                }
              }
            } else {
              if (defaultValueMapFix && defaultValueMapFix !== "") {
                if (isPolygon) {
                  if (!mapOtherOpt?.imageOverlay) {
                    const boundss = getBoundsFromMarkers(defaultValueMapFix)
                    mapRefPolygon.current.fitBounds(boundss)
                    mapRefPolygon.current.setZoom(17)
                  } else {
                    const imageBounds = mapOtherOpt?.imageOverlay?.imgBound ??
                      mapOpt?.bounds ?? [
                        [40.7937721704678, -74.12398338317873],
                        [40.68581858921337, -74.23092842102052]
                      ]
                    mapRefPolygon.current.fitBounds(imageBounds)
                    mapRefPolygon.current.setZoom(mapOpt?.zoom ?? 13)
                  }
                } else {
                  try {
                    mapRefPolygon.current?.setView(
                      defaultValueMapFix,
                      mapOpt?.zoom ?? 15
                    )
                  } catch (error) {
                    console.error(defaultValueMapFix)
                  }
                }
              }
            }
          })
        } else {
        }
      }
    }
  }, [mapRefPolygon.current, isInitialized])

  //////COMPONENTS
  const renderSubmitBtn = (isFromWizard) => {
    if (
      !disableBtnCondition(
        pageAttr?.crudOpt,
        defaultFormValues,
        pageType,
        storeUnit.data,
        pageType,
        pageAttr
      )
    ) {
      let isContainMapInput = dynamicForm.some(
        (ff) => ff.type === "map-latlng" || ff?.type === "map-polygon"
      )
      // console.log(isContainMapInput)
      return (
        <Button
          disabled={(!isDirty || !isValid) && !isContainMapInput}
          type="submit"
          className={isFromWizard ? "ms-1" : "me-1"}
          color={isFromWizard ? "success" : "primary"}
          {...(pageAttr?.crudOpt?.btnSubmitProp ?? {})}
          key={`btn_form_submit_${storeName}`}
          id={`btn_form_submit_${storeName}`}
          form={`form_${storeName}`}
        >
          {pageAttr?.crudOpt?.btnSubmitIcon ? (
            <Fragment>
              {renderIcon(pageAttr?.crudOpt?.btnSubmitIcon, "mx-25", 14)}
              <span className="align-middle">
                {pageAttr?.crudOpt?.btnSubmitText ?? t("Submit")}
              </span>
            </Fragment>
          ) : (
            pageAttr?.crudOpt?.btnSubmitText ?? t("Submit")
          )}
        </Button>
      )
    }
  }

  const renderCancelBtn = () => {
    return (
      <Button
        type="reset"
        color="secondary"
        outline
        {...(pageAttr?.crudOpt?.btnCancelProp ?? {})}
        onClick={() => {
          handleReset()
        }}
      >
        {pageAttr?.crudOpt?.btnCancelIcon ? (
          <Fragment>
            {renderIcon(pageAttr?.crudOpt?.btnCancelIcon, "mx-25", 14)}
            <span className="align-middle">
              {pageAttr?.crudOpt?.btnCancelText ??
                (pageType === "edit" ? t("Back") : t("Cancel"))}
            </span>
          </Fragment>
        ) : (
          pageAttr?.crudOpt?.btnCancelText ??
          (pageType === "edit" ? t("Back") : t("Cancel"))
        )}
      </Button>
    )
  }

  const renderForm = (hideFooter) => {
    return (
      <Form
        autoComplete="off"
        id={`form_${storeName}`}
        key={`form_${storeName}`}
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()
          beforeHandleFormSubmit(getValues())
        }}
      >
        <Row className="">
          {dynamicForm.map((item, index) => {
            const fieldName = item["fieldName"]
            formDatas[fieldName] = null
            formDatasError[fieldName] = false
            const colSizeFix = {
              md: item?.size ?? true,
              xs: 12
            }

            const fieldNameGenerate =
              item?.type === "separator" ||
              item?.type === "column" ||
              item?.type === "tabs"
                ? `${item.type}_${index}`
                : ""
            const hideOnPageClassName = {
              hidden:
                hiddenInput[fieldName ?? ""] === true ||
                hiddenInput[fieldNameGenerate] === true ||
                item?.isHidden ||
                item?.hideOnPage?.[pageType ?? useParams()["pageType"]] === true
            }
            if (item["type"] === "column" && item?.children?.length) {
              if (item?.cardOpt) {
                const children = (
                  <CardBody
                    style={
                      skin !== "dark"
                        ? item.cardOpt?.style?.cardBody
                        : item.cardOpt?.style?.cardBodyDark ??
                          item.cardOpt?.style?.cardBody
                    }
                    className={
                      skin !== "dark"
                        ? item.cardOpt?.className?.cardBody
                        : item.cardOpt?.className?.cardBodyDark ??
                          item.cardOpt?.className?.cardBody
                    }
                  >
                    <Row>
                      {item?.children.map((item_child, index_child) => {
                        return renderInput(item_child, index_child)
                      })}
                    </Row>
                  </CardBody>
                )
                return (
                  <Col
                    className={`${classnames(
                      `mb-1 input_column_type ${item?.className ?? ""}`,
                      hideOnPageClassName
                    )}`}
                    key={`col_${index}_`}
                    id={`col_${fieldName ?? `${index}_`}`}
                    {...colSizeFix}
                  >
                    {item?.fieldLabel ? (
                      <CardAction2
                        style={
                          skin !== "dark"
                            ? item.cardOpt?.style?.card
                            : item.cardOpt?.style?.cardDark ??
                              item.cardOpt?.style?.card
                        }
                        color={
                          skin !== "dark"
                            ? item.cardOpt?.color
                            : item.cardOpt?.colorDark ?? item.cardOpt?.color
                        }
                        actions={["collapse"]}
                        inverse
                        defaultExpanded={item.cardOpt?.defaultExpanded}
                        className={classnames(
                          "saba-card-form mt-75 mb-0",
                          {
                            "bg-transparent":
                              !item.cardOpt?.className?.card?.includes("bg-") &&
                              !item.cardOpt?.color,
                            "text-white saba-card-form-color":
                              (item.cardOpt?.className?.card?.includes("bg-") &&
                                !item.cardOpt?.className?.card?.includes(
                                  "bg-transparent"
                                )) ||
                              (item.cardOpt?.color &&
                                !item.cardOpt?.color.includes("light-")),
                            "border-card-form":
                              !item.cardOpt?.className?.card?.includes(
                                "border-"
                              ),
                            "shadow-none":
                              !item.cardOpt?.className?.card?.includes(
                                "shadow-"
                              )
                          },
                          item?.cardOpt?.className?.card
                        )}
                        titleEl={
                          <CardTitle
                            style={{
                              fontSize: "0.9375rem",
                              fontWeight: "bold",
                              ...(skin !== "dark"
                                ? item.cardOpt?.style?.cardTitle
                                : item.cardOpt?.style?.cardTitleDark ??
                                  item.cardOpt?.style?.cardTitle)
                            }}
                            className={classnames(
                              "d-flex align-items-center",
                              {
                                "text-white":
                                  (item.cardOpt?.className?.cardTitle?.includes(
                                    "bg-"
                                  ) &&
                                    !item.cardOpt?.className?.cardTitle?.includes(
                                      "bg-transparent"
                                    )) ||
                                  (item.cardOpt?.color &&
                                    !item.cardOpt?.style?.cardTitle?.color &&
                                    !item.cardOpt?.color.includes("light-"))
                              },
                              item.cardOpt?.className?.cardTitle
                            )}
                            tag="h4"
                          >
                            {item?.icon ? (
                              <Fragment>
                                {renderIcon(
                                  item?.icon,
                                  `me-50 ${item?.iconClass ?? ""}`,
                                  item?.iconSize,
                                  {
                                    ...(skin !== "dark"
                                      ? item.cardOpt?.style?.cardTitle
                                      : item.cardOpt?.style?.cardTitleDark ??
                                        item.cardOpt?.style?.cardTitle)
                                  }
                                )}
                                <span> {item.fieldLabel}</span>
                              </Fragment>
                            ) : (
                              <Fragment>{item.fieldLabel}</Fragment>
                            )}
                          </CardTitle>
                        }
                      >
                        {children}
                      </CardAction2>
                    ) : (
                      <Card
                        style={
                          skin !== "dark"
                            ? item.cardOpt?.style?.card
                            : item.cardOpt?.style?.cardDark ??
                              item.cardOpt?.style?.card
                        }
                        color={
                          skin !== "dark"
                            ? item.cardOpt?.color
                            : item.cardOpt?.colorDark ?? item.cardOpt?.card
                        }
                        actions={["collapse"]}
                        inverse
                        className={classnames(
                          "saba-card-form mt-75 mb-0",
                          {
                            "bg-transparent":
                              !item.cardOpt?.className?.card?.includes("bg-") &&
                              !item.cardOpt?.color,
                            "text-white saba-card-form-color":
                              (item.cardOpt?.className?.card?.includes("bg-") &&
                                !item.cardOpt?.className?.card?.includes(
                                  "bg-transparent"
                                )) ||
                              (item.cardOpt?.color &&
                                !item.cardOpt?.color.includes("light-")),
                            "border-card-form":
                              !item.cardOpt?.className?.card?.includes(
                                "border-"
                              ),
                            "shadow-none":
                              !item.cardOpt?.className?.card?.includes(
                                "shadow-"
                              )
                          },
                          item?.cardOpt?.className?.card
                        )}
                      >
                        {children}
                      </Card>
                    )}
                  </Col>
                )
              } else {
                return (
                  <Col
                    className={classnames(
                      `mb-1 input_column_type ${item?.className ?? ""}`,
                      hideOnPageClassName
                    )}
                    key={`col_${index}_`}
                    id={`col_${fieldName ?? `${index}_`}`}
                    {...colSizeFix}
                  >
                    <Row>
                      {item?.children.map((item_child, index_child) => {
                        return renderInput(item_child, index_child)
                      })}
                    </Row>
                  </Col>
                )
              }
            } else if (
              item["type"] === "tabs" &&
              item?.children?.length &&
              !filterMode
            ) {
              return (
                (pageType === "edit" || pageType === "view") && (
                  <Col
                    key={`col_tab_${fieldName ?? `${index}`}`}
                    id={`col_${fieldName ?? `${index}`}`}
                    className={classnames("mb-1", hideOnPageClassName)}
                    {...colSizeFix}
                  >
                    <div
                      className={classnames({
                        "nav-vertical":
                          item?.tabVertical && window.innerWidth > 992
                      })}
                    >
                      <Nav
                        tabs
                        fill={
                          !item?.tabVertical && item?.tabMenuAlign === "fill"
                        }
                        justified={
                          !item?.tabVertical &&
                          ((item?.tabMenuAlign === "justified" ||
                            !item?.tabMenuAlign) ??
                            true)
                        }
                        className={classnames(`${item?.className}`, {
                          "justify-content-center":
                            !item?.tabVertical &&
                            item?.tabMenuAlign === "center",
                          "justify-content-start":
                            !item?.tabVertical && item?.tabMenuAlign === "left",
                          "justify-content-end":
                            !item?.tabVertical &&
                            item?.tabMenuAlign === "right",
                          "nav-left":
                            item?.tabVertical &&
                            (!item?.tabMenuAlign ||
                              item?.tabMenuAlign === "left") &&
                            window.innerWidth > 992,
                          "nav-right":
                            item?.tabVertical && item?.tabMenuAlign === "right"
                        })}
                      >
                        {item?.children.map((item_child, index_child) => {
                          return (
                            <NavItem
                              key={`${storeName}_${
                                item?.pageId ?? index_child
                              }_tab${index}_nav_${index_child}`}
                            >
                              <NavLink
                                active={
                                  activeTab ===
                                  `${storeName}_tab${index}_menu_${
                                    item?.pageId ?? index_child
                                  }_${index_child}`
                                }
                                onClick={() => {
                                  toggleTab(
                                    `${storeName}_tab${index}_menu_${
                                      item?.pageId ?? index_child
                                    }_${index_child}`,
                                    item_child
                                  )
                                }}
                              >
                                {item_child?.tabIcon || item_child?.icon
                                  ? renderIcon(
                                      item_child?.tabIcon ?? item_child?.icon,
                                      "mx-1"
                                    )
                                  : null}
                                <span className="align-middle">
                                  {t(item_child?.tabTitle ?? "")}
                                </span>
                              </NavLink>
                            </NavItem>
                          )
                        })}
                      </Nav>

                      <TabContent activeTab={activeTab}>
                        {item?.children.map((item_childs, index_childs) => {
                          return (
                            <TabPane
                              key={`${storeName}_tab${index}_menu_${
                                item?.pageId ?? index_childs
                              }_${index_childs}`}
                              tabId={`${storeName}_tab${index}_menu_${
                                item?.pageId ?? index_childs
                              }_${index_childs}`}
                            >
                              <Row className="m-25">
                                {/* {index_childs === 0
                              ? item_childs?.tabContent?.map(
                                  (item_child, index_child) => {
                                    return renderInput(
                                      item_child,
                                      index_child
                                    )
                                  }
                                )
                              : null} */}
                                {item_childs?.tabContent?.map(
                                  (item_child, index_child) => {
                                    return renderInput(item_child, index_child)
                                  }
                                )}
                              </Row>
                            </TabPane>
                          )
                        })}
                      </TabContent>
                    </div>
                  </Col>
                )
              )
            } else if (
              item["type"] === "form-repeater" &&
              item?.children?.length
            ) {
              if (!editForm) {
                return (
                  <Col
                    key={`col_tab_${fieldName ?? `${index}`}`}
                    id={`col_${fieldName ?? `${index}_`}`}
                    className="mb-1"
                    {...colSizeFix}
                  >
                    <Repeater count={count}>
                      {(i) => {
                        const Tag = i === 0 ? "div" : SlideDown
                        return (
                          <Tag
                            className={`justify-content-between align-items-center rounded ${
                              count > 1 ? "form-repeater-item mb-3" : ""
                            }`}
                            key={i}
                          >
                            <Row className="justify-content-between align-items-center mx-25">
                              <Col
                                style={{ height: "8px" }}
                                sm={12}
                                key={`col_form_repeater_delete_${fieldName}_${i}`}
                                className="d-flex justify-content-between align-items-center"
                              >
                                <h5 className="modal-title"></h5>
                                <Button
                                  style={{
                                    opacity: i === 0 ? 0 : 1,
                                    padding: "0.2rem",
                                    transform: "translate(14px, -3px)"
                                  }}
                                  size="sm"
                                  disabled={i === 0}
                                  color={i === 0 ? "secondary" : "danger"}
                                  className=""
                                  onClick={deleteForm}
                                >
                                  <X size={14} />
                                </Button>
                              </Col>
                              {item?.children.map((item_child) => {
                                let item_child_edit = { ...item_child }
                                item_child_edit.fieldName = `${item_child.fieldName}__${i}`
                                return renderInput(item_child_edit)
                              })}
                            </Row>
                          </Tag>
                        )
                      }}
                    </Repeater>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                      {
                        <FormText className="text-muted me-25 align-middle">
                          {count > 1
                            ? `${count} ${
                                item?.fieldLabel ?? pageAttr?.pageTitle
                              } in Form`
                            : ""}
                        </FormText>
                      }
                      <Button
                        color="flat-secondary"
                        className="btn-icon"
                        onClick={increaseCount}
                      >
                        <Plus size={14} />
                        <span className="align-middle ms-25">
                          {t("Add Other")}
                        </span>
                      </Button>
                    </div>
                  </Col>
                )
              } else {
                return (
                  <Fragment>
                    {item?.children.map((child, index_child) => {
                      return renderInput(child, index_child)
                    })}
                  </Fragment>
                )
              }
            } else {
              return renderInput(item, index)
            }
          })}

          {!hideFooter && !hideAllFotterButton && (
            <Col
              xs={12}
              className="text-center mt-25 pt-50"
              {...(pageAttr?.crudOpt?.footerProp ?? {})}
            >
              {renderSubmitBtn()}

              {pageAttr?.crudOpt?.saveAsNewBtn && pageType !== "add" && (
                <Button
                  disabled={!isValid}
                  // outline
                  className="me-1"
                  color="secondary"
                  key={`btn_form_save_as_${storeName}`}
                  id={`btn_form_save_as_${storeName}`}
                  onClick={() => {
                    beforeHandleFormSubmit(getValues(), null, null, true)
                  }}
                >
                  {t("Save As New")}
                </Button>
              )}

              {showDeleteButton && (
                <Button
                  color="danger"
                  className="me-1"
                  onClick={() => {
                    handleDelete(defaultFormValues["id"])
                  }}
                >
                  {t("Delete")}
                </Button>
              )}

              {renderCancelBtn()}
            </Col>
          )}
        </Row>
      </Form>
    )
  }

  const renderBtnEditViewMode = (isFromWizard) => {
    if (
      !disableBtnCondition(
        pageAttr?.crudOpt,
        defaultFormValues,
        "edit",
        storeUnit.data,
        pageType,
        pageAttr,
        true
      ) &&
      ability.can("update", sessionStorage.getItem("current_page_perm"))
    ) {
      return (
        <Button
          type="button"
          outline
          onClick={() => {
            const url = location.pathname.replace("/view/", "/edit/")
            navigate(url)
          }}
          className="me-1 mt-25"
          color="secondary"
        >
          <Edit size={14} />
          <span className="align-middle ms-25">{t("Edit")}</span>
        </Button>
      )
    }
  }

  const renderBtnCancelViewMode = (isFromWizard) => {
    if (
      pageAttr?.pageType !== "detail-page" &&
      pageAttr?.crudOpt?.detailPage &&
      !pageAttr?.isViewOnForm
    ) {
      return (
        <Button
          type="button"
          color="secondary"
          outline
          className="mt-25"
          onClick={() => {
            // navigate(-1)
            // handleReset()
            dispatch(resetSelectedData(storeName))
            if (searchParams.get("isOrigin") === "true") {
              return navigate(-1)
            } else {
              const url1 = location.pathname.slice(
                0,
                location.pathname.lastIndexOf("/")
              )
              const url2 = url1.slice(0, url1.lastIndexOf("/"))
              return navigate(url2)
            }
          }}
        >
          <ArrowLeft size={14} />
          <span className="align-middle ms-25">{t("Back")}</span>
        </Button>
      )
    }
  }

  const renderFormViewMode = (hideFooter) => {
    return (
      <Form
        id={`form_${storeName}`}
        key={`form_${storeName}`}
        autoComplete="off"
      >
        <Row className="">
          {dynamicForm.map((item, index) => {
            // const hideOnPageClassName = {
            //   hidden:
            //     // hiddenInput[fieldName ?? ""] === true ||
            //     // hiddenInput[fieldNameGenerate] === true ||
            //     item?.isHidden ||
            //     item?.hideOnPage?.[pageType ?? useParams()["pageType"]] ===
            //       true
            // }

            const fieldName = item["fieldName"]
            formDatas[fieldName] = null
            formDatasError[fieldName] = false
            const colSize = {
              md: item?.size ?? true,
              xs: 12
            }

            const colSizeFix = {
              md: item?.size ?? true,
              xs: 12
            }

            const fieldNameGenerate =
              item?.type === "separator" ||
              item?.type === "column" ||
              item?.type === "tabs"
                ? `${item.type}_${index}`
                : ""
            const hideOnPageClassName = {
              hidden:
                hiddenInput[fieldName ?? ""] === true ||
                hiddenInput[fieldNameGenerate] === true ||
                item?.isHidden ||
                item?.hideOnPage?.[pageType ?? useParams()["pageType"]] === true
            }

            if (item["type"] === "column" && item?.children) {
              if (item?.cardOpt) {
                const children = (
                  <CardBody
                    style={
                      skin !== "dark"
                        ? item.cardOpt?.style?.cardBody
                        : item.cardOpt?.style?.cardBodyDark ??
                          item.cardOpt?.style?.cardBody
                    }
                    className={
                      skin !== "dark"
                        ? item.cardOpt?.className?.cardBody
                        : item.cardOpt?.className?.cardBodyDark ??
                          item.cardOpt?.className?.cardBody
                    }
                  >
                    <Row>
                      {item?.children.map((item_child, index_child) => {
                        return renderInput(item_child, index_child)
                      })}
                    </Row>
                  </CardBody>
                )
                return (
                  <Col
                    className={`${classnames(
                      `mb-1 input_column_type ${item?.className ?? ""}`,
                      hideOnPageClassName
                    )}`}
                    key={`col_${index}_`}
                    id={`col_${fieldName ?? `${index}_`}`}
                    is_input_group="true"
                    {...colSizeFix}
                  >
                    {item?.fieldLabel ? (
                      <CardAction2
                        style={
                          skin !== "dark"
                            ? item.cardOpt?.style?.card
                            : item.cardOpt?.style?.cardDark ??
                              item.cardOpt?.style?.card
                        }
                        color={
                          skin !== "dark"
                            ? item.cardOpt?.color
                            : item.cardOpt?.colorDark ?? item.cardOpt?.card
                        }
                        actions={["collapse"]}
                        inverse
                        defaultExpanded={item.cardOpt?.defaultExpanded ?? true}
                        className={classnames(
                          "saba-card-form mt-75 mb-0",
                          {
                            "bg-transparent":
                              !item.cardOpt?.className?.card?.includes("bg-") &&
                              !item.cardOpt?.color,
                            "text-white saba-card-form-color":
                              (item.cardOpt?.className?.card?.includes("bg-") &&
                                !item.cardOpt?.className?.card?.includes(
                                  "bg-transparent"
                                )) ||
                              (item.cardOpt?.color &&
                                !item.cardOpt?.color.includes("light-")),
                            "border-card-form":
                              !item.cardOpt?.className?.card?.includes(
                                "border-"
                              ),
                            "shadow-none":
                              !item.cardOpt?.className?.card?.includes(
                                "shadow-"
                              )
                          },
                          item?.cardOpt?.className?.card
                        )}
                        titleEl={
                          <CardTitle
                            style={{
                              fontSize: "0.9375rem",
                              fontWeight: "bold",
                              ...(skin !== "dark"
                                ? item.cardOpt?.style?.cardTitle
                                : item.cardOpt?.style?.cardTitleDark ??
                                  item.cardOpt?.style?.cardTitle)
                            }}
                            className={classnames(
                              "d-flex align-items-center",
                              {
                                "text-white":
                                  (item.cardOpt?.className?.cardTitle?.includes(
                                    "bg-"
                                  ) &&
                                    !item.cardOpt?.className?.cardTitle?.includes(
                                      "bg-transparent"
                                    )) ||
                                  (item.cardOpt?.color &&
                                    !item.cardOpt?.style?.cardTitle?.color &&
                                    !item.cardOpt?.color.includes("light-"))
                              },
                              item.cardOpt?.className?.cardTitle
                            )}
                            tag="h4"
                          >
                            {item?.icon ? (
                              <Fragment>
                                {renderIcon(
                                  item?.icon,
                                  `me-50 saba-pulse ${item?.iconClass ?? ""}`,
                                  item?.iconSize,
                                  {
                                    ...(skin !== "dark"
                                      ? item.cardOpt?.style?.cardTitle
                                      : item.cardOpt?.style?.cardTitleDark ??
                                        item.cardOpt?.style?.cardTitle)
                                  }
                                )}
                                <span> {item.fieldLabel}</span>
                              </Fragment>
                            ) : (
                              <Fragment>{item.fieldLabel}</Fragment>
                            )}
                          </CardTitle>
                        }
                      >
                        {children}
                      </CardAction2>
                    ) : (
                      <Card
                        style={
                          skin !== "dark"
                            ? item.cardOpt?.style?.card
                            : item.cardOpt?.style?.cardDark ??
                              item.cardOpt?.style?.card
                        }
                        color={
                          skin !== "dark"
                            ? item.cardOpt?.color
                            : item.cardOpt?.colorDark ?? item.cardOpt?.card
                        }
                        actions={["collapse"]}
                        inverse
                        className={classnames(
                          "saba-card-form mt-75 mb-0",
                          {
                            "bg-transparent":
                              !item.cardOpt?.className?.card?.includes("bg-") &&
                              !item.cardOpt?.color,
                            "text-white saba-card-form-color":
                              (item.cardOpt?.className?.card?.includes("bg-") &&
                                !item.cardOpt?.className?.card?.includes(
                                  "bg-transparent"
                                )) ||
                              (item.cardOpt?.color &&
                                !item.cardOpt?.color.includes("light-")),
                            "border-card-form":
                              !item.cardOpt?.className?.card?.includes(
                                "border-"
                              ),
                            "shadow-none":
                              !item.cardOpt?.className?.card?.includes(
                                "shadow-"
                              )
                          },
                          item?.cardOpt?.className?.card
                        )}
                      >
                        {children}
                      </Card>
                    )}
                  </Col>
                )
              } else {
                return (
                  <Col
                    className={classnames(
                      `mb-1 input_column_type ${item?.className ?? ""}`,
                      hideOnPageClassName
                    )}
                    key={`col_${index}_`}
                    id={`col_${fieldName ?? `${index}_`}`}
                    {...colSizeFix}
                  >
                    <Row>
                      {item?.children.map((item_child, index_child) => {
                        return renderInput(item_child, index_child)
                      })}
                    </Row>
                  </Col>
                )
              }
            } else if (item["type"] === "tabs" && item?.children?.length) {
              return (
                (pageType === "edit" || pageType === "view") && (
                  <Col
                    key={`col_tab_${fieldName ?? `${index}`}`}
                    className={classnames("mb-1", hideOnPageClassName)}
                    {...colSize}
                  >
                    <div
                      className={classnames({
                        "nav-vertical": item?.tabVertical
                      })}
                    >
                      <Nav
                        tabs
                        fill={
                          !item?.tabVertical && item?.tabMenuAlign === "fill"
                        }
                        justified={
                          !item?.tabVertical &&
                          ((item?.tabMenuAlign === "justified" ||
                            !item?.tabMenuAlign) ??
                            true)
                        }
                        className={classnames(`${item?.className}`, {
                          "justify-content-center":
                            !item?.tabVertical &&
                            item?.tabMenuAlign === "center",
                          "justify-content-start":
                            !item?.tabVertical && item?.tabMenuAlign === "left",
                          "justify-content-end":
                            !item?.tabVertical &&
                            item?.tabMenuAlign === "right",
                          "nav-left":
                            item?.tabVertical &&
                            (!item?.tabMenuAlign ||
                              item?.tabMenuAlign === "left"),
                          "nav-right":
                            item?.tabVertical && item?.tabMenuAlign === "right"
                        })}
                      >
                        {item?.children.map((item_child, index_child) => {
                          return (
                            <NavItem
                              key={`${storeName}_${
                                item?.pageId ?? index_child
                              }_tab${index}_nav_${index_child}`}
                            >
                              <NavLink
                                active={
                                  activeTab ===
                                  `${storeName}_tab${index}_menu_${
                                    item?.pageId ?? index_child
                                  }_${index_child}`
                                }
                                onClick={() => {
                                  toggleTab(
                                    `${storeName}_tab${index}_menu_${
                                      item?.pageId ?? index_child
                                    }_${index_child}`,
                                    item_child
                                  )
                                }}
                              >
                                {item_child?.tabIcon || item_child?.icon
                                  ? renderIcon(
                                      item_child?.tabIcon ?? item_child?.icon,
                                      "mx-1"
                                    )
                                  : null}
                                <span className="align-middle">
                                  {t(item_child?.tabTitle ?? "")}
                                </span>
                              </NavLink>
                            </NavItem>
                          )
                        })}
                      </Nav>

                      <TabContent activeTab={activeTab}>
                        {item?.children.map((item_childs, index_childs) => {
                          return (
                            <TabPane
                              key={`${storeName}_tab${index}_menu_${
                                item?.pageId ?? index_childs
                              }_${index_childs}`}
                              tabId={`${storeName}_tab${index}_menu_${
                                item?.pageId ?? index_childs
                              }_${index_childs}`}
                            >
                              <Row>
                                {/* {index_childs === 0
                                  ? item_childs?.tabContent?.map(
                                      (item_child, index_child) => {
                                        return renderInput(
                                          item_child,
                                          index_child
                                        )
                                      }
                                    )
                                  : null} */}
                                {item_childs?.tabContent?.map(
                                  (item_child, index_child) => {
                                    return renderInput(item_child, index_child)
                                  }
                                )}
                              </Row>
                            </TabPane>
                          )
                        })}
                      </TabContent>
                    </div>
                  </Col>
                )
              )
            } else {
              return renderInput(item, index)
            }
          })}

          {!hideFooter && !hideAllFotterButton && (
            <Col xs={12} className="text-center pt-50">
              {}

              {pageAttr?.crudOpt?.saveAsNewBtn && pageType !== "add" && (
                <Button
                  disabled={!isValid}
                  type="button"
                  outline
                  className="me-1 mt-25"
                  color="secondary"
                  key={`btn_form_save_as_${storeName}`}
                  id={`btn_form_save_as_${storeName}`}
                  onClick={() => {
                    beforeHandleFormSubmit(getValues(), null, null, true)
                  }}
                >
                  {renderIcon("material:save_as", "align-middle me-25", 14, {})}
                  <span className="align-middle">{t("Save As New")}</span>
                </Button>
              )}

              {renderBtnEditViewMode()}
              {renderBtnCancelViewMode()}

              {ability.can("update", "hr_manager") &&
              pageAttr?.crudOpt?.data?.extraButtonOnDetailView?.length > 0 ? (
                pageAttr?.crudOpt?.data?.extraButtonOnDetailView.map(
                  (item, index) => {
                    const showIf = item?.showIf
                    let showCondition = true
                    if (showIf) {
                      showIf.map((it) => {
                        let fieldsParse = []
                        if (it.includes("=")) {
                          fieldsParse = it.split("=")
                          showCondition =
                            `${getValues(fieldsParse[0])}` === fieldsParse[1]
                        }
                      })
                    }

                    const abiltyToBtn = item?.ability?.name
                    let canViewBtn = true
                    if (abiltyToBtn) {
                      canViewBtn = ability.can(
                        item?.ability?.method,
                        item?.ability?.name
                      )
                    }

                    return (
                      canViewBtn &&
                      showCondition && (
                        <Button
                          key={`extra_button_${index}`}
                          // type={item?.type ?? "button"}
                          type="button"
                          color={item?.color ?? "relief-primary"}
                          className="ms-1 mt-2"
                          onClick={() => {
                            const fillInput = item?.beforeClick?.fillInput
                            if (fillInput) {
                              fillInput.map((it) => {
                                const fieldsParse = it.split("=")
                                setValue(fieldsParse[0], fieldsParse[1])
                              })
                              beforeHandleFormSubmit(getValues(), null, null)
                            }
                          }}
                        >
                          {renderIcon(item?.icon, "", 14)}
                          <span className="align-middle ms-25">
                            {item?.buttonText
                              ? t(item?.buttonText)
                              : `Button ${index}`}
                          </span>
                        </Button>
                      )
                    )
                  }
                )
              ) : (
                <div></div>
              )}
            </Col>
          )}
        </Row>
      </Form>
    )
  }

  if (isInitialized) {
    return !filterMode && !reportMode ? (
      pageType !== "view" ? (
        pageAttr?.crudOpt?.data?.dynamicFormWizard ? (
          <InputWizard
            type={pageAttr?.crudOpt?.dynamicFormWizardType ?? 1}
            dynamicFormWizard={pageAttr?.crudOpt?.data?.dynamicFormWizard ?? []}
            form={renderForm(true)}
            formId={`form_${storeName}`}
            cancelBtn={renderCancelBtn()}
            submitBtn={renderSubmitBtn(true)}
            dynamicForm={dynamicForm}
            getFormValues={getValues()}
            setErrorForm={setError}
          />
        ) : (
          renderForm()
        )
      ) : pageAttr?.crudOpt?.data?.dynamicFormWizard ? (
        <InputWizard
          type={pageAttr?.crudOpt?.dynamicFormWizardType ?? 1}
          dynamicFormWizard={pageAttr?.crudOpt?.data?.dynamicFormWizard ?? []}
          form={renderFormViewMode(true)}
          formId={`form_${storeName}`}
          cancelBtn={renderBtnCancelViewMode(true)}
          editBtn={renderBtnEditViewMode(true)}
          dynamicForm={dynamicForm}
          getFormValues={getValues()}
          setErrorForm={setError}
        />
      ) : (
        renderFormViewMode()
      )
    ) : !chartFilter ? (
      !filterModeCalendar ? (
        filterComponent()
      ) : (
        <Fragment>
          <h5 className="section-label mb-1">
            <span className="align-middle">{t("Filters")}</span>
          </h5>
          <div className="calendar-events-filter">
            <Form
              onSubmit={(event) => {
                event.preventDefault()
                handleSubmit(beforeHandleFormSubmit(getValues(), "pdf", true))
              }}
            >
              <Row>
                {dynamicForm.map((itemx, index) => {
                  let item = { ...itemx }
                  const fieldName = item["fieldName"]
                  formDatas[fieldName] = null
                  formDatasError[fieldName] = false
                  item["size"] = 12
                  const colSize = {
                    md: item?.size ?? true,
                    xs: 12
                  }

                  const abiltyToInput = item?.ability?.name
                  let canViewInput = true
                  if (abiltyToInput) {
                    canViewInput = ability.can(
                      item?.ability?.method,
                      item?.ability?.name
                    )
                  }
                  if (!abiltyToInput || canViewInput) {
                    if (!item?.startDateField) {
                      if (item["type"] === "column" && item?.children) {
                        return (
                          <Col key={`col_${index}_`} {...colSize}>
                            <Row>
                              {item?.children.map((item_child) => {
                                return renderInput(item_child)
                              })}
                            </Row>
                          </Col>
                        )
                      } else {
                        return renderInput(item)
                      }
                    } else {
                    }
                  }
                })}
              </Row>
            </Form>
          </div>
        </Fragment>
      )
    ) : (
      dynamicForm.map((item, index) => {
        const fieldName = item["fieldName"]
        formDatas[fieldName] = null
        formDatasError[fieldName] = false

        let colSize = {}
        if (item?.size) {
          colSize = {
            md: item?.size,
            xs: item?.size < 4 ? 6 : 12
          }
        }
        const abiltyToInput = item?.ability?.name
        let canViewInput = true
        if (abiltyToInput) {
          canViewInput = ability.can(item?.ability?.method, item?.ability?.name)
        }
        if (!abiltyToInput || canViewInput) {
          if (item["type"] === "column" && item?.children) {
            return (
              <Col key={`col_${storeName}_${index}_`} {...colSize}>
                {/* <Row> */}
                {item?.children.map((item_child) => {
                  return renderInput(item_child)
                })}
                {/* </Row> */}
              </Col>
            )
          } else {
            return renderInput(item)
          }
        }
      })
    )
  } else {
    return filterMode ? (
      <Card
        style={{
          minHeight: "97px"
        }}
      >
        <CardBody className="mt-1 mt-md-0 py-75">
          <Form>
            <Row>
              <Col className="mb-1" key={`col_${storeName}initialize_`} md={12}>
                <Label for="plan-select"></Label>
                <Select
                  className="react-select"
                  isDisabled={true}
                  classNamePrefix="select"
                />
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    ) : (
      <Col
        key={`col_${storeName}initialize_`}
        md={12}
        style={{
          minHeight: "60vh"
        }}
      ></Col>
    )
  }
}

export default DynamicForm
