// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getMessaging, getToken, onMessage } from "firebase/messaging"

const tutorialImg =
  require("@src/assets/images/misc/allow_notifications.jpg").default
import Swal from "sweetalert2"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7Zs8GM5eQgGJDqLrQye5LJpr4wm7DZYs",
  authDomain: "add-app-indonesia.firebaseapp.com",
  projectId: "add-app-indonesia",
  storageBucket: "add-app-indonesia.appspot.com",
  messagingSenderId: "1096092734184",
  appId: "1:1096092734184:web:901c558f60a1cc187bef5d",
  measurementId: "G-RLSETK47T6"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const messaging = getMessaging(app)

let titleNotif = "Notification Permission Denied"
let cancelButtonText = "No Thanks"
let confirmButtonText = "Allow Notifications"
let messageNotif =
  "To ensure the security of your account and provide the best possible experience when using our app, please allow notifications from us."
let successNotif = "Notification Permission Granted"
let successNotifText = "You can now receive notifications from this website."
let deniedNotif = "Notification Permission Denied"
let deniedNotifText =
  "Unfortunately, you have denied permission for notifications."
let disabledNotif = "Notifications Disabled"
let disabledNotifText = "You will not receive notifications from this website."

const curLang = localStorage.getItem("i18nextLng")
if (curLang !== undefined && curLang === "id") {
  titleNotif = "Izin Pemberitahuan Ditolak"
  messageNotif =
    "Untuk memastikan keamanan akun Anda dan memberikan pengalaman terbaik saat menggunakan aplikasi kami, mohon izinkan notifikasi dari kami."
  confirmButtonText = "Izinkan Notifikasi"
  cancelButtonText = "Tidak, terima kasih"
  successNotif = "Pemberitahuan Diizinkan"
  successNotifText =
    "Anda sekarang dapat menerima pemberitahuan dari situs web ini."
  deniedNotif = "Izin Pemberitahuan Ditolak"
  deniedNotifText = "Sayangnya, Anda telah menolak izin untuk notifikasi."
  disabledNotif = "Notifikasi Dinonaktifkan"
  disabledNotifText =
    "Anda tidak akan menerima pemberitahuan dari situs web ini."
}

export const requestNotificationPermission = () => {
  return new Promise((resolve, reject) => {
    if (Notification.permission === "granted") {
      resolve()
    } else if (Notification.permission === "denied") {
      Swal.fire({
        // icon: "warning",
        title: titleNotif,
        text: messageNotif,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-3"
        },
        imageUrl: tutorialImg,
        imageWidth: 350,
        imageAlt: "Chrome Enable Notification Tutorial"
      }).then((result) => {
        if (result.isConfirmed) {
          Notification.requestPermission().then((permission) => {
            // console.log(permission)
            if (permission === "granted") {
              resolve()
            } else {
              reject()
            }
          })
        } else {
          reject()
        }
      })
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          resolve()
        } else {
          reject()
        }
      })
    }
  })
}

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BDRKjYq-oiBEdHhxwCREJ0pCj29HU1hUbAPBPRG5oJDWU64aLX4uwqeT774PR-irKDHVpuZjGj0e03N61ICS2m0"
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken)
        localStorage.setItem("dev", currentToken)
        // Perform any other necessary action with the token
      } else {
        // Check notification permission
        if (Notification.permission === "denied") {
          Swal.fire({
            // icon: "warning",
            title: titleNotif,
            text: messageNotif,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: true,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-secondary ml-3"
            },
            imageUrl: tutorialImg,
            imageWidth: 350,
            imageAlt: "Chrome Enable Notification Tutorial"
          }).then((result) => {
            if (result.isConfirmed) {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  Swal.fire({
                    icon: "success",
                    title: successNotif,
                    text: successNotifText,
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "btn btn-primary"
                    }
                  })
                } else {
                  Swal.fire({
                    icon: "error",
                    title: deniedNotif,
                    text: deniedNotifText,
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "btn btn-primary"
                    }
                  })
                }
              })
            } else {
              Swal.fire({
                icon: "info",
                title: disabledNotif,
                text: disabledNotifText,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-primary"
                }
              })
            }
          })
        }
        localStorage.removeItem("dev")
        // console.log(
        // "No registration token available. Request permission to generate one."
        // )
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err)
      if (Notification.permission === "denied") {
        Swal.fire({
          // icon: "warning",
          title: titleNotif,
          text: messageNotif,
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-3"
          },
          imageUrl: tutorialImg,
          imageWidth: 350,
          imageAlt: "Chrome Enable Notification Tutorial"
        }).then((result) => {
          if (result.isConfirmed) {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                Swal.fire({
                  icon: "success",
                  title: successNotif,
                  text: successNotifText,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary"
                  }
                })
              } else {
                Swal.fire({
                  icon: "error",
                  title: deniedNotif,
                  text: deniedNotifText,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary"
                  }
                })
              }
            })
          } else {
            Swal.fire({
              icon: "info",
              title: disabledNotif,
              text: disabledNotifText,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            })
          }
        })
      }
      localStorage.removeItem("dev")
    })
  // .then((currentToken) => {
  //   if (currentToken) {
  //     // console.log("current token for client: ", currentToken)
  //     localStorage.setItem("dev", currentToken)
  //     // Perform any other neccessary action with the token
  //   } else {
  //     // Show permission request UI
  //     localStorage.removeItem("dev")
  //     // console.log(
  //     //   "No registration token available. Request permission to generate one."
  //     // )
  //   }
  // })
  // .catch((err) => {
  //   console.log("An error occurred while retrieving token. ", err)
  // })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("payload", payload)
      resolve(payload)
    })
  })
