// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt"

// import { UnitsSlice } from "../views/apps/unit/store"

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem("userData")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem("userData", JSON.stringify(action.payload))
      // console.log(action.payload)
      if (action.payload?.char_replacer) {
        localStorage.setItem(
          "char_replacer",
          JSON.stringify(action.payload.char_replacer)
        )
      }
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken.replaceAll('"', ""))
      )
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken.replaceAll('"', ""))
      )
    },
    handleLogout: (state) => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // try {
      useJwt.ssoAxiosInterceptor
        .delete("logout")
        .then((x) => {
          // ** Remove user, accessToken & refreshToken from localStorage
          localStorage.removeItem("userData")
          localStorage.removeItem(config.storageTokenKeyName)
          localStorage.removeItem(config.storageRefreshTokenKeyName)
          window.location = "/login"
        })
        .catch((err) => {
          console.error(err)
          // ** Remove user, accessToken & refreshToken from localStorage
          localStorage.removeItem("userData")
          localStorage.removeItem(config.storageTokenKeyName)
          localStorage.removeItem(config.storageRefreshTokenKeyName)
          window.location = "/login"
        })
      // } catch (error) {
      //   console.error(error)
      //   // ** Remove user, accessToken & refreshToken from localStorage
      //   localStorage.removeItem("userData")
      //   localStorage.removeItem(config.storageTokenKeyName)
      //   localStorage.removeItem(config.storageRefreshTokenKeyName)
      //   window.location = "/login"
      // }
      //
      // console.log(UnitsSlice.reducer)
      // UnitsSlice.reducer.resetAll(state)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
