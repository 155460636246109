// ** Third Party Components
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

// ** Reactstrap Imports
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner
} from "reactstrap"

import illustration from "@src/assets/images/illustration/email.svg"
import {
  putDataWidget,
  resetData,
  setStorePageAttributes,
  setSelectedData
} from "@src/shared/dynamic_pages/store"
import "moment-timezone"
import "moment/locale/id"
import { Fragment, useContext, useState } from "react"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"

import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined"
import { isErpDemoApp } from "@utils"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import toast from "react-hot-toast"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import ModalAddEdit from "@src/shared/dynamic_pages/modal"

const params = {
  // navigation: true
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 5500,
    disableOnInteraction: false
  },
  slideToClickedSlide: true
}

const MySwal = withReactContent(Swal)

const ApprovalSlider = ({ item, handleChangeFilterFromChild }) => {
  const store = useSelector((state) => state.dynamic_temp_state)
  const storeAll = useSelector((state) => state.dynamicStore)
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { colors } = useContext(ThemeColors)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const storeName = item?.cardId
  const storeNameForm = `${item?.cardId}_form`
  const endpointName = item?.dataFrom?.api
  const baseUrl = item?.dataFrom?.baseUrl
  const [openAcc, setOpenAcc] = useState(null)
  const toggleAcc = (id) => {
    openAcc === id ? setOpenAcc() : setOpenAcc(id)
  }

  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState("prompt")
  const [loadingModal, setLoadingModal] = useState(false)
  const [pageAttr, setPageAttr] = useState({})

  const [currentItemClick, setCurrentItemClick] = useState({})
  const [currentRowClick, setCurrentRowClick] = useState({})
  const [currentTypeClick, setCurrentTypeClick] = useState(null)

  const errorToast = (res) => {
    let errorFieldsMsg = ""
    if (res?.payload?.error?.errors) {
      const errorFields = res?.payload?.error?.errors
      for (let errorx in res?.payload?.error?.errors) {
        errorFieldsMsg = `${errorFieldsMsg} - ${errorFields[errorx]}\n`
        // console.log(errorx, errorFields[errorx])
      }
    }
    return toast.error(
      `${res.payload?.error?.message} \n \n ${errorFieldsMsg}`,
      {
        style: {
          padding: "16px",
          color: colors.danger.main,
          border: `1px solid ${colors.danger.main}`
        },
        iconTheme: {
          primary: colors.danger.main
        }
      }
    )
  }

  const beforePutDataToApi = (
    data,
    item = currentItemClick,
    row = currentRowClick,
    type = currentTypeClick
  ) => {
    setLoadingPostPut(true)
    setLoadingText(
      currentTypeClick === "reject"
        ? `${t("Decline the Submission")}...`
        : `${t("Approve the Submission")}...`
    )

    const dataTo =
      currentTypeClick === "reject" ? item?.rejectDataTo : item?.submitDataTo
    let endpointNameTo = dataTo?.api
    let appendToEndpointPath = dataTo?.appendToEndpointPath
    if (appendToEndpointPath) {
      let endpointNameTo_extra_arr = []

      appendToEndpointPath.map((qs, index) => {
        if (row?.[qs]) {
          endpointNameTo_extra_arr.push(row?.[qs])
        }
      })
      let endpointNameTo_extra = endpointNameTo_extra_arr.join("/")
      endpointNameTo = `${endpointNameTo}/${endpointNameTo_extra}`
    }

    let payLoad = {
      storeName: storeName,
      endpointName: endpointNameTo,
      baseUrl: dataTo?.baseUrl,
      ...data
    }

    const payloadFromFields = dataTo?.payloadFromFields
    if (payloadFromFields && Array.isArray(payloadFromFields)) {
      payloadFromFields?.map((i, x) => {
        payLoad[i?.paramName] = row?.[i?.fieldName]
      })
    }

    const payloadFromStatic = dataTo?.payloadFromStatic
    if (payloadFromStatic && Array.isArray(payloadFromStatic)) {
      payloadFromStatic?.map((i, x) => {
        payLoad[i?.paramName] = i?.value
      })
    }

    // console.log(payLoad)
    return putDataToApi(payLoad, item, row, type)
  }

  const putDataToApi = (data, item = null, row = null, status = "approve") => {
    let dataTitle = row?.[item?.data_titleField] ?? row?.title
    setLoadingPostPut(true)
    setLoadingText(
      status === "approve"
        ? `${t("Approving Submission")} ${dataTitle}...`
        : `${t("Rejecting Submission")} ${dataTitle}...`
    )
    dispatch(putDataWidget(data))
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          // if (storeAll?.pageData[storeName]?.data) {
          //   dispatch(resetData(storeName))
          // }
          // dispatch(
          //   getDataList({
          //     storeName: storeName,
          //     endpointName: endpointName,
          //     baseUrl: baseUrl
          //   })
          // )
          handleChangeFilterFromChild({ item: item })
            .then(() => {
              toast.success(
                status === "approve"
                  ? `${t("Successfully Approved")} ${dataTitle}`
                  : `${t("Successfully Rejected")} ${dataTitle}`,
                {
                  style: {
                    padding: "16px",
                    color: colors.success.main,
                    border: `1px solid ${colors.success.main}`
                  },
                  iconTheme: {
                    primary: colors.success.main
                  }
                }
              )
            })
            .then(() => {
              setLoadingPostPut(false)
            })
            .then(() => {
              if (item?.afterSubmitReloadOtherPage) {
                setTimeout(() => {
                  item?.afterSubmitReloadOtherPage?.map((url) => {
                    const store_to_reset = url.replaceAll("/", "_")
                    if (storeAll?.pageData?.[store_to_reset]?.data) {
                      dispatch(resetData(store_to_reset))
                      // dispatch(
                      //   getDataList({
                      //     ...(storeAll?.pageData?.[store_to_reset]?.params ?? {}),
                      //     storeName: store_to_reset,
                      //     endpointName:
                      //       storeAll.pageAttributes[store_to_reset]?.crudOpt
                      //         ?.endpointName,
                      //     baseUrl:
                      //       storeAll.pageAttributes[store_to_reset]?.crudOpt
                      //         ?.baseUrl
                      //   })
                      // )
                    }
                  })
                }, 1000)
              } else {
                console.log("Successfully Approved")
              }
            })
        } else if (res.meta.requestStatus === "rejected") {
          setLoadingPostPut(false)
          errorToast(res)
        }
      })
      .catch((error) => {
        // console.error(error)
        setLoadingPostPut(false)
        errorToast(error)
      })
  }

  const btnEventSubmit = async (row, item) => {
    setCurrentItemClick(item)
    setCurrentRowClick(row)
    setCurrentTypeClick("approve")
    let submitPromptTitle = item?.submitPromptTitle ?? `${t("Are you sure?")}`
    let submitPromptMsg =
      item?.submitPromptMsg ??
      `${t("You will approve the submission.")} ${
        row?.[item?.data_titleField] ?? row?.title
      }. ${t("Approved data cannot be recovered")}!`
    let confirmButtonText = item?.submitPromptBtnText ?? `${t("Yes, Approve")}`

    if (item?.submitDataTo?.dynamicForm) {
      setLoadingModal(true)

      let initialPageAtrr = {
        pageTitle: submitPromptTitle,
        crudOpt: {
          detailPageStaticSubHeader: submitPromptMsg,
          detailFromGrid: true,
          btnSubmitText: confirmButtonText,
          data: {
            dynamicForm: item?.submitDataTo?.dynamicForm
          }
        }
      }
      setPageAttr(initialPageAtrr)
      await dispatch(
        setStorePageAttributes({
          storeName: storeNameForm,
          data: initialPageAtrr
        })
      ).then((x) => {
        dispatch(
          setSelectedData({
            rowData: row,
            storeName: storeNameForm
          })
        )
        setShowModal(!showModal)
        setLoadingModal(false)
      })
    } else {
      const result = await MySwal.fire({
        title: submitPromptTitle,
        text: submitPromptMsg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: `${t("Cancel")}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1"
        },
        buttonsStyling: false
      })
      if (result.value) {
        return beforePutDataToApi({}, item, row, "approve")
      }
    }
  }

  const btnEventReject = async (row, item) => {
    setCurrentItemClick(item)
    setCurrentRowClick(row)
    setCurrentTypeClick("reject")
    let rejectPromptTitle = item?.rejectPromptTitle ?? `${t("Are you sure?")}`
    let rejectPromptMsg =
      item?.rejectPromptMsg ??
      `${t("You will decline the submission.")} ${
        row?.[item?.data_titleField] ?? row?.title
      }. ${t("Declined data cannot be recovered")}!`
    let confirmButtonText = item?.rejectPromptBtnText ?? `${t("Yes, Decline")}`

    if (item?.rejectDataTo?.dynamicForm) {
      setLoadingModal(true)

      let initialPageAtrr = {
        pageTitle: rejectPromptTitle,
        crudOpt: {
          detailPageStaticSubHeader: rejectPromptMsg,
          detailFromGrid: true,
          btnSubmitText: confirmButtonText,
          data: {
            dynamicForm: item?.rejectDataTo?.dynamicForm
          }
        }
      }
      setPageAttr(initialPageAtrr)
      await dispatch(
        setStorePageAttributes({
          storeName: storeNameForm,
          data: initialPageAtrr
        })
      ).then((x) => {
        dispatch(
          setSelectedData({
            rowData: row,
            storeName: storeNameForm
          })
        )
        // console.log(initialPageAtrr)
        // console.log(pageAttr)
        setShowModal(!showModal)
        setLoadingModal(false)
      })
    } else {
      const result = await MySwal.fire({
        title: rejectPromptTitle,
        text: rejectPromptMsg,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: `${t("Cancel")}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1"
        },
        buttonsStyling: false
      })
      if (result.value) {
        return beforePutDataToApi({}, item, row, "reject")
      }
    }
  }

  const btnEventExtra = async (row, item) => {
    let endpointNameTo = item?.extraBtnTo?.static
    if (item?.extraBtnTo?.field) {
      endpointNameTo = row[item?.extraBtnTo?.field]
    }

    const paramsFromFields = item?.extraBtnTo?.paramsFromFields
    if (paramsFromFields && Array.isArray(paramsFromFields)) {
      let paramsFromFields_arr = []
      paramsFromFields?.map((i, x) => {
        const value = row?.[i?.fieldName]
        if (value) {
          paramsFromFields_arr.push(`${i?.paramName}=${value}`)
        }
      })
      let endpointNameTo_extra = paramsFromFields_arr.join("&")
      if (endpointNameTo?.includes("?")) {
        endpointNameTo = `${endpointNameTo}&${endpointNameTo_extra}`
      } else {
        endpointNameTo = `${endpointNameTo}?${endpointNameTo_extra}`
      }
    }

    if (
      endpointNameTo.startsWith("http://") ||
      endpointNameTo.startsWith("https://")
    ) {
      return window.open(endpointNameTo, "_blank", "noreferrer")
    } else {
      return navigate(endpointNameTo ?? "/")
    }
  }

  const closeModal = () => {
    setShowModal(!showModal)
    // neutralizeBack(handleCloseModal)
  }

  const calendarStrings = {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "[last] dddd",
    nextWeek: "dddd",
    sameElse: "L"
  }

  const toUpperCaseFilter = (d) => {
    return d.toUpperCase()
  }

  return (
    store.data[storeName]?.length > 0 && (
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center text-truncate">
            {item?.icon ? (
              renderIcon(item?.icon, "user-timeline-title-icon me-1", 20)
            ) : (
              <InsertInvitationOutlinedIcon className="user-timeline-title-icon me-1" />
            )}
            <CardTitle
              className="text-truncate"
              tag="h4"
              style={{
                fontSize: item?.title?.length > 30 ? "1rem" : "1.285rem"
              }}
            >
              {t(item?.title ?? "")}
            </CardTitle>
            {store.data[storeName]?.length > 1 && (
              <Badge className="ms-1" tag="div" color="danger" pill>
                {store.data[storeName]?.length}
              </Badge>
            )}
          </div>
        </CardHeader>

        <CardBody className="px-0 pb-0">
          {
            <Swiper {...params} {...(item.sliderOpt ?? {})}>
              {store.data[storeName]?.map((row) => {
                const titleData = row?.[item?.data_titleField] ?? row?.title
                const descData = row?.[item?.data_descField] ?? row?.desc
                return (
                  <SwiperSlide key={`swiper_${row.id}`}>
                    <Card className="card-developer-meetup mb-0 cursor-pointer">
                      {!item?.imageHide && (
                        <div className="meetup-img-wrapper rounded-top text-center">
                          <img
                            src={item?.image ? item?.image : illustration}
                            height="170"
                          />
                        </div>
                      )}
                      <CardBody>
                        <div className="meetup-header mb-1 d-flex align-items-center">
                          <CardTitle
                            tag="h4"
                            style={{
                              fontSize:
                                titleData?.length > 40 ? "1rem" : "1.1rem"
                            }}
                            className="mb-25"
                          >
                            {titleData}
                          </CardTitle>
                        </div>
                        <div
                          style={{
                            minHeight: "60px"
                          }}
                          className="d-flex"
                        >
                          {descData}
                        </div>
                        <div className="d-flex mt-1 mb-2 align-items-center justify-content-center">
                          {(item?.extraBtnText ||
                            item?.extraBtnIcon ||
                            item?.extraBtn) &&
                            !loadingPostPut &&
                            (item?.extraBtn ? (
                              item?.extraBtn?.map((extItem, indexx) => {
                                return (
                                  <Button.Ripple
                                    color={`${
                                      extItem?.extraBtnClass ?? "gradient-info"
                                    }  me-1`}
                                    onClick={(ex) => {
                                      ex.preventDefault()
                                      !isErpDemoApp()
                                        ? btnEventExtra(row, extItem)
                                        : e.stopPropagation()
                                    }}
                                  >
                                    {extItem?.extraBtnIcon ? (
                                      <Fragment>
                                        {renderIcon(
                                          extItem?.extraBtnIcon,
                                          extItem?.extraBtnText
                                            ? "me-25 align-middle"
                                            : "align-middle",
                                          14
                                        )}
                                        {extItem?.extraBtnText && (
                                          <span className="align-middle">
                                            {extItem?.extraBtnText}
                                          </span>
                                        )}
                                      </Fragment>
                                    ) : (
                                      extItem?.extraBtnText
                                    )}
                                  </Button.Ripple>
                                )
                              })
                            ) : (
                              <Button.Ripple
                                color={`${
                                  item?.extraBtnClass ?? "gradient-info"
                                }  me-1`}
                                onClick={(e) => {
                                  e.preventDefault()
                                  !isErpDemoApp()
                                    ? btnEventExtra(row, item)
                                    : e.stopPropagation()
                                }}
                              >
                                {item?.extraBtnIcon ? (
                                  <Fragment>
                                    {renderIcon(
                                      item?.extraBtnIcon,
                                      item?.extraBtnText
                                        ? "me-25 align-middle"
                                        : "align-middle",
                                      14
                                    )}
                                    {item?.extraBtnText && (
                                      <span className="align-middle">
                                        {item?.extraBtnText}
                                      </span>
                                    )}
                                  </Fragment>
                                ) : (
                                  item?.extraBtnText
                                )}
                              </Button.Ripple>
                            ))}

                          {!loadingPostPut &&
                            item?.rejectBtn &&
                            (!loadingDelete ? (
                              <Button.Ripple
                                onClick={(e) => {
                                  e.preventDefault()
                                  !isErpDemoApp()
                                    ? btnEventReject(row, item)
                                    : e.stopPropagation()
                                }}
                                color={`${
                                  item?.rejectBtnClass ?? "flat-secondary"
                                }  me-1`}
                              >
                                {item?.rejectBtnIcon ? (
                                  <Fragment>
                                    {renderIcon(
                                      item?.rejectBtnIcon,
                                      item?.rejectBtnText
                                        ? "me-25 align-middle"
                                        : "align-middle",
                                      14
                                    )}
                                    {item?.rejectBtnText && (
                                      <span className="align-middle">
                                        {item?.rejectBtnText ?? t("Decline")}
                                      </span>
                                    )}
                                  </Fragment>
                                ) : (
                                  item?.rejectBtnText ?? t("Decline")
                                )}
                              </Button.Ripple>
                            ) : (
                              <Button.Ripple color="flat-secondary me-1">
                                <Spinner color="white" size="sm" type="grow" />
                                <span className="ms-50">{loadingText}</span>
                              </Button.Ripple>
                            ))}

                          {!loadingPostPut ? (
                            <Button.Ripple
                              onClick={(e) => {
                                e.preventDefault()
                                !isErpDemoApp()
                                  ? btnEventSubmit(row, item)
                                  : e.stopPropagation()
                              }}
                              color="gradient-primary"
                            >
                              {item?.submitBtnIcon ? (
                                <Fragment>
                                  {renderIcon(
                                    item?.submitBtnIcon,
                                    item?.submitBtnText
                                      ? "me-25 align-middle"
                                      : "align-middle",
                                    14
                                  )}
                                  {item?.submitBtnText && (
                                    <span className="align-middle">
                                      {item?.submitBtnText ?? t("Accept")}
                                    </span>
                                  )}
                                </Fragment>
                              ) : (
                                item?.submitBtnText ?? t("Accept")
                              )}
                            </Button.Ripple>
                          ) : (
                            <Button.Ripple
                              disabled={loadingPostPut}
                              color="gradient-primary"
                            >
                              <Spinner color="white" size="sm" type="grow" />
                              <span className="ms-50">{loadingText}</span>
                            </Button.Ripple>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          }
        </CardBody>

        {showModal && (
          <ModalAddEdit
            pageAttr={pageAttr}
            storeName={storeNameForm}
            pageType={pageType}
            show={showModal}
            setShow={closeModal}
            detailPage={pageAttr?.crudOpt?.detailPage}
            setPageType={setPageType}
            loading={loadingModal}
            detailPageModalSize={pageAttr?.crudOpt?.detailPageModalSize}
            handleFormSubmitPrompt={beforePutDataToApi}
          />
        )}
      </Card>
    )
  )
}

export default ApprovalSlider
