/* eslint-disable brace-style */
/* eslint-disable no-var */
import Avatar from "@components/avatar"
import AvatarGroup from "@components/avatar-group"
import { AbilityContext } from "@src/utility/context/Can"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import "@styles/react/libs/flatpickr/flatpickr.scss"
import "cleave.js/dist/addons/cleave-phone.us"
import Moment from "moment"
import "moment/locale/id"
import { Fragment, useContext } from "react"
import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Badge, Col, Row, UncontrolledTooltip } from "reactstrap"
import { renderIcon } from "../../views/apps/menu/store/my_menu"
import { QRCodeSVG } from "qrcode.react"

import doc from "@src/assets/images/icons/file-icons/doc.png"
import docx1 from "@src/assets/images/icons/file-icons/docx1.png"
import docx2 from "@src/assets/images/icons/file-icons/docx2.png"
import excel from "@src/assets/images/icons/file-icons/excel.png"
import jpeg from "@src/assets/images/icons/file-icons/jpeg.png"
import jpg from "@src/assets/images/icons/file-icons/jpg.png"
import pdf from "@src/assets/images/icons/file-icons/pdf.png"
import png from "@src/assets/images/icons/file-icons/png.png"
import txt from "@src/assets/images/icons/file-icons/txt.png"
import mp3 from "@src/assets/images/icons/file-icons/mp3.png"
import { currencyCodes } from "@utils"

import { Swiper, SwiperSlide } from "swiper/react/swiper-react"

const params = {
  // navigation: true,
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 5500,
    disableOnInteraction: false
  },
  slideToClickedSlide: true
}

const DynamicRow = ({ row, column, clickToDetailEvent, disabledEditable }) => {
  // console.log(row, column)
  const { t, i18n } = useTranslation()
  const paramsRoutes = useParams()
  // const navigate = useNavigate()
  // const location = useLocation()
  const dynamicTempState = useSelector((state) => state.dynamic_temp_state)
  const ability = useContext(AbilityContext)
  const { colors } = useContext(ThemeColors)
  const trackBgColor = "#e9ecef"

  //////
  // console.log(row)
  // console.log(column, row)

  let fieldName =
    column?.editableInputOpt?.type === "dropdown"
      ? column.editableInputOpt?.initialValueFromOther ?? column?.fieldName
      : column?.fieldName

  let idRow = row?.id ?? Date.now()

  const fieldNameEl = fieldName?.replace(/\./g, "_")
  const fieldType = column?.type
  // let fieldData = row?.[fieldName]
  let fieldDataStr =
    fieldName?.includes(".") &&
    !["htpp://", "https://"].includes(fieldName) &&
    row?.id !== 0
      ? getDepthValue(row, fieldName)
      : row?.[fieldName] ?? column?.value ?? ""
  let fieldData =
    fieldName?.includes(".") &&
    !["htpp://", "https://"].includes(fieldName) &&
    row?.id !== 0
      ? getDepthValue(row, fieldName)
      : row[fieldName] ?? column?.value ?? ""

  const dataFormat = column?.dataFormat || column?.avatarDescFieldFormat
  let prefix = column?.prefixValue
  let suffix = column?.suffixValue
  let textStyle = column?.textStyle ?? {}
  const getFileType = fieldData ? String(fieldData)?.split(".")?.pop() : null
  const fileType = [
    "pdf",
    "xls",
    "doc",
    "txt",
    "xlsx",
    "docx",
    "png",
    "jpg",
    "jpeg",
    "mp3"
  ].includes(getFileType)

  /////////
  // console.log(fieldName, row)
  if (column?.calcFromOther && !fieldData) {
    fieldData = calcVal(column?.calcFromOther)
    if (fieldData !== "") {
      fieldDataStr = `${prefix ? `${t(prefix)} ` : ""}${fieldData ?? ""}${
        suffix ? ` ${t(suffix)}` : ""
      }`
    }
  } else {
    fieldDataStr = `${prefix ? `${t(prefix)} ` : ""}${fieldData ?? ""}${
      suffix ? ` ${t(suffix)}` : ""
    }`
  }

  const isWhatsappRow = (valueFix) => {
    // console.log(valueFix)
    return (
      valueFix &&
      valueFix !== "" &&
      valueFix.indexOf("!@#$%") === -1 &&
      valueFix.indexOf("/") === -1
    )
  }
  ////COLUMN IS LINK TO DETAIL
  let clickToLinkUrl = null
  if (column?.clickToLinkUrl) {
    clickToLinkUrl = `${column?.clickToLinkUrl}`
    if (column?.clickToLinkFieldId) {
      if (column?.clickToLinkFieldIdAsQuery) {
        let separator = column?.clickToLinkUrl?.includes("?") ? `&` : `?`
        clickToLinkUrl = `${column?.clickToLinkUrl}${separator}${
          column?.clickToLinkFieldId
        }=${row[column?.clickToLinkFieldId]}&refresh=1`
      } else {
        clickToLinkUrl = `${column?.clickToLinkUrl}/${
          row[column?.clickToLinkFieldId]
        }?refresh=1`
      }
    }
  }

  const formatPhoneNumber = (number) => {
    if (number.startsWith("0")) {
      return `+62${number.slice(1)}`?.replace(" ", "")
    } else if (!number.startsWith("+62") && !number.startsWith("0")) {
      return `+62${number}`?.replace(" ", "")
    }
    return number?.replace(" ", "")
  }

  const replaceObjectsWhatsapp = (msgWhatsapp, rowCurrent) => {
    // const regex = /\{(.*?)\}/g
    const regex = /(?<!\[)\{(.*?)(?<!\])\}/g
    const matches = []
    let match

    while ((match = regex.exec(msgWhatsapp)) !== null) {
      matches.push(match[1].trim())
    }
    // console.log(matches)
    let msgWhatsappFixed = msgWhatsapp
    if (matches) {
      matches.forEach((matchedString, index) => {
        const replacement =
          rowCurrent?.[matchedString] ??
          getDepthValue(rowCurrent, matchedString) ??
          ""
        msgWhatsappFixed = msgWhatsappFixed.replace(
          new RegExp(`\\{\\s*${matchedString}\\s*\\}`, "g"),
          replacement
        )
      })
      // console.log(msgWhatsappFixed)
    }
    return msgWhatsappFixed
  }

  function replacePlaceholders(template, data) {
    const dynamicTagPattern = /\[(\w+)\](.*?)\[\w+\]/gs

    let result = template

    // Replace placeholders outside dynamic tags
    result = result.replace(/\{(.*?)\}/g, (match, key) => {
      const trimmedKey = key.trim()
      let valueMatch = data[trimmedKey] ?? getDepthValue(data, trimmedKey) ?? ""
      if (valueMatch) {
        return valueMatch
      }
      return match
    })

    // Replace placeholders inside dynamic tags
    result = result.replace(dynamicTagPattern, (match, tag, content) => {
      if (data[tag]) {
        return data[tag]
          .map((item) => {
            let replacedContent = content
            Object.keys(item).forEach((key) => {
              const placeholderPattern = new RegExp(`{${key}}`, "g")
              replacedContent = replacedContent.replace(
                placeholderPattern,
                item[key]
              )
            })
            return replacedContent
          })
          .join("")
      }
      return match // If no data found for the tag, keep the original
    })

    return result
  }

  const handleClickLinkCustom = (column, value) => {
    if (
      column?.avatarDescFieldType === "whatsapp" ||
      column?.type === "whatsapp"
    ) {
      let phoneNumber = formatPhoneNumber(value)?.replace("+", "")
      let msgWhatsapp = column?.whatsappMsg ?? ""
      let msgWhatsappFixed = ""
      ////objects
      // let msgWhatsappFixed = replaceObjectsWhatsapp(msgWhatsapp, row)

      ////array
      const charactersToCheck = ["{", "}", "[", "]"]
      const containsSpecialCharacters = charactersToCheck.some((char) =>
        msgWhatsapp.includes(char)
      )

      if (containsSpecialCharacters) {
        msgWhatsappFixed = replacePlaceholders(msgWhatsapp, row)
      } else {
        msgWhatsappFixed = msgWhatsapp
      }

      // console.log(msgWhatsappFixed)

      if (msgWhatsappFixed) {
        msgWhatsappFixed = encodeURIComponent(msgWhatsappFixed)
      }
      const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${msgWhatsappFixed}`
      // console.log(whatsappURL)
      window.open(whatsappURL, "_blank")
    }
  }

  ////COLUMN IS AVATAR
  ///RENDER TITLE
  const renderAvatarTitle = (column, row) => {
    let avatarInitial = ""
    const avatarInitialField =
      column?.avatarInitialField ?? column?.avatarTitleField
    if (Array.isArray(avatarInitialField)) {
      for (let avatarIndex in avatarInitialField) {
        avatarInitial = `${avatarInitial} ${
          (avatarInitialField[avatarIndex].includes(".")
            ? getDepthValue(row, avatarInitialField[avatarIndex])
            : row[avatarInitialField[avatarIndex]]) ?? ""
        }`
      }
    } else {
      avatarInitial = avatarInitialField?.includes(".")
        ? getDepthValue(row, avatarInitialField)
        : row[avatarInitialField]
    }
    return avatarInitial
  }

  const renderAvatarTitleComp = (columnAvatar, rowAvatar) => {
    let avatarTitle = renderAvatarTitle(columnAvatar, rowAvatar)
    let avatarImg
    if (
      columnAvatar?.avatarImgField &&
      (rowAvatar[columnAvatar?.avatarImgField]?.length ||
        getDepthValue(rowAvatar, columnAvatar?.avatarImgField))
    ) {
      avatarImg = (
        <Avatar
          className={`me-50 ${columnAvatar?.avatarClassName}` ?? ""}
          imgClassName={columnAvatar?.imgClassName}
          img={
            columnAvatar?.avatarImgField?.includes(".")
              ? getDepthValue(rowAvatar, columnAvatar?.avatarImgField)
              : rowAvatar[columnAvatar?.avatarImgField] ?? ""
          }
          imgWidth={columnAvatar?.avatarWidth ?? "32"}
          imgHeight={columnAvatar?.avatarHeight ?? "32"}
          imgStyle={columnAvatar?.avatarImgStyle}
          // avatarStyle={columnAvatar?.avatarStyle}
          // style={{ borderRadius: "20%", ...(columnAvatar?.avatarStyle ?? {}) }}
          // imgHeight={columnAvatar?.avatarHeight ?? columnAvatar?.avatarWidth ?? "32"}
          // imgWidth={columnAvatar?.avatarWidth ?? columnAvatar?.avatarHeight ?? "32"}
        />
      )
    } else {
      if (
        avatarTitle?.length > 1 &&
        avatarTitle !== " " &&
        avatarTitle !== "  "
      ) {
        avatarImg = (
          <Avatar
            initials
            className="me-50"
            color={
              columnAvatar?.avatarColorField
                ? getDepthValue(rowAvatar, columnAvatar?.avatarColorField)
                : columnAvatar?.color
            }
            content={avatarTitle}
            length={columnAvatar?.avatarInitialSize ?? 2}
          />
        )
      } else {
        avatarImg = <span></span>
      }
    }

    let avatarTitleEl

    if (columnAvatar?.clickToLink) {
      let valueFix = columnAvatar?.avatarDescField?.includes(".")
        ? columnAvatar?.avatarDescFieldFormat
          ? dataFormating(
              getDepthValue(rowAvatar, columnAvatar?.avatarDescField)
            )
          : getDepthValue(rowAvatar, columnAvatar?.avatarDescField)
        : columnAvatar?.avatarDescFieldFormat
        ? dataFormating(rowAvatar[columnAvatar?.avatarDescField] ?? "")
        : rowAvatar[columnAvatar?.avatarDescField] ?? ""
      avatarTitleEl = (
        <Fragment>
          <Link to={`${clickToLinkUrl}&isOrigin=true`} className="text-body">
            <span className="fw-bolder">{avatarTitle}</span>
          </Link>
          {columnAvatar?.avatarDescField && (
            <small
              onClick={() =>
                handleClickLinkCustom(
                  column,
                  rowAvatar[columnAvatar?.avatarDescField]
                )
              }
              style={{ marginTop: "2px", fontSize: "inherit" }}
              className={`text-truncate text-muted mb-0 ${
                columnAvatar?.avatarDescFieldType === "whatsapp"
                  ? "cursor-pointer fw-bolder d-flex align-items-center"
                  : ""
              }`}
            >
              {valueFix}
              {columnAvatar?.avatarDescFieldType === "whatsapp" &&
                isWhatsappRow(valueFix) && (
                  <span style={{ width: "12px" }} className="ms-25">
                    <svg
                      fill="rgba(var(--bs-success-rgb), var(--bs-text-opacity))"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                  </span>
                )}
            </small>
          )}
        </Fragment>
      )
    } else {
      let valueFix = columnAvatar?.avatarDescField?.includes(".")
        ? columnAvatar?.avatarDescFieldFormat
          ? dataFormating(
              getDepthValue(rowAvatar, columnAvatar?.avatarDescField)
            )
          : getDepthValue(rowAvatar, columnAvatar?.avatarDescField)
        : columnAvatar?.avatarDescFieldFormat
        ? dataFormating(rowAvatar[columnAvatar?.avatarDescField] ?? "")
        : rowAvatar[columnAvatar?.avatarDescField] ?? ""
      avatarTitleEl = (
        <Fragment>
          <span>{avatarTitle}</span>
          {columnAvatar?.avatarDescField && (
            <small
              id={`row_custom_${columnAvatar?.avatarDescField?.replace(
                ".",
                ""
              )}`}
              onClick={() =>
                handleClickLinkCustom(
                  column,
                  rowAvatar[columnAvatar?.avatarDescField]
                )
              }
              style={{ marginTop: "2px", fontSize: "inherit" }}
              className={`text-truncate text-muted mb-0 ${
                columnAvatar?.avatarDescFieldType === "whatsapp" &&
                isWhatsappRow(valueFix)
                  ? "cursor-pointer fw-bolder d-flex align-items-center"
                  : ""
              }`}
            >
              {valueFix}
              {columnAvatar?.avatarDescFieldType === "whatsapp" &&
                isWhatsappRow(valueFix) && (
                  // renderIcon("fontAwesome:whatsapp", `ms-25 text-success`, 16)
                  <span style={{ width: "12px" }} className="ms-25">
                    <svg
                      fill="rgba(var(--bs-success-rgb), var(--bs-text-opacity))"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                    </svg>
                  </span>
                )}
            </small>
          )}

          {columnAvatar?.avatarDescFieldType === "whatsapp" &&
            isWhatsappRow(valueFix) && (
              <UncontrolledTooltip
                target={`row_custom_${columnAvatar?.avatarDescField?.replace(
                  ".",
                  ""
                )}`}
              >
                {t("Click To Chat Via Whatsapp")}
              </UncontrolledTooltip>
            )}
        </Fragment>
      )
    }

    return (
      <div
        onClick={(e) => {
          e.preventDefault()
          // eslint-disable-next-line no-unused-expressions
          columnAvatar?.clickToEdit &&
          !columnAvatar?.clickToLink &&
          ability.can("update", sessionStorage.getItem("current_page_perm")) &&
          paramsRoutes["pageType"] !== "view"
            ? clickToDetailEvent(rowAvatar)
            : null
        }}
        className={`d-flex justify-content-left align-items-center ${
          columnAvatar?.className ?? ""
        }`}
      >
        {avatarImg}
        <div className="d-flex flex-column">{avatarTitleEl}</div>
      </div>
    )
  }

  function parseDate(str) {
    var mdy = str.split("-")
    return new Date(mdy[2], mdy[0] - 1, mdy[1])
  }

  function dataFormating(fieldData, dataFormatChild) {
    Moment.locale(i18n.language)

    let result = ""
    if ((dataFormatChild ?? dataFormat)?.includes(":") && fieldData) {
      const columnTypeFormat = (dataFormatChild ?? dataFormat).split(":")
      const columnType = columnTypeFormat[0]
      const columnFormat =
        columnTypeFormat.length > 2
          ? `${columnTypeFormat[1]}:${columnTypeFormat[2]}`
          : columnTypeFormat[1]
      let columnFormated = fieldData
      if (columnType === "date") {
        columnFormated = Moment(fieldData).format("DD MMM YYYY")
        if (columnFormat) {
          columnFormated = Moment(fieldData).format(columnFormat)
        }
      } else if (columnType === "year") {
        columnFormated = Moment(`${fieldData}`).format("YYYY")
        if (columnFormat) {
          columnFormated = Moment(`${fieldData}`).format(columnFormat)
        }
      } else if (columnType === "time") {
        const timeStr = fieldData
        const timeRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
        let newData = fieldData
        if (timeRegex.test(timeStr)) {
          newData = new Date()

          const [hours, minutes, seconds] = timeStr.split(":").map(Number)

          newData.setHours(hours)
          newData.setMinutes(minutes)
          newData.setSeconds(seconds)

          // console.log("Valid Date:", newData)
        } else {
          // console.log("Invalid Date, cannot convert to datetime")
        }

        columnFormated = Moment(`${newData}`).format("HH:mm")
        if (columnFormat) {
          columnFormated = Moment(`${newData}`).format(columnFormat)
        }
      } else if (columnType === "datetime") {
        columnFormated = Moment(fieldData).format("DD MMM YYYY HH:mm")
        if (columnFormat) {
          columnFormated = Moment(fieldData).format(columnFormat)
        }
      }

      result = columnFormated
    }
    if (result === "Invalid date") {
      return ""
    }
    return result
  }

  function calcVal(inputName) {
    if (
      inputName.indexOf("*") ||
      inputName.indexOf("+") ||
      inputName.indexOf("-") ||
      inputName.indexOf("/")
    ) {
      let otherValueFieldName = []
      let operator = null
      if (inputName.includes("*")) {
        otherValueFieldName = inputName.split("*")
        operator = "*"
      } else if (inputName.includes("+")) {
        otherValueFieldName = inputName.split("+")
        operator = "+"
      } else if (inputName.includes("-")) {
        otherValueFieldName = inputName.split("-")
        operator = "-"
      } else if (inputName.includes("/")) {
        otherValueFieldName = inputName.split("/")
        operator = "/"
      }

      if (otherValueFieldName?.length > 1) {
        let input1 =
          row?.[otherValueFieldName[0]] ??
          getDepthValue(row, otherValueFieldName[0]) ??
          (column?.valueType === "string" ? "" : otherValueFieldName[0])
        let input2 =
          row?.[otherValueFieldName[1]] ??
          getDepthValue(row, otherValueFieldName[1]) ??
          (column?.valueType === "string" ? "" : otherValueFieldName[1])
        // console.log(row, inputName, input1, input2)
        if (input1 && input2) {
          let evals
          if (column?.valueType === "datetime") {
            // if (!isNaN(input1) && !isNaN(input2)) {
            input1 = new Date(Date.parse(input1))
            input2 = new Date(Date.parse(input2))
            if (operator === "-") {
              evals = Math.round((input1 - input2) / (1000 * 60 * 60 * 24))
            } else if (operator === "+") {
              evals = Math.round((input1 + input2) / (1000 * 60 * 60 * 24))
            }
            // }
          } else if (column?.valueType === "time") {
            const dateNow = `01/01/2023`
            const dateNow2 = `01/02/2023`
            let dateTime1 = new Date(`${dateNow} ${input1}`)
            let dateTime2 = new Date(`${dateNow2} ${input2}`)

            if (operator === "-") {
              if (dateTime1 < dateTime2) {
                dateTime1 = new Date(`${dateNow2} ${input1}`)
              }
              evals = dateTime1.getHours() - dateTime2.getHours()
              if (evals < 0) {
                evals = 24 + evals
              }
              // evals = Math.round(
              //   (dateTime1.getTime() - dateTime2.getTime()) /
              //     (1000 * 60 * 60 * 24)
              // )
            } else if (operator === "+") {
              // evals = Math.round(
              //   (dateTime1.getTime() + dateTime2.getTime()) /
              //     (1000 * 60 * 60 * 24)
              // )
              evals = dateTime1.getHours() + dateTime2.getHours()
              if (evals < 0) {
                evals = 24 + evals
              }
            }
          } else if (column?.valueType === "string") {
            if (operator === "+") {
              const calcFromOtherSeparator =
                column?.calcFromOtherSeparator ?? " "
              if (dataFormat) {
                const input1Format = `${dataFormating(input1)}`
                evals = `${input1Format}${
                  input1Format ? calcFromOtherSeparator : ""
                }${dataFormating(input2)}`
              } else {
                evals = `${input1}${
                  input1 ? calcFromOtherSeparator : ""
                }${input2}`
              }
              return evals
            }
          } else {
            var regExp = /[a-zA-Z]/g
            if (!regExp.test(input1) && !regExp.test(input2)) {
              evals = eval(`${input1}${operator}${input2}`)
            }
          }
          return !isNaN(evals) ? evals : ""
        }
      }
    } else {
      return row?.[inputName] ?? getDepthValue(row, inputName)
    }
  }

  function getDepthValue(obj, path, defaultValue) {
    let props
    if (typeof obj === "undefined") return defaultValue
    if (typeof path === "string") {
      props = path?.split(".").reverse()
    } else {
      props = path
    }
    if (path?.length === 0) return obj || defaultValue
    let current = props?.pop()
    return getDepthValue(obj?.[current], props, defaultValue)
  }

  const getColor = (value) => {
    let color = colors.secondary.light
    if (value < 10) {
      color = colors.secondary.main
    } else if (value < 30) {
      color = colors.danger.main
    } else if (value < 50) {
      color = colors.warning.main
    } else if (value < 70) {
      color = colors.primary.main
    } else if (value < 90) {
      color = colors.info.main
    } else {
      color = colors.success.main
    }
    return color
  }

  if (fieldType === "custom" && column?.children) {
    // console.log(column.children)

    const getPrefix = (columnChild) => {
      let resultPrefix = ""
      if (
        columnChild?.columnName &&
        !columnChild?.prefixIcon &&
        !columnChild?.icon
      ) {
        resultPrefix = <span className="me-50">{columnChild?.columnName}:</span>
      } else if (columnChild?.prefixIcon || columnChild?.icon) {
        resultPrefix = renderIcon(
          columnChild?.prefixIcon ?? columnChild?.icon,
          `me-50`,
          16
        )
      }
      return resultPrefix
    }

    let detailColumnEl = []
    let detailFields = []
    for (let i in column.children) {
      const childRow = column.children[i]
      let fieldName = childRow?.fieldName
      let rowValue = fieldName?.includes(".")
        ? getDepthValue(row, fieldName)
        : row[fieldName] ?? ""

      if (childRow?.type === "column" && childRow?.children) {
        let innerColumn = []
        for (let ii in childRow.children) {
          const childRowColumn = childRow.children[ii]
          let fieldNameColumn = childRowColumn?.fieldName
          let rowValueColumn = fieldNameColumn.includes(".")
            ? getDepthValue(row, fieldNameColumn)
            : row[fieldNameColumn]
          // console.log(fieldNameColumn)
          // console.log(rowValueColumn)
          if (
            Array.isArray(fieldNameColumn) &&
            childRowColumn?.type !== "avatar"
          ) {
            for (let indexColumn in fieldNameColumn) {
              rowValueColumn = `${rowValueColumn} ${
                fieldNameColumn[indexColumn].includes(".")
                  ? getDepthValue(row, fieldNameColumn[indexColumn])
                  : row[fieldNameColumn[indexColumn]]
              }`
            }
          } else {
            let avatarInitial = renderAvatarTitle(childRowColumn, row)
            // if (Array.isArray(childRowColumn?.avatarInitialField)) {
            //   for (let avatarIndex in childRowColumn.avatarInitialField) {
            //     avatarInitial = `${avatarInitial} ${
            //       childRowColumn?.avatarInitialField[avatarIndex].includes(".")
            //         ? getDepthValue(
            //             row,
            //             childRowColumn?.avatarInitialField[avatarIndex]
            //           )
            //         : row[childRowColumn?.avatarInitialField[avatarIndex]]
            //     }`
            //   }
            // } else {
            //   avatarInitial = childRowColumn?.avatarInitialField.includes(".")
            //     ? getDepthValue(row, childRowColumn?.avatarInitialField)
            //     : row[childRowColumn?.avatarInitialField]
            // }

            if (!row[fieldNameColumn] && !getDepthValue(row, fieldNameColumn)) {
              rowValueColumn = (
                <Avatar
                  initials
                  className={childRowColumn?.className ?? ""}
                  color={
                    childRowColumn?.avatarColorField
                      ? getDepthValue(row, childRowColumn?.avatarColorField)
                      : childRowColumn?.color
                  }
                  content={avatarInitial ?? ""}
                />
              )
            } else {
              rowValueColumn = (
                <Avatar
                  className={childRowColumn?.className ?? ""}
                  imgClassName={childRowColumn?.imgClassName ?? ""}
                  color={
                    childRowColumn?.avatarColorField
                      ? getDepthValue(row, childRowColumn?.avatarColorField)
                      : childRowColumn?.color
                  }
                  img={
                    fieldNameColumn.includes(".")
                      ? getDepthValue(row, fieldNameColumn)
                      : row[fieldNameColumn]
                  }
                />
              )
            }
          }

          if (rowValueColumn) {
            innerColumn.push(
              <div
                style={{ width: "100%" }}
                key={`row_custom_column_${ii}`}
                className={`mb-0 ${childRowColumn?.className ?? ""}`}
              >
                {childRowColumn?.type !== "avatar" && (
                  <span key={`span_custom_column_${ii}`} className="fw-bolder">
                    {getPrefix(childRowColumn)}
                  </span>
                )}{" "}
                {rowValueColumn}
              </div>
            )
          }
        }

        if (innerColumn) {
          detailColumnEl.push(
            <Col
              className={`${childRow?.className}`}
              onClick={(e) => {
                // console.log(childRow)
                e.preventDefault()
                // eslint-disable-next-line no-unused-expressions
                ability.can(
                  "update",
                  sessionStorage.getItem("current_page_perm")
                ) && paramsRoutes["pageType"] !== "view"
                  ? clickToDetailEvent(row)
                  : null
              }}
              key={`row_col_custom_column_${i}`}
              md={childRow?.size}
              xs={childRow?.sizeXs ?? 4}
            >
              {/* <Link
                className="text-body"
                to={`${useLocation().pathname}/edit/${row.id}`}
              > */}
              {innerColumn}
              {/* </Link> */}
            </Col>
          )
        }
      } else {
        // let detailFields = []
        if (Array.isArray(fieldName)) {
          for (let index in fieldName) {
            rowValue = `${rowValue} ${
              fieldName[index].includes(".")
                ? getDepthValue(row, fieldName[index])
                : row[fieldName[index]]
            }`
          }
        }

        let valueFix = childRow?.dataFormat
          ? dataFormating(
              rowValue
                ?.replaceAll("null", "")
                ?.replaceAll("undefined", "")
                ?.replaceAll("None", ""),
              childRow?.dataFormat
            )
          : rowValue
              ?.replaceAll("null", "")
              ?.replaceAll("undefined", "")
              ?.replaceAll("None", "")
        if (valueFix) {
          detailFields.push(
            <div
              key={`row_custom_${i}`}
              id={`row_custom_${i}`}
              style={{
                marginTop: "0.15rem",
                marginBottom: "0.15rem"
              }}
              className={`d-flex align-items-center ${
                childRow?.className ?? ""
              } ${
                isWhatsappRow(valueFix) && childRow?.type === "whatsapp"
                  ? "cursor-pointer fw-bolder d-flex align-items-center"
                  : ""
              }`}
              onClick={() => handleClickLinkCustom(childRow, valueFix)}
            >
              {/* <Link
              className="text-body"
              to={`${useLocation().pathname}/edit/${row.id}`}
            > */}
              <span key={`row_custom_span_${i}`} className="fw-bolder">
                {getPrefix(childRow)}
              </span>
              <span>{valueFix}</span>
              {childRow?.type === "whatsapp" && isWhatsappRow(valueFix) && (
                // renderIcon("fontAwesome:whatsapp", `ms-25 text-success`, 16)
                <span style={{ width: "12px" }} className="ms-25 text-success">
                  <svg
                    fill="rgba(var(--bs-success-rgb), var(--bs-text-opacity))"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                  </svg>
                </span>
              )}

              {childRow?.type === "whatsapp" && isWhatsappRow(valueFix) && (
                <UncontrolledTooltip target={`row_custom_${i}`}>
                  {t("Click To Chat Via Whatsapp")}
                </UncontrolledTooltip>
              )}

              {/* </Link> */}
            </div>
          )
        }
      }
    }

    detailColumnEl.push(
      <Col
        className={`${column?.className}`}
        onClick={(e) => {
          // console.log(column)
          e.preventDefault()
          // eslint-disable-next-line no-unused-expressions
          column?.clickToEdit &&
          !column?.clickToLink &&
          ability.can("update", sessionStorage.getItem("current_page_perm")) &&
          paramsRoutes["pageType"] !== "view"
            ? clickToDetailEvent(row)
            : null
        }}
        key={`col_${fieldNameEl}`}
        md={null}
      >
        {detailFields}
      </Col>
    )
    return (
      <Row
        key={`Row-${fieldNameEl}`}
        style={{ width: "100%" }}
        className="justify-content-left align-items-center"
      >
        {detailColumnEl}
      </Row>
      // <div className="d-flex justify-content-left align-items-center">
      //   {detailColumnFields}
      //   <div className="d-flex flex-column">{detailFields}</div>
      // </div>
    )
  } else {
    let result = ""
    // console.log(fieldData)
    if (
      dataFormat &&
      (row?.id || row?.id !== 0) &&
      fieldData &&
      !column?.calcFromOther &&
      !column?.avatarDescFieldFormat
    ) {
      result = dataFormating(fieldData)
    } else if (
      fieldType === "avatar_title" &&
      (row?.id || row?.id !== 0) &&
      !String(row?.id).includes("xx")
    ) {
      // let avatarTitle = renderAvatarTitle(column, row)
      // let avatarImg
      // if (
      //   column?.avatarImgField &&
      //   (row[column?.avatarImgField]?.length ||
      //     getDepthValue(row, column?.avatarImgField))
      // ) {
      //   avatarImg = (
      //     <Avatar
      //       className={`me-1 ${column?.avatarClassName}` ?? ""}
      //       imgClassName={column?.imgClassName}
      //       img={
      //         column?.avatarImgField?.includes(".")
      //           ? getDepthValue(row, column?.avatarImgField)
      //           : row[column?.avatarImgField] ?? ""
      //       }
      //       imgWidth={column?.avatarWidth ?? "32"}
      //       imgHeight={column?.avatarHeight ?? "32"}
      //       imgStyle={column?.avatarImgStyle}
      //       // avatarStyle={column?.avatarStyle}
      //       // style={{ borderRadius: "20%", ...(column?.avatarStyle ?? {}) }}
      //       // imgHeight={column?.avatarHeight ?? column?.avatarWidth ?? "32"}
      //       // imgWidth={column?.avatarWidth ?? column?.avatarHeight ?? "32"}
      //     />
      //   )
      // } else {
      //   if (
      //     avatarTitle?.length > 1 &&
      //     avatarTitle !== " " &&
      //     avatarTitle !== "  "
      //   ) {
      //     avatarImg = (
      //       <Avatar
      //         initials
      //         className="me-1"
      //         color={
      //           column?.avatarColorField
      //             ? getDepthValue(row, column?.avatarColorField)
      //             : column?.color
      //         }
      //         content={avatarTitle}
      //         length={column?.avatarInitialSize ?? 2}
      //       />
      //     )
      //   } else {
      //     avatarImg = <span></span>
      //   }
      // }

      // let avatarTitleEl
      // if (column?.clickToLink) {
      //   avatarTitleEl = (
      //     <Fragment>
      //       <Link to={`${clickToLinkUrl}&isOrigin=true`} className="text-body">
      //         <span className="fw-bolder">{avatarTitle}</span>
      //       </Link>
      //       {column?.avatarDescField && (
      //         <small
      //           style={{ marginTop: "2px" }}
      //           className="text-truncate text-muted mb-0"
      //         >
      //           {column?.avatarDescField?.includes(".")
      //             ? column?.avatarDescFieldFormat
      //               ? dataFormating(getDepthValue(row, column?.avatarDescField))
      //               : getDepthValue(row, column?.avatarDescField)
      //             : column?.avatarDescFieldFormat
      //             ? dataFormating(row[column?.avatarDescField] ?? "")
      //             : row[column?.avatarDescField] ?? ""}
      //         </small>
      //       )}
      //     </Fragment>
      //   )
      // } else {
      //   avatarTitleEl = (
      //     <Fragment>
      //       <span>{avatarTitle}</span>
      //       {column?.avatarDescField && (
      //         <small
      //           style={{ marginTop: "2px" }}
      //           className="text-truncate text-muted mb-0"
      //         >
      //           {column?.avatarDescField?.includes(".")
      //             ? column?.avatarDescFieldFormat
      //               ? dataFormating(getDepthValue(row, column?.avatarDescField))
      //               : getDepthValue(row, column?.avatarDescField)
      //             : column?.avatarDescFieldFormat
      //             ? dataFormating(row[column?.avatarDescField] ?? "")
      //             : row[column?.avatarDescField] ?? ""}
      //         </small>
      //       )}
      //     </Fragment>
      //   )
      // }

      // result = (
      //   <div
      //     onClick={(e) => {
      //       e.preventDefault()
      //       // eslint-disable-next-line no-unused-expressions
      //       column?.clickToEdit &&
      //       !column?.clickToLink &&
      //       ability.can(
      //         "update",
      //         sessionStorage.getItem("current_page_perm")
      //       ) &&
      //       paramsRoutes["pageType"] !== "view"
      //         ? clickToDetailEvent(row)
      //         : null
      //     }}
      //     className={`d-flex justify-content-left align-items-center ${
      //       column?.className ?? ""
      //     }`}
      //   >
      //     {avatarImg}
      //     <div className="d-flex flex-column">{avatarTitleEl}</div>
      //   </div>
      // )
      result = renderAvatarTitleComp(column, row)
    } else if (
      column?.lookupValue &&
      !column.dataFrom &&
      (row?.id || row?.id !== 0)
    ) {
      let selected = column.lookupValue.filter(function (el) {
        return el.value === fieldData
      })[0]
      if (fieldType === "badge") {
        result = (
          <Badge
            className={`text-capitalize ${selected?.class}`}
            color={selected?.color ?? "light-primary"}
            pill={column?.pill ?? true}
            style={{
              padding: "0.5rem 0.8rem"
            }}
          >
            {selected?.label ?? ""}
          </Badge>
        )
      } else if (fieldType !== "badge" && (selected?.icon || selected?.img)) {
        result = (
          <span
            // style={{ display: "inline-flex" }}
            className={`text-truncate align-items-center d-flex ${selected?.class}`}
          >
            {selected?.icon ? (
              renderIcon(selected?.icon, `me-25 ${selected?.class}`, null, {
                minWidth: "15px",
                textAlign: "center",
                fontSize: "inherit"
              })
            ) : (
              <img
                style={{
                  maxWidth: "59px",
                  height: "auto",
                  ...(selected?.imgStyle ?? {})
                }}
                className="me-50"
                src={selected?.img}
                alt={selected?.label}
                height="23"
              />
            )}
            {selected?.label}
          </span>
        )
      } else {
        result = (
          <span
            className={`text-truncate align-items-center d-flex ${selected?.class}`}
          >
            {selected?.label}
          </span>
        )
      }
    } else if (fieldType === "currency" && fieldData !== "") {
      const storageLang = localStorage.getItem("i18nextLng")
      const currentLang = storageLang === "id" ? "id-ID" : "en-US"
      const currentCurrency =
        column?.currencyCode ?? (storageLang === "id" ? "IDR" : "USD")
      /////FOR SUB-TOTAL
      if (!row?.id || row?.id === 0) {
        result = (
          <Fragment>
            {currencyCodes?.includes(currentCurrency) ? (
              <span
                id={`id_${fieldNameEl}_${row?.key}`}
                key={`span-key-${fieldNameEl}_${row?.key}`}
                className="fw-bolder"
              >
                {!column?.hideCurrencySymbol
                  ? new Intl.NumberFormat(currentLang, {
                      style: "currency",
                      currency: currentCurrency
                    }).format(fieldData ?? 0)
                  : new Intl.NumberFormat(currentLang, {
                      minimumFractionDigits: column?.currencyFractionDigits ?? 2
                    }).format(fieldData ?? 0)}
              </span>
            ) : (
              <span
                id={`id_${fieldNameEl}_${row?.key}`}
                key={`span-key-${fieldNameEl}_${row?.key}`}
                className="fw-bolder"
              >
                {fieldData}
              </span>
            )}
            <UncontrolledTooltip
              placement="right"
              target={`id_${fieldNameEl}_${row?.key}`}
            >
              {t("Subtotal of")} {t(column?.columnName)}
            </UncontrolledTooltip>
          </Fragment>
        )
      } else {
        result = currencyCodes?.includes(currentCurrency) ? (
          <span
            value={fieldData}
            key={`span-key-${fieldNameEl}`}
            className="text-right text-end"
          >
            {!column?.hideCurrencySymbol
              ? new Intl.NumberFormat(currentLang, {
                  style: "currency",
                  currency: currentCurrency
                }).format(fieldData ?? 0)
              : new Intl.NumberFormat(currentLang, {
                  minimumFractionDigits: column?.currencyFractionDigits ?? 2
                }).format(fieldData ?? 0)}
          </span>
        ) : (
          <span
            value={fieldData}
            key={`span-key-${fieldNameEl}`}
            className="text-right text-end"
          >
            {fieldData}
          </span>
        )
      }
    } else if (fieldType === "progress") {
      const chart = {
        type: "radialBar",
        series: [fieldData ?? 0],
        height: 30,
        width: 30,
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15
            }
          },
          colors: [getColor(fieldData)],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%"
              },
              track: {
                background: trackBgColor
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false
                },
                value: {
                  show: false
                }
              }
            }
          },
          stroke: {
            lineCap: "round"
          }
        }
      }
      result = (
        <div className="d-flex align-items-center">
          <div className="fw-bold text-body-heading me-1"> {fieldData}%</div>
          <Chart
            options={chart.options}
            series={chart.series}
            type={chart.type}
            height={chart.height}
            width={chart.width}
          />
        </div>
      )
    } else if (fieldType === "boolean") {
      if (column?.valueType === "string") {
        result = renderIcon(
          fieldData ? "fontAwesome:square-check" : "fontAwesome:square-xmark",
          `align-middle ${fieldData ? "text-success" : "text-danger"}`,
          16
        )
      } else {
        result = renderIcon(
          fieldData === true
            ? "fontAwesome:square-check"
            : "fontAwesome:square-xmark",
          `align-middle ${fieldData === true ? "text-success" : "text-danger"}`,
          16
        )
      }
    } else if (!column?.lookupValue && column?.dataFrom) {
      let selected = dynamicTempState.data[fieldName]?.filter(function (el) {
        return el[column.dataFrom?.field_value] === fieldData
      })[0]
      const field_label =
        selected?.[column.dataFrom?.field_label] ?? selected?.label
      const field_icon =
        selected?.[column.dataFrom?.field_icon] ?? selected?.icon
      const field_class =
        selected?.[column.dataFrom?.field_class] ??
        selected?.class ??
        selected?.className ??
        "secondary"

      let field_class_prefix =
        field_class && field_class?.includes("text-")
          ? field_class
          : `text-${field_class}`
      const field_color =
        getDepthValue(selected, column.dataFrom?.field_color) ??
        selected?.[column.dataFrom?.field_color] ??
        selected?.color ??
        "light-primary"
      if (fieldType === "badge") {
        result = (
          <Badge
            className={`${column?.className ?? ""} text-capitalize ${
              fieldType === "currency" || column?.subtotal || column?.right
                ? "text-right text-end"
                : "text-left text-start"
            }  ${column?.center && !column?.right ? "text-center" : ""}`}
            color={field_color}
            style={{
              padding: "0.5rem 0.8rem"
            }}
            pill={column?.pill ?? true}
          >
            {field_label}
          </Badge>
        )
      } else if (fieldType !== "badge" && field_icon) {
        result = (
          <span
            className={`text-truncate align-items-center d-flex ${field_class_prefix}`}
          >
            {/* <Icon
            size={18}
            className={`${
              roleObj[row.role_name] ? roleObj[row.role_name].class : ""
            } me-50`}
          /> */}
            {renderIcon(field_icon, `me-25 ${field_class_prefix}`, null, {
              minWidth: "15px",
              textAlign: "center",
              fontSize: "inherit"
            })}
            {field_label}
          </span>
        )
      } else {
        result = (
          <span
            className={`text-truncate align-items-center d-flex ${field_class_prefix}`}
          >
            {field_label}
          </span>
        )
      }
    } else if (column?.valueAsLink && !fileType) {
      let splitVal = fieldDataStr
      // console.error(fieldDataStr, fieldData)
      try {
        if (fieldData) {
          const urlParts = fieldData.split("/")
          const lastPart = urlParts[urlParts.length - 1]

          if (lastPart.includes(".")) {
            const ext = lastPart.split(".").pop()
            // console.log(
            //   `URL ini mengandung tautan ke file. Ekstensi file: ${ext}`
            // )
          } else {
            // console.log("URL ini tidak mengandung tautan ke file.")
          }
        } else {
          // console.log("URL ini tidak mengandung tautan ke file.")
        }
      } catch (err) {
        // console.error(err)
      }
      result = (
        <span className="fw-bolder">
          <a target="_blank" href={fieldData}>
            {splitVal}
          </a>
        </span>
      )
    } else if (fieldType === "array") {
      if (column?.dataFromField) {
        const arrayData = row[column.dataFromField]
        const minData = 7

        if (arrayData) {
          const lefData =
            arrayData.length > minData ? arrayData.length - minData + 1 : null
          let dataRender = []
          if (column?.isAvatarGroup) {
            arrayData.map((item, index) => {
              if (index < minData) {
                let linkUrl = null
                if (column?.isAvatarGroupOption?.clickToLinkUrl) {
                  linkUrl = `${column?.isAvatarGroupOption?.clickToLinkUrl}`
                  if (column?.isAvatarGroupOption?.clickToLinkFieldId) {
                    linkUrl = `${column?.isAvatarGroupOption?.clickToLinkUrl}/${
                      item[column?.isAvatarGroupOption?.clickToLinkFieldId]
                    }?refresh=1`
                  }
                }
                dataRender.push({
                  title:
                    item[column?.isAvatarGroupOption?.fields].replaceAll(
                      ".",
                      ""
                    ) ?? "",
                  placement: "bottom",
                  tooltipPopOver: column?.isAvatarGroupOption?.tooltipPopOver,
                  img: item[column?.isAvatarGroupOption?.img] ?? "",
                  imgHeight: 33,
                  imgWidth: 33,
                  linkUrl: linkUrl,
                  imgStyle: column?.isAvatarGroupOption?.imgStyle
                })
              }
            })

            // if (column?.isAvatarGroupOption?.styleMode === 2) {
            //   dataRender.push({
            //     styleMode: 2
            //   })
            // }

            if (lefData && column?.isAvatarGroupOption?.styleMode !== 2) {
              dataRender.push({
                meta: `+${lefData}`
              })
            }

            if (column?.isAvatarGroupOption?.styleMode === 2) {
              let compResult = (
                <div>
                  {dataRender.map((item, i) => (
                    <div>
                      <div
                        className={`d-flex justify-content-left align-items-center`}
                      >
                        {item?.img ? (
                          <Avatar
                            className="pull-up my-25"
                            {...item}
                            imgStyle={item.imgStyle}
                            title={undefined}
                            meta={undefined}
                          />
                        ) : (
                          <Avatar
                            initials
                            imgStyle={item.imgStyle}
                            className="pull-up my-25"
                            color="light-primary"
                            content={`${item.title}`}
                          />
                        )}
                        <div className="ms-50 d-flex flex-column">
                          {item.title}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )
              result = compResult
            } else {
              result = <AvatarGroup data={dataRender} />
            }
          } else {
            let compResult = arrayData.map((item, index) => {
              let title = item[column?.fields].replaceAll(".", "") ?? ""
              if (index < minData) {
                return (
                  <div
                    className={`d-flex justify-content-left align-items-center`}
                  >
                    <div className="d-flex flex-column">- {title}</div>
                  </div>
                )
              }
            })
            result = compResult
          }
        }
      } else {
        const arrayData = fieldData
        const minData = 7
        if (arrayData) {
          const lefData =
            arrayData.length > minData ? arrayData.length - minData + 1 : null
          let dataRender = []
          arrayData.map((item, index) => {
            if (index < minData) {
              let linkUrl = null
              dataRender.push(
                <Badge className="me-1" color="light-primary">
                  {item[column?.arrayField] ?? ""}
                </Badge>
              )
            }
          })
          if (lefData) {
            dataRender.push(<Badge color="light-primary">+{lefData}</Badge>)
          }
          result = dataRender
        }
      }
    } else if (fileType) {
      let fileTypeImg = doc
      if (getFileType === "pdf") {
        fileTypeImg = pdf
      } else if (getFileType === "txt") {
        fileTypeImg = txt
      } else if (getFileType === "docx") {
        fileTypeImg = docx2
      } else if (getFileType === "doc") {
        fileTypeImg = docx1
      } else if (getFileType === "xls") {
        fileTypeImg = excel
      } else if (getFileType === "xlsx") {
        fileTypeImg = excel
      } else if (getFileType === "jpg") {
        fileTypeImg = jpg
      } else if (getFileType === "jpeg") {
        fileTypeImg = jpeg
      } else if (getFileType === "png") {
        fileTypeImg = png
      } else if (getFileType === "mp3") {
        fileTypeImg = mp3
      }

      let splitVal = fieldDataStr
      try {
        splitVal = fieldData?.split("/")?.pop()
      } catch (err) {
        // console.error(err)
      }

      result = (
        <div className="d-flex align-items-center">
          <img
            className="me-50"
            src={fileTypeImg}
            alt={getFileType ?? ""}
            // height="23"
            style={{
              width: "20px",
              maxHeight: "25px"
            }}
          />

          <span className="mb-0">
            <a target="_blank" href={fieldData}>
              {splitVal}
            </a>
          </span>
        </div>
      )
    } else {
      if (fieldType === "badge") {
        let badgeColor = column?.color ?? "light-primary"
        let badgeIcon = column?.icon
        if (column?.colorFromField) {
          badgeColor = getDepthValue(row, column?.colorFromField) ?? badgeColor
        }
        if (column?.iconFromField) {
          badgeIcon = getDepthValue(row, column?.iconFromField) ?? badgeIcon
        }
        result = (
          <Badge
            className={`${column?.className ?? ""} text-capitalize ${
              fieldType === "currency" || column?.subtotal || column?.right
                ? "text-right text-end"
                : "text-left text-start"
            }  ${column?.center && !column?.right ? "text-center" : ""} ${
              badgeIcon ? " d-flex align-items-center" : ""
            }`}
            color={badgeColor}
            style={{
              padding: "0.5rem 0.8rem"
            }}
            pill={column?.pill ?? true}
          >
            {badgeIcon && renderIcon(badgeIcon, `align-middle me-25`, 12)}
            {fieldDataStr}
          </Badge>
        )
      } else if (fieldType === "icon") {
        result = renderIcon(fieldDataStr, ``, 35)
      } else if (fieldType === "static" || column?.value) {
        result = column?.value
      } else {
        if (column?.className) {
          result = (
            <span key={`span-key-${fieldNameEl}`} className={column.className}>
              {fieldDataStr}
            </span>
          )
          if (
            fieldDataStr?.length > 130 ||
            column.className?.includes("text-truncate")
          ) {
            // addDefaultClass = "text-truncate"
            result = (
              <Fragment>
                <span
                  style={{
                    maxWidth: column?.width ?? "350px"
                  }}
                  id={`id_${fieldNameEl}_${idRow}_span`}
                  key={`span-key-${fieldNameEl}`}
                  className={`${column.className} text-truncate`}
                >
                  {fieldDataStr}
                </span>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`id_${fieldNameEl}_${idRow}_span`}
                >
                  {fieldDataStr}
                </UncontrolledTooltip>
              </Fragment>
            )
          }
        } else {
          if (fieldDataStr?.length > 130) {
            // addDefaultClass = "text-truncate"

            result = (
              <Fragment>
                <span
                  id={`id_${fieldNameEl}_span`}
                  key={`span-key-${fieldNameEl}`}
                  className="text-wrap"
                  style={{
                    maxWidth: column?.width ?? "350px"
                  }}
                >
                  {fieldDataStr}
                </span>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`id_${fieldNameEl}_span`}
                >
                  {fieldDataStr}
                </UncontrolledTooltip>
              </Fragment>
            )
          } else if (fieldDataStr?.length > 50 && fieldDataStr?.length < 130) {
            result = <span key={`span-key-${fieldNameEl}`}>{fieldDataStr}</span>
          } else {
            result = fieldDataStr
          }
        }

        if (!row?.id || row?.id === 0) {
          result = (
            <Fragment>
              <span
                id={`id_${fieldNameEl}_${row?.key}`}
                key={`span-key-${fieldNameEl}_${row?.key}`}
                className="fw-bolder"
              >
                {fieldDataStr}
              </span>
              <UncontrolledTooltip
                placement="right"
                target={`id_${fieldNameEl}_${row?.key}`}
              >
                {t("Subtotal of")} {t(column?.columnName)}
              </UncontrolledTooltip>
            </Fragment>
          )
        }

        // else {
        //   result = (
        //     <span value={fieldData} key={`span-key-${fieldName}`}>
        //       {fieldDataStr}
        //     </span>
        //   )
        // }
      }
    }

    /////FOR SUB-TOTAL
    // if (!row?.id || row?.id === 0) {
    //   if (fieldType === "currency") {
    //     if (fieldData !== "") {
    //       const storageLang = localStorage.getItem("i18nextLng")
    //       const currentLang = storageLang === "id" ? "id-ID" : "en-US"
    //       const currentCurrency = storageLang === "id" ? "IDR" : "USD"
    //       result = (
    //         <Fragment>
    //           <span
    //             id={`id_${fieldName}_${row?.key}`}
    //             key={`span-key-${fieldName}_${row?.key}`}
    //             className="fw-bolder"
    //           >
    //             {new Intl.NumberFormat(currentLang, {
    //               style: "currency",
    //               currency: currentCurrency
    //             }).format(fieldData)}
    //           </span>
    //           <UncontrolledTooltip
    //             placement="right"
    //             target={`id_${fieldName}_${row?.key}`}
    //           >
    //             {t("Subtotal of")} {t(column.columnName)}
    //           </UncontrolledTooltip>
    //         </Fragment>
    //       )
    //     } else {
    //       result = ""
    //     }
    //   } else {
    //     result = (
    //       <span key={`span-key-${fieldName}`} className="fw-bolder">
    //         {fieldData}
    //       </span>
    //     )
    //   }
    // }

    if (clickToLinkUrl && fieldType !== "avatar_title") {
      if (fieldType === "badge") {
        let selected =
          dynamicTempState.data[fieldName]?.filter(function (el) {
            return el[column.dataFrom?.field_value] === fieldData
          })[0] ?? column
        fieldDataStr = (
          <Badge
            className={`${column?.className ?? ""} text-capitalize ${
              fieldType === "currency" || column?.subtotal || column?.right
                ? "text-right text-end"
                : "text-left text-start"
            }  ${column?.center && !column?.right ? "text-center" : ""}`}
            color={
              getDepthValue(selected, column?.colorFromField) ??
              selected?.color ??
              "light-primary"
            }
            style={{
              padding: "0.5rem 0.8rem"
            }}
            pill={column?.pill ?? true}
          >
            {selected?.label ?? fieldDataStr}
          </Badge>
        )
      }

      result = (
        <Link
          style={{
            overflow: "hidden"
          }}
          className={`text-body fw-bolder ${column?.className ?? ""}`}
          to={`${clickToLinkUrl}&isOrigin=true`}
        >
          {fieldDataStr}
        </Link>
      )
    }

    if (fieldType === "html") {
      result = <div dangerouslySetInnerHTML={{ __html: fieldDataStr }} />
    }

    if (fieldType === "qrcode") {
      result = (
        <QRCodeSVG size={column?.qrcode_size ?? 40} value={fieldDataStr} />
      )
    }

    if (fieldType === "img_slider") {
      let dataFromSource = column?.dataFrom
      let array_fieldName = dataFromSource?.array_fieldName
      let dataSliders = []
      if (array_fieldName) {
        let array_of_object_mapKey = dataFromSource?.array_of_object_mapKey

        if (Array.isArray(array_fieldName)) {
          array_fieldName?.map((field, indexxx) => {
            const fieldValue = fieldName?.includes(".")
              ? getDepthValue(row, field)
              : row?.[field]
            if (fieldValue) {
              dataSliders.push(fieldValue)
            }
          })
        } else if (typeof array_fieldName === "string") {
          const fieldValueArr = array_fieldName?.includes(".")
            ? getDepthValue(row, array_fieldName)
            : row?.[array_fieldName]

          if (fieldValueArr && Array.isArray(fieldValueArr)) {
            if (!array_of_object_mapKey) {
              fieldValueArr?.map((field, indexxx) => {
                // console.log(field)
                dataSliders.push(field)
              })
            } else {
              fieldValueArr?.map((field, indexxx) => {
                let fieldValueDetail = field?.[array_of_object_mapKey]
                if (fieldValueDetail) {
                  dataSliders.push(fieldValueDetail)
                }
              })
            }
          }
        }
      }

      result = (
        <Swiper
          style={{
            borderRadius: "5px",
            zIndex: 0
          }}
          {...params}
          {...(column?.sliderOpt ?? {})}
        >
          {dataSliders?.map((rowSlide, indexxxxxx) => {
            return (
              <SwiperSlide key={`swiper_${indexxxxxx}`}>
                <img
                  style={{ width: "100%", borderRadius: "5px" }}
                  src={rowSlide}
                  height="auto"
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )
    }

    let resultFinal = result

    if (
      column?.editable &&
      paramsRoutes["pageType"] !== "view" &&
      row?.id &&
      row?.id !== 0 &&
      !String(row?.id).includes("xx") &&
      !disabledEditable
      // && !clickToDetailEvent
    ) {
      if (
        column?.editableInputOpt?.dataFrom?.value &&
        Array.isArray(column?.editableInputOpt?.dataFrom?.value)
      ) {
        const selected = column?.editableInputOpt?.dataFrom?.value?.find(
          (c) => `${c.value}` === `${resultFinal}`
        )
        resultFinal = selected?.label
        if (fieldType === "badge") {
          resultFinal = (
            <Badge
              className="text-capitalize"
              color={selected?.color ?? "light-primary"}
              pill={column?.pill ?? true}
              style={{
                padding: "0.5rem 0.8rem"
              }}
            >
              {selected?.label}
            </Badge>
          )
        } else if (fieldType !== "badge" && selected?.icon) {
          resultFinal = (
            <span className="text-truncate text-capitalize align-middle">
              {renderIcon(selected?.icon, `me-50 ${selected?.class}`)}
              {selected?.label}
            </span>
          )
        }
      }

      result = (
        <Fragment>
          <div
            id={`cell_edit_${fieldNameEl}_${row?.id}`}
            className={`editable_row ${column?.className ?? ""} ${
              fieldType === "currency" || column?.subtotal || column?.right
                ? "text-right text-end"
                : ""
            } ${column?.center && !column?.right ? "text-center" : ""}`}
            style={{
              cursor: "pointer",
              width: "100%"
              // , height: "100%"
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              // eslint-disable-next-line no-unused-expressions
              ability.can(
                "update",
                sessionStorage.getItem("current_page_perm")
              ) &&
              (row?.id || row?.id !== 0 || !String(row?.id).includes("xx")) &&
              paramsRoutes["pageType"] !== "view"
                ? clickToDetailEvent(row, column)
                : null
            }}
          >
            {fieldDataStr?.length > 0 ? resultFinal : "-"}
          </div>
          {row?.id !== 0 && !String(row?.id).includes("xx") ? (
            <UncontrolledTooltip
              placement="left"
              target={`cell_edit_${fieldNameEl}_${row?.id}`}
            >
              {t("Click to edit")} {t(column.columnName)}
            </UncontrolledTooltip>
          ) : null}
        </Fragment>
      )
    }

    return column?.textStyle ? <span style={textStyle}>{result}</span> : result
  }
}

export default DynamicRow
