export const columnsProperties = {
  // fieldName: {
  //   size: 3,
  //   fieldLabel: "fieldName / Key",
  //   isDisabled: true
  // },
  columnName: {
    size: 3,
    fieldLabel: "Column Title"
  },
  type: {
    size: 3,
    fieldLabel: "Column Type",
    type: "dropdown",
    initialValue: "text",
    required: true,
    afterChangeFillOther: ["dataFormat=value2"],
    dataFrom: {
      value: [
        {
          value: "text",
          label: "Text"
        },
        {
          value: "badge",
          label: "Badge"
        },
        {
          value: "currency",
          label: "Currency"
        },
        {
          value: "progress",
          label: "Progress"
        },
        {
          value: "boolean",
          label: "Boolean"
        },
        {
          value: "array",
          label: "Array"
        },
        {
          value: "icon",
          label: "Icon"
        },
        {
          value: "static",
          label: "Static"
        },
        {
          value: "html",
          label: "Html"
        },
        {
          value: "custom",
          label: "Custom"
        },
        {
          value: "date",
          label: "Date",
          value2: "date:DD MMM YYYY"
        },
        {
          value: "datetime",
          label: "Date & Time",
          value2: "datetime:DD MMM YYYY HH:mm"
        },
        {
          value: "year",
          label: "Year",
          value2: "year:"
        },
        {
          value: "time",
          label: "Time",
          value2: "time:HH:mm"
        }
      ]
    }
  },

  ////EDITABLE
  //   editable: {
  //     type: "radio",
  //     fieldLabel: "Editable",
  //     size: 4,
  //     dataFrom: {
  //       value: [
  //         {
  //           value: true,
  //           label: "Yes",
  //           color: "primary",
  //           selected: true
  //         },
  //         {
  //           value: false,
  //           label: "No",
  //           color: "secondary"
  //         }
  //       ],
  //       labelTranslations: true
  //     }
  //   },
  //   editableInputOpt: {
  //     dev_childAttr: "editable",
  //     showIf: [
  //       {
  //         fieldName: "editable",
  //         operator: "===",
  //         value: true
  //       }
  //     ],
  //     type: "column",
  //     // fieldLabel: "Editable Input Setting",
  //     size: 4,
  //     children: [
  //       {
  //         fieldName: "type",
  //         fieldLabel: "Input Type",
  //         type: "dropdown",
  //         dataFrom: {
  //           value: [
  //             {
  //               value: "text",
  //               label: "Text"
  //             },
  //             {
  //               value: "number",
  //               label: "Number"
  //             },
  //             {
  //               value: "number-btn",
  //               label: "Number With Button"
  //             },
  //             {
  //               value: "currency",
  //               label: "Currency"
  //             }
  //           ]
  //         }
  //       }
  //     ]
  //   },

  // BADGE ATTRIBUTE
  color: {
    fieldLabel: "Badge Color",
    size: 3,
    type: "dropdown",
    initialValue: "primary",
    showIf: [{ fieldName: "type", operator: "===", value: "badge" }],
    dataFrom: {
      value: [
        {
          value: "primary",
          label: "Primary",
          class: "text-primary bg-primary",
          icon: "material:radio_button_checked"
        },
        {
          value: "success",
          label: "Success",
          class: "text-success bg-success",
          icon: "material:radio_button_checked"
        },
        {
          value: "info",
          label: "Info",
          class: "text-info bg-info",
          icon: "material:radio_button_checked"
        },
        {
          value: "warning",
          label: "Warning",
          class: "text-warning bg-warning",
          icon: "material:radio_button_checked"
        },
        {
          value: "danger",
          label: "Danger",
          class: "text-danger bg-danger",
          icon: "material:radio_button_checked"
        },
        {
          value: "secondary",
          label: "Secondary",
          class: "text-secondary bg-secondary",
          icon: "material:radio_button_checked"
        },
        {
          value: "dark",
          label: "Dark",
          class: "text-dark bg-dark",
          icon: "material:radio_button_checked"
        },
        {
          value: "light-primary",
          label: "Light Primary",
          class: "text-light-primary bg-light-primary",
          icon: "material:radio_button_checked"
        },
        {
          value: "light-success",
          label: "Light Success",
          class: "text-light-success bg-light-success",
          icon: "material:radio_button_checked"
        },
        {
          value: "light-info",
          label: "Light Info",
          class: "text-light-info bg-light-info",
          icon: "material:radio_button_checked"
        },
        {
          value: "light-warning",
          label: "Light Warning",
          class: "text-light-warning bg-light-warning",
          icon: "material:radio_button_checked"
        },
        {
          value: "light-danger",
          label: "Light Danger",
          class: "text-light-danger bg-light-danger",
          icon: "material:radio_button_checked"
        },
        {
          value: "light-secondary",
          label: "Light Secondary",
          class: "text-light-secondary bg-light-secondary",
          icon: "material:radio_button_checked"
        },
        {
          value: "light-dark",
          label: "Light Dark",
          class: "text-light-dark bg-light-dark",
          icon: "material:radio_button_checked"
        }
      ]
    }
  },

  // Date/Datetime/year/time ATTRIBUTE
  dataFormat: {
    fieldLabel: "Date Format",
    size: 3,
    showIf: [
      {
        fieldName: "type",
        operator: "in",
        value: ["date", "datetime", "year", "time"]
      }
    ],
    tooltip: "example: date:DD MMM YYYY"
  },

  ////COMMON ATTRIBUTE TABLE
  width: {
    dev_fieldGroup: "table",
    fieldLabel: "Width",
    size: 3,
    tooltip: "example: 125px / 0.2em / 40% / 100 / 50vw "
  },
  subtotal: {
    dev_fieldGroup: "table",
    fieldLabel: "Subtotal",
    size: 3,
    type: "radio",
    dataFrom: {
      value: [
        {
          value: true,
          label: "Yes"
        },
        {
          value: false,
          label: "No"
        }
      ]
    },
    tooltip: "for auto subtotal calculation"
  },
  center: {
    dev_fieldGroup: "table",
    fieldLabel: "Center",
    size: 3,
    type: "radio",
    dataFrom: {
      value: [
        {
          value: true,
          label: "Yes"
        },
        {
          value: false,
          label: "No"
        }
      ]
    },
    tooltip: "for center alignment"
  },

  ////COMMON ATTRIBUTE GRID
  hideColumnName: {
    dev_fieldGroup: "grid_card",
    fieldLabel: "Hide Column Title",
    size: 3,
    type: "radio",
    initialValue: 0,
    dataFrom: {
      value: [
        {
          value: 1,
          label: "Yes",
          value2: 0
        },
        {
          value: 0,
          label: "No",
          value2: 0
        }
      ]
    },
    afterChangeFillOther: ["inline_value=value2"]
  },
  inline_value: {
    dev_fieldGroup: "grid_card",
    fieldLabel: "Inline Value",
    size: 3,
    initialValue: 0,
    type: "radio",
    // showIf: [
    //   {
    //     fieldName: "hideColumnName",
    //     operator: "===",
    //     value: 0
    //   }
    // ],
    dataFrom: {
      value: [
        {
          value: 1,
          label: "Yes"
        },
        {
          value: 0,
          label: "No"
        }
      ]
    }
  }
}
