// ** React Imports
import { useEffect, Fragment, useState, useRef } from "react"

// ** Third Party Components
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"

// ** Vertical Menu Components
import VerticalMenuHeader from "./VerticalMenuHeader"
import VerticalNavMenuItems from "./VerticalNavMenuItems"

import { useSelector, useDispatch } from "react-redux"
import { ReactSortable } from "react-sortablejs"
import {
  updateMenu,
  updateMenuSortToApi
} from "@src/views/apps/menu/store/my_menu"
import { isAdminApp, restrictedMenuLink, getUserData } from "@utils"
const Sidebar = (props) => {
  // ** Props
  const { menuCollapsed, menu, skin, menuData, parentItem } = props
  const dispatch = useDispatch()
  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const storeDev = useSelector((state) => state.developer)
  const storeMenu = useSelector((state) => state.my_menu)
  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef(null)
  // const userData = getUserData()
  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    setMenuHover(true)
  }

  const doOrder = (newState, sortable, store) => {
    const lastMenuList = JSON.stringify(
      Array.from(menuData).map((child) => child.id) ?? []
    )
    const newMenuList = JSON.stringify(
      Array.from(newState).map((child) => child.id) ?? []
    )
    // console.log(lastMenuList)
    // console.log(newMenuList)
    // console.log(lastMenuList !== newMenuList)
    if (
      store.dragging &&
      store.dragging.props &&
      // JSON.stringify(menuData) !== JSON.stringify(newState) &&
      lastMenuList !== newMenuList &&
      JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState) &&
      !storeMenu.isLoadingPostPut
    ) {
      // console.log("doOrder1", newState)
      // console.log(newState)
      // console.log(sortable)
      // e.preventDefault()
      dispatch(updateMenu(newState)).then((res) => {
        // console.log(res)
      })
    }
  }
  // const sortableRef = useRef(null)
  // const [isEscKeyPressed, setIsEscKeyPressed] = useState(false)

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     console.log("handleKeyDown", event)
  //     if (event.key === "Escape") {
  //       setIsEscKeyPressed(true)
  //     }
  //   }

  //   const handleKeyUp = (event) => {
  //     if (event.key === "Escape") {
  //       setIsEscKeyPressed(false)
  //     }
  //   }
  //   // if (sortableRef.current && sortableRef.current.container) {

  //   document.addEventListener("keydown", handleKeyDown)
  //   // sortableRef.current.container.addEventListener("keyup", handleKeyUp)
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown)
  //     // sortableRef.current.container.removeEventListener("keyup", handleKeyUp)
  //   }
  //   // }
  // }, [isEscKeyPressed])

  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.add("d-block")
      }
    } else {
      if (shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.remove("d-block")
      }
    }
  }

  // useEffect(() => {
  //   console.log(parentItem)
  //   console.log(groupActive)
  // }, [menuData])

  // const getMenuData = () => {
  //   return menuData
  // }

  // let isEscKeyPressedDuringDrag = false
  // let escPressStartTime

  return (
    <Fragment>
      <div
        className={classnames(
          "main-menu menu-fixed menu-accordion menu-shadow",
          {
            expanded: menuHover || menuCollapsed === false,
            "menu-light": skin !== "semi-dark" && skin !== "dark",
            "menu-dark": skin === "semi-dark" || skin === "dark"
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu({ ...props })
        ) : (
          <Fragment>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader
              setGroupOpen={setGroupOpen}
              menuHover={menuHover}
              {...props}
            />
            {/* Vertical Menu Header Shadow */}
            <div className="shadow-bottom" ref={shadowRef}></div>
            {/* Perfect Scrollbar */}
            <PerfectScrollbar
              className="main-menu-content"
              options={{ wheelPropagation: false }}
              onScrollY={(container) => scrollMenu(container)}
            >
              {storeDev.editMode ? (
                // <SortableTree
                //   tag="ul"
                //   className="navigation navigation-main"
                //   // treeData={menuData}
                //   treeData={getTreeFromFlatData({
                //     flatData: menuData.map((node) => ({
                //       ...node,
                //       title: node.title
                //     })),
                //     getKey: (node) => `${node.id}`,
                //     getParentKey: (node) => `${node.parent_id}`,
                //     rootKey: null
                //   })}
                //   // theme={FileExplorerTheme}
                //   // generateNodeProps={({ node, path }) => ({})}
                // />
                <ReactSortable
                  // ref={sortableRef}
                  dataIdAttr="data-id"
                  animation={150}
                  // group={"nested"}
                  group={{ name: `root`, pull: true, put: true }}
                  // delayOnTouchStart={true}
                  delay={10}
                  fallbackOnBody={true}
                  swapThreshold="0.65"
                  tag="ul"
                  className={`navigation navigation-main group_menu_${menuData[0]?.parent_id}`}
                  cancelledClass="sortable-cancelled"
                  list={menuData}
                  // list={getMenuData()}
                  filter=".disabled-drag"
                  // draggable=".nav-item"
                  setList={doOrder}
                  // swap={true}
                  // swapClass={"highlight"}
                  // nested={true}
                  // onStart={(evt) => {
                  //   evt.fromOriginalOrder = Array.from(evt.from.children).map(
                  //     (child) => child.dataset.id
                  //   )
                  //   console.log(Array.from(menuData).map((child) => child.id))
                  // }}
                  onEnd={(evt) => {
                    let itemEl = evt.item
                    // const { newIndex, oldIndex, pullMode, fromOriginalOrder } =
                    //   evt
                    // console.log(Array.from(menuData).map((child) => child.id))
                    // Periksa apakah operasi drag-and-drop dibatalkan
                    // if (
                    //   JSON.stringify(fromOriginalOrder) ===
                    //   JSON.stringify(
                    //     Array.from(evt.from.children).map(
                    //       (child) => child.dataset.id
                    //     )
                    //   )
                    // ) {
                    //   console.log("Operasi drag-and-drop dibatalkan")
                    // } else {
                    //   console.log("Operasi drag-and-drop selesai")
                    // }
                    // console.log(evt.newIndex)
                    // console.log(itemEl.getAttribute("data-id"))
                    const id = parseInt(
                      itemEl.getAttribute("data-id").replace("menu_", "")
                    )
                    dispatch(
                      updateMenuSortToApi({
                        id: id,
                        index: evt.newIndex
                      })
                    ).then((res) => {
                      // console.log(res)
                    })
                  }}
                  onMove={(evt) => {
                    const anchorElement = evt.related.querySelector("li a")
                    if (anchorElement) {
                      const url = anchorElement.getAttribute("href")
                      if (restrictedMenuLink.includes(url)) {
                        return false
                      }
                    } else {
                    }
                    return true
                  }}
                >
                  <VerticalNavMenuItems
                    items={menuData}
                    menuData={menuData}
                    menuHover={menuHover}
                    groupOpen={groupOpen}
                    activeItem={activeItem}
                    groupActive={groupActive}
                    setGroupOpen={setGroupOpen}
                    menuCollapsed={menuCollapsed}
                    setActiveItem={setActiveItem}
                    setGroupActive={setGroupActive}
                    currentActiveGroup={currentActiveGroup}
                    setCurrentActiveGroup={setCurrentActiveGroup}
                    devMode={storeDev.editMode}
                  />
                </ReactSortable>
              ) : (
                <ul className="navigation navigation-main">
                  <VerticalNavMenuItems
                    items={menuData}
                    menuData={menuData}
                    menuHover={menuHover}
                    groupOpen={groupOpen}
                    activeItem={activeItem}
                    groupActive={groupActive}
                    setGroupOpen={setGroupOpen}
                    menuCollapsed={menuCollapsed}
                    setActiveItem={setActiveItem}
                    setGroupActive={setGroupActive}
                    currentActiveGroup={currentActiveGroup}
                    setCurrentActiveGroup={setCurrentActiveGroup}
                  />
                </ul>
              )}
            </PerfectScrollbar>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sidebar
