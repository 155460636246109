/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable multiline-ternary */
import {
  getDepthValue,
  neutralizeBack,
  revivalBack,
  isNestedObjectArray,
  flattenArray,
  arrayToNested,
  updateExpandedToFalse
} from "@src/utility/Utils"
import { AbilityContext } from "@src/utility/context/Can"
import debounce from "lodash.debounce"
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from "react"
import UILoader from "@components/ui-loader"
import {
  ErrorFetchData,
  Loader,
  NoDataDisplay,
  TableSkeletonLoading
} from "@src/shared/CommonComponents"
import { Edit2, Info, Trash2 } from "react-feather"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalAddEdit from "./modal"
import {
  deleteData,
  deleteSelectedRowData,
  getDataById,
  getDataList,
  putDataTree,
  resetSelectedData,
  setDataList,
  setSelectedData,
  expandCollapseAllTree,
  setViewOnlyTree
} from "./store"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Skeleton } from "@mui/material"
import SortableTree, {
  toggleExpandedForAll
} from "@nosferatu500/react-sortable-tree"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import { renderIcon } from "@src/views/apps/menu/store/my_menu"
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import toast from "react-hot-toast"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useSkin } from "@hooks/useSkin"
import { Fullscreen, FullscreenExit } from "@mui/icons-material"
import CustomHeader from "./CustomHeaders"
const MySwal = withReactContent(Swal)
const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
))
import * as go from "gojs"
import DynamicForm from "../../utility/generateForm/dynamicForm"

const OrgChart2 = ({ pageAttr, storeName, filtersCustomAdd }) => {
  const { moduleId, pageId, id, relPageId, relPageType, relId } = useParams()

  const dispatch = useDispatch()
  const store = useSelector((state) => state.dynamicStore)

  const { t } = useTranslation()
  const ability = useContext(AbilityContext)

  const [filteredData, setFilteredData] = useState([])

  const { colors } = useContext(ThemeColors)
  const [sort_dir, setSortDir] = useState("desc")
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(store.params?.page ?? 1)
  const [sort, setSort] = useState("id")
  const [rowsPerPage, setRowsPerPage] = useState(store.params?.length ?? 10)
  const [selectedRows, setSelectedRows] = useState([])

  const [filtersCustom, setFiltersCustom] = useState(filtersCustomAdd ?? {})

  const [toggleCleared, setToggleCleared] = useState(false)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(true)
  const [loadingPostPut, setLoadingPostPut] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingText, setLoadingText] = useState("Please Wait")
  const [showModal, setShowModal] = useState(false)
  const [pageType, setPageType] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const diagramRef = useRef(null)
  const idField = pageAttr?.crudOpt?.treeviewIdField ?? "id"
  const parentIdField = pageAttr?.crudOpt?.treeviewParentIdField ?? "parent_id"
  const titleField = pageAttr?.crudOpt?.treeviewTitleField ?? "title" ?? "name"
  const codeField = pageAttr?.crudOpt?.treeviewAddSubIncrementField
  const indexField = pageAttr?.crudOpt?.treeviewIndexField ?? "index" ?? "order"
  const iconField = pageAttr?.crudOpt?.treeviewIconField ?? "icon"
  const [newDataTree, setNewDataTree] = useState({})
  const [loadingFirstOnceInit, setLoadingFirstOnceInit] = useState(true)
  const [myDiagramState, setMyDiagramState] = useState(null)
  const { skin } = useSkin()
  const colorsClass = [
    "primary",
    "info",
    "success",
    "warning",
    "secondary",
    "danger"
  ]

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [oldStyleBackgroundColor, setOldStyleBackgroundColor] = useState("")
  // const [selectedData, setSelectedData] = useState(null)
  const requestFullscreen = () => {
    const element = document.getElementById("org_chart_container")
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }

    setIsFullscreen(true)
  }

  const exitFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }

    setIsFullscreen(false)
  }

  function transformToTargetFormat(data) {
    const result = {
      class: "go.GraphLinksModel",
      nodeDataArray: [],
      linkDataArray: []
    }
    const idMap = {}

    for (const item of data) {
      const key = item[idField].toString()
      const parentKey =
        item[parentIdField] !== null
          ? item[parentIdField].toString()
          : undefined

      const node = {
        ...item,
        key,
        name: item[titleField],
        title: item[titleField]
      }

      result.nodeDataArray.push(node)

      if (parentKey !== undefined) {
        const link = {
          from: parentKey,
          to: key
        }
        result.linkDataArray.push(link)
      }

      idMap[key] = node
    }

    return result
  }

  let myDiagram

  function mayWorkFor(node1, node2) {
    if (!(node1 instanceof go.Node)) return false
    if (node1 === node2) return false
    if (node2.isInTreeOf(node1)) return false
    return true
  }

  function nodeDoubleClick(e, obj) {
    let clicked = obj.part
    if (clicked !== null) {
      let thisemp = clicked.data
      myDiagram.startTransaction("add data")
      let nextkey = (myDiagram.model.nodeDataArray.length + 100).toString()
      // console.log(nextkey)
      // console.log(thisemp)
      let newemp = { key: nextkey, name: "(new data)", title: "" }
      myDiagram.model.addNodeData(newemp)
      myDiagram.model.addLinkData({ from: thisemp.key, to: nextkey })
      myDiagram.commitTransaction("add data")
    }
  }

  function onSelectionChanged(e) {
    // dispatch(resetSelectedData(storeName))
    setShowModal(false)
    let node = e.diagram.selection.first()
    // console.log(node)
    if (node instanceof go.Node) {
      // console.log(node.data)
      updateProperties(node.data)
    } else {
      setShowModal(false)
      dispatch(resetSelectedData(storeName))
      updateProperties(null)
    }
  }

  function updateProperties(data) {
    // setSelectedData(data)

    if (data === null) {
      // document.getElementById("propertiesPanel").style.display = "none"
      // document.getElementById("name").value = ""
      // document.getElementById("title").value = ""
      // document.getElementById("comments").value = ""
    } else {
      if (!pageAttr?.crudOpt?.detailFromGrid) {
        // setLoading(false)
        setShowModal(true)
        dispatch(getDataById(data[idField], storeName)).then((res) => {
          setLoadingModal(false)
        })
      } else {
        // console.log("setSelectedData")
        dispatch(setSelectedData({ rowData: data, storeName: storeName })).then(
          (res) => {
            setLoadingModal(false)
            setShowModal(true)
          }
        )
      }

      // document.getElementById("propertiesPanel").style.display = "block"
      // document.getElementById("name").value = data.name || ""
      // document.getElementById("title").value = data.title || ""
      // document.getElementById("comments").value = data.comments || ""
    }
  }

  const handleFormChangeFromChild = (data) => {
    console.log(data)
    let node = myDiagramState?.selection?.first()
    // console.log(node)
    if (node instanceof go.Node && data !== null) {
      let model = myDiagramState.model
      console.log(model)
      model.startTransaction(`modified data`)
      model.setDataProperty(data, "name", data?.[titleField])
      model.setDataProperty(data, "title", data?.[titleField])
      // if (field === "name") {
      //   model.setDataProperty(data, "name", text)
      // } else if (field === "title") {
      //   model.setDataProperty(data, "title", text)
      // } else if (field === "comments") {
      //   model.setDataProperty(data, "comments", text)
      // }
      model.commitTransaction(`modified data`)
    }
  }

  function onTextEdited(e) {
    let tb = e.subject
    if (tb === null || !tb.name) return
    let node = tb.part
    if (node instanceof go.Node) {
      updateData(tb.text, tb.name)
      updateProperties(node.data)
    }
  }

  function updateData(text, field) {
    let node = myDiagram.selection.first()
    let data = node.data
    if (node instanceof go.Node && data !== null) {
      let model = myDiagram.model
      model.startTransaction(`modified ${field}`)
      if (field === "name") {
        model.setDataProperty(data, "name", text)
      } else if (field === "title") {
        model.setDataProperty(data, "title", text)
      } else if (field === "comments") {
        model.setDataProperty(data, "comments", text)
      }
      model.commitTransaction(`modified ${field}`)
    }
  }

  // function save() {
  //   document.getElementById("mySavedModel").value = myDiagram.model.toJson()
  //   myDiagram.isModified = false
  // }

  function load(data) {
    // console.log(JSON.stringify(data))
    // console.log(document.getElementById("mySavedModel").value)
    // myDiagram.model = go.Model.fromJson(
    //   document.getElementById("mySavedModel").value
    // )
    // console.log(JSON.stringify(store.pageData[storeName]?.data))
    const savedModel = go.Model.fromJson(JSON.stringify(data))
    if (savedModel && savedModel.modelData) {
      myDiagram.model = savedModel
    } else {
      console.error("Invalid saved model format")
    }
    setMyDiagramState(myDiagram)
  }

  function init(data) {
    const $ = go.GraphObject.make

    myDiagram = $(go.Diagram, "myDiagramDiv", {
      validCycle: go.Diagram.CycleDestinationTree,
      maxSelectionCount: 1,
      layout: $(go.TreeLayout, {
        treeStyle: go.TreeLayout.StyleLastParents,
        arrangement: go.TreeLayout.ArrangementHorizontal,
        angle: 90,
        layerSpacing: 35,
        alternateAngle: 0,
        alternateLayerSpacing: 35,
        alternateAlignment: go.TreeLayout.AlignmentStart,
        alternateNodeIndent: 10,
        alternateNodeIndentPastParent: 1.0,
        alternateNodeSpacing: 10,
        alternateLayerSpacingParentOverlap: 1.0,
        alternatePortSpot: new go.Spot(0.01, 1, 10, 0),
        alternateChildPortSpot: go.Spot.Left
      }),
      // selectionAdorned: true,
      // selectionChanged: nodeSelectionChanged,
      ChangedSelection: onSelectionChanged,
      TextEdited: onTextEdited,
      "linkingTool.archetypeLinkData": { category: "Support", text: "100%" },
      "undoManager.isEnabled": true,
      InitialLayoutCompleted: function (e) {
        // Signal that the diagram is fully initialized
        // diagramInitialized()
        // setLoadingFirstOnceInit(true)
      }
    })

    // // Menerapkan fungsi pencarian pada perubahan searchKeyword
    // myDiagram.addDiagramListener("InitialLayoutCompleted", function (e) {
    //   searchDiagram(myDiagram, searchTerm)
    // })

    // // Event handler untuk mencari setiap kali nilai pencarian berubah
    // myDiagram.addDiagramListener("TextEdited", function (e) {
    //   if (e.diagram.selection.count === 0) {
    //     // Jika tidak ada node yang dipilih, jalankan pencarian
    //     searchDiagram(e.diagram, searchTerm)
    //   }
    // })

    let graygrad = $(go.Brush, "Linear", {
      0: "rgb(125, 125, 125)",
      0.5: "rgb(86, 86, 86)",
      1: "rgb(86, 86, 86)"
    })

    myDiagram.nodeTemplate = $(
      go.Node,
      "Auto",
      { doubleClick: nodeDoubleClick },
      {
        mouseDragEnter: (e, node, prev) => {
          let diagram = node.diagram
          let selnode = diagram.selection.first()
          if (!mayWorkFor(selnode, node)) return
          let shape = node.findObject("SHAPE")
          if (shape) shape.fill = "darkred"
        },
        mouseDragLeave: (e, node, next) => {
          let shape = node.findObject("SHAPE")
          if (shape) shape.fill = graygrad
        },
        mouseDrop: (e, node) => {
          let diagram = node.diagram
          let selnode = diagram.selection.first()
          if (mayWorkFor(selnode, node)) {
            let link = selnode.findTreeParentLink()
            if (link !== null) {
              link.fromNode = node
            } else {
              diagram.toolManager.linkingTool.insertLink(
                node,
                node.port,
                selnode,
                selnode.port
              )
            }
          }
        }
      },
      new go.Binding("text", "name"),
      new go.Binding("layerName", "isSelected", (sel) =>
        sel ? "Foreground" : ""
      ).ofObject(),
      $(go.Shape, "RoundedRectangle", {
        name: "SHAPE",
        fill: graygrad,
        stroke: "black",
        portId: "",
        fromLinkable: true,
        toLinkable: true,
        cursor: "pointer"
      }),
      $(
        go.Panel,
        "Table",
        {
          maxSize: new go.Size(150, 999),
          margin: new go.Margin(3, 3, 0, 3),
          defaultAlignment: go.Spot.Left
        },
        $(go.RowColumnDefinition, { column: 2, width: 4 }),
        $(
          go.TextBlock,
          {
            row: 0,
            column: 0,
            columnSpan: 5,
            font: "bold 9pt sans-serif",
            editable: true,
            isMultiline: false,
            stroke: "white",
            minSize: new go.Size(10, 14),
            name: "name"
          },
          new go.Binding("text", "name").makeTwoWay()
        ),
        // $(go.TextBlock, "Title: ", textStyle(), { row: 1, column: 0 }),
        // $(
        //   go.TextBlock,
        //   textStyle(),
        //   {
        //     row: 1,
        //     column: 1,
        //     columnSpan: 4,
        //     editable: true,
        //     isMultiline: false,
        //     minSize: new go.Size(10, 14),
        //     margin: new go.Margin(0, 0, 0, 3),
        //     name: "title"
        //   },
        //   new go.Binding("text", "title").makeTwoWay()
        // ),
        // $(go.TextBlock, "ID: ", textStyle(), { row: 2, column: 0 }),
        // $(
        //   go.TextBlock,
        //   textStyle(),
        //   { row: 2, column: 1 },
        //   new go.Binding("text", "key")
        // ),
        // $(go.TextBlock, "Boss: ", textStyle(), { row: 2, column: 3 }),
        // $(
        //   go.TextBlock,
        //   textStyle(),
        //   { row: 2, column: 4 },
        //   new go.Binding("text", "parent")
        // ),
        // $(
        //   go.TextBlock,
        //   {
        //     row: 3,
        //     column: 0,
        //     columnSpan: 5,
        //     font: "italic 9pt sans-serif",
        //     wrap: go.TextBlock.WrapFit,
        //     editable: true,
        //     stroke: "white",
        //     minSize: new go.Size(10, 14),
        //     name: "comments"
        //   },
        //   new go.Binding("text", "comments").makeTwoWay()
        // ),
        $("TreeExpanderButton", {
          row: 4,
          columnSpan: 99,
          alignment: go.Spot.Center
        })
      )
    )

    myDiagram.linkTemplate = $(
      go.Link,
      go.Link.Orthogonal,
      { corner: 5 },
      $(go.Shape, { strokeWidth: 1 })
    )

    // myDiagram.linkTemplateMap.add(
    //   "Support",
    //   $(
    //     go.Link,
    //     go.Link.Bezier,
    //     { isLayoutPositioned: false, isTreeLink: false, curviness: -50 },
    //     { relinkableFrom: true, relinkableTo: true },
    //     $(go.Shape, { stroke: "green", strokeWidth: 1 }),
    //     $(go.Shape, {
    //       toArrow: "OpenTriangle",
    //       stroke: "green",
    //       strokeWidth: 2
    //     }),
    //     $(go.TextBlock, new go.Binding("text").makeTwoWay(), {
    //       stroke: "green",
    //       background: "rgba(255,255,255,0.75)",
    //       maxSize: new go.Size(80, NaN),
    //       editable: true
    //     })
    //   )
    // )

    // myDiagram.linkTemplateMap.add(
    //   "Motion",
    //   $(
    //     go.Link,
    //     go.Link.Bezier,
    //     { isLayoutPositioned: false, isTreeLink: false, curviness: -50 },
    //     { relinkableFrom: true, relinkableTo: true },
    //     $(go.Shape, { stroke: "orange", strokeWidth: 2 }),
    //     $(go.Shape, {
    //       toArrow: "OpenTriangle",
    //       stroke: "orange",
    //       strokeWidth: 2
    //     }),
    //     $(go.TextBlock, new go.Binding("text").makeTwoWay(), {
    //       stroke: "orange",
    //       background: "rgba(255,255,255,0.75)",
    //       maxSize: new go.Size(80, NaN),
    //       editable: true
    //     })
    //   )
    // )

    load(data)
  }

  function searchDiagram(myDiagram, searchQuery) {
    // called by button
    // let input = document.getElementById("mySearch")
    // console.log(searchQuery)
    if (!searchQuery) return
    // myDiagram.focus()

    myDiagram.startTransaction("highlight search")

    if (searchQuery) {
      // search four different data properties for the string, any of which may match for success
      // create a case insensitive RegExp from what the user typed
      let safe = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
      let regex = new RegExp(safe, "i")
      let results = myDiagram.findNodesByExample(
        { name: regex },
        { title: regex }
      )
      myDiagram.highlightCollection(results)
      // try to center the diagram at the first node that was found
      if (results.count > 0) myDiagram.centerRect(results.first().actualBounds)
    } else {
      // empty string only clears highlighteds collection
      myDiagram.clearHighlighteds()
    }

    myDiagram.commitTransaction("highlight search")
  }

  const checkAndTransormData = (dataFromResp) => {
    const respData = dataFromResp
    // console.log(respData)
    let flattenRespData
    if (respData?.length > 0 && isNestedObjectArray(respData)) {
      flattenRespData = flattenArray(respData)
    } else {
      flattenRespData = dataFromResp
    }
    // console.log(flattenRespData)

    if (flattenRespData) {
      let newRespData = transformToTargetFormat(flattenRespData)
      // console.log(newRespData)
      if (newRespData) {
        setNewDataTree(newRespData)
        init(newRespData)
      }
    }
  }

  useEffect(() => {
    // const myDiagramDiv = document.getElementById("myDiagramDiv")
    // if (myDiagramDiv) {
    //   try {
    //     // Check if the div already has a Diagram
    //     if (myDiagramDiv.__gojs_diagram__) {
    //       // Clear or dispose of the existing diagram
    //       myDiagramDiv.__gojs_diagram__.div = null
    //     }

    //     // Initialize the new diagram
    //     // const newDiagram = go.Diagram.fromDiv(myDiagramDiv)
    //     // myDiagramDiv.__gojs_diagram__ = newDiagram
    //   } catch (error) {
    //     console.error("Error initializing diagram:", error)
    //   }
    // }

    // console.log(storeName)
    // console.log(store.pageData)
    if (
      (!store.pageData[storeName]?.data || pageAttr?.crudOpt?.getAlwaysGrid) &&
      !store.pageData[storeName]?.isLoading &&
      store.pageData[storeName]?.status !== "failed"
    ) {
      setLoading(true)
      setLoadingText(`${t("Loading Data")} ${t(pageAttr.pageTitle)}`)

      dispatch(
        pageAttr?.crudOpt?.serverSideGrid
          ? getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              sort_dir,
              sort,
              search: searchTerm,
              page: currentPage,
              length: rowsPerPage,
              ...filtersCustom
            })
          : getDataList({
              storeName,
              endpointName: pageAttr?.crudOpt?.endpointName,
              ...filtersCustom
            })
      ).then((x) => {
        // console.log(x?.payload?.data)

        checkAndTransormData(x?.payload?.data)
        setLoading(false)
      })
    } else {
      //////CACHE ON RELATIONS CRUD
      if (relPageId || pageAttr?.isViewOnForm) {
        // console.log(store.pageData[storeName]?.params)
        // console.log(pageAttr?.crudOpt?.initialFiltersCondition)
        const lastParentData = storeParent?.selectedData
        let curPageLastParam = store.pageData[storeName]?.params
        let curPageAttrPageFilArray = pageAttr?.crudOpt?.initialFiltersCondition
        let reloadState = false

        if (curPageAttrPageFilArray) {
          if (curPageAttrPageFilArray?.length > 1 && lastParentData) {
            curPageAttrPageFilArray?.map((childPar) => {
              // console.log(childPar?.fieldNameCurrent)
              // console.log(lastParentData[childPar?.value_from_parentFieldName])
              reloadState =
                reloadState ||
                `${curPageLastParam[curPageAttrPageFilField]}` !==
                  `${lastParentData[childPar?.value_from_parentFieldName]}`
            })
          } else {
            const curPageAttrPageFilField =
              curPageAttrPageFilArray[0]?.fieldNameCurrent
            // console.log(
            //   curPageAttrPageFilField,
            //   `${curPageLastParam[curPageAttrPageFilField]}`,
            //   `${id}`
            // )
            reloadState =
              `${curPageLastParam?.[curPageAttrPageFilField]}` !== `${id}`
          }
        }
        if (reloadState) {
          if (!store.pageData[storeName]?.isLoading) {
            dispatch(
              pageAttr?.crudOpt?.serverSideGrid
                ? getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    sort_dir,
                    sort,
                    search: searchTerm,
                    page: currentPage,
                    length: rowsPerPage,
                    ...filtersCustom
                  })
                : getDataList({
                    storeName,
                    endpointName: pageAttr?.crudOpt?.endpointName,
                    ...filtersCustom
                  })
            ).then((x) => {
              // console.log(x)

              setLoading(false)
            })
          }
        }
      }

      checkAndTransormData(store.pageData[storeName]?.data)
      setLoading(false)
    }

    // return () => {
    //   if (myDiagramDiv) {
    //     const existingDiagram = myDiagramDiv.__gojs_diagram__
    //     if (existingDiagram) {
    //       existingDiagram.div = null
    //       existingDiagram.clear()
    //       existingDiagram.destroy()
    //     }
    //   }
    // }
  }, [])

  return (
    <UILoader
      blocking={
        loadingDelete || store?.pageData?.[storeName]?.isLoading || loading
      }
      loader={<Loader loadingText={loadingText} />}
    >
      <Card
        style={{
          minHeight: "60vh"
        }}
        id="org_chart_container"
        className="overflow-hidden"
      >
        {/* <CustomHeader
          pageAttr={pageAttr}
          searchTerm={searchTerm}
          storeName={storeName}
          handleFilter={searchDiagram}
          // expandAllRows={expandAllRows}
          // customHeaderSet={customHeaderSet}
        /> */}

        <div className="content-header-right text-md-end col-md-12 col-12 mb-2 pb-2 mt-1 ps-1">
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1">
            <label className="mb-0" htmlFor="search-invoice">
              {t("Search")}:
            </label>
            <Input
              id="search-invoice"
              className="ms-50"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              onClick={(e) =>
                !isFullscreen ? requestFullscreen() : exitFullscreen()
              }
              outline
              className="btn-icon ms-1"
              color="secondary"
            >
              {!isFullscreen ? <Fullscreen /> : <FullscreenExit />}
            </Button>
          </div>
          <div className="d-flex align-items-center mb-sm-0 mb-1 me-1"></div>
        </div>

        <Row className="mx-1 mb-2 align-items-start">
          <Col md={12}>
            <div className="mt-1 mt-md-0 mb-0 mb-1 mb-md-0 px-1">
              <div className="text-info">
                <Info size={13} className="ms-0" />
                <small className="ms-25 align-middle">
                  {t(
                    "Double click on a node to add a data. Drag a node onto another to change relationships."
                  )}
                </small>
              </div>
            </div>
          </Col>

          <Col
            md={12}
            className="react-dataTable react-dataTable-selectable-rows m-1"
            id="myDiagramDiv"
            // ref={diagramRef}
            // style={{
            //   height: "550px"
            // }}
            style={{
              display: store?.pageData?.[storeName]?.isLoading
                ? "none"
                : "inherit",
              height: "550px",
              minHeight: "40vh"
            }}
          ></Col>

          {showModal ? (
            <Col
              md={12}
              id="propertiesPanel"
              style={{
                // display: "none",
                position: isFullscreen ? "fixed" : "inherit",
                left: isFullscreen ? "0" : "inherit",
                bottom: isFullscreen ? "0" : "inherit",
                zIndex: 3
                // backgroundColor: "aliceblue",
                // border: "solid 1px black"
              }}
            >
              {store.pageData[storeName]?.selectedData && (
                <Card className="card-on-treeview mb-50">
                  <CardBody>
                    <DynamicForm
                      dynamicForm={
                        store.pageAttributes[storeName]?.crudOpt?.data
                          ?.dynamicForm
                      }
                      pageAttr={store.pageAttributes[storeName]}
                      storeName={storeName}
                      defaultFormValues={JSON.parse(
                        JSON.stringify(store.pageData[storeName]?.selectedData)
                      )}
                      hideAllFotterButton={true}
                      editForm={true}
                      pageType={pageType}
                      loadingFirstOnceInit={loadingFirstOnceInit}
                      handleFormChangeToParent={handleFormChangeFromChild}
                    />
                  </CardBody>
                </Card>
              )}
              {/* Name:{" "}
        <input
          type="text"
          id="name"
          value=""
          onChange={(e) => updateData(e.target.value, "name")}
        />
        <br />
        Title:{" "}
        <input
          type="text"
          id="title"
          value=""
          onChange={(e) => updateData(e.target.value, "title")}
        />
        <br />
        Comments:{" "}
        <input
          type="text"
          id="comments"
          value=""
          onChange={(e) => updateData(e.target.value, "comments")}
        />
        <br /> */}
            </Col>
          ) : null}
        </Row>
      </Card>
    </UILoader>
  )
}

export default OrgChart2
