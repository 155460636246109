// import { Badge, HStack, Image } from "@chakra-ui/react"
import Avatar from "@components/avatar"
import {
  disableBtnCondition,
  neutralizeBack,
  revivalBack
} from "@src/utility/Utils"
import DynamicRow from "@src/utility/generateForm/dynamicRow"
import { Fragment, useMemo, useContext } from "react"
import { ThemeColors } from "@src/utility/context/ThemeColors"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle
} from "reactstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"

const localeDateStringCache = {}
const toLocaleDateStringFactory = (locale) => (date, dateTimeOptions) => {
  const key = date.toString()
  let lds = localeDateStringCache[key]
  if (!lds) {
    lds = date.toLocaleDateString(locale, dateTimeOptions)
    localeDateStringCache[key] = lds
  }
  return lds
}

const TaskListColumn = (tasksData, pageAttr) => {
  // console.log(tasks)

  // const pageAttr = tasks?.[0]?.pageAttr
  const { t } = useTranslation()
  const defaultWidth = 400
  // const toLocaleDateString = useMemo(
  //   () => toLocaleDateStringFactory(locale),
  //   [locale]
  // )
  const { colors } = useContext(ThemeColors)
  const paramss = useParams()
  const storeMyUnit = useSelector((state) => state.units)

  const tasks = tasksData.tasks
  const rowHeight = tasksData.rowHeight
  const rowWidth = tasksData.rowWidth
  const fontSize = tasksData.fontSize
  const fontFamily = tasksData.fontFamily
  const locale = tasksData.locale
  const onExpanderClick = tasksData.onExpanderClick
  //   console.log(pageAttr)

  const data = tasks ?? []

  const getColumnWidthPercentage = (sizePercentStr) => {
    let sizeFix = 0
    if (sizePercentStr?.includes("%")) {
      // const sizePercent = parseFloat(sizePercentStr) / 100.0
      // sizeFix = `${defaultWidth * sizePercent}px`
      sizeFix = sizePercentStr
    } else {
      sizeFix = sizePercentStr
    }
    return sizeFix
  }

  const renderDtColumnProp = (column, rows) => {
    const row = rows?.rowData
    return (
      <DynamicRow
        row={row}
        column={column}
        // clickToDetailEvent={handleAddEdit}
      />
    )
  }

  const getTitle = (fieldName) => {
    try {
      const columnTitle = fieldName.replace(/([A-Z])/g, " $1")
      let columnTitleEnd =
        columnTitle?.charAt(0)?.toUpperCase() + columnTitle?.slice(1)
      columnTitleEnd = columnTitleEnd?.replaceAll("_", " ")
      return columnTitleEnd
    } catch (error) {
      return fieldName ?? ""
    }
  }

  const getColor = (value) => {
    let color = colors.secondary.light
    if (value < 10) {
      color = colors.secondary.main
    } else if (value < 30) {
      color = colors.danger.main
    } else if (value < 50) {
      color = colors.warning.main
    } else if (value < 70) {
      color = colors.primary.main
    } else if (value < 90) {
      color = colors.info.main
    } else {
      color = colors.success.main
    }
    return color
  }

  return (
    <div
      className={"taskListWrapper"}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        width: defaultWidth
      }}
    >
      {data.map((row, index) => {
        // console.log(row?.rowData)
        let expanderSymbol = ""
        if (row.hideChildren === false) {
          expanderSymbol = "▼"
        } else if (row.hideChildren === true) {
          expanderSymbol = "▶"
        }

        const dynamicColumns = pageAttr?.crudOpt?.data?.dynamicColumns

        return (
          <div
            className={"taskListTableRow"}
            style={{ height: rowHeight, width: defaultWidth }}
            key={`${row.id}row`}
          >
            {dynamicColumns?.map((column, index) => {
              const styleRow = {
                fontSize: fontSize ?? 10,
                fontFamily: fontFamily ?? 10,
                fontWeight: row.hideChildren ? "bold" : "normal",
                textAlign: column?.center
                  ? "center"
                  : column?.right
                  ? "right"
                  : "left",
                width: column.width
                  ? getColumnWidthPercentage(column.width)
                  : "auto"
                // maxWidth: column?.maxWidth ?? "100%",
                // minWidth: column?.minWidth ?? "20px"
              }

              return index > 0 ? (
                <div
                  className={"taskListCell"}
                  // style={{
                  //   // minWidth: rowWidth,
                  //   // maxWidth: rowWidth
                  // }}
                  key={`${row.id}_${index}_cell`}
                  style={styleRow}
                >
                  {renderDtColumnProp(column, row)}
                </div>
              ) : (
                <div
                  className={"taskListCell"}
                  // style={{
                  //   minWidth: rowWidth,
                  //   maxWidth: rowWidth
                  // }}
                  key={`${row.id}_${index}_cell`}
                  style={styleRow}
                  title={row[column.fieldName]}
                >
                  <div className={"taskListNameWrapper align-items-center"}>
                    <div
                      className={
                        expanderSymbol
                          ? "taskListExpander"
                          : "taskListEmptyExpander"
                      }
                      onClick={() => onExpanderClick(row)}
                    >
                      {expanderSymbol}
                    </div>
                    <div>{renderDtColumnProp(column, row)}</div>
                  </div>
                </div>
              )
            })}
          </div>
        )

        return (
          <Row
            key={`gantt_row_${row?.id ?? index}`}
            style={{
              // width: defaultWidth,
              // marginTop: 18,
              padding: "0 14px"
            }}
          >
            {pageAttr?.crudOpt?.data?.dynamicColumns?.map((column) => (
              <div
                style={{
                  fontSize: row?.fontSize ?? 10,
                  fontFamily: row?.fontFamily ?? 10,
                  fontWeight: "bold",
                  width: column.width
                    ? getColumnWidthPercentage(column.width)
                    : "inherit",
                  maxWidth: column?.maxWidth ?? "100%",
                  minWidth: column?.minWidth ?? "20px"
                }}
                // breakPoint={{ xs: 4 }}
              >
                {renderDtColumnProp(column, row)}
              </div>
            ))}
          </Row>
        )
      })}
    </div>
  )
}

export default TaskListColumn
