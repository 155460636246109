// ** Vertical Menu Components
import VerticalNavMenuLink from "./VerticalNavMenuLink"
import VerticalNavMenuGroup from "./VerticalNavMenuGroup"
import VerticalNavMenuSectionHeader from "./VerticalNavMenuSectionHeader"

// ** Utils
import {
  canViewMenuItem,
  canViewMenuGroup,
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent
} from "@layouts/utils"

const VerticalMenuNavItems = (props) => {
  // console.log(props?.items)
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }

  // ** Render Nav Menu Items
  let RenderNavItems = props?.items?.map((item, index) => {
    // if (props.items.length > 1) {
    const TagName = Components[resolveNavItemComponent(item)]

    if (item.children) {
      return (
        canViewMenuGroup(item) && (
          <TagName item={item} index={index} key={item.id} {...props} />
        )
      )
    }
    // console.log(item)

    return (
      canViewMenuItem(item) && (
        <TagName key={item.id || item.header} item={item} {...props} />
      )
    )
    // }
  })

  const keyExists = RenderNavItems ?? [].find((item) => item.key === "homepage")
  if (keyExists) {
    // set blank menu for dev mode on end menu

    let separatorMenus = props?.items?.filter((item) => item?.header) ?? []
    // separatorMenus.push({
    //   action: "read",
    //   header: "FOR_NEW_MENU_SEPARATOR",
    //   id: "menu_null",
    //   parent_id: "menu_null",
    //   resource: "developer_subject",
    //   title: "FOR_NEW_MENU_SEPARATOR"
    // })

    // console.log(separatorMenus)
    if (separatorMenus) {
      let index = 1
      for (const item of separatorMenus) {
        // console.log(item)
        const indexSeparator = RenderNavItems?.findIndex(
          (itemxx) =>
            itemxx?.key === item.id &&
            item?.title !== "PLAYGROUND" &&
            item?.title !== "Universitas Nurtanio" &&
            item?.title !== "UKRI"
        )

        // console.log(index, RenderNavItems.length)

        if (indexSeparator !== -1) {
          const blankItem = {
            action: "read",
            parent_id: "menu_null",
            id: `000${index}`,
            navLink: "/0000",
            resource: `000${index}`,
            id_separator: item.id,
            disabled: true,
            title: "-----"
          }
          const TagNameBlank = Components[resolveNavItemComponent(blankItem)]
          const TagNameBlankComp = (
            <TagNameBlank
              key={`${blankItem.id || blankItem.header}_${index}`}
              item={blankItem}
              {...props}
            />
          )
          RenderNavItems.splice(indexSeparator, 0, TagNameBlankComp)
          index++
        }
      }

      const blankItem = {
        action: "read",
        parent_id: "menu_null",
        id: "0000",
        navLink: "/0000",
        resource: "0000",
        id_separator: "0000",
        disabled: true,
        title: "-----"
      }
      const TagNameBlank = Components[resolveNavItemComponent(blankItem)]
      const TagNameBlankComp = (
        <TagNameBlank
          key={`${blankItem.id || blankItem.header}_${
            RenderNavItems.length + 1
          }`}
          item={blankItem}
          {...props}
        />
      )
      RenderNavItems.splice(RenderNavItems.length + 1, 0, TagNameBlankComp)
    } else {
      const blankItem = {
        action: "read",
        parent_id: "menu_null",
        id: "0000",
        navLink: "/0000",
        resource: "0000",
        disabled: true,
        title: "-----"
      }
      const TagNameBlank = Components[resolveNavItemComponent(blankItem)]
      const TagNameBlankComp = (
        <TagNameBlank
          key={blankItem.id || blankItem.header}
          item={blankItem}
          {...props}
        />
      )
      RenderNavItems.push(TagNameBlankComp)
    }

    // const appSettingMenuIndex = props?.items?.findIndex(
    //   (item) => item?.header === "App Setting"
    // )
    // console.log(appSettingMenuIndex)
    // if (appSettingMenuIndex !== -1) {
    //   RenderNavItems.splice(appSettingMenuIndex, 0, TagNameBlankComp)
    // } else {
    //   // if (canViewMenuItem(blankItem)) {
    //   RenderNavItems.push(TagNameBlankComp)
    //   // }
    // }

    // console.log(RenderNavItems)
  }

  return RenderNavItems
}

export default VerticalMenuNavItems
