/* eslint-disable no-mixed-operators */
import { useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { useTranslation } from "react-i18next"

const CountDown = ({
  item,
  pageAttr,
  currentAttr,
  icon,
  color,
  stats,
  renderStats,
  statTitle,
  className,
  countTextColor,
  countTextStyle = {},
  titleStyle = {},
  statsMargin,
  titleOnTop = false,
  index
}) => {
  const { t } = useTranslation()

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

  const title = item?.title ?? item?.cardTitle
  const subtitle = item?.subtitle ?? item?.cardSubTitle
  const targetDate = item?.targetDate
  const solidColor = item?.solidColor
  const cardStyle = item?.cardStyle
  const cardColor = item?.cardColor

  useEffect(() => {
    const timer = setInterval(() => {
      const currentYear = new Date().getFullYear()
      const defaultDate = `Jan 1, ${currentYear + 1} 00:00:00 +0700`
      const newYear = new Date(
        targetDate ? `${targetDate}T00:00:00+0700` : defaultDate
      )
      const now = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Asia/Jakarta" })
      )
      const distance = newYear - now

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (distance < 0) {
        clearInterval(timer)
        if (document.querySelector(".text")) {
          document.querySelector(
            ".text"
          ).innerText = `Happy Event ${currentYear}!`
        }
      } else {
        setTimeLeft({ days, hours, minutes, seconds })
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  function getCircleColor(unit) {
    switch (unit) {
      case "days":
        return "#ff4c4c"
      case "hours":
        return "#ffd700"
      case "minutes":
        return "#ffc0cb"
      case "seconds":
        return "#b0e0e6"
      default:
        return "#fff"
    }
  }

  function getStrokeDashOffset(unit, value) {
    switch (unit) {
      case "days":
        return 440 - (440 * value) / 365
      case "hours":
        return 440 - (440 * value) / 24
      case "minutes":
      case "seconds":
        return 440 - (440 * value) / 60
      default:
        return 440
    }
  }

  const cardColorFix = cardColor
    ? !solidColor
      ? `bg-light-${cardColor}`
      : `bg-${cardColor} `
    : "bg-gradient-primary"

  return (
    <Card
      style={{ ...cardStyle }}
      {...(cardColor
        ? { className: cardColorFix, inverse: true }
        : { className: cardColorFix })}
    >
      <CardBody>
        <div className="card-countdown-style1">
          <h1 className="text-countdown">
            {title ?? t("The Incredible Event Is Coming Soon!")}
          </h1>
          {subtitle && (
            <h1 className="text-subtitle-countdown mb-3">{subtitle ?? ""}</h1>
          )}

          <div className="container-countdown my-1">
            <div id="countdown">
              {["days", "hours", "minutes", "seconds"].map((unit) => (
                <div
                  key={unit}
                  className="circle"
                  style={{ "--clr": getCircleColor(unit) }}
                >
                  <svg>
                    <circle cx="70" cy="70" r="70"></circle>
                    <circle
                      cx="70"
                      cy="70"
                      r="70"
                      style={{
                        strokeDashoffset: getStrokeDashOffset(
                          unit,
                          timeLeft[unit]
                        )
                      }}
                    ></circle>
                  </svg>
                  <div>
                    {String(timeLeft[unit]).padStart(2, "0")}
                    <br />
                    <span>
                      {t(unit.charAt(0).toUpperCase() + unit.slice(1))}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default CountDown
