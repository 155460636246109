// ** Redux Imports
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import axios from "axios"
import useJwt from "@src/auth/jwt/useJwt"

export const getGlobalFilterYear = createAsyncThunk(
  "getGlobalFilterYear",
  async (params, { rejectWithValue }) => {
    // console.log("GET getGlobalFilterYearSTART...")
    try {
      const currentYear = new Date().getFullYear()
      const data_year = localStorage.getItem("data_year")
      let data_year_storage = ""
      if (data_year && data_year !== "undefined") {
        data_year_storage = data_year ? JSON.parse(data_year) : null
      } else {
        localStorage.setItem("data_year", `${currentYear}`)
        data_year_storage = currentYear
      }
      // console.log("GET getGlobalFilterYear SUCCESS")
      return data_year_storage
    } catch (error) {
      // console.error(error)
      // console.error("GET getGlobalFilterYear FAILED!!!")
      return rejectWithValue(error)
    }
  }
)

export const setGlobalFilterYear = createAsyncThunk(
  "setGlobalFilterYear",
  async (params, { rejectWithValue }) => {
    try {
      localStorage.setItem("data_year", `${params}`)
      return params
    } catch (error) {
      console.log(error)
      // console.error("GET getGlobalFilterYear FAILED!!!")
      return rejectWithValue(error.response.data)
    }
  }
)

const fetchStatus = "idle" | "loading" | "succeeded" | "failed"

export const GlobalFilterSlice = createSlice({
  name: "global_filter",
  initialState: {
    status: fetchStatus,
    isLoading: false,
    error: "" | null,
    data_year: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalFilterYear.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.data_year = action.payload
      })
      .addCase(setGlobalFilterYear.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.data_year = action.payload
      })
  }
})

export default GlobalFilterSlice.reducer
