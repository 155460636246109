// ** React Imports
import { Fragment, useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

// ** Chat App Component Imports
import Chat from "./Chat"
import Sidebar from "./SidebarLeft"
import UserProfileSidebar from "./UserProfileSidebar"

// ** Third Party Components
import classnames from "classnames"

// ** Store & Actions
import { useDispatch, useSelector } from "react-redux"
import { getUserProfile, getChatContacts, selectChat } from "./store"

import "@styles/base/pages/app-chat.scss"
import "@styles/base/pages/app-chat-list.scss"

const AppChat = ({ isFloating = false }) => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector((state) => state.chat)
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  )
  const [searchParams, setSearchParams] = useSearchParams()
  // ** States
  const [user, setUser] = useState({})
  const [sidebar, setSidebar] = useState(false)
  const [userSidebarRight, setUserSidebarRight] = useState(false)
  const [userSidebarLeft, setUserSidebarLeft] = useState(false)
  const [clickSideBarId, setClickSideBarId] = useState(null)
  const [activeUserChat, setActiveUserChat] = useState(null)

  const navigate = useNavigate()

  // ** Sidebar & overlay toggle functions
  const handleSidebar = () => setSidebar(!sidebar)
  const handleUserSidebarLeft = () => setUserSidebarLeft(!userSidebarLeft)
  const handleUserSidebarRight = () => setUserSidebarRight(!userSidebarRight)
  const handleOverlayClick = () => {
    setSidebar(false)
    setUserSidebarRight(false)
    setUserSidebarLeft(false)
  }

  const refreshPage = (path) => {
    // console.log(path)
    return navigate(path)
  }

  const clickSideBar = (id) => {
    setClickSideBarId(id)
    // return id
  }

  // ** Set user function for Right Sidebar
  const handleUser = (obj) => setUser(obj)

  // const paramId = searchParams.get("current")
  // ** Get data on Mount
  useEffect(() => {
    if (store?.contacts?.length === 0 && !store?.contacts_chats_loading) {
      const now = new Date()
      const lastFetch = store?.contacts_chats_success_last_fetch
      const differenceInMinutes = (now - lastFetch) / 1000 / 60

      if (differenceInMinutes > 1 || !lastFetch) {
        dispatch(getChatContacts())
      }
    }

    // if (paramId && paramId !== ) {
    //   dispatch(selectChat(paramId))
    //   if (sidebar === true) {
    //     handleSidebar()
    //   }
    // }
  }, [dispatch])

  return (
    <Fragment>
      <Sidebar
        store={store}
        sidebar={sidebar}
        handleSidebar={handleSidebar}
        userSidebarLeft={userSidebarLeft}
        handleUserSidebarLeft={handleUserSidebarLeft}
        userData={userData}
        clickSideBarId={clickSideBarId}
        setActiveUserChat={setActiveUserChat}
      />
      <div className="content-right">
        <div className="content-wrapper">
          <div className="content-body">
            <div
              className={classnames("body-content-overlay", {
                show:
                  userSidebarRight === true ||
                  sidebar === true ||
                  userSidebarLeft === true
              })}
              onClick={handleOverlayClick}
            ></div>
            <Chat
              store={store}
              handleUser={handleUser}
              handleSidebar={handleSidebar}
              userSidebarLeft={userSidebarLeft}
              handleUserSidebarRight={handleUserSidebarRight}
              userData={userData}
              clickSideBar={clickSideBar}
              refreshPage={refreshPage}
              activeUserChat={activeUserChat}
            />
            <UserProfileSidebar
              user={user}
              userSidebarRight={userSidebarRight}
              handleUserSidebarRight={handleUserSidebarRight}
              userData={userData}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AppChat
